





























































































import Vue from 'vue';

export default Vue.extend({
  components: { },

  props: {
    result: Array,
  },

  data() {
    return {
      showAll: false,
    };
  },
});
