var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-item-result report-item-result__result-wrapper" },
    _vm._l(_vm.result, function(item, index) {
      return _c(
        "div",
        { key: index, staticClass: "report-item-result__result" },
        [
          _c("app-report-collapse-item", {
            attrs: { opened: _vm.opened },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      item["Номер записи РДЛ"]
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text-wrapper flex-shrink-0"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text"
                                },
                                [
                                  _vm._v(
                                    "\n            Номер записи\n          "
                                  )
                                ]
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item["Номер записи РДЛ"]) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      item["Срок дисквалификации"]
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text-wrapper"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text"
                                },
                                [
                                  _vm._v(
                                    "\n            Срок дисквалификации\n          "
                                  )
                                ]
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item["Срок дисквалификации"]) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "report-item-result__result-elem-text-wrapper"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text"
                            },
                            [
                              _vm._v(
                                "\n            Период дисквалификации\n          "
                              )
                            ]
                          ),
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(item["Дата начала дисквалификации"]) +
                                " -\n            " +
                                _vm._s(
                                  item["Дата истечения срока дисквалификации"]
                                ) +
                                "\n          "
                            )
                          ])
                        ]
                      ),
                      item["Статья КоАП РФ"]
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text-wrapper"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text"
                                },
                                [_vm._v("\n            Статья\n          ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item["Статья КоАП РФ"]) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "report-item-result__content-section" },
                        [
                          _c(
                            "div",
                            { staticClass: "report-item-result__content-list" },
                            [
                              item.status
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Статус дисквалификации\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.getStatus(item.status)
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item["ФИО дисквалифицированного лица"]
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                ФИО\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                item[
                                                  "ФИО дисквалифицированного лица"
                                                ]
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item["Дата рождения дисквалифицированного лица"]
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Дата рождения\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                item[
                                                  "Дата рождения дисквалифицированного лица"
                                                ]
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item["Место рождения дисквалифицированного лица"]
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Место рождения лица\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                item[
                                                  "Место рождения дисквалифицированного лица"
                                                ]
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item["Наименование организации"]
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Организация (ИНН)\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                item["Наименование организации"]
                                              ) +
                                              "\n                "
                                          ),
                                          item["ИНН организации"]
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                  (" +
                                                    _vm._s(
                                                      item["ИНН организации"]
                                                    ) +
                                                    ")\n                "
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item["Должность"]
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Должность\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item["Должность"]) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item[
                                "Наименование органа, составившего протокол об административном правонарушении"
                              ]
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Наименование органа\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                item[
                                                  "Наименование органа, составившего протокол об административном правонарушении"
                                                ]
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item["Должность судьи"]
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Должность судьи\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item["Должность судьи"]) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item["ФИО судьи"]
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                ФИО судьи\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item["ФИО судьи"]) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }