var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPhysical && _vm.onlyOwners
    ? _c(
        "app-report-item",
        {
          attrs: {
            title: "Проверка СНИЛС",
            icon: "id-card",
            status: _vm.status,
            images: _vm.images,
            color: _vm.map.color,
            opened: _vm.resultStatus === "found",
            "missing-requirements": _vm.missingRequirements
          },
          scopedSlots: _vm._u(
            [
              {
                key: "not-required",
                fn: function() {
                  return [
                    !_vm.$isServer
                      ? _c(
                          "span",
                          {
                            staticClass: "link font-weight-500 d-print-none",
                            on: {
                              click: function($event) {
                                return _vm.openAddFieldDialog("snils")
                              }
                            }
                          },
                          [_vm._v("\n      Добавить\n    ")]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            222409461
          )
        },
        [
          _vm.task
            ? _c(
                "div",
                { staticClass: "report-item-modern__subtitle-wrapper" },
                [
                  _vm.resultStatus === "found"
                    ? [
                        _c(
                          "div",
                          { staticClass: "report-item-modern__subtitle" },
                          [
                            _c("div", {
                              staticClass:
                                "report-item-modern__dot report-item-modern__color--danger"
                            }),
                            _c("div", [
                              _vm._v(
                                "\n          Результат проверки СНИЛС отрицательный. В СФР отсутствуют сведения, соответствующие введенным данным.\n        "
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "report-item-modern__link" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "link font-weight-500 d-print-none",
                                    on: {
                                      click: function($event) {
                                        return _vm.openAddFieldDialog("snils")
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            Изменить данные запроса\n          "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    : [
                        _c(
                          "div",
                          { staticClass: "report-item-modern__subtitle" },
                          [
                            _c("div", {
                              staticClass:
                                "report-item-modern__dot report-item-modern__color--success"
                            }),
                            _c("div", [
                              _vm._v(
                                "\n          Результат проверки СНИЛС положительный. В СФР имеются сведения, соответствующие введенным данным.\n        "
                              )
                            ])
                          ]
                        )
                      ]
                ],
                2
              )
            : _vm.orderIsDone
            ? [
                _c("span", { staticClass: "text-warning" }, [
                  _vm._v(" Недостаточно данных ")
                ])
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }