
























































































































































































































































import Vue from 'vue';
import find from 'lodash/find';

import Component from 'vue-class-component';

@Component({
  popupOptions: {
    name: 'popup_side popup_sud-details',
  },

  props: {
    result: Array,
    region: String,
    owner: Object,
  },
})
export default class AppArbirtDetails extends Vue {
  // props
  result: any;
  region: string;
  owner: any;
  // data
  showDetails = false;
  details: any = null;
  caseFilter: string = 'all';
  personFilter: string = 'all';
  typeFilters = [
    {
      text: 'Все',
      value: 'all',
    },
    {
      text: 'Банкротные',
      value: 'Б',
    },
    {
      text: 'Административные',
      value: 'А',
    },
    {
      text: 'Гражданские',
      value: 'Г',
    },
  ];

  personFilters = [
    {
      text: 'Все',
      value: 'all',
    },
    {
      text: 'Ответчик',
      value: 'respondent',
    },
    {
      text: 'Истец',
      value: 'plaintiff',
    },
    {
      text: 'Третье лицо',
      value: 'third',
    },
  ];

  casesByPerson: any ={
    plaintiff: [],
    respondent: [],
    third: [],
  };

  roleTypes = {
    P: 'Истец',
    R: 'Ответчик',
  };

  caseTypes = {
    Г: 'Гражданское',
    А: 'Административное',
    Б: 'Банкротное',
    У: 'Уголовное',
    civil: 'Гражданское',
    administrative: 'Административное',
    criminal: 'Уголовное',
  };

  get cases() {
    if (this.personFilter === 'all') {
      return this.filterCasesByType(this.caseFilter);
    }
    return this.filterCasesByPerson(this.personFilter);
  }

  mounted() {
    this.sortCasesByPerson();
  }

  sortCasesByPerson() {
    // const fullName = [ this.owner.surname, this.owner.first, this.owner.patronymic ].join(' ');
    // const shortName = `${this.owner.surname} ${this.owner.first.charAt(0)}. ${this.owner.patronymic.charAt(0)}.`;
    this.result.forEach((elem: any) => {
      // const plaintiff = elem.plaintiff.find((item: any) => {
      //   return (item.name.includes(fullName) || item.name.includes(shortName));
      // });
      // const respondent = elem.respondent.find((item: any) => {
      //   return (item.name.includes(fullName) || item.name.includes(shortName));
      // });
      if (elem.entity_role === 'Истец') {
        this.casesByPerson.plaintiff.push(elem);
      } else if (elem.entity_role === 'Ответчик') {
        this.casesByPerson.respondent.push(elem);
      } else {
        this.casesByPerson.third.push(elem);
      }
    });
  }

  filterCasesByPerson(val: string) {
    if (val === 'all') {
      return this.filterCasesByType(this.caseFilter);
    }
    if (this.caseFilter === 'all') {
      return this.casesByPerson[val];
    }
    return this.casesByPerson[val].filter((elem: any) => {
      return elem.case_type === this.caseFilter;
    });
  }

  filterCasesByType(val: string) {
    if (val === 'all') {
      return this.result;
    }
    return this.result.filter((elem: any) => {
      return elem.case_type === val;
    });
  }

  changeCaseFilter(val: string) {
    this.caseFilter = val;
    this.personFilter = 'all';
  }

  toggleDetails(id: string) {
    this.showDetails = !this.showDetails;

    if (this.showDetails) {
      this.details = find(this.result, { case_id: id });
    } else {
      this.details = null;
    }
  }
  // sortByRegion(val?: string): string {
  //   console.log(this.result.all);
  //   if (val === 'other') {
  //     return this.result.all.filter((elem: any) => {
  //       if (elem.regions) {
  //         return elem.regions[0] !== parseInt(this.region);
  //       } else {
  //         return true;
  //       }
  //     });
  //   }
  //   return this.result.all.filter((elem: any) => {
  //     if (elem.regions) {
  //       return elem.regions[0] === parseInt(this.region);
  //     } else {
  //       return false;
  //     }
  //   });
  // },

  formatCaseDate(date: string) {
    return date.split(' ')[0];
  }
}
