var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-item-result report-item-result__result-wrapper" },
    [
      _c("div", { staticClass: "report-item-result__graph-filter mb-2" }, [
        _c("div", { staticClass: "report-item-result__graph-filter-select" }, [
          _c("div", {}, [
            _c("div", { staticClass: "rc-form-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.caseType,
                      expression: "caseType"
                    }
                  ],
                  staticClass: "custom-select",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.caseType = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.typeFilters, function(reg) {
                  return _c(
                    "option",
                    { key: reg.value, domProps: { value: reg.value } },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(reg.text) +
                          "\n              (" +
                          _vm._s(_vm.getCountByType(reg.value)) +
                          ")\n            "
                      )
                    ]
                  )
                }),
                0
              ),
              _c("label", { class: [{ active: _vm.caseType }] }, [
                _vm._v("Вид дела")
              ])
            ])
          ]),
          _c("div", {}, [
            _c("div", { staticClass: "rc-form-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.roleType,
                      expression: "roleType"
                    }
                  ],
                  staticClass: "custom-select",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.roleType = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.personFilters, function(reg) {
                  return _c(
                    "option",
                    { key: reg.value, domProps: { value: reg.text } },
                    [
                      _vm._v(
                        "\n              " + _vm._s(reg.text) + "\n            "
                      )
                    ]
                  )
                }),
                0
              ),
              _c("label", { class: [{ active: _vm.roleType }] }, [
                _vm._v("Тип участия")
              ])
            ])
          ])
        ]),
        _vm.filteredCases && _vm.filteredCases.length > 0
          ? _c("div", { staticClass: "ml-auto mt-1 mt-md-0 report-open-all" }, [
              _c(
                "span",
                {
                  staticClass: "link d-print-none",
                  on: {
                    click: function($event) {
                      _vm.opened = !_vm.opened
                    }
                  }
                },
                [
                  !_vm.opened
                    ? [_vm._v("\n          Раскрыть все\n        ")]
                    : [_vm._v("\n          Скрыть все\n        ")]
                ],
                2
              )
            ])
          : _vm._e()
      ]),
      _vm._l(_vm.cases[_vm.currentPage - 1], function(item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "report-item-result__result arbitr-cases-header"
          },
          [
            _c("app-report-collapse-item", {
              attrs: { opened: _vm.opened },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "report-item-result__result-elem-image d-none d-md-block",
                            class: {
                              "report-item-result__result-elem-image--success":
                                item.date_and_reason_to_stop
                            }
                          },
                          [_c("div")]
                        ),
                        item.case_number
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__result-elem-text-wrapper"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__result-elem-text"
                                  },
                                  [
                                    _vm._v(
                                      "\n            Номер/Дата\n          "
                                    )
                                  ]
                                ),
                                _c("div", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "https://kad.arbitr.ru/Card/" +
                                          item.case_id,
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.case_number))]
                                  ),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.formatCaseDate(item.case_date)
                                      ) +
                                      "\n          "
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass:
                              "report-item-result__result-elem-text-wrapper"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__result-elem-text"
                              },
                              [_vm._v("\n            Тип дела\n          ")]
                            ),
                            item.case_type
                              ? _c("div", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.caseTypes[item.case_type]) +
                                      "\n          "
                                  )
                                ])
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "report-item-result__result-elem-text-wrapper"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__result-elem-text"
                              },
                              [_vm._v("\n            Тип участия\n          ")]
                            ),
                            item.entity_role
                              ? _c("div", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(item.entity_role) +
                                      "\n          "
                                  )
                                ])
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "report-item-result__result-elem-text-wrapper"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__result-elem-text"
                              },
                              [_vm._v("\n            Суд\n          ")]
                            ),
                            item.court_name_val
                              ? _c("div", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(item.court_name_val) +
                                      "\n          "
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "report-item-result__content-section"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "report-item-result__content-list"
                              },
                              [
                                item.case_id && item.case_number
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Номер дела\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "https://kad.arbitr.ru/Card/" +
                                                    item.case_id,
                                                  target: "_blank"
                                                }
                                              },
                                              [_vm._v(_vm._s(item.case_number))]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                item.case_type
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Тип дела\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.caseTypes[item.case_type]
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                item.role
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Роль\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.roleTypes[item.role]
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                item.case_date
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Дата\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.formatCaseDate(
                                                    item.case_date
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                item.court_name_val
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Судебный участок\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(item.court_name_val) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                item.address_val
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Адрес\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(item.address_val) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "report-item-result__content-section"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__content-section-title"
                              },
                              [
                                _c("h4", [
                                  _vm._v(
                                    "\n              Информация о сторонах\n            "
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "report-item-result__table" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__table-head d-none d-md-flex"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__table-head-item"
                                      },
                                      [
                                        _vm._v(
                                          "\n                Наименование\n              "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__table-head-item"
                                      },
                                      [
                                        _vm._v(
                                          "\n                Тип участия\n              "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__table-head-item"
                                      },
                                      [
                                        _vm._v(
                                          "\n                Адрес\n              "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__table-body"
                                  },
                                  [
                                    _vm._l(item.plaintiff, function(elem, i) {
                                      return _c(
                                        "div",
                                        {
                                          key: elem.inn + i,
                                          staticClass:
                                            "report-item-result__table-body-item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__table-body-elem"
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "d-md-none" },
                                                [
                                                  _vm._v(
                                                    "\n                    Наименование\n                  "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(elem.name) +
                                                  "\n                  "
                                              ),
                                              elem.inn
                                                ? _c("div", [
                                                    _c("p", [
                                                      _vm._v(
                                                        "\n                      ИНН: " +
                                                          _vm._s(elem.inn) +
                                                          "\n                    "
                                                      )
                                                    ])
                                                  ])
                                                : _vm._e()
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__table-body-elem"
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "d-md-none" },
                                                [
                                                  _vm._v(
                                                    "\n                    Тип участия\n                  "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                  Истец\n                "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__table-body-elem"
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "d-md-none" },
                                                [
                                                  _vm._v(
                                                    "\n                    Адрес\n                  "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(elem.address) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    item.respondent.length
                                      ? _vm._l(item.respondent, function(
                                          elem,
                                          i
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: elem.inn + i,
                                              staticClass:
                                                "report-item-result__table-body-item"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "report-item-result__table-body-elem"
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "d-md-none"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Наименование\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(elem.name) +
                                                      "\n                    "
                                                  ),
                                                  elem.inn
                                                    ? _c("div", [
                                                        _c("p", [
                                                          _vm._v(
                                                            "\n                        ИНН: " +
                                                              _vm._s(elem.inn) +
                                                              "\n                      "
                                                          )
                                                        ])
                                                      ])
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "report-item-result__table-body-elem"
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "d-md-none"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Тип участия\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\n                    Ответчик\n                  "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "report-item-result__table-body-elem"
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "d-md-none"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Адрес\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(elem.address) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        })
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            })
          ],
          1
        )
      }),
      _vm.filteredCases.length > _vm.perPage
        ? _c("app-pagination", {
            attrs: {
              current: _vm.currentPage,
              "per-page": _vm.perPage,
              total: _vm.filteredCases.length
            },
            on: { "page-changed": _vm.pageChanged }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }