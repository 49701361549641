var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sud-container" }, [
    _c("div", { staticClass: "sud-items" }, [
      _c(
        "div",
        { staticClass: "popup__header" },
        [
          _c("div", { staticClass: "popup__title" }, [
            _vm._v("\n        Данные о розыске\n      ")
          ]),
          _c("app-dialog-close")
        ],
        1
      ),
      _vm.get(_vm.result, "_embedded.notices")
        ? _c(
            "div",
            { staticClass: "popup__body" },
            _vm._l(_vm.result._embedded.notices, function(elem) {
              return _c(
                "div",
                {
                  key: elem.entity_id,
                  staticClass: "sud-details__content legacy-container"
                },
                [
                  _c(
                    "div",
                    { staticClass: "row mb-3 no-gutters align-items-center" },
                    [
                      _vm.get(elem, "_links.thumbnail.href")
                        ? _c("div", { staticClass: "col-auto mr-2" }, [
                            _c("div", { staticClass: "sud-details-item" }, [
                              _c("img", {
                                attrs: {
                                  src: elem._links.thumbnail.href,
                                  width: "100px"
                                }
                              })
                            ])
                          ])
                        : _vm._e()
                    ]
                  ),
                  elem.forename
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v("\n            Фамилия\n          ")
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(elem.forename) +
                              "\n          "
                          )
                        ])
                      ])
                    : _vm._e(),
                  elem.name
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v("\n            Имя\n          ")
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(elem.name) +
                              "\n          "
                          )
                        ])
                      ])
                    : _vm._e(),
                  elem.date_of_birth
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v("\n            Дата рождения\n          ")
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("date")(elem.date_of_birth, "DD.MM.YYYY")
                              ) +
                              "\n          "
                          )
                        ])
                      ])
                    : _vm._e(),
                  elem.territorial
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v("\n            Регион\n          ")
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(elem.territorial) +
                              "\n          "
                          )
                        ])
                      ])
                    : _vm._e(),
                  elem.federal
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v("\n            Федеральный округ\n          ")
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(elem.federal) +
                              "\n          "
                          )
                        ])
                      ])
                    : _vm._e(),
                  elem.subtext
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v(
                            "\n            Дополнительная информация\n          "
                          )
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(elem.subtext) +
                              "\n          "
                          )
                        ])
                      ])
                    : _vm._e()
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm.get(_vm.result, "data")
        ? _c(
            "div",
            { staticClass: "popup__body" },
            _vm._l(_vm.result.data, function(elem) {
              return _c(
                "div",
                {
                  key: elem.entity_id,
                  staticClass: "sud-details__content legacy-container"
                },
                [
                  _c(
                    "div",
                    { staticClass: "row mb-3 no-gutters align-items-center" },
                    [
                      elem.images && elem.images.length
                        ? _c(
                            "div",
                            { staticClass: "col-auto mr-2" },
                            _vm._l(elem.images, function(img) {
                              return _c(
                                "div",
                                { key: img, staticClass: "sud-details-item" },
                                [
                                  _c("img", {
                                    attrs: { src: img, width: "100px" }
                                  })
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ]
                  ),
                  elem.forename
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v("\n            Фамилия\n          ")
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(elem.forename) +
                              "\n          "
                          )
                        ])
                      ])
                    : _vm._e(),
                  elem.name
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v("\n            Имя\n          ")
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(elem.name) +
                              "\n          "
                          )
                        ])
                      ])
                    : _vm._e(),
                  elem.date_of_birth
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v("\n            Дата рождения\n          ")
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("date")(elem.date_of_birth, "DD.MM.YYYY")
                              ) +
                              "\n          "
                          )
                        ])
                      ])
                    : _vm._e(),
                  elem.territorial
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v("\n            Регион\n          ")
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(elem.territorial) +
                              "\n          "
                          )
                        ])
                      ])
                    : _vm._e(),
                  elem.federal
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v("\n            Федеральный округ\n          ")
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(elem.federal) +
                              "\n          "
                          )
                        ])
                      ])
                    : _vm._e(),
                  elem.subtext
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v(
                            "\n            Дополнительная информация\n          "
                          )
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(elem.subtext) +
                              "\n          "
                          )
                        ])
                      ])
                    : _vm._e()
                ]
              )
            }),
            0
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }