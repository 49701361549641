var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "a-risk-report" }, [
    _c(
      "div",
      { staticClass: "a-risk-report__head d-flex align-items-center mb-2" },
      [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("div", { staticClass: "btn-link" }, [
            _c("i", {
              staticClass: "fas fa-arrow-left mr-2",
              on: { click: _vm.goToOrder }
            })
          ]),
          _c(
            "h5",
            { staticClass: "m-0" },
            [
              _vm.isProduct("CreditRating")
                ? [_vm._v("\n          Кредитный отчет\n        ")]
                : _vm._e(),
              _vm.isProduct("CostAssessment")
                ? [_vm._v("\n          Оценка стоимости\n        ")]
                : _vm._e()
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "a-risk-report__create-pdf btn-link ml-3 small",
              on: { click: _vm.openCreditRatingModal }
            },
            [_vm._v("\n        предпросмотр\n      ")]
          )
        ])
      ]
    ),
    _c(
      "div",
      { staticClass: "border-bottom pb-3 mb-3" },
      [
        _vm.report
          ? _c("div", { staticClass: "small mb-2" }, [
              _vm._v(
                "\n      Общий статус заказа: " +
                  _vm._s(_vm.report.status) +
                  "\n      "
              ),
              _vm.report.status === "failed"
                ? _c("i", {
                    staticClass:
                      "fas fa-info-circle fa-fw text-danger cursor-pointer"
                  })
                : _vm._e()
            ])
          : _vm._e(),
        _c(
          "app-button",
          {
            staticClass: "ml-2",
            attrs: { size: "sm", variant: "light" },
            on: { click: _vm.showUpdateStatus }
          },
          [_vm._v("\n      Парсеры\n    ")]
        ),
        _vm.isProduct("CreditRating")
          ? _c(
              "app-button",
              {
                staticClass: "ml-2",
                attrs: { size: "sm", variant: "light" },
                on: { click: _vm.openEditDialog }
              },
              [_vm._v("\n      Редактировать\n    ")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }