


























































import Vue from 'vue';
import Component from 'vue-class-component';
import noop from 'lodash/noop';

import { Validators } from '@/core/utils/validator';

@Component({
  name: 'AppCreateCouponDialog',

  popupOptions: {
    size: 'sm',
  },

  props: {
    campaignsById: Object,
    onRequestEnd: {
      type: Function,
      default: noop,
    },
  },
})
export default class CreateCouponDialog extends Vue {
  // props
  campaignsById: { [key: string]: AdminCampaignItem };
  onRequestEnd: () => void;

  // data
  model: CreateCouponData = {
    person_id: '',
    count: null,
    campaign_id: null,
  };

  // methods
  async onSubmit() {
    try {
      await this.$api.admin.CreateCoupon(this.model);

      this.onRequestEnd();
      this.$dialog.close();
    } catch (error) {
      console.error(error);
    }
  }

  validationRules(): ValidationConfig {
    return {
      person_id: [ Validators.required ],
      count: [ Validators.required ],
      campaign_id: [ Validators.required ],
    };
  }
}
