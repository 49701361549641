




































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import AppTaxReportDetails from 'shared/components/FizCheck/Details/TaxReportDetails.vue';
import AppWarningImg from 'shared/components/Static/WarningImg.vue';
import sumBy from 'lodash/sumBy';
import get from 'lodash/get';
import concat from 'lodash/concat';
import filter from 'lodash/filter';

@Component({
  components: { AppReportItem, AppTaxReportDetails, AppWarningImg },
  props: {
    report: Object,
    status: String,
    images: Array,
    orderIsDone: Boolean,
    missingRequirements: Array,
    openAddFieldDialog: Function,
    updateMap: Function,
    task: Object,
    isPhysical: Boolean,
    onlyOwners: Boolean,
    map: Object,
    isPdf: Boolean,
  },
})
export default class AppTaxReportModern extends Vue {
  // props
  missingRequirements: any[];
  report: any;
  status: string;
  images: any[];
  orderIsDone: boolean;
  task: any;
  isPhysical: boolean;
  onlyOwners: boolean;
  openAddFieldDialog: (val: string) => any;
  updateMap: (taskName: string, field: string, value: any) => void;
  map: any;
  opened: boolean = false;
  isPdf: boolean;
  resultStatusTrace: any = null;

  created() {
    this.resultStatusTrace = this.resultStatus;
  }

  updateColor(color: string) {
    this.updateMap('individualDebt', 'color', color);
  }

  get result() {
    return this.gosuslugiResult;
  }

  get slicedResult() {
    return this.result.slice(0, 20);
  }

  get taskStatus() {
    if (this.statusFailed && this.gosuslugiBills) {
      return this.gosuslugiBills.status;
    }
    return this.status;
  }

  get statusDone() {
    return this.taskStatus === 'done';
  }

  get statusFailed() {
    return this.status === 'failed';
  }

  get resultStatus() {
    if (this.statusDone) {
      if (this.result && this.result.length) {
        this.updateColor('danger');
        return 'found';
      } else if (this.inn) {
        this.updateColor('success');
        return 'not found';
      } else {
        return 'no inn';
      }
    } else if (this.orderIsDone) {
      this.updateColor('info');
      return 'no data';
    } else {
      this.updateColor('info');
      return 'no task';
    }
  }

  get gosuslugiBills() {
    return this.getTask('gosuslugi_bills');
  }

  get gosuslugiResult() {
    // return get(this.gosuslugiBills, 'task_result.result.inn.bills', []);
    const result = get(this.gosuslugiBills, 'task_result.result');
    if (!result) {
      return [];
    }
    return concat(
      filter(get(result, 'inn.bills'), this.mapBills),
      filter(get(result, 'passport.bills'), this.mapBills),
      filter(get(result, 'snils.bills'), this.mapBills),
    );
  }

  get penaltySum() {
    if (this.result && this.result.length) {
      const sum = sumBy(
        this.result,
        (elem: any) => +elem.amount
      );
      return sum;
    }
    return 0;
  }

  get inn() {
    const innTask = this.getTask('owner_check_individual_inn');
    if (innTask && [ 'failed, soft_failed' ].includes(innTask.status)) {
      return '';
    }
    if (innTask && innTask.task_result.inn) {
      return innTask.task_result.inn;
    } else if (this.report.owner && this.report.owner.inn) {
      return this.report.owner.inn;
    } else {
      return '';
    }
  }

  getTask(val: string) {
    return this.report.tasks.find((elem: any) => elem.task_type === val);
  }

  mapBills(elem: any) {
    return elem.serviceCategory.code === 'FNS';
  }
}
