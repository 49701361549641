var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-statement-item",
    {
      attrs: { "is-disabled": !_vm.hasInfo, "is-open": _vm.hasInfo },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c("i", { staticClass: "fas fa-tree fa-fw mr-1 icon" }),
              _vm._v(" Сведения о земельном участке\n  ")
            ]
          },
          proxy: true
        },
        {
          key: "status",
          fn: function() {
            return [
              !_vm.payed && _vm.showPayInfo
                ? [
                    _c("client-only", [
                      _c("div", { staticClass: "risk-report-item__status" }, [
                        _c("i", {
                          staticClass: "fas fa-lock mr-2 text-danger"
                        }),
                        _c("span", { staticClass: "d-none d-md-inline" }, [
                          _vm._v("Доступно после заказа")
                        ]),
                        _c("img", {
                          staticClass: "ml-md-4",
                          attrs: { src: "/images/eye.svg" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("icon-click")
                            }
                          }
                        })
                      ])
                    ])
                  ]
                : _vm.info
                ? [
                    _c("i", {
                      staticClass: "fas fa-exclamation-circle text-success"
                    }),
                    _vm._v(" Найдено\n    ")
                  ]
                : _vm.status !== "done" && !_vm.$isServer && !_vm.opened
                ? [
                    _c("i", { staticClass: "fas fa-info-circle text-info" }),
                    _vm._v(" В работе\n    ")
                  ]
                : [
                    _c("i", {
                      staticClass: "fas fa-check-circle text-success"
                    }),
                    _vm._v(" Не найдено\n    ")
                  ]
            ]
          },
          proxy: true
        }
      ])
    },
    [
      [
        _vm.info
          ? [
              _c("div", { staticClass: "object-info-list" }, [
                _vm.info.Number
                  ? _c("div", { staticClass: "object-info-list__item" }, [
                      _c("b", [
                        _vm._v("Кадастровый номер"),
                        _c("span", { staticClass: "desktop-hidden" }, [
                          _vm._v(":")
                        ])
                      ]),
                      _vm._v(
                        "\n          " + _vm._s(_vm.info.Number) + "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm.info.Info
                  ? _c("div", { staticClass: "object-info-list__item" }, [
                      _c("b", [
                        _vm._v("Cтатус"),
                        _c("span", { staticClass: "desktop-hidden" }, [
                          _vm._v(":")
                        ])
                      ]),
                      _vm._v(
                        "\n          " + _vm._s(_vm.info.Info) + "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm.info.Area || _vm.info.AreaOKC
                  ? _c("div", { staticClass: "object-info-list__item" }, [
                      _c("b", [
                        _vm._v("Площадь"),
                        _c("span", { staticClass: "desktop-hidden" }, [
                          _vm._v(":")
                        ])
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("float")(_vm.info.Area || _vm.info.AreaOKC)
                          ) +
                          " м"
                      ),
                      _c("sup", [_vm._v("2")])
                    ])
                  : _vm._e(),
                _vm.info.DateOfKadReg
                  ? _c("div", { staticClass: "object-info-list__item" }, [
                      _c("b", [
                        _vm._v("Дата постановки на кадастровый учет"),
                        _c("span", { staticClass: "desktop-hidden" }, [
                          _vm._v(":")
                        ])
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.info.DateOfKadReg) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm.info.LandCategory
                  ? _c("div", { staticClass: "object-info-list__item" }, [
                      _c("b", [
                        _vm._v("Категория участка"),
                        _c("span", { staticClass: "desktop-hidden" }, [
                          _vm._v(":")
                        ])
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.info.LandCategory) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm.info.PermittedUse
                  ? _c("div", { staticClass: "object-info-list__item" }, [
                      _c("b", [
                        _vm._v("Виды разрешенного использования"),
                        _c("span", { staticClass: "desktop-hidden" }, [
                          _vm._v(":")
                        ])
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.info.PermittedUse) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm.info.UpdatedAt
                  ? _c("div", { staticClass: "object-info-list__item" }, [
                      _c("b", [
                        _vm._v("Дата обновления"),
                        _c("span", { staticClass: "desktop-hidden" }, [
                          _vm._v(":")
                        ])
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.info.UpdatedAt) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm.info.KadPrice
                  ? _c("div", { staticClass: "object-info-list__item" }, [
                      _c("b", [
                        _vm._v("Кадастровая стоимость"),
                        _c("span", { staticClass: "desktop-hidden" }, [
                          _vm._v(":")
                        ])
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("currency")(_vm.info.KadPrice)) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm.info.DateOfPriceAdded
                  ? _c("div", { staticClass: "object-info-list__item" }, [
                      _c("b", [
                        _vm._v("Дата внесения стоимости"),
                        _c("span", { staticClass: "desktop-hidden" }, [
                          _vm._v(":")
                        ])
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.info.DateOfPriceAdded) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "row sm-gutters" }, [
                _c("div", { staticClass: "col-auto" }, [
                  _c("i", {
                    staticClass:
                      "report-item__icon fa-fw mt-1 fas fa-address-card text-primary"
                  })
                ]),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    { staticClass: "report-item__title font-weight-500 mb-3" },
                    [
                      _vm._v(
                        "\n            Сведения о регистрации\n            "
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tippy",
                              rawName: "v-tippy",
                              value: {
                                placement: "top-center",
                                content:
                                  "Информация может быть устаревшей. Актуальные данные можно получить заказав проверку земельного участка."
                              },
                              expression:
                                "{\n                placement: 'top-center',\n                content: 'Информация может быть устаревшей. Актуальные данные можно получить заказав проверку земельного участка.',\n              }"
                            }
                          ],
                          staticClass: "ml-1"
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-info-circle text-muted"
                          })
                        ]
                      )
                    ]
                  ),
                  _c("div", { staticClass: "object-info-list" }, [
                    _c(
                      "div",
                      { staticClass: "object-info-list__item" },
                      [
                        _c("b", [
                          _vm._v("Собственники"),
                          _c("span", { staticClass: "desktop-hidden" }, [
                            _vm._v(":")
                          ])
                        ]),
                        _vm.info.Rights && _vm.info.Rights.length
                          ? _vm._l(_vm.info.Rights, function(owner, i) {
                              return _c(
                                "div",
                                { key: owner.number + i + "i" },
                                [
                                  _vm.info.Rights.length > 1
                                    ? [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(i + 1) +
                                            ")\n                  "
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(owner.number) +
                                      "\n                "
                                  )
                                ],
                                2
                              )
                            })
                          : [
                              _vm._v(
                                "\n                Нет данных\n              "
                              )
                            ]
                      ],
                      2
                    ),
                    _vm.info.Rights && _vm.info.Rights.length
                      ? _c(
                          "div",
                          { staticClass: "object-info-list__item" },
                          [
                            _c("b", [
                              _vm._v("Дата собственности"),
                              _c("span", { staticClass: "desktop-hidden" }, [
                                _vm._v(":")
                              ])
                            ]),
                            _vm._l(_vm.info.Rights, function(owner, i) {
                              return _c("div", { key: owner.number + i }, [
                                _vm._v(
                                  "\n                - с " +
                                    _vm._s(owner.date) +
                                    " по н.в.\n              "
                                )
                              ])
                            })
                          ],
                          2
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "object-info-list__item" },
                      [
                        _c("b", [
                          _vm._v("Наличие обременений"),
                          _c("span", { staticClass: "desktop-hidden" }, [
                            _vm._v(":")
                          ])
                        ]),
                        _vm.info.Limits &&
                        _vm.info.Limits.length > _vm.restrictions.length
                          ? [_vm._v("\n                Да\n              ")]
                          : [_vm._v("\n                Нет\n              ")]
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "object-info-list__item" },
                      [
                        _c("b", [
                          _vm._v("Наличие ограничений"),
                          _c("span", { staticClass: "desktop-hidden" }, [
                            _vm._v(":")
                          ])
                        ]),
                        _vm.restrictions.length
                          ? [_vm._v("\n                Да\n              ")]
                          : [_vm._v("\n                Нет\n              ")]
                      ],
                      2
                    )
                  ])
                ])
              ]),
              _vm.showButton
                ? _c(
                    "a",
                    {
                      staticClass: "d-print-none",
                      attrs: {
                        href: "/objectCheckout/" + _vm.info.Number,
                        target: "_blank"
                      }
                    },
                    [
                      _c(
                        "app-button",
                        {
                          attrs: {
                            type: "button",
                            variant: "primary-light",
                            size: "lg",
                            block: ""
                          }
                        },
                        [
                          _vm._v(
                            "\n          Проверить земельный участок\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }