import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import get from 'lodash/get';
import concat from 'lodash/concat';

import { Validators } from '@/core/utils/validator';
import { Region, regionsSortByName } from 'shared/utils/regions';
import AppRiskUpdateScraperStatusDialog from '../RiskUpdateScraperStatus.vue';
// import AppOwnerFormItemBase from './OwnerFormItemBase.vue';
import AppOwnerFormItemBase from 'shared/components/RiskNew/AddOwnerForm/OwnerFormItemBase.vue';
import isUnderage from 'shared/utils/isUnderage';
import { AuthTypes } from 'shared/store/modules/auth/types';
import AppSurveyDialog from 'shared/components/RiskNew/SurveyDialog/SurveyDialog.vue';
import { statusText, statusClassName } from 'shared/utils/orderStatus';

@Component({
  components: { AppOwnerFormItemBase, AppSurveyDialog },

  props: {
    item: Object,
    id: String,
    api: Object,
    reportV2: Object,
  },
})
export default class RiskReportForm extends Vue {
  // props
  item: LawInformationOwner;
  id: string;
  reportV2: LawInformation;

  // data()
  regions: Region[] = regionsSortByName;

  btnLoading: boolean = false;

  @Inject() Report: RiskReportV2Provide;

  // computed
  get reportItem() {
    if (this.Report.ownersById[this.id]) {
      return this.Report.ownersById[this.id];
    }

    return null;
  }

  get statusClassName(): string {
    if (!this.reportItem) {
      return '';
    }
    return statusClassName(this.statusV2);
  }

  get statusText(): string {
    if (!this.reportItem) {
      return null;
    }
    return statusText(this.statusV2);
  }

  get statusV2() {
    const statusFailed = this.findStatus('failed');
    if (statusFailed) {
      return 'failed';
    }
    const statusSoftFailed = this.findStatus('soft_failed');
    if (statusSoftFailed) {
      return 'soft_failed';
    }
    const statusPending = this.findStatus('in progress');
    if (statusPending) {
      return 'in progress';
    }
    const statusNotStatred = this.findStatus('not started');
    if (statusNotStatred) {
      return 'not started';
    }
    const statusDone = this.findStatus('done');
    if (statusDone) {
      return 'done';
    }
    return 'not started';
  }

  get isAdmin(): boolean {
    return this.$store.getters[AuthTypes.getters.IS_ADMIN];
  }

  get isCheckOwner() {
    const productName = get(this.reportV2, 'order.input_data.product_name', '');
    return productName === 'CheckOwnerV2';
  }

  get fullObject() {
    const tasks = get(this.reportV2, 'tasks_general', []);
    if (!tasks) {
      return;
    }
    return tasks.find((elem) => {
      return elem.task_type === 'get_object_full';
    });
  }

  get fullObjectRights() {
    let rights = get(this.fullObject, 'task_result.Rights', []);
    let limits = get(this.fullObject, 'task_result.Limits', []);
    if (rights && rights.length) {
      rights = rights.filter((elem: any) => {
        return elem.date;
      });
    }
    if (limits && limits.length) {
      limits = limits.filter((elem: any) => {
        return [ 'Аренда', 'Доверительное управление' ].includes(elem.type) && elem.date;
      });
    }
    return concat(rights || [], limits || []);
  }

  // lifecycle hooks
  created() {
    if (this.item.birthday === '0001-01-01' || this.item.birthday === '31.12.0000' || this.item.birthday === '') {
      this.item.birthday = '';
    }
  }

  // methods
  validationConfig(model: LawInformationOwner): ValidationConfig {
    if (model.owner_type === 0) {
      let config: ValidationConfig = {
        first: [ Validators.required ],
        surname: [ Validators.required ],
        // passport: [ Validators.required, Validators.min(10, 'Обязательное поле') ],
        // birthday: [ Validators.required, Validators.date('Обязатльное поле') ],
        // region: [ Validators.required ],
      };

      if (isUnderage(model.birthday)) {
        config = omit(config, 'passport', 'region');
      }

      return config;
    } else {
      return {
        first: [ Validators.required ],
        inn: [ Validators.required, Validators.min(10, 'Обязательное поле') ],
        region: [ Validators.required ],
      };
    }
  }

  onSubmit() {
    this.btnLoading = true;
    this.$emit('create', this.item);
  }

  async updateUserMetadata() {
    this.btnLoading = true;
    try {
      const body: any = cloneDeep(this.item);
      if (body.birthday) {
        body.birthday = new Date(body.birthday).toISOString();
      }
      if (body.driver_license_at) {
        body.driver_license_at = new Date(body.driver_license_at).toISOString();
      }
      body.force_update = true;
      setTimeout(async () => {
        await this.$api.risk.UpdateOwnerV2(body);
        this.btnLoading = false;
        this.$noty.success({ text: 'Обновлено' });
        this.$emit('updated', this.id);
      }, 2000);
    } catch (error) {
      this.$noty.error({ text: 'Ошибка при обновлении данных' });
      console.error(error);
      this.btnLoading = false;
    }
  }

  async removeItem() {
    let forceUpdate = false;

    if (this.reportItem && confirm('Удалить этого собственника')) {
      this.btnLoading = true;

      try {
        await this.$api.risk.RemoveOwnerV2({ owner_id: this.id, order_item_id: this.reportV2.order.order_item_id });

        this.$noty.success({ text: 'Успешно удалён' });
        forceUpdate = true;
      } catch (error) {
        this.$noty.error({ text: 'Ошибка при удалении' });
        this.btnLoading = false;
        return;
      }

      this.btnLoading = false;

      this.$emit('remove', this.id, forceUpdate);
    }
  }

  showUpdateStatus() {
    this.$dialog.open({
      component: AppRiskUpdateScraperStatusDialog,
      props: {
        report: this.reportItem,
        orderItemId: this.reportV2.order.order_item_id,
      },
    });
  }

  showSurveyDialog() {
    this.$dialog.open({
      component: AppSurveyDialog,
      props: { orderItemId: this.reportV2.order.order_item_id, surveys: this.reportItem.surveys, onSave: () => this.$emit('updated', this.id) },
    });
  }

  findStatus(status: string) {
    return this.reportItem.tasks.find((elem) => elem.status === status);
  }
  // async resetItem() {
  //   this.btnLoading = true;
  //   try {
  //     await this.$api.risk.ResetReportItem({
  //       reportItemId: this.reportItem.itemId,
  //     });

  //     this.reportItem.status = 'pending';
  //   } catch (error) {
  //     console.log(error);
  //     this.$noty.error({ text: this.$api.getErrorMessage(error) });
  //   }

  //   this.btnLoading = false;
  // }
}
