








































































































import Vue from 'vue';
import _ from 'lodash';

import pluck from 'shared/utils/pluck';
import formatDateStringToISO from 'shared/utils/date/formatDateStringToISO';
import { Validators } from 'shared/utils/validator';

interface ComponentData {
  model: PriceComponent.Request.UserCreateItem;
  priceComponentItems: { [key: string]: string };
}

export default Vue.extend({
  popupOptions: {
    size: 'xs',
  },

  props: {
    userId: String,
    onCreated: Function,
  },

  data(): ComponentData {
    return {
      model: {
        price_component_id: null,
        person_id: this.userId,
        name: '',
        description: '',
        from_date: '',
        thru_date: '',
        count_current: 10,
        count_max: 10,
        enabled: true,
      },

      priceComponentItems: {},
    };
  },

  async created() {
    try {
      const items = await this.$api.priceComponent.items.All();
      const sortedItems = _.sortBy(items, 'name');
      const firstItem = _.first(items);

      this.model.price_component_id = firstItem.price_component_id;
      this.priceComponentItems = pluck(sortedItems, 'price_component_id', 'name');
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    async createUserPriceComponent() {
      try {
        const body = { ...this.model };

        formatDateStringToISO(body, 'from_date', 'thru_date');

        await this.$api.priceComponent.user.CreateItem(body);

        this.onCreated();

        this.$dialog.close();
      } catch (error) {
        console.error(error);
      }
    },

    validationRules(): ValidationConfig {
      return {
        price_component_id: [ Validators.required ],
        name: [ Validators.required ],
        from_date: [ Validators.required ],
        thru_date: [ Validators.required ],
        count_current: [ Validators.isInt({ min: 1, message: 'Число должно быть не меньше 1' }) ],
        count_max: [ Validators.isInt({ min: 1, message: 'Число должно быть не меньше 1' }) ],
      };
    },
  },

});
