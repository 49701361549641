



























































import Vue from 'vue';
import { Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import AppExtendedReportItemList from 'shared/components/FizCheck/ExtendedReport/ExtendedReportItemList.vue';
import capitalize from 'lodash/capitalize';
import AppWarningImg from 'shared/components/Static/WarningImg.vue';

@Component({
  components: { AppReportItem, AppExtendedReportItemList, AppWarningImg },
  props: {
    result: Object,
    opened: Boolean,
  },
})
export default class AppExtendedReport extends Vue {
  // props
  result: any;
  capitalize = capitalize;
  opened: boolean;
  openedLocal: boolean = false;

  get slicedItems() {
    return this.result.items.slice(0, 20);
  }

  @Watch('opened', { immediate: true })
  openedChanged(val: boolean): void {
    this.openedLocal = val;
  }

  toggleOpen() {
    this.openedLocal = !this.openedLocal;
  }
}
