

























































































































































































































import Vue from 'vue';

import Component from 'vue-class-component';
import AppBankruptDetails from './BankruptDetails.vue';
import has from 'lodash/has';

import { BankruptCaseTypes } from 'shared/utils/constants/bankrupt';

@Component({
  props: {
    result: Array,
    isPhysical: Boolean,
  },
})
export default class AppBankruptReport extends Vue {
  // props
  result: any;
  isPhysical: boolean;
  // data
  showAll: boolean = false;

  get caseTypes() {
    return BankruptCaseTypes;
  }

  objectHas(object: any, key: string) {
    return has(object, key);
  }

  openDetails(elem: any) {
    this.$dialog.open({
      component: AppBankruptDetails,
      props: {
        result: elem,
        isPhysical: this.isPhysical,
      },
    });
  }
}
