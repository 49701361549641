































































import Vue from 'vue';
import Component from 'vue-class-component';
import _ from 'lodash';
import noop from 'lodash/noop';
@Component({
  name: 'AddResponsibleToUser',

  popupOptions: {
    size: 'xs',
  },

  props: {
    userId: {
      type: String,
      required: true,
    },
    onResponsibleUserUpdated: {
      type: Function,
      default: noop,
    },
  },
})
export default class AddResponsibleToUserDialog extends Vue {
  // props
  onResponsibleUserUpdated: () => void;
  userId: string;

  // data
  model: SimpleObject = {
    email: '',
  };
  userItems: User[] = [];
  loading: boolean = false;

  // methods
  async onSubmit() {
    try {
      const users = await this.$api.admin.SearchUsers(this.model);

      if (_.isEmpty(users)) {
        return this.$noty.error({ text: 'Пользователь с таким email не найден' });
      }

      if (_.size(users) > 1) {
        this.userItems = users;
      } else {
        this.addResponsibleToUser(_.first(users));
      }
    } catch (error) {
      this.$noty.error({ text: this.$api._getErrorMessage(error) });
    }
  }

  async addResponsibleToUser(item: User) {
    try {
      this.$dialog.close();

      await this.$api.admin.ResponsibleUser({
        responsible_person_id: item.id,
        controlled_person_id: this.userId,
      });
      this.onResponsibleUserUpdated();
    } catch (error) {
      this.$noty.error({ text: this.$api._getErrorMessage(error) });
    }
  }
}
