var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPhysical && _vm.onlyOwners
    ? _c(
        "app-report-item",
        {
          attrs: {
            title: "Розыск Интерпол",
            icon: "globe-europe",
            status: _vm.status,
            opened: !!_vm.result,
            "missing-requirements": _vm.missingRequirements,
            images: _vm.images,
            color: _vm.map.color,
            source: {
              domain: "interpol.int",
              link: "https://www.interpol.int/"
            }
          },
          scopedSlots: _vm._u(
            [
              _vm.resultStatus === "found"
                ? {
                    key: "extra-content",
                    fn: function() {
                      return _vm._l(_vm.result.data, function(elem) {
                        return _c("div", { key: elem.entity_id }, [
                          _c("div", {
                            staticClass: "criminal-report__divider"
                          }),
                          _c("div", { staticClass: "row md-gutters" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-12 col-md-auto mb-5 mb-md-0"
                              },
                              [
                                elem.images && elem.images.length
                                  ? _c(
                                      "VueSlickCarousel",
                                      _vm._b(
                                        {
                                          staticClass:
                                            "criminal-report__carousel",
                                          staticStyle: { width: "160px" }
                                        },
                                        "VueSlickCarousel",
                                        _vm.settings,
                                        false
                                      ),
                                      _vm._l(elem.images, function(img, index) {
                                        return _c("img", {
                                          key: index,
                                          staticStyle: { "max-width": "160px" },
                                          attrs: { src: img }
                                        })
                                      }),
                                      0
                                    )
                                  : _c("img", {
                                      staticClass: "criminal-report__image",
                                      attrs: { src: "/rc-modern/photo.svg" }
                                    })
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "div",
                                { staticClass: "criminal-report__items" },
                                [
                                  elem.forename
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                ФИО\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(elem.name) +
                                                  " " +
                                                  _vm._s(elem.forename) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  elem.date_of_birth
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Дата рождения\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      elem.date_of_birth,
                                                      "DD.MM.YYYY"
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  elem.place_of_birth
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Место рождения\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(elem.place_of_birth) +
                                                  ", " +
                                                  _vm._s(
                                                    elem.country_of_birth_id
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  elem.nationalities
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Национальность\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            _vm._l(elem.nationalities, function(
                                              nationalities,
                                              index
                                            ) {
                                              return _c(
                                                "span",
                                                { key: index },
                                                [_vm._v(_vm._s(nationalities))]
                                              )
                                            }),
                                            0
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  elem.languages_spoken_ids
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Языки, на которых говорит\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            _vm._l(
                                              elem.languages_spoken_ids,
                                              function(lang, index) {
                                                return _c(
                                                  "span",
                                                  { key: index },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(lang)
                                                    ),
                                                    index <
                                                    elem.languages_spoken_ids
                                                      .length -
                                                      1
                                                      ? _c("span", [
                                                          _vm._v(", ")
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  elem.sex_id
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Пол\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(elem.sex_id) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  elem.height
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Рост\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(elem.height) +
                                                  " см\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  elem.weight
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Вес\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(elem.weight) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  elem.eyes_colors_id
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Цвет глаз\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(elem.eyes_colors_id) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  elem.hairs_id
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Цвет волос\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(elem.hairs_id[0]) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  elem.distinguishing_marks
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Особые приметы\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    elem.distinguishing_marks
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  elem.entity_id
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                ID дела\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(elem.entity_id) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  elem.arrest_warrants &&
                                  elem.arrest_warrants.length
                                    ? _vm._l(elem.arrest_warrants, function(
                                        order
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: order.issuing_country_id,
                                            staticClass:
                                              "d-flex flex-column gap-8"
                                          },
                                          [
                                            order.issuing_country_id
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "criminal-report__item"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "criminal-report__title"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    Инициатор\n                  "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "criminal-report__result"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              order.issuing_country_id
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            order.charge
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "criminal-report__item"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "criminal-report__title"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    Основание для розыска\n                  "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "criminal-report__result"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              order.charge
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      })
                                    : _vm._e()
                                ],
                                2
                              )
                            ])
                          ])
                        ])
                      })
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _vm.task
            ? [
                _c(
                  "div",
                  { staticClass: "report-item-modern__subtitle" },
                  [
                    _vm.resultStatus === "not found"
                      ? [
                          _vm._v(
                            "\n        В базе данных розыска Интерпол не числится\n      "
                          )
                        ]
                      : [
                          _vm._v(
                            "\n        Числится в базе данных розыска Интерпол\n      "
                          )
                        ]
                  ],
                  2
                )
              ]
            : _vm.orderIsDone
            ? [
                _c("span", { staticClass: "text-warning" }, [
                  _vm._v(" Недостаточно данных ")
                ])
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }