






























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportCollapseItem from 'shared/components/FizCheck/ReportCollapseItem.vue';
import AppHiddenText from 'shared/components/ui/HiddenText/HiddenText.vue';
import AppExtendedReportBadgeCollapse from 'shared/components/FizCheck/ExtendedReport/ExtendedReportBadgeCollapse.vue';

@Component({
  components: { AppReportCollapseItem, AppHiddenText, AppExtendedReportBadgeCollapse },
  props: {
    result: Array,
    opened: Boolean,
  },
})
export default class AppExtendedReportItemList extends Vue {
  // props
  result: any;
  opened: boolean;
}
