


















































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import AppWarningImg from 'shared/components/Static/WarningImg.vue';

@Component({
  components: {
    AppReportItem,
    AppWarningImg,
    AppAreaChart: () => import('shared/components/Charts/AreaChart.vue'),
  },
  props: {
    report: Object,
  },
})
export default class AppCounterAgentFinance extends Vue {
  // props
  report: any;
  filterBySum: string = 'ВЫРУЧКА';

  financePeriods: string[] = [
    '2011',
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
    '2022',
    '2023',
  ];

  filters: any[] = [
    {
      name: 'Выручка',
      field: 'ВЫРУЧКА',
      positive: true,
    },
    {
      name: 'Прибыль',
      field: 'ПРИБЫЛЬ',
      positive: true,
    },
    {
      name: 'Осн.средства',
      field: 'ОСНСРЕДСТВА',
      positive: true,
    },
    {
      name: 'Кред.задолженность',
      field: 'КРЕДИТОРЗАДОЛЖН',
      positive: false,
    },
    {
      name: 'Дебит.задолженность',
      field: 'ДЕБИТОРЗАДОЛЖН',
      positive: false,
    },
  ];

  lastYear: number = new Date().getFullYear() - 1;

  get haveFinance() {
    return find(this.finance, (item: any) => {
      return item['ВЫРУЧКА'] ||
      item['ПРИБЫЛЬ'] ||
      item['ОСНСРЕДСТВА'] ||
      item['КРЕДИТОРЗАДОЛЖН'] ||
      item['ДЕБИТОРЗАДОЛЖН'];
    });
  }

  get finance() {
    return map(this.financePeriods, (period: string) => {
      if (this.report['ФО' + period]) {
        this.report['ФО' + period].year = period;
        return this.report['ФО' + period];
      }
      return {};
    });
  }

  get lastPeriod() {
    return this.finance[this.finance.length - 1];
  }

  get bySumChartData() {
    return map(this.finance, (item: any) => {
      return item[this.filterBySum];
    });
  }

  get found() {
    return get(this.report, 'ФондОплТруда', []);
  }

  get startFrom() {
    return parseInt(this.financePeriods[0]);
  }

  get pointFormat() {
    return '<b>{point.y:,.0f} ₽</b><br/>';
  }

  get chartRange() {
    return `Range: ${this.financePeriods[0]} to ${this.financePeriods[this.financePeriods.length - 1]}.`;
  }

  getChangePercent(field: string) {
    const nearPeriod = this.finance[this.finance.length - 2];
    const nearPeriodSum = nearPeriod[field] || 0;
    if (!nearPeriodSum || !this.lastPeriod[field]) {
      return 0;
    }
    return Math.round((this.lastPeriod[field] - nearPeriodSum) / nearPeriodSum * 100);
  }

  getChange(field: string) {
    const nearPeriod = this.finance[this.finance.length - 2];
    return Math.round(this.lastPeriod[field] - nearPeriod[field]);
  }

  // lazyround(num: number) {
  //   return Math.abs(Number(num)) >= 1.0e+9
  //     ? this.round(num / 1.0e+9) + ' млрд. ₽'
  //     : Math.abs(Number(num)) >= 1.0e+6
  //       ? this.round(num / 1.0e+6) + ' млн. ₽'
  //       : Math.abs(Number(num)) >= 1.0e+3
  //         ? this.round(num / 1.0e+3) + ' тыс. ₽'
  //         : Math.abs(Number(num)) + ' ₽';
  // }

  // round(num: number) {
  //   return Math.round(num * Math.pow(10, 1)) / Math.pow(10, 1);
  // }

  haveData(field: string) {
    return find(this.finance, (item: any) => {
      return item[field];
    });
  }
}
