var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pagination-modern" },
    [
      _c(
        "div",
        {
          staticClass: "pagination-modern__item",
          class: { disabled: !_vm.hasPrevPage },
          on: {
            click: function($event) {
              return _vm.changePage(_vm.current - 1)
            }
          }
        },
        [_vm._m(0)]
      ),
      _vm.hasFirst
        ? _c(
            "div",
            {
              staticClass: "pagination-modern__item",
              on: {
                click: function($event) {
                  return _vm.changePage(1)
                }
              }
            },
            [
              _c("span", { staticClass: "pagination-modern__link" }, [
                _vm._v("1")
              ])
            ]
          )
        : _vm._e(),
      _vm.hasFirst
        ? _c("div", { staticClass: "pagination-modern__item disabled" }, [
            _c("div", { staticClass: "pagination-modern__link" }, [
              _vm._v("\n      ...\n    ")
            ])
          ])
        : _vm._e(),
      _vm._l(_vm.pages, function(page) {
        return _c(
          "div",
          {
            key: page,
            staticClass: "pagination-modern__item",
            class: { active: _vm.current == page },
            on: {
              click: function($event) {
                return _vm.changePage(page)
              }
            }
          },
          [
            _c("span", { staticClass: "pagination-modern__link" }, [
              _vm._v(_vm._s(page))
            ])
          ]
        )
      }),
      _vm.hasLast
        ? _c("div", { staticClass: "pagination-modern__item disabled" }, [
            _c("div", { staticClass: "pagination-modern__link" }, [
              _vm._v("\n      ...\n    ")
            ])
          ])
        : _vm._e(),
      _vm.hasLast
        ? _c(
            "div",
            {
              staticClass: "pagination-modern__item",
              on: {
                click: function($event) {
                  return _vm.changePage(_vm.totalPages)
                }
              }
            },
            [
              _c("span", { staticClass: "pagination-modern__link" }, [
                _vm._v(_vm._s(_vm.totalPages))
              ])
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "pagination-modern__item",
          class: { disabled: !_vm.hasNextPage },
          on: {
            click: function($event) {
              return _vm.changePage(_vm.current + 1)
            }
          }
        },
        [_vm._m(1)]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "pagination-modern__link" }, [
      _c("i", { staticClass: "fas fa-chevron-left" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "pagination-modern__link" }, [
      _c("i", { staticClass: "fas fa-chevron-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }