






















































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import AppCounterAgentReportPreview from '@/views/Admin/AdminUserInfo/CounterAgent/CounterAgentPdfPreview.vue';
import { ObjectTypes } from 'shared/store/modules/objectDetail/types';

import AppRiskUpdateScraperStatusDialog from '@/views/Admin/AdminUserInfo/RiskReportV2/RiskUpdateScraperStatus.vue';

@Component({
  components: { },
  props: {
    userID: String,
    orderId: String,
    orderItemID: String,
  },
  computed: {
    ...mapState('objectDetail', [ 'riskReportV2' ]),
    ...mapState('advert', [
      'priceChangeHouse',
      'priceChangeArea',
    ]),
  },
})
export default class AppAdminCounterAgent extends Vue {
  // store
  priceChangeHouse: AveragePriceData;
  priceChangeArea: AveragePriceData;

  // props
  userID!: string;
  orderId!: string;
  orderItemID!: string;

  // data()
  loading: boolean = true;
  reportSending: boolean = false;
  orderItem: any = null;
  report: any = null;
  kadastrNumber: string = '';
  objectInfo: any = null;
  attachments: AttachmentItem[] = [];
  owners: LawInformationOwner[] = [];
  order: any = null;
  info: any = null;
  reportV2: LawInformation;
  riskReportV2: LawInformation;

  async created() {
    await this.fetchOrder();
  }

  isProduct(name: string) {
    return this.report && this.report.product_name === name;
  }

  async fetchOrder() {
    await this.$store.dispatch(ObjectTypes.actions.GET_RISK_REPORT_V2, this.orderItemID);
    this.report = this.riskReportV2;
    console.log('getRiskRiskReportV2', this.report);
    // const orders = await this.$api.risk.GetOrders({
    //   task_ids: [
    //     this.orderItemID,
    //   ],
    // });
    // console.log(orders);
    // if (!orders.length) {
    //   return null;
    // }
    // this.report = orders[0];
  }

  goToOrder() {
    this.$router.push({
      name: 'admin-order-detail',
      params: {
        orderID: this.orderId,
        userID: this.userID,
      },
    });
  }

  getTask(name: string) {
    if (this.report && this.report.tasks.length) {
      return this.report.tasks.find((elem: any) => {
        return elem.task_type === name;
      });
    }
  }

  async openCreditRatingModal() {
    this.$dialog.open({
      component: AppCounterAgentReportPreview,
      props: {
        report: this.report,
        fetchReport: (): any => null,
        demo: false,
        download: (): any => null,
        allowPdfDownload: false,
      },
    });
  }

  showUpdateStatus() {
    this.$dialog.open({
      component: AppRiskUpdateScraperStatusDialog,
      props: {
        report: this.report.owners[0],
        orderItemId: this.orderItemID,
      },
    });
  }
}
