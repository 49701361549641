var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "admin-page-head" },
        [
          _c(
            "app-button",
            {
              attrs: { size: "sm" },
              on: { click: _vm.showAddComponentDialog }
            },
            [
              _c("i", { staticClass: "fas fa-plus fa-xs mr-1" }),
              _vm._v(" Добавить прайс компонент\n    ")
            ]
          ),
          _c(
            "app-button",
            {
              staticClass: "ml-2",
              attrs: { size: "sm" },
              on: { click: _vm.activateTariff }
            },
            [
              _c("i", { staticClass: "fas fa-plus fa-xs mr-1" }),
              _vm._v(" Активировать тариф\n    ")
            ]
          ),
          _vm.currentTariff
            ? _c("div", { staticClass: "mt-3" }, [
                _c(
                  "div",
                  [
                    _vm._v("\n        Текущий тарифный план: "),
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm.currentTariff.tariff_simple_id === 0
                            ? "Базовый"
                            : _vm.currentTariff.tariff_name
                        )
                      )
                    ]),
                    _vm.currentTariff.tariff_simple_id > 0
                      ? [
                          _c(
                            "app-button",
                            {
                              staticClass: "mx-3",
                              attrs: { size: "xs", variant: "light" },
                              on: { click: _vm.editTariff }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-pencil-alt fa-xs"
                              })
                            ]
                          ),
                          _c(
                            "app-button",
                            {
                              attrs: { size: "xs", variant: "danger-light" },
                              on: { click: _vm.deleteTariff }
                            },
                            [_c("i", { staticClass: "far fa-trash-alt fa-xs" })]
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _vm.currentTariff.tariff_simple_id > 0
                  ? _c("div", [
                      _vm._v("\n        Срок действия:\n        "),
                      _c("b", [
                        _vm._v(
                          _vm._s(
                            _vm._f("date")(
                              _vm.currentTariff.time_start,
                              "DD.MM.YYYY HH:mm"
                            )
                          ) +
                            " -\n          " +
                            _vm._s(
                              _vm._f("date")(
                                _vm.currentTariff.time_end,
                                "DD.MM.YYYY HH:mm"
                              )
                            )
                        )
                      ])
                    ])
                  : _vm._e()
              ])
            : _c("div", { staticClass: "text-danger" }, [
                _vm._v("\n      Не удалось получить текущий тариф\n    ")
              ])
        ],
        1
      ),
      _vm.items
        ? _c("app-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              loading: _vm.loading
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c("td", [
                        _vm._v("\n        " + _vm._s(row.name) + "\n      ")
                      ]),
                      _c("td", [
                        _vm._v(
                          "\n        " +
                            _vm._s(row.enabled ? "Да" : "Нет") +
                            "\n      "
                        )
                      ]),
                      _c("td", [
                        _vm._v(
                          "\n        " + _vm._s(row.count_current) + "\n      "
                        )
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("date")(row.from_date, "DD.MM.YYYY"))
                        )
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("date")(row.thru_date, "DD.MM.YYYY"))
                        )
                      ]),
                      _c(
                        "td",
                        [
                          _c(
                            "app-button",
                            {
                              staticClass: "mr-2",
                              attrs: { size: "xs", variant: "light" },
                              on: {
                                click: function($event) {
                                  return _vm.showEditDialog(row)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-pencil-alt fa-xs"
                              })
                            ]
                          ),
                          _c(
                            "app-button",
                            {
                              attrs: { size: "xs", variant: "danger-light" },
                              on: {
                                click: function($event) {
                                  return _vm.destroyItem(row)
                                }
                              }
                            },
                            [_c("i", { staticClass: "far fa-trash-alt fa-xs" })]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              1886562533
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }