var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "conclusion-item row no-gutters" },
    [
      _vm.statusColor
        ? _c("div", { staticClass: "col-auto mr-3" }, [
            _c("span", [
              _c("i", {
                staticClass: "fas fa-fw",
                class: [
                  _vm.statusColor,
                  {
                    "fa-check-circle": _vm.conclusion.color_level === "check",
                    "fa-info-circle": _vm.conclusion.color_level === "info",
                    "fa-circle fa-xs":
                      _vm.conclusion.color_level !== "check" &&
                      _vm.conclusion.color_level !== "info"
                  }
                ]
              })
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "conclusion-item__body col" }, [
        _vm.conclusion.text
          ? _c("div", { staticClass: "conclusion-item__text" }, [
              _c("span", {
                staticStyle: { "white-space": "pre-line" },
                domProps: { innerHTML: _vm._s(_vm.conclusion.text) }
              })
            ])
          : _vm._e()
      ]),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }