var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sud-container" }, [
    _c("div", { staticClass: "sud-items" }, [
      _c(
        "div",
        { staticClass: "popup__header" },
        [
          _c("div", { staticClass: "popup__title" }, [
            _vm._v("\n        Данные о розыске\n      ")
          ]),
          _c("app-dialog-close")
        ],
        1
      ),
      _c("div", { staticClass: "popup__body" }, [
        _vm.result
          ? _c(
              "div",
              { staticClass: "sud-details__content legacy-container" },
              [
                _c(
                  "div",
                  { staticClass: "row mb-3 no-gutters align-items-center" },
                  [
                    _vm.result.image
                      ? _c("div", { staticClass: "col-auto mr-2" }, [
                          _c("div", { staticClass: "sud-details-item" }, [
                            _c("img", {
                              attrs: { src: _vm.result.image, width: "100px" }
                            })
                          ])
                        ])
                      : _vm._e()
                  ]
                ),
                _vm.result.input
                  ? [
                      _vm.result.input.surname
                        ? _c("div", { staticClass: "sud-details-item" }, [
                            _c(
                              "div",
                              { staticClass: "sud-details-item__title" },
                              [_vm._v("\n              Фамилия\n            ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "sud-details-item__value" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.result.input.surname) +
                                    "\n            "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm.result.input.first
                        ? _c("div", { staticClass: "sud-details-item" }, [
                            _c(
                              "div",
                              { staticClass: "sud-details-item__title" },
                              [_vm._v("\n              Имя\n            ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "sud-details-item__value" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.result.input.first) +
                                    "\n            "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm.result.input.patronymic
                        ? _c("div", { staticClass: "sud-details-item" }, [
                            _c(
                              "div",
                              { staticClass: "sud-details-item__title" },
                              [_vm._v("\n              Отчество\n            ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "sud-details-item__value" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.result.input.patronymic) +
                                    "\n            "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm.result.input.birthday
                        ? _c("div", { staticClass: "sud-details-item" }, [
                            _c(
                              "div",
                              { staticClass: "sud-details-item__title" },
                              [
                                _vm._v(
                                  "\n              Дата рождения\n            "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "sud-details-item__value" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("date")(
                                        _vm.result.input.birthday,
                                        "DD.MM.YYYY"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm.result.input.inn
                        ? _c("div", { staticClass: "sud-details-item" }, [
                            _c(
                              "div",
                              { staticClass: "sud-details-item__title" },
                              [_vm._v("\n              ИНН\n            ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "sud-details-item__value" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.result.input.inn) +
                                    "\n            "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm.result.input.region
                        ? _c("div", { staticClass: "sud-details-item" }, [
                            _c(
                              "div",
                              { staticClass: "sud-details-item__title" },
                              [_vm._v("\n              Регион\n            ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "sud-details-item__value" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.regionName(_vm.result.input.region)
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm.result.result.datamail &&
                      _vm.result.result.datamail.length
                        ? _c("div", { staticClass: "sud-details-item" }, [
                            _c(
                              "div",
                              { staticClass: "sud-details-item__title" },
                              [
                                _vm._v(
                                  "\n              Дополнительная информация\n            "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "sud-details-item__value" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.result.result.datamail[0].data) +
                                    "\n            "
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ]
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }