var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container container_responsive stats-container" },
    [
      _vm._m(0),
      _c("div", { staticClass: "page-wrapper tabs-stats" }, [
        _c("ul", { staticClass: "nav nav-tabs nav-tabs_underline" }, [
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "span",
              {
                class: ["nav-link", { active: _vm.tab === "graphic" }],
                on: {
                  click: function($event) {
                    _vm.tab = "graphic"
                  }
                }
              },
              [_vm._v("\n          Графики\n        ")]
            )
          ]),
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "span",
              {
                class: ["nav-link", { active: _vm.tab === "table" }],
                on: {
                  click: function($event) {
                    _vm.tab = "table"
                  }
                }
              },
              [_vm._v("\n          Таблицы\n        ")]
            )
          ]),
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "span",
              {
                class: [
                  "nav-link",
                  { active: _vm.tab === "clientsWithTariffes" }
                ],
                on: {
                  click: function($event) {
                    _vm.tab = "clientsWithTariffes"
                  }
                }
              },
              [_vm._v("\n          Клиенты\n        ")]
            )
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "tabs-stats__filter text-right",
            on: { click: _vm.showDateFilter }
          },
          [
            _vm.tab !== "clientsWithTariffes"
              ? [
                  _c("div", { staticClass: "text-primary" }, [
                    _vm._v("\n          Фильтр по дате\n        ")
                  ]),
                  _c("div", { staticClass: "text-muted small" }, [
                    _vm._v(
                      "\n          от " +
                        _vm._s(_vm._f("date")(_vm.date.from, "DD.MM.YYYY")) +
                        " до\n          " +
                        _vm._s(_vm._f("date")(_vm.date.to, "DD.MM.YYYY")) +
                        "\n        "
                    )
                  ])
                ]
              : _vm._e()
          ],
          2
        )
      ]),
      _vm.tab === "graphic"
        ? [
            _c("AppLineGraphic", { attrs: { date: _vm.date } }),
            _c(
              "div",
              { staticClass: "row", class: { "stats-loading": _vm.loading } },
              [
                _c(
                  "div",
                  { staticClass: "col-md-4 mb-3 pr-md-2" },
                  [
                    _vm.stats.order_item_statuses
                      ? _c("app-stats-chart", {
                          attrs: {
                            title: "Заказы",
                            items: _vm.stats.order_item_statuses.items,
                            total: _vm.stats.order_item_statuses.total_count,
                            "label-map-callback": _vm.ordersMapLabels,
                            "color-map-callback": _vm.ordersMapColors
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-4 mb-3 px-md-2" },
                  [
                    _vm.stats.user_roles
                      ? _c("app-stats-chart", {
                          attrs: {
                            title: "Роли",
                            items: _vm.stats.user_roles.items,
                            total: _vm.stats.user_roles.total_count,
                            "label-map-callback": _vm.rolesMapLabel
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-4 mb-3 pl-md-2" },
                  [
                    _vm.stats.user_source
                      ? _c("app-stats-chart", {
                          attrs: {
                            title: "Источники",
                            items: _vm.stats.user_source.items,
                            total: _vm.stats.user_source.total_count
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ]
        : _vm._e(),
      _vm.tab === "table"
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6 pr-md-2" },
              [
                _c("AppStatsDefaultTable", {
                  attrs: {
                    title: "Статусы",
                    items: _vm.stats.sum_statuses,
                    headers: [{ text: "Статус" }].concat(_vm.defaultHeaders),
                    loading: _vm.loading
                  }
                }),
                _c("AppStatsDefaultTable", {
                  attrs: {
                    title: "Роли",
                    items: _vm.stats.sum_roles,
                    headers: [{ text: "Роль" }].concat(_vm.defaultHeaders),
                    loading: _vm.loading
                  }
                }),
                _c(
                  "div",
                  { staticClass: "page-wrapper mb-3" },
                  [
                    _c("h6", { staticClass: "m-0 pb-0 admin-page-head" }, [
                      _vm._v("\n          Sum\n        ")
                    ]),
                    _c("app-data-table", {
                      attrs: { items: _vm.sumGroupItems, loading: _vm.loading },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _c("td", [_vm._v(_vm._s(row.title))]),
                                _c("td", [_vm._v(_vm._s(row.value))])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        444994809
                      )
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "page-wrapper mb-3" },
                  [
                    _c("h6", { staticClass: "m-0 pb-0 admin-page-head" }, [
                      _vm._v("\n          Server\n        ")
                    ]),
                    _c("app-data-table", {
                      attrs: {
                        items: _vm.serverGroupItems,
                        loading: _vm.loading
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _c("td", [_vm._v(_vm._s(row.title))]),
                                _c("td", [_vm._v(_vm._s(row.value))])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        444994809
                      )
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "page-wrapper mb-3" },
                  [
                    _c("h6", { staticClass: "m-0 pb-0 admin-page-head" }, [
                      _vm._v("\n          Users\n        ")
                    ]),
                    _c("app-data-table", {
                      attrs: {
                        items: _vm.usersGroupItems,
                        loading: _vm.loading
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _c("td", [_vm._v(_vm._s(row.title))]),
                                _c("td", [_vm._v(_vm._s(row.value))])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        444994809
                      )
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "page-wrapper mb-3" },
                  [
                    _c("h6", { staticClass: "m-0 pb-0 admin-page-head" }, [
                      _vm._v("\n          Top Donators\n        ")
                    ]),
                    _c("app-data-table", {
                      attrs: { items: _vm.donatorsLimit, loading: _vm.loading },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              var index = ref.index
                              return [
                                _c("td", [
                                  _c("div", { staticClass: "form-row" }, [
                                    _c("div", { staticClass: "col-auto" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(index + 1) +
                                          ".\n                "
                                      )
                                    ]),
                                    _c("div", { staticClass: "col" }, [
                                      _c(
                                        "div",
                                        { staticClass: "text-truncate" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(row.email) +
                                              "\n                  "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "small text-muted text-truncate"
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.roles[row.person_role_id]
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm._f("currency")(row.sum)))
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2773874017
                      )
                    }),
                    _c(
                      "div",
                      { staticClass: "px-2" },
                      [
                        _c(
                          "app-button",
                          {
                            attrs: { variant: "link" },
                            on: {
                              click: function($event) {
                                _vm.showAllDonators = !_vm.showAllDonators
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.showAllDonators
                                    ? "Скрыть"
                                    : "Показать полностью"
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "page-wrapper mb-3" },
                  [
                    _c("h6", { staticClass: "m-0 pb-0 admin-page-head" }, [
                      _vm._v("\n          Top Donators Responsible\n        ")
                    ]),
                    _c("app-data-table", {
                      attrs: {
                        items: _vm.donatorsResponsible,
                        loading: _vm.loading
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              var index = ref.index
                              return [
                                _c("td", [
                                  _c("div", { staticClass: "form-row" }, [
                                    _c("div", { staticClass: "col-auto" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(index + 1) +
                                          ".\n                "
                                      )
                                    ]),
                                    _c("div", { staticClass: "col" }, [
                                      _c(
                                        "div",
                                        { staticClass: "text-truncate" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(row.email) +
                                              "\n                  "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "small text-muted text-truncate"
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.roles[row.person_role_id]
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(_vm._f("currency")(row.sum)))
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2773874017
                      )
                    }),
                    _c(
                      "div",
                      { staticClass: "px-2" },
                      [
                        _c(
                          "app-button",
                          {
                            attrs: { variant: "link" },
                            on: {
                              click: function($event) {
                                _vm.showAllDonatorsResponsible = !_vm.showAllDonatorsResponsible
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.showAllDonatorsResponsible
                                    ? "Скрыть"
                                    : "Показать полностью"
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6 pl-md-2" },
              [
                _c("AppStatsDefaultTable", {
                  attrs: {
                    title: "Продукты",
                    items: _vm.stats.sum_products,
                    headers: [{ text: "Название" }].concat(_vm.defaultHeaders),
                    loading: _vm.loading
                  }
                }),
                _c("AppStatsDefaultTable", {
                  attrs: {
                    title: "Источники",
                    items: _vm.stats.sum_sources,
                    headers: [{ text: "Роль" }].concat(_vm.defaultHeaders),
                    loading: _vm.loading
                  }
                }),
                _c(
                  "div",
                  { staticClass: "page-wrapper mb-3" },
                  [
                    _c("h6", { staticClass: "m-0 pb-0 admin-page-head" }, [
                      _vm._v("\n          Сроки выполнения\n        ")
                    ]),
                    _c("app-data-table", {
                      attrs: {
                        items: _vm.stats.orders_execution_time_avg,
                        loading: _vm.loading
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _c("td", [_vm._v(_vm._s(row.product_name))]),
                                _c("td", [_vm._v(_vm._s(row.avg) + " мин.")])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1181411853
                      )
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "page-wrapper mb-3" },
                  [
                    _c("h6", { staticClass: "m-0 pb-0 admin-page-head" }, [
                      _vm._v("\n          Orders\n        ")
                    ]),
                    _c("app-data-table", {
                      attrs: {
                        items: _vm.ordersGroupItems,
                        loading: _vm.loading
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _c("td", [_vm._v(_vm._s(row.title))]),
                                _c("td", [_vm._v(_vm._s(row.value))])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        444994809
                      )
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "page-wrapper mb-3" },
                  [
                    _c("h6", { staticClass: "m-0 pb-0 admin-page-head" }, [
                      _vm._v("\n          Api\n        ")
                    ]),
                    _c("app-data-table", {
                      attrs: { items: _vm.apiGroupItems, loading: _vm.loading },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _c("td", [_vm._v(_vm._s(row.title))]),
                                _c("td", [_vm._v(_vm._s(row.value))])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        444994809
                      )
                    })
                  ],
                  1
                ),
                _c("app-partner-sum-stats", { attrs: { date: _vm.date } })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.tab === "clientsWithTariffes"
        ? _c("div", [_c("AppClientsWithTariffesTable")], 1)
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "admin-page-head" }, [
      _c("h5", [_vm._v("Статистика")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }