



































































import Vue from 'vue';
export default Vue.extend({
  name: 'AppPagination',

  props: {
    current: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 10,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    pageRange: {
      type: Number,
      default: 2,
    },
  },

  computed: {
    pages(): number[] {
      const pages = [];

      for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i);
      }

      return pages;
    },

    hasFirst(): boolean {
      return this.rangeStart !== 1;
    },

    hasLast(): boolean {
      return this.rangeEnd !== this.totalPages;
    },

    totalPages(): number {
      return Math.ceil(this.total / this.perPage);
    },

    rangeStart(): number {
      const start = this.current - this.pageRange;

      return start > 0 ? start : 1;
    },

    rangeEnd(): number {
      const end = this.current + this.pageRange;

      return end < this.totalPages ? end : this.totalPages;
    },

    hasNextPage(): boolean {
      return this.current < this.totalPages;
    },

    hasPrevPage(): boolean {
      return this.current > 1;
    },
  },

  methods: {
    changePage(page: number): void {
      if (
        page === this.current ||
        (page < this.current && !this.hasPrevPage) ||
        (page > this.current && !this.hasNextPage)
      ) {
        return;
      }

      this.$emit('page-changed', page);
    },
  },
});
