var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-report-item",
    {
      attrs: {
        title: "Лицензии",
        icon: "stamp",
        opened: true,
        disabled: "",
        status: "done",
        color: "success"
      }
    },
    [
      _c(
        "div",
        { staticClass: "report-item-modern__subtitle" },
        [
          _vm.license
            ? [
                _vm._v(
                  "\n      Найдено лицензий: " + _vm._s(_vm.license) + "\n    "
                )
              ]
            : [_vm._v("\n      Лицензий не найдено\n    ")]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }