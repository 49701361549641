var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c("app-estimate", {
        attrs: {
          info: _vm.info,
          "price-change-house": _vm.priceChangeHouse,
          "price-change-area": _vm.priceChangeArea,
          rated: "",
          "is-pdf": ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }