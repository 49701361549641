
























import Vue from 'vue';
import Component from 'vue-class-component';

import AppRightListReport from 'shared/components/EgrnObject/RightListReport.vue';

@Component({
  popupOptions: {
    name: 'popup_full',
  },

  components: { AppRightListReport },

  props: {
    landCadInfo: Object,
    objectInfo: Object,
    objectKey: String,
    statements: Object,
    report: Object,
  },
})
export default class RightListPdfPreview extends Vue {
  print() {
    window.print();
  }

  fetchRisk() {
    return true;
  }
}
