var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPhysical
    ? _c(
        "app-report-item",
        {
          attrs: {
            title: "Руководитель или учредитель юридического лица",
            icon: "user-tie",
            images: _vm.images,
            color: _vm.map.color,
            status: _vm.status,
            opened: _vm.resultStatus === "found",
            "missing-requirements": _vm.missingRequirements
          },
          scopedSlots: _vm._u(
            [
              _vm.resultStatus === "found"
                ? {
                    key: "extra-content",
                    fn: function() {
                      return [
                        _c("app-founder-report-details", {
                          attrs: {
                            result: _vm.result,
                            date: _vm.task.in_progress_started_time
                          }
                        }),
                        _vm.slicedResult.length < _vm.result.length &&
                        _vm.$isServer
                          ? _c(
                              "div",
                              { staticClass: "rc-modern-info-panel mt-3" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "rc-modern-info-panel__wrapper"
                                  },
                                  [
                                    _c("div", [_c("app-warning-img")], 1),
                                    _c("div", [
                                      _vm._v(
                                        "\n          Отображено " +
                                          _vm._s(_vm.slicedResult.length) +
                                          " дел из " +
                                          _vm._s(_vm.result.length) +
                                          ", полная информация доступна в веб версии отчета.\n        "
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _vm.task
            ? _c(
                "div",
                { staticClass: "report-item-modern__subtitle-wrapper" },
                [
                  _vm.resultStatus === "found"
                    ? [
                        _c(
                          "div",
                          { staticClass: "report-item-modern__subtitle" },
                          [
                            _vm._v(
                              "\n        Физическое лицо зарегистрировано в качестве учредителя и/или руководителя в организации\n      "
                            )
                          ]
                        )
                      ]
                    : [
                        _c(
                          "div",
                          { staticClass: "report-item-modern__subtitle" },
                          [
                            _vm._v(
                              "\n        Среди руководителей и учредителей не значится\n      "
                            )
                          ]
                        )
                      ]
                ],
                2
              )
            : _vm.orderIsDone
            ? [
                _c("span", { staticClass: "text-warning" }, [
                  _vm._v(" Недостаточно данных ")
                ])
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }