






























































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import AppSudActDetails from 'shared/components/FizCheck/Details/SudDetails/SudActDetails.vue';
import AppMosgorsudDetails from 'shared/components/FizCheck/Details/SudDetails/MosgorsudDetails.vue';
// import AppSudRfDetails from 'shared/components/FizCheck/Details/SudDetails/SudRfDetails.vue';
import AppSudRfDetails from 'shared/components/FizCheck/Details/SudDetails/SudRfDetailsAlt.vue';
import AppWarningImg from 'shared/components/Static/WarningImg.vue';
import { getRegionNameById } from 'shared/utils/regions';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

@Component({
  components: { AppReportItem, AppSudActDetails, AppMosgorsudDetails, AppSudRfDetails, AppWarningImg },
  props: {
    report: Object,
    status: String,
    images: Array,
    orderIsDone: Boolean,
    missingRequirements: Array,
    openAddFieldDialog: Function,
    updateMap: Function,
    task: Object,
    isPhysical: Boolean,
    onlyOwners: Boolean,
    map: Object,
    isPdf: Boolean,
  },
})
export default class AppSudReportModern extends Vue {
  // props
  missingRequirements: any[];
  report: any;
  status: string;
  images: any[];
  orderIsDone: boolean;
  task: any;
  isPhysical: boolean;
  onlyOwners: boolean;
  openAddFieldDialog: (val: string) => any;
  updateMap: (taskName: string, field: string, value: any) => void;
  map: any;
  currentFilter: string = 'all';
  opened: boolean = false;
  currentPage: number = 1;
  isPdf: boolean;
  resultStatusTrace: any = null;

  created() {
    this.resultStatusTrace = this.resultStatus;
  }

  get sudRf() {
    // return get(this.task, 'task_result.searchResult.documents', []);
    return get(this.task, 'task_result.result.data', []);
  }

  get statusDone() {
    return this.status === 'done';
  }

  get resultStatus() {
    if (this.task && this.task.task_result && this.statusDone) {
      if ((this.sudRf && this.sudRf.length) ||
      this.haveMosgorsud || this.haveSudact) {
        this.updateColor('danger');
        return 'found';
      } else {
        this.updateColor('success');
        return 'not found';
      }
    }
    this.updateColor('info');
    return 'no data';
  }

  get mosgorsud(): any {
    return this.getTask('mosgorsud');
  }

  get sudact(): any {
    return this.getTask('sudact');
  }

  get regionName(): string {
    return getRegionNameById(this.report.owner.region);
  }

  get naming(): string {
    if (this.isPhysical) {
      return 'фамилии и инициалам';
    } else {
      return 'наименованию юридического лица';
    }
  }

  get haveMosgorsud(): boolean {
    return this.mosgorsud && this.mosgorsud.task_result && this.mosgorsud.task_result.answer && this.mosgorsud.task_result.answer.length;
  }

  get haveSudact(): boolean {
    return this.sudact &&
    this.sudact.task_result &&
    this.sudact.task_result.result &&
    (!isEmpty(this.sudact.task_result.result.magistrate) || !isEmpty(this.sudact.task_result.result.regular) || !isEmpty(this.sudact.task_result.result.vsrf));
  }

  get sudactCases(): any[] {
    if (!this.haveSudact) {
      return [];
    }
    const cases = [].concat(this.sudact.task_result.result.magistrate, this.sudact.task_result.result.regular, this.sudact.task_result.result.vsrf);
    return cases;
  }

  get mosgorsudCases(): any[] {
    return get(this.mosgorsud, 'task_result.answer', []);
  }

  get casesCount(): number {
    let res = 0;
    if (this.sudRf && this.sudRf.length) {
      res += this.sudRf.length;
    }
    if (this.haveMosgorsud) {
      res += this.mosgorsud.task_result.answer.length;
    }
    if (this.haveSudact) {
      res += this.sudactCases.length;
    }
    return res;
  }

  get slicedCases() {
    const cases: any = {
      sudRf: this.sudRf.slice(0, 20),
      mosgorsud: this.mosgorsudCases.slice(0, 20),
      sudact: this.sudactCases.slice(0, 20),
    };
    cases.count = cases.sudRf.length + cases.mosgorsud.length + cases.sudact.length;
    return cases;
  }

  checkFilter(value: string) {
    return [ 'all', value ].includes(this.currentFilter);
  }

  updateColor(color: string) {
    this.updateMap('judicialActs', 'color', color);
  }

  getTask(val: string) {
    return this.report.tasks.find((elem: any) => elem.task_type === val);
  }

  pageChanged(page: number) {
    this.currentPage = page;
  }
}
