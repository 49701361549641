




























































































import Component, { mixins } from 'vue-class-component';
import { format } from 'date-fns';
import locale from 'date-fns/locale/ru';
import { mapState } from 'vuex';
import { isEmpty } from 'lodash';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import DataTableMixin from '@/core/mixins/data-table.mixin';
import HelpersMixin from '@/core/mixins/helpers.mixin';

  @Component({
    computed: {
      ...mapState('admin', [ 'user' ]),
    },
    components: {
    },
  })
export default class AdminUserEmailsSendpulse extends mixins(DataTableMixin, HelpersMixin) {
  // types
  user: User;
  pagination: TablePagination = {
    page: 1,
    limit: 20,
    total: 40,
  };
  headers: TableHeaderItem[] = [
    {
      text: 'Дата',
      sortProp: 'created_at',
      width: '170px',
    },
    {
      text: 'Отправитель / Получатель',
      width: '220px',
    },
    {
      text: 'Тема',
      sortProp: 'subject',
    },
    {
      text: 'Статус',
      width: '170px',
    },
  ];

  async handleRequest(offset: number, limit: number) {
    const data = await this.$api.admin.Emails('', { recipient: this.user.email, offset, limit });
    const combinedEmails = this.combineEmails(data);
    return Array.isArray(combinedEmails) ? combinedEmails : [];
  }

  hasTracking(tracking: object) {
    return !isEmpty(tracking);
  }

  combineEmails(data: any[]) {
    const items: any = {};

    data.forEach(item => {
      const key = `${item.job_id}-${item.subject}`;
      const { status, event_time, ip, user_agent, url, ...rest } = item;

      if (!items[key]) {
        items[key] = {
          ...rest,
          status_list: [],
          created_at: moment(event_time).add(3, 'hours').toISOString(),
          isFull: false,
        };
      }

      items[key].status_list.push({
        status: status,
        event_time: moment(event_time).add(3, 'hours').toISOString(),
        ip: ip,
        user_agent: user_agent,
        url: url,
      });
    });

    return orderBy(items, [ 'created_at' ], [ 'desc' ]);
  }

  // метод специально для Safari, т.к. в нём при применении обычного фильтра выходит Invalid date
  getDate(date: string, formatDate: string) {
    return format(new Date(date.split(' ').join('T')), formatDate, { locale });
  }
}

