var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "extended-report-panel" }, [
    _vm._m(0),
    _c("div", {}, [
      _c("div", { staticClass: "report-item-result__graph-filter-radio" }, [
        _c(
          "div",
          {
            staticClass: "report-item-result__graph-filter-radio-btn",
            attrs: {
              active: _vm.currentFilter === "person",
              disabled: !_vm.users.length
            },
            on: {
              click: function($event) {
                return _vm.filterSelect("person")
              }
            }
          },
          [_vm._v("\n        Лица (" + _vm._s(_vm.users.length) + ")\n      ")]
        ),
        _c(
          "div",
          {
            staticClass: "report-item-result__graph-filter-radio-btn",
            attrs: {
              active: _vm.currentFilter === "passport",
              disabled: !_vm.get(_vm.allData, "documents.passport.length")
            },
            on: {
              click: function($event) {
                return _vm.filterSelect("passport")
              }
            }
          },
          [
            _vm._v(
              "\n        Паспорта (" +
                _vm._s(_vm.get(_vm.allData, "documents.passport.length", 0)) +
                ")\n      "
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "report-item-result__graph-filter-radio-btn",
            attrs: {
              active: _vm.currentFilter === "phone",
              disabled: !_vm.get(_vm.allData, "list_phones.length")
            },
            on: {
              click: function($event) {
                return _vm.filterSelect("phone")
              }
            }
          },
          [
            _vm._v(
              "\n        Телефоны (" +
                _vm._s(_vm.get(_vm.allData, "list_phones.length", 0)) +
                ")\n      "
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "report-item-result__graph-filter-radio-btn",
            attrs: {
              active: _vm.currentFilter === "auto",
              disabled: !_vm.get(_vm.allData, "documents.auto.length")
            },
            on: {
              click: function($event) {
                return _vm.filterSelect("auto")
              }
            }
          },
          [
            _vm._v(
              "\n        Автомобили (" +
                _vm._s(_vm.get(_vm.allData, "documents.auto.length", 0)) +
                ")\n      "
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "report-item-result__graph-filter-radio-btn",
            attrs: {
              active: _vm.currentFilter === "snils",
              disabled: !_vm.get(_vm.allData, "documents.snils.length")
            },
            on: {
              click: function($event) {
                return _vm.filterSelect("snils")
              }
            }
          },
          [
            _vm._v(
              "\n        СНИЛС (" +
                _vm._s(_vm.get(_vm.allData, "documents.snils.length", 0)) +
                ")\n      "
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "report-item-result__graph-filter-radio-btn",
            attrs: {
              active: _vm.currentFilter === "inn",
              disabled: !_vm.get(_vm.allData, "documents.inn.length")
            },
            on: {
              click: function($event) {
                return _vm.filterSelect("inn")
              }
            }
          },
          [
            _vm._v(
              "\n        ИНН (" +
                _vm._s(_vm.get(_vm.allData, "documents.inn.length", 0)) +
                ")\n      "
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "report-item-result__graph-filter-radio-btn",
            attrs: {
              active: _vm.currentFilter === "email",
              disabled: !_vm.get(_vm.allData, "list_emails.length")
            },
            on: {
              click: function($event) {
                return _vm.filterSelect("email")
              }
            }
          },
          [
            _vm._v(
              "\n        Email (" +
                _vm._s(_vm.get(_vm.allData, "list_emails.length", 0)) +
                ")\n      "
            )
          ]
        )
      ]),
      _vm.currentFilter === "person"
        ? _c(
            "div",
            { staticClass: "extended-report-summary" },
            [
              _vm.personFilter
                ? [
                    _c(
                      "div",
                      { staticClass: "extended-report-summary__selected-data" },
                      [
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.personFilter) +
                              "\n          "
                          )
                        ]),
                        _c("i", {
                          staticClass: "fas fa-times text-muted cursor-pointer",
                          on: {
                            click: function($event) {
                              return _vm.personSelect("", 0)
                            }
                          }
                        })
                      ]
                    ),
                    _c("div", [
                      _vm._v(
                        "\n          Найдены следующие данные, относящиеся к этому лицу\n        "
                      )
                    ]),
                    _c("app-fizcheck-expert-summary-result", {
                      attrs: {
                        "current-filter": _vm.currentFilter,
                        "data-filter": _vm.dataFilter,
                        "person-filter": _vm.personFilter,
                        "all-data": _vm.allData,
                        users: _vm.users,
                        "finded-users": _vm.findedUsers,
                        "finded-users-data": _vm.findedUsersData,
                        "related-data": _vm.relatedData
                      },
                      on: {
                        filterSelect: _vm.filterSelect,
                        personSelect: _vm.personSelect,
                        dataSelect: _vm.dataSelect
                      }
                    })
                  ]
                : _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.sortBy(_vm.users, "fio"), function(item, i) {
                      return _c(
                        "div",
                        {
                          key: item.fio + i,
                          staticClass:
                            "col-12 col-md-6 cursor-pointer mb-2 extended-report-summary__hover"
                        },
                        [
                          _c(
                            "div",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.personSelect(item.fio, i)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.fio) +
                                  "\n          "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "extended-report-summary__search",
                              on: {
                                click: function($event) {
                                  return _vm.searchPerson(item.fio)
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-search" })]
                          )
                        ]
                      )
                    }),
                    0
                  )
            ],
            2
          )
        : _vm._e(),
      _vm.currentFilter === "passport"
        ? _c(
            "div",
            { staticClass: "extended-report-summary" },
            [
              _vm.dataFilter
                ? [
                    _c(
                      "div",
                      { staticClass: "extended-report-summary__selected-data" },
                      [
                        _c("div", [
                          _vm._v(
                            "\n            Паспорт №" +
                              _vm._s(_vm.dataFilter) +
                              "\n          "
                          )
                        ]),
                        _c("i", {
                          staticClass: "fas fa-times text-muted cursor-pointer",
                          on: {
                            click: function($event) {
                              return _vm.dataSelect("")
                            }
                          }
                        })
                      ]
                    ),
                    _c("div", [
                      _vm._v(
                        "\n          Найдены следующие данные, относящиеся к этому номеру паспорта\n        "
                      )
                    ]),
                    _c("app-fizcheck-expert-summary-result", {
                      attrs: {
                        "current-filter": _vm.currentFilter,
                        "data-filter": _vm.dataFilter,
                        "person-filter": _vm.personFilter,
                        "all-data": _vm.allData,
                        users: _vm.users,
                        "finded-users": _vm.findedUsers,
                        "finded-users-data": _vm.findedUsersData,
                        "related-data": _vm.relatedData
                      },
                      on: {
                        filterSelect: _vm.filterSelect,
                        personSelect: _vm.personSelect,
                        dataSelect: _vm.dataSelect
                      }
                    })
                  ]
                : _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(
                      _vm.sortBy(_vm.allData.documents.passport, ["number"]),
                      function(item, i) {
                        return _c(
                          "div",
                          {
                            key: item.number ? item.number + i : item + i,
                            staticClass:
                              "col-12 col-md-4 cursor-pointer mb-2 extended-report-summary__hover"
                          },
                          [
                            item.series
                              ? _c(
                                  "div",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.dataSelect(
                                          item.series + item.number
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(item.series + item.number) +
                                        "\n          "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            item.number
                              ? _c(
                                  "div",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.dataSelect(item.number)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(item.number) +
                                        "\n          "
                                    )
                                  ]
                                )
                              : item
                              ? _c(
                                  "div",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.dataSelect(item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(item) +
                                        "\n          "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "nuxt-link",
                              {
                                staticClass: "extended-report-summary__search",
                                attrs: {
                                  to: {
                                    name: "fizcheck-expert",
                                    query: {
                                      formType: "fiz",
                                      searchType: "passport",
                                      passport: item.number ? item.number : item
                                    }
                                  },
                                  target: "_blank"
                                }
                              },
                              [_c("i", { staticClass: "fas fa-search" })]
                            )
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
            ],
            2
          )
        : _vm._e(),
      _vm.currentFilter === "phone"
        ? _c(
            "div",
            { staticClass: "extended-report-summary" },
            [
              _vm.dataFilter
                ? [
                    _c(
                      "div",
                      { staticClass: "extended-report-summary__selected-data" },
                      [
                        _c("div", [
                          _vm._v(
                            "\n            Телефон " +
                              _vm._s(_vm.dataFilter) +
                              "\n          "
                          )
                        ]),
                        _c("i", {
                          staticClass: "fas fa-times text-muted cursor-pointer",
                          on: {
                            click: function($event) {
                              return _vm.dataSelect("")
                            }
                          }
                        })
                      ]
                    ),
                    _c("div", [
                      _vm._v(
                        "\n          Найдены следующие данные, относящиеся к этому номеру телефона\n        "
                      )
                    ]),
                    _c("app-fizcheck-expert-summary-result", {
                      attrs: {
                        "current-filter": _vm.currentFilter,
                        "data-filter": _vm.dataFilter,
                        "person-filter": _vm.personFilter,
                        "all-data": _vm.allData,
                        users: _vm.users,
                        "finded-users": _vm.findedUsers,
                        "finded-users-data": _vm.findedUsersData,
                        "related-data": _vm.relatedData
                      },
                      on: {
                        filterSelect: _vm.filterSelect,
                        personSelect: _vm.personSelect,
                        dataSelect: _vm.dataSelect
                      }
                    })
                  ]
                : _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(
                      _vm.sortBy(_vm.allData.list_phones, "value"),
                      function(item, i) {
                        return _c(
                          "div",
                          {
                            key: item.value + i,
                            staticClass:
                              "col-12 col-md-4 cursor-pointer mb-2 extended-report-summary__hover"
                          },
                          [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.dataSelect(item.value)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.value) +
                                    "\n          "
                                )
                              ]
                            ),
                            _c(
                              "nuxt-link",
                              {
                                staticClass: "extended-report-summary__search",
                                attrs: {
                                  to: {
                                    name: "fizcheck-expert",
                                    query: {
                                      formType: "contact",
                                      searchType: "phone",
                                      phone: item.value
                                    }
                                  },
                                  target: "_blank"
                                }
                              },
                              [_c("i", { staticClass: "fas fa-search" })]
                            )
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
            ],
            2
          )
        : _vm._e(),
      _vm.currentFilter === "auto"
        ? _c(
            "div",
            { staticClass: "extended-report-summary" },
            [
              _vm.dataFilter
                ? [
                    _c(
                      "div",
                      { staticClass: "extended-report-summary__selected-data" },
                      [
                        _c("div", [
                          _vm._v(
                            "\n            Автомобиль " +
                              _vm._s(_vm.dataFilter) +
                              "\n          "
                          )
                        ]),
                        _c("i", {
                          staticClass: "fas fa-times text-muted cursor-pointer",
                          on: {
                            click: function($event) {
                              return _vm.dataSelect("")
                            }
                          }
                        })
                      ]
                    ),
                    _c("div", [
                      _vm._v(
                        "\n          Найдены следующие данные, относящиеся к этому автомобилю\n        "
                      )
                    ]),
                    _c("app-fizcheck-expert-summary-result", {
                      attrs: {
                        "current-filter": _vm.currentFilter,
                        "data-filter": _vm.dataFilter,
                        "person-filter": _vm.personFilter,
                        "all-data": _vm.allData,
                        users: _vm.users,
                        "finded-users": _vm.findedUsers,
                        "finded-users-data": _vm.findedUsersData,
                        "related-data": _vm.relatedData
                      },
                      on: {
                        filterSelect: _vm.filterSelect,
                        personSelect: _vm.personSelect,
                        dataSelect: _vm.dataSelect
                      }
                    })
                  ]
                : _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.sortBy(_vm.allData.documents.auto), function(
                      item,
                      i
                    ) {
                      return _c(
                        "div",
                        {
                          key: item + i,
                          staticClass:
                            "col-12 col-md-4 cursor-pointer mb-2 extended-report-summary__hover"
                        },
                        [
                          _c(
                            "div",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.dataSelect(item)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " + _vm._s(item) + "\n          "
                              )
                            ]
                          ),
                          _c(
                            "nuxt-link",
                            {
                              staticClass: "extended-report-summary__search",
                              attrs: {
                                to: {
                                  name: "fizcheck-expert",
                                  query: {
                                    formType: "auto",
                                    searchType: "auto_number",
                                    auto_number: item
                                  }
                                },
                                target: "_blank"
                              }
                            },
                            [_c("i", { staticClass: "fas fa-search" })]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
            ],
            2
          )
        : _vm._e(),
      _vm.currentFilter === "snils"
        ? _c(
            "div",
            { staticClass: "extended-report-summary" },
            [
              _vm.dataFilter
                ? [
                    _c(
                      "div",
                      { staticClass: "extended-report-summary__selected-data" },
                      [
                        _c("div", [
                          _vm._v(
                            "\n            СНИЛС №" +
                              _vm._s(_vm.dataFilter) +
                              "\n          "
                          )
                        ]),
                        _c("i", {
                          staticClass: "fas fa-times text-muted cursor-pointer",
                          on: {
                            click: function($event) {
                              return _vm.dataSelect("")
                            }
                          }
                        })
                      ]
                    ),
                    _c("div", [
                      _vm._v(
                        "\n          Найдены следующие данные, относящиеся к этому номеру СНИЛС\n        "
                      )
                    ]),
                    _c("app-fizcheck-expert-summary-result", {
                      attrs: {
                        "current-filter": _vm.currentFilter,
                        "data-filter": _vm.dataFilter,
                        "person-filter": _vm.personFilter,
                        "all-data": _vm.allData,
                        users: _vm.users,
                        "finded-users": _vm.findedUsers,
                        "finded-users-data": _vm.findedUsersData,
                        "related-data": _vm.relatedData
                      },
                      on: {
                        filterSelect: _vm.filterSelect,
                        personSelect: _vm.personSelect,
                        dataSelect: _vm.dataSelect
                      }
                    })
                  ]
                : _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(
                      _vm.sortBy(_vm.allData.documents.snils, "number"),
                      function(item, i) {
                        return _c(
                          "div",
                          {
                            key: item.number + i,
                            staticClass:
                              "col-12 col-md-4 cursor-pointer mb-2 extended-report-summary__hover",
                            on: {
                              click: function($event) {
                                return _vm.dataSelect(item.number)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.number) +
                                "\n        "
                            )
                          ]
                        )
                      }
                    ),
                    0
                  )
            ],
            2
          )
        : _vm._e(),
      _vm.currentFilter === "inn"
        ? _c(
            "div",
            { staticClass: "extended-report-summary" },
            [
              _vm.dataFilter
                ? [
                    _c(
                      "div",
                      { staticClass: "extended-report-summary__selected-data" },
                      [
                        _c("div", [
                          _vm._v(
                            "\n            ИНН №" +
                              _vm._s(_vm.dataFilter) +
                              "\n          "
                          )
                        ]),
                        _c("i", {
                          staticClass: "fas fa-times text-muted cursor-pointer",
                          on: {
                            click: function($event) {
                              return _vm.dataSelect("")
                            }
                          }
                        })
                      ]
                    ),
                    _c("div", [
                      _vm._v(
                        "\n          Найдены следующие данные, относящиеся к этому номеру ИНН\n        "
                      )
                    ]),
                    _c("app-fizcheck-expert-summary-result", {
                      attrs: {
                        "current-filter": _vm.currentFilter,
                        "data-filter": _vm.dataFilter,
                        "person-filter": _vm.personFilter,
                        "all-data": _vm.allData,
                        users: _vm.users,
                        "finded-users": _vm.findedUsers,
                        "finded-users-data": _vm.findedUsersData,
                        "related-data": _vm.relatedData
                      },
                      on: {
                        filterSelect: _vm.filterSelect,
                        personSelect: _vm.personSelect,
                        dataSelect: _vm.dataSelect
                      }
                    })
                  ]
                : _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.sortBy(_vm.allData.documents.inn), function(
                      item,
                      i
                    ) {
                      return _c(
                        "div",
                        {
                          key: item.number + i,
                          staticClass:
                            "col-12 col-md-4 cursor-pointer mb-2 extended-report-summary__hover"
                        },
                        [
                          _c(
                            "div",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.dataSelect(item.number)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.number) +
                                  "\n          "
                              )
                            ]
                          ),
                          _c(
                            "nuxt-link",
                            {
                              staticClass: "extended-report-summary__search",
                              attrs: {
                                to: {
                                  name: "fizcheck-expert",
                                  query: {
                                    formType: "fiz",
                                    searchType: "inn",
                                    inn: item.number
                                  }
                                },
                                target: "_blank"
                              }
                            },
                            [_c("i", { staticClass: "fas fa-search" })]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
            ],
            2
          )
        : _vm._e(),
      _vm.currentFilter === "email"
        ? _c(
            "div",
            { staticClass: "extended-report-summary" },
            [
              _vm.dataFilter
                ? [
                    _c(
                      "div",
                      { staticClass: "extended-report-summary__selected-data" },
                      [
                        _c("div", [
                          _vm._v(
                            "\n            Email " +
                              _vm._s(_vm.dataFilter) +
                              "\n          "
                          )
                        ]),
                        _c("i", {
                          staticClass: "fas fa-times text-muted cursor-pointer",
                          on: {
                            click: function($event) {
                              return _vm.dataSelect("")
                            }
                          }
                        })
                      ]
                    ),
                    _c("div", [
                      _vm._v(
                        "\n          Найдены следующие данные, относящиеся к этому email\n        "
                      )
                    ]),
                    _c("app-fizcheck-expert-summary-result", {
                      attrs: {
                        "current-filter": _vm.currentFilter,
                        "data-filter": _vm.dataFilter,
                        "person-filter": _vm.personFilter,
                        "all-data": _vm.allData,
                        users: _vm.users,
                        "finded-users": _vm.findedUsers,
                        "finded-users-data": _vm.findedUsersData,
                        "related-data": _vm.relatedData
                      },
                      on: {
                        filterSelect: _vm.filterSelect,
                        personSelect: _vm.personSelect,
                        dataSelect: _vm.dataSelect
                      }
                    })
                  ]
                : _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(
                      _vm.sortBy(_vm.allData.list_emails, "value"),
                      function(item, i) {
                        return _c(
                          "div",
                          {
                            key: item.value + i,
                            staticClass:
                              "col-12 col-md-4 cursor-pointer mb-2 extended-report-summary__hover"
                          },
                          [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.dataSelect(item.value)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.value) +
                                    "\n          "
                                )
                              ]
                            ),
                            _c(
                              "nuxt-link",
                              {
                                staticClass: "extended-report-summary__search",
                                attrs: {
                                  to: {
                                    name: "fizcheck-expert",
                                    query: {
                                      formType: "contact",
                                      searchType: "email",
                                      email: item.value
                                    }
                                  },
                                  target: "_blank"
                                }
                              },
                              [_c("i", { staticClass: "fas fa-search" })]
                            )
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
            ],
            2
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "extended-report-panel__header" }, [
      _c("div", { staticClass: "extended-report-panel__header-icon" }, [
        _c("i", { staticClass: "fas fa-clipboard" })
      ]),
      _c("div", { staticClass: "extended-report-panel__header-text" }, [
        _c("div", { staticClass: "extended-report-panel__title" }, [
          _vm._v("\n        Краткая сводка\n      ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }