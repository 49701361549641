var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.records && _vm.records.length
        ? [
            _vm._l(_vm.records, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "debtor",
                  class: {
                    "d-none d-print-block":
                      !_vm.$isServer && !_vm.showAll && index >= 2
                  }
                },
                [
                  item
                    ? _c("div", [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-auto mb-2" }, [
                            _c("div", { staticClass: "debtor-item__title" }, [
                              _vm._v(
                                "\n              Дата регистрации\n            "
                              )
                            ]),
                            _c("div", { staticClass: "debtor-item__value" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("date")(
                                      item.registerDate,
                                      "DD.MM.YYYY"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "col-auto mb-2" }, [
                            _c("div", { staticClass: "debtor-item__title" }, [
                              _vm._v(
                                "\n              Номер уведомления о возникновении залога\n            "
                              )
                            ]),
                            _c("div", { staticClass: "debtor-item__value" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "link",
                                  on: {
                                    click: function($event) {
                                      return _vm.showDetails(item)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.referenceNumber) +
                                      "\n                "
                                  ),
                                  !_vm.$isServer
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "small font-weight-500 text-nowrap"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  | Смотреть детали\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ])
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-12 mb-2" },
                            [
                              _c("div", { staticClass: "debtor-item__title" }, [
                                _vm._v(
                                  "\n              Имущество\n            "
                                )
                              ]),
                              _vm._l(item.properties, function(property) {
                                return _c(
                                  "div",
                                  {
                                    key: property.properties_vin,
                                    staticClass: "debtor-item__value"
                                  },
                                  [
                                    _vm._v(
                                      "\n              VIN: " +
                                        _vm._s(property.properties_vin) +
                                        "\n            "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          ),
                          _c("div", { staticClass: "col-auto mb-2" }, [
                            _c("div", { staticClass: "debtor-item__title" }, [
                              _vm._v(
                                "\n              Залогодатель\n            "
                              )
                            ]),
                            _c("div", { staticClass: "debtor-item__value" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.pledgors_name) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.pledgors_birth) +
                                  "\n            "
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "col-auto mb-2" }, [
                            _c("div", { staticClass: "debtor-item__title" }, [
                              _vm._v(
                                "\n              Залогодержатель\n            "
                              )
                            ]),
                            _c("div", { staticClass: "debtor-item__value" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.pledgees_name) +
                                  "\n            "
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e()
                ]
              )
            }),
            !_vm.$isServer && !_vm.showAll && _vm.cases && _vm.result.cases > 2
              ? _c("div", { staticClass: "mt-3 text-center d-print-none" }, [
                  _c(
                    "span",
                    {
                      staticClass: "btn-link",
                      on: {
                        click: function($event) {
                          _vm.showAll = !_vm.showAll
                        }
                      }
                    },
                    [_vm._v("Показать ещё")]
                  )
                ])
              : _vm._e()
          ]
        : [_vm._v("\n    В реестре залогов записи\n    "), _vm._m(0)]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "text-success" }, [
      _c("i", { staticClass: "fas fa-check-circle fa-sm fa-fw" }),
      _c("b", [_vm._v("не найдены")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }