















































































































































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    icon: String,
    externalIcon: {
      type: Boolean,
      default: false,
    },
    title: String,
    iconStyle: {
      type: String,
      default: 'fas',
    },
    source: Object,
    status: String,
    missingRequirements: Array,
    color: {
      type: String,
      default: 'info',
    },
    images: {
      type: Array,
      default: (): any => {
        return [];
      },
    },
    opened: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
export default class AppReportItemModern extends Vue {
  // props
  externalIcon: boolean;
  disabled: boolean;
  // status: ExecutionStatusList;
  status: string;
  images: string[];
  source: any;
  icon: string;
  iconStyle: string;
  opened: boolean;
  missingRequirements: any[];
  color: string;

  // get iconColor() {
  //   if (this.isCompleted) {
  //     return 'succes';
  //   } else if (this.isCanceled) {
  //     return 'danger';
  //   } else if (this.isNotRequired) {
  //     return 'gray';
  //   } else {
  //     return 'primary';
  //   }
  // }

  get isCompleted(): boolean {
    return this.status === 'done';
  }

  get isNotRequired(): boolean {
    return this.status === 'not required';
  }

  get isNotPaid(): boolean {
    return this.status === 'not paid';
  }

  get isCanceled(): boolean {
    if (this.$isServer) {
      if (this.status === 'failed' || this.status === 'soft_failed') {
        return true;
      } else {
        return false;
      }
    }

    return this.status === 'failed';
  }

  showImage() {
    const args = this.images.map((elem: string) => {
      return { src: elem, w: 0, h: 0 };
    });
    this.$photoSwipe(args, true);
  }
}
