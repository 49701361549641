var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPhysical && _vm.onlyOwners
    ? _c(
        "app-report-item",
        {
          attrs: {
            title: "Штрафы ГИБДД",
            icon: "camera",
            status: _vm.status,
            images: _vm.images,
            color: _vm.map.color,
            opened: _vm.resultStatus === "found",
            "missing-requirements": _vm.getMissingRequirements,
            source: {
              domain: "гибдд.рф",
              link: "https://xn--90adear.xn--p1ai/"
            }
          },
          scopedSlots: _vm._u(
            [
              {
                key: "not-required",
                fn: function() {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "link font-weight-500 d-print-none",
                        on: {
                          click: function($event) {
                            return _vm.openAddFieldDialog("owner")
                          }
                        }
                      },
                      [_vm._v("\n      Добавить\n    ")]
                    )
                  ]
                },
                proxy: true
              },
              _vm.resultStatus === "found"
                ? {
                    key: "extra-content",
                    fn: function() {
                      return [
                        !!(_vm.bills && _vm.bills.length)
                          ? _c(
                              "div",
                              { staticClass: "text-right report-open-all" },
                              [
                                !_vm.$isServer
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "link",
                                        on: { click: _vm.toggleOpen }
                                      },
                                      [
                                        !_vm.openedLocal
                                          ? [
                                              _vm._v(
                                                "\n          Раскрыть все\n        "
                                              )
                                            ]
                                          : [
                                              _vm._v(
                                                "\n          Скрыть все\n        "
                                              )
                                            ]
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _vm._e(),
                        _c("app-report-item-graph", {
                          attrs: { items: _vm.graphItems }
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "report-item-result report-item-result__result-wrapper"
                          },
                          _vm._l(_vm.bills, function(item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass:
                                  "report-item-result__result bills-report-header"
                              },
                              [
                                _c("app-report-collapse-item", {
                                  attrs: { opened: _vm.openedLocal },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__result-elem-image"
                                              },
                                              [_c("div")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__result-elem-text-wrapper"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "report-item-result__result-elem-text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                Дата\n              "
                                                    )
                                                  ]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm._f("date")(
                                                          item.billDate,
                                                          "DD.MM.YYYY"
                                                        )
                                                      ) +
                                                      "\n              "
                                                  )
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__result-elem-text-wrapper"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "report-item-result__result-elem-text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                Сумма\n              "
                                                    )
                                                  ]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(item.amount) + " ₽"
                                                  )
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__result-elem-text-wrapper"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "report-item-result__result-elem-text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                Описание\n              "
                                                    )
                                                  ]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(item.billName) +
                                                      "\n              "
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      },
                                      {
                                        key: "content",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-section"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "report-item-result__content-list"
                                                  },
                                                  [
                                                    item.billDate
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "report-item-result__content-list-elem"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "report-item-result__content-list-elem-title"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                    Дата\n                  "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "report-item-result__content-list-elem-result"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "date"
                                                                      )(
                                                                        item.billDate,
                                                                        "DD.MM.YYYY"
                                                                      )
                                                                    ) +
                                                                    "\n                  "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    item.amount
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "report-item-result__content-list-elem"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "report-item-result__content-list-elem-title"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                    Сумма\n                  "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "report-item-result__content-list-elem-result"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      item.amount
                                                                    ) +
                                                                    " ₽\n                  "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    item.date
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "report-item-result__content-list-elem"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "report-item-result__content-list-elem-title"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                    Статус оплаты\n                  "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "report-item-result__content-list-elem-result"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      item.date
                                                                    ) +
                                                                    "\n                  "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "report-item-result__content-list-elem"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "report-item-result__content-list-elem-title"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    Статус оплаты\n                  "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "report-item-result__content-list-elem-result"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  item.isPaid
                                                                    ? "Оплачен"
                                                                    : "Не оплачен"
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "report-item-result__content-list-elem"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "report-item-result__content-list-elem-title"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    Инициатор\n                  "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "report-item-result__content-list-elem-result"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  item.addAttrs
                                                                    .attrs[2]
                                                                    .value
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    item.billName
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "report-item-result__content-list-elem"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "report-item-result__content-list-elem-title"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                    Описание\n                  "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "report-item-result__content-list-elem-result"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      item.billName
                                                                    ) +
                                                                    "\n                  "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _vm.task
            ? _c(
                "div",
                { staticClass: "report-item-modern__subtitle-wrapper" },
                [
                  _c("div", { staticClass: "report-item-modern__subtitle" }, [
                    _c(
                      "div",
                      [
                        _vm.resultStatus === "found"
                          ? [
                              _vm._v(
                                "\n          Найдены неоплаченные штрафы\n        "
                              )
                            ]
                          : _vm.resultStatus === "not found"
                          ? [
                              _vm.report.owner.passport &&
                              !_vm.report.owner.driver_license_at
                                ? [
                                    _vm._v(
                                      "\n            Штрафы по паспорту не найдены. Добавьте водительское удостоверение\n            для дополнительной проверки.\n          "
                                    )
                                  ]
                                : !_vm.report.owner.passport &&
                                  _vm.report.owner.driver_license_at
                                ? [
                                    _vm._v(
                                      "\n            трафы по номеру водительского удостоверения не найдены\n          "
                                    )
                                  ]
                                : [
                                    _vm._v(
                                      "\n            Штрафы не обнаружены\n          "
                                    )
                                  ]
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]),
                  _vm.resultStatus === "not found" &&
                  (!_vm.report.owner.driver_license_at ||
                    !_vm.report.owner.passport)
                    ? _c(
                        "div",
                        { staticClass: "report-item-modern__subtitle" },
                        [
                          !_vm.report.owner.passport
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "link font-weight-500 d-print-none",
                                  on: {
                                    click: function($event) {
                                      return _vm.openAddFieldDialog("passport")
                                    }
                                  }
                                },
                                [_vm._v("\n        Добавить\n      ")]
                              )
                            : _vm._e(),
                          !_vm.report.owner.driver_license_at
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "link font-weight-500 d-print-none",
                                  on: {
                                    click: function($event) {
                                      return _vm.openAddFieldDialog("license")
                                    }
                                  }
                                },
                                [_vm._v("\n        Добавить\n      ")]
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ]
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }