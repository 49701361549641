
























































































































































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppFizcheckExpertSummaryResult from 'shared/components/FizcheckExpert/FizcheckExpertSummaryResult.vue';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import moment from 'moment';

@Component({
  components: { AppFizcheckExpertSummaryResult },
  props: {
    currentFilter: String,
    dataFilter: String,
    personFilter: String,
    allData: Object,
    users: Array,
    findedUsers: Array,
    findedUsersData: Object,
    relatedData: Object,
  },
})
export default class AppFizcheckExpertSummary extends Vue {
  // props
  currentFilter: string;
  dataFilter: string;
  personFilter: string;
  allData: any;
  users: any[];
  findedUsers: any[];
  findedUsersData: any;
  relatedData: any;

  get = get;
  sortBy = sortBy;

  filterSelect(value: string) {
    this.$emit('filterSelect', value);
  }

  dataSelect(value: string) {
    this.$emit('dataSelect', value);
  }

  personSelect(value: any, index: number) {
    this.$emit('personSelect', value, index);
  }

  searchPerson(fio: string) {
    const arr = fio.split(' ');
    let birth = '';
    if (arr && arr.length === 4) {
      birth = moment(arr.pop(), 'DD-MM-YYYY').format('MM-DD-YYYY');
    }
    window.open(`/fizcheck-expert?formType=fiz&searchType=fio&first=${arr.join(' ')}&birthday=${birth}`, '_blank').focus();
  }
}
