var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "popup__header" },
      [
        _c("div", { staticClass: "popup__title" }, [
          _vm._v("\n      Создание тарифа\n    ")
        ]),
        _c("app-dialog-close")
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "popup__body" },
      [
        _c(
          "app-form",
          {
            attrs: {
              model: _vm.model,
              rules: _vm.validationRules,
              "request-handler": _vm.onSubmit
            },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function(ref) {
                  var loading = ref.loading
                  return [
                    _c(
                      "app-button",
                      {
                        attrs: {
                          size: "sm",
                          type: "submit",
                          loading: loading,
                          block: ""
                        }
                      },
                      [_vm._v("\n          Создать\n        ")]
                    )
                  ]
                }
              }
            ])
          },
          [
            _c(
              "app-form-group",
              { attrs: { label: "Продукт", "model-name": "product_id" } },
              [
                _c("app-select", {
                  attrs: { size: "sm", items: _vm.products },
                  model: {
                    value: _vm.model.product_id,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "product_id", $$v)
                    },
                    expression: "model.product_id"
                  }
                })
              ],
              1
            ),
            _c(
              "app-form-group",
              {
                attrs: {
                  label: "Наша минимальная цена",
                  "model-name": "base_cost"
                }
              },
              [
                _c("app-input", {
                  attrs: { type: "number", size: "sm" },
                  model: {
                    value: _vm.model.base_cost,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "base_cost", $$v)
                    },
                    expression: "model.base_cost"
                  }
                })
              ],
              1
            ),
            _c(
              "app-form-group",
              {
                attrs: {
                  label: "Коэф. (пока по умолчанию 0.1)",
                  "model-name": "precent"
                }
              },
              [
                _c("app-input", {
                  attrs: { type: "number", size: "sm" },
                  model: {
                    value: _vm.model.precent,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "precent", $$v)
                    },
                    expression: "model.precent"
                  }
                })
              ],
              1
            ),
            _c(
              "app-form-group",
              {
                attrs: {
                  label: "Чистая прибыль партнёра",
                  "model-name": "amount"
                }
              },
              [
                _c("app-input", {
                  attrs: { type: "number", size: "sm" },
                  model: {
                    value: _vm.model.amount,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "amount", $$v)
                    },
                    expression: "model.amount"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }