var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("h5", { staticClass: "text-center" }, [
        _vm._v("\n    Редактирование текста\n  ")
      ]),
      _c("app-dialog-close"),
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "form-label" }, [
          _vm._v("\n      Текст\n    ")
        ]),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.model.text,
              expression: "model.text"
            }
          ],
          staticClass: "form-control",
          attrs: { rows: "3" },
          domProps: { value: _vm.model.text },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.model, "text", $event.target.value)
            }
          }
        })
      ]),
      _c(
        "app-button",
        {
          attrs: { loading: _vm.loading, block: "" },
          on: { click: _vm.submit }
        },
        [_vm._v("\n    Сохранить изменения\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }