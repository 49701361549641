var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.result
        ? [
            _vm._l(_vm.result, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "debtor",
                  class: {
                    "d-none d-print-block":
                      !_vm.$isServer && !_vm.showAll && index >= 2
                  }
                },
                [
                  _c("div", { staticClass: "row no-gutters" }, [
                    _vm._m(0, true),
                    _c("div", { staticClass: "col" }, [
                      _c("div", { staticClass: "debtor-item__value mb-2" }, [
                        _vm._v("\n            Данные лица\n          ")
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-6" }, [
                          item.name
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  Полное наименование\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.name) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm.objectHas(item, "data.info.birthdateBankruptcy")
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  Дата рождения\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm._f("date")(
                                            item.data.info.birthdateBankruptcy,
                                            "DD.MM.YYYY"
                                          )
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          item.region
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  Регион\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.region) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          item.address
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  Адрес\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.address) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "col-6" }, [
                          _vm.objectHas(item, "data.info.snils")
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  СНИЛС\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.data.info.snils) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          item.ogrn
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  ОГРН\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.ogrn) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          item.inn
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  ИНН\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.inn) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm.objectHas(item, "data.companyInfo.kpp")
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  КПП\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.data.companyInfo.kpp) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm.objectHas(item, "data.info.birthplaceBankruptcy")
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  Место рождения\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          item.data.info.birthplaceBankruptcy
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  ]),
                  item.lastLegalCase && item.lastLegalCase.status
                    ? _c("div", { staticClass: "debtor-cols" }, [
                        item.lastLegalCase.status.description
                          ? _c("div", { staticClass: "debtor-item" }, [
                              _c("div", { staticClass: "debtor-item__title" }, [
                                _vm._v(
                                  "\n            Статус банкротного дела\n          "
                                )
                              ]),
                              _c("div", { staticClass: "debtor-item__value" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.caseTypes[
                                        item.lastLegalCase.status.code
                                      ]
                                    ) +
                                    "\n            (" +
                                    _vm._s(
                                      item.lastLegalCase.status.description
                                    ) +
                                    ")\n          "
                                )
                              ])
                            ])
                          : _vm._e(),
                        item.lastLegalCase.status.updateDate
                          ? _c("div", { staticClass: "debtor-item" }, [
                              _c("div", { staticClass: "debtor-item__title" }, [
                                _vm._v(
                                  "\n            Дата последних сведений\n          "
                                )
                              ]),
                              _c("div", { staticClass: "debtor-item__value" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("date")(
                                        item.lastLegalCase.status.updateDate,
                                        "DD.MM.YYYY"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ])
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.objectHas(item, "data.companyInfo")
                    ? _c("div", { staticClass: "debtor-cols" }, [
                        item.data.companyInfo.egrulStatus
                          ? _c("div", { staticClass: "debtor-item" }, [
                              _c("div", { staticClass: "debtor-item__title" }, [
                                _vm._v(
                                  "\n            Статус из ЕГРЮЛ/ЕГРИП\n          "
                                )
                              ]),
                              _c("div", { staticClass: "debtor-item__value" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.data.companyInfo.egrulStatus) +
                                    "\n            "
                                ),
                                item.data.companyInfo.egrulStatusDate
                                  ? _c("span", [
                                      _vm._v(
                                        "\n              (статус обновлен " +
                                          _vm._s(
                                            _vm._f("date")(
                                              item.data.companyInfo
                                                .egrulStatusDate,
                                              "DD.MM.YYYY"
                                            )
                                          ) +
                                          ")\n            "
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ])
                          : _vm._e(),
                        item.data.companyInfo.okvedName
                          ? _c("div", { staticClass: "debtor-item" }, [
                              _c("div", { staticClass: "debtor-item__title" }, [
                                _vm._v("\n            ОКВЭД\n          ")
                              ]),
                              _c("div", { staticClass: "debtor-item__value" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.data.companyInfo.okvedName) +
                                    "\n          "
                                )
                              ])
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.objectHas(item, "lastLegalCase.status") ||
                  _vm.objectHas(item, "data.companyInfo")
                    ? _c(
                        "span",
                        {
                          staticClass: "btn-link small d-print-none",
                          on: {
                            click: function($event) {
                              return _vm.openDetails(item)
                            }
                          }
                        },
                        [_vm._v("\n        Смотреть детали\n      ")]
                      )
                    : _vm._e()
                ]
              )
            }),
            !_vm.$isServer &&
            !_vm.showAll &&
            _vm.result &&
            _vm.result.length > 2
              ? _c("div", { staticClass: "mt-3 text-center d-print-none" }, [
                  _c(
                    "span",
                    {
                      staticClass: "btn-link",
                      on: {
                        click: function($event) {
                          _vm.showAll = !_vm.showAll
                        }
                      }
                    },
                    [_vm._v("Показать ещё")]
                  )
                ])
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-auto" }, [
      _c("i", {
        staticClass:
          "report-item__icon fa-fw mr-3 mt-1 fas fa-address-card text-primary"
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }