





























































import _ from 'lodash';
import Component, { mixins } from 'vue-class-component';
import DataTableMixin from '@/core/mixins/data-table.mixin';

@Component({
  props: {
    userID: String,
  },
})
export default class AdminUserMetriks extends mixins(DataTableMixin) {
  // props
  userID: string;
  headers: TableHeaderItem[] = [
    {
      text: 'Кадастровый номер',
    },
    {
      text: 'Источник',
    },
  ];

  // data
  loading: boolean = false;
  nextPageloading: boolean = false;
  limit: number = 20;
  page: number = 1;
  hasNextPage: boolean = false;

  metriks: UserMetrik[] = [];

  // methods
  handleRequest(offset: number, limit: number) {
    return this.$api.admin.Orders({ offset, limit, userID: this.userID });
  }

  // lifecycle
  async created() {
    await this.fetchMetriks();
  }

  async fetchMetriks() {
    this.loading = true;
    const params = {
      personIDs: this.userID,
      /* offset: this.page * (this.limit),
      limit: this.limit + 1, */
    };

    try {
      this.nextPageloading = true;
      const metriks = await this.$api.admin.Metriks<UserMetrik[]>(params);
      // Добавляем email партнёра
      for (let metrik of metriks) {
        if (metrik.name === 'order_partner_id') {
          const partnerInfo: User = await this.$api.admin.GetUserInfo(metrik.value);
          if (partnerInfo) {
            metrik.partner_email = partnerInfo.email;
          }
        }
      }
      this.nextPageloading = false;
      this.hasNextPage = metriks.length > this.limit;
      this.metriks.push(...metriks.slice(0, this.limit));
      this.page += 1;
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  mapDataIntoView(userOrders: AdminOrderInfo[]) {
    userOrders = userOrders.filter((userOrder: AdminOrderInfo) => {
      userOrder.OrderItem = userOrder.OrderItem.filter((item: AdminOrderItem) => {
        return item.status && !_.includes([ 'waiting_for_payment', 'paid', 'pending' ], item.status);
      });

      return userOrder.OrderItem.length !== 0;
    });

    return userOrders.map((order: AdminOrderInfo) => {
      let address = '';
      let kadastrNumber = '';

      order.OrderItem.forEach((item) => {
        if (item.ItemMetadata.address) {
          address = item.ItemMetadata.address;
        }

        if (item.ItemMetadata.kadastr_number) {
          kadastrNumber = item.ItemMetadata.kadastr_number;
        }
      });

      order.TotalAmount = 0;
      order.Completed = 0;
      order.ItemsCount = order.OrderItem.length;
      order.KadastrNumber = kadastrNumber;
      order.Address = address;

      order.OrderItem.forEach((item) => {
        order.TotalAmount += parseInt(item.Price, 10);
        if (item.status === 'done') {
          order.Completed += 1;
        }
      });

      return order;
    });
  }
}
