var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-3" },
    [
      _c("app-dialog-close"),
      _c("h5", [_vm._v("Перезапуск парсеров")]),
      _c(
        "div",
        { staticClass: "statuses mb-3" },
        _vm._l(_vm.statuses, function(item, key) {
          return _c(
            "div",
            {
              key: key,
              staticClass: "status-item form-row align-items-center py-2"
            },
            [
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c("app-checkbox", {
                    attrs: {
                      value: key,
                      disabled:
                        item.status === "in-progress" &&
                        _vm.report.status === "in-progress"
                    },
                    model: {
                      value: _vm.checked,
                      callback: function($$v) {
                        _vm.checked = $$v
                      },
                      expression: "checked"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "col" }, [
                _vm._v("\n        " + _vm._s(item.name) + "\n      ")
              ]),
              _c(
                "div",
                {
                  staticClass: "col-auto small",
                  class: _vm.statusClassName(item.status)
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.statusText(item.status)) +
                      "\n      "
                  )
                ]
              )
            ]
          )
        }),
        0
      ),
      _c(
        "app-button",
        {
          attrs: { loading: _vm.loading, block: "" },
          on: { click: _vm.submit }
        },
        [_vm._v("\n    Перезапустить выбранные\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }