var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-form",
    {
      attrs: {
        model: _vm.owner,
        rules: _vm.validationConfig,
        "request-handler": _vm.onSubmit
      }
    },
    [
      _c("div", { staticClass: "form-wrapper fizcheck-form-input" }, [
        _c(
          "div",
          { staticClass: "form-wrapper-row" },
          [
            _c(
              "app-form-group",
              {
                staticClass: "fizcheck-form-input mb-0",
                attrs: { "model-name": "surname" }
              },
              [
                _c("app-input", {
                  attrs: { label: "Фамилия", size: "lg" },
                  model: {
                    value: _vm.owner.surname,
                    callback: function($$v) {
                      _vm.$set(_vm.owner, "surname", $$v)
                    },
                    expression: "owner.surname"
                  }
                })
              ],
              1
            ),
            _c(
              "app-form-group",
              {
                staticClass: "fizcheck-form-input mb-0",
                attrs: { "model-name": "first" }
              },
              [
                _c("app-input", {
                  attrs: { label: "Имя", size: "lg" },
                  model: {
                    value: _vm.owner.first,
                    callback: function($$v) {
                      _vm.$set(_vm.owner, "first", $$v)
                    },
                    expression: "owner.first"
                  }
                })
              ],
              1
            ),
            _c(
              "app-form-group",
              {
                staticClass: "fizcheck-form-input mb-0",
                attrs: { "model-name": "patronymic" }
              },
              [
                _c("app-input", {
                  attrs: { label: "Отчество", size: "lg" },
                  model: {
                    value: _vm.owner.patronymic,
                    callback: function($$v) {
                      _vm.$set(_vm.owner, "patronymic", $$v)
                    },
                    expression: "owner.patronymic"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-wrapper-row" },
          [
            _c(
              "app-form-group",
              {
                staticClass: "fizcheck-form-input mb-0",
                attrs: { "model-name": "birthday" }
              },
              [
                _c("app-date-picker", {
                  class: {
                    "is-underage-error": _vm.isUnderage
                  },
                  attrs: {
                    label: "Дата рождения",
                    options: _vm.datePickerOptions,
                    size: "sm",
                    placeholder: "ДД.ММ.ГГГГ"
                  },
                  model: {
                    value: _vm.owner.birthday,
                    callback: function($$v) {
                      _vm.$set(_vm.owner, "birthday", $$v)
                    },
                    expression: "owner.birthday"
                  }
                }),
                _vm.isUnderage
                  ? _c("div", { staticClass: "error" }, [
                      _vm._v(
                        "\n          Лицо должно быть старше 18 лет\n        "
                      )
                    ])
                  : _vm._e()
              ],
              1
            ),
            _c(
              "app-form-group",
              {
                staticClass: "fizcheck-form-input mb-0",
                attrs: { "model-name": "passport" }
              },
              [
                _c("the-mask", {
                  staticClass: "form-control form-control-sm",
                  attrs: {
                    mask: ["## ## ######"],
                    placeholder: "__ __ ______"
                  },
                  model: {
                    value: _vm.owner.passport,
                    callback: function($$v) {
                      _vm.$set(_vm.owner, "passport", $$v)
                    },
                    expression: "owner.passport"
                  }
                }),
                _c("label", { class: [{ active: _vm.owner.passport }] }, [
                  _vm._v("Серия и номер паспорта")
                ])
              ],
              1
            ),
            _c(
              "app-form-group",
              {
                staticClass: "fizcheck-form-input mb-0",
                attrs: { "model-name": "passport_issue_at" }
              },
              [
                _c("app-date-picker", {
                  class: {
                    "is-underage-error": _vm.passportDateError
                  },
                  attrs: {
                    label: "Дата выдачи паспорта",
                    options: _vm.datePickerOptions,
                    size: "sm",
                    placeholder: "ДД.ММ.ГГГГ"
                  },
                  model: {
                    value: _vm.owner.passport_issue_at,
                    callback: function($$v) {
                      _vm.$set(_vm.owner, "passport_issue_at", $$v)
                    },
                    expression: "owner.passport_issue_at"
                  }
                }),
                _vm.passportDateError
                  ? _c("div", { staticClass: "error" }, [
                      _vm._v("\n          Неправильная дата\n        ")
                    ])
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._t("buttons")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }