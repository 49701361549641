var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "extended-report-panel" }, [
    _vm._m(0),
    _c("div", { staticClass: "report-item-result__graph-filter-radio" }, [
      _c(
        "div",
        {
          staticClass: "report-item-result__graph-filter-radio-btn",
          attrs: {
            active: _vm.currentFilter === _vm.activeName,
            disabled: !_vm.docsActive.length
          },
          on: {
            click: function($event) {
              _vm.currentFilter = _vm.activeName
            }
          }
        },
        [
          _c("div", {
            staticClass:
              "report-item-result-filter__color-circle bg-success-modern"
          }),
          _vm._v(
            "\n      Действительные (" +
              _vm._s(_vm.docsActive.length) +
              ")\n    "
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "report-item-result__graph-filter-radio-btn",
          attrs: {
            active: _vm.currentFilter === _vm.disabledName,
            disabled: !_vm.docsDisabled.length
          },
          on: {
            click: function($event) {
              _vm.currentFilter = _vm.disabledName
            }
          }
        },
        [
          _c("div", {
            staticClass:
              "report-item-result-filter__color-circle bg-danger-modern"
          }),
          _vm._v(
            "\n      Прошлые (" + _vm._s(_vm.docsDisabled.length) + ")\n    "
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "report-item-result__graph-filter-radio-btn",
          attrs: {
            active: _vm.currentFilter === _vm.inProgressName,
            disabled: !_vm.docsInProgress.length
          },
          on: {
            click: function($event) {
              _vm.currentFilter = _vm.inProgressName
            }
          }
        },
        [
          _c("div", {
            staticClass:
              "report-item-result-filter__color-circle bg-gray-modern"
          }),
          _vm._v(
            "\n      На проверке (" +
              _vm._s(_vm.docsInProgress.length) +
              ")\n    "
          )
        ]
      )
    ]),
    _c("div", { staticClass: "report-item-modern__subtitle-wrapper" }, [
      _vm.docsActive.length && _vm.currentFilter === _vm.activeName
        ? _c("div", { staticClass: "report-item-modern__subtitle" }, [
            _c("div", {
              staticClass:
                "report-item-modern__dot report-item-modern__color--success"
            }),
            _c("div", {}, [
              _vm._v(
                "\n        Документы проверены на действительность\n      "
              )
            ])
          ])
        : _vm._e(),
      _vm.currentFilter === _vm.disabledName && _vm.docsDisabled.length
        ? _c("div", { staticClass: "report-item-modern__subtitle" }, [
            _c("div", {
              staticClass:
                "report-item-modern__dot report-item-modern__color--danger"
            }),
            _c("div", {}, [
              _vm._v("\n        Документы являются не действительными\n      ")
            ])
          ])
        : _vm._e(),
      !_vm.docsActive.length && _vm.docsInProgress.length
        ? _c("div", { staticClass: "report-item-modern__subtitle" }, [
            _c("img", {
              staticClass: "fa-spin",
              attrs: { src: "https://fizcheck.ru/rc-modern/Group 2678.svg" }
            }),
            _c("div", {}, [
              _vm._v(
                "\n        На текущий момент найденные документы проверяются на действительность и на соответствие данному лицу.\n      "
              )
            ])
          ])
        : _vm._e(),
      _vm.currentFilter === _vm.activeName &&
      !_vm.docsActive.length &&
      _vm.docsInProgress.length
        ? _c("div", { staticClass: "report-item-modern__subtitle mt-3" }, [
            _c("div", { staticClass: "text-neutral-600" }, [
              _vm._v(
                "\n        По завершению отобразим на соответствующих вкладках. Список найденных документов находится на вкладке\n        "
              ),
              _c(
                "span",
                {
                  staticClass: "link",
                  on: {
                    click: function($event) {
                      _vm.currentFilter = _vm.inProgressName
                    }
                  }
                },
                [_vm._v("\n          На проверке\n        ")]
              )
            ])
          ])
        : _vm._e(),
      !_vm.docsActive.length &&
      !_vm.docsInProgress.length &&
      !_vm.docsDisabled.length
        ? _c("div", { staticClass: "report-item-modern__subtitle" }, [
            _c("div", {}, [
              _vm._v(
                "\n        Среди полученных данных, сведений о документах не обнаружено.\n      "
              )
            ])
          ])
        : _vm._e()
    ]),
    (_vm.currentFilter === _vm.activeName && _vm.docsActive.length) ||
    (_vm.currentFilter === _vm.disabledName && _vm.docsDisabled.length) ||
    (_vm.currentFilter === _vm.inProgressName && _vm.docsInProgress.length)
      ? _c("div", { staticClass: "extended-report-panel__divider" })
      : _vm._e(),
    _c(
      "div",
      { staticClass: "extended-report-panel__docs" },
      [
        _vm._l(_vm.docs.passport, function(item, i) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: item.status === _vm.currentFilter,
                  expression: "item.status === currentFilter"
                }
              ],
              key: item.number + i,
              staticClass:
                "extended-report-panel-document extended-report-panel-document--passport"
            },
            [
              _c("img", {
                attrs: {
                  src: "https://fizcheck.ru/rc-modern/extended-passport.svg"
                }
              }),
              _c(
                "div",
                { staticClass: "extended-report-panel-document__title" },
                [_vm._v("\n        Паспорт\n      ")]
              ),
              _c(
                "div",
                { staticClass: "extended-report-panel-document__value" },
                [
                  _c("div", [
                    _vm._v(
                      "\n          " +
                        _vm._s(item.series) +
                        "\n          " +
                        _vm._s(item.number) +
                        "\n        "
                    )
                  ]),
                  _c("i", {
                    staticClass: "fas fa-copy",
                    attrs: { "data-clipboard-text": item.series + item.number }
                  })
                ]
              ),
              _c(
                "div",
                { staticClass: "extended-report-panel-document__status" },
                [
                  _vm.statusActive(item.status)
                    ? _c("i", {
                        staticClass: "fas fa-check-circle text-success-modern"
                      })
                    : _vm.statusDisabled(item.status)
                    ? _c("i", {
                        staticClass:
                          "fas fa-exclamation-circle text-danger-modern"
                      })
                    : _vm._e()
                ]
              )
            ]
          )
        }),
        _vm._l(_vm.docs.snils, function(item, i) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: item.status === _vm.currentFilter,
                  expression: "item.status === currentFilter"
                }
              ],
              key: i + "snils",
              staticClass:
                "extended-report-panel-document extended-report-panel-document--snils"
            },
            [
              _c("img", {
                attrs: {
                  src:
                    "https://fizcheck.ru/rc-modern/Credit-Card-1--Streamline-Core.svg.svg"
                }
              }),
              _c(
                "div",
                { staticClass: "extended-report-panel-document__title" },
                [_vm._v("\n        СНИЛС\n      ")]
              ),
              _c(
                "div",
                { staticClass: "extended-report-panel-document__value" },
                [
                  item.number
                    ? _c("div", [
                        _vm._v(
                          "\n          " + _vm._s(item.number) + "\n        "
                        )
                      ])
                    : _c("div", [
                        _vm._v("\n          " + _vm._s(item) + "\n        ")
                      ]),
                  _c("i", {
                    staticClass: "fas fa-copy",
                    attrs: { "data-clipboard-text": item }
                  })
                ]
              ),
              _c(
                "div",
                { staticClass: "extended-report-panel-document__status" },
                [
                  _vm.statusActive(item.status)
                    ? _c("i", {
                        staticClass: "fas fa-check-circle text-success-modern"
                      })
                    : _vm.statusDisabled(item.status)
                    ? _c("i", {
                        staticClass:
                          "fas fa-exclamation-circle text-danger-modern"
                      })
                    : _vm._e()
                ]
              )
            ]
          )
        }),
        _vm._l(_vm.docs.inn, function(item, i) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: item.status === _vm.currentFilter,
                  expression: "item.status === currentFilter"
                }
              ],
              key: i + "inn",
              staticClass:
                "extended-report-panel-document extended-report-panel-document--inn"
            },
            [
              _c("img", {
                attrs: {
                  src:
                    "https://fizcheck.ru/rc-modern/Business-User-Curriculum--Streamline-Core.svg.svg"
                }
              }),
              _c(
                "div",
                { staticClass: "extended-report-panel-document__title" },
                [_vm._v("\n        ИНН\n      ")]
              ),
              _c(
                "div",
                { staticClass: "extended-report-panel-document__value" },
                [
                  item.number
                    ? _c("div", [
                        _vm._v(
                          "\n          " + _vm._s(item.number) + "\n        "
                        )
                      ])
                    : _c("div", [
                        _vm._v("\n          " + _vm._s(item) + "\n        ")
                      ]),
                  _c("i", {
                    staticClass: "fas fa-copy",
                    attrs: { "data-clipboard-text": item }
                  })
                ]
              ),
              _c(
                "div",
                { staticClass: "extended-report-panel-document__status" },
                [
                  _vm.statusActive(item.status)
                    ? _c("i", {
                        staticClass: "fas fa-check-circle text-success-modern"
                      })
                    : _vm.statusDisabled(item.status)
                    ? _c("i", {
                        staticClass:
                          "fas fa-exclamation-circle text-danger-modern"
                      })
                    : _vm._e()
                ]
              )
            ]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "extended-report-panel__header" }, [
      _c("div", { staticClass: "extended-report-panel__header-icon" }, [
        _c("i", { staticClass: "fas fa-book" })
      ]),
      _c("div", { staticClass: "extended-report-panel__header-text" }, [
        _c("div", { staticClass: "extended-report-panel__title" }, [
          _vm._v("\n        Документы\n      ")
        ]),
        _c("div", { staticClass: "report-item-modern__subtitle" }, [
          _c("div", {}, [
            _vm._v("\n          Результаты проверки документов:\n        ")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }