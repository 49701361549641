import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'AppFilterMixin',
})
export default class FilterMixin extends Vue {
  sources: any = {};
  statuses: any = {};
  roles: any = {};

  mounted() {
    this.$api.admin.GetSourceList<any[]>().then(response => {
      this.sources = response.map((item: any) => {
        item.source = item.source.replace(/https?:\/\//, '');

        return item;
      });
    });

    this.$api.admin.StatusesList().then(({ statuses }) => {
      const statusList = statuses.map((status: string) => ({
        value: status,
        key: status,
      }));

      const statusesGroup: any = {
        default: [],
        risk: [],
        spv: [],
        kadnet: [],
      };

      const rusWords = /[а-яА-Я]/;
      const spvStatuses = /order_.*|download_.*/;
      const riskStatuses = /waiting_for.*[^_payment]/;

      statusList.forEach((status: any) => {
        if (rusWords.test(status.value)) {
          return statusesGroup.kadnet.push(status);
        }

        if (spvStatuses.test(status.value)) {
          return statusesGroup.spv.push(status);
        }

        if (riskStatuses.test(status.value)) {
          return statusesGroup.risk.push(status);
        }

        statusesGroup.default.push(status);
      });

      this.statuses = statusesGroup;
    });

    this.$api.admin.GetRolesList<any[]>().then(response => {
      this.roles = response;
    });
  }
}
