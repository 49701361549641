var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.conclusionStatus
    ? _c(
        "div",
        {
          class: {
            "text-danger": _vm.conclusionStatus === "high",
            "text-warning": _vm.conclusionStatus === "medium",
            "text-success": _vm.conclusionStatus === "low"
          }
        },
        [
          _c("i", { staticClass: "fas fa-exclamation-circle" }),
          _vm._v(" " + _vm._s(_vm.textByStatus) + " риск\n")
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }