



















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';

@Component({
  components: { AppReportItem },
  props: {
    report: Object,
  },
})
export default class AppCounterAgentRkn extends Vue {
  // props
  report: any;
}
