




















import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: { },

  props: {
    title: String,
    data: String,
  },
})
export default class AppStatementTableRow extends Vue {
  title: any;
  data: any;
}
