import { render, staticRenderFns } from "./BankruptDetails.vue?vue&type=template&id=bdece812&"
import script from "./BankruptDetails.vue?vue&type=script&lang=ts&"
export * from "./BankruptDetails.vue?vue&type=script&lang=ts&"
import style0 from "./SudDetails.style.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/realtycloud/rcfront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('bdece812', component.options)
    } else {
      api.reload('bdece812', component.options)
    }
    module.hot.accept("./BankruptDetails.vue?vue&type=template&id=bdece812&", function () {
      api.rerender('bdece812', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "rcshare/components/RiskNew/BankruptDetails.vue"
export default component.exports