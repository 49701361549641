





















































































import Vue from 'vue';
import Component from 'vue-class-component';
import _ from 'lodash';

import AppRiskConclusionItem from 'shared/components/Risk/RiskConclusionItem.vue';

@Component({
  components: { AppRiskConclusionItem },
  props: {
    report: Object,
    onChange: Function,
  },
})
export default class RiskAddConclusion extends Vue {
  // props
  report: RiskReport;
  onChange: Function;

  // data
  dataSendLoading: boolean = false;

  statuses: { [key in ConclusionStatuses]: string } = {
    high: '<i class="fas fa-circle text-danger mr-1"></i> Высокий',
    medium: '<i class="fas fa-circle text-warning mr-1"></i> Средний',
    low: '<i class="fas fa-circle text-success mr-1"></i> Низкий',
    check: '<i class="fas fa-check-circle text-success mr-1"></i>',
    info: '<i class="fas fa-info-circle text-info mr-1"></i>',
  };

  item: { status: ConclusionStatuses; title: string; text: string } = {
    status: null,
    title: '',
    text: '',
  };

  get conclusions() {
    return this.report.conclusion.parsed.filter((item: ConclusionItemParsed) => !item.isDefault);
  }

  get conclusionRaw() {
    return this.report.conclusion.raw.filter((item: ConclusionItemRaw) => !item.isDefault);
  }

  changeStatus(status: ConclusionStatuses) {
    this.item.status = status;
  }

  async addConclusion() {
    const item: ConclusionItemRaw = {
      status: this.item.status,
      text: [ { insert: this.item.text } ],
    };

    if (this.item.title) {
      item.title = [ { insert: this.item.title } ];
    }

    await this.updateConclusionItems(this.conclusionRaw.concat([ item ]));

    this.$dialog.close();
  }

  async remove(index: number) {
    const items = _.cloneDeep(this.conclusions);

    items.splice(index, 1);

    await this.updateConclusionItems(items);

    this.$dialog.close();
  }

  async updateConclusionItems(items: any[]) {
    if (this.dataSendLoading) {
      return;
    }

    this.dataSendLoading = true;

    try {
      await this.$api.risk.AddConclusion({
        order_item_id: this.report.orderItemId,
        conclusion: JSON.stringify(items),
      });

      this.onChange();
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: 'Ошибка при добавлении заключения' });
    }

    this.dataSendLoading = false;
  }
}
