var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      (_vm.result && _vm.result.documents && _vm.result.documents.length) ||
      _vm.haveMosgorsud ||
      _vm.haveSudact
        ? [
            _vm._v(
              "\n    В базе ГАС «Правосудие» по " +
                _vm._s(_vm.naming) +
                "\n    "
            ),
            _vm.region
              ? _c("span", [
                  _vm._v(
                    '\n      в субъекте РФ "' +
                      _vm._s(_vm.regionName) +
                      '"\n    '
                  )
                ])
              : _vm._e(),
            _c("b", { staticClass: "text-danger" }, [
              _c("span", [
                _vm._v(
                  "\n        найдено " + _vm._s(_vm.casesCount) + "\n      "
                )
              ])
            ]),
            _vm._v(" совпадений\n    "),
            !_vm.$isServer
              ? _c(
                  "span",
                  { staticClass: "link", on: { click: _vm.showDetails } },
                  [_vm._v("подробнее")]
                )
              : _vm._e()
          ]
        : [
            _vm._v(
              "\n    В базе ГАС «Правосудие» совпадений по " +
                _vm._s(_vm.naming) +
                "\n    "
            ),
            _vm._m(0)
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "text-success" }, [
      _c("i", { staticClass: "fas fa-check-circle fa-sm fa-fw mr-1" }),
      _c("b", [_vm._v("не найдены")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }