var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stats-chart page-wrapper admin-stats-content" },
    [
      _c("h5", { staticClass: "d-flex justify-content-between" }, [
        _c("span", [_vm._v(_vm._s(_vm.title))]),
        _c("span", [_vm._v(_vm._s(_vm.total))])
      ]),
      _c("div", { staticClass: "stats-chart-wrap" }, [
        _c("canvas", { ref: "chart", attrs: { height: "250px" } })
      ]),
      _c("div", {
        ref: "legend",
        staticClass: "stats-chart-legend",
        on: { click: _vm.onLegendClick }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }