var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("app-report-item", {
    attrs: {
      title: "Реестр Роскомнадзорa",
      icon: "envelope-open-text",
      opened: true,
      disabled: "",
      status: "done",
      color: "success"
    },
    scopedSlots: _vm._u([
      {
        key: "extra-content",
        fn: function() {
          return [
            _c("div", [
              _c("div", { staticClass: "criminal-report__divider" }),
              _c(
                "div",
                { staticClass: "criminal-report__items license-report" },
                [
                  _c("div", { staticClass: "criminal-report__item" }, [
                    _c("div", { staticClass: "criminal-report__title" }, [
                      _vm._v(
                        "\n            Дата внесения в реестр обработчиков персональных данных\n          "
                      )
                    ]),
                    _vm.report["ДатаВнесРКН"]
                      ? _c("div", { staticClass: "criminal-report__result" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.report["ДатаВнесРКН"]) +
                              "\n          "
                          )
                        ])
                      : _c("div", { staticClass: "criminal-report__result" }, [
                          _vm._v("\n            -\n          ")
                        ])
                  ]),
                  _c("div", { staticClass: "criminal-report__item" }, [
                    _c("div", { staticClass: "criminal-report__title" }, [
                      _vm._v(
                        "\n            Дата исключения из реестра обработчиков персональных данных\n          "
                      )
                    ]),
                    _vm.report["ДатаИсклРКН"]
                      ? _c("div", { staticClass: "criminal-report__result" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.report["ДатаИсклРКН"]) +
                              "\n          "
                          )
                        ])
                      : _c("div", { staticClass: "criminal-report__result" }, [
                          _vm._v("\n            -\n          ")
                        ])
                  ]),
                  _c("div", { staticClass: "criminal-report__item" }, [
                    _c("div", { staticClass: "criminal-report__title" }, [
                      _vm._v(
                        "\n            Дата начала обработки персональных данных\n          "
                      )
                    ]),
                    _vm.report["ДатаНачРКН"]
                      ? _c("div", { staticClass: "criminal-report__result" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.report["ДатаНачРКН"]) +
                              "\n          "
                          )
                        ])
                      : _c("div", { staticClass: "criminal-report__result" }, [
                          _vm._v("\n            -\n          ")
                        ])
                  ]),
                  _c("div", { staticClass: "criminal-report__item" }, [
                    _c("div", { staticClass: "criminal-report__title" }, [
                      _vm._v(
                        "\n            Регистрационный номер реестра обработчиков персональных данных\n          "
                      )
                    ]),
                    _vm.report["РегНомРКН"]
                      ? _c("div", { staticClass: "criminal-report__result" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.report["РегНомРКН"]) +
                              "\n          "
                          )
                        ])
                      : _c("div", { staticClass: "criminal-report__result" }, [
                          _vm._v("\n            -\n          ")
                        ])
                  ]),
                  _c("div", { staticClass: "criminal-report__item" }, [
                    _c("div", { staticClass: "criminal-report__title" }, [
                      _vm._v(
                        "\n            Цель обработки персональных данных\n          "
                      )
                    ]),
                    _vm.report["ЦельОбрабРКН"]
                      ? _c("div", { staticClass: "criminal-report__result" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.report["ЦельОбрабРКН"]) +
                              "\n          "
                          )
                        ])
                      : _c("div", { staticClass: "criminal-report__result" }, [
                          _vm._v("\n            -\n          ")
                        ])
                  ])
                ]
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }