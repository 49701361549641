var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu__item", on: { click: _vm.sendRefund } },
    [_vm._v("\n  Возврат средств\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }