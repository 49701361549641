var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "contours-table contours-table--compact" },
    [
      _vm._m(0),
      _c(
        "tbody",
        _vm._l(_vm.borders, function(item, index) {
          return _c("tr", { key: index }, [
            _c("td", [_vm._v(_vm._s(index + 1))]),
            _c(
              "td",
              [
                item.by_definition
                  ? [
                      _vm._v(
                        "\n          " +
                          _vm._s(item.by_definition.nmb_contour) +
                          ".\n          " +
                          _vm._s(item.by_definition.spatial_element) +
                          ".\n          " +
                          _vm._s(item.by_definition.point1) +
                          "\n        "
                      )
                    ]
                  : _vm._e()
              ],
              2
            ),
            _c(
              "td",
              [
                item.by_definition
                  ? [
                      _vm._v(
                        "\n          " +
                          _vm._s(item.by_definition.nmb_contour) +
                          ".\n          " +
                          _vm._s(item.by_definition.spatial_element) +
                          ".\n          " +
                          _vm._s(item.by_definition.point2) +
                          "\n        "
                      )
                    ]
                  : _vm._e()
              ],
              2
            ),
            _c("td", [_vm._v(_vm._s(item.direction_angle))]),
            _c("td", [_vm._v(_vm._s(item.horizontal_distance))]),
            _c("td", [_vm._v("данные отсутствуют")]),
            _c(
              "td",
              [
                _vm.get(item, "cadastral_numbs_adjacent_parcels.length", 0)
                  ? _vm._l(item.cadastral_numbs_adjacent_parcels, function(
                      kad
                    ) {
                      return _c("div", { key: kad.cad_number }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(kad.cad_number) +
                            "\n          "
                        )
                      ])
                    })
                  : [_vm._v("\n          данные отсутствуют\n        ")]
              ],
              2
            ),
            _c(
              "td",
              [
                _vm.get(item, "holders_related_lands.length", 0)
                  ? _vm._l(item.holders_related_lands, function(kad, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "comma-separated" },
                        [
                          kad.email
                            ? _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(kad.email) +
                                    "\n            "
                                )
                              ])
                            : _vm._e(),
                          kad.mailing_addess
                            ? _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(kad.mailing_addess) +
                                    "\n            "
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    })
                  : [_vm._v("\n          данные отсутствуют\n        ")]
              ],
              2
            )
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { colspan: "8" } }, [
          _vm._v(
            "\n        Описание местоположения границ земельного учатска\n      "
          )
        ])
      ]),
      _c("tr", [
        _c("th", { attrs: { rowspan: "2" } }, [
          _vm._v("\n        № П/П\n      ")
        ]),
        _c("th", { attrs: { colspan: "2" } }, [
          _vm._v("\n        Номер точки\n      ")
        ]),
        _c("th", { attrs: { rowspan: "2" } }, [
          _vm._v("\n        Дирекционный угол\n      ")
        ]),
        _c("th", { attrs: { rowspan: "2" } }, [
          _vm._v("\n        Горизонтальное положение, м\n      ")
        ]),
        _c("th", { attrs: { rowspan: "2" } }, [
          _vm._v("\n        Описание закрепления на местности\n      ")
        ]),
        _c("th", { attrs: { rowspan: "2" } }, [
          _vm._v("\n        Кадастровые номера смежных участков\n      ")
        ]),
        _c("th", { attrs: { rowspan: "2" } }, [
          _vm._v(
            "\n        Сведения об адресах правообладателей смежных земельных участков\n      "
          )
        ])
      ]),
      _c("tr", [
        _c("th", { staticStyle: { "border-left-style": "none" } }, [
          _vm._v("\n        Начальная\n      ")
        ]),
        _c("th", [_vm._v("Конечная")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }