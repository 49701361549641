var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container container_responsive" }, [
    _c(
      "div",
      { staticClass: "page-wrapper page-wrapper_border" },
      [
        _c(
          "div",
          {
            staticClass:
              "admin-page-head d-flex align-items-center justify-content-between"
          },
          [
            _c("h5", [_vm._v("Список пользователей")]),
            _c(
              "app-button",
              { attrs: { size: "sm" }, on: { click: _vm.openFilter } },
              [_vm._v("\n        Фильтр пользователей\n      ")]
            )
          ],
          1
        ),
        _c("app-data-table", {
          attrs: {
            headers: _vm.headers,
            items: _vm.items,
            loading: _vm.loading,
            pagination: _vm.pagination
          },
          on: { "page-changed": _vm.pageChanged },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "admin-user-orders",
                              params: { userID: row.id }
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(row.email) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("td", [
                    _vm._v("\n          " + _vm._s(row.phone) + "\n          "),
                    row.phone
                      ? _c(
                          "a",
                          { attrs: { href: _vm.getWhatsAppLink(row.phone) } },
                          [
                            _c(
                              "svg",
                              {
                                staticStyle: { width: "21px", height: "21px" }
                              },
                              [
                                _c("use", {
                                  attrs: { "xlink:href": "#icon-whatsapp" }
                                })
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c("td", [
                    _c("a", { attrs: { href: row.source } }, [
                      _vm._v(_vm._s(row.source))
                    ])
                  ]),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("date")(row.register_date, "DD.MM.YYYY HH:mm:ss")
                      )
                    )
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(
                      "\n          " + _vm._s(row.person_role) + "\n        "
                    )
                  ]),
                  _c("td", { staticClass: "text-right" }, [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "tippy",
                            rawName: "v-tippy",
                            value: {
                              content: "Добавить ответственного",
                              placement: "top-end"
                            },
                            expression:
                              "{ content: 'Добавить ответственного', placement: 'top-end' }"
                          }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.showAddResponsibleUserDialog(row.id)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-user-plus" })]
                    )
                  ])
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }