var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c("h5", { staticClass: "text-center mb-3" }, [
        _vm._v("\n    Расхождение выписок\n  ")
      ]),
      _c("div", { staticClass: "text-center" }, [
        _vm.data.amount_of_owners
          ? _c("p", [
              _vm._v("\n      Количество собственников: "),
              _c("span", { staticClass: "text-danger" }, [
                _vm._v("расхождение")
              ])
            ])
          : _vm._e(),
        _vm.data.area
          ? _c("p", [
              _vm._v("\n      Площадь: "),
              _c("span", { staticClass: "text-danger" }, [
                _vm._v("расхождение")
              ])
            ])
          : _vm._e(),
        _vm.data.cadastral_value
          ? _c("p", [
              _vm._v("\n      Кадастровая стоимость: "),
              _c("span", { staticClass: "text-danger" }, [
                _vm._v("расхождение")
              ])
            ])
          : _vm._e(),
        _vm.data.date_rights
          ? _c("p", [
              _vm._v("\n      Дата регистрации: "),
              _c("span", { staticClass: "text-danger" }, [
                _vm._v("расхождение")
              ])
            ])
          : _vm._e(),
        _vm.data.registration_number_of_rights
          ? _c("p", [
              _vm._v("\n      Количество регистраций: "),
              _c("span", { staticClass: "text-danger" }, [
                _vm._v("расхождение")
              ])
            ])
          : _vm._e(),
        _vm.data.encumbrance
          ? _c("p", [
              _vm._v("\n      Ограничения: "),
              _c("span", { staticClass: "text-danger" }, [
                _vm._v("расхождение")
              ])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [
          _c("h6", [_vm._v("Росреестр")]),
          _c("p", [
            _vm._v("\n        Площадь: "),
            _c("b", [_vm._v(_vm._s(_vm.data.details.rc_data.area))])
          ]),
          _c("p", [
            _vm._v("\n        Кадастровая стоимость: "),
            _c("b", [_vm._v(_vm._s(_vm.data.details.rc_data.cadCost))])
          ]),
          _c(
            "p",
            [
              _vm._v("\n        Ограничения:\n        "),
              _vm.data.details.rc_data.encumbrances
                ? [
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.data.details.rc_data.encumbrances.length)
                      )
                    ]),
                    _c(
                      "ul",
                      _vm._l(_vm.data.details.rc_data.encumbrances, function(
                        encumbrance,
                        i
                      ) {
                        return _c("li", { key: i }, [
                          _c("span", [
                            _c("i", [_vm._v("Тип ограничения:")]),
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.getEncumbranceType(encumbrance.type)
                                ) +
                                "\n              "
                            )
                          ]),
                          _c("br"),
                          _c("span", [
                            _c("i", [_vm._v("Дата: ")]),
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  encumbrance.startDate
                                    ? _vm.getFormatDate(encumbrance.startDate)
                                    : "-"
                                ) +
                                "\n              "
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  ]
                : _c("b", [_vm._v("0")])
            ],
            2
          ),
          _c(
            "p",
            [
              _vm._v("\n        Собственники:\n        "),
              _vm.data.details.rc_data.rights
                ? [
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.data.details.rc_data.rights.length) + " "
                      )
                    ]),
                    _c(
                      "ul",
                      _vm._l(_vm.data.details.rc_data.rights, function(
                        right,
                        i
                      ) {
                        return _c("li", { key: i }, [
                          _c("span", [
                            _c("i", [_vm._v("Номер:")]),
                            _vm._v(
                              "\n                " +
                                _vm._s(right.rightNumber) +
                                "\n              "
                            )
                          ]),
                          _c("br"),
                          _c("span", [
                            _c("i", [_vm._v("Дата регистрации: ")]),
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  right.rightRegDate
                                    ? _vm.getFormatDate(right.rightRegDate)
                                    : "-"
                                ) +
                                "\n              "
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  ]
                : _c("b", [_vm._v("0")])
            ],
            2
          )
        ]),
        _c("div", { staticClass: "col-6" }, [
          _c("h6", [_vm._v("Архив")]),
          _c("p", [
            _vm._v("\n        Площадь: "),
            _c("b", [_vm._v(_vm._s(_vm.data.details.zip_data.area))])
          ]),
          _c("p", [
            _vm._v("\n        Кадастровая стоимость:\n        "),
            _c("b", [_vm._v(_vm._s(_vm.data.details.zip_data.cadastral_value))])
          ]),
          _c(
            "p",
            [
              _vm._v("\n        Ограничения:\n        "),
              _vm.data.details.zip_data.encumbrances
                ? [
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.data.details.zip_data.encumbrances.length)
                      )
                    ]),
                    _c(
                      "ul",
                      _vm._l(_vm.data.details.zip_data.encumbrances, function(
                        encumbrance,
                        i
                      ) {
                        return _c("li", { key: i }, [
                          _c("span", [
                            _c("i", [_vm._v("Тип ограничения:")]),
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.getEncumbranceType(encumbrance.type)
                                ) +
                                "\n              "
                            )
                          ]),
                          _c("br"),
                          _c("span", [
                            _c("i", [_vm._v("Дата: ")]),
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  encumbrance.encumbrance_date
                                    ? encumbrance.encumbrance_date
                                    : "-"
                                ) +
                                "\n              "
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  ]
                : _c("b", [_vm._v("0")])
            ],
            2
          ),
          _c(
            "p",
            [
              _vm._v("\n        Собственники:\n        "),
              _vm.data.details.zip_data.rights
                ? [
                    _c("b", [
                      _vm._v(_vm._s(_vm.data.details.zip_data.rights.length))
                    ]),
                    _c(
                      "ul",
                      _vm._l(_vm.data.details.zip_data.rights, function(
                        right,
                        i
                      ) {
                        return _c("li", { key: i }, [
                          _c("span", [
                            _c("i", [_vm._v("Номер:")]),
                            _vm._v(
                              "\n                " +
                                _vm._s(right.right_number) +
                                "\n              "
                            )
                          ]),
                          _c("br"),
                          _c("span", [
                            _c("i", [_vm._v("Дата регистрации: ")]),
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  right.right_reg_date
                                    ? right.right_reg_date
                                    : "-"
                                ) +
                                "\n              "
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  ]
                : _c("b", [_vm._v("0")])
            ],
            2
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }