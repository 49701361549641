var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrapper mb-3" },
    [
      _c("div", { staticClass: "admin-page-head form-row pb-0" }, [
        _vm._m(0),
        _c("div", { staticClass: "col text-right small text-muted" }, [
          _vm._v(
            "\n      Всего " +
              _vm._s(_vm._f("currency")(_vm.totalSum)) +
              "\n    "
          )
        ])
      ]),
      _c("app-data-table", {
        attrs: { headers: _vm.headers, items: _vm.items, loading: _vm.loading },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "td",
                  { staticClass: "text-truncate" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          target: "_blank",
                          to: {
                            name: "admin-user-orders",
                            params: { userID: row.partner_id }
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(row.email) + "\n        "
                        )
                      ]
                    ),
                    _c("div", { staticClass: "text-truncate" }, [
                      _vm._v(
                        "\n          " + _vm._s(row.partner_id) + "\n        "
                      )
                    ])
                  ],
                  1
                ),
                _c("td", [_vm._v(_vm._s(_vm._f("currency")(row.reward_sum)))]),
                _c("td", [
                  _vm._v(_vm._s(_vm._f("currency")(row.service_income_sum)))
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm._f("currency")(row.total_users_transaction_sum))
                  )
                ])
              ]
            }
          }
        ])
      }),
      _vm.hasNextPage
        ? _c(
            "div",
            { staticClass: "px-2" },
            [
              _c("app-button", { attrs: { variant: "link" } }, [
                _vm._v("\n      Загрузить ещё\n    ")
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("h6", { staticClass: "m-0" }, [
        _vm._v("\n        Статистика партнёров\n      ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }