
































import Vue from 'vue';
import Component from 'vue-class-component';
import noop from 'lodash/noop';
import { Validators } from '@/core/utils/validator';
import { Api } from 'shared/api';
@Component({
  props: {
    userId: {
      type: String,
      required: true,
    },
    submit: {
      type: Function,
      default: noop,
    },
  },
})
export default class AdminChooseRoleDialog extends Vue {
  // props
  userId: UUID;
  submit!: (newphone: string) => void;

  model: SimpleObject<string> = {
    phone: '',
  };
  user: User = null;

  // lifecycle
  async created() {
    this.user = await Api.admin.GetUserInfo(this.userId);
    this.model.phone = this.user.phone;
  }

  // methods
  validationRules(): ValidationConfig {
    return {
      phone: [ Validators.required, Validators.phone ],
    };
  }

  async onSubmit() {
    this.user.phone = this.model.phone;
    try {
      await this.$api.user.UpdateInfo(this.user);
      this.$noty.info({ text: 'Профиль успешно обновлен' });
      this.submit(this.model.phone);
      this.$dialog.close();
    } catch (error) {
      this.$noty.error({ text: this.$api._getErrorMessage(error) });
    }
  }
}
