
















































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Provide } from 'vue-property-decorator';

import AppReportItem from './ReportItem.vue';
import AppOwnerInfo from './OwnerInfo.vue';
import AppTaxReport from './TaxReport.vue';
import AppPledgeReport from 'shared/components/RiskNew/PledgeReport.vue';
import AppDeptReport from './DebtReport.vue';
import AppSudReport from './SudReport.vue';
import AppArbitrReport from './ArbitrReport.vue';
import AppFounderReport from './Founder.vue';
import AppAddBirthdayDialog from 'shared/components/RiskNew/AddOwnerForm/AddBirthdayDialog.vue';
import AppAddPassportDialog from 'shared/components/RiskNew/AddOwnerForm/AddPassportDialog.vue';
import AppAddInnDialog from 'shared/components/RiskNew/AddOwnerForm/AddInnDialog.vue';
import AppBankruptReport from 'shared/components/RiskNew/BankruptReport.vue';
import AppDisqualifiedReport from 'shared/components/RiskNew/DisqualifiedReport.vue';
import AppForeignAgentReport from 'shared/components/RiskNew/ForeignAgentReport.vue';
import AppCriminalReport from 'shared/components/RiskNew/CriminalReport.vue';
import AppFsinReport from 'shared/components/RiskNew/FsinReport.vue';
import AppInterpolReport from 'shared/components/RiskNew/InterpolReport.vue';
import sumBy from 'lodash/sumBy';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { checkPersonTasks } from 'shared/utils/checkPersonTasks';
import concat from 'lodash/concat';
import filter from 'lodash/filter';

@Component({
  components: {
    AppReportItem,
    AppOwnerInfo,
    AppTaxReport,
    AppPledgeReport,
    AppDeptReport,
    AppSudReport,
    AppArbitrReport,
    AppFounderReport,
    AppBankruptReport,
    AppDisqualifiedReport,
    AppForeignAgentReport,
    AppCriminalReport,
    AppFsinReport,
    AppInterpolReport,
  },

  props: {
    report: Object,
    orderStatus: String,
    opened: {
      type: Boolean,
      default: false,
    },
    isDemo: {
      type: Boolean,
      default: false,
    },
    onlyOwners: {
      type: Boolean,
      default: false,
    },
    fetchReport: {
      type: Function,
      default: noop,
    },
    notPaid: {
      type: Boolean,
      default: false,
    },
  },
})
export default class OwnerRiskReport extends Vue {
  // props
  report!: LawInformationOwnerElem;
  orderStatus: string;
  opened!: boolean;
  onlyOwners!: boolean;
  isDemo: boolean;
  fetchReport!: () => void;
  notPaid: boolean;
  isEmpty = isEmpty;
  get: any = get;

  // data()
  @Provide()
  ReportProvider: any = {
    owner: this.report.owner,
    isPdf: this.opened,
  };

  get isPhysical() {
    // eslint-disable-next-line eqeqeq
    return this.report.owner.owner_type !== 1;
  }

  get fioValidCheck() {
    const innTask = this.getTask('owner_check_individual_inn');
    return (
      innTask &&
      innTask.task_result.inn &&
      innTask.task_result.text !== 'Добавлен пользователем'
    );
  }

  get gosuslugiPassport() {
    const task = this.getTask('validate_passport_government');
    if (!task) {
      return 'error';
    }
    if (task.status === 'done') {
      if (task.task_result.result === 'Действительный') {
        return 'valid';
      } else if (task.task_result.result === 'Данные не найдены') {
        return 'notFound';
      } else if (task.task_result.result === 'Недействительный') {
        return 'invalid';
      }
    } else if (task.status === 'failed') {
      return 'error';
    }
    return 'inProgress';
  }

  get passportTask() {
    let task = this.getTask('owner_check_individual_valid_passport');
    if (this.gosuslugiPassport !== 'error') {
      task = this.getTask('validate_passport_government');
    }
    if (!task) {
      return null;
    }
    task.task_result.gosuslugiPassport = this.gosuslugiPassport;
    return task;
  }

  get tasks() {
    const tasksNames = checkPersonTasks();
    let ceo = this.getTask(tasksNames.ceo);
    if (!ceo) {
      ceo = this.getTask('owner_check_individual_ceo');
    }
    return {
      passport: this.passportTask,
      criminal: this.getTask(tasksNames.criminal),
      fsin: this.getTask(tasksNames.fsin),
      interpol: this.getTask(tasksNames.interpol),
      bailiffDebt: this.getTask(tasksNames.bailiffDebt),
      individualDebt: this.getTask(tasksNames.individualDebt),
      bankrupt: this.getTask(tasksNames.bankrupt),
      selfEmployed: this.getTask(tasksNames.selfEmployed),
      ceo,
      disqualified: this.getTask(tasksNames.disqualified),
      terrorism: this.getTask(tasksNames.terrorism),
      foreignAgent: this.getTask(tasksNames.foreignAgent),
      arbitrReport: this.getTask(tasksNames.arbitrReport),
      judicialActs: this.getTask(tasksNames.judicialActs),
      pledge: this.getTask(tasksNames.pledge),
    };
  }

  get orderIsDone() {
    return this.orderStatus === 'done';
  }

  get newSurveys() {
    return this.report.surveys.filter((elem) => {
      return elem.status === 'in progress';
    });
  }

  get checkBankrupt() {
    if (this.isPhysical) {
      if (this.tasks.bankrupt.task_result.fiz.length) {
        return this.tasks.bankrupt.task_result.fiz;
      }
    } else {
      if (this.tasks.bankrupt.task_result.ur.length) {
        return this.tasks.bankrupt.task_result.ur;
      }
    }
    return null;
  }

  get inn() {
    const innTask = this.getTask('owner_check_individual_inn');
    if (innTask && [ 'failed, soft_failed' ].includes(innTask.status)) {
      return '';
    }
    if (innTask && innTask.task_result.inn) {
      return innTask.task_result.inn;
    } else if (this.report.owner && this.report.owner.inn) {
      return this.report.owner.inn;
    } else {
      return '';
    }
  }

  get gosuslugiBills() {
    return this.getTask('gosuslugi_bills');
  }

  get penaltySum() {
    const sum = sumBy(
      this.taxReportResult,
      (elem: any) => +elem.amount
    );
    return sum.toFixed(2);
  }

  get gosuslugiTaxResult() {
    // return get(this.gosuslugiBills, 'task_result.result.inn.bills', []);
    const result = get(this.gosuslugiBills, 'task_result.result');
    if (!result) {
      return [];
    }
    return concat(
      filter(get(result, 'inn.bills'), this.mapBills),
      filter(get(result, 'passport.bills'), this.mapBills),
      filter(get(result, 'snils.bills'), this.mapBills),
    );
  }

  get taxReportTask() {
    return this.gosuslugiBills;
  }

  get taxReportResult() {
    return this.gosuslugiTaxResult;
  }

  mapBills(elem: any) {
    return elem.serviceCategory.code === 'FNS';
  }

  getTask(val: string) {
    return this.report.tasks.find((elem: any) => elem.task_type === val);
  }

  getTaskStatus(task: any) {
    if (this.notPaid) {
      if (task && [ 'owner_check_individual_valid_passport', 'owner_check_individual_criminal_investigation', 'owner_check_individual_bailiff_debt' ].includes(task.task_type)) {
        return task.status;
      } else {
        return 'not paid';
      }
    } else if (task) return task.status;
    else if (this.orderIsDone) return 'done';
    else return 'in progress';
  }

  getSudTaskStatus(task: any) {
    const status = this.getTaskStatus(task);
    if ([ 'failed', 'soft_failed' ].includes(status)) {
      const sudact = this.getTask('sudact');
      this.tasks.judicialActs = sudact;
      return this.getTaskStatus(sudact);
    }
    return status;
  }

  getFsspTaskStatus(task: any) {
    const status = this.getTaskStatus(task);
    if ([ 'failed', 'soft_failed' ].includes(status)) {
      const gosuslugi = this.getTask('gosuslugi_bills');
      this.tasks.bailiffDebt = gosuslugi;
      return this.getTaskStatus(gosuslugi);
    }
    return status;
  }

  getMissingRequirements(task: any) {
    if (task) return task.missing_requirements;
    else return [];
  }

  formatArbitr(data: any) {
    let result = data.cases;
    if (data.fio) {
      result = data.fio;
    }
    return {
      all: result,
      totalCount: data.count,
      executionStatus: 'complete',
    };
  }

  openAddFieldDialog(field: string) {
    let component: any;
    switch (field) {
      case 'birthday':
        component = AppAddBirthdayDialog;
        break;
      case 'passport':
        component = AppAddPassportDialog;
        break;
      case 'inn':
        component = AppAddInnDialog;
        break;
    }
    this.$dialog.open({
      component,
      props: {
        owner: this.report.owner,
        fetchReport: this.fetchReport,
      },
    });
  }
}
