var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sud-container" }, [
    _c("div", { staticClass: "sud-items" }, [
      _c(
        "div",
        { staticClass: "popup__header" },
        [
          _c("div", { staticClass: "popup__title" }, [
            _vm._v("\n        Данные о розыске\n      ")
          ]),
          _c("app-dialog-close")
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "popup__body" },
        _vm._l(_vm.result, function(elem) {
          return _c(
            "div",
            {
              key: elem.url,
              staticClass: "sud-details__content legacy-container"
            },
            [
              _c(
                "div",
                { staticClass: "row mb-3 no-gutters align-items-center" },
                [
                  elem.photo
                    ? _c("div", { staticClass: "col-auto mr-2" }, [
                        _c("div", { staticClass: "sud-details-item" }, [
                          _c("img", {
                            attrs: { src: elem.photo, width: "100px" }
                          })
                        ])
                      ])
                    : _vm._e()
                ]
              ),
              elem.fio
                ? _c("div", { staticClass: "sud-details-item" }, [
                    _c("div", { staticClass: "sud-details-item__title" }, [
                      _vm._v("\n            ФИО\n          ")
                    ]),
                    _c("div", { staticClass: "sud-details-item__value" }, [
                      _vm._v(
                        "\n            " + _vm._s(elem.fio) + "\n          "
                      )
                    ])
                  ])
                : _vm._e(),
              elem.date
                ? _c("div", { staticClass: "sud-details-item" }, [
                    _c("div", { staticClass: "sud-details-item__title" }, [
                      _vm._v("\n            Дата рождения\n          ")
                    ]),
                    _c("div", { staticClass: "sud-details-item__value" }, [
                      _vm._v(
                        "\n            " + _vm._s(elem.date) + "\n          "
                      )
                    ])
                  ])
                : _vm._e(),
              elem.territorial
                ? _c("div", { staticClass: "sud-details-item" }, [
                    _c("div", { staticClass: "sud-details-item__title" }, [
                      _vm._v("\n            Регион\n          ")
                    ]),
                    _c("div", { staticClass: "sud-details-item__value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(elem.territorial) +
                          "\n          "
                      )
                    ])
                  ])
                : _vm._e(),
              elem.federal
                ? _c("div", { staticClass: "sud-details-item" }, [
                    _c("div", { staticClass: "sud-details-item__title" }, [
                      _vm._v("\n            Федеральный округ\n          ")
                    ]),
                    _c("div", { staticClass: "sud-details-item__value" }, [
                      _vm._v(
                        "\n            " + _vm._s(elem.federal) + "\n          "
                      )
                    ])
                  ])
                : _vm._e(),
              elem.subtext
                ? _c("div", { staticClass: "sud-details-item" }, [
                    _c("div", { staticClass: "sud-details-item__title" }, [
                      _vm._v(
                        "\n            Дополнительная информация\n          "
                      )
                    ]),
                    _c("div", { staticClass: "sud-details-item__value" }, [
                      _vm._v(
                        "\n            " + _vm._s(elem.subtext) + "\n          "
                      )
                    ])
                  ])
                : _vm._e()
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }