var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-item-result report-item-result__result-wrapper" },
    [
      _c("div", { staticClass: "report-item-result__graph-filter mb-2" }, [
        _c("div", { staticClass: "report-item-result__graph-filter-radio" }, [
          _c(
            "div",
            {
              staticClass: "report-item-result__graph-filter-radio-btn",
              attrs: { active: _vm.currentFilter === "all" },
              on: {
                click: function($event) {
                  _vm.currentFilter = "all"
                }
              }
            },
            [_vm._v("\n        Все\n      ")]
          ),
          _c(
            "div",
            {
              staticClass: "report-item-result__graph-filter-radio-btn",
              attrs: {
                active: _vm.currentFilter === "active",
                disabled: !_vm.activeCases.length
              },
              on: {
                click: function($event) {
                  _vm.currentFilter = "active"
                }
              }
            },
            [
              _vm._m(0),
              _vm._v(
                "\n        Открытые (" +
                  _vm._s(_vm.activeCases.length) +
                  ")\n      "
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "report-item-result__graph-filter-radio-btn",
              attrs: {
                active: _vm.currentFilter === "closed",
                disabled: !_vm.closedCases.length
              },
              on: {
                click: function($event) {
                  _vm.currentFilter = "closed"
                }
              }
            },
            [
              _vm._m(1),
              _vm._v(
                "\n        Приостановленные и прекращенные (" +
                  _vm._s(_vm.closedCases.length) +
                  ")\n      "
              )
            ]
          )
        ])
      ]),
      _vm._l(_vm.elems, function(item, index) {
        return _c(
          "div",
          { key: index, staticClass: "report-item-result__result" },
          [
            _c("app-report-collapse-item", {
              attrs: { opened: _vm.opened },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "report-item-result__result-elem-image d-none d-md-block",
                            class: {
                              "report-item-result__result-elem-image--warning":
                                item.date_and_reason_to_stop
                            }
                          },
                          [_c("div")]
                        ),
                        item.enforcement_proceedings
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__result-elem-text-wrapper fssp-modern__title-date"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__result-elem-text"
                                  },
                                  [
                                    _vm._v(
                                      "\n            Номер/Дата\n          "
                                    )
                                  ]
                                ),
                                _c("div", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(item.enforcement_proceedings) +
                                      "\n          "
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass:
                              "report-item-result__result-elem-text-wrapper"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__result-elem-text"
                              },
                              [
                                _vm._v(
                                  "\n            Предмет исполнения\n          "
                                )
                              ]
                            ),
                            _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.amount || "Сведения не найдены") +
                                  "\n            "
                              ),
                              item.date_and_reason_to_stop
                                ? _c("div", [
                                    _vm._v(
                                      "\n              Дата, причина окончания или прекращения ИП: " +
                                        _vm._s(item.date_and_reason_to_stop) +
                                        "\n            "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "report-item-result__content-section"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "report-item-result__content-list"
                              },
                              [
                                item.entity
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Должник\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(item.entity) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                item.enforcement_proceedings
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Исполнительное производство\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  item.enforcement_proceedings
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                item.amount
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Предмет исполнения, сумма непогашенной задолженности\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(item.amount) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                item.department
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Отдел судебных приставов\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(item.department) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                item.bailiff
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Судебный пристав-исполнитель\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(item.bailiff) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                item.document
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Реквизиты исполнительного документа\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(item.document) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                item.date_and_reason_to_stop
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Дата, причина окончания или прекращения ИП (статья, часть, пункт основания)\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  item.date_and_reason_to_stop
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            })
          ],
          1
        )
      }),
      _vm.filteredResult.length > _vm.perPage
        ? _c("app-pagination", {
            attrs: {
              current: _vm.currentPage,
              "per-page": _vm.perPage,
              total: _vm.filteredResult.length
            },
            on: { "page-changed": _vm.pageChanged }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "report-item-result__result-elem-image" }, [
      _c("div", { staticClass: "mt-0" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "report-item-result__result-elem-image report-item-result__result-elem-image--warning"
      },
      [_c("div", { staticClass: "mt-0" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }