var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-report-item",
    {
      attrs: {
        title: "Руководитель",
        icon: "user",
        opened: !!_vm.ceo,
        disabled: "",
        status: "done",
        color: "success"
      },
      scopedSlots: _vm._u([
        {
          key: "extra-content",
          fn: function() {
            return [
              !!_vm.ceo
                ? _c("div", [
                    _c("div", { staticClass: "criminal-report__divider" }),
                    _c(
                      "div",
                      { staticClass: "criminal-report__items license-report" },
                      [
                        _vm.ceo["fl"]
                          ? _c(
                              "div",
                              { staticClass: "criminal-report__item" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "criminal-report__title" },
                                  [_vm._v("\n            ФИО\n          ")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "criminal-report__result" },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.ceo["fl"]) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.ceo["post"]
                          ? _c(
                              "div",
                              { staticClass: "criminal-report__item" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "criminal-report__title" },
                                  [
                                    _vm._v(
                                      "\n            Наименование должности\n          "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "criminal-report__result" },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.ceo["post"]) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.ceo["date"]
                          ? _c(
                              "div",
                              { staticClass: "criminal-report__item" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "criminal-report__title" },
                                  [
                                    _vm._v(
                                      "\n            Действует с\n          "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "criminal-report__result" },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm._f("date")(
                                            _vm.ceo["date"],
                                            "DD.MM.YYYY"
                                          )
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.ceo["inn"]
                          ? _c(
                              "div",
                              { staticClass: "criminal-report__item" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "criminal-report__title" },
                                  [_vm._v("\n            ИНН\n          ")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "criminal-report__result" },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.ceo["inn"]) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      !_vm.ceo
        ? _c("div", { staticClass: "report-item-modern__subtitle-wrapper" }, [
            _c("div", { staticClass: "report-item-modern__subtitle" }, [
              _vm._v("\n      Информация о руководителе не найдена\n    ")
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }