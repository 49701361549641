var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPhysical && _vm.onlyOwners
    ? _c(
        "app-report-item",
        {
          attrs: {
            title: "Реестр дисквалифицированных лиц",
            icon: "coins",
            status: _vm.status,
            images: _vm.images,
            color: _vm.map.color,
            opened: _vm.resultStatus === "found",
            "missing-requirements": _vm.missingRequirements,
            source: {
              domain: "service.nalog.ru",
              link: "https://service.nalog.ru/disqualified.html#t=1667220507115"
            }
          },
          scopedSlots: _vm._u(
            [
              _vm.resultStatus === "found"
                ? {
                    key: "extra-content",
                    fn: function() {
                      return [
                        _vm.task.task_result.result &&
                        _vm.task.task_result.result.length > 1
                          ? _c(
                              "div",
                              { staticClass: "text-right report-open-all" },
                              [
                                !_vm.$isServer
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "link",
                                        on: {
                                          click: function($event) {
                                            _vm.opened = !_vm.opened
                                          }
                                        }
                                      },
                                      [
                                        !_vm.opened
                                          ? [
                                              _vm._v(
                                                "\n          Раскрыть все\n        "
                                              )
                                            ]
                                          : [
                                              _vm._v(
                                                "\n          Скрыть все\n        "
                                              )
                                            ]
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _vm._e(),
                        _c("app-disqualified-report-details", {
                          attrs: {
                            opened: _vm.opened || _vm.isPdf,
                            result: _vm.task.task_result.result
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _vm.task
            ? [
                _vm.resultStatus === "not found"
                  ? _c("span", [
                      _vm._v("\n      В реестре ФНС не найден\n    ")
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n      Числится в реестре дисквалифицированных лиц\n    "
                      )
                    ])
              ]
            : _vm.orderIsDone
            ? [
                _c("span", { staticClass: "text-warning" }, [
                  _vm._v(" Недостаточно данных ")
                ])
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }