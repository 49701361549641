var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-report-item",
    {
      attrs: {
        title: "Товарные знаки",
        icon: "trademark",
        opened: true,
        disabled: "",
        status: "done",
        color: "success"
      },
      scopedSlots: _vm._u(
        [
          _vm.trademarks.length
            ? {
                key: "extra-content",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "report-item-result report-item-result__result-wrapper"
                      },
                      [
                        _vm._l(_vm.elems[_vm.currentPage - 1], function(
                          item,
                          index
                        ) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass:
                                "report-item-result__result mosgorsud-result"
                            },
                            [
                              _c("app-report-collapse-item", {
                                attrs: { opened: false },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "header",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__result-elem-text-wrapper"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "report-item-result__result-elem-text"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                Регистрационный номер\n              "
                                                  )
                                                ]
                                              ),
                                              item["РегНомер"]
                                                ? _c("div", [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          item["РегНомер"]
                                                        ) +
                                                        "\n              "
                                                    )
                                                  ])
                                                : _c("div", [
                                                    _vm._v(
                                                      "\n                -\n              "
                                                    )
                                                  ])
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__result-elem-text-wrapper"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "report-item-result__result-elem-text"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                Номер заявки\n              "
                                                  )
                                                ]
                                              ),
                                              item["НомЗаявки"]
                                                ? _c("div", [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          item["НомЗаявки"]
                                                        ) +
                                                        "\n              "
                                                    )
                                                  ])
                                                : _c("div", [
                                                    _vm._v(
                                                      "\n                -\n              "
                                                    )
                                                  ])
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__result-elem-text-wrapper"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "report-item-result__result-elem-text"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                Дата гос.регистрации\n              "
                                                  )
                                                ]
                                              ),
                                              item["ДатаРег"]
                                                ? _c("div", [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          item["ДатаРег"]
                                                        ) +
                                                        "\n              "
                                                    )
                                                  ])
                                                : _c("div", [
                                                    _vm._v(
                                                      "\n                -\n              "
                                                    )
                                                  ])
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "content",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__content-section"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "report-item-result__content-list"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "report-item-result__content-list-elem"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "report-item-result__content-list-elem-title"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    Регистрационный номер\n                  "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "report-item-result__content-list-elem-result"
                                                        },
                                                        [
                                                          item["РегНомер"] &&
                                                          item["СсылкаНаПубл"]
                                                            ? [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      href:
                                                                        item[
                                                                          "СсылкаНаПубл"
                                                                        ],
                                                                      target:
                                                                        "_blank",
                                                                      rel:
                                                                        "noopener noreferrer"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          item[
                                                                            "РегНомер"
                                                                          ]
                                                                        ) +
                                                                        "\n                      "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            : item["РегНомер"]
                                                            ? [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      item[
                                                                        "РегНомер"
                                                                      ]
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]
                                                            : [
                                                                _vm._v(
                                                                  "\n                      -\n                    "
                                                                )
                                                              ]
                                                        ],
                                                        2
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "report-item-result__content-list-elem"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "report-item-result__content-list-elem-title"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    Дата гос.регистрации\n                  "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "report-item-result__content-list-elem-result"
                                                        },
                                                        [
                                                          item["ДатаРег"]
                                                            ? [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      item[
                                                                        "ДатаРег"
                                                                      ]
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]
                                                            : [
                                                                _vm._v(
                                                                  "\n                      -\n                    "
                                                                )
                                                              ]
                                                        ],
                                                        2
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "report-item-result__content-list-elem"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "report-item-result__content-list-elem-title"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    Дата окончания\n                  "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "report-item-result__content-list-elem-result"
                                                        },
                                                        [
                                                          item["ДатаОконч"]
                                                            ? [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      item[
                                                                        "ДатаОконч"
                                                                      ]
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]
                                                            : [
                                                                _vm._v(
                                                                  "\n                      -\n                    "
                                                                )
                                                              ]
                                                        ],
                                                        2
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "report-item-result__content-list-elem"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "report-item-result__content-list-elem-title"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    Номер заявки\n                  "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "report-item-result__content-list-elem-result"
                                                        },
                                                        [
                                                          item["НомЗаявки"]
                                                            ? [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      item[
                                                                        "НомЗаявки"
                                                                      ]
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]
                                                            : [
                                                                _vm._v(
                                                                  "\n                      -\n                    "
                                                                )
                                                              ]
                                                        ],
                                                        2
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        }),
                        _vm.trademarks.length > _vm.perPage
                          ? _c("app-pagination", {
                              attrs: {
                                current: _vm.currentPage,
                                "per-page": _vm.perPage,
                                total: _vm.trademarks.length
                              },
                              on: { "page-changed": _vm.pageChanged }
                            })
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _c(
        "div",
        { staticClass: "report-item-modern__subtitle" },
        [
          _vm.trademarks.length
            ? [
                _vm._v(
                  "\n      Найдено товарных знаков: " +
                    _vm._s(_vm.trademarks.length) +
                    "\n    "
                )
              ]
            : [_vm._v("\n      Товарных знаков не найдено\n    ")]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }