




























































































































































































import Component, { mixins } from 'vue-class-component';
import get from 'lodash/get';

import StatementRightListMixin from 'shared/mixins/statements/statementRightList.mixin';
import AppStatementTableRow from 'shared/components/EgrnObject/StatementTableRow.vue';
import { OBJECT_BY_TYPE } from 'shared/utils/constants/object-types';
import AppEcpSvg from 'shared/components/Static/ecp-print.vue';

@Component({
  components: { AppStatementTableRow, AppEcpSvg },

  props: {
    landCadInfo: Object,
    info: Object,
    objectKey: String,
    statements: Object,
    riskReport: Object,
  },
})
export default class RightListReport extends mixins(StatementRightListMixin) {
  landCadInfo: any;
  info: any;
  objectKey: any;
  statements: any;
  riskReport: any;
  get = get;

  get objectPlan() {
    if (this.egrnTask && this.egrnTask.meta) {
      return this.egrnTask.meta.images;
    }
    return [];
  }

  get egrnTask() {
    if (this.riskReport && this.riskReport.tasks_parse_xml) {
      return this.riskReport.tasks_parse_xml.find((elem: any) => {
        if (elem) {
          return elem.task_type === 'download_service_egrn_object_parse';
        }
      });
    }
    return null;
  }

  objectType(value: any) {
    return OBJECT_BY_TYPE[value];
  }

  regName(owner: StatementRight): string | null {
    if (owner.registration) {
      if (owner.registration.name) {
        return owner.registration.name;
      }

      if (owner.registration.type) {
        let regName = '';

        if (owner.registration.type.type_name) {
          regName = owner.registration.type.type_name;
          if (owner.registration.share_description) {
            regName += `, ${owner.registration.share_description}`;
          } else if (owner.rightList && owner.rightList.registration.share_description) {
            regName += `, ${owner.rightList.registration.share_description}`;
          }
        }

        if (owner.registration.text) {
          regName = `${regName}, ${owner.registration.text}`;
        }

        if (owner.registration.reg_number) {
          regName = `${regName}, № ${owner.registration.reg_number}`;
        }

        if (owner.registration.reg_date) {
          regName = `${regName} от ${owner.registration.reg_date}`;
        }

        return regName;
      }
    }

    return null;
  }
}
