






















































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportCollapseItem from 'shared/components/FizCheck/ReportCollapseItem.vue';
import find from 'lodash/find';
import { getRegionNameById } from 'shared/utils/regions';
import isString from 'lodash/isString';

@Component({
  components: { AppReportCollapseItem },
  props: {
    result: Array,
    opened: Boolean,
  },
})
export default class AppSudRfDetails extends Vue {
  // props
  result: any[];
  // data
  opened: boolean;
  getRegionNameById = getRegionNameById;
  isString = isString;

  getField(item: any, field: string) {
    const res: any = find(item.additionalFields, { name: field });
    if (!res) {
      return null;
    }
    if (!res.value) {
      return res.dateValue;
    }
    return res.value;
  }
}
