var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "estimate" }, [
    _c(
      "div",
      { staticClass: "estimate__title-wrapper" },
      [
        _c("h2", { staticClass: "estimate__title" }, [
          _vm._v("\n      Оценка стоимости\n    ")
        ]),
        _vm._t("download-report-btn")
      ],
      2
    ),
    _c("div", { staticClass: "estimate__houseInfo" }, [
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("p", { staticClass: "estimate__houseInfo-title" }, [
            _vm._v("\n          Адрес\n        ")
          ]),
          _c("p", { staticClass: "estimate__houseInfo-text" }, [
            _vm._v("\n          " + _vm._s(_vm.info.address) + "\n        ")
          ])
        ])
      ]),
      _c("div", { staticClass: "row mb-3 estimate__houseInfo-row" }, [
        _c("div", { staticClass: "col-2" }, [
          _c("p", { staticClass: "estimate__houseInfo-title" }, [
            _vm._v("\n          Комнат\n        ")
          ]),
          _c("p", { staticClass: "estimate__houseInfo-text" }, [
            _vm._v("\n          " + _vm._s(_vm.info.rooms) + "\n        ")
          ])
        ]),
        _c("div", { staticClass: "col-2" }, [
          _c("p", { staticClass: "estimate__houseInfo-title" }, [
            _vm._v("\n          Площадь\n        ")
          ]),
          _c("p", { staticClass: "estimate__houseInfo-text" }, [
            _vm._v("\n          " + _vm._s(_vm.info.area) + " м²\n        ")
          ])
        ]),
        _c("div", { staticClass: "col-2" }, [
          _c("p", { staticClass: "estimate__houseInfo-title" }, [
            _vm._v("\n          Этаж\n        ")
          ]),
          _c("p", { staticClass: "estimate__houseInfo-text" }, [
            _vm._v("\n          " + _vm._s(_vm.info.level) + "\n        ")
          ])
        ]),
        _c("div", { staticClass: "col-auto" }, [
          _c("p", { staticClass: "estimate__houseInfo-title" }, [
            _vm._v("\n          Ремонт\n        ")
          ]),
          _c("p", { staticClass: "estimate__houseInfo-text" }, [
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.getApartmentConditions(_vm.info.apartment_condition)
                ) +
                "\n        "
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "app-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isPdf,
                  expression: "!isPdf"
                }
              ],
              staticClass: "estimate__showExtraFieldsBtn",
              attrs: { variant: "link", block: "" },
              on: {
                click: function($event) {
                  _vm.showExtraFields = !_vm.showExtraFields
                }
              }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.showExtraFields ? "Скрыть" : "Показать ещё") +
                  "\n      "
              )
            ]
          )
        ],
        1
      ),
      _vm.showExtraFields || _vm.isPdf
        ? _c("div", { staticClass: "row" }, [
            _vm.info.kitchenArea
              ? _c(
                  "div",
                  {
                    staticClass: "mb-2",
                    class: _vm.isPdf ? "col-4" : "col-lg-2 col-sm-4 col-6"
                  },
                  [
                    _c("p", { staticClass: "estimate__houseInfo-title" }, [
                      _vm._v("\n          Площадь кухни\n        ")
                    ]),
                    _c("p", { staticClass: "estimate__houseInfo-text" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.info.kitchenArea) +
                          " м²\n        "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm.info.building_type
              ? _c(
                  "div",
                  {
                    staticClass: "mb-2",
                    class: _vm.isPdf ? "col-4" : "col-lg-2 col-sm-4 col-6"
                  },
                  [
                    _c("p", { staticClass: "estimate__houseInfo-title" }, [
                      _vm._v("\n          Тип дома\n        ")
                    ]),
                    _c("p", { staticClass: "estimate__houseInfo-text" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getBuildingType(_vm.info.building_type)) +
                          "\n        "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "mb-2",
                class: _vm.isPdf ? "col-4" : "col-lg-2 col-sm-4 col-6"
              },
              [
                _c("p", { staticClass: "estimate__houseInfo-title" }, [
                  _vm._v("\n          Вид объекта\n        ")
                ]),
                _c("p", { staticClass: "estimate__houseInfo-text" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.getObjectType()) + "\n        "
                  )
                ])
              ]
            ),
            _vm.info.levels
              ? _c(
                  "div",
                  {
                    staticClass: "mb-2",
                    class: _vm.isPdf ? "col-4" : "col-lg-2 col-sm-4 col-6"
                  },
                  [
                    _c("p", { staticClass: "estimate__houseInfo-title" }, [
                      _vm._v("\n          Этажность\n        ")
                    ]),
                    _c("p", { staticClass: "estimate__houseInfo-text" }, [
                      _vm._v(
                        "\n          " + _vm._s(_vm.info.levels) + "\n        "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "mb-2",
                class: _vm.isPdf ? "col-4" : "col-lg-2 col-sm-4 col-6"
              },
              [
                _c("p", { staticClass: "estimate__houseInfo-title" }, [
                  _vm._v("\n          Год постройки\n        ")
                ]),
                _c("p", { staticClass: "estimate__houseInfo-text" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.info.built_year ? _vm.info.built_year : "нет данных"
                      ) +
                      "\n        "
                  )
                ])
              ]
            )
          ])
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "estimate__price" },
      [
        _c("app-estimate-result", {
          attrs: {
            rated: _vm.isPdf ? true : _vm.rated,
            "is-pdf": _vm.isPdf,
            "loading-btn": _vm.loadingBtn
          },
          on: { edit: _vm.updateEstimate, rateEstimate: _vm.sendRateEstimate }
        })
      ],
      1
    ),
    _c("div", { staticClass: "html2pdf__page-break" }),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "estimate__graphic col-12 mb-5" },
        [
          _c("app-price-change-chart", {
            attrs: {
              price_change_house: _vm.priceChangeHouse,
              price_change_area: _vm.priceChangeArea,
              current_price: _vm.info.price
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }