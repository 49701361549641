import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import _ from 'lodash';

import { Validators } from '@/core/utils/validator';
import { Region, regionsSortByName } from 'shared/utils/regions';
import AppRiskUpdateScraperStatusDialog from '../RiskUpdateScraperStatus/RiskUpdateScraperStatus.vue';
import AppOwnerFormItemBase from 'shared/components/RiskNew/AddOwnerForm/OwnerFormItemBase.vue';
import AppMetadataEditor from '../MetadataEditor.vue';
import isUnderage from 'shared/utils/isUnderage';
import { AuthTypes } from 'shared/store/modules/auth/types';

@Component({
  components: { AppOwnerFormItemBase },

  props: {
    item: Object,
    id: String,
    api: Object,
  },
})
export default class RiskReportForm extends Vue {
  // props
  item: OwnerRequestBody;
  id: string;

  // data()
  regions: Region[] = regionsSortByName;

  btnLoading: boolean = false;

  @Inject() Report: RiskReportProvide;

  // computed
  get reportItem(): OwnerItemReport {
    if (this.Report.ownersById[this.id]) {
      return this.Report.ownersById[this.id];
    }

    return null;
  }

  get statusClassName(): string {
    if (!this.reportItem) {
      return '';
    }

    switch (this.reportItem.status) {
      case 'complete':
        return 'text-success';
      case 'in-progress':
        return 'text-info';
      case 'error':
        return 'text-danger';
      default:
        return 'text-purple';
    }
  }

  get isProcessing(): boolean {
    return this.reportItem && this.reportItem.status === 'in-progress';
  }

  get statusText(): string {
    if (!this.reportItem) {
      return null;
    }

    switch (this.reportItem.status) {
      case 'complete':
        return 'Выполнено';
      case 'in-progress':
        return 'Выполняется';
      case 'error':
        return 'Ошибка';
      default:
        return 'В очереди на выполнение';
    }
  }

  get isAdmin(): boolean {
    return this.$store.getters[AuthTypes.getters.IS_ADMIN];
  }

  // methods
  validationConfig(model: OwnerRequestBody): ValidationConfig {
    if (model.ownerType === 1) {
      let config: ValidationConfig = {
        name: [ Validators.required ],
        surname: [ Validators.required ],
        // patronymic: [ Validators.required ],
        passport: [ Validators.required, Validators.min(10, 'Обязательное поле') ],
        birthday: [ Validators.required, Validators.date('Обязатльное поле') ],
        region: [ Validators.required ],
      };

      if (isUnderage(model.birthday)) {
        config = _.omit(config, 'passport', 'region');
      }

      return config;
    } else {
      return {
        name: [ Validators.required ],
        inn: [ Validators.required, Validators.min(10, 'Обязательное поле') ],
        region: [ Validators.required ],
      };
    }
  }

  onSubmit() {
    this.btnLoading = true;
    this.$emit('create', this.item);
  }

  async updateUserMetadata() {
    this.btnLoading = true;

    try {
      await this.$api.risk.UpdateOwnerItem({ owner: this.item, ownerId: this.id });

      this.btnLoading = false;
      this.$noty.success({ text: 'Обновлено' });
      this.$emit('updated', this.id);
    } catch (error) {
      this.$noty.error({ text: 'Ошибка при обновлении данных' });
      console.error(error);
    }

    this.btnLoading = false;
  }

  async removeItem() {
    let forceUpdate = false;

    if (this.reportItem && confirm('Удалить этого собственника')) {
      this.btnLoading = true;

      try {
        await this.$api.risk.RemoveOwnerItem({ ownerId: this.id });

        this.$noty.success({ text: 'Успешно удалён' });
        forceUpdate = true;
      } catch (error) {
        this.$noty.error({ text: 'Ошибка при удалении' });
        this.btnLoading = false;
        return;
      }

      this.btnLoading = false;

      this.$emit('remove', this.id, forceUpdate);
    }
  }

  showUpdateStatus() {
    this.$dialog.open({
      component: AppRiskUpdateScraperStatusDialog,
      props: {
        report: this.reportItem,
      },
    });
  }

  showMetadataEditor() {
    this.$dialog.open({
      component: AppMetadataEditor,
      props: {
        ownerId: this.id,
        owner: this.item,
      },
    });
  }

  // lifecycle hooks
  created() {
    if (this.item.birthday === '0001-01-01' || this.item.birthday === '31.12.0000' || this.item.birthday === '') {
      this.item.birthday = 'не предоставлено';
    }
  }

  // async resetItem) {
  //   this.btnLoading = true;
  //   try {
  //     await this.$api.risk.ResetReportItem({
  //       reportItemId: this.reportItem.itemId,
  //     });

  //     this.reportItem.status = 'pending';
  //   } catch (error) {
  //     console.log(error);
  //     this.$noty.error({ text: this.$api.getErrorMessage(error) });
  //   }

  //   this.btnLoading = false;
  // }
}
