var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          loading: _vm.loading,
          pagination: _vm.pagination,
          "item-key": "OrderID"
        },
        on: { "page-changed": _vm.pageChanged },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("td", [
                  _c("div", [
                    _vm._v(_vm._s(_vm._f("date")(row.created_at, "DD.MM.YYYY")))
                  ]),
                  _c("div", [
                    _vm._v(_vm._s(_vm._f("date")(row.created_at, "HH:mm:ss")))
                  ])
                ]),
                _c("td", { staticStyle: { "max-width": "150px" } }, [
                  _vm._v("\n        " + _vm._s(row.action) + "\n      ")
                ]),
                _c("td", [_vm._v(_vm._s(row.ip))]),
                _c(
                  "td",
                  [
                    row.input_data.redirect_url
                      ? [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: row.input_data.redirect_url,
                                target: "_blank"
                              }
                            },
                            [_vm._v("ссылка")]
                          )
                        ]
                      : _vm._e(),
                    row.input_data.order_id
                      ? [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: row.input_data.order_id,
                                target: "_blank"
                              }
                            },
                            [_vm._v("отчет")]
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }