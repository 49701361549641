var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-report-item",
    {
      attrs: {
        title: "Реестр недобросовестных поставщиков",
        icon: "user-times",
        opened: true,
        status: "done",
        color: "success"
      }
    },
    [
      _c(
        "div",
        { staticClass: "report-item-modern__subtitle" },
        [
          _vm.unfair
            ? [
                _vm._v(
                  "\n      Cостоит в реестре недобросовестных поставщиков (подрядчиков, исполнителей)\n    "
                )
              ]
            : [
                _vm._v(
                  "\n      Не состоит в реестре недобросовестных поставщиков (подрядчиков, исполнителей)\n    "
                )
              ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }