var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("h5", [_vm._v("Изменение баланса")]),
      _c("app-dialog-close"),
      _c(
        "app-form",
        {
          attrs: {
            model: _vm.model,
            "request-handler": _vm.submit,
            rules: _vm.validationConfig
          },
          scopedSlots: _vm._u([
            {
              key: "button",
              fn: function(ref) {
                var loading = ref.loading
                return [
                  _c(
                    "app-button",
                    { attrs: { loading: loading, type: "submit", block: "" } },
                    [_vm._v("\n        Изменить\n      ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c("app-form-group", [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.action,
                    expression: "action"
                  }
                ],
                staticClass: "custom-select",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.action = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c("option", { attrs: { value: "add" } }, [
                  _vm._v("\n          Пополнить\n        ")
                ]),
                _c("option", { attrs: { value: "withdraw" } }, [
                  _vm._v("\n          Списать\n        ")
                ])
              ]
            )
          ]),
          _c(
            "app-form-group",
            { attrs: { label: "Сумма", "model-name": "amount" } },
            [
              _c("app-input", {
                model: {
                  value: _vm.model.amount,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "amount", _vm._n($$v))
                  },
                  expression: "model.amount"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }