var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("app-report-item", {
    attrs: {
      title: _vm.capitalize(_vm.result["ИСТОЧНИК"]),
      icon: "book-reader",
      status: "done",
      color: "primary",
      opened: true
    },
    scopedSlots: _vm._u([
      {
        key: "extra-content",
        fn: function() {
          return [
            _c(
              "div",
              {
                staticClass:
                  "report-item-result report-item-result__result-wrapper"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "report-item-result__result extended-report-alt-divider"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "report-item-result__content-list" },
                      _vm._l(_vm.result, function(value, name) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: name !== "SOURCE",
                                expression: "name !== 'SOURCE'"
                              }
                            ],
                            key: name,
                            staticClass: "report-item-result__content-list-elem"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__content-list-elem-title"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(name) +
                                    "\n            "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__content-list-elem-result"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(value) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              ]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }