var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.uniqueProfiles.length
    ? _c(
        "div",
        { staticClass: "social-media-modern__result-elem" },
        [
          _c(
            "app-collapse",
            [
              _c(
                "app-collapse-item",
                {
                  staticClass: "social-media-modern-collapse",
                  attrs: { opened: _vm.$isServer }
                },
                [
                  _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                    _c(
                      "div",
                      { staticClass: "social-media-modern__result-elem-title" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "social-media-modern__result-elem-image"
                          },
                          [_vm._t("default")],
                          2
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "social-media-modern__result-elem-text-wrapper social-media-modern__result-elem-text-wrapper--grow"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "social-media-modern__result-elem-text d-none d-md-block"
                              },
                              [
                                _vm._v(
                                  "\n              Название соцсети/мессенджера\n            "
                                )
                              ]
                            ),
                            _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.name) +
                                  "\n              "
                              ),
                              _c("span", { staticClass: "d-md-none" }, [
                                _vm._v(
                                  "\n                (" +
                                    _vm._s(_vm.uniqueProfiles.length) +
                                    ")\n              "
                                )
                              ])
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "social-media-modern__result-elem-text-wrapper d-none d-md-block"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "social-media-modern__result-elem-text"
                              },
                              [
                                _vm._v(
                                  "\n              Найдено совпадений\n            "
                                )
                              ]
                            ),
                            _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.uniqueProfiles.length) +
                                  "\n            "
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "social-media-modern__content-wrapper" },
                    _vm._l(_vm.uniqueProfiles, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.request,
                          staticClass: "social-media-modern__content"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "social-media-modern__result-elem-image"
                            },
                            [
                              _vm.getField(item, "photo")
                                ? _c("img", {
                                    staticClass: "social-media-modern__photo",
                                    attrs: { src: _vm.getField(item, "photo") }
                                  })
                                : _vm.getField(item, "FullPhoto")
                                ? _c("img", {
                                    staticClass: "social-media-modern__photo",
                                    attrs: {
                                      src: _vm.getField(item, "FullPhoto")
                                    }
                                  })
                                : _c("img", {
                                    staticClass: "social-media-modern__photo",
                                    attrs: {
                                      src:
                                        "https://fizcheck.ru/rc-modern/photo.svg"
                                    }
                                  })
                            ]
                          ),
                          _vm.getField(item, "Name")
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "social-media-modern__result-elem-text-wrapper"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "social-media-modern__result-elem-text"
                                    },
                                    [
                                      _vm._v(
                                        "\n              Имя пользователя\n            "
                                      )
                                    ]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getField(item, "Name")) +
                                        "\n            "
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.getField(item, "phone")
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "social-media-modern__result-elem-text-wrapper"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "social-media-modern__result-elem-text"
                                    },
                                    [
                                      _vm._v(
                                        "\n              Телефон\n            "
                                      )
                                    ]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getField(item, "phone")) +
                                        "\n            "
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm.getField(item, "Link")
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "social-media-modern__result-elem-text-wrapper"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "social-media-modern__result-elem-text"
                                    },
                                    [
                                      _vm._v(
                                        "\n              Ссылка  на профиль\n            "
                                      )
                                    ]
                                  ),
                                  _c("div", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.getField(item, "Link"),
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.getField(item, "Link")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }