var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c("h5", { staticClass: "text-center mb-4" }, [
        _vm._v("\n    Добавление купонов к пользователю\n  ")
      ]),
      _c(
        "app-form",
        {
          attrs: {
            "request-handler": _vm.onSubmit,
            model: _vm.model,
            rules: _vm.validationRules
          },
          scopedSlots: _vm._u([
            {
              key: "button",
              fn: function(ref) {
                var loading = ref.loading
                return [
                  _c(
                    "app-button",
                    { attrs: { type: "submit", loading: loading, block: "" } },
                    [_vm._v("\n        Добавить\n      ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "app-form-group",
            { attrs: { "model-name": "person_id" } },
            [
              _c("app-input", {
                attrs: { placeholder: "ID пользователя" },
                model: {
                  value: _vm.model.person_id,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "person_id", $$v)
                  },
                  expression: "model.person_id"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            { attrs: { "model-name": "count" } },
            [
              _c("app-input", {
                attrs: {
                  type: "number",
                  placeholder: "Количество использований купонов"
                },
                model: {
                  value: _vm.model.count,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "count", _vm._n($$v))
                  },
                  expression: "model.count"
                }
              })
            ],
            1
          ),
          _c("app-form-group", { attrs: { "model-name": "campaign_id" } }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.campaign_id,
                    expression: "model.campaign_id"
                  }
                ],
                staticClass: "custom-select",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.model,
                      "campaign_id",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c(
                  "option",
                  { attrs: { selected: "" }, domProps: { value: null } },
                  [_vm._v("\n          Тип купона\n        ")]
                ),
                _vm._l(_vm.campaignsById, function(campaign) {
                  return _c(
                    "option",
                    { key: campaign.ID, domProps: { value: campaign.ID } },
                    [
                      _vm._v(
                        "\n          Скидка - " +
                          _vm._s(campaign.Discount) +
                          " руб. (" +
                          _vm._s(campaign.Description) +
                          " - " +
                          _vm._s(campaign.Name) +
                          ")\n        "
                      )
                    ]
                  )
                })
              ],
              2
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }