


































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import get from 'lodash/get';

@Component({
  components: { AppReportItem },
  props: {
    report: Object,
  },
})
export default class AppCounterAgentFounders extends Vue {
  // props
  report: any;

  get founders() {
    return get(this.report, 'СвУчредит.all', []);
  }

  get cap() {
    return get(this.report, 'СвУчредит.sumCap', 0);
  }

  founderCap(value: string) {
    return (100 * parseInt(value)) / this.cap;
  }

  goCheckPerson(name: string) {
    const [ surname, first, patronymic, other ] = name
      .trim()
      .split(/\s+/)
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1));
    const query: any = {
      first: first || '',
      surname: surname || '',
      patronymic: (patronymic || '') + (other ? ` ${other}` : ''),
    };
    const routeData = this.$router.resolve({ name: 'check-person', query });
    if (window) {
      window.open(routeData.href, '_blank');
    }
  }
}
