

























































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import get from 'lodash/get';

@Component({
  components: { AppReportItem },
  props: {
    report: Object,
  },
})
export default class AppCounterAgentFounders extends Vue {
  // props
  report: any;

  get founders() {
    return get(this.report, 'СвУчредит.all', []);
  }

  get cap() {
    return get(this.report, 'СвУчредит.sumCap', 0);
  }

  founderCap(value: string) {
    return (100 * parseInt(value)) / this.cap;
  }
}
