var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", [_vm._v("\n    В базе данных розыска УФСИН:\n  ")]),
      _vm.result.result && _vm.result.result.length
        ? [
            _vm._m(0),
            !_vm.$isServer
              ? _c(
                  "span",
                  { staticClass: "link", on: { click: _vm.showDetails } },
                  [_vm._v("\n      подробнее\n    ")]
                )
              : _vm._e()
          ]
        : [_vm._m(1)]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "text-danger" }, [
      _c("i", { staticClass: "fas fa-exclamation-circle fa-sm fa-fw mr-1" }),
      _c("b", [_vm._v("числится")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "text-success" }, [
      _c("i", { staticClass: "fas fa-check-circle fa-sm fa-fw mr-1" }),
      _c("b", [_vm._v("не числится")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }