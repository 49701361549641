























import Vue from 'vue';
import Component from 'vue-class-component';
import AppCounterAgentReport from 'shared/components/CounterAgent/CounterAgentReport.vue';

@Component({
  popupOptions: {
    name: 'popup_full',
  },

  components: { AppCounterAgentReport },

  props: {
    report: Object,
    fetchReport: Function,
    demo: Boolean,
    download: Function,
    allowPdfDownload: Boolean,
  },
})
export default class CounterAgentPdfPreview extends Vue {
  print() {
    window.print();
  }
}
