var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-report-item",
    {
      attrs: {
        title: "Учредители",
        icon: "chart-pie",
        opened: true,
        disabled: "",
        status: "done",
        color: "success"
      },
      scopedSlots: _vm._u(
        [
          _vm.founders.length
            ? {
                key: "extra-content",
                fn: function() {
                  return [
                    _c("div", [
                      _c("div", { staticClass: "criminal-report__divider" }),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column gap-8 divided" },
                        [
                          _c("div", { staticClass: "font-weight-500" }, [
                            _vm._v(
                              "\n          Всего " +
                                _vm._s(_vm.founders.length) +
                                "\n        "
                            )
                          ]),
                          _c("div", {
                            staticClass: "divider-200 d-none d-md-block"
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row no-gutters modern-table-head d-none d-md-flex"
                            },
                            [
                              _c("div", { staticClass: "col-4" }, [
                                _vm._v("\n            Учредитель\n          ")
                              ]),
                              _c("div", { staticClass: "col-4" }, [
                                _vm._v(
                                  "\n            Доля в уставном капитале\n          "
                                )
                              ]),
                              _c("div", { staticClass: "col-4" }, [
                                _vm._v(
                                  "\n            Дата последнего упоминания\n          "
                                )
                              ])
                            ]
                          ),
                          _c("div", { staticClass: "divider-200" }),
                          _vm._l(_vm.founders, function(elem) {
                            return _c(
                              "div",
                              {
                                key: elem.fl_aff,
                                staticClass: "row no-gutters table-modern-tr"
                              },
                              [
                                _c("div", { staticClass: "col-12 col-md-4" }, [
                                  _c(
                                    "div",
                                    { staticClass: "table-modern-mobile-head" },
                                    [
                                      _vm._v(
                                        "\n              Учредитель\n            "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-column gap-8" },
                                    [
                                      elem.fl_aff
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(elem.fl_aff) +
                                                "\n              "
                                            )
                                          ])
                                        : elem.name
                                        ? _c("div", { staticClass: "small" }, [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(elem.name) +
                                                "\n              "
                                            )
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "table-modern-tr__subtext"
                                        },
                                        [
                                          _vm._v(
                                            "\n                ИНН " +
                                              _vm._s(elem.inn) +
                                              "\n                "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "link",
                                              on: {
                                                click: function($event) {
                                                  return _vm.goCheckPerson(
                                                    elem.name || elem.fl_aff
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Проверить\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "col-12 col-md-4" }, [
                                  _c(
                                    "div",
                                    { staticClass: "table-modern-mobile-head" },
                                    [
                                      _vm._v(
                                        "\n              Доля в уставном капитале\n            "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-column gap-8" },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("currency")(elem.dol_abs)
                                            ) +
                                            " /\n                " +
                                            _vm._s(
                                              _vm.founderCap(elem.dol_abs)
                                            ) +
                                            "%\n              "
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "col-12 col-md-4" }, [
                                  _c(
                                    "div",
                                    { staticClass: "table-modern-mobile-head" },
                                    [
                                      _vm._v(
                                        "\n              Дата последнего упоминания\n            "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-column gap-8" },
                                    [
                                      elem.date
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(elem.date) +
                                                "\n              "
                                            )
                                          ])
                                        : _c("div", [
                                            _vm._v(
                                              "\n                -\n              "
                                            )
                                          ])
                                    ]
                                  )
                                ])
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ])
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      !_vm.founders.length
        ? _c("div", { staticClass: "report-item-modern__subtitle" }, [
            _vm._v("\n    Учредители не найдены\n  ")
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }