import Component, { mixins } from 'vue-class-component';
import { mapState } from 'vuex';
import AppChangeBalanceDialog from '../../components/ChangeBalanceDialog.vue';
import DataTableMixin from '@/core/mixins/data-table.mixin';

@Component({
  props: {
    // route param to props
    userID: String,
  },
  computed: {
    ...mapState('auth', [ 'balance', 'balanceDiff' ]),
  },
})
export default class AdminUserFinance extends mixins(DataTableMixin) {
  // props
  userID: string;
  balance!: number;
  balanceDiff!: number;

  // data
  headers = [
    {
      text: 'Тип',
      align: 'left',
    },
    {
      text: 'Сумма на балансе',
      align: 'left',
    },
    {
      text: '',
      align: 'left',
    },
  ] as TableHeaderItem[];
  items = [
    {
      type: 1,
      name: 'Баланс аккаунта',
      amount: null,
    },
    {
      type: 2,
      name: 'Баланс партнёра',
      amount: null,
    },
  ] as Admin.Balance.Item[];

  showChangeBalanceDialog(balanceType: string) {
    this.$dialog.open({
      component: AppChangeBalanceDialog,
      props:
      { userID: this.userID,
        balanceType,
        onSuccess: this.updateBalance,
      },
    });
  }

  async updateBalance() {
    this.loading = true;
    setTimeout(this.handleRequest, 5000);
  }

  async handleRequest() {
    this.loading = true;
    try {
      const user: User[] = await this.$api.admin.Users({ id: this.userID });
      this.items[0].amount = +user[0].account_balance.Balance;
      if (user[0].partner_account) {
        this.items[1].amount = +user[0].partner_account.CurrentBalance;
      } else {
        this.items[1].amount = 0;
      }
      return this.items;
    } catch (error) {
      return this.$api.HttpError(error);
    } finally {
      this.loading = false;
    }
  }
}
