























































































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import first from 'lodash/first';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import AppFizCheckReport from 'shared/components/FizCheck/FizCheckReport.vue';
import AppMaleUserImg from 'shared/components/Static/MaleUserImg.vue';
import AppFizcheckLogo from 'shared/components/Static/FizcheckLogo.vue';
import AppFizcheckLogoShort from 'shared/components/Static/FizcheckLogoShort.vue';
import AppLogoSvg from 'shared/components/Static/logo-new.vue';
import isUnderage from 'shared/utils/isUnderage';
import differenceInYears from 'date-fns/difference_in_years';
import { checkPersonTasks } from 'shared/utils/checkPersonTasks';
import AppScrollTop from 'shared/components/ui/ScrollTop/ScrollTop.vue';

@Component({
  components: {
    AppFizCheckReport,
    AppMaleUserImg,
    AppFizcheckLogo,
    AppFizcheckLogoShort,
    AppLogoSvg,
    AppScrollTop,
  },
  props: {
    demo: {
      type: Boolean,
      default: false,
    },
    report: Object,
    fetchReport: Function,
    showExtendedReportSteps: {
      type: Boolean,
      default: false,
    },
    extendedReportEnabled: {
      type: Boolean,
      default: false,
    },
    extendedReportReturned: {
      type: Boolean,
      default: false,
    },
    allowPdfDownload: {
      type: Boolean,
      default: false,
    },
    isPdf: {
      type: Boolean,
      default: false,
    },
    returnExtendedReport: Function,
    download: Function,
  },
})
export default class AppFizcheckReportWrapper extends Vue {
  downloadLoading: boolean = false;
  loading: boolean = true;
  order: any = null;
  id: string = null;
  timerId: any = null;
  riskReportV2!: LawInformation;
  refreshInterval: any = null;
  refreshCounter: number = 0;
  isUnderage = isUnderage;
  user: User;
  balance: number;
  tariff: number = null;
  checkOwnerDiscount: UserDiscount = null;
  orderIsPaid: boolean = true;
  timer: any = null;
  aiReportTask: string = 'data_analysis_owner';
  get = get;
  // props
  report: LawInformation;
  fetchReport: () => any;
  returnExtendedReport: () => any;
  download: () => any;
  isPdf: boolean;
  allowPdfDownload: boolean;
  extendedReportReturned: boolean;
  showExtendedReportSteps: boolean;
  extendedReportEnabled: boolean;
  demo: boolean;
  hideUlixes: boolean = false;
  findedRefund: boolean = false;
  hideRateDesign: boolean = false;

  get isComplete(): boolean {
    if (!this.report) {
      return false;
    }
    return this.report.order.status === 'done';
  }

  get owner(): LawInformationOwnerElem {
    return first(this.report.owners);
  }

  get isPhysical() {
    return this.owner.owner.owner_type !== 1;
  }

  get isFizcheckOrder() {
    const source = get(this.report, 'global_data.url_source', '');
    return /fizcheck/.test(source);
  }

  get settings() {
    return get(this.report, 'global_data.person_settings', []);
  }

  get haveLogo() {
    const logoPerm = this.getSetting('add_logo_permission');
    const logo = this.getSetting('company_logo_url');
    if (logoPerm && logoPerm.value === 'active' && logo && logo.value) {
      return logo.value;
    } else return null;
  }

  get companyName() {
    const companyName = this.getSetting('company_name');
    if (this.haveLogo && companyName && companyName.value) {
      return companyName.value;
    } else return null;
  }

  // get designRated() {
  //   if (this.hideRateDesign) {
  //     return true;
  //   }
  //   const item = LocStorage.getItem('new_design_rated');
  //   return !!item;
  // }

  get image() {
    let photo = '';
    if (!isEmpty(this.sources)) {
      this.sources.find((elem: any) => {
        const recordFields = get(elem, 'record.0.fields');
        if (recordFields) {
          const photoField = recordFields.find((field: any) => {
            return [ 'FullPhoto', 'photo' ].includes(field.field_name);
          });
          if (photoField) {
            photo = photoField.field_value;
            return true;
          }
        }
      });
    }
    return photo;
  }

  get sources() {
    return get(this.socialMediaTask, 'task_result.result.sources');
  }

  get ulixesNotRequired() {
    const task = this.getTask(this.aiReportTask);
    if (task && task.status === 'not required') {
      return task;
    } else return false;
  }

  get sortedConclusions(): ConclusionItem[] {
    // const res: any = {
    //   high: [],
    //   medium: [],
    //   low: [],
    //   check: [],
    //   info: [],
    // };
    // this.report.conclusions.forEach((elem: ConclusionItem) => {
    //   res[elem.color_level].push(elem);
    // });
    const res = this.report.conclusions;
    if (res && res.length) {
      const finded = res.find(el => {
        return el.text.includes('для формирования отчета данные не найдены');
      });
      if (finded) {
        this.findedRefund = true;
        return [ finded ];
      }
    }
    return res;
    // return [].concat(res.high, res.medium, res.low, res.check, res.info);
  }

  get age() {
    return differenceInYears(Date.now(), this.owner.owner.birthday);
  }

  get parsersToCheck(): string[] {
    const tasksNames = checkPersonTasks();
    let checkKeys: string[] = [];
    checkKeys = [
      tasksNames.passport,
      tasksNames.criminal,
      tasksNames.bankrupt,
      tasksNames.individualDebt,
      tasksNames.bailiffDebt,
      tasksNames.interpol,
      tasksNames.foreignAgent,
      tasksNames.judicialActs,
      tasksNames.arbitrReport,
      tasksNames.selfEmployed,
      tasksNames.ceo,
      tasksNames.terrorism,
      tasksNames.disqualified,
      tasksNames.pledge,
      tasksNames.fsin,
      tasksNames.socialMedia,
      tasksNames.snils,
      tasksNames.license,
      tasksNames.phone,
      tasksNames.bills,
    ];
    if (!this.isPhysical) {
      checkKeys = [
        tasksNames.bankrupt,
        tasksNames.bailiffDebt,
        tasksNames.judicialActs,
        tasksNames.arbitrReport,
      ];
    }

    return checkKeys;
  }

  get completedCount(): number {
    const owner = first(this.report.owners);
    let completed = 0;

    this.parsersToCheck.forEach(path => {
      const task = owner.tasks.find(task => task.task_type === path);
      if (task && task.status === 'done') {
        completed += 1;
      }
      // if (task && ![ 'in progress', 'not_required', 'failed', 'soft_failed' ].includes(task.status)) {
      //   completed += 1;
      // }
    });

    return completed;
  }

  get isInProgress(): boolean {
    const owner = first(this.report.owners);
    return this.parsersToCheck.some(path => {
      const task = owner.tasks.find(task => task.task_type === path);
      return task && [ 'in progress', 'not required' ].includes(task.status);
    });
  }

  get completedProgress(): number {
    return (this.completedCount / this.totalParsers) * 100;
  }

  get totalParsers() {
    return this.parsersToCheck.length;
  }

  get basicTariff(): boolean {
    return this.tariff === 0;
  }

  get checkOwnerDiscountPercent(): number {
    const discountStr = this.checkOwnerDiscount.name.slice(
      this.checkOwnerDiscount.name.length - 4
    );
    return parseInt(discountStr);
  }

  get auth(): User {
    return this.$store.state.auth.user;
  }

  get checkOwnerPrice(): number {
    return 297;
    // if (this.order && this.order.OrderItem[0].Price) {
    //   return parseInt(this.order.OrderItem[0].Price, 10);
    // }
    // return null;
  }

  // get ulixes() {
  //   const res = this.getTask(this.aiReportTask);
  //   const ulixes = this.getTask('ulixes');
  //   console.log(res, 'res');
  //   if (res && res.status === 'done' && isArray(res.task_result.result)) {
  //     const { result } = res.task_result.result.find((el: any) => el.name === 'sum');
  //     return result;
  //   } else if (ulixes && ulixes.status === 'done' && isArray(ulixes.task_result.result)) {
  //     return ulixes.task_result.result;
  //   }
  //   return null;
  // }

  get ulixes() {
    const res = this.getTask('data_analysis_owner');
    if (res && res.status === 'done' && isArray(res.task_result.result)) {
      const result = res.task_result.result.find(
        (el: any) => el.name === 'sum'
      );
      const items = get(result, 'users.0.items', []);
      return items;
    }
    return null;
  }

  get ulixesInProgress() {
    const res = this.getTask(this.aiReportTask);
    if (res && [ 'failed', 'in progress' ].includes(res.status)) {
      return true;
    }
    return false;
  }

  get socialMediaTask() {
    const first = this.getTask('isphere');
    const second = this.getTask('isphere_phone');
    const secondSources = get(second, 'task_result.result.sources');
    const firstSources = get(first, 'task_result.result.sources');
    if (second && secondSources && first && firstSources) {
      first.task_result.result.sources = firstSources.concat(secondSources);
    }
    return first;
  }

  async created() {
    // this.id = this.$route.params.id;

    // const minutes = 60 * 30;
    // this.startTimer(minutes);

    // // если в заказе старый checkOwner то перенаправляем на старую версию страницы
    // if (this.user) {
    //   try {
    //     const [ orders ] = await Promise.all([
    //       this.$api.user.OrdersList({ orderItemID: this.id }),
    //     ]);

    //     this.order = first(orders);
    //     if (this.order && this.order.OrderItem && this.order.OrderItem[0].ProductName === 'CheckOwner') {
    //       this.$router.push({
    //         name: 'check-owner-old-id',
    //         params: { id: this.id },
    //       });
    //       return;
    //     }
    //     // проверяем оплачен ли заказ, если нет то будем отображать не все таски
    //     const { is_order_paid: isOrderPaid } = await this.$api.order.CheckOrderPayment(this.order.OrderID);
    //     this.orderIsPaid = isOrderPaid;
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }
    // await this.fetchReport();
    // await this.getToken();
    // if (!this.report) {
    //   this.refreshInterval = setInterval(this.waitReport, 1000);
    // } else {
    //   this.loading = false;
    //   if (!this.isComplete) {
    //     this.timerId = setInterval(this.fetchReport, 10000);
    //   }
    // }
    // if (this.$route.query.showExtendedReportSteps) {
    //   this.showExtendedReportSteps = true;
    // }
    // window.addEventListener('scroll', this.handleScroll);
  }

  // handleScroll() {
  //   const navbar = document.getElementById('ownerInfoHeader');
  //   const sideNav = document.getElementById('fizcheck-side-nav');
  //   // const sticky = navbar.offsetTop;
  //   if (window.scrollY > navbar.scrollHeight + 100) {
  //     navbar.classList.add('check-fiz__owner-info--sticky');
  //     sideNav.classList.add('fizcheck-side-navigation--header-sticky');
  //   } else {
  //     navbar.classList.remove('check-fiz__owner-info--sticky');
  //     sideNav.classList.remove('fizcheck-side-navigation--header-sticky');
  //   }
  // }

  // beforeDestroy() {
  //   window.removeEventListener('scroll', this.handleScroll);
  //   if (this.timerId != null) {
  //     clearInterval(this.timerId);
  //   }
  //   if (this.refreshInterval != null) {
  //     clearInterval(this.refreshInterval);
  //   }
  // }

  scrollTo(id: string) {
    const elem = document.getElementById(id);
    const headerOffset = 100;
    const elementPosition = elem.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  getSetting(key: string) {
    if (this.settings && this.settings.length) {
      return this.settings.find((elem: any) => {
        return elem.key === key;
      });
    }
  }

  // confirmPay() {
  //   if (this.balance >= this.checkOwnerPrice) {
  //     this.$dialog.open({
  //       component: AppPaymentConfirmDialog,
  //       props: {
  //         orderAmount: this.checkOwnerPrice,
  //         onConfirm: () => this.buyReport(),
  //       },
  //     });
  //   } else {
  //     this.buyReport();
  //   }
  // }

  // async buyReport() {
  //   this.setCheckOwnerProduct();
  //   const owners = this.report.owners.map((elem) => elem.owner);

  //   this.$store.commit(AuthTypes.mutations.SET_UTM_COUPON, 'fiz52');
  //   await this.$store.dispatch(ProductTypes.actions.GET_PRODUCTS);

  //   this.$store.commit(OrderTypes.mutations.SET_OWNERS, owners);
  //   this.$store.commit(OrderTypes.mutations.SET_OBJECT_KEY, 'n/a');
  //   let order = null;

  //   const orderOptions: OrderOptions = {
  //     use_account_balance: true,
  //   };

  //   try {
  //     order = await this.$store.dispatch(OrderTypes.actions.SEND, orderOptions);
  //   } catch (error) {
  //     this.$noty.error({ text: this.$api.getErrorMessage(error as any) });
  //     console.error(error);
  //     return;
  //   }
  //   const orderItem = order.order_items[0];
  //   const params: any = {};
  //   params.user_id = this.auth.id;
  //   params.ya = {
  //     order_price: orderItem.price,
  //     currency: 'RUB',
  //   };
  //   this.$analytics.send(`${orderItem.product_name}_success`, params);
  //   this.$router.push({
  //     name: 'check-person-id',
  //     params: { id: orderItem.order_item_id },
  //   });
  // }

  generateReport() {
    if (!this.isComplete) {
      return this.$noty.info({
        text: 'Отчёт ещё находится в работе, пожалуйста, дождитесь завершения',
      });
    }
    this.downloadLoading = true;
    (this.$refs.checkOwnerPdf as any).generateReport();
    this.downloadLoading = false;
  }

  getTask(name: string) {
    const owner = first(this.report.owners);
    return owner.tasks.find((elem: any) => {
      return elem.task_type === name;
    });
  }

  // async rateDesign(value: boolean) {
  //   const template = `
  //     Пользователь с email
  //     ${this.user.email},
  //     оценил новый дизайн:
  //     ${value ? 'ПОЛОЖИТЕЛЬНО (наш слон!)' : 'ОТРИЦАТЕЛЬНО (надо узнать где он живет)'},
  //   `;
  //   try {
  //     await this.$api.events.SendEmail({
  //       template,
  //       email: 'fazleev@realtycloud.ru',
  //       title: 'Оценка нового дизайна',
  //     });
  //     LocStorage.setItem('new_design_rated', true);
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   this.hideRateDesign = true;
  // }
}
