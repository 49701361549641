var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "report-item-result__graph" }, [
      _c(
        "div",
        { staticClass: "report-item-result__graph-counters-col" },
        _vm._l(_vm.counters, function(elem, name) {
          return _c(
            "div",
            {
              key: name,
              staticClass: "report-item-result__graph-counters-elem"
            },
            [
              _c(
                "div",
                { staticClass: "report-item-result__graph-counters-elem-key" },
                [_vm._v("\n          " + _vm._s(name) + "\n        ")]
              ),
              _c(
                "div",
                {
                  staticClass: "report-item-result__graph-counters-elem-result"
                },
                [_vm._v("\n          " + _vm._s(elem) + "\n        ")]
              )
            ]
          )
        }),
        0
      )
    ]),
    _vm.cases.length > 1
      ? _c("div", { staticClass: "text-right report-open-all mt-3" }, [
          !_vm.$isServer
            ? _c(
                "span",
                {
                  staticClass: "link",
                  on: {
                    click: function($event) {
                      _vm.opened = !_vm.opened
                    }
                  }
                },
                [
                  !_vm.opened
                    ? [_vm._v("\n        Раскрыть все\n      ")]
                    : [_vm._v("\n        Скрыть все\n      ")]
                ],
                2
              )
            : _vm._e()
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "report-item-result report-item-result__result-wrapper" },
      [
        _c("div", { staticClass: "report-item-result__graph-filter mb-2" }, [
          _c("div", { staticClass: "report-item-result__graph-filter-radio" }, [
            _c(
              "div",
              {
                staticClass: "report-item-result__graph-filter-radio-btn",
                attrs: { active: _vm.currentFilter === "all" },
                on: {
                  click: function($event) {
                    _vm.currentFilter = "all"
                  }
                }
              },
              [_vm._v("\n          Все\n        ")]
            ),
            _c(
              "div",
              {
                staticClass: "report-item-result__graph-filter-radio-btn",
                attrs: {
                  active: _vm.currentFilter === "active",
                  disabled: !_vm.activeCases.length
                },
                on: {
                  click: function($event) {
                    _vm.currentFilter = "active"
                  }
                }
              },
              [
                _vm._m(0),
                _vm._v(
                  "\n          Действующие (" +
                    _vm._s(_vm.activeCases.length) +
                    ")\n        "
                )
              ]
            ),
            _c(
              "div",
              {
                staticClass: "report-item-result__graph-filter-radio-btn",
                attrs: {
                  active: _vm.currentFilter === "closed",
                  disabled: !_vm.closedCases.length
                },
                on: {
                  click: function($event) {
                    _vm.currentFilter = "closed"
                  }
                }
              },
              [
                _vm._m(1),
                _vm._v(
                  "\n          Ликвидированные и прекращенные (" +
                    _vm._s(_vm.closedCases.length) +
                    ")\n        "
                )
              ]
            )
          ])
        ]),
        _vm._l(_vm.cases, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "report-item-result__result mosgorsud-result"
            },
            [
              _c("app-report-collapse-item", {
                attrs: { opened: _vm.opened || _vm.$isServer },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-image d-none d-md-block",
                              class: {
                                "report-item-result__result-elem-image--success": !item.disabled
                              }
                            },
                            [_c("div")]
                          ),
                          item.org_data.name
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text-wrapper"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__result-elem-text"
                                    },
                                    [
                                      _vm._v(
                                        "\n              Название организации\n            "
                                      )
                                    ]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.org_data.name) +
                                        "\n            "
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          item.org_data.inn || item.org_data.ogrn
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text-wrapper"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__result-elem-text"
                                    },
                                    [
                                      _vm._v(
                                        "\n              Реквизиты\n            "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    [
                                      item.org_data.inn
                                        ? [
                                            _vm._v(
                                              "\n                ИНН " +
                                                _vm._s(item.org_data.inn) +
                                                "\n              "
                                            )
                                          ]
                                        : _vm._e(),
                                      item.org_data.ogrn
                                        ? [
                                            _c("br"),
                                            _vm._v(
                                              "ОГРН " +
                                                _vm._s(item.org_data.ogrn) +
                                                "\n              "
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ]
                              )
                            : _vm._e(),
                          item.individual_data.roles &&
                          item.individual_data.roles.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text-wrapper"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__result-elem-text"
                                    },
                                    [
                                      _vm._v(
                                        "\n              Роль\n            "
                                      )
                                    ]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getRoles(item)) +
                                        "\n            "
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "report-item-result__content-section"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-section-title"
                                },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      "\n                Юридическое лицо\n              "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-list"
                                },
                                [
                                  item.org_data.name
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__content-list-elem-title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Полное наименование\n                "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__content-list-elem-result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(item.org_data.name) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-list"
                                },
                                [
                                  item.org_data.address
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__content-list-elem-title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Адрес\n                "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__content-list-elem-result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    item.org_data.address
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-list"
                                },
                                [
                                  item.org_data.inn
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__content-list-elem-title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  ИНН\n                "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__content-list-elem-result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(item.org_data.inn) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-list"
                                },
                                [
                                  item.org_data.ogrn
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__content-list-elem-title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  ОГРН\n                "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__content-list-elem-result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(item.org_data.ogrn) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-list"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Статус\n                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          item.disabled
                                            ? [
                                                _vm._v(
                                                  "\n                    Ликвидирован\n                  "
                                                )
                                              ]
                                            : [
                                                _vm._v(
                                                  "\n                    Действующий\n                  "
                                                )
                                              ]
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-list"
                                },
                                [
                                  item.org_data.death_date
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__content-list-elem-title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Дата ликвидации\n                "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__content-list-elem-result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    item.org_data.death_date
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "report-item-result__content-section"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-section-title"
                                },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      "\n                Управление компанией\n              "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "report-item-result__table" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__table-head d-none d-md-flex"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__table-head-item"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  ФИО\n                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__table-head-item"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Наименование должности\n                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__table-head-item"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Дата участия\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__table-body"
                                    },
                                    _vm._l(item.individual_data.roles, function(
                                      role
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: role.name,
                                          staticClass:
                                            "report-item-result__table-body-item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__table-body-elem"
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "d-md-none" },
                                                [
                                                  _vm._v(
                                                    "\n                      ФИО\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    item.individual_data.name
                                                  ) +
                                                  "\n                    "
                                              ),
                                              item.individual_data.inn
                                                ? _c("div", [
                                                    _c("p", [
                                                      _vm._v(
                                                        "\n                        ИНН: " +
                                                          _vm._s(
                                                            item.individual_data
                                                              .inn
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ])
                                                  ])
                                                : _vm._e()
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__table-body-elem"
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "d-md-none" },
                                                [
                                                  _vm._v(
                                                    "\n                      Наименование должности\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(_vm.getRole(role)) +
                                                  "\n                  "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__table-body-elem"
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "d-md-none" },
                                                [
                                                  _vm._v(
                                                    "\n                      Дата участия\n                    "
                                                  )
                                                ]
                                              ),
                                              role.fl_reg && role.fl_end
                                                ? [
                                                    _vm._v(
                                                      "\n                      с " +
                                                        _vm._s(
                                                          _vm.reverseDate(
                                                            role.fl_reg
                                                          )
                                                        ) +
                                                        " по " +
                                                        _vm._s(
                                                          _vm.reverseDate(
                                                            role.fl_end
                                                          )
                                                        ) +
                                                        " г.\n                    "
                                                    )
                                                  ]
                                                : [
                                                    _vm._v(
                                                      "\n                      Отсутствует\n                    "
                                                    )
                                                  ]
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "report-item-result__result-elem-image report-item-result__result-elem-image--success"
      },
      [_c("div", { staticClass: "mt-0" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "report-item-result__result-elem-image" }, [
      _c("div", { staticClass: "mt-0" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }