



























































import Vue from 'vue';
import Component from 'vue-class-component';
import noop from 'lodash/noop';
import get from 'lodash/get';
import { Api } from 'shared/api';
@Component({
  props: {
    userId: {
      type: String,
      required: true,
    },
    submit: {
      type: Function,
      default: noop,
    },
  },
})
export default class AdminUserSettingsDialog extends Vue {
  // props
  userId: UUID;
  submit!: (newEmail: string) => void;
  // data
  settings: any = null;
  usedSettings: any[] = [
    {
      key: 'add_logo_permission',
      label: 'Лого компании',
    },
    {
      key: 'hide_ecp',
      label: 'Скрыть печать ЭЦП',
    },
    {
      key: 'ai_report',
      label: 'Доступ к отчету ИИ',
    },
  ]

  get model() {
    const model: any = {};
    this.usedSettings.forEach((elem: any) => {
      const perm = this.getSetting(elem.key);
      model[elem.key] = get(perm, 'value') || 'disabled';
    });
    return model;
  }

  // lifecycle
  async created() {
    this.settings = await Api.user.GetUserSettings({ personID: this.userId });
    // const logoPerm = this.getSetting('add_logo_permission');
    // const hideEcp = this.getSetting('hide_ecp');
    // if (logoPerm) {
    //   this.model.add_logo_permission = logoPerm.value;
    // }
    // if (hideEcp) {
    //   this.model.hide_ecp = hideEcp.value;
    // }
    // console.log(this.settings);
  }

  // methods
  getSetting(value: string) {
    return this.settings.find((elem: any) => {
      return elem.key === value;
    });
  }

  async onSubmit() {
    try {
      for (const [ key, value ] of Object.entries(this.model)) {
        if (this.getSetting(key)) {
          await this.$api.user.UpdateUserSettings({
            key,
            value,
            person_id: this.userId,
          });
        } else {
          await this.$api.user.SetUserSettings({
            key,
            value,
            person_id: this.userId,
          });
        }
      }
      this.$noty.info({ text: 'Изменения сохранены' });
      this.$dialog.close();
    } catch (error) {
      this.$noty.error({ text: this.$api._getErrorMessage(error) });
    }
  }
}
