var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-report-item",
    {
      attrs: {
        title: "Финансы",
        icon: "coins",
        opened: true,
        disabled: "",
        status: "done",
        color: "success"
      },
      scopedSlots: _vm._u(
        [
          _vm.haveFinance
            ? {
                key: "extra-content",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "counter-agent-chart-buttons counter-agent-chart-buttons--sm counter-agent-chart-buttons--select mt-3 mb-4"
                      },
                      _vm._l(_vm.filters, function(item) {
                        return _c(
                          "div",
                          {
                            key: item.name,
                            staticClass: "counter-agent-chart-buttons__button",
                            attrs: {
                              active: _vm.filterBySum === item.field,
                              disabled: !_vm.haveData(item.field)
                            },
                            on: {
                              click: function($event) {
                                _vm.filterBySum = item.field
                              }
                            }
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.name) +
                                  "\n        "
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "counter-agent-chart-buttons__title text-nowrap"
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm._f("lazyround")(
                                        _vm.lastPeriod[item.field]
                                      )
                                    ) +
                                    "\n        "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "counter-agent-chart-buttons__change"
                              },
                              [
                                _c("div", { staticClass: "text-nowrap" }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("lazyround")(
                                          _vm.getChange(item.field)
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]),
                                _vm.getChange(item.field) && item.positive
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "counter-agent-chart-buttons__badge",
                                        class: {
                                          "counter-agent-chart-buttons__badge--success":
                                            _vm.getChange(item.field) > 0
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.getChangePercent(item.field)
                                            ) +
                                            "%\n          "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.getChange(item.field) && !item.positive
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "counter-agent-chart-buttons__badge",
                                        class: {
                                          "counter-agent-chart-buttons__badge--success":
                                            _vm.getChange(item.field) < 0
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.getChangePercent(item.field)
                                            ) +
                                            "%\n          "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    ),
                    !_vm.$isServer
                      ? _c("app-area-chart", {
                          attrs: {
                            data: _vm.bySumChartData,
                            "title-text": "dsdsdsd",
                            "show-value": true,
                            "range-description": _vm.chartRange,
                            "start-from": _vm.startFrom,
                            "point-format": _vm.pointFormat,
                            "y-labels-format": "{value:,.0f} ₽"
                          }
                        })
                      : _c("div", { staticClass: "rc-modern-info-panel" }, [
                          _c(
                            "div",
                            { staticClass: "rc-modern-info-panel__wrapper" },
                            [
                              _c("div", [_c("app-warning-img")], 1),
                              _c("div", [
                                _vm._v(
                                  "\n          Интерактивный график доступен в веб версии отчета\n        "
                                )
                              ])
                            ]
                          )
                        ])
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _c(
        "div",
        { staticClass: "report-item-modern__subtitle" },
        [
          !_vm.haveFinance
            ? [_vm._v("\n      Информация о финансах отсутствует\n    ")]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }