


























































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import propertyConstants from 'shared/utils/constants/property.ts';
import AppEstimateResult from 'shared/components/Estimate/EstimateResult.vue';

@Component({
  components: {
    AppEstimateResult,
    AppPriceChangeChart: () => import('shared/components/Estimate/PriceChangeChart.vue'),
  },
  name: 'AppEstimate',
  props: {
    info: Object,
    priceChangeHouse: Object,
    priceChangeArea: Object,
    rated: Boolean,
    isPdf: {
      type: Boolean,
      default: false,
    },
  },
})
export default class AppEstimate extends Vue {
  // props
  priceChangeHouse: AveragePriceData;
  priceChangeArea: AveragePriceData;
  rated: boolean;
  info: EstimateModel;
  isPdf: boolean;

  // data
  showExtraFields: boolean = false;
  buildingTypes: PropertyBuildingType[] = propertyConstants.BUILDING_TYPES;
  objectTypes: PropertyObjectType[] = propertyConstants.OBJECT_TYPES;
  loadingBtn: boolean = false;

  // lifecycle

  async sendRateEstimate(rate: string) {
    this.loadingBtn = true;
    await this.$emit('sendRateEstimate', rate);
    this.loadingBtn = false;
  }

  async updateEstimate() {
    await this.$emit('updateEstimate');
  }

  getApartmentConditions(apartmentConditionNumber: number) {
    return propertyConstants.STATE_OF_APARTMENTS[apartmentConditionNumber - 1]
      .name;
  }

  getBuildingType(buildingType: number): string {
    return buildingType ? this.buildingTypes[buildingType].name : 'нет данных';
  }

  getObjectType(): string {
    return this.info.object_type
      ? this.objectTypes[this.info.object_type - 1].name
      : 'нет данных';
  }
}
