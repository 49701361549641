















import Vue from 'vue';

export default Vue.extend({
  props: {
    orderItemId: String,
    index: Number,
  },

  data() {
    return {
      loading: false,
      kadNumber: '',
    };
  },

  methods: {
    async onSave() {
      if (this.loading || !this.kadNumber) {
        return;
      }

      this.loading = true;

      try {
        await this.$api.admin.ManualUpdate({
          kadastr_number: this.kadNumber,
          order_item_id: this.orderItemId,
        });

        this.$emit('updated', this.kadNumber, this.index);
      } catch (error) {
        const text = this.$api._getErrorMessage(error);
        this.$noty.error({ text });
        console.error(error);
      }

      this.loading = false;
    },
  },
});
