import Component, { mixins } from 'vue-class-component';
import ClipboardJS from 'clipboard';
import { Options } from 'tippy.js';
import { Location } from 'vue-router';
import qs from 'query-string';
import _ from 'lodash';

import { AuthTypes } from 'shared/store/modules/auth/types';
import { Role } from 'shared/utils/constants';

import { NULL_UUID } from '@/core/utils/constants';
import HelpersMixin from '@/core/mixins/helpers.mixin';
import DataTableMixin from '@/core/mixins/data-table.mixin';
import OdrerFilter from '../components/OrdersFilter.vue';
import AppOrderItemStatus from '../components/OrderItemStatus.vue';
import LocStorage from '@/core/utils/localStorage';
import PartnerModule from '@/store/modules/partner';
import FilterMixin from '@/core/mixins/filter.mixin';

interface ViewOrderItem {
  userID?: string;
  email?: string;
  orderID?: string;
  orderItemID?: string;
  kadastrNumber?: string;
  createdAt?: string;
  status?: string;
  sourceText?: string;
  sourceUrl?: string;
  productName?: string;
  price?: string;
}

function defaultFilter(): SimpleObject {
  return {
    haveKadastr: undefined,
    haveTransaction: undefined,
    isNotPaid: undefined,
    partnerID: '',
    kadastrNumber: '',
    email: '',
    productID: [],
    itemStatus: [],
    creationDateFrom: '',
    creationDateTo: '',
    sourceID: [],
    minPrice: '',
    maxPrice: '',
    personRoles: [],
  };
}

let clipboard: ClipboardJS;

@Component({
  components: { AppOrderItemStatus },
})
export default class AdminOrders extends mixins(
  HelpersMixin,
  DataTableMixin,
  FilterMixin
) {
  tippyOptions: Options = {
    placement: 'left',
  };
  filter: any = { ...defaultFilter(), ...this._getQuery() };
  headers: TableHeaderItem[] = [
    {
      text: this.isAdmin ? 'Кад. номер' : 'Номер заказа',
      sortProp: 'kadastrNumber',
      align: 'left',
      width: '120px',
    },
    {
      text: 'Email',
      sortProp: 'email',
      align: 'left',
    },
    {
      text: 'Продукт',
      sortProp: 'productName',
      align: 'left',
    },
    {
      text: 'Дата заказа',
      align: 'left',
    },
    {
      text: 'Дата изменения',
      align: 'left',
    },
    {
      text: 'Источник',
      sortProp: 'source',
      align: 'left',
      width: '60px',
    },
    {
      text: 'Сумма',
      sortProp: 'price',
      width: '70px',
    },
    {
      text: 'Статус',
      sortProp: 'status',
      align: 'right',
    },
    {
      text: 'PP',
      sortProp: 'price',
      width: '51px',
    },
  ];

  // computed
  get isAdmin(): boolean {
    return this.$store.getters[AuthTypes.getters.IS_ADMIN];
  }

  get isPartnerRisk(): boolean {
    return this.$store.getters[AuthTypes.getters.IS_ROLE](Role.PartnerRisk);
  }

  get stats(): PartnerStats {
    return PartnerModule.stats;
  }

  // lifecycle
  created() {
    if (!this.isAdmin) {
      this.headers.splice(1, 1);
      if (this.isPartnerRisk) {
        this.headers.splice(3, 2);
      }
    }
  }

  beforeDestroy() {
    if (clipboard) {
      clipboard.destroy();
      clipboard = null;
    }
  }

  // methods
  handleRequest(offset: number, limit: number): Promise<any> {
    const filter = {
      ...this.filter,
      sourceID: _.map(this.filter.sourceID, 'source_id'),
      personRoles: _.map(this.filter.personRoles, 'person_role_id'),
    };

    if (this.isAdmin || this.isPartnerRisk) {
      if (this.isPartnerRisk && _.isEmpty(filter.itemStatus)) {
        filter.itemStatus = [
          'waiting_for_verification',
          'waiting_for_execution',
          'waiting_for_owners',
          'done',
        ];
      }
      return this.$api.admin.OrderItemsList({ offset, limit, ...filter });
    }

    return this.$api.partner.Orders({ offset, limit, ...filter });
  }

  mapDataIntoView(orderItems: AdminOrderItem2[]) {
    for (const orderItem of orderItems) {
      if (orderItem.source) {
        orderItem.sourceText = orderItem.source.replace(/https?:\/\//, '');
      }

      if (orderItem.transaction_id === NULL_UUID) {
        orderItem.transaction_id = null;
      }
    }

    return orderItems;
  }

  refresh() {
    this.pagination.page = 1;
    this.fetchData();
  }

  openFilter() {
    this.$dialog.open({
      component: OdrerFilter,
      props: {
        defaultFilter: defaultFilter,
        sources: this.sources,
        roles: this.roles,
        statuses: this.statuses,
        filter: this.filter,
        submit: () => {
          this.$dialog.close();
          const filter = {
            ...this.filter,
            sourceID: this.filter.sourceID.map((item: any) => item.source_id),
            personRoles: this.filter.personRoles.map((item: any) => item.person_role_id),
          };

          this.applyFilter(filter);
        },
      },
    });
  }

  orderItemHasStatuses(orderItem: AdminOrderItem): boolean {
    return orderItem.item_statuses && orderItem.item_statuses.length > 1;
  }

  goToEstimate(orderItem: AdminOrderItem) {
    LocStorage.updateItem('estimate', {
      [orderItem.OrderItemID]: orderItem.comment,
    });
    window.open(`/estimate?order_item_id=${orderItem.OrderItemID}&calc=true`);
  }

  orderItemRouteConfig(orderItem: AdminOrderItem2) {
    const config: Location = {
      name: 'admin-order-detail',
      params: {
        userID: orderItem.person_id,
      },
    };

    if (orderItem.product_name === 'RiskAssessment') {
      config.name = 'admin-risk-report';
      config.params.orderItemID = orderItem.order_item_id;
    } else if (orderItem.product_name === 'RiskAssessmentV2') {
      config.name = 'admin-risk-report-v2';
      config.params.orderItemID = orderItem.order_item_id;
    } else {
      config.params.orderID = orderItem.order_id;
    }

    return config;
  }

  _getQuery() {
    const query = qs.parse(location.search, { arrayFormat: 'bracket' });
    return _.pick(query, Object.keys(defaultFilter()));
  }
}
