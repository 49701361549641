


























































































































































































































































































































import Component, { mixins } from 'vue-class-component';
import AppDatePick from 'vue-date-pick';
import _ from 'lodash';
import { AuthTypes } from 'shared/store/modules/auth/types';

import FilterWrapper from './FilterWrapper.vue';
import HelpersMixin from '@/core/mixins/helpers.mixin';
import { Role, RoleNames } from 'shared/utils/constants';

interface OrderFilter {
  haveKadastr: boolean;
  partnerID: string;
  regPartnerID: string;
  kadastrNumber: string;
  email: string;
  productID: string[];
  itemStatus: string[];
  creationDateFrom: string;
  creationDateTo: string;
  sourceID: string;
  personRoles: string;
  [key: string]: any;
}

@Component({
  popupOptions: {
    name: 'popup_side popup_transparent-backdrop',
  },

  components: {
    FilterWrapper,
    AppDatePick,
  },
  props: {
    filter: Object,
    sources: Array,
    roles: Array,
    statuses: Object,
    defaultFilter: Function,
    submit: Function,
    isUserOrder: {
      type: Boolean,
      default: false,
    },
  },
})
export default class AdminOrdersFilter extends mixins(HelpersMixin) {
  // props
  filter: OrderFilter;
  isUserOrder: boolean;
  statuses: any;
  sources: any;
  roles: any;
  defaultFilter: () => OrderFilter;
  submit: () => void;

  // data()
  statusTitleByKey = {
    default: 'Статусы',
    kadnet: 'Статусы Каднета',
    spv: 'Статусы spv.kadastr',
    risk: 'Статусы отчёта о рисках',
  };
  showAllFilters: boolean = false;

  // computed
  get productsByKey(): Product[] {
    if (!this.isAdmin) {
      return this.$store.state.products.filter((product: Product) => {
        return product.product_name_short;
      });
    }
    return this.$store.state.products;
  }

  get isAdmin(): boolean {
    return this.$store.getters[AuthTypes.getters.IS_ADMIN];
  }

  get isPartner(): boolean {
    return this.$store.getters[AuthTypes.getters.IS_ROLE](Role.Partner);
  }

  get isPartnerRisk(): boolean {
    return this.$store.getters[AuthTypes.getters.IS_ROLE](Role.PartnerRisk);
  }

  get statusesFilter() {
    if (this.isPartnerRisk) {
      const statuses = _.cloneDeep(_.pick(this.statuses, 'risk'));
      statuses.risk.push({ key: 'done', value: 'done' });

      return statuses;
    }

    return this.statuses;
  }

  get isAllStatusesSelected() {
    return (
      this.filter.itemStatus.length ===
      this.statuses.default.length +
        this.statuses.kadnet.length +
        this.statuses.risk.length +
        this.statuses.spv.length
    );
  }

  // lifecycle
  created() {
    this.roles.forEach((role: RoleItem) => {
      if (RoleNames[role.person_role_id]) {
        role.person_role_name = RoleNames[role.person_role_id];
      }
    });
    if (this.isUserOrder && this.filter.itemStatus.length === 0) {
      this.selectStatusesByDefault();
    }
  }

  // methods
  clear() {
    const defaults: OrderFilter = this.defaultFilter();
    let key: keyof OrderFilter;

    for (key in this.filter) {
      this.filter[key] = defaults[key];
    }

    this.submit();
  }

  selectStatusesByDefault() {
    this.statuses.default.forEach((status: SimpleObject) => {
      if (
        status.key !== 'waiting_for_payment' &&
        status.key !== 'pending' &&
        status.key !== 'paid'
      ) {
        this.filter.itemStatus.push(status.key);
      }
    });
  }

  invertAllStatuses() {
    if (this.isAllStatusesSelected) {
      this.filter.itemStatus = [];
    } else {
      const statuses: string[] = [];

      for (const key in this.statuses) {
        const statusesList = this.statuses[key];

        statusesList.forEach((status: any) => {
          statuses.push(status.key);
        });
      }

      this.filter.itemStatus = statuses;
    }
  }
}
