var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "risk-user-form" }, [
    _c("div", { staticClass: "popup__body owner-added-dialog" }, [
      _vm._m(0),
      _c("div", { staticClass: "row align-items-center my-4" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-6 order-2 order-md-1" },
          [
            _c(
              "app-button",
              {
                staticClass: "w-100 w-md-auto",
                attrs: { variant: "primary-light", size: "lg" },
                on: {
                  click: function($event) {
                    return _vm.$dialog.close()
                  }
                }
              },
              [_vm._v("\n          Закрыть\n        ")]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-md-6 order-1 order-md-2 mb-2 mb-md-0" },
          [
            _c(
              "app-button",
              {
                staticClass: "w-100 w-md-auto",
                attrs: { variant: "primary", size: "lg" },
                on: { click: _vm.addMoreOwner }
              },
              [_vm._v("\n          Добавить еще\n        ")]
            )
          ],
          1
        )
      ]),
      _vm._m(1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 text-center" }, [
        _c("i", { staticClass: "fas fa-check-circle text-success mb-3" }),
        _c("h3", [_vm._v("Успешно!")]),
        _c("span", { staticClass: "owner-added-dialog__text" }, [
          _vm._v(
            "Начался сбор данных. Промежуточный отчет будет доступен в разделе Проверка собственников."
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("span", { staticClass: "text-muted owner-added-dialog__small-text" }, [
        _vm._v(
          "\n        Обязательно добавьте всех текущих собственников, а также других участников в случае необходимости.\n      "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }