



























































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import first from 'lodash/first';
import AppCreditRatingDialog from 'shared/components/CreditRating/CreditRatingDialog.vue';
import AppEditCreditRatingDialog from 'shared/components/CreditRating/EditCreditRatingDialog.vue';
import AppEstimateDialog from 'shared/components/Estimate/EstimateDialog.vue';
import { AdvertTypes } from 'shared/store/modules/advert/types';

import AppRiskUpdateScraperStatusDialog from '@/views/Admin/AdminUserInfo/RiskReportV2/RiskUpdateScraperStatus.vue';

@Component({
  components: { },
  props: {
    userID: String,
    orderId: String,
    orderItemID: String,
  },
  computed: {
    ...mapState('objectDetail', [ 'riskReportV2' ]),
    ...mapState('advert', [
      'priceChangeHouse',
      'priceChangeArea',
    ]),
  },
})
export default class AppRiskReport extends Vue {
  // store
  priceChangeHouse: AveragePriceData;
  priceChangeArea: AveragePriceData;

  // props
  userID!: string;
  orderId!: string;
  orderItemID!: string;

  // data()
  loading: boolean = true;
  reportSending: boolean = false;
  orderItem: any = null;
  report: any = null;
  kadastrNumber: string = '';
  objectInfo: any = null;
  attachments: AttachmentItem[] = [];
  owners: LawInformationOwner[] = [];
  order: any = null;
  info: any = null;
  reportV2: LawInformation;
  riskReportV2: LawInformation;

  async created() {
    await this.fetchOrder();
    if (this.isProduct('CostAssessment')) {
      await this.getEstimateOrder();
      if (this.info.advertId) {
        await this.$store.dispatch(AdvertTypes.actions.GET_PRICE_CHANGE, {
          id: this.info.advertId,
          type: 'house_month',
        });
        await this.$store.dispatch(AdvertTypes.actions.GET_PRICE_CHANGE, {
          id: this.info.advertId,
          type: 'area_month',
        });
        await this.$store.dispatch(
          AdvertTypes.actions.GET_AVERAGE_PRICE,
          this.info.advertId
        );
      } else {
        await this.$store.dispatch(
          AdvertTypes.actions.GET_PRICE_CHANGE_BY_PARAMS,
          { address: this.info.address, type: 'house_month' }
        );
        await this.$store.dispatch(
          AdvertTypes.actions.GET_PRICE_CHANGE_BY_PARAMS,
          { address: this.info.address, type: 'area_month' }
        );
      }
    }
    console.log(this.report);
  }

  isProduct(name: string) {
    return this.report && this.report.product_name === name;
  }

  async fetchOrder(): Promise<UserOrderItem> {
    const orders = await this.$api.risk.GetOrders({
      task_ids: [
        this.orderItemID,
      ],
    });
    console.log(orders);
    if (!orders.length) {
      return null;
    }
    this.report = orders[0];
  }

  openEditDialog() {
    const owner = {
      first: this.report.owner.first,
      surname: this.report.owner.surname,
      patronymic: this.report.owner.patronymic,
      birthday: this.report.owner.birthday,
      passport: this.report.owner.passport,
      passport_issue_at: this.report.owner.passport_issue_at,
    };
    this.$dialog.open({
      component: AppEditCreditRatingDialog,
      props: {
        owner: owner,
        orderItemId: this.orderItemID,
        onSuccess: () => {
          this.$noty.success({ text: 'Сохранено' });
          this.fetchOrder();
        },
      },
    });
  }

  goToOrder() {
    this.$router.push({
      name: 'admin-order-detail',
      params: {
        orderID: this.orderId,
        userID: this.userID,
      },
    });
  }

  getTask(name: string) {
    if (this.report && this.report.tasks.length) {
      return this.report.tasks.find((elem: any) => {
        return elem.task_type === name;
      });
    }
  }

  async getEstimateOrder() {
    try {
      const orders = await this.$api.admin.Orders<AdminOrderInfo[]>({
        userID: this.userID,
        orderID: this.orderId,
      });
      // const orders = await this.$api.user.OrdersList({
      //   orderItemID: this.orderItemID,
      // });
      const order = first(orders);

      if (!order) {
        console.error('no order');
      } else {
        this.order = order.OrderItem[0];
        this.info = this.order.metadata.costAssessmentData;
        this.info.Address = this.info.address;
        this.$store.commit(AdvertTypes.mutations.SET_INFO, this.info);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async openCreditRatingModal() {
    if (this.isProduct('CostAssessment')) {
      this.$dialog.open({
        component: AppEstimateDialog,
        props: {
          info: this.info,
          priceChangeHouse: this.priceChangeHouse,
          priceChangeArea: this.priceChangeArea,
        },
      });
    } else if (this.isProduct('CreditRating')) {
      const orderCreditRating = await this.getTask('owner_check_individual_credit_rating');
      const orderScoreRating = await this.getTask('owner_check_individual_score_rating');
      orderCreditRating.createdDate = this.report.started_at;
      this.$dialog.open({
        component: AppCreditRatingDialog,
        props: {
          orderCreditRating,
          orderScoreRating,
          owner: this.report.owner,
        },
      });
    }
  }

  showUpdateStatus() {
    const reportItem = {
      tasks: this.report.tasks,
    };
    this.$dialog.open({
      component: AppRiskUpdateScraperStatusDialog,
      props: {
        report: reportItem,
        orderItemId: this.orderItemID,
      },
    });
  }
}
