var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.result.result.length
        ? [_vm._v("\n    Среди руководителей и учредителей\n    "), _vm._m(0)]
        : [
            _vm._v(
              "\n    Действующие организации: " +
                _vm._s(_vm.sorted.active.length) +
                " "
            ),
            _c("br"),
            _vm.$isServer
              ? _vm._l(_vm.sorted.active, function(item) {
                  return _c(
                    "div",
                    { key: item.org_data.name, staticClass: "founder-elem" },
                    [
                      _c("app-founder-elem", {
                        attrs: { item: item, date: _vm.date }
                      })
                    ],
                    1
                  )
                })
              : _vm._e(),
            _vm._v(
              "\n    Ранее являлся учредителем или руководителем (в том числе ликвидированные): " +
                _vm._s(_vm.sorted.disabled.length) +
                " "
            ),
            _c("br"),
            _vm.$isServer
              ? _vm._l(_vm.sorted.disabled, function(item) {
                  return _c(
                    "div",
                    { key: item.org_data.name, staticClass: "founder-elem" },
                    [
                      _c("app-founder-elem", {
                        attrs: { item: item, date: _vm.date }
                      })
                    ],
                    1
                  )
                })
              : _vm._e(),
            !_vm.$isServer
              ? _c(
                  "span",
                  { staticClass: "link", on: { click: _vm.showDetails } },
                  [_vm._v("подробнее")]
                )
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "text-success" }, [
      _c("i", { staticClass: "fas fa-check-circle fa-sm fa-fw mr-1" }),
      _c("b", [_vm._v("не значится ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }