




















import Vue from 'vue';
import Component from 'vue-class-component';

// import AppReportPdf from 'shared/components/Risk/RiskReportPdf/RiskReportPdf.vue';

@Component({
  popupOptions: {
    name: 'popup_full',
  },

  components: { },

  props: {
    report: Object,
    objectInfo: Object,
    onlyUser: Boolean,
    hideConclusion: Boolean,
  },
})
export default class ReportPdfPreview extends Vue {
  print() {
    window.print();
  }
}
