





























































































import Vue from 'vue';
import without from 'lodash/without';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';

import AppAddUserPriceComponentDialog from './AddUserPriceComponentDialog.vue';
import AppEditUserPriceComponentDialog from './EditUserPriceComponentDialog.vue';
import AppActivateTariffDialog from './AppActivateTariffDialog.vue';
import AppEditTariffDialog from './AppEditTariffDialog.vue';

export default Vue.extend({
  props: {
    userID: String,
  },

  data() {
    return {
      items: [],
      loading: true,
      currentTariff: null,
      headers: [
        {
          text: 'Название',
        },
        {
          text: 'Включен',
        },
        {
          text: 'Осталось',
        },
        {
          text: 'Дата активации',
        },
        {
          text: 'Дата окончания',
        },
        {
          width: '100px',
        },
      ] as TableHeaderItem[],
    };
  },

  async created() {
    await this.requestItems();
    this.loading = false;
  },

  methods: {
    showAddComponentDialog() {
      this.$dialog.open({
        component: AppAddUserPriceComponentDialog,

        props: {
          userId: this.userID,
          onCreated: this.requestItems,
        },
      });
    },

    showEditDialog(item: PriceComponent.UserItem) {
      this.$dialog.open({
        component: AppEditUserPriceComponentDialog,
        props: {
          userPriceComponent: item,
          onUpdated: this.requestItems,
        },
      });
    },

    async destroyItem(item: PriceComponent.UserItem) {
      if (!confirm('Удалить этот прайс компонент?')) {
        return;
      }

      try {
        await this.$api.priceComponent.user.DestroyItem({
          price_component_owner_id: item.price_component_owner_id,
        });

        this.items = without(this.items, item);
      } catch (error) {
        console.error(error);
      }
    },

    async requestCurrentTariff() {
      try {
        this.currentTariff = await this.$api.tariff.GetCurrentForUser({
          user_id: this.userID,
        });
      } catch (error) {
        console.error(error);
      }
    },

    async requestItems() {
      await this.requestPriceComponents();
      await this.requestCurrentTariff();
    },

    async requestPriceComponents() {
      try {
        const priceComponents = await this.$api.priceComponent.items.All();
        const priceComponentsById: {
          [key: string]: PriceComponent.Item;
        } = keyBy(priceComponents, 'price_component_id');
        const userPriceComponents = await this.$api.priceComponent.user.All({
          personID: this.userID,
        });

        this.items = userPriceComponents.map(item => {
          const name = get(priceComponentsById[item.price_component_id], 'name');
          item.name = name || item.price_component_id + '(Копмонент не найден)';

          return item;
        });
      } catch (error) {
        console.error(error);
      }
    },

    editTariff() {
      this.$dialog.open({
        component: AppEditTariffDialog,
        props: {
          userId: this.userID,
          onUpdated: this.requestCurrentTariff,
        },
      });
    },

    activateTariff() {
      this.$dialog.open({
        component: AppActivateTariffDialog,

        props: {
          userId: this.userID,
          onCreated: this.requestItems,
        },
      });
    },

    async deleteTariff() {
      if (!confirm('Удалить тариф?')) {
        return;
      }
      try {
        await this.$api.tariff.DeleteForUser({ person_id: this.userID });
        this.$noty.success({ text: 'Тариф успешно удалён' });
        this.requestItems();
      } catch (error) {
        console.error(error);
      }
    },
  },
});
