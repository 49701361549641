













import Vue from 'vue';
import Component from 'vue-class-component';

import AppEstimate from 'shared/components/Estimate/Estimate.vue';

@Component({
  props: {
    info: Object,
    priceChangeHouse: Object,
    priceChangeArea: Object,
  },
  components: { AppEstimate },
})
export default class AppEstimateDialog extends Vue {
  priceChangeHouse: AveragePriceData;
  priceChangeArea: AveragePriceData;
  info: EstimateModel;
}
