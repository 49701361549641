
































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';

import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import AppPassportReport from 'shared/components/FizCheck/PassportReport.vue';
import AppOwnerInfo from 'shared/components/FizCheck/OwnerInfoModern.vue';
import AppTaxReport from 'shared/components/FizCheck/TaxReport.vue';
import AppPledgeReport from 'shared/components/FizCheck/PledgeReport.vue';
import AppDebtReport from 'shared/components/FizCheck/DebtReport.vue';
import AppSudReport from 'shared/components/FizCheck/SudReport.vue';
import AppArbitrReport from 'shared/components/FizCheck/ArbitrReport.vue';
import AppFounderReport from 'shared/components/FizCheck/FounderReport.vue';
import AppBankruptReport from 'shared/components/FizCheck/BankruptReport.vue';
import AppDisqualifiedReport from 'shared/components/FizCheck/DisqualifiedReport.vue';
import AppForeignAgentReport from 'shared/components/FizCheck/ForeignAgentReport.vue';
import AppCriminalReport from 'shared/components/FizCheck/CriminalReport.vue';
import AppFsinReport from 'shared/components/FizCheck/FsinReport.vue';
import AppInterpolReport from 'shared/components/FizCheck/InterpolReport.vue';
import AppSelfEmloyedReport from 'shared/components/FizCheck/SelfEmloyedReport.vue';
import AppTerrorismReport from 'shared/components/FizCheck/TerrorismReport.vue';
import AppSocialMediaReport from 'shared/components/FizCheck/SocialMediaReport.vue';
import AppExtendedReportWrapper from 'shared/components/FizCheck/ExtendedReport/ExtendedReportWrapper.vue';
import AppSnilsReport from 'shared/components/FizCheck/SnilsReport.vue';
import AppLicenseReport from 'shared/components/FizCheck/LicenseReport.vue';
import AppPhoneReport from 'shared/components/FizCheck/PhoneReport.vue';
import AppBillsReport from 'shared/components/FizCheck/BillsReport.vue';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import find from 'lodash/find';
import filter from 'lodash/filter';
import countBy from 'lodash/countBy';
import isArray from 'lodash/isArray';
import capitalize from 'lodash/capitalize';
import map from 'lodash/map';
import toPairs from 'lodash/toPairs';
import fromPairs from 'lodash/fromPairs';
import { checkPersonTasks, tasksMap } from 'shared/utils/checkPersonTasks';

// import AppAddBirthdayDialog from 'shared/components/RiskNew/AddOwnerForm/AddBirthdayDialog.vue';
// import AppAddPassportDialog from 'shared/components/RiskNew/AddOwnerForm/AddPassportDialog.vue';
// import AppAddInnDialog from 'shared/components/RiskNew/AddOwnerForm/AddInnDialog.vue';
// import AddPhoneDialog from 'shared/components/RiskNew/AddOwnerForm/AddPhoneDialog.vue';
// import AddSnilsDialog from 'shared/components/RiskNew/AddOwnerForm/AddSnilsDialog.vue';
// import AddLicenseDialog from 'shared/components/RiskNew/AddOwnerForm/AddLicenseDialog.vue';
import AddEditOwnerDialog from 'shared/components/RiskNew/AddOwnerForm/EditOwnerItemDialog.vue';
import AddUniversalEditDialog from './Dialogs/UniversalEditDialog.vue';
@Component({
  components: {
    AppReportItem,
    AppOwnerInfo,
    AppTaxReport,
    AppPledgeReport,
    AppDebtReport,
    AppSudReport,
    AppArbitrReport,
    AppFounderReport,
    AppBankruptReport,
    AppDisqualifiedReport,
    AppForeignAgentReport,
    AppCriminalReport,
    AppFsinReport,
    AppInterpolReport,
    AppPassportReport,
    AppSelfEmloyedReport,
    AppTerrorismReport,
    AppSocialMediaReport,
    AppExtendedReportWrapper,
    AppSnilsReport,
    AppLicenseReport,
    AppPhoneReport,
    AppBillsReport,
  },

  props: {
    report: Object,
    orderStatus: String,
    opened: {
      type: Boolean,
      default: false,
    },
    isDemo: {
      type: Boolean,
      default: false,
    },
    onlyOwners: {
      type: Boolean,
      default: false,
    },
    fetchReport: {
      type: Function,
      default: noop,
    },
    notPaid: {
      type: Boolean,
      default: false,
    },
    showExtendedReportSteps: {
      type: Boolean,
      default: false,
    },
    extendedReportReturned: {
      type: Boolean,
      default: false,
    },
    returnExtendedReport: {
      type: Function,
      default: noop,
    },
    extendedReportEnabled: {
      type: Boolean,
      default: false,
    },
    isPdf: {
      type: Boolean,
      default: false,
    },
  },
})
export default class AppFizCheckReport extends Vue {
  // props
  report!: LawInformationOwnerElem;
  orderStatus: string;
  opened!: boolean;
  onlyOwners!: boolean;
  isDemo: boolean;
  fetchReport!: () => void;
  notPaid: boolean;
  showExtendedReportSteps: boolean;
  extendedReportReturned: boolean;
  returnExtendedReport!: () => void;
  extendedReportEnabled: boolean;
  isPdf: boolean;

  isEmpty = isEmpty;
  get: any = get;
  filterColor: string = 'all';
  extendedListShow: boolean = false;
  ulixesUser: number = 0;
  tasksMap: any = [];

  created() {
    this.tasksMap = tasksMap();
    // if (this.report) {
    //   this.report.tasks.forEach((elem: any) => {
    //     console.log(elem.task_type, elem);
    //   });
    // }
  }

  get filteredTaskMap() {
    if (this.filterColor === 'all') {
      return this.tasksMap;
    }
    return filter(this.tasksMap, (task) => task.color === this.filterColor);
  }

  get scrollActiveList() {
    if (this.extendedListShow) {
      const ulixesAltFiltered = (this.$refs.extendedReportComponent as any).ulixesAltFiltered;
      const showExtendedReportGrouped = (this.$refs.extendedReportComponent as any).showExtendedReportGrouped;
      if (this.ulixes && this.ulixes.length && showExtendedReportGrouped) {
        return map(this.ulixes, (elem: any) => {
          elem.color = elem.indicator || 'warning';
          elem.nameCap = capitalize(elem.name);
          return elem;
        });
      } else if (ulixesAltFiltered && ulixesAltFiltered.length) {
        return map(ulixesAltFiltered, (elem: any, i: number) => {
          return {
            color: 'warning',
            nameCap: capitalize(elem['ИСТОЧНИК']),
            name: elem['ИСТОЧНИК'] + i,
            ...elem,
          };
        });
      }
    }
    return this.filteredTaskMap;
  }

  get countTasksByColors() {
    return countBy(this.tasksMap, 'color');
  }

  get isPhysical() {
    // eslint-disable-next-line eqeqeq
    return this.report.owner.owner_type !== 1;
  }

  get gosuslugiPassport() {
    const task = this.getTask('validate_passport_government');
    if (!task) {
      return 'error';
    }
    if (task.status === 'done') {
      if (task.task_result.result === 'Действительный') {
        return 'valid';
      } else if (task.task_result.result === 'Данные не найдены') {
        return 'notFound';
      } else if (task.task_result.result === 'Недействительный') {
        return 'invalid';
      }
    } else if (task.status === 'failed') {
      return 'error';
    }
    return 'inProgress';
  }

  get passportTask() {
    let task = this.getTask('owner_check_individual_valid_passport');
    if (this.gosuslugiPassport !== 'error') {
      task = this.getTask('validate_passport_government');
    }
    if (!task) {
      return null;
    }
    task.task_result.gosuslugiPassport = this.gosuslugiPassport;
    return task;
  }

  get socialMediaTask() {
    const first = Object.create(this.getTask('isphere') || null);
    const second = Object.create(this.getTask('isphere_phone') || null);
    const secondSources = get(second, 'task_result.result.sources');
    const firstSources = get(first, 'task_result.result.sources');
    if (second && secondSources && first && firstSources) {
      // first.task_result.result.sources = firstSources.concat(secondSources);
      first.task_result.result.mergedSources = firstSources.concat(secondSources);
    }
    return first;
  }

  get tasks() {
    const tasksNames = checkPersonTasks();
    let ceo = this.getTask(tasksNames.ceo);
    if (!ceo) {
      ceo = this.getTask('owner_check_individual_ceo');
    }
    return {
      passport: this.passportTask,
      criminal: this.getTask(tasksNames.criminal),
      fsin: this.getTask(tasksNames.fsin),
      interpol: this.getTask(tasksNames.interpol),
      bailiffDebt: this.getTask(tasksNames.bailiffDebt),
      individualDebt: this.getTask(tasksNames.individualDebt),
      bankrupt: this.getTask(tasksNames.bankrupt),
      selfEmployed: this.getTask(tasksNames.selfEmployed),
      ceo,
      disqualified: this.getTask(tasksNames.disqualified),
      terrorism: this.getTask(tasksNames.terrorism),
      foreignAgent: this.getTask(tasksNames.foreignAgent),
      arbitrReport: this.getTask(tasksNames.arbitrReport),
      judicialActs: this.getTask(tasksNames.judicialActs),
      pledge: this.getTask(tasksNames.pledge),
      socialMedia: this.socialMediaTask,
      snils: this.getTask(tasksNames.snils),
      license: this.getTask(tasksNames.license),
      phone: this.getTask(tasksNames.phone),
      bills: this.getTask(tasksNames.bills),
    };
  }

  get orderIsDone() {
    return this.orderStatus === 'done';
  }

  get newSurveys() {
    return this.report.surveys.filter((elem) => {
      return elem.status === 'in progress';
    });
  }

  get dataAnalysis() {
    return this.getTask('data_analysis_owner');
  }

  get ulixes() {
    const res = this.dataAnalysis;
    console.log(res, 'res ulixes');
    if (res && res.status === 'done' && isArray(res.task_result.result)) {
      const result = res.task_result.result.find((el: any) => el.name === 'sum');
      const items = get(result, 'users.' + this.ulixesUser + '.items', []);
      // поля стали приходить в верхнем регистре, поэтому чтобы ничего не сломалось тут мы делаем обратно в нижний
      return map(items, (item) => {
        const result: any = this.objectToLowercase(item);
        result.items = map(result.items, (elem: any) => {
          const newObj: any = this.objectToLowercase(elem);
          if (newObj.headers && newObj.headers.length) {
            newObj.headers = map(newObj.headers, (head) => {
              return this.objectToLowercase(head);
            });
          }
          return newObj;
        });
        return result;
      });
    }
    return null;
  }

  get ulixesAlt() {
    const res = this.dataAnalysis;
    if (res && res.status === 'done' && isArray(res.task_result.result)) {
      const result = find(res.task_result.result, (el: any) => el.name === 'alternative');
      return get(result, 'users.' + this.ulixesUser + '.items', []);
    }
    return null;
  }

  changeUlixesUser(index: number) {
    this.ulixesUser = index;
  }

  objectToLowercase(obj: any) {
    return fromPairs(
      toPairs(obj).map(([ k, v ]) => [ k.toLowerCase(), v ])
    );
    // return (Object as any).fromEntries(
    //   Object.entries(obj).map(([ k, v ]) => [ k.toLowerCase(), v ])
    // );
  }

  updateTaskMap(taskName: string, field: string, value: any) {
    const task = find(this.tasksMap, (elem) => {
      return elem.name === taskName;
    });
    task[field] = value;
  }

  getTask(val: string) {
    return this.report.tasks.find((elem: any) => elem.task_type === val);
  }

  getTaskStatus(task: any, taskName: string = ''): any {
    if ([ 'judicialActs' ].includes(taskName)) {
      return this.getSudTaskStatus(task);
    }
    if ([ 'bailiffDebt' ].includes(taskName)) {
      return this.getFsspTaskStatus(task);
    }
    if (this.notPaid) {
      if (task && [ 'owner_check_individual_valid_passport', 'owner_check_individual_criminal_investigation', 'owner_check_individual_bailiff_debt' ].includes(task.task_type)) {
        return task.status;
      } else {
        return 'not paid';
      }
    } else if (task) return task.status;
    else if (this.orderIsDone) return 'done';
    else return 'in progress';
  }

  getTaskIsLoading(item: any) {
    const task = this.getTask(item.taskName);
    const taskStatus = this.getTaskStatus(task);
    return taskStatus === 'in progress' && taskStatus !== 'not required' && item.color === 'info';
  }

  getSudTaskStatus(task: any) {
    const status = this.getTaskStatus(task);
    if ([ 'failed', 'soft_failed' ].includes(status)) {
      const sudact = this.getTask('sudact');
      this.tasks.judicialActs = sudact;
      return this.getTaskStatus(sudact);
    }
    return status;
  }

  getFsspTaskStatus(task: any) {
    const status = this.getTaskStatus(task);
    if ([ 'failed', 'soft_failed' ].includes(status)) {
      const gosuslugi = this.getTask('gosuslugi_bills');
      this.tasks.bailiffDebt = gosuslugi;
      return this.getTaskStatus(gosuslugi);
    }
    return status;
  }

  getMissingRequirements(task: any) {
    if (task) return task.missing_requirements;
    else return [];
  }

  visibilityChanged(val: boolean) {
    this.extendedListShow = val;
  }

  // openAddFieldDialog(field: string) {
  //   let component: any;
  //   switch (field) {
  //     case 'birthday':
  //       component = AppAddBirthdayDialog;
  //       break;
  //     case 'passport':
  //       component = AppAddPassportDialog;
  //       break;
  //     case 'inn':
  //       component = AppAddInnDialog;
  //       break;
  //     case 'phone':
  //       component = AddPhoneDialog;
  //       break;
  //     case 'owner':
  //       component = AddEditOwnerDialog;
  //       break;
  //     case 'snils':
  //       component = AddSnilsDialog;
  //       break;
  //     case 'license':
  //       component = AddLicenseDialog;
  //       break;
  //   }
  //   this.$dialog.open({
  //     component,
  //     props: {
  //       owner: this.report.owner,
  //       fetchReport: this.fetchReport,
  //     },
  //   });
  // }

  openAddFieldDialog(fieldType: string | string[]) {
    fieldType = Array.isArray(fieldType) ? fieldType : [ fieldType ];

    const component = fieldType[0] === 'owner' ? AddEditOwnerDialog : AddUniversalEditDialog;

    this.$dialog.open({
      component,
      props: {
        owner: this.report.owner,
        fields: fieldType,
        fetchReport: () => setTimeout(this.fetchReport, 2000),
      },
    });
  }
}
