









































































































import Component, { mixins } from 'vue-class-component';
import FilterWrapper from './FilterWrapper.vue';
import { Role, RoleNames } from 'shared/utils/constants';
import HelpersMixin from '@/core/mixins/helpers.mixin';

@Component({
  popupOptions: {
    name: 'popup_side popup_transparent-backdrop',
  },

  components: { FilterWrapper },

  props: {
    filter: Object,
    sources: Array,
    defaultFilter: Function,
    submit: Function,
  },
})
export default class AdminUsersFilter extends mixins(HelpersMixin) {
  defaultFilter!: () => any;
  sources: any;
  filter!: any;
  roles = { ...RoleNames };
  admins: User[] = [];

  search() {}

  mounted() {
    this.$api.admin
      .Users({ roleID: [ Role.Admin ] })
      .then(data => {
        this.admins = data;
      });
  }

  clear() {
    const defaults = this.defaultFilter();

    for (const key in this.filter) {
      this.filter[key] = defaults[key];
    }
  }
}
