








































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import get from 'lodash/get';
import map from 'lodash/map';
import format from 'date-fns/format';
import AppWarningImg from 'shared/components/Static/WarningImg.vue';

@Component({
  components: {
    AppReportItem,
    AppWarningImg,
    AppAreaChart: () => import('shared/components/Charts/AreaChart.vue'),
  },
  props: {
    report: Object,
  },
})
export default class AppCounterAgentStaff extends Vue {
  // props
  report: any;
  filterBySum: string = 'seller';
  filterColors: any = [
    '#9CA3AF',
    '#336FEE',
    '#FE203A',
    '#49C236',
    '#FFE146',
    '#24BA96',
    '#F0A900',
  ];

  lastYear: number = new Date().getFullYear() - 1;

  get staff() {
    return get(this.report, 'ЧислСотрудИст', []);
  }

  get currentStaff() {
    return get(this.report, 'ЧислСотруд', []);
  }

  get bySumChartData() {
    return map(this.staff, (item: any) => {
      return parseInt(item['КолСотр']);
    });
  }

  get found() {
    return get(this.report, 'ФондОплТруда', []);
  }

  get averageSum() {
    return get(this.report, 'СредЗП', []);
  }

  get startFrom() {
    if (!this.staff.length) {
      return '';
    }
    return parseInt(format(this.staff[0]['Год'], 'YYYY'));
  }

  get pointFormat() {
    return 'Число сотрудников: <b>{point.y:,.0f}</b><br/>' +
            'в {point.x} г.';
  }

  get chartRange() {
    if (!this.staff.length) {
      return '';
    }
    const rangeFrom = this.startFrom;
    const rangeTo = format(this.staff[this.staff.length - 1]['Год'], 'YYYY');
    return `Range: ${rangeFrom} to ${rangeTo}.`;
  }
}
