



















import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import Chart from 'chart.js';
import randomColor from 'randomcolor';

const defaultColors: string[] = [
  '#13ce66',
  '#17a2b8',
  '#ffc107',
  '#fd7e14',
  '#e83e8c',
  '#ff4949',
  '#409eff',
  '#007bff',
  '#6f42c1',
  '#6610f2',
  '#6c757d',
  '#343a40',
];

@Component({
  props: {
    title: String,
    total: [ Number, String ],
    items: Array,
    labelMapCallback: Function,
    colorMapCallback: Function,
    dataMapCallback: Function,
  },
})
export default class StatsChart extends Vue {
  // props
  title: string;
  total: number;
  items: StatsItem[];
  labelMapCallback: (item: StatsItem) => any;
  colorMapCallback: (item: StatsItem) => any;
  dataMapCallback: (item: StatsItem) => any;
  // props end

  private _chart: Chart;

  @Watch('items')
  itemsHandler(updatedItems: StatsItem[]) {
    const items = updatedItems || [];
    // const items = this.items;
    if (items) {
      // this.total = currentData.total_count;
      this._chart.data.labels = this.getLabels(items);
      this._chart.data.datasets.forEach((dataset) => {
        dataset.data = this.getData(items);
        dataset.backgroundColor = this.getBackgroundColors(items);
      });
    }

    (this.$refs.legend as Element).innerHTML = this._chart.generateLegend() as string;

    this._chart.update();
  }

  // lifecycle hooks
  mounted() {
    const config: Chart.ChartConfiguration = {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [],
            backgroundColor: [],
            borderWidth: 0,
          },
        ],
        labels: [],
      },

      options: {
        cutoutPercentage: 30,
        legend: { display: false },
        legendCallback: this.generateCustomLegend,
        maintainAspectRatio: false,
        tooltips: {
          displayColors: false,
          callbacks: {
            label: this.tooltipLbalelCallback,
            beforeLabel: this.tooltipBeforelabelCallback,
          },
        },
      },
    };

    this._chart = new Chart(this.$refs.chart as HTMLCanvasElement, config);

    if (this.items) {
      this.itemsHandler(this.items);
    }
  }

  beforeDestroy() {
    this._chart.destroy();
    this._chart = null;
  }

  // methods
  onLegendClick() {}

  tooltipLbalelCallback(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData) {
    const dataset = data.datasets[tooltipItem.datasetIndex];
    const currentValue = dataset.data[tooltipItem.index] as number;
    const percentage = this.getPercentValue(currentValue);
    return `${currentValue} (${percentage}%)`;
  }

  tooltipBeforelabelCallback(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData) {
    return data.labels[tooltipItem.index].toString();
  }

  getPercentValue(currentValue: number | null) {
    if (currentValue == null || this.total === 0) {
      return 0;
    }

    return ((currentValue / this.total) * 100).toFixed(2);
  }

  generateCustomLegend(chart: Chart) {
    const dataset = chart.data.datasets[0];
    const html = [];

    html.push('<ul class="chart-legend">');

    for (let i = 0; i < dataset.data.length; i++) {
      const dataValue: number = dataset.data[i] as number;
      const backgroundColor: string = (dataset as any).backgroundColor[i];
      const label = chart.data.labels[i];
      const percentage = this.getPercentValue(dataValue);

      const legendItem = `
        <li class="stats-chart-legend-item" data-index="${i}">
          <div class="stats-chart-legend-item__color" style="background-color: ${backgroundColor};"></div>
          <div class="stats-chart-legend-item__label">${label}</div>
          <div class="stats-chart-legend-item__stats">
            <div class="stats-chart-legend-item__stats-count">${dataValue}</div>
            <div class="stats-chart-legend-item__stats-percent">${percentage}%</div>
          </div>
        </li>
      `;

      html.push(legendItem);
    }

    html.push('</ul>');
    return html.join('');
  }

  getLabels(items: StatsItem[]) {
    if (this.labelMapCallback) {
      return items.map(this.labelMapCallback);
    }
    return items.map((item) => item.Name);
  }

  getData(items: StatsItem[]) {
    if (this.dataMapCallback) {
      return items.map(this.dataMapCallback);
    }

    return items.map((item) => item.Count);
  }

  getBackgroundColors(items: StatsItem[]) {
    if (this.colorMapCallback) {
      return items.map(this.colorMapCallback);
    }
    return items.map((_, index) => this.getColor(index));
  }

  getColor(index: number) {
    return defaultColors[index] ? defaultColors[index] : randomColor();
  }
}
