var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPhysical
    ? _c(
        "app-report-item",
        {
          attrs: {
            title: "Росфинмониторинг",
            icon: "exclamation-triangle",
            status: _vm.status,
            images: _vm.images,
            color: _vm.map.color,
            opened: _vm.resultStatus === "found",
            "missing-requirements": _vm.missingRequirements,
            source: {
              domain: "fedsfm.ru",
              link: "http://www.fedsfm.ru/"
            }
          },
          scopedSlots: _vm._u(
            [
              _vm.resultStatus === "found"
                ? {
                    key: "extra-content",
                    fn: function() {
                      return [
                        _c("div", {}, [
                          _c("div", {
                            staticClass: "criminal-report__divider"
                          }),
                          _c("div", { staticClass: "row md-gutters" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "criminal-report__items criminal-report__items--50"
                                },
                                [
                                  _vm.task.task_result.input.surname
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Фамилия\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.task.task_result.input
                                                      .surname
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.task.task_result.input.first
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Имя\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.task.task_result.input
                                                      .first
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.task.task_result.input.patronymic
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Отчество\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.task.task_result.input
                                                      .patronymic
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.task.task_result.input.birthday
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Дата рождения\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      _vm.task.task_result.input
                                                        .birthday,
                                                      "DD.MM.YYYY"
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.task.task_result.input.region
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Регион\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.getRegionNameById(
                                                      _vm.task.task_result.input
                                                        .region
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ])
                          ])
                        ])
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _vm.task && _vm.task.task_result.result !== "not required"
            ? [
                _c(
                  "div",
                  { staticClass: "report-item-modern__subtitle" },
                  [
                    _vm.resultStatus === "not found"
                      ? [
                          _vm._v(
                            "\n        В перечне о причастности к экстремистской деятельности или терроризму не найден\n      "
                          )
                        ]
                      : _vm.resultStatus === "found"
                      ? [
                          _vm._v(
                            "\n        Физическое лицо числится в базе РОСФИНМОНИТОРИНГА\n      "
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            : _vm.orderIsDone
            ? [
                _c("span", { staticClass: "text-warning" }, [
                  _vm._v(" Недостаточно данных ")
                ])
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }