var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("app-data-table", {
    attrs: {
      headers: _vm.headers,
      items: _vm.items,
      loading: _vm.loading,
      pagination: _vm.pagination
    },
    on: { "page-changed": _vm.pageChanged },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var row = ref.row
          return [
            _c("td", { staticStyle: { "max-width": "170px" } }, [
              _c("div", { staticClass: "text-truncate" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.getDate(row.created_at, "DD MMMM YYYY г.")) +
                    "\n      "
                )
              ]),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.getDate(row.created_at, "HH:mm:ss")) +
                  "\n    "
              )
            ]),
            _c("td", { staticStyle: { "max-width": "220px" } }, [
              _c("div", { staticClass: "text-truncate" }, [
                _vm._v("\n        " + _vm._s(row.from_email) + "\n      ")
              ]),
              _c(
                "a",
                {
                  staticClass: "text-truncate",
                  attrs: { href: "mailto:" + row.email }
                },
                [_vm._v("\n        " + _vm._s(row.email) + "\n      ")]
              )
            ]),
            _c("td", [_vm._v("\n      " + _vm._s(row.subject) + "\n    ")]),
            _c(
              "td",
              [
                _vm._l(row.status_list, function(event, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      index === row.status_list.length - 1 || row.isFull
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "badge",
                                class: _vm.$helper_classByStatus(event.status)
                              },
                              [
                                (event.status === "clicked" ||
                                  event.status === "opened") &&
                                event.ip
                                  ? _c(
                                      "div",
                                      {
                                        directives: [
                                          { name: "tippy", rawName: "v-tippy" }
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$helper_textByStatus(
                                                event.status
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "small",
                                            attrs: {
                                              "data-tippy-content": "hide"
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _vm._v("IP: " + _vm._s(event.ip))
                                            ]),
                                            _c("div", [
                                              _vm._v(_vm._s(event.user_agent))
                                            ])
                                          ]
                                        )
                                      ]
                                    )
                                  : [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$helper_textByStatus(
                                              event.status
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ]
                              ],
                              2
                            ),
                            _c("div", [
                              _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.getDate(
                                        event.event_time,
                                        "DD MMMM YYYY г."
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.getDate(event.event_time, "HH:mm:ss")
                                  ) +
                                  "\n          "
                              )
                            ]),
                            event.status === "clicked" && event.url
                              ? _c("div", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: event.url,
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v("Ссылка")]
                                  )
                                ])
                              : _vm._e()
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                }),
                row.status_list.length > 1
                  ? _c(
                      "div",
                      {
                        staticStyle: { "max-width": "20px", cursor: "pointer" },
                        on: {
                          click: function($event) {
                            row.isFull = !row.isFull
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 24 24"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
                              }
                            }),
                            _c("path", {
                              attrs: { d: "M0 0h24v24H0z", fill: "none" }
                            })
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }