var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "object-info-list mb-3" },
    [
      _vm.info.Level
        ? _c(
            "div",
            {
              staticClass: "object-info-list__item row sm-gutters",
              class: _vm.isObjectCheckout ? "" : "flex-md-column"
            },
            [
              _c("div", { staticClass: "col-auto" }, [
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v("\n        Этаж"),
                  _c(
                    "span",
                    { class: _vm.isObjectCheckout ? "" : "d-md-none" },
                    [_vm._v(":")]
                  )
                ])
              ]),
              _c("div", { staticClass: "col-auto" }, [
                _vm._v("\n      " + _vm._s(_vm.info.Level) + "\n    ")
              ])
            ]
          )
        : _vm._e(),
      _vm.info.Area || _vm.info.AreaOKC
        ? _c(
            "div",
            {
              staticClass: "object-info-list__item row sm-gutters",
              class: _vm.isObjectCheckout ? "" : "flex-md-column"
            },
            [
              _c("div", { staticClass: "col-auto" }, [
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v("\n        Площадь"),
                  _c(
                    "span",
                    { class: _vm.isObjectCheckout ? "" : "d-md-none" },
                    [_vm._v(":")]
                  )
                ])
              ]),
              _c("div", { staticClass: "col-auto" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("float")(_vm.info.Area || _vm.info.AreaOKC)) +
                    " м"
                ),
                _c("sup", [_vm._v("2")])
              ])
            ]
          )
        : _vm._e(),
      _vm.info.ObjectType
        ? _c(
            "div",
            {
              staticClass: "object-info-list__item row sm-gutters",
              class: _vm.isObjectCheckout ? "" : "flex-md-column"
            },
            [
              _c("div", { staticClass: "col-auto" }, [
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v("\n        Тип объекта"),
                  _c(
                    "span",
                    { class: _vm.isObjectCheckout ? "" : "d-md-none" },
                    [_vm._v(":")]
                  )
                ])
              ]),
              _c("div", { staticClass: "col-auto" }, [
                _vm._v("\n      " + _vm._s(_vm.typeObject) + "\n    ")
              ])
            ]
          )
        : _vm._e(),
      _vm.params
        ? [
            _vm.isObjectCode && _vm.params.purpose.value
              ? _c(
                  "div",
                  {
                    staticClass: "object-info-list__item row sm-gutters",
                    class: _vm.isObjectCheckout ? "" : "flex-md-column"
                  },
                  [
                    _c("div", { staticClass: "col-auto" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("\n          Проектируемое назначение"),
                        _c(
                          "span",
                          { class: _vm.isObjectCheckout ? "" : "d-md-none" },
                          [_vm._v(":")]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.params.purpose.value) +
                          "\n      "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm.params.degree_readiness
              ? _c(
                  "div",
                  {
                    staticClass: "object-info-list__item row sm-gutters",
                    class: _vm.isObjectCheckout ? "" : "flex-md-column"
                  },
                  [
                    _c("div", { staticClass: "col-auto" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("\n          Степень готовности объекта"),
                        _c(
                          "span",
                          { class: _vm.isObjectCheckout ? "" : "d-md-none" },
                          [_vm._v(":")]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.params.degree_readiness) +
                          "%\n      "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm.params.base_parameters &&
            _vm.params.base_parameters.length &&
            _vm.params.base_parameters[0].built_up_area
              ? _c(
                  "div",
                  {
                    staticClass: "object-info-list__item row sm-gutters",
                    class: _vm.isObjectCheckout ? "" : "flex-md-column"
                  },
                  [
                    _c("div", { staticClass: "col-auto" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("\n          Площадь застройки"),
                        _c(
                          "span",
                          { class: _vm.isObjectCheckout ? "" : "d-md-none" },
                          [_vm._v(":")]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "col-auto" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.params.base_parameters[0].built_up_area) +
                          " м"
                      ),
                      _c("sup", [_vm._v("2")])
                    ])
                  ]
                )
              : _vm._e()
          ]
        : _vm._e(),
      _vm.info.ObjectType === "Земельный участок" && _vm.info.LandCategory
        ? _c(
            "div",
            {
              staticClass: "object-info-list__item row sm-gutters",
              class: _vm.isObjectCheckout ? "" : "flex-md-column"
            },
            [
              _c("div", { staticClass: "col-auto" }, [
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v("\n        Категория участка"),
                  _c(
                    "span",
                    { class: _vm.isObjectCheckout ? "" : "d-md-none" },
                    [_vm._v(":")]
                  )
                ])
              ]),
              _c("div", { staticClass: "col-auto" }, [
                _vm._v("\n      " + _vm._s(_vm.info.LandCategory) + "\n    ")
              ])
            ]
          )
        : _vm._e(),
      _vm.info.ObjectType === "Земельный участок" && _vm.info.PermittedUse
        ? _c(
            "div",
            {
              staticClass: "object-info-list__item row sm-gutters",
              class: _vm.isObjectCheckout ? "" : "flex-md-column"
            },
            [
              _c("div", { staticClass: "col-auto" }, [
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v("\n        Виды разрешенного использования"),
                  _c(
                    "span",
                    { class: _vm.isObjectCheckout ? "" : "d-md-none" },
                    [_vm._v(":")]
                  )
                ])
              ]),
              _c("div", { staticClass: "col-auto" }, [
                _vm._v("\n      " + _vm._s(_vm.info.PermittedUse) + "\n    ")
              ])
            ]
          )
        : _vm._e(),
      _vm.info.Info
        ? _c(
            "div",
            {
              staticClass: "object-info-list__item row sm-gutters",
              class: _vm.isObjectCheckout ? "" : "flex-md-column"
            },
            [
              _c("div", { staticClass: "col-auto" }, [
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v("\n        Статус"),
                  _c(
                    "span",
                    { class: _vm.isObjectCheckout ? "" : "d-md-none" },
                    [_vm._v(":")]
                  )
                ])
              ]),
              _c("div", { staticClass: "col-auto" }, [
                _vm._v("\n      " + _vm._s(_vm.info.Info) + "\n    ")
              ])
            ]
          )
        : _vm._e(),
      _vm.info.DateOfKadReg
        ? _c(
            "div",
            {
              staticClass: "object-info-list__item row sm-gutters",
              class: _vm.isObjectCheckout ? "" : "flex-md-column"
            },
            [
              _c("div", { staticClass: "col-auto" }, [
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v("\n        Дата постановки на кадастр. учет"),
                  _c(
                    "span",
                    { class: _vm.isObjectCheckout ? "" : "d-md-none" },
                    [_vm._v(":")]
                  )
                ])
              ]),
              _c("div", { staticClass: "col-auto" }, [
                _vm._v("\n      " + _vm._s(_vm.info.DateOfKadReg) + "\n    ")
              ])
            ]
          )
        : _vm._e(),
      _vm.info.UpdatedAt
        ? _c(
            "div",
            {
              staticClass: "object-info-list__item row sm-gutters",
              class: _vm.isObjectCheckout ? "" : "flex-md-column"
            },
            [
              _c("div", { staticClass: "col-auto" }, [
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v("\n        Дата обновления"),
                  _c(
                    "span",
                    { class: _vm.isObjectCheckout ? "" : "d-md-none" },
                    [_vm._v(":")]
                  )
                ])
              ]),
              _c("div", { staticClass: "col-auto" }, [
                _vm._v("\n      " + _vm._s(_vm.info.UpdatedAt) + "\n    ")
              ])
            ]
          )
        : _vm._e(),
      _vm.info.KadPrice
        ? _c(
            "div",
            {
              staticClass: "object-info-list__item row sm-gutters",
              class: _vm.isObjectCheckout ? "" : "flex-md-column"
            },
            [
              _c("div", { staticClass: "col-auto" }, [
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v("\n        Кадастровая стоимость"),
                  _c(
                    "span",
                    { class: _vm.isObjectCheckout ? "" : "d-md-none" },
                    [_vm._v(":")]
                  )
                ])
              ]),
              _c("div", { staticClass: "col-auto" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm._f("currency")(_vm.info.KadPrice, { precision: 2 })
                    ) +
                    "\n    "
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.info.DateOfPriceAdded
        ? _c(
            "div",
            {
              staticClass: "object-info-list__item row sm-gutters",
              class: _vm.isObjectCheckout ? "" : "flex-md-column"
            },
            [
              _c("div", { staticClass: "col-auto" }, [
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v("\n        Дата внесения стоимости"),
                  _c(
                    "span",
                    { class: _vm.isObjectCheckout ? "" : "d-md-none" },
                    [_vm._v(":")]
                  )
                ])
              ]),
              _c("div", { staticClass: "col-auto" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.info.DateOfPriceAdded) + "\n    "
                )
              ])
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }