

























































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppFizcheckExpertSummaryResultElem from 'shared/components/FizcheckExpert/FizcheckExpertSummaryResultElem.vue';
import sortBy from 'lodash/sortBy';

@Component({
  components: { AppFizcheckExpertSummaryResultElem },
  props: {
    currentFilter: String,
    dataFilter: String,
    personFilter: String,
    allData: Object,
    users: Array,
    findedUsers: Array,
    findedUsersData: Object,
    relatedData: Object,
  },
})
export default class AppFizcheckExpertSummaryResult extends Vue {
  // props
  currentFilter: string;
  dataFilter: string;
  personFilter: string;
  allData: any;
  users: any[];
  findedUsers: any[];
  findedUsersData: any;
  relatedData: any;

  // data

  get findedUsersSorted() {
    return sortBy(this.findedUsers, 'fio');
  }

  filterSelect(value: string) {
    this.$emit('filterSelect', value);
  }

  dataSelect(value: string) {
    this.$emit('dataSelect', value);
  }

  personSelect(value: any) {
    this.$emit('personSelect', value);
  }
}
