import { render, staticRenderFns } from "./InterpolReportDetails.vue?vue&type=template&id=107a0416&"
import script from "./InterpolReportDetails.vue?vue&type=script&lang=ts&"
export * from "./InterpolReportDetails.vue?vue&type=script&lang=ts&"
import style0 from "./InterpolReportDetails.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/realtycloud/rcfront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('107a0416', component.options)
    } else {
      api.reload('107a0416', component.options)
    }
    module.hot.accept("./InterpolReportDetails.vue?vue&type=template&id=107a0416&", function () {
      api.rerender('107a0416', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "rcshare/components/RiskNew/InterpolReportDetails.vue"
export default component.exports