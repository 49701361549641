var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "popup__body",
      class: { "is-loading": _vm.dataSendLoading }
    },
    [
      _c("h5", { staticClass: "text-center mb-4" }, [
        _vm._v("\n    Заключение\n  ")
      ]),
      _c("app-dialog-close"),
      _c(
        "div",
        { staticClass: "conclusion-form" },
        [
          _c(
            "app-form",
            {
              attrs: { model: _vm.item, "request-handler": _vm.addConclusion },
              scopedSlots: _vm._u([
                {
                  key: "button",
                  fn: function(ref) {
                    var loading = ref.loading
                    return [
                      _c(
                        "div",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "app-button",
                            {
                              attrs: {
                                type: "submit",
                                size: "sm",
                                variant: "primary-light",
                                loading: loading
                              }
                            },
                            [
                              _vm._v(
                                "\n            Добавить заключение\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "app-dropdown",
                { staticClass: "mb-3", attrs: { "close-on-select": "" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "link",
                      attrs: { "data-dropdown-trigger": "" }
                    },
                    [
                      _vm._v("\n          icon: "),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.statuses[_vm.item.status])
                        }
                      })
                    ]
                  ),
                  _c(
                    "app-dropdown-menu",
                    _vm._l(_vm.statuses, function(text, key) {
                      return _c(
                        "app-menu-item",
                        {
                          key: key,
                          nativeOn: {
                            click: function($event) {
                              return _vm.changeStatus(key)
                            }
                          }
                        },
                        [_c("span", { domProps: { innerHTML: _vm._s(text) } })]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "app-form-group",
                { attrs: { label: "Заголовок" } },
                [
                  _c("app-input", {
                    model: {
                      value: _vm.item.title,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "title", $$v)
                      },
                      expression: "item.title"
                    }
                  })
                ],
                1
              ),
              _c("app-form-group", { attrs: { label: "Текст" } }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.item.text,
                      expression: "item.text"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { rows: "4" },
                  domProps: { value: _vm.item.text },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.item, "text", $event.target.value)
                    }
                  }
                })
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm.report.conclusion
        ? _c(
            "app-collapse",
            { staticClass: "mt-4" },
            [
              _c(
                "app-collapse-item",
                { attrs: { title: "Уже добавленные заключения" } },
                _vm._l(_vm.conclusions, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "added-conclusion" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "added-conclusion__remove",
                          on: {
                            click: function($event) {
                              return _vm.remove(index)
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-trash-alt" })]
                      ),
                      _c("app-risk-conclusion-item", {
                        attrs: { conclusion: item }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }