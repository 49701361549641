var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-item-result__result-elem" },
    [
      _c(
        "app-collapse",
        [
          _c(
            "app-collapse-item",
            {
              staticClass: "report-modern-collapse",
              attrs: { opened: _vm.opened, disabled: _vm.disabled }
            },
            [
              _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                _c(
                  "div",
                  { staticClass: "report-item-result__result-elem-title" },
                  [_vm._t("header")],
                  2
                )
              ]),
              _c(
                "div",
                { staticClass: "report-item-result__content-wrapper" },
                [_vm._t("content")],
                2
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }