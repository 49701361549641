var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "person-info row no-gutters" },
    [
      _vm.isPhysical
        ? [
            _vm.owner.passport
              ? _c(
                  "div",
                  { staticClass: "person-info__item col-6 pr-2 pt-2" },
                  [
                    _c("div", { staticClass: "small text-muted" }, [
                      _vm._v("\n        Паспорт\n      ")
                    ]),
                    _c("div", { staticClass: "font-weight-500" }, [
                      _c("span", { staticClass: "d-print-none" }, [
                        _vm._v(
                          _vm._s(_vm._f("formatPassport")(_vm.owner.passport))
                        )
                      ]),
                      _c("span", { staticClass: "d-print-block d-none" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatPassport")(_vm.owner.passport, true)
                          ) + " ******"
                        )
                      ])
                    ])
                  ]
                )
              : _c(
                  "div",
                  { staticClass: "person-info__item col-6 pr-2 pt-2" },
                  [
                    _c("div", { staticClass: "small text-muted" }, [
                      _vm._v("\n        Паспорт\n      ")
                    ]),
                    _c("div", { staticClass: "font-weight-500" }, [
                      _c("span", { staticClass: "small text-warning" }, [
                        _c(
                          "span",
                          {
                            staticClass: "link font-weight-500",
                            on: {
                              click: function($event) {
                                return _vm.addField("passport")
                              }
                            }
                          },
                          [_vm._v("\n            Добавьте\n          ")]
                        ),
                        _vm._v(
                          "\n          паспорт чтобы получить больше данных в отчете"
                        )
                      ])
                    ])
                  ]
                ),
            !_vm.ownerBirthdayIsEmpty
              ? _c(
                  "div",
                  { staticClass: "person-info__item col-6 pr-2 pt-2" },
                  [
                    _c("div", { staticClass: "small text-muted" }, [
                      _vm._v("\n        Дата рождения\n      ")
                    ]),
                    _vm.owner.birthday
                      ? _c("div", { staticClass: "font-weight-500" }, [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm._f("date")(_vm.owner.birthday, "DD.MM.YYYY")
                              ) +
                              "\n      "
                          )
                        ])
                      : _c("div", { staticClass: "font-weight-500 small" }, [
                          _vm._v("\n        Отсутствует\n        "),
                          _c(
                            "span",
                            {
                              staticClass: "link font-weight-500 d-print-none",
                              on: {
                                click: function($event) {
                                  return _vm.addField("birthday")
                                }
                              }
                            },
                            [_vm._v("\n          Добавить\n        ")]
                          )
                        ])
                  ]
                )
              : _vm._e()
          ]
        : _vm._e(),
      _vm.region
        ? _c("div", { staticClass: "person-info__item col-6 pr-2 pt-2" }, [
            _c("div", { staticClass: "small text-muted" }, [
              _vm._v("\n      Регион\n    ")
            ]),
            _c("div", { staticClass: "font-weight-500" }, [
              _vm._v("\n      " + _vm._s(_vm.region) + "\n    ")
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "person-info__item col-6 pr-2 pt-2" }, [
        _c("div", { staticClass: "small text-muted" }, [
          _vm._v("\n      ИНН\n    ")
        ]),
        _c(
          "div",
          { staticClass: "font-weight-500" },
          [
            _vm.inn
              ? [
                  _vm.innManualAdded
                    ? _c("span", { staticClass: "small" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.inn.task_result.inn) +
                            "\n          "
                        ),
                        _c("br"),
                        _c("span", { staticClass: "text-muted small" }, [
                          _vm._v("(Добавлен пользователем)")
                        ])
                      ])
                    : !_vm.innManualAdded && _vm.inn.task_result.inn
                    ? _c("span", { staticClass: "small" }, [
                        _c("b", [_vm._v("В базе ФНС:")]),
                        _vm._v(
                          " " + _vm._s(_vm.inn.task_result.inn) + "\n        "
                        )
                      ])
                    : _vm.inn.status === "in progress"
                    ? _c("span", { staticClass: "small text-muted" }, [
                        _c("b", [_vm._v("В работе")]),
                        _c("i", {
                          staticClass:
                            "fas fa-spinner fa-sm fa-spin text-info ml-2"
                        })
                      ])
                    : _vm.owner.inn
                    ? _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.owner.inn) +
                            "\n          "
                        ),
                        _c("br"),
                        _c("span", { staticClass: "text-muted small" }, [
                          _vm._v("(Добавлен пользователем)")
                        ])
                      ])
                    : ["failed", "soft_failed"].includes(_vm.inn.status)
                    ? _c("span", { staticClass: "small text-muted" }, [
                        _c("b", [_vm._v("Сервис временно недоступен")])
                      ])
                    : ["not required"].includes(_vm.inn.status)
                    ? _c("span", { staticClass: "small text-muted" }, [
                        _c("b", [
                          _vm._v("\n            Добавьте\n            "),
                          !_vm.owner.passport
                            ? _c("span", [
                                _vm._v("\n              паспорт\n            ")
                              ])
                            : _vm._e(),
                          !_vm.owner.passport && !_vm.owner.birthday
                            ? _c("span", [
                                _vm._v("\n              и\n            ")
                              ])
                            : _vm._e(),
                          !_vm.owner.birthday
                            ? _c("span", [
                                _vm._v(
                                  "\n              дату рождения\n            "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(
                            "\n            чтобы начался поиск ИНН\n          "
                          )
                        ])
                      ])
                    : _c("div", { staticClass: "small text-muted" }, [
                        _c("i", {
                          staticClass: "far fa-stop-circle text-warning"
                        }),
                        _vm._v(" Не найден\n          "),
                        _c(
                          "span",
                          {
                            staticClass: "link font-weight-500 d-print-none",
                            on: {
                              click: function($event) {
                                return _vm.addField("inn")
                              }
                            }
                          },
                          [_vm._v("\n            Добавить\n          ")]
                        )
                      ])
                ]
              : _vm.owner.inn
              ? [
                  _vm._v("\n        " + _vm._s(_vm.owner.inn) + "\n        "),
                  _c("br"),
                  _c("span", { staticClass: "text-muted small" }, [
                    _vm._v("(Добавлен пользователем)")
                  ])
                ]
              : !_vm.inn
              ? _c("div", { staticClass: "small text-muted" }, [_vm._m(0)])
              : _vm._e()
          ],
          2
        )
      ]),
      _vm.getSurvey.result && _vm.getSurvey.result.length
        ? _c(
            "div",
            { staticClass: "person-info__item col-12 pt-3" },
            [
              _c("h6", [_vm._v("\n      Дополнительные сведения\n    ")]),
              _vm._l(_vm.getSurvey.result, function(elem) {
                return _c(
                  "div",
                  { key: elem.number, staticClass: "mb-2" },
                  [
                    _vm.itsArray(elem.answer)
                      ? [
                          elem.answer.length
                            ? _c("span", {
                                staticClass: "small text-muted",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.getQuestionText(elem.number) + ": "
                                  )
                                }
                              })
                            : _vm._e(),
                          _c(
                            "ul",
                            { staticClass: "list-unstyled mt-1 mb-0" },
                            _vm._l(elem.answer, function(answer, i) {
                              return _c(
                                "li",
                                { key: i, staticClass: "mb-2" },
                                _vm._l(answer, function(field, name) {
                                  return _c(
                                    "div",
                                    { key: name + i, staticClass: "pl-3" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "small text-muted" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.translate(name)) +
                                              ":\n              "
                                          )
                                        ]
                                      ),
                                      _vm.isDateField(name)
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm._f("date")(
                                                    field,
                                                    "DD.MM.YYYY"
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(field) +
                                                "\n              "
                                            )
                                          ])
                                    ]
                                  )
                                }),
                                0
                              )
                            }),
                            0
                          )
                        ]
                      : [
                          _c("span", {
                            staticClass: "small text-muted",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.getQuestionText(elem.number)
                              )
                            }
                          }),
                          _c("span", [_vm._v(": " + _vm._s(elem.answer))])
                        ]
                  ],
                  2
                )
              })
            ],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "text-warning" }, [
      _c("i", { staticClass: "far fa-stop-circle" }),
      _vm._v(" Недостаточно данных\n        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }