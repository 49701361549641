var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPhysical
    ? _c(
        "app-report-item",
        {
          attrs: {
            title: "Самозанятый",
            icon: "briefcase",
            status: _vm.status,
            images: _vm.images,
            "missing-requirements": _vm.missingRequirements,
            color: _vm.map.color,
            source: {
              domain: "nalog.ru",
              link: "https://npd.nalog.ru/check-status/"
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "report-item-modern__subtitle" },
            [
              _vm.task
                ? [
                    _vm.resultStatus === "found"
                      ? _c("span", [
                          _vm._v(
                            "\n        Найден в реестре самозанятых\n      "
                          )
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n        В реестре самозанятых не найден\n      "
                          )
                        ])
                  ]
                : _vm.orderIsDone
                ? [
                    _c("span", { staticClass: "text-warning" }, [
                      _vm._v(" Недостаточно данных ")
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }