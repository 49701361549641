var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c("h5", { staticClass: "text-center mb-3" }, [
        _vm._v("\n    Настройки пользователя\n  ")
      ]),
      _vm.settings
        ? _c(
            "app-form",
            {
              attrs: { "request-handler": _vm.onSubmit, model: _vm.model },
              scopedSlots: _vm._u(
                [
                  {
                    key: "button",
                    fn: function(ref) {
                      var loading = ref.loading
                      return [
                        _c(
                          "app-button",
                          {
                            attrs: {
                              type: "submit",
                              loading: loading,
                              block: ""
                            }
                          },
                          [_vm._v("\n        Сохранить\n      ")]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2220093858
              )
            },
            _vm._l(_vm.usedSettings, function(elem) {
              return _c(
                "app-form-group",
                { key: elem.key, attrs: { label: elem.label } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "custom-control custom-radio custom-control-inline"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model[elem.key],
                            expression: "model[elem.key]"
                          }
                        ],
                        staticClass: "custom-control-input",
                        attrs: {
                          type: "radio",
                          id: elem.key + 1,
                          name: elem.key + "_radio",
                          value: "active"
                        },
                        domProps: {
                          checked: _vm._q(_vm.model[elem.key], "active")
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.model, elem.key, "active")
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          attrs: { for: elem.key + 1 }
                        },
                        [_vm._v("Вкл")]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "custom-control custom-radio custom-control-inline"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model[elem.key],
                            expression: "model[elem.key]"
                          }
                        ],
                        staticClass: "custom-control-input",
                        attrs: {
                          type: "radio",
                          id: elem.key + 2,
                          name: elem.key + "_radio",
                          value: "disabled"
                        },
                        domProps: {
                          checked: _vm._q(_vm.model[elem.key], "disabled")
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.model, elem.key, "disabled")
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          attrs: { for: elem.key + 2 }
                        },
                        [_vm._v("Выкл")]
                      )
                    ]
                  )
                ]
              )
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }