var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("h5", { staticClass: "text-center" }, [
        _vm._v("\n    Создать настройку\n  ")
      ]),
      _c("app-dialog-close"),
      _c(
        "app-form",
        {
          attrs: { model: _vm.model, "request-handler": _vm.submit },
          scopedSlots: _vm._u([
            {
              key: "button",
              fn: function(ref) {
                var loading = ref.loading
                return [
                  _c(
                    "app-button",
                    { attrs: { loading: loading, type: "submit", block: "" } },
                    [_vm._v("\n        Создать\n      ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "app-form-group",
            {
              attrs: {
                label:
                  "Ключ (название на английском, вместо пробелов использовать подчёркивание _)"
              }
            },
            [
              _c("app-input", {
                attrs: { size: "sm" },
                model: {
                  value: _vm.model.key,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "key", $$v)
                  },
                  expression: "model.key"
                }
              })
            ],
            1
          ),
          _c("app-form-group", { attrs: { label: "Текст (необязательно)" } }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.text,
                  expression: "model.text"
                }
              ],
              staticClass: "form-control",
              attrs: { rows: "2" },
              domProps: { value: _vm.model.text },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.model, "text", $event.target.value)
                }
              }
            })
          ]),
          _c(
            "app-form-group",
            [
              _c(
                "app-checkbox",
                {
                  model: {
                    value: _vm.model.enabled,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "enabled", $$v)
                    },
                    expression: "model.enabled"
                  }
                },
                [_vm._v("\n        Активировать эту настойку?\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }