


































































import Vue from 'vue';
import Component from 'vue-class-component';
import addDays from 'date-fns/add_days';
import getDaysInMonth from 'date-fns/get_days_in_month';
import format from 'date-fns/format';
import noop from 'lodash/noop';

const TODAY_DATE = new Date();

@Component({
  popupOptions: {
    name: 'popup_side',
  },

  props: {
    date: Object,
    onClose: {
      type: Function,
      default: noop,
    },
  },
})
export default class AdminStatsDateFilterDialog extends Vue {
  // props
  date: { from: string; to: string };
  onClose!: () => void;

  // data()
  datePresets: SimpleObject = {
    today: {
      from: TODAY_DATE,
      to: addDays(TODAY_DATE, 1),
    },
    yesterday: {
      from: addDays(TODAY_DATE, -1),
      to: TODAY_DATE,
    },
    week: {
      from: addDays(TODAY_DATE, -7),
      to: TODAY_DATE,
    },
    month: {
      from: addDays(TODAY_DATE, -getDaysInMonth(TODAY_DATE)),
      to: TODAY_DATE,
    },
  };

  setFilter(presetName: string) {
    const preset = this.datePresets[presetName];

    this.date.from = format(preset.from, 'YYYY-MM-DD');
    this.date.to = format(preset.to, 'YYYY-MM-DD');
  }

  // lifecycle
  beforeDestroy() {
    this.onClose();
  }
}
