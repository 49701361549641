var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sud-details" }, [
    _c(
      "div",
      {
        staticClass: "popup__header",
        on: {
          click: function($event) {
            return _vm.$emit("toggleDetails")
          }
        }
      },
      [
        _c(
          "app-button",
          {
            staticClass: "sud-details__back",
            attrs: { variant: "light", size: "sm" }
          },
          [
            _c("i", { staticClass: "fas fa-xs fa-arrow-left mr-1" }),
            _vm._v("\n      Назад\n    ")
          ]
        )
      ],
      1
    ),
    _c("div", { staticClass: "popup__body" }, [
      _c("div", { staticClass: "sud-details__content" }, [
        _c("div", { staticClass: "sud-details__title" }, [
          _vm._v("\n        Детали дела\n      ")
        ]),
        _vm.details.casename
          ? _c("div", { staticClass: "sud-details-item" }, [
              _c("div", { staticClass: "sud-details-item__title" }, [
                _vm._v("\n          Номер дела\n        ")
              ]),
              _c("div", { staticClass: "sud-details-item__value" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.details.casename) + "\n        "
                )
              ])
            ])
          : _vm._e(),
        _vm.details.date
          ? _c("div", { staticClass: "sud-details-item" }, [
              _c("div", { staticClass: "sud-details-item__title" }, [
                _vm._v("\n          Дата\n        ")
              ]),
              _c("div", { staticClass: "sud-details-item__value" }, [
                _vm._v("\n          " + _vm._s(_vm.details.date) + "\n        ")
              ])
            ])
          : _vm._e(),
        _vm.details.category
          ? _c("div", { staticClass: "sud-details-item" }, [
              _c("div", { staticClass: "sud-details-item__title" }, [
                _vm._v("\n          Категория\n        ")
              ]),
              _c("div", { staticClass: "sud-details-item__value" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.details.category) + "\n        "
                )
              ])
            ])
          : _vm._e(),
        _vm.details.subcategory
          ? _c("div", { staticClass: "sud-details-item" }, [
              _c("div", { staticClass: "sud-details-item__title" }, [
                _vm._v("\n          Подкатегория\n        ")
              ]),
              _c("div", { staticClass: "sud-details-item__value" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.details.subcategory) +
                    "\n        "
                )
              ])
            ])
          : _vm._e(),
        _vm.details.article
          ? _c("div", { staticClass: "sud-details-item" }, [
              _c("div", { staticClass: "sud-details-item__title" }, [
                _vm._v("\n          Статья\n        ")
              ]),
              _c("div", { staticClass: "sud-details-item__value" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.details.article) + "\n        "
                )
              ])
            ])
          : _vm._e(),
        _vm.details.fio
          ? _c("div", { staticClass: "sud-details-item" }, [
              _c("div", { staticClass: "sud-details-item__title" }, [
                _vm._v("\n          ФИО участника\n        ")
              ]),
              _c("div", { staticClass: "sud-details-item__value" }, [
                _vm._v("\n          " + _vm._s(_vm.details.fio) + "\n        ")
              ])
            ])
          : _vm._e(),
        _vm.details.id_region
          ? _c("div", { staticClass: "sud-details-item" }, [
              _c("div", { staticClass: "sud-details-item__title" }, [
                _vm._v("\n          Регион\n        ")
              ]),
              _c("div", { staticClass: "sud-details-item__value" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.getRegionNameById(_vm.details.id_region.toString())
                    ) +
                    "\n        "
                )
              ])
            ])
          : _vm._e(),
        _vm.details.sudname
          ? _c("div", { staticClass: "sud-details-item" }, [
              _c("div", { staticClass: "sud-details-item__title" }, [
                _vm._v("\n          Суд\n        ")
              ]),
              _c("div", { staticClass: "sud-details-item__value" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.details.sudname) + "\n        "
                )
              ])
            ])
          : _vm._e(),
        _vm.details.judge
          ? _c("div", { staticClass: "sud-details-item" }, [
              _c("div", { staticClass: "sud-details-item__title" }, [
                _vm._v("\n          Судья\n        ")
              ]),
              _c("div", { staticClass: "sud-details-item__value" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.details.judge) + "\n        "
                )
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }