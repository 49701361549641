













import Vue from 'vue';

export default Vue.extend({
  props: {
    conclusionStatus: String,
  },

  computed: {

    textByStatus(): string {
      switch (this.conclusionStatus) {
        case 'low': return 'Низкий';
        case 'medium': return 'Средний';
        case 'high': return 'Высокий';
        default: return null;
      }
    },
  },
});
