















































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportCollapseItem from 'shared/components/FizCheck/ReportCollapseItem.vue';
import AppPagination from 'shared/components/ui/Pagination/Pagination.vue';
import chunk from 'lodash/chunk';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';

@Component({
  components: { AppReportCollapseItem, AppPagination },
  props: {
    result: Array,
    opened: Boolean,
    isPdf: Boolean,
  },
})
export default class AppFsspDetails extends Vue {
  // props
  result: any[];
  // data
  currentFilter: string = 'all';
  opened: boolean;
  isPdf: boolean;

  currentPage: number = 1;
  perPage: number = 10;

  get elems() {
    return this.isPdf ? this.filteredResult : this.paginatedResult[this.currentPage - 1];
  }

  get paginatedResult() {
    return chunk(this.filteredResult, this.perPage);
  }

  get filteredResult() {
    switch (this.currentFilter) {
      case 'active':
        return this.activeCases;
      case 'closed':
        return this.closedCases;
      default:
        return this.sortedResult;
    }
  }

  get activeCases() {
    const activeCases = filter(this.result, (el) => !el.date_and_reason_to_stop);
    return this.sortCases(activeCases);
  }

  get closedCases() {
    const closedCases = filter(this.result, (el) => !!el.date_and_reason_to_stop);
    return this.sortCases(closedCases);
  }

  get sortedResult() {
    return [ ...this.activeCases, ...this.closedCases ];
  }

  parseDate(dateString: string) {
    const regex = /(\d{2})\.(\d{2})\.(\d{4})/;
    const match = dateString.match(regex);
    return match ? new Date(`${match[3]}-${match[2]}-${match[1]}`) : '';
  }

  sortCases(cases: any) {
    return orderBy(cases, (el) => this.parseDate(el.date_and_reason_to_stop || el.enforcement_proceedings), [ 'desc' ]);
  }

  pageChanged(page: number) {
    this.currentPage = page;
  }
}
