var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("div", { staticClass: "mb-4" }, [_c("div", { ref: "editor" })]),
      _c(
        "app-button",
        {
          attrs: { variant: "primary-light", loading: _vm.loading },
          on: { click: _vm.save }
        },
        [_vm._v("\n    Сохранить\n  ")]
      ),
      _c(
        "app-button",
        {
          staticClass: "ml-3",
          attrs: { variant: "light" },
          on: {
            click: function($event) {
              return _vm.$dialog.close()
            }
          }
        },
        [_vm._v("\n    Отмена\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }