var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body owner-add-field" },
    [
      _c("app-dialog-close"),
      _c("h4", { staticClass: "mb-3" }, [_vm._v("\n    Дата рождения\n  ")]),
      _c(
        "app-form",
        {
          attrs: {
            model: _vm.model,
            rules: _vm.validationConfig,
            "request-handler": _vm.onSubmit
          }
        },
        [
          _c(
            "app-form-group",
            {
              staticClass: "rc-form-group mb-2",
              attrs: { "model-name": "birthday" }
            },
            [
              _c("app-date-picker", {
                attrs: {
                  label: "Дата рождения",
                  options: _vm.datePickerOptions,
                  placeholder: "ДД.ММ.ГГГГ"
                },
                model: {
                  value: _vm.model.birthday,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "birthday", $$v)
                  },
                  expression: "model.birthday"
                }
              })
            ],
            1
          ),
          _c(
            "app-button",
            {
              attrs: {
                type: "submit",
                variant: "primary",
                size: "lg",
                loading: _vm.loading,
                block: ""
              }
            },
            [_vm._v("\n      Сохранить\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }