













































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  popupOptions: {
    size: 'sm',
  },

  props: {
    onSubmit: Function,
    orders: Array,
  },
})

export default class UpdateReportDialog extends Vue {
  // props
  public onSubmit: Function;
  public orders: AdminOrderItem[];

  // data
  order = {
    orderRiskID: '',
    orderEgrnID: '',
    orderRightListID: '',
  }

  // methods
  submit() {
    this.onSubmit(this.order.orderRiskID, this.order.orderEgrnID, this.order.orderRightListID);
  }

  // lifecycle
  async created() {
    for (const order of this.orders) {
      if (order.ProductName === 'EgrnObject' || order.ProductName === 'EgrnObjectFast') {
        this.order.orderEgrnID = order.OrderItemID;
      }
      if (order.ProductName === 'EgrnRightList' || order.ProductName === 'EgrnRightListFast') {
        this.order.orderRightListID = order.OrderItemID;
      }
      if (order.ProductName === 'RiskAssessment' || order.ProductName === 'RiskAssessmentSimple' || order.ProductName === 'RiskAssessmentAdvanced' || order.ProductName === 'RiskAssessmentFast') {
        this.order.orderRiskID = order.OrderItemID;
      }
    }
  }
}

