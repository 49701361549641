






















import Vue from 'vue';
import Component from 'vue-class-component';
import get from 'lodash/get';

import AppStatementTableRow from 'shared/components/EgrnObject/StatementTableRow.vue';
import AppStatementTableSingleRow from 'shared/components/EgrnObject/StatementTableSingleRow.vue';

@Component({
  components: { AppStatementTableRow, AppStatementTableSingleRow },

  props: {
    contour: Object,
  },
})
export default class AppStatementEgrnObjectContours extends Vue {
  contour: any;
  get = get;
}
