























































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppPagination from 'shared/components/ui/Pagination/Pagination.vue';
import chunk from 'lodash/chunk';
import find from 'lodash/find';
import AppReportCollapseItem from 'shared/components/FizCheck/ReportCollapseItem.vue';

@Component({
  components: { AppReportCollapseItem, AppPagination },
  props: {
    result: Array,
    opened: Boolean,
  },
})
export default class AppFsspGosuslugiDetails extends Vue {
  // props
  result: any[];
  opened: boolean;

  currentPage: number = 1;
  perPage: number = 10;

  // get sortedResult() {
  //   console.log(this.result);
  //   return concat(
  //     map(get(this.result, 'inn.bills'), (el) => this.mapBills(el, 'ИНН')),
  //     map(get(this.result, 'passport.bills'), (el) => this.mapBills(el, 'Паспорт')),
  //     map(get(this.result, 'snils.bills'), (el) => this.mapBills(el, 'СНИЛС')),
  //   );
  // }

  get elems() {
    return this.$isServer ? this.result : this.paginatedResult[this.currentPage - 1];
  }

  get paginatedResult() {
    return chunk(this.result, this.perPage);
  }

  getAttr(item: any, key: string) {
    if (item.addAttrs && item.addAttrs.attrs) {
      const attr = find(item.addAttrs.attrs, (el) => el.name === key);
      return attr.value || 'Не найдено';
    }
    return 'Не найдено';
  }

  pageChanged(page: number) {
    this.currentPage = page;
  }

  // mapBills(elem: any, type: string) {
  //   elem.type_name = type;
  //   return elem;
  // }
}
