var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "14",
        height: "18",
        viewBox: "0 0 14 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M12.0007 0.666748H6.02565C5.58398 0.666748 5.15898 0.841748 4.84232 1.15841L0.833984 5.18341C0.525651 5.49175 0.350651 5.91675 0.350651 6.35008L0.333984 15.6667C0.333984 16.5834 1.08398 17.3334 2.00065 17.3334H12.0007C12.9173 17.3334 13.6673 16.5834 13.6673 15.6667V2.33341C13.6673 1.41675 12.9173 0.666748 12.0007 0.666748ZM7.83398 13.1667H6.16732V11.5001H7.83398V13.1667ZM7.00065 9.83342C6.54232 9.83342 6.16732 9.45842 6.16732 9.00008V6.50008C6.16732 6.04175 6.54232 5.66675 7.00065 5.66675C7.45898 5.66675 7.83398 6.04175 7.83398 6.50008V9.00008C7.83398 9.45842 7.45898 9.83342 7.00065 9.83342Z",
          fill: "#F0A900"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }