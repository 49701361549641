







































































import Vue from 'vue';
import Component from 'vue-class-component';

import { Validators } from 'shared/utils/validator';

@Component({
  popupOptions: {
    size: 'xs',
  },

  props: {
    userId: String,
    products: Object,
    onAdded: Function,
  },
})
export default class AdminCreateTariffDialog extends Vue {
  // props
  userId!: string;
  products!: { [key: string]: ProductName };
  onAdded!: Function;

  // props end

  // data()
  model: Api.Admin.Tariff.Request.Create = {
    person_id: this.userId,
    product_id: null,
    precent: '0.1',
    base_cost: '',
    amount: '',
  };

  // lifecycle hooks

  // methods
  validationRules(): ValidationConfig {
    return {
      person_id: [ Validators.required ],
      product_id: [ Validators.required ],
      precent: [ Validators.required ],
      base_cost: [ Validators.required ],
      amount: [ Validators.required ],
    };
  }

  async onSubmit() {
    try {
      await this.$api.admin.tariff.Add(this.model);
      this.onAdded();
      this.$dialog.close();
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: this.$api._getErrorMessage(error) });
    }
  }
}
