





















































import Component, { mixins } from 'vue-class-component';
import DataTableMixin from '@/core/mixins/data-table.mixin';
import ClipboardJS from 'clipboard';
import ClientsWithTariffesFilter from '@/views/Admin/components/ClientsWithTariffesFilter.vue';

function defaultFilter(): SimpleObject {
  return {
    email: '',
    tariffes: '',
    tariffInactivate: false,
    emptyBalance: false,
    responsiblePersonID: '',
    showFreeEmailDomains: false,
    activationDateFrom: '',
    activationDateTo: '',
    restBalanceFrom: '',
    restBalanceTo: '',
  };
}

let clipboard: ClipboardJS;

@Component
export default class ClientsWithTariffesTable extends mixins(DataTableMixin) {
  filter: any = defaultFilter();
  headers: TableHeaderItem[] = [
    {
      text: 'Email',
      sortProp: 'email',
    },
    {
      text: 'Тариф',
      sortProp: 'tariff_name',
    },
    {
      text: 'Дата активации',
      sortProp: 'from_date',
    },
    {
      text: 'Дата окончания',
      sortProp: 'thru_date',
    },
    {
      text: 'Последнее пополнение',
      sortProp: 'last_balance_replenishment_date',
    },
    {
      text: 'Сумма пополнения',
      sortProp: 'last_balance_replenishment_amount',
    },
    {
      text: 'Сумма на балансе',
      sortProp: 'balance',
    },
  ];

  // methods

  handleRequest(offset: number, limit: number) {
    // const filter = {
    //   ...this.filter,
    //   responsiblePersonID: _.get(this.filter, 'responsiblePersonID.id', ''),
    //   sourceID: _.map(this.filter.sourceID, 'source_id'),
    // };

    return this.$api.admin.UserTariffes({ offset, limit });
  }

  openFilter() {
    this.$dialog.open({
      component: ClientsWithTariffesFilter,
      props: {
        defaultFilter: defaultFilter,
        // tariffes: this.sources,
        // statuses: this.statuses,
        // filter: this.filter,
        // submit: () => {
        //   this.$dialog.close();
        //   const filter = {
        //     ...this.filter,
        //     sourceID: this.filter.sourceID.map((item: any) => item.source_id),
        //   };

        //   this.applyFilter(filter);
        // },
      },
    });
  }

  // lifecycle
  mounted() {
    clipboard = new ClipboardJS('[data-clipboard-text]');
    clipboard.on('success', (event: ClipboardJS.Event) => {
      const title = event.trigger.getAttribute('data-title') || 'Transaction ID';

      this.$noty.success({
        text: `<div class="small">${title} скопирован <br> ${event.text}</div>`,
      });
    });
  }
}

