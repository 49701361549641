import Component, { mixins } from 'vue-class-component';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import size from 'lodash/size';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';

import formatToDate from 'shared/utils/formatStringToDate';
import StatementBaseMixin from './statementBase.mixin';

@Component
export default class StatementRightListMixin extends mixins(StatementBaseMixin) {
  // История правообладателей из Выписки о переходе прав
  get historyOwners(): { dates: string[]; items: { [date: string]: StatementEntity[] }; names: string[]; rights: any } {
    const rightList = cloneDeep(this.statements.rightList);
    const rights: StatementRight[] = sortBy(get(rightList, 'data.extract_object.rights', []), (elem: any) => {
      const date = get(elem, 'registration.reg_date', '');
      return formatToDate(date).getTime();
    });

    if (rights && Array.isArray(rights)) {
      const entities = this._getAllEntities(rights);

      const items = groupBy(entities, 'registration.reg_date');

      const dates = Object.keys(items).sort((a, b) => {
        return formatToDate(a).getTime() - formatToDate(b).getTime();
      });

      const names = this._mapEntityNames(entities);

      return { dates, items, names, rights };
    }

    return null;
  }

  /**
   * Количество сделок
   */
  get historyDealsCount(): number {
    return size(get(this.historyOwners, 'items'));
  }

  get status(): Pick<StatementItem, 'isCompleted' | 'isPurchased'> {
    return omit(this.statements.rightList, 'data');
  }

  getRegistrationType(entity: StatementEntity): string {
    if (entity.registration) {
      return get(entity, 'registration.type.type_name') || entity.registration.name;
    }

    return null;
  }
}
