


























































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { ENCUMBRANCE_BY_TYPE } from 'shared/utils/constants/encumbrance-types';
import formatDate from 'date-fns/format';
import locale from 'date-fns/locale/ru';

@Component({
  props: {
    data: {
      type: Object,
    },
  },
})
export default class AppDivergenceDialog extends Vue {
  // props
  data: any;

  // methods
  getEncumbranceType(encumbranceType: string): string {
    return ENCUMBRANCE_BY_TYPE[encumbranceType];
  }

  getFormatDate(encumbranceDate: number): string {
    return formatDate(new Date(encumbranceDate), 'DD.MM.YYYY', { locale });
  }
}
