





















































import Vue from 'vue';
import { noop, cloneDeep, isEmpty, omit } from 'lodash';
import Component from 'vue-class-component';
import AppOwnerFormItemBase from './OwnerFormItemBase.vue';
import isUnderage from 'shared/utils/isUnderage';
import { Validators } from 'shared/utils/validator';

@Component({
  components: { AppOwnerFormItemBase },
  props: {
    owner: Object,
    onSave: {
      type: Function,
      default: noop,
    },
    fetchReport: {
      type: Function,
      default: noop,
    },
    rightsList: {
      type: Array,
      default: null,
    },
    onSuccess: Function,
  },

  popupOptions: {
    disableBackdrop: true,
  },
})
export default class EditOwnerItemDialog extends Vue {
  // props
  owner: LawInformationOwner;
  onSave: Function;
  onSuccess: (model: any) => void;
  fetchReport: () => void;
  // data
  model: LawInformationOwner = null;

  created() {
    const model = cloneDeep(this.owner);
    this.model = model;
  }

  get isCheckPerson(): boolean {
    return this.$router.currentRoute.name === 'check-person-id';
  }

  get isCheckout(): boolean {
    return this.$router.currentRoute.name === 'fizcheckCheckout';
  }

  get isRisk(): boolean {
    return this.$router.currentRoute.name === 'object-objectKey';
  }

  async onSubmit() {
    try {
      const body = cloneDeep(this.model);

      if (this.onSuccess) {
        this.onSuccess(body);
      } else {
        await this.$api.risk.UpdateOwnerV2(body);
        this.fetchReport();
      }

      this.onSave();
      this.$dialog.close();
    } catch (error) {
      this.$noty.error({ text: this.$api.getErrorMessage(error as any) });
    }
    return Promise.resolve();
  }

  validationConfig(model: LawInformationOwner): ValidationConfig {
    if (model.owner_type === 0) {
      let config: ValidationConfig = {
        first: [ Validators.required ],
        surname: [ Validators.required ],
        passport: [ Validators.min(10, 'Минимальная длина 10') ],
        birthday: [ Validators.date('Неверный формат даты') ],
        snils: [ Validators.snils ],
        inn: [ Validators.min(12, 'Минимальная длина 12') ],
      };

      if (isEmpty(model.passport)) {
        config = omit(config, 'passport');
      }

      if (this.isCheckPerson) {
        config = omit(config, 'region');
      }

      if (isEmpty(model.snils)) {
        config = omit(config, 'snils');
      }

      if (isEmpty(model.inn)) {
        config = omit(config, 'inn');
      }

      if (isEmpty(model.birthday)) {
        config = omit(config, 'birthday');
      }

      if (isUnderage(model.birthday)) {
        config = omit(config, 'passport', 'region');
      }

      return config;
    } else {
      return {
        first: [ Validators.required ],
        inn: [ Validators.required, Validators.max(12, 'Максимальная длина 12') ],
        region: [ Validators.required ],
      };
    }
  }
}
