var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-report-item",
    {
      attrs: {
        title: "Налоги и сборы",
        icon: "chart-pie",
        opened: true,
        disabled: "",
        status: "done",
        color: "success"
      },
      scopedSlots: _vm._u(
        [
          _vm.allTaxes && _vm.allTaxes.length
            ? {
                key: "extra-content",
                fn: function() {
                  return [
                    _c("div", [
                      _c("div", { staticClass: "rc-table-wrapper" }, [
                        _c("table", { staticClass: "rc-table" }, [
                          _c("thead", [
                            _c(
                              "tr",
                              [
                                _c("th", [
                                  _vm._v(
                                    "\n                Налог\n              "
                                  )
                                ]),
                                _vm._l(_vm.allTaxes, function(elem, i) {
                                  return _c("th", { key: i }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(elem["Год"]) +
                                        "\n              "
                                    )
                                  ])
                                })
                              ],
                              2
                            )
                          ]),
                          _c(
                            "tbody",
                            [
                              _vm._l(_vm.groupedTaxes, function(arr, item) {
                                return _c(
                                  "tr",
                                  { key: item },
                                  [
                                    _c(
                                      "td",
                                      { staticClass: "rc-table__first-td" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    _vm._l(_vm.allTaxes.length, function(
                                      value,
                                      index
                                    ) {
                                      return _c(
                                        "td",
                                        { key: value.year + index.toString() },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  arr[index]
                                                    ? arr[index]["@attributes"][
                                                        "СумУплНал"
                                                      ]
                                                    : "-"
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              }),
                              _c(
                                "tr",
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "rc-table__first-td font-weight-500"
                                    },
                                    [
                                      _vm._v(
                                        "\n                Всего\n              "
                                      )
                                    ]
                                  ),
                                  _vm._l(_vm.allTaxes, function(elem, ind) {
                                    return _c(
                                      "td",
                                      {
                                        key: ind,
                                        staticClass: "font-weight-500"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("currency")(elem.sum)
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            ],
                            2
                          )
                        ])
                      ])
                    ])
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      !_vm.allTaxes || !_vm.allTaxes.length
        ? _c("div", { staticClass: "report-item-modern__subtitle" }, [
            _vm._v("\n    Налоги и сборы не найдены\n  ")
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }