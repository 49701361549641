var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("h5", { staticClass: "mb-4" }, [
        _vm._v("\n    Редактирование информации\n  ")
      ]),
      _c("app-dialog-close"),
      _c(
        "app-form",
        {
          attrs: {
            model: _vm.model,
            "request-handler": _vm.onSubmit,
            rules: _vm.validationConfig
          },
          scopedSlots: _vm._u([
            {
              key: "button",
              fn: function(ref) {
                var loading = ref.loading
                return [
                  _c(
                    "app-button",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        variant: "primary-light",
                        size: "sm",
                        type: "submit",
                        loading: loading
                      }
                    },
                    [_vm._v("\n        Сохранить\n      ")]
                  ),
                  _c(
                    "app-button",
                    {
                      attrs: { variant: "light", size: "sm" },
                      on: {
                        click: function($event) {
                          return _vm.$dialog.close()
                        }
                      }
                    },
                    [_vm._v("\n        Отменить\n      ")]
                  )
                ]
              }
            }
          ])
        },
        [
          !_vm.isCheckPerson && !_vm.isCheckout
            ? _c("h6", [_vm._v("\n      Собственник\n    ")])
            : _vm._e(),
          _c("app-owner-form-item-base", {
            staticClass: "mb-3",
            attrs: {
              owner: _vm.model,
              "is-editing": "",
              "is-check-person": !_vm.isRisk,
              "rights-list": _vm.rightsList
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }