



























import Vue from 'vue';
import Component from 'vue-class-component';
import AppFsinReportDetails from 'shared/components/RiskNew/FsinReportDetails.vue';

@Component({
  components: {
  },
  props: {
    result: Object,
  },
})
export default class AppFsinReport extends Vue {
  // props
  result: any;

  showDetails() {
    this.$dialog.open({
      component: AppFsinReportDetails,

      props: {
        result: this.result.result,
      },
    });
  }
}
