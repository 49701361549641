var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isPdf
      ? _c("div", { staticClass: "check-fiz__logo" }, [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "row align-items-center justify-content-between" },
              [
                _vm.haveLogo
                  ? _c("div", { staticClass: "col-auto" }, [
                      _c("img", {
                        attrs: { height: "45px", src: _vm.haveLogo }
                      })
                    ])
                  : _vm.isFizcheckOrder
                  ? _c(
                      "div",
                      { staticClass: "col-auto" },
                      [
                        _c("app-fizcheck-logo-short", { staticClass: "mr-2" }),
                        _c("app-fizcheck-logo")
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "col-auto" },
                      [
                        _c("app-logo-svg", {
                          attrs: { height: "45", width: "90" }
                        })
                      ],
                      1
                    ),
                _c("div", { staticClass: "col-auto" }, [
                  _c("div", [
                    _c(
                      "span",
                      { staticClass: "check-fiz__date-order text-muted" },
                      [
                        _vm._v(
                          "\n              Дата проверки:\n              " +
                            _vm._s(
                              _vm._f("date")(
                                _vm.report.order.created_at,
                                "DD.MM.YYYY HH:mm:ss"
                              )
                            ) +
                            "\n            "
                        )
                      ]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "check-fiz__order-number text-muted" },
                    [
                      _vm._v(
                        "\n            Номер заказа:\n            " +
                          _vm._s(_vm.report.order.order_item_id) +
                          "\n          "
                      )
                    ]
                  )
                ])
              ]
            )
          ])
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "check-fiz__owner-info",
        attrs: { id: "ownerInfoHeader" }
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row check-fiz__owner-info-top" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md mb-3 mb-md-0 d-none d-md-block" },
              [
                !_vm.isPdf
                  ? _c(
                      "div",
                      [
                        _c(
                          "nuxt-link",
                          {
                            staticClass: "check-fiz__link",
                            attrs: { to: { name: "user-orders" } }
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-chevron-left mr-1"
                            }),
                            _vm._v("\n              Мои заказы\n            ")
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            ),
            !_vm.isPdf
              ? _c("div", { staticClass: "col-12 col-md-auto" }, [
                  _c(
                    "div",
                    { staticClass: "check-fiz__owner-number" },
                    [
                      _c("div", [
                        _c("span", { staticClass: "check-fiz__date-order" }, [
                          _vm._v(
                            "\n                Дата проверки:\n                " +
                              _vm._s(
                                _vm._f("date")(
                                  _vm.report.order.created_at,
                                  "DD.MM.YYYY HH:mm:ss"
                                )
                              ) +
                              "\n              "
                          )
                        ])
                      ]),
                      _c("div", {
                        staticClass:
                          "check-fiz__owner-info-divider d-none d-md-block"
                      }),
                      !_vm.demo
                        ? _c(
                            "div",
                            { staticClass: "check-fiz__order-number" },
                            [
                              _vm._v(
                                "\n              Номер заказа:\n              " +
                                  _vm._s(_vm.report.order.order_item_id) +
                                  "\n            "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.allowPdfDownload && !_vm.demo && _vm.orderIsPaid
                        ? [
                            _c("div", {
                              staticClass:
                                "check-fiz__owner-info-divider d-none d-md-block"
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "check-fiz__download-pdf d-none d-md-block",
                                on: { click: _vm.download }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src:
                                      "/rc-modern/Convert-Pdf-2--Streamline-Core.svg.svg"
                                  }
                                }),
                                _c("span", [
                                  _vm._v(
                                    "\n                  скачать PDF\n                "
                                  )
                                ])
                              ]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            {
              staticClass:
                "row align-items-center justify-content-between flex-md-nowrap"
            },
            [
              _c("div", { staticClass: "col-auto" }, [
                _c("div", { staticClass: "check-fiz__owner-info-person" }, [
                  _c(
                    "div",
                    { staticClass: "check-fiz__avatar" },
                    [
                      _vm.image
                        ? _c("img", { attrs: { src: _vm.image } })
                        : _c("app-male-user-img")
                    ],
                    1
                  ),
                  _c("div", {}, [
                    _c("div", {}, [
                      _c("div", { staticClass: "check-fiz__owner-info-name" }, [
                        _c("div", [
                          _c("h5", {}, [
                            _vm._v(
                              "\n                      " +
                                _vm._s(_vm.owner.owner.surname) +
                                " " +
                                _vm._s(_vm.owner.owner.first) +
                                "\n                      " +
                                _vm._s(_vm.owner.owner.patronymic)
                            ),
                            _vm.age
                              ? _c(
                                  "span",
                                  { staticClass: "check-fiz__owner-info-age" },
                                  [
                                    _vm._v(
                                      "\n                        , " +
                                        _vm._s(_vm.age) +
                                        " лет\n                      "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]),
                        _c("div", {
                          staticClass: "check-fiz__owner-info-name-divider"
                        }),
                        !_vm.isUnderage(_vm.owner.owner.birthday)
                          ? _c("div", { staticClass: "d-none d-md-block" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "check-fiz__owner-info-progress"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "check-owner-progress__head"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "check-owner-progress__count"
                                        },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(_vm.completedCount) +
                                              " из " +
                                              _vm._s(_vm.totalParsers) +
                                              " выполнено\n                        "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "check-owner-progress__status"
                                        },
                                        [
                                          _vm.isComplete
                                            ? _c("i", {
                                                staticClass:
                                                  "fa fa-check-circle text-success fa-xs"
                                              })
                                            : [
                                                _c("i", {
                                                  directives: [
                                                    {
                                                      name: "tippy",
                                                      rawName: "v-tippy",
                                                      value: {
                                                        placement: "top-end",
                                                        content:
                                                          "Отчет обновляется, идет проверка по нескольким источникам, в том числе скрытым, для точности данных."
                                                      },
                                                      expression:
                                                        "{\n                                placement: 'top-end',\n                                content:\n                                  'Отчет обновляется, идет проверка по нескольким источникам, в том числе скрытым, для точности данных.'\n                              }"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "fas fa-question-circle fa-xs"
                                                }),
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-spinner fa-spin text-muted fa-xs"
                                                })
                                              ]
                                        ],
                                        2
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "progress" }, [
                                    _c("div", {
                                      staticClass: "progress-bar",
                                      style: {
                                        width: _vm.completedProgress + "%"
                                      }
                                    })
                                  ])
                                ]
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  ])
                ])
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isPdf,
                      expression: "!isPdf"
                    }
                  ],
                  staticClass: "col-12 col-md-auto"
                },
                [
                  _c(
                    "div",
                    { staticClass: "check-fiz__owner-buttons no-gutters" },
                    [
                      _c(
                        "div",
                        { staticClass: "col col-md-auto" },
                        [
                          _c(
                            "app-button",
                            {
                              attrs: {
                                variant: "modern-white",
                                size: "lg",
                                block: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.scrollTo("main-report")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                Базовый\n                "
                              ),
                              _c(
                                "span",
                                { staticClass: "d-none d-md-inline-block" },
                                [
                                  _vm._v(
                                    "\n                  отчет\n                "
                                  )
                                ]
                              ),
                              !_vm.isComplete
                                ? _c("i", {
                                    staticClass: "fas fa-spinner fa-spin ml-1"
                                  })
                                : _c("i", { staticClass: "fas fa-check ml-1" })
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "check-fiz__download-pdf sticky-download-pdf",
                          on: { click: _vm.download }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                "/rc-modern/Convert-Pdf-2--Streamline-Core.svg.svg"
                            }
                          }),
                          _c(
                            "span",
                            { staticClass: "d-none d-md-inline-block" },
                            [
                              _vm._v(
                                "\n                скачать PDF\n              "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ])
      ]
    ),
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("app-scroll-top"),
        [
          _vm.isUnderage(_vm.owner.owner.birthday)
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "row sm-gutters" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "col" }, [
                      _c("h6", [
                        _vm._v(
                          "\n                Персональные данные\n              "
                        )
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-auto" }, [
                          _c("div", { staticClass: "person-info__item" }, [
                            _c("span", { staticClass: "small text-muted" }, [
                              _vm._v(
                                "\n                      Дата рождения\n                    "
                              )
                            ]),
                            _vm.owner.owner.birthday
                              ? _c("div", { staticClass: "font-weight-500" }, [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm._f("date")(
                                          _vm.owner.owner.birthday,
                                          "DD.MM.YYYY"
                                        )
                                      ) +
                                      "\n                    "
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _vm._m(1)
                      ])
                    ])
                  ])
                ])
              ])
            : _c("app-fiz-check-report", {
                staticClass: "mb-3",
                attrs: {
                  "only-owners": true,
                  report: _vm.owner,
                  "order-status": _vm.report.order.status,
                  "fetch-report": _vm.fetchReport,
                  "not-paid": !_vm.orderIsPaid,
                  "show-extended-report-steps": _vm.showExtendedReportSteps,
                  "extended-report-returned": _vm.extendedReportReturned,
                  "return-extended-report": _vm.returnExtendedReport,
                  "extended-report-enabled": _vm.extendedReportEnabled,
                  "is-pdf": _vm.isPdf
                }
              })
        ],
        _vm._t("extra-products")
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-auto" }, [
      _c("i", { staticClass: "fas fa-address-card text-info" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-auto" }, [
      _c("span", { staticClass: "small text-muted" }, [
        _vm._v(
          "\n                    Несовершеннолетнее физическое лицо\n                  "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }