var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-report-item",
    {
      attrs: {
        title: _vm.capitalize(_vm.result.name),
        icon: "building",
        status: "done",
        color: "warning",
        id: _vm.result.name,
        opened: !!(_vm.result.items && _vm.result.items.length)
      },
      scopedSlots: _vm._u([
        {
          key: "extra-content",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "report-item-result report-item-result__result-wrapper extended-criminal-report-collapse"
                },
                _vm._l(_vm.result.items, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "report-item-result__result" },
                    [
                      _c("app-report-collapse-item", {
                        attrs: { opened: true },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__result-elem-text-wrapper extended-criminal-report-header__title"
                                    },
                                    [
                                      item["source"]
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(item["source"]) +
                                                "\n              "
                                            )
                                          ])
                                        : _c("div", [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(item["источник"]) +
                                                "\n              "
                                            )
                                          ])
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "extended-criminal-report-header__images"
                                    },
                                    [
                                      item.indicators &&
                                      item.indicators.includes("GREEN SHIELD")
                                        ? _c("img", {
                                            attrs: { src: "/rc-modern/m.svg" }
                                          })
                                        : _vm._e(),
                                      item.indicators &&
                                      item.indicators.includes(
                                        "RED EXCLAMATION_MARK"
                                      )
                                        ? _c("i", {
                                            staticClass:
                                              "fas fa-exclamation-circle"
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            },
                            item.items && item.items.length
                              ? {
                                  key: "content",
                                  fn: function() {
                                    return [
                                      _vm._l(item.items, function(elem, i) {
                                        return _c(
                                          "div",
                                          {
                                            key: elem[0],
                                            staticClass:
                                              "report-item-result__content-section"
                                          },
                                          [
                                            elem.title
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "report-item-result__content-section-title"
                                                  },
                                                  [
                                                    _c("h4", [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(i + 1) +
                                                          ".\n                  " +
                                                          _vm._s(elem.title) +
                                                          "\n                "
                                                      )
                                                    ])
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list"
                                              },
                                              [
                                                _vm._l(item.headers, function(
                                                  head
                                                ) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: head.value,
                                                      staticClass:
                                                        "report-item-result__content-list-elem"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "report-item-result__content-list-elem-title"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                head.name
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "report-item-result__content-list-elem-result"
                                                        },
                                                        [
                                                          _c(
                                                            "app-hidden-text",
                                                            {
                                                              attrs: {
                                                                text:
                                                                  "" +
                                                                  head.value,
                                                                "max-height":
                                                                  "180px"
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                }),
                                                _vm._l(elem, function(
                                                  value,
                                                  name
                                                ) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: name,
                                                      staticClass:
                                                        "report-item-result__content-list-elem"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "report-item-result__content-list-elem-title"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(name) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "report-item-result__content-list-elem-result"
                                                        },
                                                        [
                                                          _c(
                                                            "app-hidden-text",
                                                            {
                                                              attrs: {
                                                                text:
                                                                  "" + value,
                                                                "max-height":
                                                                  "180px"
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                })
                                              ],
                                              2
                                            )
                                          ]
                                        )
                                      }),
                                      item.badges && item.badges.length
                                        ? _c(
                                            "app-extended-report-badge-collapse",
                                            { attrs: { badges: item.badges } }
                                          )
                                        : _vm._e()
                                    ]
                                  },
                                  proxy: true
                                }
                              : {
                                  key: "content",
                                  fn: function() {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-section"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__content-list"
                                            },
                                            _vm._l(item, function(value, name) {
                                              return _c(
                                                "div",
                                                {
                                                  key: name,
                                                  staticClass:
                                                    "report-item-result__content-list-elem"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "report-item-result__content-list-elem-title"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(name) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "report-item-result__content-list-elem-result"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(value) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            }),
                                            0
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.result.items && _vm.result.items.length
        ? [_vm._v("\n    Найдены данные в источнике\n  ")]
        : [_vm._v("\n    Данные не найдены\n  ")]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }