



























































import Vue from 'vue';
import Component from 'vue-class-component';
import get from 'lodash/get';

@Component({
  components: {},

  props: {
    contour: Object,
  },
})
export default class AppStatementEgrnObjectContours extends Vue {
  contour: any;
  get = get;

  get elements() {
    return get(this.contour, 'entity_spatial.spatials_elements', []);
  }
}
