var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "textContainer",
        staticClass: "text-hidden",
        style: { "max-height": _vm.showAll ? "100%" : _vm.maxHeight }
      },
      [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
    ),
    _vm.needCollapse
      ? _c("div", { staticClass: "pt-2" }, [
          _c("span", {
            staticClass: "link text-right",
            domProps: {
              innerHTML: _vm._s(_vm.showAll ? "Скрыть" : "Раскрыть")
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                _vm.showAll = !_vm.showAll
              }
            }
          })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }