
























































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import isArray from 'lodash/isArray';
import { getRegionNameById } from 'shared/utils/regions';
import AppSurveyMixin from 'shared/mixins/surveys.mixin';

@Component({
  props: {
    owner: Object,
    metadata: Array,
    inn: Object,
    orderIsDone: Boolean,
    addField: Function,
  },
  mixins: [ AppSurveyMixin ],

  // filters: {
  //   formatPassport: (value: string, isPrint: boolean): string => {
  //     if (isPrint) {
  //       value = value.slice(0, 4);
  //     }

  //     return value.replace(/(\d{2})(\d{2})(\d+)?/, '$1 $2 $3');
  //   },
  // },
})
export default class AppOwnerInfo extends Vue {
  // props
  owner: LawInformationOwner;
  metadata: any[];
  inn: any;
  orderIsDone: boolean;

  get region(): string {
    if (this.owner.region) {
      return getRegionNameById(this.owner.region);
    }

    return null;
  }

  get innManualAdded() {
    return this.inn && this.inn.task_result.inn && this.inn.task_result.text === 'Добавлен пользователем';
  }

  get isPhysical() {
    return this.owner.owner_type !== 1;
  }

  get ownerBirthdayIsEmpty(): boolean {
    if (this.owner.birthday === '0001-01-01' || this.owner.birthday === '31.12.0000') {
      return true;
    }
    return false;
  }

  get getSurvey(): any {
    if (this.metadata) {
      const res = this.metadata.find((survey: any) => survey.status === 'done');
      if (res) return res;
    }
    return {};
  }

  getQuestionText(val: number): any {
    if (this.metadata) {
      const question = this.getSurvey.survey_type.questions.find((question: any) => question.question_number === val);
      return question.text;
    } else return '-';
  }

  itsArray(val: any) {
    return isArray(val);
  }
}
