





















import Vue from 'vue';

export default Vue.extend({
  popupOptions: {
    size: 'sm',
  },
  props: {
    attachments: Array as () => AttachmentItem[],
  },
});
