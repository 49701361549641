



















































import { sortBy } from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: { },
  props: {
    fieldName: String,
    name: String,
    nameLot: String,
    data: Array,
  },
})
export default class AppFizcheckExpertSummaryResultElem extends Vue {
  // props
  fieldName: string;
  name: string;
  nameLot: string;
  data: any[];

  // data
  showAll: boolean = false;

  get dataSorted() {
    return sortBy(this.data, this.fieldName);
  }

  get dataSliced() {
    if (!this.dataSorted) {
      return [];
    }
    if (this.showAll || this.$isServer) {
      return this.dataSorted;
    }
    return this.dataSorted.slice(0, 3);
  }
}
