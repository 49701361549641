









































































































































































































































































































































































































































































































































































































































































































































































































import Component, { mixins } from 'vue-class-component';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import assign from 'lodash/assign';

import StatementEgrnMixin from 'shared/mixins/statements/statementEgrn.mixin';
import AppStatementTableRow from 'shared/components/EgrnObject/StatementTableRow.vue';
import AppEgrnObjectContours from 'shared/components/EgrnObject/EgrnObjectContours.vue';
import AppEgrnObjectBorders from 'shared/components/EgrnObject/EgrnObjectBorders.vue';
import AppEgrnObjectNestedStatements from 'shared/components/EgrnObject/EgrnObjectNestedStatements.vue';
import { OBJECT_BY_TYPE } from 'shared/utils/constants/object-types';
import AppEcpSvg from 'shared/components/Static/ecp-print.vue';

@Component({
  components: { AppStatementTableRow, AppEcpSvg, AppEgrnObjectContours, AppEgrnObjectBorders, AppEgrnObjectNestedStatements },

  props: {
    info: Object,
    statements: Object,
    riskReport: Object,
    reformaInfo: Object,
  },
})
export default class EgrnObjectReport extends mixins(StatementEgrnMixin) {
  // props
  info: any;
  statements: any;
  riskReport: any;
  reformaInfo: any;

  // data
  get = get;
  isEmpty = isEmpty;
  playgrounds: any = {
    498: 'Нет',
    499: 'Есть',
    500: 'Нет',
  };

  get objectPlan() {
    if (this.egrnTask && this.egrnTask.meta) {
      return this.egrnTask.meta.images;
    }
    return [];
  }

  get reforma() {
    const values = Object.values(this.reformaInfo);
    return values.reduce((acc, current) => {
      return assign(acc, current);
    }, {});
  }

  get egrnTask() {
    if (this.riskReport && this.riskReport.tasks_parse_xml) {
      return this.riskReport.tasks_parse_xml.find((elem: any) => {
        if (elem) {
          return elem.task_type === 'download_service_egrn_object_parse';
        }
      });
    }
    return null;
  }

  get terms() {
    const rights = get(this.currentOwners, 'rights', []);
    const arr = [];
    if (this.checkRightType(rights, 'Общая совместная собственность')) {
      arr.push({
        Header: 'Общая совместная собственность',
        Text: 'имущество, которым одновременно владеют два и более лиц, причем их доли владения в общей собственности не определены, и считаются равными. Чаще всего, это случай совместной собственности супругов, или ситуация, когда квартира приватизировалась на всех членов семьи без определения долей.',
      });
    }
    if (this.checkRightType(rights, 'Собственность')) {
      arr.push({
        Header: 'Индивидуальная собственность',
        Text: 'имущество, которое находится в собственности у единоличного лица.',
      });
    }
    if (this.checkRightType(rights, 'Общая долевая собственность')) {
      arr.push({
        Header: 'Общая долевая собственность',
        Text: 'имущество, которым одновременно владеют два и более лиц (общая собственность), при этом определена конкретная доля каждого собственника. Распоряжение, владение, пользование как самим имуществом, так и доходами от его использования, несение расходов, продажа — все это производится в соответствии с размером доли собственника и по принятому соглашению с другими участниками.',
      });
    }
    if (!get(this.statements, 'egrn.data.generic_record.cost.value', '')) {
      arr.push({
        Header: 'В отчете из ЕГРН в графе «кадастровая стоимость» указана формулировка «не определена»',
        Text: 'это не является технической ошибкой. Кадастровая стоимость в отношении вновь созданных объектов недвижимости устанавливается в течение месяца.',
      });
    }
    return arr;
  }

  checkRightType(rights: any[], text: string) {
    return !!find(rights, (elem: any) => {
      const rightType = get(elem, 'right_data.right_type.value', '');
      return rightType.includes(text);
    });
  }

  objectType(value: any) {
    return OBJECT_BY_TYPE[value];
  }
}
