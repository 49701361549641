





























































import Vue from 'vue';
import format from 'date-fns/format';

export default Vue.extend({
  props: {
    item: Object,
    date: String,
  },

  computed: {
  },
  methods: {
    getRole(item: any) {
      const arr = item.individual_data.roles.filter((role: any) => {
        return role.active === true;
      });
      if (arr.length) {
        const names = arr.map((elem: any) => elem.name);
        return names.join('/');
      } else {
        const names = item.individual_data.roles.map((elem: any) => elem.name);
        let text = ` (лицо к данной организации на ${format(this.date, 'DD.MM.YYYY')} отношения не имеет)`;
        if (item.org_data.death_date) {
          text = '';
        }
        return names.join('/') + text;
      }
    },
  },
});
