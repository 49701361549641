






























import Vue from 'vue';
import Component from 'vue-class-component';
import differenceInYears from 'date-fns/difference_in_years';

import AppCreditRatingForm from 'shared/components/CreditRating/CreditRatingForm.vue';

@Component({
  props: {
    owner: Object,
    orderItemId: String,
    onSuccess: Function,
  },
  components: { AppCreditRatingForm },
})
export default class AppEditCreditRatingDialog extends Vue {
  owner: any;
  orderItemId: string;
  onSuccess: any;

  loading: boolean = false;

  get passportDateError(): boolean {
    if (/\d{4}-\d{2}-\d{2}/.test(this.owner.passport_issue_at)) {
      return differenceInYears(this.owner.passport_issue_at, this.owner.birthday) < 14;
    }

    return null;
  }

  get isUnderage(): boolean {
    if (/\d{4}-\d{2}-\d{2}/.test(this.owner.birthday)) {
      return differenceInYears(Date.now(), this.owner.birthday) < 14;
    }

    return null;
  }

  async onSubmit() {
    try {
      const body = Object.assign({}, this.owner);

      // body.metadata = JSON.stringify(body.metadata);
      // if (body.birthday) {
      //   body.birthday = new Date(body.birthday).toISOString();
      // } else {
      //   body.birthday = '';
      // }

      // body.passport_issue_at = new Date(body.passport_issue_at).toISOString();

      body.order_item_id = this.orderItemId;
      await this.$api.risk.UpdateOwnerV2(body);
      this.onSuccess();
      this.$dialog.close();
    } catch (error) {
      console.error(error);
    }
  }
}
