export function statusText(status: string): string {
  switch (status) {
    case 'done':
      return 'Выполнено';
    case 'in progress':
      return 'Выполняется';
    case 'failed':
      return 'Ошибка';
    case 'not required':
      return 'Не обязателен';
    case 'not started':
      return 'В очереди на выполнение';
    case 'soft_failed':
      return 'Ошибка(Авторестарт)';
    default:
      return status;
  }
}

export function statusClassName(status: string): string {
  switch (status) {
    case 'done':
      return 'text-success';
    case 'in progress':
      return 'text-info';
    case 'failed':
      return 'text-danger';
    case 'not required':
      return 'text-warnig';
    default:
      return 'text-purple';
  }
}
