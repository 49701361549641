var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-item-result report-item-result__result-wrapper" },
    _vm._l(_vm.result, function(item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "report-item-result__result mosgorsud-result"
        },
        [
          _c("app-report-collapse-item", {
            attrs: { opened: _vm.opened },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      item.name_delo
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text-wrapper"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text"
                                },
                                [_vm._v("\n            Номер дела\n          ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.name_delo) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      item.date
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text-wrapper"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text"
                                },
                                [_vm._v("\n            Дата\n          ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.date) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      item.judge
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text-wrapper"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text"
                                },
                                [_vm._v("\n            Судья\n          ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.judge) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "report-item-result__content-section" },
                        [
                          _c(
                            "div",
                            { staticClass: "report-item-result__content-list" },
                            [
                              item.name_delo
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Номер дела\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          item.url
                                            ? _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "https://mos-gorsud.ru" +
                                                      item.url,
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(item.name_delo) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            : [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(item.name_delo) +
                                                    "\n                "
                                                )
                                              ]
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.category
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Категория дела\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.category) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.status
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Статус\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.status) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.date
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Дата\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.date) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.judge
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Судья\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.judge) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      ),
                      item.users && item.users.length
                        ? _c(
                            "div",
                            {
                              staticClass: "report-item-result__content-section"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-section-title"
                                },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      "\n              Информация о сторонах\n            "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-list"
                                },
                                _vm._l(item.users, function(user) {
                                  return _c(
                                    "div",
                                    {
                                      key: user.type,
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(user.type) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        _vm._l(user.people, function(p) {
                                          return _c(
                                            "p",
                                            { key: p, staticClass: "mb-0" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(p) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        : _vm._e(),
                      item.list_cases && item.list_cases.length
                        ? _c(
                            "div",
                            {
                              staticClass: "report-item-result__content-section"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-section-title"
                                },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      "\n              Документы\n            "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-list"
                                },
                                _vm._l(item.list_cases, function(document) {
                                  return _c(
                                    "div",
                                    {
                                      key: document.url,
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "https://mos-gorsud.ru" +
                                                  document.url,
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(document.name) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }