var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container container_responsive" }, [
    _c(
      "div",
      { staticClass: "page-wrapper page-wrapper_border" },
      [
        _c(
          "div",
          {
            staticClass:
              "admin-page-head d-flex justify-content-between align-items-center"
          },
          [
            _c("h5", [
              _vm._v("\n        Заказы\n        "),
              _c("i", {
                directives: [
                  {
                    name: "tippy",
                    rawName: "v-tippy",
                    value: { content: "Обновить данные" },
                    expression: "{ content: 'Обновить данные' }"
                  }
                ],
                staticClass: "fas fa-sync-alt fa-xs ml-2 text-muted",
                class: { "fa-spin": _vm.loading },
                on: { click: _vm.refresh }
              })
            ]),
            _c(
              "app-button",
              {
                attrs: { variant: "primary", size: "sm" },
                on: { mousedown: _vm.openFilter }
              },
              [
                _vm._v("\n        Фильтр "),
                _c("i", { staticClass: "fas fa-filter fa-xs" })
              ]
            )
          ],
          1
        ),
        _c("app-data-table", {
          attrs: {
            headers: _vm.headers,
            items: _vm.items,
            pagination: _vm.pagination,
            loading: _vm.loading
          },
          on: { "page-changed": _vm.pageChanged },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "td",
                    {
                      staticClass: "text-nowrap",
                      staticStyle: { "max-width": "200px" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          row.transaction_id && _vm.isAdmin
                            ? [
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      "data-clipboard-text": row.transaction_id
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "far fa-credit-card fa-fw mr-1"
                                    })
                                  ]
                                )
                              ]
                            : _vm._e(),
                          _c(
                            "router-link",
                            {
                              staticClass: "text-truncate",
                              attrs: { to: _vm.orderItemRouteConfig(row) }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(row.kadastr_number || "---") +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        2
                      )
                    ]
                  ),
                  !_vm.isPartnerRisk
                    ? _c("td", { staticClass: "text-nowrap" }, [
                        _c(
                          "div",
                          {
                            staticClass: "text-truncate",
                            staticStyle: { "max-width": "120px" }
                          },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "admin-user-orders",
                                    params: { userID: row.person_id }
                                  },
                                  target: "_blank"
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(row.email) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _c("td", [
                    _c(
                      "div",
                      {
                        staticClass: "text-truncate",
                        staticStyle: { "max-width": "120px" }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(row.product_name) +
                            "\n          "
                        )
                      ]
                    )
                  ]),
                  _c("td", [
                    _c("div", { staticStyle: { "max-width": "140px" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("date")(
                              row.created_at,
                              "DD.MM.YYYY HH:mm:ss"
                            )
                          ) +
                          "\n          "
                      )
                    ])
                  ]),
                  _c("td", [
                    row.last_updated_at
                      ? _c("div", { staticStyle: { "max-width": "140px" } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("date")(
                                  row.last_updated_at,
                                  "DD.MM.YYYY HH:mm:ss"
                                )
                              ) +
                              "\n          "
                          )
                        ])
                      : _c("div", { staticStyle: { "max-width": "100px" } }, [
                          _vm._v("\n            -\n          ")
                        ])
                  ]),
                  !_vm.isPartnerRisk
                    ? _c("td", { staticClass: "text-nowrap" }, [
                        _c(
                          "div",
                          {
                            staticClass: "text-truncate",
                            staticStyle: { "max-width": "100px" }
                          },
                          [
                            _c(
                              "a",
                              { attrs: { href: row.source, target: "_blank" } },
                              [_vm._v(_vm._s(row.sourceText))]
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  !_vm.isPartnerRisk
                    ? _c("td", { staticClass: "text-center" }, [
                        _c("div", { staticStyle: { "max-width": "50px" } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(row.price) +
                              "\n          "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("td", { staticClass: "text-right" }, [
                    _c(
                      "div",
                      {
                        staticClass: "badge",
                        class: _vm.$helper_classByStatus(row.status),
                        staticStyle: { "max-width": "110px" }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$helper_textByStatus(row.status)) +
                            "\n          "
                        )
                      ]
                    )
                  ]),
                  _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      row.rosreestr_request_id
                        ? [
                            _c("img", {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: { content: row.rosreestr_request_id },
                                  expression:
                                    "{ content: row.rosreestr_request_id }"
                                }
                              ],
                              staticStyle: { width: "20px", height: "20px" },
                              attrs: {
                                src:
                                  "https://img.icons8.com/fluent-systems-regular/48/000000/application-shield.png"
                              }
                            })
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }