var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c(
      "th",
      { staticClass: "text-center", attrs: { colspan: _vm.tableColspan } },
      [_vm._t("default", [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")])],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }