var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-item-result report-item-result__result-wrapper" },
    _vm._l(_vm.result, function(item, index) {
      return _c(
        "div",
        { key: index, staticClass: "report-item-result__result" },
        [
          _c("app-report-collapse-item", {
            attrs: { opened: _vm.opened },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "report-item-result__result-elem-image d-none d-md-block"
                        },
                        [_c("div")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "report-item-result__result-elem-text-wrapper flex-shrink-0"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text report-item-result__result-elem-text--mobile-dot"
                            },
                            [_vm._v("\n            Дата/Номер\n          ")]
                          ),
                          _c("div", [
                            _vm.has(item, "lastLegalCase.status.updateDate")
                              ? _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("date")(
                                          item.lastLegalCase.status.updateDate,
                                          "DD.MM.YYYY"
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e(),
                            _vm.has(item, "lastLegalCase.number")
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "https://kad.arbitr.ru/card?number=" +
                                        item.lastLegalCase.number,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.lastLegalCase.number) +
                                        "\n            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "report-item-result__result-elem-text-wrapper"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text"
                            },
                            [_vm._v("\n            Статус дела\n          ")]
                          ),
                          _c(
                            "div",
                            [
                              _vm.has(item, "lastLegalCase.status.code") &&
                              _vm.caseTypes[item.lastLegalCase.status.code]
                                ? [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.caseTypes[
                                            item.lastLegalCase.status.code
                                          ]
                                        ) +
                                        "\n              "
                                    ),
                                    item.lastLegalCase.status.description !==
                                    _vm.caseTypes[
                                      item.lastLegalCase.status.code
                                    ]
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                (" +
                                              _vm._s(
                                                item.lastLegalCase.status
                                                  .description
                                              ) +
                                              ")\n              "
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                : _vm.has(
                                    item,
                                    "lastLegalCase.status.description"
                                  )
                                ? [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          item.lastLegalCase.status.description
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                      ),
                      item.status
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text-wrapper"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text"
                                },
                                [
                                  _vm._v(
                                    "\n            Примененная процедура\n          "
                                  )
                                ]
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.status) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "report-item-result__content-section" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__content-section-title"
                            },
                            [
                              _c("h4", [
                                _vm._v(
                                  "\n              Производство по банкротному делу\n            "
                                )
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "report-item-result__content-list" },
                            [
                              item.guid
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Банкротное дело\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "https://fedresurs.ru/person/" +
                                                  item.guid,
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Федресурс\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.has(item, "lastLegalCase.number")
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Номер судебного дела\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "https://kad.arbitr.ru/card?number=" +
                                                  item.lastLegalCase.number,
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    item.lastLegalCase.number
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.has(item, "lastLegalCase.status.updateDate")
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Дата последних сведений\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("date")(
                                                  item.lastLegalCase.status
                                                    .updateDate,
                                                  "DD.MM.YYYY"
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.has(item, "lastLegalCase.status.code")
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Текущая процедура дела\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          item.lastLegalCase.status.code &&
                                          _vm.caseTypes[
                                            item.lastLegalCase.status.code
                                          ]
                                            ? [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.caseTypes[
                                                        item.lastLegalCase
                                                          .status.code
                                                      ]
                                                    ) +
                                                    "\n                  "
                                                ),
                                                item.lastLegalCase.status
                                                  .description !==
                                                _vm.caseTypes[
                                                  item.lastLegalCase.status.code
                                                ]
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "\n                    (" +
                                                          _vm._s(
                                                            item.lastLegalCase
                                                              .status
                                                              .description
                                                          ) +
                                                          ")\n                  "
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ]
                                            : [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      item.lastLegalCase.status
                                                        .description
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.arbitrManagerFio
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Арбитражный управляющий\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.arbitrManagerFio) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "report-item-result__content-section" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__content-section-title"
                            },
                            [
                              _c("h4", [
                                _vm._v(
                                  "\n              Информация о должнике\n            "
                                )
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "report-item-result__content-list" },
                            [
                              item.fio
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Должник\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.fio) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.has(item, "data.companyInfo.fullName")
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Полное наименование\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                item.data.companyInfo.fullName
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.has(item, "data.info.birthdateBankruptcy")
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Дата рождения\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("date")(
                                                  item.data.info
                                                    .birthdateBankruptcy,
                                                  "DD.MM.YYYY"
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.has(item, "data.info.birthplaceBankruptcy")
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Место рождения\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                item.data.info
                                                  .birthplaceBankruptcy
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.region
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Регион\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.region) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.address
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Адрес\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.address) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.has(item, "data.info.snils")
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                СНИЛС\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.data.info.snils) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.ogrn
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                ОГРН\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.ogrn) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.inn
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                ИНН\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.inn) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.has(item, "data.companyInfo.kpp")
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                КПП\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                item.data.companyInfo.kpp
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.has(item, "data.companyInfo.egrulStatus")
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Статус из ЕГРЮЛ/ЕГРИП\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                item.data.companyInfo
                                                  .egrulStatus
                                              ) +
                                              "\n                "
                                          ),
                                          item.data.companyInfo.egrulStatusDate
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                  (статус обновлен " +
                                                    _vm._s(
                                                      _vm._f("date")(
                                                        item.data.companyInfo
                                                          .egrulStatusDate,
                                                        "DD.MM.YYYY"
                                                      )
                                                    ) +
                                                    ")\n                "
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      ),
                      _vm.has(item, "data.info.ogrnipHistories")
                        ? _c(
                            "div",
                            {
                              staticClass: "report-item-result__content-section"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-section-title"
                                },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      "\n              Ранее имевшиеся ИП\n            "
                                    )
                                  ])
                                ]
                              ),
                              _vm._l(item.data.info.ogrnipHistories, function(
                                elem
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: elem.ogrnip,
                                    staticClass:
                                      "report-item-result__content-list",
                                    class: {
                                      "mb-3":
                                        item.data.info.ogrnipHistories.length >
                                        1
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                ОГРНИП\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(elem.ogrnip) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                ОКВЭД\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(elem.okved) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    elem.regDate
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-title"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Регистрация\n              "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-result"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm._f("date")(
                                                        elem.regDate,
                                                        "DD.MM.YYYY"
                                                      )
                                                    ) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    elem.statusUpdateDate
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-title"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Ликвидация\n              "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-result"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm._f("date")(
                                                        elem.regDate,
                                                        "DD.MM.YYYY"
                                                      )
                                                    ) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    elem.status
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-title"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Причина\n              "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-result"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(elem.status) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.has(item, "data.individualExecutives")
                        ? _c(
                            "div",
                            {
                              staticClass: "report-item-result__content-section"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-section-title"
                                },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      "\n              Исполнительные органы\n            "
                                    )
                                  ])
                                ]
                              ),
                              _vm._l(item.data.individualExecutives, function(
                                elem
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: elem.inn,
                                    staticClass:
                                      "report-item-result__content-list",
                                    class: {
                                      "mb-3":
                                        item.data.individualExecutives.length >
                                        1
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(elem.title) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(elem.name) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                ИНН\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(elem.inn) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.has(item, "data.propertyInfo")
                        ? _c(
                            "div",
                            {
                              staticClass: "report-item-result__content-section"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-section-title"
                                },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      "\n              Сведения об имуществе\n            "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-list"
                                },
                                [
                                  item.data.propertyInfo.authCapitalStock
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__content-list-elem-title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Уставной капитал\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__content-list-elem-result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      item.data.propertyInfo
                                                        .authCapitalStock,
                                                      { precision: 2 }
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  item.data.propertyInfo.assetsCost
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__content-list-elem-title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Стоимость чистых активов\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "report-item-result__content-list-elem-result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      item.data.propertyInfo
                                                        .assetsCost,
                                                      { precision: 2 }
                                                    )
                                                  ) +
                                                  "\n                на\n                " +
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      item.data.propertyInfo
                                                        .assetsCostDateChange,
                                                      "DD.MM.YYYY"
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.has(item, "data.legalCases")
                        ? _c(
                            "div",
                            {
                              staticClass: "report-item-result__content-section"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-section-title"
                                },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      "\n              Дела о банкротстве\n            "
                                    )
                                  ])
                                ]
                              ),
                              _vm._l(item.data.legalCases, function(elem) {
                                return _c(
                                  "div",
                                  {
                                    key: elem.number,
                                    staticClass:
                                      "report-item-result__content-list",
                                    class: {
                                      "mb-3": item.data.legalCases.length > 1
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Номер дела\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "https://kad.arbitr.ru/card?number=" +
                                                    elem.number,
                                                  target: "_blank"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(elem.number) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    elem.courtName
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-title"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Суд\n              "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-result"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(elem.courtName) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    elem.claimantType
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-title"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Заявитель\n              "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-result"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(elem.claimantType) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      item.predecessors && item.predecessors.length
                        ? _c(
                            "div",
                            {
                              staticClass: "report-item-result__content-section"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-section-title"
                                },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      "\n              Правопредшественники\n            "
                                    )
                                  ])
                                ]
                              ),
                              _vm._l(item.predecessors, function(elem) {
                                return _c(
                                  "div",
                                  {
                                    key: elem.shortName,
                                    staticClass:
                                      "report-item-result__content-list",
                                    class: {
                                      "mb-3": item.predecessors.length > 1
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Наименование\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "https://fedresurs.ru/company/" +
                                                    elem.guid,
                                                  target: "_blank"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(elem.shortName) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    elem.ogrn
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-title"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                ОГРН\n              "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-result"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(elem.ogrn) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    elem.inn
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-title"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                ИНН\n              "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-result"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(elem.inn) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    elem.eventDate
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-title"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Дата события\n              "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-result"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm._f("date")(
                                                        item.eventDate,
                                                        "DD.MM.YYYY"
                                                      )
                                                    ) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      item.successors && item.successors.length
                        ? _c(
                            "div",
                            {
                              staticClass: "report-item-result__content-section"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-section-title"
                                },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      "\n              Правопреемники\n            "
                                    )
                                  ])
                                ]
                              ),
                              _vm._l(item.successors, function(elem) {
                                return _c(
                                  "div",
                                  {
                                    key: elem.shortName,
                                    staticClass:
                                      "report-item-result__content-list",
                                    class: {
                                      "mb-3": item.successors.length > 1
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Наименование\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "https://fedresurs.ru/company/" +
                                                    elem.guid,
                                                  target: "_blank"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(elem.shortName) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    elem.ogrn
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-title"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                ОГРН\n              "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-result"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(elem.ogrn) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    elem.inn
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-title"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                ИНН\n              "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-result"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(elem.inn) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    elem.eventDate
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-title"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Дата события\n              "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-result"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm._f("date")(
                                                        item.eventDate,
                                                        "DD.MM.YYYY"
                                                      )
                                                    ) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }