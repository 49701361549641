








































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'AppAddOwnerSuccessDialog',
  popupOptions: {
    name: 'popup_default popup_success',
  },
  props: {
    addOwner: {
      type: Function,
      required: true,
    },
  },
})
export default class AppAddOwnerSuccessDialog extends Vue {
  // props
  addOwner: () => void;

  addMoreOwner() {
    this.$dialog.close();
    this.addOwner();
  }
}
