var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sud-container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showDetails,
            expression: "!showDetails"
          }
        ],
        staticClass: "sud-items"
      },
      [
        _c(
          "div",
          { staticClass: "popup__header" },
          [
            _c("div", { staticClass: "popup__title" }, [
              _vm._v("\n        Сведения о банкротстве\n      ")
            ]),
            _c("app-dialog-close")
          ],
          1
        ),
        _c("div", { staticClass: "popup__body" }, [
          _c("div", { staticClass: "sud-details bankrupt-details" }, [
            _c("div", { staticClass: "sud-preview-item__body mb-3" }, [
              _c("div", { staticClass: "sud-preview-item__name" }, [
                _c("i", { staticClass: "fas fa-briefcase" }),
                _vm.result.guid
                  ? _c(
                      "a",
                      {
                        staticClass: "font-weight-500",
                        attrs: { href: _vm.fedresursLink, target: "_blank" }
                      },
                      [_vm._v("\n              Банкротное дело\n            ")]
                    )
                  : _c("span", { staticClass: "font-weight-500" }, [
                      _vm._v("\n              Банкротное дело\n            ")
                    ])
              ])
            ]),
            _vm.result.fio
              ? _c("div", { staticClass: "sud-details-item" }, [
                  _c("div", { staticClass: "sud-details-item__title" }, [
                    _vm._v("\n            Должник\n          ")
                  ]),
                  _c("div", { staticClass: "sud-details-item__value" }, [
                    _vm._v(
                      "\n            " + _vm._s(_vm.result.fio) + "\n          "
                    )
                  ])
                ])
              : _vm._e(),
            _vm.has(_vm.result, "data.companyInfo.fullName")
              ? _c("div", { staticClass: "sud-details-item" }, [
                  _c("div", { staticClass: "sud-details-item__title" }, [
                    _vm._v("\n            Полное наименование\n          ")
                  ]),
                  _c("div", { staticClass: "sud-details-item__value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.result.data.companyInfo.fullName) +
                        "\n          "
                    )
                  ])
                ])
              : _vm._e(),
            _vm.result.address
              ? _c("div", { staticClass: "sud-details-item" }, [
                  _c("div", { staticClass: "sud-details-item__title" }, [
                    _vm._v("\n            Адрес\n          ")
                  ]),
                  _c("div", { staticClass: "sud-details-item__value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.result.address) +
                        "\n          "
                    )
                  ])
                ])
              : _vm._e(),
            _vm.has(_vm.result, "data.companyInfo.egrulStatus")
              ? _c("div", { staticClass: "sud-details-item" }, [
                  _c("div", { staticClass: "sud-details-item__title" }, [
                    _vm._v("\n            Статус из ЕГРЮЛ/ЕГРИП\n          ")
                  ]),
                  _c("div", { staticClass: "sud-details-item__value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.result.data.companyInfo.egrulStatus) +
                        "\n            "
                    ),
                    _vm.result.data.companyInfo.egrulStatusDate
                      ? _c("span", [
                          _vm._v(
                            "\n              (статус обновлен " +
                              _vm._s(
                                _vm._f("date")(
                                  _vm.result.data.companyInfo.egrulStatusDate,
                                  "DD.MM.YYYY"
                                )
                              ) +
                              ")\n            "
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              : _vm._e(),
            _vm.has(_vm.result, "data.info.birthdateBankruptcy")
              ? _c("div", { staticClass: "sud-details-item" }, [
                  _c("div", { staticClass: "sud-details-item__title" }, [
                    _vm._v("\n            Дата рождения\n          ")
                  ]),
                  _c("div", { staticClass: "sud-details-item__value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm._f("date")(
                            _vm.result.data.info.birthdateBankruptcy,
                            "DD.MM.YYYY"
                          )
                        ) +
                        "\n          "
                    )
                  ])
                ])
              : _vm._e(),
            _vm.has(_vm.result, "lastLegalCase.status.code")
              ? _c("div", { staticClass: "sud-details-item" }, [
                  _c("div", { staticClass: "sud-details-item__title" }, [
                    _vm._v("\n            Текущая процедура дела\n          ")
                  ]),
                  _c("div", { staticClass: "sud-details-item__value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.caseTypes[_vm.result.lastLegalCase.status.code]
                        ) +
                        "\n          "
                    )
                  ])
                ])
              : _vm._e(),
            _vm.has(_vm.result, "lastLegalCase.number")
              ? _c("div", { staticClass: "sud-details-item" }, [
                  _c("div", { staticClass: "sud-details-item__title" }, [
                    _vm._v("\n            Номер судебного дела\n          ")
                  ]),
                  _c("div", { staticClass: "sud-details-item__value" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://kad.arbitr.ru/card?number=" +
                            _vm.result.lastLegalCase.number,
                          target: "_blank"
                        }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.result.lastLegalCase.number) +
                            "\n            "
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e(),
            _vm.result.arbitrManagerFio
              ? _c("div", { staticClass: "sud-details-item" }, [
                  _c("div", { staticClass: "sud-details-item__title" }, [
                    _vm._v("\n            Арбитражный управляющий\n          ")
                  ]),
                  _c("div", { staticClass: "sud-details-item__value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.result.arbitrManagerFio) +
                        "\n          "
                    )
                  ])
                ])
              : _vm._e(),
            _vm.has(_vm.result, "data.info.ogrnipHistories")
              ? _c(
                  "div",
                  _vm._l(_vm.result.data.info.ogrnipHistories, function(
                    item,
                    i
                  ) {
                    return _c(
                      "div",
                      { key: item.ogrnip, staticClass: "sud-details-item" },
                      [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v(
                            "\n              Дополнительная информация\n            "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "sud-details-item__value mb-2" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(i + 1) +
                                ". Ранее имевшиеся ИП:\n            "
                            )
                          ]
                        ),
                        _c("div", { staticClass: "sud-details-item" }, [
                          _c(
                            "div",
                            { staticClass: "sud-details-item__title" },
                            [_vm._v("\n                ОГРНИП\n              ")]
                          ),
                          _c(
                            "div",
                            { staticClass: "sud-details-item__value" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.ogrnip) +
                                  "\n              "
                              )
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "sud-details-item" }, [
                          _c(
                            "div",
                            { staticClass: "sud-details-item__title" },
                            [_vm._v("\n                ОКВЭД\n              ")]
                          ),
                          _c(
                            "div",
                            { staticClass: "sud-details-item__value" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.okved) +
                                  "\n              "
                              )
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "sud-details-item" }, [
                          _c(
                            "div",
                            { staticClass: "sud-details-item__title" },
                            [
                              _vm._v(
                                "\n                Регистрация\n              "
                              )
                            ]
                          ),
                          item.regDate
                            ? _c(
                                "div",
                                { staticClass: "sud-details-item__value" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("date")(
                                          item.regDate,
                                          "DD.MM.YYYY"
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            : _c(
                                "div",
                                { staticClass: "sud-details-item__value" },
                                [
                                  _vm._v(
                                    "\n                Нет данных\n              "
                                  )
                                ]
                              )
                        ]),
                        _c("div", { staticClass: "sud-details-item" }, [
                          _c(
                            "div",
                            { staticClass: "sud-details-item__title" },
                            [
                              _vm._v(
                                "\n                Ликвидация\n              "
                              )
                            ]
                          ),
                          item.statusUpdateDate
                            ? _c(
                                "div",
                                { staticClass: "sud-details-item__value" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("date")(
                                          item.statusUpdateDate,
                                          "DD.MM.YYYY"
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            : _c(
                                "div",
                                { staticClass: "sud-details-item__value" },
                                [
                                  _vm._v(
                                    "\n                Нет данных\n              "
                                  )
                                ]
                              )
                        ]),
                        _c("div", { staticClass: "sud-details-item" }, [
                          _c(
                            "div",
                            { staticClass: "sud-details-item__title" },
                            [
                              _vm._v(
                                "\n                Причина\n              "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "sud-details-item__value" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.status) +
                                  "\n              "
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.has(_vm.result, "data.individualExecutives")
              ? _c(
                  "div",
                  _vm._l(_vm.result.data.individualExecutives, function(
                    item,
                    i
                  ) {
                    return _c(
                      "div",
                      { key: item.inn, staticClass: "sud-details-item" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "sud-details-item__value font-weight-500 mb-2"
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(i + 1) +
                                ". ИСПОЛНИТЕЛЬНЫЙ ОРГАН\n            "
                            )
                          ]
                        ),
                        _c("div", { staticClass: "sud-details-item" }, [
                          _c(
                            "div",
                            { staticClass: "sud-details-item__title" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.title) +
                                  ":\n              "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "sud-details-item__value" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.name) +
                                  "\n              "
                              )
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "sud-details-item" }, [
                          _c(
                            "div",
                            { staticClass: "sud-details-item__title" },
                            [_vm._v("\n                ИНН\n              ")]
                          ),
                          _c(
                            "div",
                            { staticClass: "sud-details-item__value" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.inn) +
                                  "\n              "
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.has(_vm.result, "data.propertyInfo")
              ? _c("div", [
                  _c("div", { staticClass: "sud-details-item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "sud-details-item__value font-weight-500 my-2"
                      },
                      [
                        _vm._v(
                          "\n              Сведения об имуществе:\n            "
                        )
                      ]
                    ),
                    _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v(
                          "\n                Уставной капитал:\n              "
                        )
                      ]),
                      _vm.result.data.propertyInfo.authCapitalStock
                        ? _c(
                            "div",
                            { staticClass: "sud-details-item__value" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.result.data.propertyInfo
                                        .authCapitalStock,
                                      { precision: 2 }
                                    )
                                  ) +
                                  "\n              "
                              )
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "sud-details-item__value" },
                            [
                              _vm._v(
                                "\n                Нет данных\n              "
                              )
                            ]
                          )
                    ]),
                    _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v(
                          "\n                Стоимость чистых активов:\n              "
                        )
                      ]),
                      _vm.result.data.propertyInfo.assetsCost
                        ? _c(
                            "div",
                            { staticClass: "sud-details-item__value" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.result.data.propertyInfo.assetsCost,
                                      { precision: 2 }
                                    )
                                  ) +
                                  "\n                на\n                " +
                                  _vm._s(
                                    _vm._f("date")(
                                      _vm.result.data.propertyInfo
                                        .assetsCostDateChange,
                                      "DD.MM.YYYY"
                                    )
                                  ) +
                                  "\n              "
                              )
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "sud-details-item__value" },
                            [
                              _vm._v(
                                "\n                Нет данных\n              "
                              )
                            ]
                          )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm.has(_vm.result, "data.legalCases")
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "sud-details-item__value font-weight-500 my-2"
                      },
                      [_vm._v("\n            Дела о банкротстве:\n          ")]
                    ),
                    _vm._l(_vm.result.data.legalCases, function(item, i) {
                      return _c(
                        "div",
                        { key: item.number, staticClass: "sud-details-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "sud-details-item__value my-2" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(i + 1) +
                                  ".\n              "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "https://kad.arbitr.ru/card?number=" +
                                      item.number,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.number) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c("div", { staticClass: "sud-details-item" }, [
                            _c(
                              "div",
                              { staticClass: "sud-details-item__title" },
                              [_vm._v("\n                Суд\n              ")]
                            ),
                            item.courtName
                              ? _c(
                                  "div",
                                  { staticClass: "sud-details-item__value" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.courtName) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "sud-details-item__value" },
                                  [
                                    _vm._v(
                                      "\n                Нет данных\n              "
                                    )
                                  ]
                                )
                          ]),
                          _c("div", { staticClass: "sud-details-item" }, [
                            _c(
                              "div",
                              { staticClass: "sud-details-item__title" },
                              [
                                _vm._v(
                                  "\n                Заявитель\n              "
                                )
                              ]
                            ),
                            item.claimantType
                              ? _c(
                                  "div",
                                  { staticClass: "sud-details-item__value" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.claimantType) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "sud-details-item__value" },
                                  [
                                    _vm._v(
                                      "\n                Нет данных\n              "
                                    )
                                  ]
                                )
                          ])
                        ]
                      )
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm.result.predecessors && _vm.result.predecessors.length
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "sud-details-item__value font-weight-500 my-2"
                      },
                      [
                        _vm._v(
                          "\n            Правопредшественники:\n          "
                        )
                      ]
                    ),
                    _vm._l(_vm.result.predecessors, function(item, i) {
                      return _c(
                        "div",
                        {
                          key: item.shortName,
                          staticClass: "sud-details-item"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "sud-details-item__value my-2" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(i + 1) +
                                  ".\n              "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "https://fedresurs.ru/company/" +
                                      item.guid,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.shortName) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c("div", { staticClass: "sud-details-item" }, [
                            _c(
                              "div",
                              { staticClass: "sud-details-item__title" },
                              [_vm._v("\n                ОГРН\n              ")]
                            ),
                            item.ogrn
                              ? _c(
                                  "div",
                                  { staticClass: "sud-details-item__value" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.ogrn) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "sud-details-item__value" },
                                  [
                                    _vm._v(
                                      "\n                Нет данных\n              "
                                    )
                                  ]
                                )
                          ]),
                          _c("div", { staticClass: "sud-details-item" }, [
                            _c(
                              "div",
                              { staticClass: "sud-details-item__title" },
                              [_vm._v("\n                ИНН\n              ")]
                            ),
                            item.inn
                              ? _c(
                                  "div",
                                  { staticClass: "sud-details-item__value" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.inn) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "sud-details-item__value" },
                                  [
                                    _vm._v(
                                      "\n                Нет данных\n              "
                                    )
                                  ]
                                )
                          ]),
                          _c("div", { staticClass: "sud-details-item" }, [
                            _c(
                              "div",
                              { staticClass: "sud-details-item__title" },
                              [
                                _vm._v(
                                  "\n                Дата события\n              "
                                )
                              ]
                            ),
                            item.eventDate
                              ? _c(
                                  "div",
                                  { staticClass: "sud-details-item__value" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm._f("date")(
                                            item.eventDate,
                                            "DD.MM.YYYY"
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "sud-details-item__value" },
                                  [
                                    _vm._v(
                                      "\n                Нет данных\n              "
                                    )
                                  ]
                                )
                          ])
                        ]
                      )
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm.result.successors && _vm.result.successors.length
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "sud-details-item__value font-weight-500 my-2"
                      },
                      [_vm._v("\n            Правопреемники:\n          ")]
                    ),
                    _vm._l(_vm.result.successors, function(item, i) {
                      return _c(
                        "div",
                        {
                          key: item.shortName,
                          staticClass: "sud-details-item"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "sud-details-item__value my-2" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(i + 1) +
                                  ".\n              "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "https://fedresurs.ru/company/" +
                                      item.guid,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.shortName) +
                                      "\n              "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c("div", { staticClass: "sud-details-item" }, [
                            _c(
                              "div",
                              { staticClass: "sud-details-item__title" },
                              [_vm._v("\n                ОГРН\n              ")]
                            ),
                            item.ogrn
                              ? _c(
                                  "div",
                                  { staticClass: "sud-details-item__value" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.ogrn) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "sud-details-item__value" },
                                  [
                                    _vm._v(
                                      "\n                Нет данных\n              "
                                    )
                                  ]
                                )
                          ]),
                          _c("div", { staticClass: "sud-details-item" }, [
                            _c(
                              "div",
                              { staticClass: "sud-details-item__title" },
                              [_vm._v("\n                ИНН\n              ")]
                            ),
                            item.inn
                              ? _c(
                                  "div",
                                  { staticClass: "sud-details-item__value" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.inn) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "sud-details-item__value" },
                                  [
                                    _vm._v(
                                      "\n                Нет данных\n              "
                                    )
                                  ]
                                )
                          ]),
                          _c("div", { staticClass: "sud-details-item" }, [
                            _c(
                              "div",
                              { staticClass: "sud-details-item__title" },
                              [
                                _vm._v(
                                  "\n                Дата события\n              "
                                )
                              ]
                            ),
                            item.eventDate
                              ? _c(
                                  "div",
                                  { staticClass: "sud-details-item__value" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm._f("date")(
                                            item.eventDate,
                                            "DD.MM.YYYY"
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "sud-details-item__value" },
                                  [
                                    _vm._v(
                                      "\n                Нет данных\n              "
                                    )
                                  ]
                                )
                          ])
                        ]
                      )
                    })
                  ],
                  2
                )
              : _vm._e()
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }