import Component, { mixins } from 'vue-class-component';
import DataTableMixin from '@/core/mixins/data-table.mixin';

@Component({
  props: {
    userID: String,
  },
})
export default class AdminUserLogs extends mixins(DataTableMixin) {
  userID!: string;
  headers: TableHeaderItem[] = [
    {
      text: 'Дата',
    },
    {
      text: 'Тип',
    },
    {
      text: 'IP',
    },
    {
      text: 'Ссылка',
    },
  ];

  handleRequest(offset: number, limit: number) {
    return this.$api.admin.GetLogs({
      offset,
      limit,
      personID: this.userID,
    });
  }
}
