var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("h5", { staticClass: "mb-4" }, [
        _vm._v("\n    Добавление собственника\n  ")
      ]),
      _c("app-dialog-close"),
      _c(
        "app-form",
        {
          attrs: {
            model: _vm.model,
            "request-handler": _vm.fetchCreateOwner,
            rules: _vm.validationConfig
          }
        },
        [
          _c(
            "div",
            { staticClass: "add-owner-check mb-2" },
            [
              _c("span", [_vm._v("Введите ФИО полностью*")]),
              !_vm.onlyPhysical
                ? _c(
                    "app-checkbox",
                    {
                      staticClass: "add-owner-checkbox",
                      attrs: { "false-value": 0, "true-value": 1 },
                      model: {
                        value: _vm.model.owner_type,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "owner_type", $$v)
                        },
                        expression: "model.owner_type"
                      }
                    },
                    [_vm._v("\n        Юридическое лицо\n      ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("app-owner-form-item-base", {
            staticClass: "mb-3",
            attrs: { owner: _vm.model, "rights-list": _vm.rightsList }
          }),
          _c("p", { staticClass: "add-owner-tip" }, [
            _c("span", { staticClass: "text-default" }, [
              _vm._v("*Обязательные поля")
            ]),
            _c("br"),
            _vm._v(
              "\n      Чем больше заполнено полей, тем выше точность и больше источников будут задействованы.\n      При выборе региона, укажите регион проживания или прописки или регистрации.\n    "
            )
          ]),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "div",
              { staticClass: "row align-items-center justify-content-right" },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-md-auto order-2 order-md-1" },
                  [
                    _c(
                      "app-button",
                      {
                        staticClass: "w-100 w-md-auto",
                        attrs: { variant: "primary-light", size: "lg" },
                        on: {
                          click: function($event) {
                            return _vm.$dialog.close()
                          }
                        }
                      },
                      [_vm._v("\n            Отменить\n          ")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-12 col-md-auto order-1 order-md-2 mb-2 mb-md-0"
                  },
                  [
                    _c(
                      "app-button",
                      {
                        staticClass: "w-100 w-md-auto",
                        attrs: {
                          variant: "primary",
                          size: "lg",
                          type: "submit",
                          loading: _vm.loading
                        }
                      },
                      [_vm._v("\n            Сохранить\n          ")]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }