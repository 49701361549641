






















import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {},
  props: {
    result: Array,
    name: String,
    opened: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
export default class AppReportCollapseItem extends Vue {
  // props
  result: any[];
  name: string;
  opened: boolean;
  disabled: boolean;
}
