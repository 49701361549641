var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-collapse-item",
    {
      staticClass: "statement-item object-page-collapse",
      class: { "is-disabled": _vm.isDisabled },
      attrs: { disabled: _vm.isDisabled, opened: _vm.isOpen },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "statement-item__head row justify-content-between"
                  },
                  [
                    _vm.title || _vm.$slots.title
                      ? _c(
                          "div",
                          { staticClass: "statement-item__title col-auto" },
                          [
                            _vm._t("title", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.title) +
                                  "\n        "
                              )
                            ])
                          ],
                          2
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "statement-item__right col-auto" },
                      [_vm._t("status")],
                      2
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _vm.$slots.default
        ? _c(
            "div",
            { staticClass: "statement-item__content" },
            [_vm._t("default")],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }