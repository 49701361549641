var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "popup__header" },
      [
        _c("div", { staticClass: "popup__title" }, [
          _vm._v("\n      Добавление прайс компонента\n    ")
        ]),
        _c("app-dialog-close")
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "popup__body" },
      [
        _c(
          "app-form",
          {
            attrs: {
              model: _vm.model,
              rules: _vm.validationRules,
              "request-handler": _vm.createUserPriceComponent
            },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function(ref) {
                  var loading = ref.loading
                  return [
                    _c(
                      "app-button",
                      {
                        attrs: { type: "submit", loading: loading, block: "" }
                      },
                      [_vm._v("\n          Добавить\n        ")]
                    )
                  ]
                }
              }
            ])
          },
          [
            _c(
              "app-form-group",
              { attrs: { label: "Название", "model-name": "name" } },
              [
                _c("app-input", {
                  attrs: { size: "sm" },
                  model: {
                    value: _vm.model.name,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "name", $$v)
                    },
                    expression: "model.name"
                  }
                })
              ],
              1
            ),
            _c(
              "app-form-group",
              {
                attrs: {
                  label: "Прайс компонент",
                  "model-name": "price_component_id"
                }
              },
              [
                _c("app-select", {
                  attrs: { items: _vm.priceComponentItems, size: "sm" },
                  model: {
                    value: _vm.model.price_component_id,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "price_component_id", $$v)
                    },
                    expression: "model.price_component_id"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c(
                  "app-form-group",
                  {
                    staticClass: "col",
                    attrs: { label: "Дата старта", "model-name": "from_date" }
                  },
                  [
                    _c("app-date-picker", {
                      attrs: { size: "sm" },
                      model: {
                        value: _vm.model.from_date,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "from_date", $$v)
                        },
                        expression: "model.from_date"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "app-form-group",
                  {
                    staticClass: "col",
                    attrs: {
                      label: "Дата окончания",
                      "model-name": "thru_date"
                    }
                  },
                  [
                    _c("app-date-picker", {
                      attrs: { size: "sm" },
                      model: {
                        value: _vm.model.thru_date,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "thru_date", $$v)
                        },
                        expression: "model.thru_date"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c(
                  "app-form-group",
                  {
                    staticClass: "col",
                    attrs: {
                      label: "Кол-во использований",
                      "model-name": "count_current"
                    }
                  },
                  [
                    _c("app-input", {
                      attrs: { type: "number", size: "sm" },
                      model: {
                        value: _vm.model.count_current,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "count_current", _vm._n($$v))
                        },
                        expression: "model.count_current"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "app-form-group",
                  {
                    staticClass: "col",
                    attrs: {
                      label: "Макс исп. (test)",
                      "model-name": "count_max"
                    }
                  },
                  [
                    _c("app-input", {
                      attrs: { type: "number", size: "sm" },
                      model: {
                        value: _vm.model.count_max,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "count_max", _vm._n($$v))
                        },
                        expression: "model.count_max"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "app-form-group",
              { attrs: { "model-name": "enabled" } },
              [
                _c(
                  "app-checkbox",
                  {
                    model: {
                      value: _vm.model.enabled,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "enabled", $$v)
                      },
                      expression: "model.enabled"
                    }
                  },
                  [_vm._v("\n          Активировать\n        ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }