var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sud-container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showDetails,
            expression: "!showDetails"
          }
        ],
        staticClass: "sud-items"
      },
      [
        _c(
          "div",
          { staticClass: "popup__header mb-2" },
          [
            _c("div", { staticClass: "popup__title" }, [
              _vm._v("\n        Информация по организациям\n      ")
            ]),
            _c("app-dialog-close")
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup__body" },
          [
            _vm.result.active.length
              ? _c("div", { staticClass: "popup__subtitle" }, [
                  _vm._v(
                    "\n        Действующий учредитель и/или руководитель\n      "
                  )
                ])
              : _vm._e(),
            _vm._l(_vm.result.active, function(item) {
              return _c(
                "div",
                {
                  key: item.org_data.name,
                  staticClass: "sud-preview-item",
                  on: {
                    click: function($event) {
                      return _vm.toggleDetails(item)
                    }
                  }
                },
                [
                  _c("app-founder-elem", {
                    attrs: { item: item, date: _vm.date }
                  }),
                  _vm._m(0, true)
                ],
                1
              )
            }),
            _vm.result.disabled.length
              ? _c("div", { staticClass: "popup__subtitle mt-3" }, [
                  _vm._v(
                    "\n        Ранее являлся учредителем или руководителем (в том числе ликвидированные):\n      "
                  )
                ])
              : _vm._e(),
            _vm._l(_vm.result.disabled, function(item) {
              return _c(
                "div",
                {
                  key: item.org_data.name,
                  staticClass: "sud-preview-item",
                  on: {
                    click: function($event) {
                      return _vm.toggleDetails(item)
                    }
                  }
                },
                [
                  _c("app-founder-elem", {
                    attrs: { item: item, date: _vm.date }
                  }),
                  _vm._m(1, true)
                ],
                1
              )
            })
          ],
          2
        )
      ]
    ),
    _vm.showDetails && _vm.details !== null
      ? _c("div", { staticClass: "sud-details" }, [
          _c(
            "div",
            {
              staticClass: "popup__header",
              on: {
                click: function($event) {
                  return _vm.toggleDetails()
                }
              }
            },
            [
              _c(
                "app-button",
                {
                  staticClass: "sud-details__back",
                  attrs: { variant: "light", size: "sm" }
                },
                [
                  _c("i", { staticClass: "fas fa-xs fa-arrow-left mr-1" }),
                  _vm._v("\n        Назад\n      ")
                ]
              )
            ],
            1
          ),
          _c("div", { staticClass: "popup__body" }, [
            _c("div", { staticClass: "sud-details__content" }, [
              _c("div", { staticClass: "sud-details__title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.details.org_data.name) +
                    "\n          "
                ),
                _vm.details.org_data.death_date
                  ? _c("i", { staticClass: "fas fa-minus-circle text-danger" })
                  : _c("i", { staticClass: "fas fa-check-circle text-success" })
              ]),
              _c("div", { staticClass: "sud-details-item" }, [
                _c("div", { staticClass: "sud-details-item__title" }, [
                  _vm._v("\n            Статус\n          ")
                ]),
                _vm.details.org_data.death_date
                  ? _c("div", { staticClass: "sud-details-item__value" }, [
                      _vm._v("\n            Ликвидирован\n          ")
                    ])
                  : _c("div", { staticClass: "sud-details-item__value" }, [
                      _vm._v("\n            Действующее\n          ")
                    ])
              ]),
              _c("div", { staticClass: "sud-details-item" }, [
                _c("div", { staticClass: "sud-details-item__title" }, [
                  _vm._v("\n            Реквизиты\n          ")
                ]),
                _c(
                  "div",
                  { staticClass: "sud-details-item__value" },
                  [
                    _vm.details.org_data.inn
                      ? _c("span", [
                          _vm._v("ИНН " + _vm._s(_vm.details.org_data.inn))
                        ])
                      : _vm._e(),
                    _vm.details.org_data.ogrn
                      ? [
                          _vm.isIP(_vm.details.org_data.name)
                            ? _c("span", [
                                _vm._v(
                                  "ОГРНИП " + _vm._s(_vm.details.org_data.ogrn)
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  "ОГРН " + _vm._s(_vm.details.org_data.ogrn)
                                )
                              ])
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _vm.details.org_data.address_obj
                ? _c("div", { staticClass: "sud-details-item" }, [
                    _c("div", { staticClass: "sud-details-item__title" }, [
                      _vm._v("\n            Адрес\n          ")
                    ]),
                    _c("div", { staticClass: "sud-details-item__value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.details.org_data.address_obj.zip_code) +
                          "\n            " +
                          _vm._s(_vm.details.org_data.address_obj.region_type) +
                          "\n            " +
                          _vm._s(_vm.details.org_data.address_obj.region_name) +
                          "\n            " +
                          _vm._s(_vm.details.org_data.address_obj.city_type) +
                          "\n            " +
                          _vm._s(_vm.details.org_data.address_obj.city_name) +
                          "\n            " +
                          _vm._s(_vm.details.org_data.address_obj.street_type) +
                          "\n            " +
                          _vm._s(_vm.details.org_data.address_obj.street_name) +
                          "\n            " +
                          _vm._s(_vm.details.org_data.address_obj.house) +
                          "\n            " +
                          _vm._s(_vm.details.org_data.address_obj.flat) +
                          "\n          "
                      )
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "sud-details-item" }, [
                _c("div", { staticClass: "sud-details-item__title" }, [
                  _vm._v("\n            Роль\n          ")
                ]),
                _c("div", { staticClass: "sud-details-item__value" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.getRole(_vm.details)) +
                      "\n          "
                  )
                ])
              ]),
              _vm.details.org_data.death_date
                ? _c("div", { staticClass: "sud-details-item" }, [
                    _c("div", { staticClass: "sud-details-item__title" }, [
                      _vm._v("\n            Дата ликвидации\n          ")
                    ]),
                    _c("div", { staticClass: "sud-details-item__value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.details.org_data.death_date) +
                          "\n          "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.details.org_data.okved && _vm.details.org_data.okved.name
                ? _c("div", { staticClass: "sud-details-item" }, [
                    _c("div", { staticClass: "sud-details-item__title" }, [
                      _vm._v(
                        "\n            Основной вид деятельности\n          "
                      )
                    ]),
                    _c("div", { staticClass: "sud-details-item__value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.details.org_data.okved.name) +
                          "\n          "
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sud-preview-item__btn" }, [
      _vm._v("\n          детали "),
      _c("i", { staticClass: "fas fa-angle-right" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sud-preview-item__btn" }, [
      _vm._v("\n          детали "),
      _c("i", { staticClass: "fas fa-angle-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }