var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-statement-item",
    {
      attrs: {
        "is-disabled": _vm.historyDealsCount === 0,
        "is-open": _vm.opened || _vm.historyDealsCount !== 0
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c("i", {
                staticClass:
                  "fas fa-file-signature fa-fw mr-1 icon text-primary"
              }),
              _vm._v(" История владения\n  ")
            ]
          },
          proxy: true
        },
        {
          key: "status",
          fn: function() {
            return [
              !_vm.status.isPurchased && _vm.showPayInfo
                ? [
                    _c("client-only", [
                      _c("div", { staticClass: "risk-report-item__status" }, [
                        _c("i", {
                          staticClass: "fas fa-lock mr-2 text-danger"
                        }),
                        _c("span", { staticClass: "d-none d-md-inline" }, [
                          _vm._v("Доступно после заказа")
                        ]),
                        _c("img", {
                          staticClass: "ml-md-4",
                          attrs: { src: "/images/eye.svg" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("icon-click")
                            }
                          }
                        })
                      ])
                    ])
                  ]
                : [
                    _vm.status.isPurchased && !_vm.status.isCompleted
                      ? [
                          _c("i", {
                            staticClass: "fas fa-info-circle text-info"
                          }),
                          _vm._v(" В работе\n      ")
                        ]
                      : !_vm.historyDealsCount
                      ? [
                          _c("i", {
                            staticClass:
                              "fas fa-exclamation-circle text-warning"
                          }),
                          _vm._v(" Отсутствует\n      ")
                        ]
                      : _vm._e()
                  ]
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.historyOwners
        ? _vm._l(_vm.historyOwners.dates, function(date) {
            return _c(
              "div",
              { key: date, staticClass: "row no-gutters statement-ownership" },
              [
                _c("div", { staticClass: "col-auto pr-2" }, [
                  _c("i", { staticClass: "fas fa-check text-success" })
                ]),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("div", { staticClass: "mb-2" }, [
                      _vm._v("\n          Дата регистрации:\n          "),
                      _c("span", { staticClass: "font-weight-500" }, [
                        _vm._v(_vm._s(date))
                      ])
                    ]),
                    _vm._l(_vm.historyOwners.items[date], function(
                      owner,
                      ownerIndex
                    ) {
                      return _c(
                        "div",
                        {
                          key: ownerIndex,
                          staticClass: "statement-ownership__owner"
                        },
                        [
                          _vm._v("\n          Собственник:\n          "),
                          _c("span", { staticClass: "font-weight-500" }, [
                            _vm._v(_vm._s(_vm._f("capitalize")(owner.content))),
                            owner.birth_date
                              ? _c("span", [
                                  _vm._v(
                                    ", дата рождения: " +
                                      _vm._s(
                                        _vm._f("date")(
                                          owner.birth_date,
                                          "DD.MM.YYYY"
                                        )
                                      )
                                  )
                                ])
                              : _vm._e()
                          ]),
                          /Физическое лицо/.test(owner.content) &&
                          !_vm.$isServer
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tippy",
                                      rawName: "v-tippy",
                                      value: {
                                        placement: "top-end",
                                        content: _vm.fizTip
                                      },
                                      expression:
                                        "{\n              placement: 'top-end',\n              content: fizTip,\n            }"
                                    }
                                  ],
                                  staticClass: "d-inline-block"
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-info-circle text-muted"
                                  })
                                ]
                              )
                            : _c("div", { staticClass: "small" }, [
                                owner.inn
                                  ? _c("div", [
                                      _vm._v(
                                        "\n              ИНН: " +
                                          _vm._s(owner.inn) +
                                          "\n            "
                                      )
                                    ])
                                  : _vm._e(),
                                owner.ogrn
                                  ? _c("div", [
                                      _vm._v(
                                        "\n              ОГРН: " +
                                          _vm._s(owner.ogrn) +
                                          "\n            "
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                          owner.registration
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "text-muted mt-1 statement-ownership__registration small"
                                },
                                [
                                  _vm.getRegistrationType(owner)
                                    ? _c("div", { staticClass: "text-muted" }, [
                                        _vm._v(
                                          "\n              Вид (доля в праве):\n              "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-500" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getRegistrationType(owner)
                                              ) + "\n                "
                                            ),
                                            owner.registration.share_description
                                              ? [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        owner.registration
                                                          .share_description
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              : owner.registration.text
                                              ? [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        owner.registration.text
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      ])
                                    : _vm._e(),
                                  owner.registration.reg_number
                                    ? _c("div", { staticClass: "text-muted" }, [
                                        _vm._v(
                                          "\n              Номер регистрации:\n              "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-500" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                owner.registration.reg_number
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  owner.registration.docs &&
                                  owner.registration.docs.length
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "text-muted" },
                                          [
                                            owner.rightList.changes
                                              ? [
                                                  _vm._v(
                                                    "\n                  Основание перехода права:\n                "
                                                  )
                                                ]
                                              : [
                                                  _vm._v(
                                                    "\n                  Основание возникновения права:\n                "
                                                  )
                                                ],
                                            _vm._l(
                                              owner.registration.docs,
                                              function(d, docIndex) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: docIndex,
                                                    staticClass:
                                                      "encumbrance-item__row-content font-weight-500"
                                                  },
                                                  [
                                                    owner.registration.docs
                                                      .length > 1
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                    -\n                  "
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    d.Content
                                                      ? [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                d.Content
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      : _vm._e(),
                                                    d.Name &&
                                                    d.Name.toLowerCase() !==
                                                      d.Content.toLowerCase()
                                                      ? [
                                                          _vm._v(
                                                            "\n                    : " +
                                                              _vm._s(d.Name) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      : _vm._e(),
                                                    d.Number
                                                      ? [
                                                          _vm._v(
                                                            "\n                    № " +
                                                              _vm._s(d.Number) +
                                                              ",\n                  "
                                                          )
                                                        ]
                                                      : _vm._e(),
                                                    d.DocumentIssuer
                                                      ? [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                d.DocumentIssuer
                                                              ) +
                                                              ",\n                  "
                                                          )
                                                        ]
                                                      : _vm._e(),
                                                    d.IssueOrgan
                                                      ? [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                d.IssueOrgan
                                                              ) +
                                                              ",\n                  "
                                                          )
                                                        ]
                                                      : _vm._e(),
                                                    d.Date
                                                      ? [
                                                          _vm._v(
                                                            "\n                    дата документа: " +
                                                              _vm._s(
                                                                _vm._f("date")(
                                                                  d.Date,
                                                                  "DD.MM.YYYY"
                                                                )
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      : _vm._e()
                                                  ],
                                                  2
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      ]
                                    : _vm._e(),
                                  owner.registration.end_date
                                    ? _c("div", { staticClass: "text-muted" }, [
                                        _vm._v(
                                          "\n              Дата прекращения права:\n              "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-500" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                owner.registration.end_date
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                2
                              )
                            : _vm._e()
                        ]
                      )
                    })
                  ],
                  2
                )
              ]
            )
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }