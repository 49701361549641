var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ul", { staticClass: "nav nav-tabs nav-tabs_sm nav-tabs_underline" }, [
        _c(
          "li",
          { staticClass: "nav-item" },
          [
            _c(
              "router-link",
              {
                staticClass: "nav-link",
                attrs: {
                  "active-class": "active",
                  exact: "",
                  to: { name: "admin-user-emails-sendpulse" }
                }
              },
              [_vm._v("\n        Sendpulse\n      ")]
            )
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "nav-item" },
          [
            _c(
              "router-link",
              {
                staticClass: "nav-link",
                attrs: {
                  "active-class": "active",
                  exact: "",
                  to: { name: "admin-user-emails-unisender" }
                }
              },
              [_vm._v("\n        Unisender\n      ")]
            )
          ],
          1
        )
      ]),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }