var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c("div", { staticClass: "p-2" }, [
        _c("div", { staticClass: "card mb-4" }, [
          _c(
            "div",
            {
              staticClass:
                "card-header d-flex justify-content-between align-items-center"
            },
            [
              _vm._m(0),
              _vm.isAdmin
                ? _c(
                    "span",
                    { staticClass: "btn-link", on: { click: _vm.showDialog } },
                    [_vm._v("Добавить продукт к заказу")]
                  )
                : _vm._e()
            ]
          ),
          _c("ul", { staticClass: "card-body list-unstyled mb-0 small py-2" }, [
            _c(
              "li",
              { staticClass: "py-1 d-flex justify-content-between" },
              [
                _c("strong", { staticClass: "mr-1" }, [
                  _vm._v("Номер заказа:")
                ]),
                _vm._v(" " + _vm._s(_vm.order.OrderID) + "\n        "),
                _c(
                  "app-button",
                  {
                    staticClass: "disableOrderProcessBtn p-0 text-right",
                    class: {
                      "disableOrderProcessBtn--red": !_vm.isOrderEnabled
                    },
                    attrs: { variant: "link" },
                    on: { click: _vm.disableOrderProcess }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.isOrderEnabled ? "ВЫКЛ" : "ВКЛ") +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            ),
            _c("li", { staticClass: "py-1" }, [
              _c("strong", [_vm._v("Источник: ")]),
              _c("a", { attrs: { href: _vm.order.source } }, [
                _vm._v(_vm._s(_vm.order.source))
              ])
            ]),
            _vm.order.Address
              ? _c("li", { staticClass: "py-1" }, [
                  _c("strong", [_vm._v("Адрес:")]),
                  _vm._v(" " + _vm._s(_vm.order.Address) + "\n      ")
                ])
              : _vm._e(),
            _vm.order.transaction_id
              ? _c("li", { staticClass: "py-1" }, [
                  _c("strong", [_vm._v("ID Транзакции:")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.order.transaction_id) +
                      " (" +
                      _vm._s(
                        _vm.getSourcePaid(_vm.order.transaction_status_id)
                      ) +
                      ")\n      "
                  )
                ])
              : _vm._e(),
            _c(
              "li",
              { staticClass: "py-1 d-flex justify-content-between" },
              [
                _c(
                  "span",
                  {
                    staticClass: "w-100",
                    class: {
                      orderTermPassed: _vm.passedMoreThanFourWeeks(
                        _vm.order.CreatedAt
                      )
                    }
                  },
                  [
                    _c("strong", [_vm._v("Дата заказа: ")]),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("date")(
                            _vm.order.CreatedAt,
                            "DD.MM.YYYY HH:mm:ss"
                          )
                        ) +
                        "\n        "
                    )
                  ]
                ),
                _c(
                  "app-button",
                  {
                    staticClass: "userEnterBtn p-0 text-right",
                    attrs: { variant: "link" },
                    on: { click: _vm.sendLinkEnterToEmail }
                  },
                  [_vm._v("\n          Войти в аккаунт\n        ")]
                )
              ],
              1
            )
          ])
        ]),
        _c(
          "table",
          { staticClass: "admin-details-table table table-lg mb-0 table-sm" },
          [
            _vm._m(1),
            _c(
              "tbody",
              [
                _vm._l(_vm.order.OrderItem, function(item, index) {
                  return [
                    _c("tr", { key: item.OrderItemID }, [
                      _c(
                        "td",
                        {
                          staticClass: "small text-truncate",
                          staticStyle: { "max-width": "40px" }
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: {
                                    content: item.OrderItemID,
                                    arrow: false,
                                    placement: "top-start"
                                  },
                                  expression:
                                    "{\n                content: item.OrderItemID,\n                arrow: false,\n                placement: 'top-start',\n              }"
                                }
                              ],
                              staticClass: "btn-link text-truncate",
                              attrs: { "data-clipboard-text": item.OrderItemID }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.OrderItemID) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "text-truncate",
                          staticStyle: {
                            "max-width": "150px",
                            overflow: "visible"
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: {
                                    content: item.ProductName,
                                    arrow: false,
                                    placement: "top-start"
                                  },
                                  expression:
                                    "{\n                content: item.ProductName,\n                arrow: false,\n                placement: 'top-start',\n              }"
                                }
                              ]
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.ProductName) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm.checkDivergence(item)
                            ? _c("i", {
                                staticClass:
                                  "fas fa-info-circle fa-fw text-danger cursor-pointer",
                                on: {
                                  click: function($event) {
                                    return _vm.showDivergenceDialog(item)
                                  }
                                }
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "btn-link cadastral-number",
                              attrs: {
                                "data-clipboard-text":
                                  item.ItemMetadata.kadastr_number
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.ItemMetadata.kadastr_number) +
                                  "\n            "
                              )
                            ]
                          ),
                          !_vm.isMetadataEmpty(item.metadata)
                            ? [
                                _c(
                                  "app-button",
                                  {
                                    staticClass: "btn-link p-0",
                                    attrs: { variant: "link" },
                                    on: {
                                      click: function($event) {
                                        return _vm.changeShowMetadata(index)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          !_vm.showMetadataArr[index]
                                            ? "Показать метаданные"
                                            : "Скрыть метаданные"
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _vm.showMetadataArr[index]
                                  ? _c(
                                      "pre",
                                      {
                                        staticStyle: {
                                          "font-size": "12px",
                                          "min-width": "350px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            JSON.stringify(
                                              item.metadata,
                                              "\t",
                                              2
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "small text-truncate",
                          staticStyle: { "max-width": "150px" }
                        },
                        [
                          _c("div", { staticClass: "text-truncate" }, [
                            item.rosreestr_request_id
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "btn-link",
                                    attrs: {
                                      "data-clipboard-text":
                                        item.rosreestr_request_id
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "text-muted" }, [
                                      _vm._v("Номер РР:")
                                    ]),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.rosreestr_request_id) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _c("span", { staticClass: "text-muted" }, [
                                  _c("span", { staticClass: "text-muted" }, [
                                    _vm._v("Номер РР:")
                                  ]),
                                  _vm._v(" Отсутствует\n              ")
                                ])
                          ]),
                          item.request_id
                            ? _c(
                                "div",
                                {
                                  staticClass: "btn-link text-truncate",
                                  attrs: {
                                    "data-clipboard-text": item.request_id
                                  }
                                },
                                [
                                  _c("span", { staticClass: "text-muted" }, [
                                    _vm._v("Api ID:")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(item.request_id) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "td",
                        [
                          _c("app-order-item-status", {
                            attrs: { "order-item": item }
                          })
                        ],
                        1
                      ),
                      _c("td", [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.Discount) +
                            "\n            "
                        ),
                        _c("i", { staticClass: "fa fa-rub" }),
                        item.CouponID !== "00000000-0000-0000-0000-000000000000"
                          ? _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "tippy",
                                    rawName: "v-tippy",
                                    value: {
                                      content: item.CouponInfo,
                                      arrow: false,
                                      placement: "top-start"
                                    },
                                    expression:
                                      "{\n                content: item.CouponInfo,\n                arrow: false,\n                placement: 'top-start',\n              }"
                                  }
                                ],
                                staticClass: "ml-1"
                              },
                              [_vm._m(2, true)]
                            )
                          : _vm._e()
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(item.Amount || item.Price) + " "),
                        _c("i", { staticClass: "fa fa-rub" })
                      ]),
                      _c("td", { staticClass: "icon-cell" }, [
                        _c(
                          "div",
                          { staticClass: "icon-cell__wrap" },
                          [
                            item.ProductName === "CounterAgent"
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tippy",
                                        rawName: "v-tippy",
                                        value: { content: "Парсеры" },
                                        expression: "{ content: 'Парсеры' }"
                                      }
                                    ],
                                    staticClass: "icon-cell__btn",
                                    on: {
                                      click: function($event) {
                                        return _vm.openStatusDialog(
                                          item.OrderItemID
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-list text-primary"
                                    })
                                  ]
                                )
                              : _vm._e(),
                            item.ProductName === "CounterAgent"
                              ? _c(
                                  "router-link",
                                  {
                                    directives: [
                                      {
                                        name: "tippy",
                                        rawName: "v-tippy",
                                        value: { content: "Перейти к отчету" },
                                        expression:
                                          "{ content: 'Перейти к отчету' }"
                                      }
                                    ],
                                    staticClass: "icon-cell__btn",
                                    attrs: {
                                      to: {
                                        name: "admin-counter-agent",
                                        params: {
                                          orderItemID: item.OrderItemID,
                                          orderId: _vm.orderID,
                                          userID: _vm.order.OwnerID
                                        }
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-file-signature"
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm.isRiskProduct(item.ProductName)
                              ? _c(
                                  "router-link",
                                  {
                                    directives: [
                                      {
                                        name: "tippy",
                                        rawName: "v-tippy",
                                        value: { content: "Перейти к отчету" },
                                        expression:
                                          "{ content: 'Перейти к отчету' }"
                                      }
                                    ],
                                    staticClass: "icon-cell__btn",
                                    attrs: {
                                      to: {
                                        name: _vm.getRiskRoute(
                                          item.ProductName
                                        ),
                                        params: {
                                          userID: _vm.order.OwnerID,
                                          orderItemID: item.OrderItemID,
                                          encumbrances: _vm.isEncumbranceInOrder
                                        }
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-file-signature"
                                    })
                                  ]
                                )
                              : _vm._e(),
                            item.ProductName === "CreditRating" ||
                            item.ProductName === "CostAssessment"
                              ? _c(
                                  "router-link",
                                  {
                                    directives: [
                                      {
                                        name: "tippy",
                                        rawName: "v-tippy",
                                        value: { content: "Перейти к отчету" },
                                        expression:
                                          "{ content: 'Перейти к отчету' }"
                                      }
                                    ],
                                    staticClass: "icon-cell__btn",
                                    attrs: {
                                      to: {
                                        name: "admin-credit-rating",
                                        params: {
                                          orderItemID: item.OrderItemID,
                                          orderId: _vm.orderID,
                                          userID: _vm.order.OwnerID
                                        }
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-file-signature"
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm.isAdmin
                              ? [
                                  _vm.editingIndex === null &&
                                  _vm.canEditKadastr(item)
                                    ? _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "tippy",
                                              rawName: "v-tippy",
                                              value: {
                                                content:
                                                  "Добавить кадастровый номер"
                                              },
                                              expression:
                                                "{ content: 'Добавить кадастровый номер' }"
                                            }
                                          ],
                                          staticClass: "icon-cell__btn",
                                          on: {
                                            click: function($event) {
                                              _vm.editingIndex = index
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-pencil-alt"
                                          })
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.editingIndex === index
                                    ? _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "tippy",
                                              rawName: "v-tippy",
                                              value: { content: "Отмена" },
                                              expression:
                                                "{ content: 'Отмена' }"
                                            }
                                          ],
                                          staticClass: "icon-cell__btn",
                                          on: {
                                            click: function($event) {
                                              _vm.editingIndex = null
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-times"
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tippy",
                                    rawName: "v-tippy",
                                    value: _vm.tippyDropdownOptions,
                                    expression: "tippyDropdownOptions"
                                  }
                                ],
                                staticClass: "icon-cell__btn"
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-ellipsis-h text-primary"
                                }),
                                _c(
                                  "div",
                                  { attrs: { "data-tippy-content": "hidden" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "menu" },
                                      [
                                        _vm.isFastProduct(item.ProductName)
                                          ? [
                                              _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "menu__header pb-1"
                                                },
                                                [_vm._v("Действия spv.kadastr")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "menu__item",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.spvKadastrActions(
                                                        "order",
                                                        item.status,
                                                        item.OrderItemID
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Перезаказать\n                      "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "menu__item",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.spvKadastrActions(
                                                        "download",
                                                        item.status,
                                                        item.OrderItemID
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Перезапустить скачку\n                      "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "menu__header pb-1"
                                                },
                                                [_vm._v("Другое")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "menu__item text-danger",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.updateOrderItemStatus(
                                                        item,
                                                        "delete"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Удалить из РР\n                      "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "menu__item",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.downloadOrder(
                                                        item.OrderItemID,
                                                        _vm.getProductName(
                                                          item.ProductName
                                                        ),
                                                        "pdf"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Скачать PDF\n                      "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "menu__item",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.downloadOrder(
                                                        item.OrderItemID,
                                                        _vm.getProductName(
                                                          item.ProductName
                                                        ),
                                                        "signed_zip"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Скачать архив RC ЭЦП\n                      "
                                                  )
                                                ]
                                              ),
                                              _c("AppOrderRefund", {
                                                attrs: {
                                                  "order-item-id":
                                                    item.OrderItemID
                                                }
                                              }),
                                              _c("AppSendEmailWithAttachment", {
                                                attrs: {
                                                  "order-item-id":
                                                    item.OrderItemID
                                                }
                                              })
                                            ]
                                          : [
                                              _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "menu__header pb-1"
                                                },
                                                [_vm._v("Действия для Каднета")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "menu__item",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.updateKadnetStatus(
                                                        item,
                                                        4
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Перезаказать в РР\n                      "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "menu__item",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.updateKadnetStatus(
                                                        item,
                                                        16
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Скачать из РР\n                      "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "menu__item text-danger",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.updateOrderItemStatus(
                                                        item,
                                                        "delete"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Удалить из РР\n                      "
                                                  )
                                                ]
                                              ),
                                              _c("AppOrderRefund", {
                                                attrs: {
                                                  "order-item-id":
                                                    item.OrderItemID
                                                }
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "menu__item",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.downloadOrder(
                                                        item.OrderItemID,
                                                        _vm.getProductName(
                                                          item.ProductName
                                                        ),
                                                        "pdf"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Скачать PDF\n                      "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "menu__item",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.downloadOrder(
                                                        item.OrderItemID,
                                                        _vm.getProductName(
                                                          item.ProductName
                                                        ),
                                                        "signed_zip"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Скачать архив RC ЭЦП\n                      "
                                                  )
                                                ]
                                              ),
                                              _c("AppSendEmailWithAttachment", {
                                                attrs: {
                                                  "order-item-id":
                                                    item.OrderItemID
                                                }
                                              })
                                            ],
                                        _vm.isEgrnObjectOrEgrnRightList(
                                          item.ProductName
                                        )
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "menu__item",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.downloadOrder(
                                                        item.OrderItemID,
                                                        _vm.getProductName(
                                                          item.ProductName
                                                        ),
                                                        "zip"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Скачать zip\n                      "
                                                  )
                                                ]
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          2
                        )
                      ])
                    ]),
                    _vm.editingIndex === index
                      ? _c(
                          "tr",
                          {
                            key: item.OrderItemID + "-" + index,
                            staticClass: "editable-row"
                          },
                          [
                            _c(
                              "td",
                              {
                                staticClass: "pt-0 border-0",
                                attrs: { colspan: "6" }
                              },
                              [
                                _c("app-update-kad-number", {
                                  attrs: {
                                    "order-item-id": item.OrderItemID,
                                    index: index
                                  },
                                  on: { updated: _vm.kadNumberUpdated }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                })
              ],
              2
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c("span", [_vm._v("Информация")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("ID")]),
        _c("th", [_vm._v("Название")]),
        _c("th"),
        _c("th", [_vm._v("Статус")]),
        _c("th", [_vm._v("Скидка")]),
        _c("th", [_vm._v("Цена")]),
        _c("th", { staticClass: "icon-cell" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "/admin/coupons" } }, [
      _c("i", { staticClass: "fas fa-tag" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }