


























































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import get from 'lodash/get';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import AppSocialMediaReportElem from 'shared/components/FizCheck/SocialMediaReportElem.vue';

@Component({
  components: { AppReportItem, AppSocialMediaReportElem },
  props: {
    report: Object,
    status: String,
    images: Array,
    orderIsDone: Boolean,
    missingRequirements: Array,
    openAddFieldDialog: Function,
    updateMap: Function,
    task: Object,
    isPhysical: Boolean,
    onlyOwners: Boolean,
    isPdf: Boolean,
    map: Object,
  },
})
export default class AppSocialMediaReport extends Vue {
  // props
  missingRequirements: any[];
  report: any;
  status: string;
  images: any[];
  orderIsDone: boolean;
  task: any;
  isPhysical: boolean;
  onlyOwners: boolean;
  isPdf: boolean;
  openAddFieldDialog: (val: string) => any;
  updateMap: (taskName: string, field: string, value: any) => void;
  map: any;
  resultStatusTrace: any = null;

  created() {
    this.resultStatusTrace = this.resultStatus;
  }

  get statusDone() {
    return this.status === 'done';
  }

  get resultStatus() {
    if (this.task && this.statusDone) {
      if (this.groupedSources.OK.length ||
      this.groupedSources.VK.length ||
      this.groupedSources.WhatsApp.length) {
        this.updateColor('warning');
        return 'found';
      } else {
        this.updateColor('success');
        return 'not found';
      }
    }
    this.updateColor('info');
    return 'no data';
  }

  get havePhone() {
    return !!this.report.owner.phone;
  }

  get sources() {
    return get(this.task, 'task_result.result.sources');
  }

  get groupedSources() {
    const result: any = {
      OK: [],
      VK: [],
      WhatsApp: [],
    };
    if (this.sources) {
      this.sources.forEach((elem: any) => {
        if (elem.record) {
          result[elem.name].push(elem);
        }
      });
    }
    return result;
  }

  updateColor(color: string) {
    this.updateMap('socialMedia', 'color', color);
  }
}
