var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "owner" },
    [
      _c(
        "app-report-item",
        {
          attrs: {
            title: _vm.isPhysical
              ? "Персональные данные"
              : "Данные юридического лица",
            icon: "address-card",
            status: "done"
          },
          scopedSlots: _vm._u(
            [
              !_vm.$isServer
                ? {
                    key: "status",
                    fn: function() {
                      return [
                        !_vm.opened && !_vm.onlyOwners && !_vm.isDemo
                          ? _c("div", { staticClass: "row no-gutters py-1" }, [
                              _vm.report.surveys
                                ? _c("div", { staticClass: "col-6" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "link small",
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit(
                                              "open-survey-dialog",
                                              _vm.report.surveys
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm.newSurveys && _vm.newSurveys.length
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge badge-pill badge-success"
                                              },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      _vm.newSurveys.length
                                                    ) +
                                                    "\n            "
                                                )
                                              ]
                                            )
                                          : _c("i", {
                                              staticClass: "fas fa-user fa-sm"
                                            }),
                                        _vm._v(
                                          "\n\n            Добавить доп. сведения\n          "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "col-6" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "link small",
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit("edit", _vm.report)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-pencil-alt fa-sm"
                                    }),
                                    (_vm.report.owner &&
                                      _vm.report.owner.registration_number) ||
                                    !_vm.isPhysical
                                      ? [
                                          _vm._v(
                                            "\n              Редактировать\n            "
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            "\n              Указать право собственности\n            "
                                          )
                                        ]
                                  ],
                                  2
                                )
                              ])
                            ])
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _c("app-owner-info", {
            attrs: {
              owner: _vm.report.owner,
              metadata: _vm.report.surveys,
              inn: _vm.getTask("owner_check_individual_inn"),
              "order-is-done": _vm.orderIsDone,
              "add-field": _vm.openAddFieldDialog
            }
          })
        ],
        1
      ),
      _vm.onlyOwners && !_vm.$isServer
        ? _c("div", { staticClass: "border-bottom-gray mt-3 mb-4" })
        : _vm._e(),
      _vm.isPhysical &&
      (_vm.fioValidCheck ||
        ["valid", "invalid"].includes(_vm.gosuslugiPassport))
        ? _c("div", { staticClass: "row no-gutters mb-3" }, [
            _vm._m(0),
            _c("div", { staticClass: "col" }, [
              _vm._v("\n      Соответствие ФИО"),
              _vm.fioValidCheck
                ? _c("span", [_vm._v(", даты рождения")])
                : _vm._e(),
              _vm._v("\n      и номера паспорта\n      "),
              _vm._m(1)
            ])
          ])
        : _vm._e(),
      _vm.isPhysical
        ? _c(
            "app-report-item",
            {
              attrs: {
                title: "Действительность паспорта",
                icon: "passport",
                status: _vm.getTaskStatus(_vm.passportTask),
                images: _vm.get(_vm.tasks.passport, "task_result.images"),
                source: {
                  domain: "services.fms.gov.ru",
                  link: "http://services.fms.gov.ru/info-service.htm?sid=2000"
                },
                "check-passport": "",
                "missing-requirements": _vm.getMissingRequirements(
                  _vm.tasks.passport
                )
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "not-required",
                    fn: function() {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "link font-weight-500 d-print-none",
                            on: {
                              click: function($event) {
                                return _vm.openAddFieldDialog("passport")
                              }
                            }
                          },
                          [_vm._v("\n        Добавить\n      ")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1804220851
              )
            },
            [
              _vm.gosuslugiPassport !== "error"
                ? [
                    _vm.report.owner.passport
                      ? _c("span", [
                          _vm._v(
                            "\n        В базе недействительных паспортов МВД:\n      "
                          )
                        ])
                      : _vm._e(),
                    _vm.gosuslugiPassport === "valid"
                      ? [
                          _c("span", { staticClass: "text-success" }, [
                            _c("i", {
                              staticClass:
                                "fas fa-check-circle fa-sm fa-fw mr-1"
                            }),
                            _c("b", [_vm._v("не числится")])
                          ]),
                          _c("span", {}, [
                            _vm._v(
                              "\n          на " +
                                _vm._s(
                                  _vm._f("date")(
                                    _vm.passportTask.in_progress_started_time,
                                    "DD.MM.YYYY"
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                        ]
                      : _vm.gosuslugiPassport === "invalid"
                      ? [
                          _vm.report.owner.passport
                            ? _c("span", { staticClass: "text-danger" }, [
                                _c("i", {
                                  staticClass:
                                    "fas fa-exclamation-circle fa-sm fa-fw mr-1"
                                }),
                                _c("b", [_vm._v("числится")])
                              ])
                            : _vm._e(),
                          _c("span", {}, [
                            _vm._v(
                              "\n          на " +
                                _vm._s(
                                  _vm._f("date")(
                                    _vm.passportTask.in_progress_started_time,
                                    "DD.MM.YYYY"
                                  )
                                ) +
                                "\n        "
                            )
                          ])
                        ]
                      : _vm.gosuslugiPassport === "notFound"
                      ? [
                          _c("div", { staticClass: "text-warning" }, [
                            _vm._v(
                              "\n          Данные не найдены. Вероятно наличие ошибки в введенных данных (в ФИО или серии и номере паспорта)\n        "
                            )
                          ])
                        ]
                      : _vm._e()
                  ]
                : _vm.tasks.passport
                ? [
                    _vm.report.owner.passport
                      ? _c("span", [
                          _vm._v(
                            "\n        В базе недействительных паспортов МВД:\n      "
                          )
                        ])
                      : _vm._e(),
                    _vm.tasks.passport.task_result.result === "valid"
                      ? [
                          _c("span", { staticClass: "text-success" }, [
                            _c("i", {
                              staticClass:
                                "fas fa-check-circle fa-sm fa-fw mr-1"
                            }),
                            _c("b", [_vm._v("не числится")])
                          ]),
                          _c("span", {}, [
                            _vm._v("\n          на 20.07.2023\n        ")
                          ])
                        ]
                      : [
                          _vm.report.owner.passport
                            ? _c("span", { staticClass: "text-danger" }, [
                                _c("i", {
                                  staticClass:
                                    "fas fa-exclamation-circle fa-sm fa-fw mr-1"
                                }),
                                _c("b", [_vm._v("числится")])
                              ])
                            : _vm._e(),
                          _c("span", {}, [
                            _vm._v("\n          на 20.07.2023\n        ")
                          ]),
                          _vm.tasks.passport.task_result.text
                            ? _c("div", { staticClass: "text-warning" }, [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.tasks.passport.task_result.text
                                    ) +
                                    "\n        "
                                )
                              ])
                            : _vm._e()
                        ],
                    _c("p", { staticClass: "small text-muted mb-0" }, [
                      _vm._v(
                        "\n        После получения информации от источника, данные будут обновлены на текущую дату запроса.\n        "
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tippy",
                              rawName: "v-tippy",
                              value: {
                                placement: "top-end",
                                interactive: true
                              },
                              expression:
                                "{ placement: 'top-end', interactive: true }"
                            }
                          ],
                          staticClass: "link small ml-2"
                        },
                        [
                          _vm._v("\n          Подробнее\n\n          "),
                          _c(
                            "div",
                            { attrs: { "data-tippy-content": "hide" } },
                            [
                              _vm._v(
                                "\n            Напоминаем, МВД приостановил онлайн проверку действительных паспортов в связи с путаницей в базе данных. В связи с чем, сервис использует в работе последнюю доступную версию базы МВД. Для проверки актуальной информации мы используем Госуслуги, который на текущий момент не доступен - ожидайте уведомления.\n          "
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ]
                : _vm.orderIsDone
                ? [
                    _c("span", { staticClass: "text-warning" }, [
                      _vm._v(" Недостаточно данных ")
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.isPhysical
        ? _c(
            "app-report-item",
            {
              attrs: {
                title: "Нахождение в розыске",
                icon: "fingerprint",
                status: _vm.getTaskStatus(_vm.tasks.criminal),
                "missing-requirements": _vm.getMissingRequirements(
                  _vm.tasks.criminal
                ),
                images: _vm.get(_vm.tasks.criminal, "task_result.images"),
                source: {
                  domain: "мвд.рф",
                  link: "https://xn--b1aew.xn--p1ai/wanted"
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "not-required",
                    fn: function() {
                      return [
                        _c(
                          "span",
                          {
                            staticClass:
                              "link font-weight-500 ml-2 d-print-none",
                            on: {
                              click: function($event) {
                                return _vm.openAddFieldDialog("birthday")
                              }
                            }
                          },
                          [_vm._v("\n        Добавить\n      ")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1406919612
              )
            },
            [
              _vm.tasks.criminal
                ? [
                    _c("app-criminal-report", {
                      attrs: { result: _vm.tasks.criminal.task_result }
                    })
                  ]
                : _vm.orderIsDone
                ? [
                    _c("span", { staticClass: "text-warning" }, [
                      _vm._v(" Недостаточно данных ")
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.isPhysical && _vm.onlyOwners
        ? _c(
            "app-report-item",
            {
              attrs: {
                title: "Розыск УФСИН",
                icon: "binoculars",
                status: _vm.getTaskStatus(_vm.tasks.fsin),
                "missing-requirements": _vm.getMissingRequirements(
                  _vm.tasks.fsin
                ),
                images: _vm.get(_vm.tasks.fsin, "task_result.images"),
                source: {
                  domain: "fsin.gov.ru",
                  link: "https://fsin.gov.ru/criminal/"
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "not-required",
                    fn: function() {
                      return [
                        _c(
                          "span",
                          {
                            staticClass:
                              "link font-weight-500 ml-2 d-print-none",
                            on: {
                              click: function($event) {
                                return _vm.openAddFieldDialog("birthday")
                              }
                            }
                          },
                          [_vm._v("\n        Добавить\n      ")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1406919612
              )
            },
            [
              _vm.tasks.fsin
                ? [
                    _c("app-fsin-report", {
                      attrs: { result: _vm.tasks.fsin.task_result }
                    })
                  ]
                : _vm.orderIsDone
                ? [
                    _c("span", { staticClass: "text-warning" }, [
                      _vm._v(" Недостаточно данных ")
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.isPhysical && _vm.onlyOwners
        ? _c(
            "app-report-item",
            {
              attrs: {
                title: "Розыск Интерпол",
                icon: "globe-europe",
                status: _vm.getTaskStatus(_vm.tasks.interpol),
                "missing-requirements": _vm.getMissingRequirements(
                  _vm.tasks.interpol
                ),
                images: _vm.get(_vm.tasks.interpol, "task_result.images"),
                source: {
                  domain: "interpol.int",
                  link: "https://www.interpol.int/"
                }
              }
            },
            [
              _vm.tasks.interpol
                ? [
                    _c("app-interpol-report", {
                      attrs: { result: _vm.tasks.interpol.task_result }
                    })
                  ]
                : _vm.orderIsDone
                ? [
                    _c("span", { staticClass: "text-warning" }, [
                      _vm._v(" Недостаточно данных ")
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _c(
        "app-report-item",
        {
          attrs: {
            title: "Сведения о долгах у судебных приставов",
            icon: "file-invoice",
            status: _vm.getTaskStatus(_vm.tasks.bailiffDebt),
            "missing-requirements": _vm.getMissingRequirements(
              _vm.tasks.bailiffDebt
            ),
            images: _vm.get(_vm.tasks.bailiffDebt, "task_result.images"),
            source: {
              domain: "fssprus.ru",
              link: "http://fssprus.ru/iss/ip"
            }
          },
          scopedSlots: _vm._u(
            [
              _vm.tasks.bailiffDebt && _vm.tasks.bailiffDebt.task_result.debts
                ? {
                    key: "status",
                    fn: function() {
                      return [
                        _c("span", { staticClass: "text-danger" }, [
                          _c("i", { staticClass: "fas fa-exclamation-circle" }),
                          _vm._v(
                            " Найдено записей об ИП:\n        " +
                              _vm._s(
                                _vm.tasks.bailiffDebt.task_result.debts.length
                              ) +
                              "\n        "
                          ),
                          _vm.tasks.bailiffDebt.task_result.money
                            ? _c("span", [
                                _vm._v(
                                  "\n          на сумму " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.tasks.bailiffDebt.task_result.money
                                      )
                                    ) +
                                    "\n        "
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _vm.tasks.bailiffDebt
            ? _c("app-dept-report", {
                attrs: { result: _vm.tasks.bailiffDebt.task_result.debts }
              })
            : _vm.orderIsDone
            ? [
                _c("span", { staticClass: "text-warning" }, [
                  _vm._v(" Недостаточно данных ")
                ])
              ]
            : _vm._e()
        ],
        2
      ),
      _vm.notPaid
        ? _c("div", { staticClass: "report-not-paid" }, [
            _c("div", {
              staticClass: "report-not-paid__inner-white",
              attrs: { id: "fizcheck-buy" }
            }),
            _c("div", { staticClass: "report-not-paid__inner-red" }, [
              _c(
                "div",
                {
                  staticClass:
                    "row sm-gutters justify-content-between align-items-center"
                },
                [
                  _vm._m(2),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-auto" },
                    [_vm._t("buy-report-btn")],
                    2
                  )
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm.isPhysical
        ? _c(
            "app-report-item",
            {
              attrs: {
                title: "Налоговые задолженности",
                icon: "coins",
                status: _vm.getTaskStatus(_vm.taxReportTask),
                images: _vm.get(_vm.tasks.individualDebt, "task_result.images"),
                "missing-requirements": _vm.getMissingRequirements(
                  _vm.tasks.individualDebt
                ),
                source: {
                  domain: "nalogi.online",
                  link: "https://nalogi.online/"
                }
              },
              scopedSlots: _vm._u(
                [
                  _vm.taxReportResult && _vm.taxReportResult.length
                    ? {
                        key: "status",
                        fn: function() {
                          return [
                            _c("span", { staticClass: "text-danger" }, [
                              _c("i", {
                                staticClass: "fas fa-exclamation-circle"
                              }),
                              _vm._v(
                                " найдено задолженностей:\n        " +
                                  _vm._s(_vm.taxReportResult.length) +
                                  " на\n        " +
                                  _vm._s(_vm.penaltySum) +
                                  " ₽\n      "
                              )
                            ])
                          ]
                        },
                        proxy: true
                      }
                    : null,
                  {
                    key: "not-required",
                    fn: function() {
                      return [
                        !_vm.inn
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "link font-weight-500 ml-2 d-print-none",
                                on: {
                                  click: function($event) {
                                    return _vm.openAddFieldDialog("inn")
                                  }
                                }
                              },
                              [_vm._v("\n        Добавить\n      ")]
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            },
            [
              _vm.taxReportResult
                ? _c("app-tax-report", {
                    attrs: { result: _vm.taxReportResult }
                  })
                : _vm.orderIsDone
                ? [
                    _c("span", { staticClass: "text-warning" }, [
                      _vm._v(" Недостаточно данных ")
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _c(
        "app-report-item",
        {
          attrs: {
            title: "Федеральный реестр сведений о банкротстве",
            icon: "university",
            status: _vm.getTaskStatus(_vm.tasks.bankrupt),
            images: _vm.get(_vm.tasks.bankrupt, "task_result.images"),
            "missing-requirements": _vm.getMissingRequirements(
              _vm.tasks.bankrupt
            ),
            source: {
              domain: "bankrot.fedresurs.ru",
              link: "https://bankrot.fedresurs.ru/?attempt=1"
            }
          }
        },
        [
          _vm._v("\n    В реестре Банкротов:\n    "),
          _vm.tasks.bankrupt &&
          (_vm.tasks.bankrupt.task_result.fiz ||
            _vm.tasks.bankrupt.task_result.ur)
            ? [
                !_vm.checkBankrupt
                  ? [
                      _c("span", { staticClass: "text-success" }, [
                        _c("i", {
                          staticClass: "fas fa-check-circle fa-sm fa-fw mr-1"
                        }),
                        _c("b", [_vm._v("отсутствует")])
                      ])
                    ]
                  : [
                      _c("span", { staticClass: "text-danger" }, [
                        _c("i", {
                          staticClass:
                            "fas fa-exclamation-circle fa-sm fa-fw mr-1"
                        }),
                        _c("b", [_vm._v("числится")]),
                        _vm.checkBankrupt.length > 1
                          ? _c("span", [
                              _vm._v(
                                "\n            (найдено несколько совпадений по ФИО)\n          "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm.checkBankrupt.length
                        ? _c("app-bankrupt-report", {
                            attrs: {
                              result: _vm.checkBankrupt,
                              "is-physical": _vm.isPhysical
                            }
                          })
                        : _vm._e()
                    ]
              ]
            : _vm.orderIsDone
            ? [
                _c("span", { staticClass: "text-warning" }, [
                  _vm._v(" Недостаточно данных ")
                ])
              ]
            : _vm._e()
        ],
        2
      ),
      _vm.isPhysical
        ? _c(
            "app-report-item",
            {
              attrs: {
                title: "Самозанятый",
                icon: "briefcase",
                status: _vm.getTaskStatus(_vm.tasks.selfEmployed),
                images: _vm.get(_vm.tasks.selfEmployed, "task_result.images"),
                "missing-requirements": _vm.getMissingRequirements(
                  _vm.tasks.selfEmployed
                ),
                source: {
                  domain: "nalog.ru",
                  link: "https://npd.nalog.ru/check-status/"
                }
              }
            },
            [
              _vm.tasks.selfEmployed
                ? [
                    _vm._v("\n      В реестре самозанятых\n      "),
                    _vm.tasks.selfEmployed.task_result.result === "true"
                      ? _c("span", { staticClass: "text-warning" }, [
                          _c("i", {
                            staticClass:
                              "fas fa-exclamation-circle fa-sm fa-fw mr-1"
                          }),
                          _c("b", [_vm._v("найден")])
                        ])
                      : _c("span", { staticClass: "text-success" }, [
                          _c("i", {
                            staticClass: "fas fa-check-circle fa-sm fa-fw mr-1"
                          }),
                          _c("b", [_vm._v("не найден")])
                        ])
                  ]
                : _vm.orderIsDone
                ? [
                    _c("span", { staticClass: "text-warning" }, [
                      _vm._v(" Недостаточно данных ")
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.isPhysical
        ? _c(
            "app-report-item",
            {
              attrs: {
                title: "Руководитель или учредитель юридического лица",
                icon: "user-tie",
                images: _vm.get(_vm.tasks.ceo, "task_result.images"),
                status: _vm.getTaskStatus(_vm.tasks.ceo),
                "missing-requirements": _vm.getMissingRequirements(
                  _vm.tasks.ceo
                )
              },
              scopedSlots: _vm._u(
                [
                  _vm.tasks.ceo &&
                  _vm.tasks.ceo.task_result.result &&
                  _vm.tasks.ceo.task_result.result.length
                    ? {
                        key: "status",
                        fn: function() {
                          return [
                            _c("span", { staticClass: "text-warning" }, [
                              _c("i", {
                                staticClass: "fas fa-exclamation-circle"
                              }),
                              _vm._v(
                                " найдено:\n        " +
                                  _vm._s(
                                    _vm.tasks.ceo.task_result.result.length
                                  ) +
                                  "\n      "
                              )
                            ])
                          ]
                        },
                        proxy: true
                      }
                    : null
                ],
                null,
                true
              )
            },
            [
              _vm.tasks.ceo
                ? _c("app-founder-report", {
                    attrs: {
                      result: _vm.tasks.ceo.task_result,
                      date:
                        _vm.tasks.ceo.in_progress_started_time ||
                        _vm.tasks.ceo.started_at
                    }
                  })
                : _vm.orderIsDone
                ? [
                    _c("span", { staticClass: "text-warning" }, [
                      _vm._v(" Недостаточно данных ")
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.isPhysical && _vm.onlyOwners
        ? _c(
            "app-report-item",
            {
              attrs: {
                title: "Реестр дисквалифицированных лиц",
                icon: "coins",
                status: _vm.getTaskStatus(_vm.tasks.disqualified),
                images: _vm.get(_vm.tasks.disqualified, "task_result.images"),
                "missing-requirements": _vm.getMissingRequirements(
                  _vm.tasks.disqualified
                ),
                source: {
                  domain: "service.nalog.ru",
                  link:
                    "https://service.nalog.ru/disqualified.html#t=1667220507115"
                }
              }
            },
            [
              _vm.tasks.disqualified
                ? [
                    _vm._v("\n      В реестре ФНС:\n      "),
                    _vm.isEmpty(_vm.tasks.disqualified.task_result.result)
                      ? _c("span", { staticClass: "text-success" }, [
                          _c("i", {
                            staticClass: "fas fa-check-circle fa-sm fa-fw mr-1"
                          }),
                          _c("b", [_vm._v("не найден")])
                        ])
                      : _c("span", { staticClass: "text-danger" }, [
                          _c("i", {
                            staticClass:
                              "fas fa-exclamation-circle fa-sm fa-fw mr-1"
                          }),
                          _c("b", [_vm._v("числится")])
                        ]),
                    _c("app-disqualified-report", {
                      attrs: {
                        result: _vm.tasks.disqualified.task_result.result
                      }
                    })
                  ]
                : _vm.orderIsDone
                ? [
                    _c("span", { staticClass: "text-warning" }, [
                      _vm._v(" Недостаточно данных ")
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.isPhysical
        ? _c(
            "app-report-item",
            {
              attrs: {
                title: "Росфинмониторинг",
                icon: "exclamation-triangle",
                status: _vm.getTaskStatus(_vm.tasks.terrorism),
                images: _vm.get(_vm.tasks.terrorism, "task_result.images"),
                "missing-requirements": _vm.getMissingRequirements(
                  _vm.tasks.terrorism
                ),
                source: {
                  domain: "fedsfm.ru",
                  link: "http://www.fedsfm.ru/"
                }
              }
            },
            [
              _vm.tasks.terrorism &&
              _vm.tasks.terrorism.task_result.result !== "not required"
                ? [
                    _vm._v(
                      "\n      В перечне о причастности к экстремистской деятельности или терроризму\n      "
                    ),
                    ["not wanted", null].includes(
                      _vm.tasks.terrorism.task_result.result
                    )
                      ? _c("span", { staticClass: "text-success" }, [
                          _c("i", {
                            staticClass: "fas fa-check-circle fa-sm fa-fw mr-1"
                          }),
                          _c("b", [_vm._v("не найден")])
                        ])
                      : _c("span", { staticClass: "text-danger" }, [
                          _c("i", {
                            staticClass:
                              "fas fa-exclamation-circle fa-sm fa-fw mr-1"
                          }),
                          _c("b", [_vm._v("найден")])
                        ])
                  ]
                : _vm.orderIsDone
                ? [
                    _c("span", { staticClass: "text-warning" }, [
                      _vm._v(" Недостаточно данных ")
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.isPhysical && _vm.tasks.foreignAgent
        ? _c(
            "app-report-item",
            {
              attrs: {
                title: "Реестр иностранных агентов",
                icon: "user-secret",
                status: _vm.getTaskStatus(_vm.tasks.foreignAgent),
                images: _vm.get(_vm.tasks.foreignAgent, "task_result.images"),
                "missing-requirements": _vm.getMissingRequirements(
                  _vm.tasks.foreignAgent
                )
              }
            },
            [
              _vm.tasks.foreignAgent.task_result
                ? _c("app-foreign-agent-report", {
                    attrs: { result: _vm.tasks.foreignAgent.task_result.result }
                  })
                : _vm.orderIsDone
                ? [
                    _c("span", { staticClass: "text-warning" }, [
                      _vm._v(" Недостаточно данных ")
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _c(
        "app-report-item",
        {
          attrs: {
            title: "Арбитражные суды",
            icon: "gavel",
            status: _vm.getTaskStatus(_vm.tasks.arbitrReport),
            images: _vm.get(_vm.tasks.arbitrReport, "task_result.images"),
            "missing-requirements": _vm.getMissingRequirements(
              _vm.tasks.arbitrReport
            )
          }
        },
        [
          _vm.tasks.arbitrReport
            ? _c("app-arbitr-report", {
                attrs: {
                  region: _vm.report.owner.region,
                  result: _vm.tasks.arbitrReport.task_result,
                  "is-physical": _vm.isPhysical,
                  inn: _vm.inn,
                  owner: _vm.report.owner
                }
              })
            : _vm.orderIsDone
            ? [
                _c("span", { staticClass: "text-warning" }, [
                  _vm._v(" Недостаточно данных ")
                ])
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "app-report-item",
        {
          attrs: {
            title: "Судебные акты Российской Федерации",
            icon: "gavel",
            status: _vm.getSudTaskStatus(_vm.tasks.judicialActs),
            images: _vm.get(_vm.tasks.judicialActs, "task_result.images"),
            "missing-requirements": _vm.getMissingRequirements(
              _vm.tasks.judicialActs
            ),
            source: {
              domain: "sudrf.ru",
              link: "https://bsr.sudrf.ru/"
            }
          }
        },
        [
          _vm.tasks.judicialActs
            ? _c("app-sud-report", {
                attrs: {
                  region: _vm.report.owner.region,
                  result: _vm.tasks.judicialActs.task_result,
                  mosgorsud: _vm.getTask("mosgorsud"),
                  sudact: _vm.getTask("sudact"),
                  "is-physical": _vm.isPhysical
                }
              })
            : _vm.orderIsDone
            ? [
                _c("span", { staticClass: "text-warning" }, [
                  _vm._v(" Недостаточно данных ")
                ])
              ]
            : _vm._e()
        ],
        2
      ),
      _vm.isPhysical && _vm.onlyOwners
        ? _c(
            "app-report-item",
            {
              attrs: {
                title: "Реестр залогов",
                icon: "hand-holding-usd",
                status: _vm.getSudTaskStatus(_vm.tasks.pledge),
                images: _vm.get(_vm.tasks.pledge, "task_result.images"),
                "missing-requirements": _vm.getMissingRequirements(
                  _vm.tasks.pledge
                ),
                source: {
                  domain: "reestr-zalogov.ru",
                  link: "https://www.reestr-zalogov.ru/search/index"
                }
              },
              scopedSlots: _vm._u(
                [
                  _vm.get(
                    _vm.tasks.pledge,
                    "task_result.result.sources.0.record.length"
                  )
                    ? {
                        key: "status",
                        fn: function() {
                          return [
                            _c("span", { staticClass: "text-danger" }, [
                              _c("i", {
                                staticClass: "fas fa-exclamation-circle"
                              }),
                              _vm._v(
                                " Найдено записей:\n        " +
                                  _vm._s(
                                    _vm.get(
                                      _vm.tasks.pledge,
                                      "task_result.result.sources.length"
                                    )
                                  ) +
                                  "\n      "
                              )
                            ])
                          ]
                        },
                        proxy: true
                      }
                    : null
                ],
                null,
                true
              )
            },
            [
              _vm.tasks.pledge
                ? _c("app-pledge-report", {
                    attrs: {
                      result: _vm.tasks.pledge.task_result,
                      "is-physical": _vm.isPhysical
                    }
                  })
                : _vm.orderIsDone
                ? [
                    _c("span", { staticClass: "text-warning" }, [
                      _vm._v(" Недостаточно данных ")
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-auto mr-3" }, [
      _c("span", { staticClass: "text-success" }, [
        _c("i", { staticClass: "fas fa-check-circle fa-sm fa-fw mr-1" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "text-success" }, [
      _c("b", [_vm._v("подтверждено")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-md-7 mb-3 mb-md-0" }, [
      _c("h5", [
        _vm._v(
          "\n            Полный отчет доступен по сниженной цене\n          "
        )
      ]),
      _c("span", [
        _vm._v(
          "\n            Отчет содержит больше официальной информации, необходимой для вашей безопасности и защиты от финансовых и репутационных рисков.\n          "
        )
      ]),
      _c("br"),
      _c(
        "span",
        { staticClass: "report-not-paid__text-yellow font-weight-500" },
        [
          _vm._v(
            "\n            Понимаем, что важно обезопасить себя, поэтому дарим скидку 14%\n          "
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }