









































































































































































































import Vue from 'vue';
import { noop, cloneDeep, isEmpty, omit } from 'lodash';
import Component from 'vue-class-component';
import isUnderage from 'shared/utils/isUnderage';
import { Validators } from 'shared/utils/validator';
import flatpickr from 'flatpickr';
import { regionsSortByName, Region } from 'shared/utils/regions';
import { TheMask } from 'vue-the-mask';

const now = new Date();

@Component({
  components: { TheMask },
  props: {
    owner: Object,
    onSave: {
      type: Function,
      default: noop,
    },
    fetchReport: {
      type: Function,
      default: noop,
    },
    fields: Array,
    rightsList: {
      type: Array,
      default: null,
    },
  },
  popupOptions: {
    name: '',
    size: 'md',
  },
})

export default class AppUniveralEditDialog extends Vue {
  // props
  owner: LawInformationOwner;
  onSave: Function;
  fields: string[];
  // data
  model: LawInformationOwner = null;
  regions: Region[] = regionsSortByName;
  loading: boolean = false;
  fetchReport: () => any;
  checkTargets: any[] = [
    {
      text: 'Поиск Родственника или старого друга',
      value: 1,
    },
    {
      text: 'Знакомства/Отношения',
      value: 2,
    },
    {
      text: 'Личное любопытство',
      value: 3,
    },
    {
      text: 'Проверка себя',
      value: 4,
    },
    {
      text: 'Личная безопасность/Мошенничество',
      value: 5,
    },
    {
      text: 'Трудоустройство',
      value: 6,
    },
    {
      text: 'Важная Сделка',
      value: 7,
    },
    {
      text: 'Юридические нужды',
      value: 8,
    },
    {
      text: 'Проверка партнера по Бизнесу',
      value: 9,
    },
    {
      text: 'Другое',
      value: 10,
    },
  ];

  datePickerOptions: flatpickr.Options.Options = {
    maxDate: now,
    minDate: new Date(now.getFullYear() - 120, 0, 0),
    defaultDate: '',
  };

  error: string = '';

  created() {
    this.model = cloneDeep(this.owner);
  }

  get title() {
    if (this.fields.length > 1) return 'Редактирование информации';

    const titleMap: { [key: string]: string } = {
      birthday: 'Дата рождения',
      passport: 'Серия и номер паспорта',
      license: 'Водительское удостоверение',
      phone: 'Добавить телефон и email',
      inn: 'ИНН',
      snils: 'СНИЛС',
      region: 'Регион регистрации/проживания',
    };

    return titleMap[this.fields[0]];
  }

  isIncluded(modelName: string): boolean {
    return this.fields.includes(modelName);
  }

  async onSubmit() {
    if (isUnderage(this.model.birthday)) {
      return this.$noty.error({ text: 'Проверка осуществляется лиц, достигших 14 лет. Введите другое физическое лицо' });
    }
    try {
      const body = cloneDeep(this.model);
      Object.assign(this.owner, body);
      await this.$api.risk.UpdateOwnerV2(body);
      this.fetchReport();
      this.$dialog.close();
    } catch (error) {
      this.$noty.error({ text: this.$api.getErrorMessage(error as any) });
    }
    return Promise.resolve();
  }

  validationConfig(model: LawInformationOwner): ValidationConfig {
    let config: ValidationConfig = {
      first: [ Validators.required ],
      surname: [ Validators.required ],
      passport: [ Validators.min(10, 'Минимальная длина 10') ],
      birthday: [ Validators.date('Неверный формат даты') ],
      snils: [ Validators.snils ],
      inn: [ Validators.min(12, 'Минимальная длина 12') ],
    };

    if (isEmpty(model.passport)) {
      config = omit(config, 'passport');
    }

    if (isEmpty(model.birthday)) {
      config = omit(config, 'birthday');
    }

    if (isUnderage(model.birthday)) {
      config = omit(config, 'passport', 'region');
    }

    if (isEmpty(model.snils)) {
      config = omit(config, 'snils');
    }

    if (isEmpty(model.inn)) {
      config = omit(config, 'inn');
    }

    return config;
  }
}
