


















































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import { getRegionNameById } from 'shared/utils/regions';

@Component({
  components: { AppReportItem },
  props: {
    report: Object,
    status: String,
    images: Array,
    orderIsDone: Boolean,
    missingRequirements: Array,
    openAddFieldDialog: Function,
    updateMap: Function,
    task: Object,
    isPhysical: Boolean,
    onlyOwners: Boolean,
    map: Object,
  },
})
export default class AppCriminalReportModern extends Vue {
  // props
  missingRequirements: any[];
  report: any;
  status: string;
  images: any[];
  orderIsDone: boolean;
  task: any;
  isPhysical: boolean;
  onlyOwners: boolean;
  openAddFieldDialog: (val: string) => any;
  updateMap: (taskName: string, field: string, value: any) => void;
  map: any;
  resultStatusTrace: any = null;

  created() {
    this.resultStatusTrace = this.resultStatus;
  }

  get result() {
    return this.task.task_result;
  }

  get statusDone() {
    return this.status === 'done';
  }

  get resultStatus() {
    if (this.task && this.statusDone) {
      if (this.task.task_result.result === 'not wanted') {
        this.updateColor('success');
        return 'not found';
      } else if (this.task.task_result.result === 'wanted' || this.task.task_result.result) {
        this.updateColor('danger');
        return 'found';
      }
    }
    this.updateColor('info');
    return 'no data';
  }

  regionName(value: string): string {
    return getRegionNameById(value);
  }

  updateColor(color: string) {
    this.updateMap('criminal', 'color', color);
  }
}
