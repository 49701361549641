






























































































































import Vue from 'vue';
import flatpickr from 'flatpickr';
import addDays from 'date-fns/add_days';

import pluck from 'shared/utils/pluck';
import formatDateStringToISO from 'shared/utils/date/formatDateStringToISO';
import { Validators } from 'shared/utils/validator';

interface ComponentData {
  model: PriceComponent.Request.Create;
  priceComponentTypes: { [key: string]: string };
  pickerOptions: flatpickr.Options.Options;
}

export default Vue.extend({
  popupOptions: {
    size: 'xs',
  },

  props: {
    onUpdated: Function,
  },

  data(): ComponentData {
    return {
      model: {
        product_id: null,
        price_component_type_id: null,
        name: '',
        description: '',
        comment: '',
        price: '0',
        enabled: true,
        from_date: '',
        thru_date: '',
      },

      priceComponentTypes: {
        '0f0e400c-bf5f-11e9-952f-0242ac110002': 'Скидка',
        '162f64ce-bf5f-11e9-952f-0242ac110002': 'Добавочный',
      },

      pickerOptions: {
        minDate: addDays(Date.now(), -2),
      },
    };
  },

  computed: {
    products(): { [key: string]: string } {
      return pluck(this.$store.state.products, 'product_id', 'product_name');
    },
  },

  methods: {
    async createPriceComponent() {
      try {
        const body = { ...this.model };

        formatDateStringToISO(body, 'from_date', 'thru_date');

        await this.$api.priceComponent.items.CreateItem(body);

        this.$dialog.close();

        this.onUpdated();
      } catch (error) {
        console.error(error);
      }
    },

    validationRules(): ValidationConfig {
      return {
        product_id: [ Validators.required ],
        price_component_type_id: [ Validators.required ],
        name: [ Validators.required ],
        price: [ Validators.isInt({ min: 1, message: 'Должно быть числом, не меньше 1' }) ],
        from_date: [ Validators.required ],
        thru_date: [ Validators.required ],
      };
    },
  },
});
