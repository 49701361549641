



















































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import AppWarningImg from 'shared/components/Static/WarningImg.vue';
import { getRegionNameById } from 'shared/utils/regions';
import AppSurveyMixin from 'shared/mixins/surveys.mixin';

@Component({
  props: {
    owner: Object,
    metadata: Array,
    inn: Object,
    snils: Object,
    orderIsDone: Boolean,
    addField: Function,
  },
  mixins: [ AppSurveyMixin ],
  components: { AppWarningImg },

  // filters: {
  //   formatPassport: (value: string, isPrint: boolean): string => {
  //     if (isPrint) {
  //       value = value.slice(0, 4);
  //     }

  //     return value.replace(/(\d{2})(\d{2})(\d+)?/, '$1 $2 $3');
  //   },
  // },
})
export default class AppOwnerInfoModern extends Vue {
  // props
  owner: LawInformationOwner;
  metadata: any[];
  inn: any;
  snils: any;
  orderIsDone: boolean;
  addField: (dialogName: string) => any;

  get region(): string {
    if (this.owner.region) {
      return getRegionNameById(this.owner.region);
    }

    return null;
  }

  get innManualAdded() {
    return this.inn && this.inn.task_result.inn && this.inn.task_result.text === 'Добавлен пользователем';
  }

  get isPhysical() {
    return this.owner.owner_type !== 1;
  }

  get ownerBirthdayIsEmpty(): boolean {
    if (this.owner.birthday === '0001-01-01' || this.owner.birthday === '31.12.0000') {
      return true;
    }
    return false;
  }

  get getSurvey(): any {
    if (this.metadata) {
      const res = this.metadata.find((survey: any) => survey.status === 'done');
      if (res) return res;
    }
    return {};
  }

  get errors() {
    const errorsMsg = [];

    const innErrorMsg = get(this.inn, 'task_result.text', '');
    if (innErrorMsg !== 'Добавлен пользователем' && this.messageIsRu(innErrorMsg)) {
      errorsMsg.push({
        field: 'owner',
        message: innErrorMsg,
      });
    }

    const snilsErrors = get(this.snils, 'task_result.result.data.errors', []);
    const snilsErrorMsg = get(this.snils, 'task_result.result.errorMsg', '');

    if (snilsErrors && snilsErrors.length > 0) {
      const firstError = snilsErrors[0];
      const nameFields = [ 'nameFirst', 'nameLast', 'patronymic' ];

      let field = firstError.field;
      if (nameFields.includes(field)) {
        field = 'owner';
      }

      if (field === 'birthDate') {
        field = 'birthday';
      }
      errorsMsg.push({
        field: field,
        message: firstError.message,
      });
    } else if (snilsErrorMsg) {
      errorsMsg.push({
        field: 'snils',
        message: snilsErrorMsg,
      });
    }

    return errorsMsg;
  }

  // get resultText() {
  //   return get(this.inn, 'task_result.text', '');
  // }

  messageIsRu(message: string): boolean {
    const regexp = new RegExp(/^[-а-яА-ЯёЁ0-9?!,.()\s']+$/);
    return regexp.test(message);
  }

  getQuestionText(val: number): any {
    if (this.metadata) {
      const question = this.getSurvey.survey_type.questions.find((question: any) => question.question_number === val);
      return question.text;
    } else return '-';
  }

  itsArray(val: any) {
    return isArray(val);
  }
}
