import Component, { mixins } from 'vue-class-component';
import ClipboardJS from 'clipboard';
import { Options } from 'tippy.js';
import startsWith from 'lodash/startsWith';
import includes from 'lodash/includes';
import { saveAs } from 'file-saver';
import { AuthTypes } from 'shared/store/modules/auth/types';
import HelpersMixin from '@/core/mixins/helpers.mixin';

import AppUpdateKadNumber from '../../components/UpdateKadNumber.vue';
import AppAdminManualAttachOrderItem from '../../components/AdminManualAttachOrderItem.vue';
import AppOrderItemStatus from '../../components/OrderItemStatus.vue';
import AppSendEmailWithAttachment from './components/SendEmailWithAttachment.vue';
import AppOrderRefund from './components/OrderRefund.vue';
import AppDivergenceDialog from '@/views/Admin/AdminUserInfo/AdminOrderDetail/components/DivergenceDialog.vue';
import Vue from 'vue';
import { PRODUCT_NAME_PREFIXES } from 'shared/utils/constants/product-prefixes';
import AppRiskUpdateScraperStatusDialog from '@/views/Admin/AdminUserInfo/RiskReportV2/RiskUpdateScraperStatus.vue';

let clipboard: ClipboardJS;
@Component({
  components: {
    AppUpdateKadNumber,
    AppOrderItemStatus,
    AppSendEmailWithAttachment,
    AppOrderRefund,
  },

  props: {
    orderID: String,
    userID: String,
  },
})
export default class AdminOrderDetail extends mixins(HelpersMixin) {
  // props
  orderID!: string;
  userID!: string;

  // data()
  order: AdminOrderInfo = null;
  loading = true;
  editingIndex: number | null = null;
  loadings: SimpleObject<boolean> = {};
  tippyDropdownOptions: Options = {
    placement: 'left-start',
    theme: 'dropdown light',
    interactive: true,
    hideOnClick: false,
    delay: [ 150, 400 ],
  };
  coupons: string[] = [];
  counter: number = 0;
  showMetadataArr: boolean[] = [];
  updateOrderStatus: boolean = true;
  isEncumbranceInOrder: boolean = false;
  isOrderEnabled: boolean = true;

  // computed
  get isAdmin(): boolean {
    return this.$store.getters[AuthTypes.getters.IS_ADMIN];
  }

  // lifecycle
  async created() {
    await this.handleRequest();
    clipboard = new ClipboardJS('[data-clipboard-text]');
    clipboard.on('success', (event: ClipboardJS.Event) => {
      this.$noty.success({
        text: `<div class="small">Номер скопирован <br> ${event.text}</div>`,
      });
    });
    this.fillShowMetadataArr();
  }

  beforeDestroy() {
    if (clipboard) {
      clipboard.destroy();
      clipboard = null;
    }
  }

  // methods
  async handleRequest() {
    try {
      const [ order ] = await this.$api.admin.Orders<AdminOrderInfo[]>({
        userID: this.userID,
        orderID: this.orderID,
      });
      const { OrderItem } = order;
      const meta: OrderItemMetadata =
        OrderItem && OrderItem[0] ? OrderItem[0].ItemMetadata : {};
      const kadastrNumbers: SimpleObject<string> = {};

      for (const orderItem of OrderItem) {
        kadastrNumbers[orderItem.ProductName] =
          orderItem.ItemMetadata.kadastr_number;
        if (orderItem.CouponID !== '00000000-0000-0000-0000-000000000000') {
          orderItem.CouponInfo = await this.getCouponInfo(orderItem.CouponID);
        }
      }

      order.KadastrNumbers = kadastrNumbers;
      order.Address = meta.address;
      this.order = order;
      this.isOrderEnabled = order.Paid;
      this.loading = false;
    } catch (error) {
      return this.$api.HttpError(error);
    }
  }

  // getObjectUrl(item: AdminOrderItem, subDomain: string) {
  //   const metaData = item.ItemMetadata;
  //   let objectKey;

  //   if (metaData && metaData.address) {
  //     objectKey = metaData.address.trim().replace(/\//g, '%2F');
  //   }

  //   if (
  //     metaData &&
  //     metaData.kadastr_number &&
  //     metaData.kadastr_number !== 'n/a'
  //   ) {
  //     objectKey = metaData.kadastr_number.trim();
  //   }

  //   if (!objectKey) {
  //     return '#';
  //   }

  //   return `https://${subDomain}.realtycloud.ru/object/${objectKey}?id=${item.OrderItemID}`;
  // }

  canEditKadastr(item: AdminOrderItem) {
    // const { kadastr_number: kadastrNumber } = item.ItemMetadata;

    if (
      startsWith(item.ProductName, 'RiskAssessmentV2') ||
      startsWith(item.ProductName, 'RiskAssessmentFastV2')
    ) {
      return true;
    }

    return (
      item.status === 'manual' ||
      item.status === 'manual_only' ||
      item.status === 'Не найден'
    );
  }

  kadNumberUpdated(kadastrNumber: string, index: number) {
    this.order.KadastrNumber = kadastrNumber;
    this.order.OrderItem[index].status = 'requested';
    this.editingIndex = null;
  }

  async updateOrderItemStatus(item: AdminOrderItem, status: string) {
    if (status === 'delete') {
      if (!confirm('Удалить?')) {
        return;
      }
    }
    try {
      await this.$api.admin.UpdateOrderItemStatus({ order_item_id: item.OrderItemID, status: 'delete' });
    } catch (error) {
      this.$noty.error({ text: this.$api._getErrorMessage(error) });
      console.error(error);
    }
  }

  async updateKadnetStatus(item: AdminOrderItem, statusId: KadnetStatus) {
    if (this.loadings[statusId]) {
      return;
    }

    if (statusId === 4) {
      if (!confirm('Перезаказать?')) {
        return;
      }
    }

    try {
      this.toggleLoading(statusId);

      const body: UpdateKadnetStatusRequestBody = {
        order_item_id: item.OrderItemID,
        status_id: statusId,
      };

      this.$noty.info({ text: 'Отправляем запрос на бэк...' });

      await this.$api.admin.UpdateOrderItemKadnetStatus(body);

      this.$noty.success({ text: 'Запрос выполнен успешно' });
    } catch (error) {
      this.$noty.error({ text: this.$api._getErrorMessage(error) });
      console.error(error);
    }

    if (this.loadings[statusId]) {
      this.toggleLoading(statusId);
    }
  }

  isRiskProduct(productName: ProductName) {
    return (
      includes(productName, 'RiskAssessment') ||
      includes(productName, 'CheckOwner') ||
      includes(productName, 'EgrnObject') ||
      includes(productName, 'EgrnRightList')
    );
  }

  isFastProduct(productName: ProductName): boolean {
    return includes(productName, 'Fast');
  }

  isEgrnObjectOrEgrnRightList(productName: ProductName): boolean {
    return (
      includes(productName, 'EgrnObject') ||
      includes(productName, 'EgrnRightList')
    );
  }

  async spvKadastrActions(
    action: 'order' | 'download',
    itemStatus: string,
    orderItemId: OrderItemID
  ) {
    if (this.loadings[action] || itemStatus === 'done') {
      return;
    }

    if (action === 'order') {
      if (!confirm('Перезаказать?')) {
        return;
      }
    }

    if (action === 'order' && itemStatus === 'order_error') {
      this.toggleLoading(action);
      try {
        this.$noty.info({ text: 'Отправляем запрос на бэк...' });
        await this.$api.admin.UpdateSpvOrderItemStatus(orderItemId);
        this.$noty.success({ text: 'Ok' });
      } catch (error) {
        console.error(error);
        this.$noty.error({ text: this.$api._getErrorMessage(error) });
      }
    }

    if (action === 'download') {
      this.toggleLoading(action);
      try {
        this.$noty.info({ text: 'Отправляем запрос на бэк...' });
        await this.$api.admin.DownloadSpvOrderItem(orderItemId);
        this.$noty.success({ text: 'Ok' });
      } catch (error) {
        console.error(error);
        this.$noty.error({ text: this.$api._getErrorMessage(error) });
      }
    }

    if (this.loadings[action]) {
      this.toggleLoading(action);
    }
  }

  toggleLoading(key: string | number) {
    if (!(key in this.loadings)) {
      this.$set(this.loadings, key, false);
    }

    this.loadings[key] = !this.loadings[key];
  }

  showDialog() {
    this.$dialog.open({
      component: AppAdminManualAttachOrderItem,
      props: {
        orderId: this.orderID,
        kadastrNumber: this.order.KadastrNumber,
        onRequestEnd: this.handleRequest,
      },
    });
  }

  async downloadOrder(
    orderItemID: string,
    productName: string,
    fileType: string
  ) {
    let response: Blob;
    try {
      let fileName = `${productName}.${fileType}`;
      const url = `/download?orderID=${orderItemID}&fileType=${fileType}`;
      if (fileType === 'signed_zip') {
        fileName = `${productName}.zip`;
      }
      response = await this.$api.raw.files.GetFileBlob(url);
      saveAs(response, fileName);
    } catch (error) {
      console.error(error);
      const errorObj = await error.response.data.text();
      const text = JSON.parse(errorObj);
      this.$noty.error({ text: text.error });
    }
  }

  async sendLinkEnterToEmail() {
    if (confirm('Отправить на email?')) {
      try {
        this.$noty.info({ text: 'Отправляем ссылку на email' });
        const params: SendLinkEnterToEmailParams = {
          person_id: this.userID,
          order_item_id: this.order.OrderItem[0].OrderItemID,
        };
        if (
          this.order.OrderItem[0].ItemMetadata &&
          this.order.OrderItem[0].ItemMetadata.kadastr_number &&
          this.order.OrderItem[0].ItemMetadata.kadastr_number !== 'n/a'
        ) {
          params.kadnum = this.order.OrderItem[0].ItemMetadata.kadastr_number;
        }
        // if (this.order.OrderItem[0].ItemMetadata?.kadastr_number !== 'n/a') {
        //   params.kadnum = this.order.OrderItem[0].ItemMetadata?.kadastr_number;
        // }
        await this.$api.admin.SendLinkEnterToEmail(params);
        this.$noty.success({ text: 'Отправлено' });
      } catch (error) {
        console.error(error);
        this.$noty.error({ text: this.$api._getErrorMessage(error) });
      }
    }
  }

  async getCouponInfo(couponID: string) {
    const response: any = await this.$api.admin.GetCouponInfo(couponID);
    if (response.promo_code) {
      return `Промокод: ${response.promo_code.code}, Купон: ${
        response.campaign.code
      }`;
    }
    return `Купон: ${response.campaign.code}`;
  }

  getRiskRoute(productName: string) {
    return (includes(productName, 'V2') || includes(productName, 'Egrn'))
      ? 'admin-risk-report-v2'
      : 'admin-risk-report';
  }

  getSourcePaid(transactionStatusId: number): string {
    return transactionStatusId === 2 ? 'с карты' : 'с баланса';
  }

  checkDivergence(item: OrderItem) {
    if (item.metadata && item.metadata.diffStatement) {
      if (
        item.metadata.diffStatement.amount_of_owners ||
        item.metadata.diffStatement.area ||
        item.metadata.diffStatement.cadastral_value ||
        item.metadata.diffStatement.date_rights ||
        item.metadata.diffStatement.registration_number_of_rights ||
        item.metadata.diffStatement.encumbrance
      ) {
        this.isEncumbranceInOrder = true;
        return true;
      }
    }
  }

  showDivergenceDialog(item: OrderItem) {
    this.$dialog.open({
      component: AppDivergenceDialog,
      props: {
        data: item.metadata.diffStatement,
      },
    });
  }

  isMetadataEmpty(itemMetadata: OrderItemMetadata): boolean {
    return Object.keys(itemMetadata).length === 0;
  }

  passedMoreThanFourWeeks(orderCreatedDate: string): boolean {
    // 2419200000 - количество миллисекунд в 28 днях
    return Date.now() - +new Date(orderCreatedDate) > 2419200000;
  }

  fillShowMetadataArr() {
    this.order.OrderItem.forEach(() => {
      this.showMetadataArr.push(false);
    });
  }

  changeShowMetadata(i: number) {
    Vue.set(this.showMetadataArr, i, !this.showMetadataArr[i]);
  }

  async disableOrderProcess() {
    this.isOrderEnabled = !this.isOrderEnabled;
    await this.$api.admin.UpdateOrderStatus(this.orderID, this.isOrderEnabled);
  }

  getProductName(productName: string): string {
    if (this.order.KadastrNumbers && PRODUCT_NAME_PREFIXES[productName]) {
      return `${PRODUCT_NAME_PREFIXES[productName]}\\${this.order.KadastrNumbers[productName].replace(/:/g, '\\')}`;
    }
    return productName;
  }

  async openStatusDialog(id: string) {
    const orders = await this.$api.risk.GetOrders({
      task_ids: [
        id,
      ],
    });
    if (!orders.length) {
      this.$noty.error({ text: 'Заказ не найден' });
      return;
    }
    const report = orders[0];
    const reportItem = {
      tasks: report.tasks,
    };
    this.$dialog.open({
      component: AppRiskUpdateScraperStatusDialog,
      props: {
        report: reportItem,
        orderItemId: id,
      },
    });
  }

  // @Watch('updateOrderStatus')
  // changeUpdateOrderStatus(value: boolean): void {
  //   this.toggleCollapse(value);
  // }
}
