
















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import moment from 'moment';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';

@Component({
  components: {
    AppSemiCircleChart: () =>
      import('shared/components/Charts/SemiCircleChart.vue'),
    AppColumnChartWithRadio: () =>
      import('shared/components/Charts/ColumnChartWithRadio.vue'),
  },
  props: {
    items: Array,
    isPanel: Boolean,
  },
})
export default class AppReportItemGraph extends Vue {
  // props
  get = get;
  items: any;
  currentPage: number = 1;
  filterBy: string = 'sum';
  filterBySum: any = 'all';
  filterByYear: any = 'all';
  isPanel: boolean = false;
  filterColors: any = [
    '#9CA3AF',
    '#336FEE',
    '#FE203A',
    '#49C236',
    '#FFE146',
    '#24BA96',
    '#F0A900',
  ];

  get itemsBySum() {
    const result: any = {
      500: [],
      1500: [],
      5000: [],
      20000: [],
      100000: [],
      'Более 100 000 ₽': [],
      'Без суммы': [],
    };
    const keys = Object.keys(result);
    forEach(this.items, (elem: any) => {
      if (!elem.price || this.isNumeric(elem.price)) {
        result['Без суммы'].push(elem);
        return;
      }
      for (let index = 0; index < keys.length; index++) {
        const element: number = parseInt(keys[index]);
        if (parseInt(elem.price) > 100000) {
          result['Более 100 000 ₽'].push(elem);
          break;
        }
        if (parseInt(elem.price) <= element) {
          result[element].push(elem);
          break;
        }
      }
    });
    return result;
  }

  get itemsByYear() {
    const itemsWithYear = this.items.map((elem: any) => {
      const year = elem.date ? moment(elem.date, 'DD.MM.YYYY').year() : 'Без даты';
      return {
        ...elem,
        year,
      };
    });
    return groupBy(itemsWithYear, 'year');
  }

  get itemsSum() {
    return sumBy(this.items, (elem: any) => {
      return parseInt(elem.price || 0);
    });
  }

  get bySumChartData() {
    return map(this.itemsBySum, (elem, i) => {
      if (!i) {
        i = 'Без суммы';
      }
      return [ this.$options.filters.currency(i), elem.length ];
    });
  }

  get byYearChartData() {
    return map(this.itemsByYear, (elem, i) => {
      return [ i, sumBy(elem, (item: any) => parseInt(item.price || 0)) ];
    });
  }

  get bySumChartTitleText() {
    return `<div class="semi-circle-chart-title">Общая сумма</div><div class="semi-circle-chart-subtitle">${this.$options.filters.lazyround(this.itemsSum)}</div>`;
  }

  isNumeric(str: any) {
    if (typeof str !== 'string') return false;
    return !isNaN(str as any) && !isNaN(parseFloat(str));
  }

  selectYear(event: any) {
    if (event.point.name === this.filterByYear) {
      this.filterByYear = 'all';
      return;
    }
    this.filterByYear = event.point.name;
  }

  getField(items: any, name: string) {
    return find(items, (elem) => elem.name === name);
  }
}
