var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c(
        "h5",
        { staticClass: "text-center mb-4" },
        [
          _vm.campaign
            ? [_vm._v("\n      Редактирование купона\n    ")]
            : [_vm._v("\n      Создание купона\n    ")]
        ],
        2
      ),
      _c(
        "app-form",
        {
          attrs: {
            "request-handler": _vm.onSubmit,
            model: _vm.model,
            rules: _vm.validationRules
          },
          scopedSlots: _vm._u([
            {
              key: "button",
              fn: function(ref) {
                var loading = ref.loading
                return [
                  _c(
                    "app-button",
                    {
                      attrs: {
                        type: "submit",
                        size: "lg",
                        loading: loading,
                        block: ""
                      }
                    },
                    [_vm._v("\n        Создать\n      ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "app-form-group",
            {
              staticClass: "rc-form-group mb-2",
              attrs: { "model-name": "name" }
            },
            [
              _c("app-input", {
                attrs: { label: "Название" },
                model: {
                  value: _vm.model.name,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "name", $$v)
                  },
                  expression: "model.name"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            {
              staticClass: "rc-form-group mb-2",
              attrs: { "model-name": "code" }
            },
            [
              _c("app-input", {
                attrs: { label: "Код купона" },
                model: {
                  value: _vm.model.code,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "code", $$v)
                  },
                  expression: "model.code"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            {
              staticClass: "rc-form-group mb-2",
              attrs: { "model-name": "description" }
            },
            [
              _c("app-input", {
                attrs: { label: "Процент скидки", placeholder: "50%" },
                model: {
                  value: _vm.model.description,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "description", $$v)
                  },
                  expression: "model.description"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            {
              staticClass: "rc-form-group mb-2",
              attrs: { "model-name": "discount" }
            },
            [
              _c("app-input", {
                attrs: { label: "Размер скидки ₽", placeholder: "49" },
                model: {
                  value: _vm.model.discount,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "discount", $$v)
                  },
                  expression: "model.discount"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            {
              staticClass: "rc-form-group mb-2",
              attrs: { "model-name": "usage_count" }
            },
            [
              _c("app-input", {
                attrs: { label: "Количество продуктов", type: "number" },
                model: {
                  value: _vm.model.usage_count,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "usage_count", $$v)
                  },
                  expression: "model.usage_count"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            {
              staticClass: "rc-form-group mb-2",
              attrs: { "model-name": "product_id" }
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.product_id,
                      expression: "model.product_id"
                    }
                  ],
                  staticClass: "custom-select",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.model,
                        "product_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c(
                    "option",
                    { attrs: { selected: "" }, domProps: { value: null } },
                    [_vm._v("\n          Тип купона\n        ")]
                  ),
                  _vm._l(_vm.products, function(product) {
                    return _c(
                      "option",
                      {
                        key: product.product_id,
                        domProps: { value: product.product_id }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(product.product_name) +
                            "\n        "
                        )
                      ]
                    )
                  })
                ],
                2
              ),
              _c("label", { class: [{ active: _vm.model.product_id }] }, [
                _vm._v("\n        Продукт\n      ")
              ])
            ]
          ),
          _c(
            "app-form-group",
            {
              staticClass: "rc-form-group mb-2",
              attrs: { "model-name": "start_at" }
            },
            [
              _c("app-date-picker", {
                attrs: {
                  label: "Действует от",
                  options: _vm.datePickerOptions,
                  placeholder: "ДД.ММ.ГГГГ"
                },
                model: {
                  value: _vm.model.start_at,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "start_at", $$v)
                  },
                  expression: "model.start_at"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            {
              staticClass: "rc-form-group mb-2",
              attrs: { "model-name": "end_at" }
            },
            [
              _c("app-date-picker", {
                attrs: {
                  label: "Действует до",
                  options: _vm.datePickerOptions,
                  placeholder: "ДД.ММ.ГГГГ"
                },
                model: {
                  value: _vm.model.end_at,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "end_at", $$v)
                  },
                  expression: "model.end_at"
                }
              })
            ],
            1
          ),
          _c(
            "app-checkbox",
            {
              staticClass: "my-3",
              attrs: { "false-value": false, "true-value": true },
              model: {
                value: _vm.model.active,
                callback: function($$v) {
                  _vm.$set(_vm.model, "active", $$v)
                },
                expression: "model.active"
              }
            },
            [_vm._v("\n      Активен\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }