var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-report-item",
    {
      attrs: {
        title: "Участие в организациях",
        icon: "briefcase",
        color: "warning",
        status: _vm.reportItemStatus,
        opened: _vm.resultStatus === "found",
        "missing-requirements": _vm.missingRequirements
      },
      scopedSlots: _vm._u(
        [
          _vm.resultStatus === "found"
            ? {
                key: "extra-content",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "report-item-result report-item-result__result-wrapper"
                      },
                      [
                        _vm._l(_vm.companies, function(item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass:
                                "report-item-result__result mosgorsud-result"
                            },
                            [
                              _c("app-report-collapse-item", {
                                attrs: { opened: false, disabled: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "header",
                                      fn: function() {
                                        return [
                                          item.value
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "report-item-result__result-elem-text-wrapper"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "report-item-result__result-elem-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                Название организации\n              "
                                                      )
                                                    ]
                                                  ),
                                                  _c("div", [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(item.value) +
                                                        "\n              "
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e(),
                                          item.data.inn || item.data.ogrn
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "report-item-result__result-elem-text-wrapper"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "report-item-result__result-elem-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                Реквизиты\n              "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      item.data.inn
                                                        ? [
                                                            _vm._v(
                                                              "\n                  ИНН " +
                                                                _vm._s(
                                                                  item.data.inn
                                                                ) +
                                                                "\n                "
                                                            )
                                                          ]
                                                        : _vm._e(),
                                                      item.data.ogrn
                                                        ? [
                                                            _c("br"),
                                                            _vm._v(
                                                              "ОГРН " +
                                                                _vm._s(
                                                                  item.data.ogrn
                                                                ) +
                                                                "\n                "
                                                            )
                                                          ]
                                                        : _vm._e()
                                                    ],
                                                    2
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          item.data.state &&
                                          item.data.state.status
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "report-item-result__result-elem-text-wrapper"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "report-item-result__result-elem-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                Статус\n              "
                                                      )
                                                    ]
                                                  ),
                                                  item.data.state.status ===
                                                  "ACTIVE"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-success-modern"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                Действующий\n              "
                                                          )
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-danger-modern"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                Ликвидирован\n              "
                                                          )
                                                        ]
                                                      )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        }),
                        _c("div", { staticClass: "my-2" }, [
                          _c(
                            "a",
                            { attrs: { href: "#bankrupt" } },
                            [
                              _c(
                                "app-button",
                                {
                                  attrs: {
                                    size: "lg",
                                    variant: "modern-outline",
                                    type: "button"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Перейти в базовый отчет\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "rc-modern-info-panel" }, [
                          _c(
                            "div",
                            { staticClass: "rc-modern-info-panel__wrapper" },
                            [
                              _c("div", [_c("app-warning-img")], 1),
                              _c("div", [
                                _vm._v(
                                  "\n            Все организации и полные данные представлены в Базовом отчете.\n          "
                                )
                              ])
                            ]
                          )
                        ])
                      ],
                      2
                    )
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm.user
        ? _c(
            "div",
            { staticClass: "report-item-modern__subtitle-wrapper" },
            [
              _vm.resultStatus === "found"
                ? [
                    _c("div", { staticClass: "report-item-modern__subtitle" }, [
                      _vm._v(
                        "\n        Физическое лицо зарегистрировано в качестве учредителя и/или руководителя в организации\n      "
                      )
                    ])
                  ]
                : [
                    _c("div", { staticClass: "report-item-modern__subtitle" }, [
                      _vm._v(
                        "\n        Среди руководителей и учредителей не значится\n      "
                      )
                    ])
                  ]
            ],
            2
          )
        : _vm.orderIsDone
        ? [
            _c("span", { staticClass: "text-warning" }, [
              _vm._v(" Недостаточно данных ")
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }