var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c(
        "app-form",
        { attrs: { "request-handler": _vm.submit, model: _vm.order } },
        [
          _c(
            "app-form-group",
            {
              staticClass: "text-center",
              attrs: { label: "ID заказа отчёта ЕГРН" }
            },
            [
              _c("app-input", {
                attrs: { size: "sm" },
                model: {
                  value: _vm.order.orderEgrnID,
                  callback: function($$v) {
                    _vm.$set(_vm.order, "orderEgrnID", $$v)
                  },
                  expression: "order.orderEgrnID"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            {
              staticClass: "text-center",
              attrs: { label: "ID заказа отчёта о переходе прав" }
            },
            [
              _c("app-input", {
                attrs: { size: "sm" },
                model: {
                  value: _vm.order.orderRightListID,
                  callback: function($$v) {
                    _vm.$set(_vm.order, "orderRightListID", $$v)
                  },
                  expression: "order.orderRightListID"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            {
              staticClass: "text-center",
              attrs: { label: "ID заказа отчёта о рисках" }
            },
            [
              _c("app-input", {
                attrs: { size: "sm", disabled: "" },
                model: {
                  value: _vm.order.orderRiskID,
                  callback: function($$v) {
                    _vm.$set(_vm.order, "orderRiskID", $$v)
                  },
                  expression: "order.orderRiskID"
                }
              })
            ],
            1
          ),
          _c("app-button", { attrs: { type: "submit", block: "" } }, [
            _vm._v("\n      Сохранить\n    ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }