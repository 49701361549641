var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-manual-update" },
    [
      _c("app-input", {
        staticClass: "w-100",
        on: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.onSave($event)
          }
        },
        model: {
          value: _vm.kadNumber,
          callback: function($$v) {
            _vm.kadNumber = $$v
          },
          expression: "kadNumber"
        }
      }),
      _c(
        "app-button",
        { attrs: { loading: _vm.loading }, on: { click: _vm.onSave } },
        [_c("i", { staticClass: "fas fa-save" })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }