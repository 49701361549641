var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "admin-page-head" },
        [
          _c(
            "app-button",
            { attrs: { size: "sm" }, on: { click: _vm.showDateFilter } },
            [_vm._v("\n      + Дата\n    ")]
          ),
          _c("div", { staticClass: "mt-3" }, [
            _c("b", [_vm._v("Дата")]),
            _vm._v(
              " с " +
                _vm._s(_vm._f("date")(_vm.date.from, "DD.MM.YYYY")) +
                " по " +
                _vm._s(_vm._f("date")(_vm.date.to, "DD.MM.YYYY")) +
                "\n    "
            )
          ])
        ],
        1
      ),
      _c("app-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          loading: _vm.loading,
          "item-key": "OrderID"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("td", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$helper_nameByProduct(row.name)) +
                      "\n      "
                  )
                ]),
                _c("td", [_vm._v(_vm._s(row.count))]),
                _c("td", [_vm._v(_vm._s(_vm._f("currency")(row.sum)))]),
                _c("td", [_vm._v(_vm._s(_vm.getPercent(row)) + " %")])
              ]
            }
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c("td", [_c("b", [_vm._v("ИТОГО")])]),
                _c("td", [_vm._v(_vm._s(_vm.totalCount))]),
                _c("td", [_vm._v(_vm._s(_vm._f("currency")(_vm.totalSum)))])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }