




























































































import Vue from 'vue';
import Component from 'vue-class-component';

import get from 'lodash/get';

import AppPledgeReportDetails from 'shared/components/RiskNew/PledgeReportDetails.vue';

@Component({
  components: {
  },

  props: {
    result: Object,
    isPhysical: Boolean,
  },
})
export default class AppPledgeReport extends Vue {
  result: any;
  isPhysical: boolean;

  showAll: boolean = false;

  get cases() {
    return get(this.result, 'result.sources');
  }

  get records() {
    if (!this.cases) {
      return null;
    }
    const result: any[] = [];
    this.cases.forEach((elem: any) => {
      let recordResult: any = {
        properties: [],
      };
      if (elem.record && elem.record.length) {
        elem.record.forEach((record: any) => {
          const obj = this.toObject(record.fields);
          if (obj.Type === 'properties') {
            recordResult.properties.push(obj);
          } else {
            recordResult = Object.assign(recordResult, obj);
          }
        });
        result.push(recordResult);
      }
    });
    return result;
  }

  toObject(arr: any[]) {
    const res: any = {};
    arr.forEach((elem) => {
      res[elem.field_name] = elem.field_value;
    });
    return res;
  }

  showDetails(item: any) {
    this.$dialog.open({
      component: AppPledgeReportDetails,

      props: {
        result: item,
      },
    });
  }
}
