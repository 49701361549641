import Vue from 'vue';
import Component from 'vue-class-component';
import { Provide } from 'vue-property-decorator';
// import { Prop } from 'vue-property-decorator';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
// import groupBy from 'lodash/groupBy';
// import find from 'lodash/find';
import mergeWith from 'lodash/mergeWith';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import keyBy from 'lodash/keyBy';
// import remove from 'lodash/remove';
// import sortBy from 'lodash/sortBy';
// import includes from 'lodash/includes';

import AppAttachmentsDialog from 'shared/components/Risk/AttachmentsDialog.vue';
import AppStatementNotesDialog from './StatementNotesDialog.vue';
import AppUpdateReportDialog from './UpdateReportDialog.vue';
import AppRiskReportForm from './RiskReportForm/RiskReportForm.vue';
import AppRiskReportPdfPreview from './RiskReportPdfPreview.vue';
import AppRiskAddConclusion from './RiskAddConclusion.vue';

interface OwnerItem {
  ownerId: string;
  owner: OwnerRequestBody;
  api: any;
}

@Component({
  components: { AppRiskReportForm },
  props: {
    userID: String,
    orderItemID: String,
  },
})
export default class AppRiskReport extends Vue {
  // props
  userID!: string;
  orderItemID!: string;

  // data()
  loading: boolean = true;
  reportSending: boolean = false;
  orderItem: any = null;
  report: RiskReport = null;
  kadastrNumber: string = '';
  objectInfo: any = null;
  attachments: AttachmentItem[] = [];
  owners: OwnerItem[] = [];
  order: AdminOrderInfo = null;

  @Provide()
  Report = { ownersById: {} };

  get products(): { [key: string]: Product } {
    return this.$store.state.productsByKey;
  }

  // TODO: надо получить эти notes из report
  get statementNotes(): { right: string; egrn: string } {
    const egrn = '';
    const right = '';

    return { egrn, right };
  }

  // lifecycle hooks
  async created() {
    try {
      await Promise.all([ this.handleRequest(), this.getReportInfo(), this.getAttachments() ]);
      if (this.objectInfo) {
        const objectInfoFull = await this.$api.object.InfoFull(this.objectInfo.Number);

        this.objectInfo = mergeWith(
          _.clone(this.objectInfo),
          objectInfoFull,
          (infoValue, fullInfoValue, key) => {
            if (key === 'UpdatedAt') {
              return fullInfoValue;
            }

            if (infoValue && !fullInfoValue) {
              return infoValue;
            }

            return fullInfoValue;
          },
        );
      }
      const [ order ] = await this.$api.admin.Orders<AdminOrderInfo[]>({
        userID: this.userID,
        orderID: this.orderItem.OrderID,
      });
      this.order = order;
    } catch (error) {
      console.error(error);
    }

    this.loading = false;
  }

  // methods
  addOwner() {
    const owner: OwnerRequestBody = {
      ownerType: 1,
      name: '',
      surname: '',
      patronymic: '',
      birthday: '',
      region: '',
      passport: '',
      inn: '',
    };

    this.owners.push({
      ownerId: uniqueId(),
      api: null,
      owner,
    });
  }

  async handleRequest() {
    const statementsData = await this.$api.risk.GetReportStatementsData(this.orderItemID);
    // const [ order ] = await this.$api.admin.Orders<AdminOrderInfo[]>({ orderID: statementsData.order_item.OrderID });

    const orderItem: RiskReportOrderItem = statementsData.order_item;
    const meta: RiskReportItemMetadata =
      orderItem && orderItem.ItemMetadata ? orderItem.ItemMetadata : {};

    this.objectInfo = _.clone(statementsData.object_info_full);

    if (this.objectInfo && _.isEmpty(this.objectInfo.Number)) {
      this.objectInfo.Number = meta.kadastr_number;
    }

    this.orderItem = statementsData.order_item;
    this.kadastrNumber = meta.kadastr_number;
  }

  async getReportInfo() {
    const data = await this.$api.risk.GetReport(this.orderItemID);

    if (!isEmpty(data)) {
      this.report = data;

      if (!isEmpty(data.owners)) {
        this.Report.ownersById = keyBy(this.report.owners, 'ownerId');

        this.owners = this.report.owners.map((item): OwnerItem => {
          return {
            ownerId: item.ownerId,
            api: item.irbisApi,
            owner: {
              ownerType: item.owner.ownerType,
              name: item.owner.name,
              surname: item.owner.surname,
              patronymic: item.owner.patronymic,
              birthday: item.owner.birthday,
              passport: item.owner.passport.full,
              region: item.owner.region,
              inn: get(item, 'owner.inn.number', ''),
              metadata: item.metadata,
            },
          };
        });
      }
    }
  }

  async getAttachments() {
    try {
      const attachments = await this.$api.upload.GetAttachmentList(this.orderItemID);
      this.attachments = attachments;
    } catch (error) {
      console.error(error);
    }
  }

  async createReport(owner: OwnerItemRequestBody) {
    try {
      await this.$api.risk.CreateReport({
        orderItemId: this.orderItemID,
        kadastrNumber: this.kadastrNumber,
        owner,
      });
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: this.$api.getErrorMessage(error) });
    }

    this.getReportInfo();
  }

  ownerItemUpdated() {
    this.getReportInfo();
  }

  removeReportItem(id: string, forceUpdate?: boolean) {
    this.owners = this.owners.filter(item => item.ownerId !== id);

    if (forceUpdate) {
      this.getReportInfo();
    }
  }

  createPdf() {
    const showOnlyUser = this.products[this.orderItem.ProductID].product_name === 'CheckOwner';

    this.$dialog.open({
      component: AppRiskReportPdfPreview,
      props: {
        report: this.report,
        objectInfo: this.objectInfo,
        onlyUser: showOnlyUser,
        hideConclusion: showOnlyUser,
      },
    });
  }

  async sendReportToUser() {
    if (!confirm('Вы уверены что хотите отправить отчет пользователю?')) {
      return;
    }

    try {
      this.reportSending = true;
      await this.$api.risk.CreatePDF(this.orderItemID);
      this.$noty.success({ text: 'Отчет отправлен' });
      this.orderItem.status = 'done';
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: 'Ошибка при отправке' });
    }

    this.reportSending = false;
  }

  showConclusion() {
    if (!this.report) {
      return;
    }

    this.$dialog.open({
      component: AppRiskAddConclusion,
      props: {
        report: this.report,
        onChange: () => this.getReportInfo(),
      },
    });
  }

  showAttachments() {
    this.$dialog.open({
      component: AppAttachmentsDialog,
      props: { attachments: this.attachments },
    });
  }

  showNotes() {
    this.$dialog.open({
      component: AppStatementNotesDialog,
      props: {
        notes: this.statementNotes,
      },
    });
  }

  goToOrder() {
    this.$router.push({
      name: 'admin-order-detail',
      params: {
        orderID: this.orderItem.OrderID,
        userID: this.userID,
      },
    });
  }

  openUpdateReportDialog() {
    this.$dialog.open({
      component: AppUpdateReportDialog,
      props: {
        onSubmit: this.updateReport,
        orders: this.order.OrderItem,
      },
    });
  }

  async updateReport(orderRiskID: string, orderEgrnID: string, orderRightListID: string) {
    try {
      await this.$api.risk.UpdateReport({ order_item_id: orderRiskID, egrn_object_order_item_id: orderEgrnID, egrn_right_list_order_item_id: orderRightListID });
      this.$noty.success({ text: 'Отчет обновлён' });
    } catch (error) {
      console.error(error);
      if (error.response.data.errorTxt === 'Invalid request') {
        this.$noty.error({ text: 'Ошибка в номере заказа' });
      } else {
        this.$noty.error({ text: 'Ошибка при оновлении' });
      }
    }
  }
}
