























































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import noop from 'lodash/noop';
import get from 'lodash/get';

@Component({
  props: {
    orderCreditRating: Object,
    orderScoreRating: Object,
    creditOrderId: String,
    owner: Object,
    openEditDialog: {
      type: Function,
      default: noop,
    },
  },
})
export default class CreditRatingInfo extends Vue {
  // data
  resultCreditRating: CreditRatingResult = null;
  resultScoreRating: CreditRatingResult = null;
  owner: LawInformationOwner;
  isNew: boolean = false;
  orderCreditRating: any;
  orderScoreRating: any;
  creditOrderId: string;
  get = get;
  creditTypes: SimpleObject[] = [
    {
      type: 1,
      name: 'Mortgage',
      ru_name: 'Ипотека',
    },
    {
      type: 2,
      name: 'ConsumerLoan',
      ru_name: 'Потребительский',
    },
    {
      type: 3,
      name: 'Microcredit',
      ru_name: 'МФО',
    },
    {
      type: 4,
      name: 'AutoLoan',
      ru_name: 'Автокредит',
    },
    {
      type: 5,
      name: 'CreditCard',
      ru_name: 'Кредитная карта',
    },
    {
      type: 6,
      name: 'Other',
      ru_name: 'Кредит связанный с финансовой деятельностью',
    },
    {
      type: 7,
      name: 'Other',
      ru_name: 'Кредит на образование',
    },
    {
      type: 8,
      name: 'Other',
      ru_name: 'Другой залоговый кредит',
    },
  ];

  overdueDates: SimpleObject[]= [
    {
      value: 0,
      ru_name: 'Новый кредит, оценка невозможна',
      textClass: 'text-warning',
      iconClass: 'fas fa-exclamation-circle fa-sm fa-fw',
    },
    {
      value: 1,
      ru_name: 'Оплата без просрочек',
      textClass: 'text-success',
      iconClass: 'fas fa-check-circle fa-sm fa-fw',
    },
    {
      value: 2,
      ru_name: 'Просрочка от 1 до 7 дней',
      textClass: 'text-warning',
      iconClass: 'fas fa-exclamation-circle fa-sm fa-fw',
    },
    {
      value: 3,
      ru_name: 'Просрочка от 8 до 29 дней',
      textClass: 'text-warning',
      iconClass: 'fas fa-exclamation-circle fa-sm fa-fw',
    },
    {
      value: 4,
      ru_name: 'Просрочка от 1 до 29 дней',
      textClass: 'text-warning',
      iconClass: 'fas fa-exclamation-circle fa-sm fa-fw',
    },
    {
      value: 5,
      ru_name: 'Просрочка от 30 до 59 дней',
      textClass: 'text-warning',
      iconClass: 'fas fa-exclamation-circle fa-sm fa-fw',
    },
    {
      value: 6,
      ru_name: 'Просрочка от 60 до 89 дней',
      textClass: 'text-warning',
      iconClass: 'fas fa-exclamation-circle fa-sm fa-fw',
    },
    {
      value: 7,
      ru_name: 'Просрочка от 90 до 119 дней',
      textClass: 'text-warning',
      iconClass: 'fas fa-exclamation-circle fa-sm fa-fw',
    },
    {
      value: 8,
      ru_name: 'Просрочка более 120 дней до 179 дней',
      textClass: 'text-danger',
      iconClass: 'fas fa-exclamation-circle fa-sm fa-fw',
    },
    {
      value: 9,
      ru_name: 'Просрочка более 180 дней',
      textClass: 'text-danger',
      iconClass: 'fas fa-exclamation-circle fa-sm fa-fw',
    },
    {
      value: 10,
      ru_name: 'Реструктуризация/изменения/дополнения к договору займа (кредита)',
      textClass: 'text-danger',
      iconClass: 'fas fa-exclamation-circle fa-sm fa-fw',
    },
    {
      value: 11,
      ru_name: 'Погашение за счет обеспечения',
      textClass: 'text-danger',
      iconClass: 'fas fa-exclamation-circle fa-sm fa-fw',
    },
    {
      value: 12,
      ru_name: 'Безнадёжный долг / передано на взыскание / пропущенный платеж',
      textClass: 'text-danger',
      iconClass: 'fas fa-exclamation-circle fa-sm fa-fw',
    },
    {
      value: 13,
      ru_name: 'Льготный период уплаты процентов по кредиту',
      textClass: 'text-danger',
      iconClass: 'fas fa-exclamation-circle fa-sm fa-fw',
    },
  ]

  get fullName(): string {
    if (this.isNew) {
      return this.owner.surname + ' ' + this.owner.first + ' ' + (this.owner.patronymic || '');
    } else {
      if (this.orderCreditRating.metadata.nbkiData.document.middleName) {
        return this.orderCreditRating.metadata.nbkiData.document.lastName + ' ' + this.orderCreditRating.metadata.nbkiData.document.firstName + ' ' + this.orderCreditRating.metadata.nbkiData.document.middleName;
      } else {
        return this.orderCreditRating.metadata.nbkiData.document.lastName + ' ' + this.orderCreditRating.metadata.nbkiData.document.firstName;
      }
    }
  }

  get passportIsEmpty(): boolean {
    if (this.isNew) {
      return !this.owner.passport;
    } else {
      if (this.orderCreditRating.metadata.nbkiData.document.number && this.orderCreditRating.metadata.nbkiData.document.series) {
        return false;
      }

      return true;
    }
  }

  get overdueAll() {
    return this.overdueDates.find((el) => el.value === this.resultCreditRating.report.credits.worstOverdueForAllCredits);
  }

  get overdueActive() {
    return this.overdueDates.find((el) => el.value === this.resultCreditRating.report.credits.worstOverdueForActiveCredits);
  }

  get emptyCreditTypes(): SimpleObject[] {
    return this.creditTypes.filter((creditType) => {
      return !this.resultCreditRating.report.credits.creditTypeInfos.some((info) => info.type === creditType.type);
    });
  }

  created() {
    if (this.orderCreditRating.task_result) {
      this.isNew = true;
      this.resultCreditRating = this.orderCreditRating.task_result.result;
      this.resultScoreRating = this.orderScoreRating.task_result.result;
    } else {
      this.resultCreditRating = this.orderCreditRating.metadata.nbkiData.result;
      this.resultScoreRating = this.orderScoreRating.metadata.nbkiData.result;
    }
    console.log(this.resultCreditRating, this.resultScoreRating);
  }
}
