var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "45",
        height: "45",
        viewBox: "0 0 56 56",
        fill: "none"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M27.5 35.7634C32.0537 35.7634 35.7452 32.0719 35.7452 27.5182C35.7452 22.9645 32.0537 19.2729 27.5 19.2729C22.9463 19.2729 19.2548 22.9645 19.2548 27.5182C19.2548 32.0719 22.9463 35.7634 27.5 35.7634Z",
          fill: "#DCE5F0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M38.9643 35.0934C40.4566 32.8579 41.252 30.23 41.25 27.5422C41.25 23.8923 39.8001 20.3919 37.2192 17.8111C34.6384 15.2302 31.1379 13.7803 27.488 13.7803C23.8381 13.7803 20.3377 15.2302 17.7569 17.8111C15.176 20.3919 13.7261 23.8923 13.7261 27.5422C13.724 30.0264 14.3956 32.4646 15.6692 34.5973C16.9429 36.7301 18.771 38.4776 20.959 39.6539C23.147 40.8302 25.613 41.3911 28.0945 41.2772C30.576 41.1632 32.9802 40.3785 35.0511 39.0066L44.888 48.8314C46.303 47.6677 47.6015 46.3692 48.7652 44.9541L38.9643 35.0934ZM27.5239 35.7755C25.8908 35.7755 24.2944 35.2912 22.9365 34.3839C21.5786 33.4766 20.5203 32.187 19.8953 30.6782C19.2703 29.1694 19.1068 27.5091 19.4254 25.9074C19.744 24.3057 20.5305 22.8344 21.6852 21.6796C22.84 20.5248 24.3113 19.7384 25.913 19.4198C27.5148 19.1012 29.175 19.2647 30.6838 19.8897C32.1926 20.5146 33.4822 21.573 34.3895 22.9309C35.2969 24.2887 35.7811 25.8852 35.7811 27.5183C35.7827 28.6031 35.5702 29.6775 35.1558 30.6801C34.7414 31.6826 34.1332 32.5935 33.3662 33.3605C32.5991 34.1276 31.6882 34.7358 30.6857 35.1502C29.6832 35.5646 28.6087 35.7771 27.5239 35.7755Z",
          fill: "#F3F4F5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M27.5 35.7634C32.0537 35.7634 35.7452 32.0719 35.7452 27.5182C35.7452 22.9645 32.0537 19.2729 27.5 19.2729C22.9463 19.2729 19.2548 22.9645 19.2548 27.5182C19.2548 32.0719 22.9463 35.7634 27.5 35.7634Z",
          fill: "#F3F4F5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M44.9119 48.8073C39.998 52.8188 33.8494 55.0098 27.506 55.0098C21.1626 55.0098 15.014 52.8188 10.1001 48.8073L19.9249 38.9824C22.1698 40.4737 24.805 41.2691 27.5 41.2691C30.1951 41.2691 32.8302 40.4737 35.0751 38.9824L44.9119 48.8073Z",
          fill: "#336FEE"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M27.5 35.7634C32.0537 35.7634 35.7452 32.0719 35.7452 27.5182C35.7452 22.9645 32.0537 19.2729 27.5 19.2729C22.9463 19.2729 19.2548 22.9645 19.2548 27.5182C19.2548 32.0719 22.9463 35.7634 27.5 35.7634Z",
          fill: "#336FEE"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M55.0479 27.5423C55.0598 33.8909 52.8642 40.0462 48.837 44.9541L39.0122 35.1173C40.492 32.8697 41.2709 30.2332 41.25 27.5423C41.25 23.8924 39.8001 20.3919 37.2192 17.8111C34.6383 15.2302 31.1379 13.7803 27.488 13.7803C23.8381 13.7803 20.3377 15.2302 17.7568 17.8111C15.176 20.3919 13.7261 23.8924 13.7261 27.5423C13.73 29.8142 14.2958 32.0499 15.3732 34.0502C16.4506 36.0505 18.0061 37.7531 19.901 39.0066L10.1001 48.8075C8.67844 47.6471 7.37562 46.3483 6.21082 44.9302C3.41984 41.5147 1.49059 37.4785 0.585585 33.1615C-0.319419 28.8445 -0.173664 24.3733 1.01057 20.1244C2.1948 15.8756 4.38283 11.9735 7.39033 8.74705C10.3978 5.52057 14.1367 3.06414 18.292 1.58469C22.4473 0.105236 26.8973 -0.3539 31.2671 0.245958C35.6369 0.845816 39.7986 2.4871 43.4015 5.03154C47.0045 7.57598 49.9431 10.9491 51.9699 14.8666C53.9967 18.7842 55.0524 23.1315 55.0479 27.5423Z",
          fill: "#DCE5F0"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }