









import Vue from 'vue';

export default Vue.extend({
  props: {
    orderItemId: String,
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async sendRefund() {
      if (this.loading) {
        return;
      }

      if (!confirm('Вернуть средства?')) {
        return;
      }

      this.loading = true;

      try {
        this.$noty.info({ text: 'Отправляем' });
        const { return_amount: amount } = await this.$api.admin.Refunds(this.orderItemId);
        this.$noty.success({ text: `Возвращено ${amount} руб.` });
      } catch (error) {
        console.error(error);
        this.$noty.error({ text: this.$api._getErrorMessage(error) });
      }

      this.loading = false;
    },
  },
});
