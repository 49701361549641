var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-report-item",
    {
      attrs: {
        title: "Сведения о банкротстве",
        icon: "university",
        status: _vm.status,
        images: _vm.images,
        opened: _vm.resultStatus === "found",
        color: _vm.map.color,
        "missing-requirements": _vm.missingRequirements,
        source: {
          domain: "bankrot.fedresurs.ru",
          link: "https://bankrot.fedresurs.ru/?attempt=1"
        }
      },
      scopedSlots: _vm._u(
        [
          _vm.resultStatus === "found" && _vm.checkBankrupt.length
            ? {
                key: "extra-content",
                fn: function() {
                  return [
                    _vm.checkBankrupt && _vm.checkBankrupt.length > 1
                      ? _c(
                          "div",
                          { staticClass: "text-right report-open-all" },
                          [
                            !_vm.isPdf
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "link",
                                    on: {
                                      click: function($event) {
                                        _vm.opened = !_vm.opened
                                      }
                                    }
                                  },
                                  [
                                    !_vm.opened
                                      ? [
                                          _vm._v(
                                            "\n          Раскрыть все\n        "
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            "\n          Скрыть все\n        "
                                          )
                                        ]
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _c("app-bankrupt-report-details", {
                      attrs: {
                        opened: _vm.opened || _vm.isPdf,
                        result: _vm.isPdf
                          ? _vm.slicedResult
                          : _vm.checkBankrupt,
                        "is-physical": _vm.isPhysical
                      }
                    }),
                    _vm.slicedResult.length < _vm.checkBankrupt.length &&
                    _vm.isPdf
                      ? _c(
                          "div",
                          { staticClass: "rc-modern-info-panel mt-3" },
                          [
                            _c(
                              "div",
                              { staticClass: "rc-modern-info-panel__wrapper" },
                              [
                                _c("div", [_c("app-warning-img")], 1),
                                _c("div", [
                                  _vm._v(
                                    "\n          Отображено " +
                                      _vm._s(_vm.slicedResult.length) +
                                      " дел из " +
                                      _vm._s(_vm.checkBankrupt.length) +
                                      ", полная информация доступна в веб версии отчета.\n        "
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      !["no data", "no task"].includes(_vm.resultStatus)
        ? [
            _c(
              "div",
              { staticClass: "report-item-modern__subtitle" },
              [
                !_vm.checkBankrupt
                  ? [
                      _vm._v(
                        "\n        В реестре банкротов отсутствует\n      "
                      )
                    ]
                  : [
                      _vm._v(
                        "\n        Числится в реестре банкротов\n        "
                      ),
                      _vm.checkBankrupt.length > 1
                        ? _c("span", [
                            _vm._v(
                              "\n          (найдено несколько совпадений по ФИО)\n        "
                            )
                          ])
                        : _vm._e()
                    ]
              ],
              2
            )
          ]
        : _vm.orderIsDone
        ? [
            _c("span", { staticClass: "text-warning" }, [
              _vm._v(" Недостаточно данных ")
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }