var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-report-item",
    {
      attrs: {
        title: "Сотрудники",
        icon: "address-book",
        opened: true,
        disabled: "",
        status: "done",
        color: "success"
      },
      scopedSlots: _vm._u(
        [
          _vm.staff.length
            ? {
                key: "extra-content",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "counter-agent-chart-buttons mt-3 mb-4" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "counter-agent-chart-buttons__button"
                          },
                          [
                            _c("div", [
                              _vm._v("\n          Число сотрудников\n        ")
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "counter-agent-chart-buttons__title"
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.currentStaff) +
                                    " человек\n        "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "counter-agent-chart-buttons__button"
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n          Средняя заработная плата\n        "
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "counter-agent-chart-buttons__title"
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm._f("currency")(_vm.averageSum)) +
                                    "\n        "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "counter-agent-chart-buttons__button"
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n          Фонд оплаты труда (общий)\n        "
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "counter-agent-chart-buttons__title"
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm._f("currency")(_vm.found)) +
                                    "\n        "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    !_vm.$isServer
                      ? _c("app-area-chart", {
                          attrs: {
                            data: _vm.bySumChartData,
                            "title-text": "dsdsdsd",
                            "show-value": true,
                            "range-description": _vm.chartRange,
                            "start-from": _vm.startFrom,
                            "point-format": _vm.pointFormat,
                            "y-labels-format": "{value} чел."
                          }
                        })
                      : _c("div", { staticClass: "rc-modern-info-panel" }, [
                          _c(
                            "div",
                            { staticClass: "rc-modern-info-panel__wrapper" },
                            [
                              _c("div", [_c("app-warning-img")], 1),
                              _c("div", [
                                _vm._v(
                                  "\n          Интерактивный график доступен в веб версии отчета\n        "
                                )
                              ])
                            ]
                          )
                        ])
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _c(
        "div",
        { staticClass: "report-item-modern__subtitle" },
        [
          !_vm.staff.length
            ? [_vm._v("\n      Информация о сотрудниках отсутствует\n    ")]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }