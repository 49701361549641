var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sud-container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showDetails,
            expression: "!showDetails"
          }
        ],
        staticClass: "sud-items"
      },
      [
        _c(
          "div",
          { staticClass: "popup__header" },
          [
            _c("div", { staticClass: "popup__title" }, [
              _vm._v("\n        Арбитражные дела\n      ")
            ]),
            _c("app-dialog-close")
          ],
          1
        ),
        _c("div", { staticClass: "px-3 pt-3 sud-details-filter" }, [
          _c(
            "div",
            { staticClass: "tabs position-relative" },
            _vm._l(_vm.typeFilters, function(item) {
              return _c(
                "a",
                {
                  key: item.text,
                  staticClass: "tabs__tab",
                  class: [
                    { "is-active": _vm.caseFilter === item.value },
                    { disabled: !_vm.filterCasesByType(item.value).length }
                  ],
                  on: {
                    click: function($event) {
                      return _vm.changeCaseFilter(item.value)
                    }
                  }
                },
                [
                  _vm._v("\n          " + _vm._s(item.text) + "\n          "),
                  _vm.filterCasesByType(item.value).length
                    ? _c("span", [
                        _vm._v(
                          "\n            (" +
                            _vm._s(_vm.filterCasesByType(item.value).length) +
                            ")\n          "
                        )
                      ])
                    : _vm._e()
                ]
              )
            }),
            0
          )
        ]),
        _c(
          "div",
          { staticClass: "popup__body" },
          [
            _c("div", { staticClass: "p-2" }, [
              _c(
                "div",
                { staticClass: "tabs rc-tabs-white position-relative" },
                _vm._l(_vm.personFilters, function(item) {
                  return _c(
                    "a",
                    {
                      key: item.text,
                      staticClass: "tabs__tab",
                      class: [
                        { "is-active": _vm.personFilter === item.value },
                        {
                          disabled: !_vm.filterCasesByPerson(item.value).length
                        }
                      ],
                      on: {
                        click: function($event) {
                          _vm.personFilter = item.value
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.text) + "\n            "
                      ),
                      _vm.filterCasesByPerson(item.value).length
                        ? _c("span", [
                            _vm._v(
                              "\n              (" +
                                _vm._s(
                                  _vm.filterCasesByPerson(item.value).length
                                ) +
                                ")\n            "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            ]),
            _vm._l(_vm.cases, function(item, i) {
              return _c(
                "div",
                {
                  key: item.case_id + i,
                  staticClass: "sud-preview-item",
                  on: {
                    click: function($event) {
                      return _vm.toggleDetails(item.case_id)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "sud-preview-item__body" }, [
                    _c("div", { staticClass: "sud-preview-item__name" }, [
                      _c("i", { staticClass: "fas fa-university" }),
                      _vm._v(" " + _vm._s(item.court_name_val) + "\n          ")
                    ]),
                    _c("div", { staticClass: "sud-preview-item__number" }, [
                      _vm._v(
                        "\n            Номер дела №" +
                          _vm._s(item.case_number) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._m(0, true)
                ]
              )
            })
          ],
          2
        )
      ]
    ),
    _vm.showDetails && _vm.details !== null
      ? _c("div", { staticClass: "sud-details" }, [
          _c(
            "div",
            {
              staticClass: "popup__header",
              on: {
                click: function($event) {
                  return _vm.toggleDetails()
                }
              }
            },
            [
              _c(
                "app-button",
                {
                  staticClass: "sud-details__back",
                  attrs: { variant: "light", size: "sm" }
                },
                [
                  _c("i", { staticClass: "fas fa-xs fa-arrow-left mr-1" }),
                  _vm._v("\n        Назад\n      ")
                ]
              )
            ],
            1
          ),
          _c("div", { staticClass: "popup__body" }, [
            _c("div", { staticClass: "sud-details__content" }, [
              _c("div", { staticClass: "sud-details__title" }, [
                _vm._v("\n          Детали\n        ")
              ]),
              _vm.details.case_id && _vm.details.case_number
                ? _c("div", { staticClass: "sud-details-item" }, [
                    _c("div", { staticClass: "sud-details-item__title" }, [
                      _vm._v("\n            Номер дела\n          ")
                    ]),
                    _c("div", { staticClass: "sud-details-item__value" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://kad.arbitr.ru/Card/" +
                              _vm.details.case_id,
                            target: "_blank"
                          }
                        },
                        [_vm._v(_vm._s(_vm.details.case_number))]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.details.case_type
                ? _c("div", { staticClass: "sud-details-item" }, [
                    _c("div", { staticClass: "sud-details-item__title" }, [
                      _vm._v("\n            Категория дела\n          ")
                    ]),
                    _c("div", { staticClass: "sud-details-item__value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.caseTypes[_vm.details.case_type]) +
                          "\n          "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.details.role
                ? _c("div", { staticClass: "sud-details-item" }, [
                    _c("div", { staticClass: "sud-details-item__title" }, [
                      _vm._v("\n            Роль\n          ")
                    ]),
                    _c("div", { staticClass: "sud-details-item__value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.roleTypes[_vm.details.role]) +
                          "\n          "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.details.case_date
                ? _c("div", { staticClass: "sud-details-item" }, [
                    _c("div", { staticClass: "sud-details-item__title" }, [
                      _vm._v("\n            Дата\n          ")
                    ]),
                    _c("div", { staticClass: "sud-details-item__value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.formatCaseDate(_vm.details.case_date)) +
                          "\n          "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.details.court_name_val
                ? _c("div", { staticClass: "sud-details-item" }, [
                    _c("div", { staticClass: "sud-details-item__title" }, [
                      _vm._v("\n            Судебный участок\n          ")
                    ]),
                    _c("div", { staticClass: "sud-details-item__value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.details.court_name_val) +
                          "\n          "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.details.address_val
                ? _c("div", { staticClass: "sud-details-item" }, [
                    _c("div", { staticClass: "sud-details-item__title" }, [
                      _vm._v("\n            Адрес\n          ")
                    ]),
                    _c("div", { staticClass: "sud-details-item__value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.details.address_val) +
                          "\n          "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.details.plaintiff.length
                ? _c(
                    "div",
                    { staticClass: "sud-details-item" },
                    [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v("\n            Истец\n          ")
                      ]),
                      _vm._l(_vm.details.plaintiff, function(item, i) {
                        return _c(
                          "div",
                          {
                            key: item.inn + i,
                            staticClass: "sud-details-item__value"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(i + 1) +
                                ". " +
                                _vm._s(item.name) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n            Адрес: " +
                                _vm._s(item.address) +
                                " "
                            ),
                            _c("br"),
                            _vm._v("\n            ИНН:\n            "),
                            item.inn
                              ? _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.inn) +
                                      "\n            "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "\n              нет данных\n            "
                                  )
                                ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _vm.details.respondent.length
                ? _c(
                    "div",
                    { staticClass: "sud-details-item" },
                    [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v("\n            Ответчик\n          ")
                      ]),
                      _vm._l(_vm.details.respondent, function(item, i) {
                        return _c(
                          "div",
                          {
                            key: item.inn + i,
                            staticClass: "sud-details-item__value"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(i + 1) +
                                ". " +
                                _vm._s(item.name) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n            Адрес: " +
                                _vm._s(item.address) +
                                " "
                            ),
                            _c("br"),
                            _vm._v("\n            ИНН:\n            "),
                            item.inn
                              ? _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.inn) +
                                      "\n            "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "\n              нет данных\n            "
                                  )
                                ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sud-preview-item__btn" }, [
      _vm._v("\n          детали "),
      _c("i", { staticClass: "fas fa-angle-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }