






















import Vue from 'vue';
import Component from 'vue-class-component';
import Quill from 'quill';
import _ from 'lodash';

const toolbarOptions: any = [
  [ { 'header': 2 } ], // custom button values

  [ 'bold', 'italic', 'underline', 'strike' ], // toggled buttons

  [ 'blockquote', { 'list': 'ordered' }, { 'list': 'bullet' } ],
  [ { 'indent': '-1' }, { 'indent': '+1' } ], // outdent/indent

  [ { 'align': [] } ],

  [ 'clean' ], // remove formatting button
];

@Component({
  popupOptions: {
    disableBackdrop: true,
  },

  props: {
    owner: Object,
    ownerId: String,
  },
})
export default class MetadataEditor extends Vue {
  private _editor: Quill;

  // props
  owner: OwnerRequestBody;
  ownerId: string;

  // data
  loading = false;

  mounted() {
    this._editor = new Quill(this.$refs.editor as Element, {
      placeholder: 'Текст...',
      modules: {
        toolbar: toolbarOptions,
      },
      theme: 'snow',
    });

    if (_.has(this.owner, 'metadata.advancedData')) {
      this._editor.pasteHTML(this.owner.metadata.advancedData);
    }
  }

  async save() {
    if (this.loading) {
      return;
    }

    this.loading = true;

    try {
      const metadata = this.owner.metadata || {};
      metadata.advancedData = this._editor.root.innerHTML;

      this.owner.metadata = _.cloneDeep(metadata);

      const owner = _.cloneDeep(this.owner);
      owner.metadata = JSON.stringify(owner.metadata);

      await this.$api.risk.UpdateOwnerItem({
        ownerId: this.ownerId,
        owner,
      });

      this.$noty.success({ text: 'Данные сохранены' });
      this.$dialog.close();
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: 'Ошибка при отправке' });
    }

    this.loading = false;
    console.log(this._editor.root.innerHTML);
  }
}
