














































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Component({
  props: {
    date: Object,
  },
})
export default class AdminPartnerSumStats extends Vue {
  // props
  date: StatsDateParams;

  // data()
  items: Admin.Stats.PartnerInfoItem[] = [];
  headers: TableHeaderItem[] = [
    {
      text: 'Партнёр',
    },
    {
      text: 'Награда',
    },
    {
      text: 'Сумма',
    },
    {
      text: 'Общая',
    },
  ];
  totalSum: number = 0;
  page: number = 1;
  hasNextPage: boolean = false;
  loading: boolean = true;

  @Watch('date', { deep: true })
  dateChanged() {
    this.items = [];
    this.request(1);
  }

  async created() {
    await this.request(1);

    this.loading = false;
  }

  async request(page: number) {
    this.page = page;

    const limit = 10;
    const offset: number = limit * Math.max(0, this.page - 1);

    this.loading = true;

    try {
      const data = await this.$api.admin.PartnerStats({ ...this.date, limit, offset });

      this.items = this.items.concat(data.partners);
      this.totalSum = data.total_sum;

      this.hasNextPage = this.items.length === Math.max(offset, limit);
    } catch (error) {
      console.error(error);
    }

    this.loading = false;
  }

  nextPage() {
    this.request(this.page + 1);
  }
}
