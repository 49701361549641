



























import Vue from 'vue';
import Component from 'vue-class-component';
import AppCriminalReportDetails from 'shared/components/RiskNew/CriminalReportDetails.vue';

@Component({
  components: {
  },
  props: {
    result: Object,
  },
})
export default class AppCriminalReport extends Vue {
  // props
  result: any[];

  showDetails() {
    this.$dialog.open({
      component: AppCriminalReportDetails,

      props: {
        result: this.result,
      },
    });
  }
}
