

























































































































































































import Component, { mixins } from 'vue-class-component';
import get from 'lodash/get';
import find from 'lodash/find';

import StatementRightListMixin from 'shared/mixins/statements/statementRightList.mixin';
import AppStatementTableRow from 'shared/components/EgrnObject/StatementTableRow.vue';
import { OBJECT_BY_TYPE } from 'shared/utils/constants/object-types';
import AppEcpSvg from 'shared/components/Static/ecp-print.vue';

@Component({
  components: { AppStatementTableRow, AppEcpSvg },

  props: {
    landCadInfo: Object,
    info: Object,
    objectKey: String,
    statements: Object,
    riskReport: Object,
  },
})
export default class RightListReport extends mixins(StatementRightListMixin) {
  landCadInfo: any;
  info: any;
  objectKey: any;
  statements: any;
  riskReport: any;
  get = get;

  get terms() {
    const rights = get(this.historyOwners, 'rights', []);
    const arr = [];
    if (this.checkRightType(rights, 'Общая совместная собственность')) {
      arr.push({
        Header: 'Общая совместная собственность',
        Text: 'имущество, которым одновременно владеют два и более лиц, причем их доли владения в общей собственности не определены, и считаются равными. Чаще всего, это случай совместной собственности супругов, или ситуация, когда квартира приватизировалась на всех членов семьи без определения долей.',
      });
    }
    if (this.checkRightType(rights, 'Собственность')) {
      arr.push({
        Header: 'Индивидуальная собственность',
        Text: 'имущество, которое находится в собственности у единоличного лица.',
      });
    }
    if (this.checkRightType(rights, 'Общая долевая собственность')) {
      arr.push({
        Header: 'Общая долевая собственность',
        Text: 'имущество, которым одновременно владеют два и более лиц (общая собственность), при этом определена конкретная доля каждого собственника. Распоряжение, владение, пользование как самим имуществом, так и доходами от его использования, несение расходов, продажа — все это производится в соответствии с размером доли собственника и по принятому соглашению с другими участниками.',
      });
    }
    return arr;
  }

  checkRightType(rights: any[], text: string) {
    return !!find(rights, (elem: any) => {
      const rightType = get(elem, 'right_data.right_type.value', '');
      return rightType.includes(text);
    });
  }

  objectType(value: any) {
    return OBJECT_BY_TYPE[value];
  }

  regName(owner: StatementRight): string | null {
    if (owner.registration) {
      if (owner.registration.name) {
        return owner.registration.name;
      }

      if (owner.registration.type) {
        let regName = '';

        if (owner.registration.type.type_name) {
          regName = owner.registration.type.type_name;
          if (owner.registration.share_description) {
            regName += `, ${owner.registration.share_description}`;
          } else if (owner.rightList && owner.rightList.registration.share_description) {
            regName += `, ${owner.rightList.registration.share_description}`;
          }
        }

        if (owner.registration.text) {
          regName = `${regName}, ${owner.registration.text}`;
        }

        if (owner.registration.reg_number) {
          regName = `${regName}, № ${owner.registration.reg_number}`;
        }

        if (owner.registration.reg_date) {
          regName = `${regName} от ${owner.registration.reg_date}`;
        }

        return regName;
      }
    }

    return null;
  }
}
