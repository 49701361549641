var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "popup__header" },
      [
        _c("div", { staticClass: "popup__title" }, [
          _vm._v("\n      Обновление\n    ")
        ]),
        _c("app-dialog-close")
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "popup__body" },
      [
        _c(
          "app-form",
          {
            attrs: {
              model: _vm.model,
              rules: _vm.validationRules,
              "request-handler": _vm.onSubmit
            },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function(ref) {
                  var loading = ref.loading
                  return [
                    _c(
                      "app-button",
                      {
                        attrs: {
                          size: "sm",
                          type: "submit",
                          loading: loading,
                          block: ""
                        }
                      },
                      [_vm._v("\n          Обновить\n        ")]
                    )
                  ]
                }
              }
            ])
          },
          [
            _c(
              "app-form-group",
              {
                attrs: {
                  label: "Наша минимальная цена",
                  "model-name": "base_cost"
                }
              },
              [
                _c("app-input", {
                  attrs: { type: "number", size: "sm" },
                  model: {
                    value: _vm.model.base_cost,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "base_cost", $$v)
                    },
                    expression: "model.base_cost"
                  }
                })
              ],
              1
            ),
            _c(
              "app-form-group",
              {
                attrs: {
                  label: "Коэф. (по умолчанию 0.1)",
                  "model-name": "precent"
                }
              },
              [
                _c("app-input", {
                  attrs: {
                    type: "number",
                    min: "0",
                    max: "1",
                    step: "0.1",
                    size: "sm"
                  },
                  model: {
                    value: _vm.model.precent,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "precent", $$v)
                    },
                    expression: "model.precent"
                  }
                })
              ],
              1
            ),
            _c(
              "app-form-group",
              {
                attrs: {
                  label: "Чистая прибыль партнёра",
                  "model-name": "amount"
                }
              },
              [
                _c("app-input", {
                  attrs: { type: "number", size: "sm" },
                  model: {
                    value: _vm.model.amount,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "amount", $$v)
                    },
                    expression: "model.amount"
                  }
                })
              ],
              1
            ),
            _c(
              "app-form-group",
              { attrs: { label: "Цена для пользователя" } },
              [
                _c("app-input", {
                  attrs: { type: "number", size: "sm", disabled: "" },
                  model: {
                    value: _vm.userPrice,
                    callback: function($$v) {
                      _vm.userPrice = $$v
                    },
                    expression: "userPrice"
                  }
                })
              ],
              1
            ),
            _c(
              "app-form-group",
              { attrs: { label: "Вознаграждение партнера" } },
              [
                _c("app-input", {
                  attrs: { type: "number", size: "sm", disabled: "" },
                  model: {
                    value: _vm.partnerReward,
                    callback: function($$v) {
                      _vm.partnerReward = $$v
                    },
                    expression: "partnerReward"
                  }
                })
              ],
              1
            ),
            _c(
              "app-form-group",
              { attrs: { label: "Чистая RC" } },
              [
                _c("app-input", {
                  attrs: { type: "number", size: "sm", disabled: "" },
                  model: {
                    value: _vm.cleanRC,
                    callback: function($$v) {
                      _vm.cleanRC = $$v
                    },
                    expression: "cleanRC"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }