var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-item-result report-item-result__result-wrapper" },
    _vm._l(_vm.result, function(item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "report-item-result__result mosgorsud-result"
        },
        [
          _c("app-report-collapse-item", {
            attrs: { opened: false, disabled: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("div", {
                        staticClass: "report-item-result__result-elem-image"
                      }),
                      item.billDate
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text-wrapper"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text"
                                },
                                [_vm._v("\n            Дата\n          ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("date")(
                                        item.billDate,
                                        "DD.MM.YYYY"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      item.amount
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text-wrapper"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text"
                                },
                                [_vm._v("\n            Сумма\n          ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("currency")(item.amount, {
                                        precision: 2
                                      })
                                    ) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      item.billName
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text-wrapper"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text"
                                },
                                [
                                  _vm._v(
                                    "\n            Тип задолженности\n          "
                                  )
                                ]
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.billName) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }