






























import Component, { mixins } from 'vue-class-component';
import DataTableMixin from '@/core/mixins/data-table.mixin';
@Component
export default class AdminBills extends mixins(DataTableMixin) {
  headers: TableHeaderItem[] = [
    {
      text: 'Пользователь',
      align: 'left',
    },
    {
      text: '№ счета',
      align: 'left',
    },
    {
      text: 'ИНН',
      align: 'left',
    },
    {
      text: 'Дата',
      align: 'left',
    },
    {
      text: 'Сумма',
      align: 'left',
    },
  ];

  handleRequest(offset: number, limit: number) {
    return this.$api.admin.GetBills({ offset, limit });
  }

  async goToUserPage(email: string) {
    const user: User[] = await this.$api.admin.SearchUsers({ email });
    this.$router.push({
      name: 'admin-user-orders', params: { userID: user[0].id },
    });
  }
}
