var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-item-result report-item-result__result-wrapper" },
    _vm._l(_vm.records, function(item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "report-item-result__result mosgorsud-result"
        },
        [
          _c("app-report-collapse-item", {
            attrs: { opened: _vm.opened },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function() {
                    return [
                      item.referenceNumber && item.registerDate
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text-wrapper"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text"
                                },
                                [
                                  _vm._v(
                                    "\n            Дата/номер залога\n          "
                                  )
                                ]
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("date")(
                                        item.registerDate,
                                        "DD.MM.YYYY"
                                      )
                                    )
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.referenceNumber) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      item.referenceStatus
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text-wrapper"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text"
                                },
                                [_vm._v("\n            Статус\n          ")]
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.referenceStatus) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      item.pledgors_name
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text-wrapper"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text"
                                },
                                [
                                  _vm._v(
                                    "\n            Залогодержатель\n          "
                                  )
                                ]
                              ),
                              _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.pledgors_name) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "content",
                  fn: function() {
                    return [
                      item.properties && item.properties.length
                        ? _vm._l(item.properties, function(elem, i) {
                            return _c(
                              "div",
                              {
                                key: elem.properties_description,
                                staticClass:
                                  "report-item-result__content-section"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__content-section-title"
                                  },
                                  [
                                    _c("h4", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(i + 1) +
                                          ". " +
                                          _vm._s(elem.properties_typetext) +
                                          "\n              "
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__content-list"
                                  },
                                  [
                                    elem.properties_vin
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-title"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  VIN\n                "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-result"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      elem.properties_vin
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__content-list"
                                  },
                                  [
                                    elem.referenceNumber
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-title"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  Номер возникновения права\n                "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-result"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      elem.referenceNumber
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__content-list"
                                  },
                                  [
                                    elem.properties_status
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-title"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  Статус\n                "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-result"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      elem.properties_status
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__content-list"
                                  },
                                  [
                                    elem.properties_description
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-title"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  Описание\n                "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "report-item-result__content-list-elem-result"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      elem.properties_description
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "report-item-result__content-section" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__content-section-title"
                            },
                            [
                              _c("h4", [
                                _vm._v(
                                  "\n              Залогодатель\n            "
                                )
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "report-item-result__content-list" },
                            [
                              item.pledgors_typetext
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.pledgors_typetext) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.pledgors_name) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.pledgors_birth
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Дата рождения\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.pledgors_birth) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.pledgors_region
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Регион\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.pledgors_region) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "report-item-result__content-section" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__content-section-title"
                            },
                            [
                              _c("h4", [
                                _vm._v(
                                  "\n              Залогодержатель\n            "
                                )
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "report-item-result__content-list" },
                            [
                              item.pledgees_typetext
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.pledgees_typetext) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          item.pledgees_name
                                            ? [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(item.pledgees_name) +
                                                    "\n                "
                                                )
                                              ]
                                            : item.pledgees_orgname
                                            ? [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      item.pledgees_orgname
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.pledgees_inn
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                ИНН\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.pledgees_inn) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.pledgees_regnumber
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                ОГРН\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.pledgees_regnumber) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.pledgees_region
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Регион\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.pledgees_region) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "report-item-result__content-section" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__content-section-title"
                            },
                            [
                              _c("h4", [
                                _vm._v("\n              Договор\n            ")
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "report-item-result__content-list" },
                            [
                              item.referenceNumber
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Номер уведомления о возникновении залога\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.referenceNumber) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.referenceStatus
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Статус\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.referenceStatus) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.doc_num
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Номер договора\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.doc_num) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.doc_name
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                Наименование договора\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.doc_name) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }