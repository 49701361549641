import Vue from 'vue';
import Component from 'vue-class-component';
import flatpickr from 'flatpickr';

import AppConfirmDialog from '@/components/layout/Dialogs/ConfirmationDialog.vue';
import AppAdminControlForm from './AdminControlForm.vue';
import format from 'date-fns/format';
import addDays from 'date-fns/add_days';

@Component({
  components: { AppAdminControlForm },
})
export default class AdminControl extends Vue {
  // props

  // data()
  loadings: SimpleObject<boolean> = {};
  datePickerOptions: flatpickr.Options.Options = {
    maxDate: new Date(),
  }
  date: StatsDateParams = {
    from: format(addDays(new Date(), -1), 'YYYY-MM-DD'),
    to: format(new Date(), 'YYYY-MM-DD'),
  };

  kadnetDate: StatsDateParams = {
    from: format(addDays(new Date(), -1), 'YYYY-MM-DD'),
    to: format(new Date(), 'YYYY-MM-DD'),
  };
  jobs: any[] = null;
  // computed

  // lifecycle hooks
  async created() {
    const data = await this.$api.reset.GetJobs();
    this.jobs = data;
  }

  // methods
  resetRosreestr() {
    this.$dialog.open({
      component: AppConfirmDialog,
      props: {
        title: 'Сбросить поиск?',
        confirmBtn: {
          text: 'Да',
          variant: 'danger',
        },
        onConfirm: async () => {
          try {
            await this.$api.reset.RosreestrSearch();
            this.$noty.info({ text: 'OK' });
          } catch (error) {
            this.$noty.error({ text: 'Произошла ошибка' });
          }
        },
      },
    });
  }

  resetBlockedIP() {
    this.$dialog.open({
      component: AppConfirmDialog,
      props: {
        title: 'Сбросить заблокированные ip?',
        confirmBtn: {
          text: 'Да',
          variant: 'danger',
        },
        onConfirm: async () => {
          try {
            await this.$api.reset.BlockedIP();
            this.$noty.info({ text: 'OK' });
          } catch (error) {
            this.$noty.error({ text: 'Произошла ошибка' });
          }
        },
      },
    });
  }

  async manualUpdateAuto() {
    try {
      this.toggleLoading('manualUpdate');
      await this.$api.reset.ManualUpdateAuto(this.date);
      this.$noty.info({ text: 'OK' });
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: this.$api._getErrorMessage(error) });
    }
    this.toggleLoading('manualUpdate');
  }

  async resetKadnet() {
    try {
      this.toggleLoading('kadnetUpdate');
      await this.$api.reset.KadnetManualReOrder(this.kadnetDate);
      this.$noty.info({ text: 'OK' });
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: this.$api._getErrorMessage(error) });
    }

    this.toggleLoading('kadnetUpdate');
  }

  toggleLoading(name: string) {
    if (!(name in this.loadings)) {
      this.$set(this.loadings, name, false);
    }

    this.loadings[name] = !this.loadings[name];
  }
}
