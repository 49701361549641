












































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import format from 'date-fns/format';
import get from 'lodash/get';

@Component({
  components: { AppReportItem },
  props: {
    report: Object,
    status: String,
    images: Array,
    orderIsDone: Boolean,
    missingRequirements: Array,
    openAddFieldDialog: Function,
    updateMap: Function,
    task: Object,
    isPhysical: Boolean,
    onlyOwners: Boolean,
    map: Object,
  },
})
export default class AppPassportReport extends Vue {
  // props
  missingRequirements: any[];
  report: any;
  status: string;
  images: any[];
  orderIsDone: boolean;
  task: any;
  isPhysical: boolean;
  onlyOwners: boolean;
  openAddFieldDialog: (val: string) => any;
  updateMap: (taskName: string, field: string, value: any) => void;
  map: any;

  // data
  showMessage: boolean = false;
  resultStatusTrace: any = null;

  created() {
    this.resultStatusTrace = this.resultText;
  }

  updateColor(color: string) {
    this.updateMap('passport', 'color', color);
  }

  get fioValidCheck() {
    const innTask = this.getTask('owner_check_individual_inn');
    return (
      innTask &&
      innTask.task_result.inn &&
      innTask.task_result.text !== 'Добавлен пользователем'
    );
  }

  get resultText() {
    const gosuslugi = this.gosuslugiStatus;
    let text = 'В базе недействительных паспортов МВД ';
    if (
      gosuslugi !== 'error' &&
      this.report.owner.passport
    ) {
      switch (gosuslugi) {
        case 'valid':
          this.updateColor('success');
          text += `не числится на ${format(this.task.in_progress_started_time, 'DD.MM.YYYY')}`;
          break;
        case 'invalid':
          this.updateColor('danger');
          text += `числится на ${format(this.task.in_progress_started_time, 'DD.MM.YYYY')}`;
          break;
        case 'notFound':
          this.updateColor('warning');
          text += 'Данные не найдены. Вероятно наличие ошибки в введенных данных (в ФИО или серии и номере паспорта)';
      }
    } else if (this.task && this.report.owner.passport) {
      this.updateColor('warning');
      this.showMessage = true;
      if (this.task.task_result.result === 'valid') {
        text += 'не числится на 20.07.2023';
      } else {
        text += 'числится на 20.07.2023';
      }
    } else {
      text = '';
    }
    return text;
  }

  get gosuslugiStatus() {
    return get(this.task, 'task_result.gosuslugiPassport');
  }

  getTask(val: string) {
    return this.report.tasks.find((elem: any) => elem.task_type === val);
  }
}
