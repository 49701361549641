






































































































































































































































































































import Component, { mixins } from 'vue-class-component';
import { ENCUMBRANCE_BY_TYPE } from 'shared/utils/constants/encumbrance-types';

import ProductEmitter, { ProductEmitterEvents } from 'shared/components/Product/productEmitter';
import AppStatementItem from './StatementItem.vue';
import StatementEgrnMixin from 'shared/mixins/statements/statementEgrn.mixin';
import get from 'lodash/get';

@Component({
  components: { AppStatementItem },
  props: {
    showPayInfo: Boolean,
    opened: {
      type: Boolean,
      default: false,
    },
    divergence: {
      type: Boolean,
      default: false,
    },
  },
})
export default class AppStatementRestrictions extends mixins(StatementEgrnMixin) {
  // props
  showPayInfo!: boolean;
  opened: boolean;
  divergence: boolean;

  // data()
  showCount: number = 50;
  get = get;

  // computed
  get isDisabled(): boolean {
    return (
      !this.status.isPurchased ||
      !this.status.isCompleted ||
      this.noRestrictions
    );
  }

  getRestrictionType(encumbranceType: string): string {
    return ENCUMBRANCE_BY_TYPE[encumbranceType];
  }

  // lifecycle hooks

  // methods
  showDialog() {
    ProductEmitter.emit(ProductEmitterEvents.showDialog, 'RiskAssessment');
  }

  getEncumbranceType(e: StatementEncumbrance) {
    if (e.registration && e.registration.type && e.registration.type.type_name) {
      return e.registration.type.type_name;
    } else if (e.type && e.type.type_name) {
      return e.type.type_name;
    }

    return e.name || e.owners_restriction_in_favorem;
  }
}
