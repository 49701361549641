var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-report-item",
    {
      attrs: {
        title: "Судебные акты Российской Федерации",
        icon: "gavel",
        status: _vm.status,
        images: _vm.images,
        "missing-requirements": _vm.missingRequirements,
        color: _vm.map.color,
        opened: _vm.resultStatus === "found",
        source: {
          domain: "sudrf.ru",
          link: "https://bsr.sudrf.ru/"
        }
      },
      scopedSlots: _vm._u(
        [
          _vm.resultStatus === "found"
            ? {
                key: "extra-content",
                fn: function() {
                  return [
                    _c("div", { staticClass: "report-item-result__graph" }, [
                      _c(
                        "div",
                        { staticClass: "report-item-result__graph-filter" },
                        [
                          _c("div", [
                            _c("h4", [
                              _vm._v("\n            Совпадения:\n          ")
                            ])
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__graph-filter-radio"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__graph-filter-radio-btn",
                                  attrs: {
                                    active: _vm.currentFilter === "all",
                                    disabled: !_vm.casesCount
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.currentFilter = "all"
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Все (" +
                                      _vm._s(_vm.casesCount) +
                                      ")\n          "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__graph-filter-radio-btn",
                                  attrs: {
                                    active: _vm.currentFilter === "sudRf",
                                    disabled: !_vm.sudRf.length
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.currentFilter = "sudRf"
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            СудРФ (" +
                                      _vm._s(_vm.sudRf.length) +
                                      ")\n          "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__graph-filter-radio-btn",
                                  attrs: {
                                    active: _vm.currentFilter === "sudAct",
                                    disabled: !_vm.sudactCases.length
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.currentFilter = "sudAct"
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            СудАкт (" +
                                      _vm._s(_vm.sudactCases.length) +
                                      ")\n          "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__graph-filter-radio-btn",
                                  attrs: {
                                    active: _vm.currentFilter === "mosgorsud",
                                    disabled: !_vm.mosgorsudCases.length
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.currentFilter = "mosgorsud"
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Мосгорсуд (" +
                                      _vm._s(_vm.mosgorsudCases.length) +
                                      ")\n          "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _c("div", { staticClass: "rc-modern-info-panel" }, [
                        _c(
                          "div",
                          { staticClass: "rc-modern-info-panel__wrapper" },
                          [
                            _c("div", [_c("app-warning-img")], 1),
                            _c("div", [
                              _vm._v(
                                "\n            Возможны однофамильцы\n          "
                              )
                            ]),
                            _c("div", { staticClass: "ml-auto" }, [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tippy",
                                      rawName: "v-tippy",
                                      value: {
                                        placement: "top-end",
                                        content:
                                          "В отчете могут быть указаны дела не только проверяемого лица из-за отсутствия данных о дате рождения в источниках. При редком ФИО риск минимален. Чтобы сузить количество дел укажите регион."
                                      },
                                      expression:
                                        "{\n                placement: 'top-end',\n                content: 'В отчете могут быть указаны дела не только проверяемого лица из-за отсутствия данных о дате рождения в источниках. При редком ФИО риск минимален. Чтобы сузить количество дел укажите регион.',\n              }"
                                    }
                                  ]
                                },
                                [_c("i", { staticClass: "fas fa-info-circle" })]
                              )
                            ])
                          ]
                        )
                      ])
                    ]),
                    _vm.casesCount > 1
                      ? _c(
                          "div",
                          { staticClass: "text-right report-open-all mt-3" },
                          [
                            !_vm.isPdf
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "link",
                                    on: {
                                      click: function($event) {
                                        _vm.opened = !_vm.opened
                                      }
                                    }
                                  },
                                  [
                                    !_vm.opened
                                      ? [
                                          _vm._v(
                                            "\n          Раскрыть все\n        "
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            "\n          Скрыть все\n        "
                                          )
                                        ]
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm.sudRf && _vm.sudRf.length && _vm.checkFilter("sudRf")
                      ? _c("app-sud-rf-details", {
                          attrs: {
                            opened: _vm.opened || _vm.isPdf,
                            owner: _vm.report.owner,
                            result: _vm.isPdf
                              ? _vm.slicedCases.sudRf
                              : _vm.sudRf
                          }
                        })
                      : _vm._e(),
                    _vm.haveMosgorsud && _vm.checkFilter("mosgorsud")
                      ? _c("app-mosgorsud-details", {
                          attrs: {
                            opened: _vm.opened || _vm.isPdf,
                            owner: _vm.report.owner,
                            result: _vm.isPdf
                              ? _vm.slicedCases.mosgorsud
                              : _vm.mosgorsudCases
                          }
                        })
                      : _vm._e(),
                    _vm.haveSudact && _vm.checkFilter("sudAct")
                      ? _c("app-sud-act-details", {
                          attrs: {
                            opened: _vm.opened || _vm.isPdf,
                            owner: _vm.report.owner,
                            result: _vm.isPdf
                              ? _vm.slicedCases.sudact
                              : _vm.sudactCases
                          }
                        })
                      : _vm._e(),
                    _vm.slicedCases.count < _vm.casesCount && _vm.isPdf
                      ? _c(
                          "div",
                          { staticClass: "rc-modern-info-panel mt-3" },
                          [
                            _c(
                              "div",
                              { staticClass: "rc-modern-info-panel__wrapper" },
                              [
                                _c("div", [_c("app-warning-img")], 1),
                                _c("div", [
                                  _vm._v(
                                    "\n          Отображено " +
                                      _vm._s(_vm.slicedCases.count) +
                                      " дел из " +
                                      _vm._s(_vm.casesCount) +
                                      ", полная информация доступна в веб версии отчета.\n        "
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm.task
        ? _c(
            "div",
            { staticClass: "report-item-modern__subtitle-wrapper" },
            [
              _vm.resultStatus === "found"
                ? [
                    _c(
                      "div",
                      { staticClass: "report-item-modern__subtitle" },
                      [
                        _vm._v(
                          "\n        В базе ГАС «Правосудие» по " +
                            _vm._s(_vm.naming) +
                            "\n        "
                        ),
                        _vm.report.owner.region
                          ? [
                              _vm._v(
                                '\n          в субъекте РФ "' +
                                  _vm._s(_vm.regionName) +
                                  '"\n        '
                              )
                            ]
                          : _vm._e(),
                        _vm._v(
                          "\n        найдено " +
                            _vm._s(_vm.casesCount) +
                            " совпадений\n      "
                        )
                      ],
                      2
                    )
                  ]
                : [
                    _vm._v(
                      "\n      В базе ГАС «Правосудие» совпадений по " +
                        _vm._s(_vm.naming) +
                        "\n      не найдены\n    "
                    )
                  ]
            ],
            2
          )
        : _vm.orderIsDone
        ? [
            _c("span", { staticClass: "text-warning" }, [
              _vm._v(" Недостаточно данных ")
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }