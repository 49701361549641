
























import Vue from 'vue';
import Component from 'vue-class-component';

import AppEgrnObjectReport from 'shared/components/EgrnObject/EgrnObjectReport.vue';

@Component({
  popupOptions: {
    name: 'popup_full',
  },

  components: { AppEgrnObjectReport },

  props: {
    landCadInfo: Object,
    objectInfo: Object,
    objectKey: String,
    statements: Object,
    report: Object,
  },
})
export default class EgrnObjectPdfPreview extends Vue {
  print() {
    window.print();
  }

  fetchRisk() {
    return true;
  }
}
