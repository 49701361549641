


































































































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  popupOptions: {
    name: 'popup_side popup_sud-details',
  },

  props: {
    result: Array,
  },
})
export default class AppFsinReportDetails extends Vue {
  // props
  result: any;
}
