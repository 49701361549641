var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "admin-page-head" },
        [
          _c(
            "app-button",
            { attrs: { size: "sm" }, on: { click: _vm.openBillForm } },
            [_vm._v("\n      Выставить счёт\n    ")]
          )
        ],
        1
      ),
      _c("app-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          loading: _vm.loading,
          pagination: _vm.pagination
        },
        on: { "page-changed": _vm.pageChanged },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("td", { staticStyle: { "max-width": "170px" } }, [
                  _c("div", { staticClass: "text-truncate" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm._f("date")(row.Created, "D MMMM YYYY г.")) +
                        "\n        "
                    )
                  ])
                ]),
                _c("td", [
                  _c("div", { staticClass: "text-truncate" }, [
                    _vm._v("\n          " + _vm._s(row.Number) + "\n        ")
                  ])
                ]),
                _c("td", [
                  _c("div", { staticClass: "text-truncate" }, [
                    _vm._v("\n          " + _vm._s(row.INN) + "\n        ")
                  ])
                ]),
                _c("td", [
                  _vm._v("\n        " + _vm._s(row.UserEmail) + "\n      ")
                ]),
                _c("td", [_vm._v("\n        " + _vm._s(row.Sum) + "\n      ")])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }