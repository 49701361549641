var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "body" }, [
    _vm.title
      ? _c("h5", { staticClass: "text-center mb-3" }, [
          _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
        ])
      : _vm._e(),
    _vm.body
      ? _c("p", {
          staticClass: "text-center",
          domProps: { innerHTML: _vm._s(_vm.body) }
        })
      : _vm._e(),
    _c(
      "div",
      { staticClass: "d-flex btns" },
      [
        _c(
          "app-button",
          {
            attrs: { variant: _vm.confirmBtn.variant, loading: _vm.loading },
            on: { click: _vm.confirm }
          },
          [_vm._v("\n      " + _vm._s(_vm.confirmBtn.text) + "\n    ")]
        ),
        _c(
          "app-button",
          {
            attrs: { variant: _vm.cancelBtn.variant },
            on: { click: _vm.cancel }
          },
          [_vm._v("\n      " + _vm._s(_vm.cancelBtn.text) + "\n    ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }