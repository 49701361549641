var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c("div", { staticClass: "mb-3" }, [
        _vm._v("\n    Выберите роль пользователя\n  ")
      ]),
      _c(
        "app-form",
        {
          attrs: {
            model: _vm.model,
            rules: _vm.validationRules,
            "request-handler": _vm.onSubmit
          },
          scopedSlots: _vm._u([
            {
              key: "button",
              fn: function(ref) {
                var loading = ref.loading
                return [
                  _c(
                    "app-button",
                    { attrs: { type: "submit", loading: loading, block: "" } },
                    [_vm._v("\n        Поменять\n      ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c("app-form-group", { attrs: { "model-name": "person_role" } }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.model.person_role,
                    expression: "model.person_role",
                    modifiers: { number: true }
                  }
                ],
                staticClass: "custom-select custom-select-lg",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return _vm._n(val)
                      })
                    _vm.$set(
                      _vm.model,
                      "person_role",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", { domProps: { value: null } }, [
                  _vm._v("\n          Выберите роль\n        ")
                ]),
                _vm._l(_vm.roleNames, function(roleName, roleId) {
                  return _c(
                    "option",
                    { key: roleId, domProps: { value: roleId } },
                    [_vm._v("\n          " + _vm._s(roleName) + "\n        ")]
                  )
                })
              ],
              2
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }