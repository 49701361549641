var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "risk-report-form" },
    [
      _c("div", { staticClass: "risk-report-form__head" }, [
        _c("h6", { staticClass: "m-0" }, [
          _vm._v("\n      Собственник "),
          _vm.api && _vm.api.id && _vm.isAdmin
            ? _c(
                "a",
                {
                  staticClass: "small ml-3",
                  attrs: {
                    target: "_blank",
                    href:
                      "https://irbis.in/ru/base/-/services/report/v2/" +
                      _vm.api.id +
                      "/people"
                  }
                },
                [_vm._v("api profile")]
              )
            : _vm._e()
        ]),
        _vm.statusText
          ? _c("div", [
              _c(
                "span",
                {
                  staticClass: "mr-2 text-muted",
                  on: { click: _vm.showUpdateStatus }
                },
                [_c("i", { staticClass: "fas fa-tasks" })]
              ),
              _c(
                "span",
                {
                  staticClass: "risk-report-form__status font-weight-500",
                  class: _vm.statusClassName
                },
                [_vm._v("\n        " + _vm._s(_vm.statusText) + "\n      ")]
              )
            ])
          : _vm._e()
      ]),
      _c(
        "app-form",
        {
          staticClass: "risk-report-form__wrap",
          attrs: {
            "request-handler": _vm.onSubmit,
            model: _vm.item,
            rules: _vm.validationConfig
          },
          scopedSlots: _vm._u([
            {
              key: "button",
              fn: function(ref) {
                var loading = ref.loading
                return [
                  _c(
                    "div",
                    { staticClass: "risk-report-form__button w-100 mt-2" },
                    [
                      !_vm.reportItem
                        ? _c(
                            "app-button",
                            {
                              attrs: {
                                type: "submit",
                                loading: loading,
                                size: "sm"
                              }
                            },
                            [_vm._v("\n          Создать отчет\n        ")]
                          )
                        : _vm._e(),
                      _vm.reportItem && !_vm.isProcessing
                        ? _c(
                            "app-button",
                            {
                              attrs: {
                                variant: "primary-light",
                                size: "sm",
                                loading: _vm.btnLoading
                              },
                              on: { click: _vm.updateUserMetadata }
                            },
                            [
                              _vm._v(
                                "\n          Сохранить изменения\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      !_vm.isProcessing
                        ? _c(
                            "app-button",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                loading: _vm.btnLoading,
                                variant: "light",
                                size: "sm"
                              },
                              on: { click: _vm.showMetadataEditor }
                            },
                            [
                              _vm._v(
                                "\n          Дополнительные поля\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      !_vm.isProcessing
                        ? _c(
                            "app-button",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                loading: _vm.btnLoading,
                                variant: "danger-light",
                                size: "sm"
                              },
                              on: { click: _vm.removeItem }
                            },
                            [_vm._v("\n          Удалить\n        ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c("AppOwnerFormItemBase", {
            attrs: { owner: _vm.item, "is-editing": _vm.reportItem != null }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }