import Component, { mixins } from 'vue-class-component';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import property from 'lodash/property';
import filter from 'lodash/filter';
import size from 'lodash/size';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';

import StatementBaseMixin from './statementBase.mixin';

@Component
export default class StatementEgrnMixin extends mixins(StatementBaseMixin) {
  // Текущие собственники из Отчеты ЕГРН об объекте
  get currentOwners(): StatementCurrentOwners | null {
    const egrn = cloneDeep(this.statements.egrn);
    const rights = get(egrn, 'data.extract_object.rights');
    if (rights && Array.isArray(rights)) {
      const entities = this._getAllEntities(rights);

      if (entities && entities.length) {
        const names = this._mapEntityNames(entities);

        return {
          items: entities,
          byDate: groupBy(entities, 'registration.reg_date'),
          names,
          rights,
        };
      }
    }

    return null;
  }

  get ownersCount(): number {
    let count = size(get(this.currentOwners, 'items'));
    // в ownerlessData хранится информация о собственнике бесхозного обьекта
    if (this.ownerlessData && this.ownerlessData.authority_name) {
      count++;
    }
    return count;
  }

  get ownerlessData() {
    return get(this.statements, 'egrn.data.ownerless_right_record.ownerless_right_data');
  }

  get ownerlessInfo() {
    return get(this.statements, 'egrn.data.ownerless_right_record.record_info');
  }

  get status(): Pick<StatementItem, 'isCompleted' | 'isPurchased'> {
    return omit(this.statements.egrn, 'data');
  }

  // /** @deprecated */
  // get currentOwnersByDate(): { [date: string]: StatementEntity[] } | null {
  //   if (this.currentOwners) {
  //     return groupBy(this.currentOwners, 'registration.reg_date');
  //   }

  //   return null;
  // }

  // Обременения из Отчеты ЕГРН об объекте
  get encumbrance(): StatementEncumbrance[] | null {
    const rights: StatementRight[] = get(this.statements.egrn, 'data.extract_object.rights');

    let encumbrance: StatementEncumbrance[] = flatten(
      filter(
        map(rights, property('encumbrance')),
        null,
      ),
    );

    if (!encumbrance || !encumbrance.length) {
      encumbrance = get(this.statements.egrn, 'data.extract_object.encumbrance');
    }

    if (encumbrance && encumbrance.length) {
      encumbrance.forEach(e => {
        if (e.entities) {
          e.entities.forEach(this._checkEmptyContentInEntity);
        }
      });

      return encumbrance;
    }

    return null;
  }

  //
  get noEncumbrance(): boolean {
    const rights: StatementRight[] = get(this.statements.egrn, 'data.extract_object.rights');

    if (rights) {
      const allRightsNoEncumbrance = rights.every(r => r.no_encumbrance === 'не зарегистрировано');

      return allRightsNoEncumbrance && !this.encumbrance;
    }

    return false;
  }

  get restrictions(): StatementEncumbrance[] | null {
    const rights: StatementRight[] = get(this.statements.egrn, 'data.parcel.rights');

    let encumbrance: StatementEncumbrance[] = flatten(
      filter(
        map(rights, property('encumbrance')),
        null,
      ),
    );

    if (!encumbrance || !encumbrance.length) {
      encumbrance = get(this.statements.egrn, 'data.parcel.encumbrance');
    }

    if (encumbrance && encumbrance.length) {
      encumbrance.forEach(e => {
        if (e.entities) {
          e.entities.forEach(this._checkEmptyContentInEntity);
        }
      });

      return encumbrance;
    }

    return null;
  }

  get noRestrictions(): boolean {
    return this.statements.egrn && !this.restrictions && !this.missingEncumbrances;
  }

  get missingEncumbrances() {
    const encumbrances = get(this.statements.egrn, 'data.difference.missing_encumbrances');
    const isChecked = get(this.statements.egrn, 'data.difference.is_checked');
    const encumbrance = get(this.statements.egrn, 'data.difference.difference.encumbrance');
    if (!isChecked && encumbrance && encumbrances) {
      const result = filter(encumbrances, (elem) => {
        return [ '022004000000', '022004001000' ].includes(elem.type);
      });

      if (result && result.length) {
        return result;
      }
    }

    return null;
  }

  get restrictionsCount() {
    let count = 0;
    if (this.restrictions && this.restrictions.length) {
      count += this.restrictions.length;
    }
    if (this.missingEncumbrances && this.missingEncumbrances.length) {
      count += this.missingEncumbrances.length;
    }
    return count;
  }

  get propertyNotHaveEncumbranceData(): boolean {
    return this.statements.egrn && !this.encumbrance && !this.noEncumbrance;
  }

  isOwnership(right: any) {
    const desc = get(right, 'right_data.share_description', '');
    const type = get(right, 'right_data.right_type.value', '');
    return /совместн/.test(desc) || /совместн/.test(type);
  }

  regName(owner: StatementRight): string | null {
    if (owner.registration) {
      if (owner.registration.name) {
        return owner.registration.name;
      }

      if (owner.registration.type) {
        let regName = '';

        if (owner.registration.type.type_name) {
          regName = owner.registration.type.type_name;
          if (owner.registration.share_description) {
            regName += `, ${owner.registration.share_description}`;
          } else if (owner.rightList && owner.rightList.registration.share_description) {
            regName += `, ${owner.rightList.registration.share_description}`;
          }
        }

        if (owner.registration.text) {
          regName = `${regName}, ${owner.registration.text}`;
        }

        if (owner.registration.reg_number) {
          regName = `${regName}, № ${owner.registration.reg_number}`;
        }

        if (owner.registration.reg_date) {
          regName = `${regName} от ${owner.registration.reg_date}`;
        }

        return regName;
      }
    }

    return null;
  }
}
