































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { getRegionNameById } from 'shared/utils/regions';

@Component({
  popupOptions: {
    name: 'popup_side popup_sud-details',
  },

  props: {
    result: Object,
  },
})
export default class AppPledgeReportDetails extends Vue {
  // props
  result: any;

  regionName(value: string): string {
    return getRegionNameById(value);
  }
}
