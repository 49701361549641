var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c("h4", { staticClass: "mb-3" }, [
        _vm._v("\n    Редактировать кредитный отчет\n  ")
      ]),
      _c("app-credit-rating-form", {
        attrs: { "on-submit": _vm.onSubmit, owner: _vm.owner },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function() {
              return [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-4 ml-md-auto" },
                    [
                      _c(
                        "app-button",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            type: "submit",
                            block: "",
                            size: "lg",
                            loading: _vm.loading,
                            disabled: _vm.isUnderage || _vm.passportDateError
                          }
                        },
                        [_vm._v("\n            Сохранить\n          ")]
                      )
                    ],
                    1
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }