

































































































import Vue from 'vue';
import Component from 'vue-class-component';
import get from 'lodash/get';

@Component({
  components: {},

  props: {
    borders: Array,
  },
})
export default class AppStatementEgrnObjectBorders extends Vue {
  borders: any;
  get = get;

  // get elements() {
  //   return get(this.contour, 'entity_spatial.spatials_elements', []);
  // }
}
