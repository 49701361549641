var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container container_responsive" }, [
    _c(
      "div",
      { staticClass: "page-wrapper page-wrapper_border" },
      [
        _c(
          "app-collapse",
          [
            _c(
              "app-collapse-item",
              {
                staticClass:
                  "risk-report-item risk-report-item_conclusion mt-3 mb-3",
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "risk-report-item__title-inner row" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "risk-report-item__name col-12 col-md-auto"
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-info text-primary mr-2"
                                }),
                                _vm._v(" Инструкция\n            ")
                              ]
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _c("pre", { staticClass: "pt-3" }, [
                  _vm._v(
                    "          Инструкция\n\n          Ключ состоит из нескольких блоков, которые заделяются нижним подчеркиванием:\n          проект_цвет_страница_страница_и т.д.\n\n          Чтобы отобразить алерт на RC, ключ должен начинаться с alert_\n          Отобразить на fizcheck - alertFiz_\n\n          Варианты второго блока\n          success - зеленый\n          info - синий\n          primary - другой синий\n          danger - красный\n          warning - оранжевый\n\n          У следующих блоков порядок не важен, но рекомендую указывать в них страницы,\n          разделяя их нижним подчеркиванием.\n\n          index - главная стр\n          object-objectKey - стр обьекта\n          check-person - лендинг чековнера\n          check-person-id - страница с заказом чековнера\n          credit-rating-id - страница с заказом кред рейтинга\n          user-orders - стр со списком заказов\n\n          Чтобы отображать алерт везде, нужно вместо страницы указать all\n        "
                  )
                ])
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "admin-page-head d-flex justify-content-between align-items-center"
          },
          [
            _c("h5", [_vm._v("Список настроек")]),
            _c(
              "app-button",
              {
                attrs: { size: "sm", variant: "primary-light" },
                on: { click: _vm.showCreateDialog }
              },
              [
                _c("i", { staticClass: "fas fa-plus fa-xs fa-fw" }),
                _vm._v(" Добавить настройку\n      ")
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "app-settings" },
          [
            _vm._m(0),
            _vm._l(_vm.settings, function(item) {
              return _c(
                "div",
                {
                  key: item.key,
                  staticClass: "app-settings-item",
                  class: { "app-settings-item_loading": _vm.loading[item.key] }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "app-settings-item__cell app-settings-item__cell_key"
                    },
                    [_vm._v("\n          " + _vm._s(item.key) + "\n        ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "app-settings-item__cell app-settings-item__cell_text text-wrap"
                    },
                    [
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col-auto" }, [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: { content: "Редактировать текст" },
                                  expression:
                                    "{ content: 'Редактировать текст' }"
                                }
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.editText(item)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-pencil-alt fa-xs"
                              })
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "col" }, [
                          item.text
                            ? _c("span", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.text) +
                                    "\n              "
                                )
                              ])
                            : _c("span", { staticClass: "text-muted small" }, [
                                _vm._v(
                                  'Тут пусто. Нжамине на "Карандаш" чтобы добавить текст'
                                )
                              ])
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "app-settings-item__cell app-settings-item__cell_checkbox text-nowrap"
                    },
                    [
                      _c(
                        "app-checkbox",
                        {
                          attrs: { model: item.enabled },
                          on: {
                            change: function($event) {
                              return _vm.toggleEnabled($event, item.key)
                            }
                          }
                        },
                        [_vm._v("\n            Включить\n          ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "app-settings-item__cell app-settings-item__cell_trash",
                      on: {
                        click: function($event) {
                          return _vm.removeItem(item.key)
                        }
                      }
                    },
                    [_c("i", { staticClass: "far fa-trash-alt fa-sm" })]
                  )
                ]
              )
            })
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "app-settings-item app-settings-item_head text-muted small"
      },
      [
        _c(
          "div",
          {
            staticClass: "app-settings-item__cell app-settings-item__cell_key"
          },
          [_vm._v("\n          Ключ / Название\n        ")]
        ),
        _c(
          "div",
          {
            staticClass: "app-settings-item__cell app-settings-item__cell_text"
          },
          [_vm._v("\n          Текст\n        ")]
        ),
        _c("div", {
          staticClass:
            "app-settings-item__cell app-settings-item__cell_checkbox"
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }