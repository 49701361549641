var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showButton,
            expression: "showButton"
          }
        ],
        staticClass: "fixed"
      },
      [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "fixed-button", on: { click: _vm.scrollToTop } },
            [
              _c("i", { staticClass: "fas fa-solid fa-arrow-up text-brand" }),
              _vm._v(" Наверх\n      ")
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }