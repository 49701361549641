var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "w-100 text-right my-2 pr-2" },
        [
          _c(
            "app-button",
            {
              attrs: { variant: "primary", size: "sm" },
              on: { mousedown: _vm.openFilter }
            },
            [
              _vm._v("\n      Фильтр "),
              _c("i", { staticClass: "fas fa-filter fa-xs" })
            ]
          )
        ],
        1
      ),
      _c("app-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          loading: _vm.loading,
          pagination: _vm.pagination,
          "item-key": "OrderID"
        },
        on: { "page-changed": _vm.pageChanged },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "td",
                  [
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "admin-order-detail",
                              params: { orderID: row.OrderID }
                            }
                          }
                        },
                        [
                          !_vm.isPhysical(row)
                            ? [
                                _vm._v(
                                  "\n              Проверка юридических лиц\n            "
                                )
                              ]
                            : [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.nameByProduct(row.ProductName)) +
                                    "\n            "
                                )
                              ]
                        ],
                        2
                      ),
                      _vm.getFullName(row.OrderItem[0])
                        ? _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.getFullName(row.OrderItem[0])) +
                                "\n          "
                            )
                          ])
                        : row.KadastrNumber !== "n/a"
                        ? _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.KadastrNumber) +
                                "\n          "
                            )
                          ])
                        : row.Address !== "n/a"
                        ? _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.Address) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ],
                    _vm.getTextByStatus(row)
                      ? [
                          _c(
                            "div",
                            { staticStyle: { "max-width": "fit-content" } },
                            [
                              _c(
                                "div",
                                { class: "badge " + _vm.getClassByStatus(row) },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.getTextByStatus(row)) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _c(
                  "td",
                  { staticStyle: { "max-width": "150px", overflow: "hidden" } },
                  [
                    row.OrderItem.length < 4
                      ? _vm._l(row.OrderItem, function(order) {
                          return _c("div", { key: order.OrderItemID }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(order.ProductName) +
                                "\n          "
                            )
                          ])
                        })
                      : [
                          _c(
                            "div",
                            {
                              directives: [
                                { name: "tippy", rawName: "v-tippy" }
                              ]
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.isWholesaleOrder(row)
                                      ? "Оптовый заказ"
                                      : "Продукты"
                                  ) +
                                  " (" +
                                  _vm._s(row.OrderItem.length) +
                                  ")\n            "
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "small",
                                  attrs: { "data-tippy-content": "hide" }
                                },
                                _vm._l(row.OrderItem, function(order) {
                                  return _c("div", { key: order.OrderItemID }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(order.ProductName) +
                                        "\n              "
                                    )
                                  ])
                                }),
                                0
                              )
                            ]
                          )
                        ]
                  ],
                  2
                ),
                _c("td", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._f("date")(row.CreatedAt, "DD.MM.YYYY")) +
                      "\n        "
                  ),
                  _c("div", [
                    _vm._v(_vm._s(_vm._f("date")(row.CreatedAt, "HH:mm:ss")))
                  ])
                ]),
                _c("td", [_vm._v(_vm._s(row.TotalAmount))]),
                _c("td", [
                  _c(
                    "div",
                    {
                      staticClass: "text-center",
                      staticStyle: { "font-size": "12px" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(row.Completed) +
                          " / " +
                          _vm._s(row.ItemsCount) +
                          "\n        "
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "progress", staticStyle: { height: "5px" } },
                    [
                      _c("div", {
                        staticClass: "progress-bar",
                        style: { width: _vm.getProgress(row) + "%" }
                      })
                    ]
                  )
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }