var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("app-data-table", {
    attrs: {
      headers: _vm.headers,
      items: _vm.items,
      loading: _vm.loading,
      pagination: _vm.pagination
    },
    on: { "page-changed": _vm.pageChanged },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var row = ref.row
          return [
            _c("td", { staticStyle: { "max-width": "170px" } }, [
              _c("div", { staticClass: "text-truncate" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.getDate(row.send_date, "DD MMMM YYYY г.")) +
                    "\n      "
                )
              ]),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.getDate(row.send_date, "HH:mm:ss")) +
                  "\n    "
              )
            ]),
            _c("td", { staticStyle: { "max-width": "220px" } }, [
              _c("div", { staticClass: "text-truncate" }, [
                _vm._v("\n        " + _vm._s(row.sender) + "\n      ")
              ]),
              _c(
                "a",
                {
                  staticClass: "text-truncate",
                  attrs: { href: "mailto:" + row.recipient }
                },
                [_vm._v("\n        " + _vm._s(row.recipient) + "\n      ")]
              )
            ]),
            _c("td", [_vm._v("\n      " + _vm._s(row.subject) + "\n    ")]),
            _c("td", [
              row.smtp_answer_code === 250
                ? _c("div", { staticClass: "text-success" }, [
                    _vm._v("\n        Доставлено\n      ")
                  ])
                : _vm._e(),
              row.smtp_answer_code === 550
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v("\n        Ошибка\n      ")
                  ])
                : _vm._e(),
              row.smtp_answer_code === 552
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n        Не доставлено: превышен размер ящика получателя\n      "
                    )
                  ])
                : _vm._e(),
              row.smtp_answer_code === 554
                ? _c(
                    "div",
                    { staticClass: "text-danger" },
                    [
                      row.smtp_answer_data === "User marked email as spam"
                        ? [
                            _vm._v(
                              "\n          Письмо отмечено как спам\n        "
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              row.smtp_answer_code === 555
                ? _c(
                    "div",
                    { staticClass: "text-warning" },
                    [
                      row.smtp_answer_data === "Unsubscribed"
                        ? [_vm._v("\n          Отписался\n        ")]
                        : [
                            _vm._v(
                              "\n          " +
                                _vm._s(row.smtp_answer_data) +
                                "\n        "
                            )
                          ]
                    ],
                    2
                  )
                : _vm._e(),
              _vm.hasTracking(row.tracking) && row.tracking.open !== 0
                ? _c("div", { staticClass: "text-primary" }, [
                    _vm._v("\n        Прочитано\n      ")
                  ])
                : _vm._e(),
              _vm.hasTracking(row.tracking) && row.tracking.click !== 0
                ? _c("div", { staticClass: "text-info" }, [
                    _vm._v("\n        Переход по ссылке\n      ")
                  ])
                : _vm._e()
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }