































































































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    details: Object,
  },
})
export default class AppSudactDetails extends Vue {
  // props
  details: any;
}
