





















































































import Vue from 'vue';
import Component from 'vue-class-component';
import noop from 'lodash/noop';

import { Validators } from '@/core/utils/validator';
import find from 'lodash/find';
import omit from 'lodash/omit';

@Component({
  popupOptions: {
    size: 'xs',
  },

  props: {
    userId: String,
    onRequestEnd: {
      type: Function,
      default: noop,
    },
  },
})
export default class AdminManualAttachOrder extends Vue {
  // props
  userId: string;
  onRequestEnd: () => void;

  // data
  model: ManualAttachOrderParams = {
    kadastr_number: '',
    product_id: null,
    person_id: this.userId,
    amount: 100,
    source: 4,
  };

  // computed

  // Список всех продуктов, нужен в шаблоне
  get products(): Product[] {
    return this.$store.state.products;
  }

  // Ищем продукт по названию, нам нужен продукт по пополнению баланса
  get balanceProduct(): Product {
    return find(this.products, { product_name: 'AccountBalanceReplenishmentCustom' });
  }

  // Проверяем, является ли продукт пополнением баланса
  get isBalanceProduct(): boolean {
    return this.model.product_id === this.balanceProduct.product_id;
  }

  // Проверяем, если выбранный продукт является выписками, которые заказываются через api
  get isStatement(): boolean {
    let product: Product = null;

    if (this.model.product_id) {
      product = this.$store.state.productsByKey[this.model.product_id];
    }

    if (product) {
      return product.product_type === 'official_documents';
    }

    return false;
  }

  // methods
  async onSubmit() {
    try {
      let body = this.model;

      // Если это пополнение баланса, то кадастровый номер не нужен
      if (this.isBalanceProduct) {
        body = omit(this.model, 'kadastr_number');
      } else {
        // В остальном случае нам не нужно количество, если это не пополнение баланса
        body = omit(this.model, 'amount');
      }

      // Если это не выписки, то убираем источник откуда заказываем
      if (!this.isStatement) {
        body = omit(this.model, 'source');
      }

      if (this.model.product_id) {
        const product = this.$store.state.productsByKey[this.model.product_id];
        if (product && product.product_name === 'CheckOwnerV2') {
          body.metadata = {
            version: 'V2.1',
          };
        }
      }

      await this.$api.admin.ManualAttachOrder(body);
      this.onRequestEnd();
      this.$noty.info({ text: 'Заказ добавлен' });
      this.$dialog.close();
    } catch (error) {
      const text = this.$api.getErrorMessage(error);
      this.$noty.error({ text });
      console.error(error);
    }
  }

  validationRules(): ValidationConfig {
    const config: ValidationConfig = {
      product_id: [ Validators.required ],
    };

    if (this.isBalanceProduct) {
      config.amount = [
        Validators.required,
        Validators.isInt({ message: 'Минимальное значение 1 руб.', min: 1 }),
      ];
    } else {
      config.kadastr_number = [ Validators.required ];
    }

    return config;
  }
}
