


























































































import Vue from 'vue';
import Component from 'vue-class-component';
import isEmpty from 'lodash/isEmpty';

interface SearchResultItem {
  userId: string;
  email: string;
  phone: string;
  name?: string;
}

type SearchTypes =
  | 'email'
  | 'name'
  | 'orderID'
  | 'orderItemID'
  | 'kadastrNumber';

@Component
export default class AdminSearchUsers extends Vue {
  searchBy: SearchTypes = 'email';
  query: string = '';
  loading: boolean = false;
  error: boolean = false;
  searchResult: SearchResultItem[] = [];
  headers: TableHeaderItem[] = [
    { text: 'Email' },
    { text: 'Телефон' },
    { text: 'Имя' },
  ];

  async requestData() {
    if (this.loading) {
      return;
    }

    this.error = false;
    this.loading = true;

    try {
      const apiMethodName =
        this.searchBy === 'email' || this.searchBy === 'name'
          ? 'SearchUsers'
          : 'Orders';
      const callApiMethod =
        this.searchBy === 'email' || this.searchBy === 'name'
          ? this.$api.admin.SearchUsers
          : this.$api.admin.Orders;
      const result = await callApiMethod({
        offset: 0,
        limit: 20,
        [this.searchBy]: this.query,
      });

      this.searchResult = this.mapDataToView(result, apiMethodName);
    } catch (error) {
      console.error(error);
      this.error = true;
    }

    this.loading = false;
  }

  mapDataToView(result: any[], apiMethodName: string): SearchResultItem[] {
    if (!Array.isArray(result) || isEmpty(result)) {
      return [];
    }

    if (apiMethodName !== 'Orders') {
      return result.map(
        (user: User): SearchResultItem => {
          return {
            userId: user.id,
            phone: user.phone,
            email: user.email,
            name: user.name,
          };
        }
      );
    }

    return result.map(
      (Order: AdminOrderInfo): SearchResultItem => {
        return {
          email: Order.OwnerName,
          userId: Order.OwnerID,
          phone: Order.OwnerPhone,
        };
      }
    );
  }
}
