import _ from 'lodash';
import Component, { mixins } from 'vue-class-component';
import DataTableMixin from '@/core/mixins/data-table.mixin';
import FilterMixin from '@/core/mixins/filter.mixin';

import { RoleNames } from 'shared/utils/constants';
import OrderFilter from '../../components/OrdersFilter.vue';
import qs from 'query-string';
import HelpersMixin from '@/core/mixins/helpers.mixin';

function defaultFilter(): SimpleObject {
  return {
    haveKadastr: undefined,
    haveTransaction: undefined,
    isNotPaid: undefined,
    partnerID: '',
    kadastrNumber: '',
    productID: [],
    itemStatus: [],
    creationDateFrom: '',
    creationDateTo: '',
    sourceID: [],
    personRoles: [],
    minPrice: '',
    maxPrice: '',
  };
}
@Component({
  props: {
    userID: String,
  },
})
export default class AdminUserOrdersNew extends mixins(
  FilterMixin,
  DataTableMixin,
  HelpersMixin
) {
  userID!: string;
  filter: any = { ...defaultFilter(), ...this._getQuery() };
  headers: TableHeaderItem[] = [
    {
      text: 'Название',
    },
    {
      text: 'Продукт',
    },
    {
      text: 'Дата заказа',
    },
    {
      text: 'Сумма',
    },
    {
      text: 'Выполнено',
      width: '100px',
    },
  ];

  roles = RoleNames;

  handleRequest(offset: number, limit: number) {
    let reverseStatus = false;
    const filter = {
      ...this.filter,
      responsiblePersonID: _.get(this.filter, 'responsiblePersonID.id', ''),
      sourceID: _.map(this.filter.sourceID, 'source_id'),
      personRoles: _.map(this.filter.personRoles, 'person_role_id'),
    };
    /* Если в фильтре ничего не выбрано, то по умолчанию запрашиваем заказы без статусов 'waiting_for_payment', 'pending', 'paid' */
    if (filter.itemStatus.length === 0) {
      filter.itemStatus = [ 'waiting_for_payment', 'pending', 'paid' ];
      reverseStatus = true;
    }
    return this.$api.admin.Orders({
      offset,
      limit,
      reverseStatus,
      userID: this.userID,
      ...filter,
    });
  }

  mapDataIntoView(userOrders: AdminOrderInfo[]) {
    return userOrders.map((order: AdminOrderInfo) => {
      let address = '';
      let kadastrNumber = '';

      order.OrderItem.forEach(item => {
        if (item.ItemMetadata.address) {
          address = item.ItemMetadata.address;
        }

        if (item.ItemMetadata.kadastr_number) {
          kadastrNumber = item.ItemMetadata.kadastr_number;
        }
      });

      order.TotalAmount = 0;
      order.Completed = 0;
      order.ItemsCount = order.OrderItem.length;
      order.KadastrNumber = kadastrNumber;
      order.Address = address;
      order.ProductName = order.OrderItem[0].ProductName;

      order.OrderItem.forEach(item => {
        order.TotalAmount += parseInt(item.Price, 10);
        if (item.status === 'done') {
          order.Completed += 1;
        }
      });

      return order;
    });
  }

  openFilter() {
    this.$dialog.open({
      component: OrderFilter,
      props: {
        defaultFilter: defaultFilter,
        sources: this.sources,
        roles: this.roles,
        statuses: this.statuses,
        filter: this.filter,
        isUserOrder: true,
        submit: () => {
          this.$dialog.close();
          const filter = {
            ...this.filter,
            sourceID: this.filter.sourceID.map((item: any) => item.source_id),
            personRoles: this.filter.personRoles.map(
              (item: any) => item.person_role_id
            ),
          };

          this.applyFilter(filter);
        },
      },
    });
  }

  getProgress({ Completed, ItemsCount }: AdminOrderInfo) {
    return (Completed / ItemsCount) * 100;
  }

  getFullName(orderItem: OrderItem): string {
    if (orderItem.metadata.ownersData) {
      return `${orderItem.metadata.ownersData[0].surname} ${
        orderItem.metadata.ownersData[0].first
      } ${orderItem.metadata.ownersData[0].patronymic}`;
    }
    return '';
  }

  getTextByStatus(userOrder: AdminOrderInfo): string {
    for (const order of userOrder.OrderItem) {
      if (order.status === 'done') return;

      else if (this.$helper_textByStatus(order.status)) {
        return this.$helper_textByStatus(order.status);
      }
    }
    return '';
  }

  getClassByStatus(userOrder: AdminOrderInfo): string {
    for (const order of userOrder.OrderItem) {
      if (order.status === 'done') return;

      if (this.$helper_classByStatus(order.status)) {
        return this.$helper_classByStatus(order.status);
      }
    }
    return '';
  }

  isWholesaleOrder(userOrder: AdminOrderInfo): boolean {
    let kadastrNumber = null;

    for (const order of userOrder.OrderItem) {
      if (
        kadastrNumber &&
        order.ItemMetadata.kadastr_number !== kadastrNumber
      ) {
        return true;
      }

      kadastrNumber = order.ItemMetadata.kadastr_number;
    }

    return false;
  }

  isPhysical(userOrder: AdminOrderInfo): boolean {
    if (userOrder.OrderItem[0].metadata && userOrder.OrderItem[0].metadata.ownersData) {
      return userOrder.OrderItem[0].metadata.ownersData[0].owner_type === 0;
    }
    return true;
  }

  _getQuery() {
    const query = qs.parse(location.search, { arrayFormat: 'bracket' });
    return _.pick(query, Object.keys(defaultFilter()));
  }

  beforeDestroy() {
    this.filter = defaultFilter();
  }

  nameByProduct(name: string): string {
    const productNameMap: { [key: string]: string } = {
      CheckOwnerV2: 'Проверка физического лица',
      CheckOwner: 'Проверка физического лица',
      ReportAI: 'Проверка физического лица',
      Tariff: 'Тарифф',
      IndependentReportAI: 'Проверка физчек эксперт',
      AccountBalanceReplenishmentCustom: 'Пополнение баланса',
      AccountBalanceReplenishmentMini: 'Пополнение баланса',
      AccountBalanceReplenishmentNorma: 'Пополнение баланса',
      AccountBalanceReplenishmentOptima: 'Пополнение баланса',
      AccountBalanceReplenishmentRealtor: 'Пополнение баланса',
      AccountBalanceReplenishmentAgency: 'Пополнение баланса',
      CostAssessment: 'Оценка стоимости',
      CostAssessmentAdvanced: 'Оценка стоимости',
      CreditRating: 'Проверка кредитного рейтинга',
      ScoreRating: 'Проверка кредитного рейтинга',
      EgrnRightList: 'Проверка недвижимости',
      EgrnRightListFast: 'Проверка недвижимости',
      EgrnObject: 'Проверка недвижимости',
      EgrnObjectFast: 'Проверка недвижимости',
      RiskAssessmentV2: 'Проверка недвижимости',
      RiskAssessmentFastV2: 'Проверка недвижимости',
      RiskAssessmentSimple: 'Проверка недвижимости',
      RiskAssessmentAdvanced: 'Проверка недвижимости',
      RiskAssessmentFast: 'Проверка недвижимости',
      CounterAgent: 'Проверка контрагента',

      // | 'OrderMaintenance'
      // | 'MortgageSelection'
      // | 'PropertySaleEntranceFee'
      // | 'PropertySaleEntranceFeeLight'
      // | 'SubscriptionBasic'
    };

    if (name.includes('Tariff')) {
      name = name.split(' ')[0];
    }

    if (productNameMap.hasOwnProperty(name)) {
      return productNameMap[name];
    } else {
      return '¯\\_(ツ)_/¯';
    }
  }
}
