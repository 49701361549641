










































import Vue from 'vue';
import Component from 'vue-class-component';
import { Validators } from 'shared/utils/validator';
import { AxiosError } from 'axios';

@Component({
  popupOptions: {
    size: 'xs',
  },

  props: {
    userID: String,
    balanceType: Number,
    onSuccess: Function,
  },
})
export default class UserWithdrawMoneyDialog extends Vue {
  // props
  userID!: string;
  balanceType: number;
  onSuccess: () => void;

  // computed
  get balanceReplenishmentCustomProduct(): Product {
    return this.$store.getters.getProduct({
      product_name: 'AccountBalanceReplenishmentCustom',
    } as Product);
  }

  // data()
  model: Api.Admin.RequestBody.ChangeBalance = {
    amount: 0,
  };
  action: string = 'withdraw';

  // methods
  async submit() {
    try {
      if (this.balanceType === 1) {
        await this.changeAccountBalance();
      } else {
        await this.changePartnerBalance();
      }
      this.changeBalanceSuccess();
    } catch (error) {
      this.changeBalanceError(error as AxiosError);
    }
  }

  async changePartnerBalance() {
    if (this.action === 'withdraw') {
      const model: Api.Admin.RequestBody.PartnerWithdrawMoney = {
        amount: this.model.amount,
        partner_id: this.userID,
      };
      await this.$api.admin.PartnerWithdrawMoney(model);
    } else {
      const model = {
        product_id: this.balanceReplenishmentCustomProduct.product_id,
        person_id: this.userID,
        amount: this.model.amount,
      };
      await this.$api.admin.ManualAttachOrder(model);
    }
  }

  async changeAccountBalance() {
    if (this.action === 'withdraw') {
      const model: Api.Admin.RequestBody.AccountWithdrawMoney = {
        amount: this.model.amount,
        person_id: this.userID,
      };
      await this.$api.admin.AccountWithdrawMoney(model);
    } else {
      const model = {
        product_id: this.balanceReplenishmentCustomProduct.product_id,
        person_id: this.userID,
        amount: this.model.amount,
      };
      await this.$api.admin.ManualAttachOrder(model);
    }
  }

  validationConfig() {
    return {
      amount: [
        Validators.required,
        Validators.min(1, 'Минимальное значение должно быть не меньше 1'),
      ],
    };
  }

  changeBalanceSuccess() {
    this.$noty.info({ text: 'Баланс успешно изменён' });
    this.$dialog.close();
    this.onSuccess();
  }

  changeBalanceError(error: AxiosError) {
    console.error(error);
    this.$noty.error({ text: this.$api._getErrorMessage(error) });
  }
}
