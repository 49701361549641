






























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { getRegionNameById } from 'shared/utils/regions';

@Component({
  props: {
    details: Object,
  },
})
export default class AppSudrfDetailsAlt extends Vue {
  // props
  details: any;
  // data
  getRegionNameById = getRegionNameById;
}
