var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container container_responsive" },
    [
      _vm._m(0),
      _c("app-data-table", {
        attrs: { headers: _vm.headers, items: _vm.items, loading: _vm.loading },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("td", [
                  _vm._v("\n        " + _vm._s(row.name) + "\n      ")
                ]),
                _c("td", [
                  _vm._v("\n        " + _vm._s(row.amount) + " руб.\n      ")
                ]),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "app-button",
                      {
                        staticClass: "mr-2",
                        attrs: { size: "xs", variant: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.showChangeBalanceDialog(row.type)
                          }
                        }
                      },
                      [
                        _vm._v("\n          Изменить\n          "),
                        _c("i", { staticClass: "fas fa-pencil-alt fa-xs" })
                      ]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "admin-page-head d-flex align-items-center" },
      [
        _c("h5", { staticClass: "mr-3" }, [
          _vm._v("\n      Баланс клиента\n    ")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }