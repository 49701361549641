var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sud-preview-item__body" }, [
    _c("div", { staticClass: "sud-preview-item__name" }, [
      _c("i", { staticClass: "fas fa-briefcase" }),
      _c("b", [_vm._v(_vm._s(_vm.item.org_data.name))]),
      _vm.item.org_data.death_date
        ? _c("i", { staticClass: "fas fa-minus-circle text-danger" })
        : _c("i", { staticClass: "fas fa-check-circle text-success" }),
      _vm.item.org_data.death_date
        ? _c("span", { staticClass: "text-muted" }, [
            _vm._v("\n      (ликвидирован)\n    ")
          ])
        : _vm._e()
    ]),
    _c("div", { staticClass: "sud-details-item" }, [
      _c("div", { staticClass: "sud-details-item__title" }, [
        _vm._v("\n      Роль\n    ")
      ]),
      _c("div", { staticClass: "sud-details-item__value" }, [
        _vm._v("\n      " + _vm._s(_vm.getRole(_vm.item)) + "\n    ")
      ])
    ]),
    _vm.item.org_data.address_obj
      ? _c("div", { staticClass: "sud-details-item" }, [
          _c("div", { staticClass: "sud-details-item__title" }, [
            _vm._v("\n      Адрес\n    ")
          ]),
          _c("div", { staticClass: "sud-details-item__value" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.item.org_data.address_obj.zip_code) +
                "\n      " +
                _vm._s(_vm.item.org_data.address_obj.region_type) +
                "\n      " +
                _vm._s(_vm.item.org_data.address_obj.region_name) +
                "\n      " +
                _vm._s(_vm.item.org_data.address_obj.city_type) +
                "\n      " +
                _vm._s(_vm.item.org_data.address_obj.city_name) +
                "\n      " +
                _vm._s(_vm.item.org_data.address_obj.street_type) +
                "\n      " +
                _vm._s(_vm.item.org_data.address_obj.street_name) +
                "\n      " +
                _vm._s(_vm.item.org_data.address_obj.house) +
                "\n      " +
                _vm._s(_vm.item.org_data.address_obj.flat) +
                "\n    "
            )
          ])
        ])
      : _vm._e(),
    _vm.item.org_data.death_date
      ? _c("div", { staticClass: "sud-details-item" }, [
          _c("div", { staticClass: "sud-details-item__title" }, [
            _vm._v("\n      Дата ликвидации\n    ")
          ]),
          _c("div", { staticClass: "sud-details-item__value" }, [
            _vm._v("\n      " + _vm._s(_vm.item.org_data.death_date) + "\n    ")
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }