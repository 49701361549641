



























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import AppFounderReport from 'shared/components/RiskNew/Founder.vue';
import AppFounderReportDetails from 'shared/components/FizCheck/Details/FounderReportDetails.vue';
import AppReportCollapseItem from 'shared/components/FizCheck/ReportCollapseItem.vue';
import AppWarningImg from 'shared/components/Static/WarningImg.vue';
import get from 'lodash/get';

@Component({
  components: { AppReportItem, AppFounderReport, AppFounderReportDetails, AppReportCollapseItem, AppWarningImg },
  props: {
    report: Object,
    status: String,
    images: Array,
    orderIsDone: Boolean,
    missingRequirements: Array,
    openAddFieldDialog: Function,
    updateMap: Function,
    task: Object,
    isPhysical: Boolean,
    onlyOwners: Boolean,
    map: Object,
    user: Object,
  },
})
export default class AppExtendedReportCompanies extends Vue {
  // props
  missingRequirements: any[];
  report: any;
  status: string;
  images: any[];
  orderIsDone: boolean;
  task: any;
  isPhysical: boolean;
  onlyOwners: boolean;
  openAddFieldDialog: (val: string) => any;
  updateMap: (taskName: string, field: string, value: any) => void;
  map: any;
  user: any;

  get statusDone() {
    return this.reportItemStatus === 'done';
  }

  get reportItemStatus() {
    if (this.companies && this.companies.length) {
      return 'done';
    } else {
      return this.status;
    }
  }

  get companies() {
    return get(this.user, 'documents.company', []);
  }

  get resultStatus() {
    if (this.statusDone) {
      if (this.companies && this.companies.length) {
        return 'found';
      } else {
        return 'not found';
      }
    }
    return 'no data';
  }
}
