var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-item-result report-item-result__result-wrapper" },
    [
      _vm._l(_vm.elems, function(item, index) {
        return _c(
          "div",
          { key: index, staticClass: "report-item-result__result" },
          [
            _c("app-report-collapse-item", {
              attrs: { opened: _vm.opened },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function() {
                      return [
                        item.billDate
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__result-elem-text-wrapper fssp-modern__title-date--gosuslugi"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__result-elem-text"
                                  },
                                  [
                                    _vm._v(
                                      "\n            Номер/Дата\n          "
                                    )
                                  ]
                                ),
                                _c("div", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(item.billNumber) +
                                      "\n            от\n            " +
                                      _vm._s(
                                        _vm._f("date")(
                                          item.billDate,
                                          "DD.MM.YYYY"
                                        )
                                      ) +
                                      "\n            " +
                                      _vm._s(item.billName) +
                                      "\n          "
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        item.amount
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__result-elem-text-wrapper flex-shrink-0"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__result-elem-text"
                                  },
                                  [_vm._v("\n            Сумма\n          ")]
                                ),
                                _c("div", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("currency")(item.amount, {
                                          precision: 2
                                        })
                                      ) +
                                      "\n          "
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm.getAttr(item, "type_doc")
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__result-elem-text-wrapper flex-shrink-0"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__result-elem-text"
                                  },
                                  [
                                    _vm._v(
                                      "\n            Назначена  на\n          "
                                    )
                                  ]
                                ),
                                _c("div", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.getAttr(item, "type_doc")) +
                                      "\n          "
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "report-item-result__content-section"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "report-item-result__content-list"
                              },
                              [
                                _vm.getAttr(item, "SupplierFullName")
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Получатель платежа\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.getAttr(
                                                    item,
                                                    "SupplierFullName"
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                item.amount
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                Предмет исполнения, сумма непогашенной задолженности\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    item.amount,
                                                    { precision: 2 }
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            })
          ],
          1
        )
      }),
      _vm.result.length > _vm.perPage
        ? _c("app-pagination", {
            attrs: {
              current: _vm.currentPage,
              "per-page": _vm.perPage,
              total: _vm.result.length
            },
            on: { "page-changed": _vm.pageChanged }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }