var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPhysical
    ? _c(
        "app-report-item",
        {
          attrs: {
            title: "Нахождение в розыске",
            icon: "fingerprint",
            opened: _vm.resultStatus === "found",
            status: _vm.status,
            "missing-requirements": _vm.missingRequirements,
            images: _vm.images,
            color: _vm.map.color,
            source: {
              domain: "мвд.рф",
              link: "https://xn--b1aew.xn--p1ai/wanted"
            }
          },
          scopedSlots: _vm._u(
            [
              {
                key: "not-required",
                fn: function() {
                  return [
                    !_vm.$isServer
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "link font-weight-500 ml-2 d-print-none",
                            on: {
                              click: function($event) {
                                return _vm.openAddFieldDialog("birthday")
                              }
                            }
                          },
                          [_vm._v("\n      Добавить\n    ")]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              },
              _vm.resultStatus === "found"
                ? {
                    key: "extra-content",
                    fn: function() {
                      return [
                        _c("div", [
                          _c("div", {
                            staticClass: "criminal-report__divider"
                          }),
                          _c("div", { staticClass: "row md-gutters" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-md-auto text-center text-md-left mb-4 mb-md-0"
                              },
                              [
                                _vm.result.image
                                  ? _c("img", {
                                      staticClass: "criminal-report__image",
                                      attrs: { src: _vm.result.image }
                                    })
                                  : _c("img", {
                                      staticClass: "criminal-report__image",
                                      attrs: { src: "/rc-modern/photo.svg" }
                                    })
                              ]
                            ),
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "div",
                                { staticClass: "criminal-report__items" },
                                [
                                  _vm.result.input.surname
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Фамилия\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.result.input.surname
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result.input.first
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Имя\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.result.input.first
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result.input.patronymic
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Отчество\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.result.input.patronymic
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result.input.birthday
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Дата рождения\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      _vm.result.input.birthday,
                                                      "DD.MM.YYYY"
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result.input.inn
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                ИНН\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(_vm.result.input.inn) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result.input.region
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Регион\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.regionName(
                                                      _vm.result.input.region
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result.result.datamail &&
                                  _vm.result.result.datamail.length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Дополнительная информация\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.result.result
                                                      .datamail[0].data
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ])
                          ])
                        ])
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _vm.task
            ? [
                _c(
                  "div",
                  { staticClass: "report-item-modern__subtitle" },
                  [
                    _vm.resultStatus === "not found"
                      ? [
                          _vm._v(
                            "\n        В базе данных розыска не числится\n      "
                          )
                        ]
                      : _vm.resultStatus === "found"
                      ? [
                          _vm._v(
                            "\n        Числится в базе данных розыска\n      "
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            : _vm.orderIsDone
            ? [
                _c("span", { staticClass: "text-warning" }, [
                  _vm._v(" Недостаточно данных ")
                ])
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }