var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-3" },
    [
      _c("app-dialog-close"),
      _c("h5", [_vm._v("Перезапуск парсеров")]),
      _c(
        "div",
        { staticClass: "statuses mb-3" },
        _vm._l(_vm.tasks, function(item, key) {
          return _c(
            "div",
            {
              key: key,
              staticClass: "status-item form-row align-items-center py-2"
            },
            [
              item
                ? [
                    _c(
                      "div",
                      { staticClass: "col-auto" },
                      [
                        _c(
                          "app-button",
                          {
                            attrs: {
                              disabled: ["in progress", "not started"].includes(
                                item.status
                              ),
                              size: "xs",
                              loading: _vm.loading
                            },
                            on: {
                              click: function($event) {
                                return _vm.restartTask(item.task_id)
                              }
                            }
                          },
                          [_c("i", { staticClass: "fas fa-sync-alt" })]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        item.task_name
                          ? [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.task_name) +
                                  "\n          "
                              )
                            ]
                          : _vm.taskNames[key]
                          ? [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.taskNames[key]) +
                                  "\n          "
                              )
                            ]
                          : [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.task_type) +
                                  "\n          "
                              )
                            ],
                        ["failed", "soft_failed"].includes(item.status)
                          ? _c("div", { staticClass: "col-12" }, [
                              item.task_result && item.task_result.text
                                ? _c(
                                    "span",
                                    { staticClass: "text-danger small" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.task_result.text) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.meta && item.meta.text
                                ? _c(
                                    "div",
                                    { staticClass: "text-danger small" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.meta.text) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              item.number_of_starts && item.number_of_starts > 0
                                ? _c(
                                    "p",
                                    { staticClass: "text-warning small m-0" },
                                    [
                                      _vm._v(
                                        "\n              Попыток запуска " +
                                          _vm._s(item.number_of_starts) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-auto small",
                        class: _vm.statusClassName(item.status)
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.statusText(item.status)) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "col-auto" },
                      [
                        _c(
                          "app-button",
                          { attrs: { disabled: "", size: "xs" } },
                          [_c("i", { staticClass: "fas fa-sync-alt" })]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col text-muted" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.taskNames[key]) +
                          "\n        "
                      )
                    ]),
                    _c("div", { staticClass: "col-auto small text-muted" }, [
                      _vm._v("\n          Не создан\n        ")
                    ])
                  ]
            ],
            2
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }