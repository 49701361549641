var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body p-4" },
    [
      _c("app-dialog-close"),
      _c("h5", { staticClass: "text-center mb-4" }, [
        _vm._v("\n    Добавление продуктов к заказу\n  ")
      ]),
      _c(
        "app-form",
        {
          attrs: {
            "request-handler": _vm.onSubmit,
            model: _vm.model,
            rules: _vm.validationRules
          },
          scopedSlots: _vm._u([
            {
              key: "button",
              fn: function(ref) {
                var loading = ref.loading
                return [
                  _c(
                    "app-button",
                    { attrs: { type: "submit", loading: loading, block: "" } },
                    [_vm._v("\n        Добавить\n      ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "app-form-group",
            { attrs: { "model-name": "order_id" } },
            [
              _c("app-input", {
                attrs: { placeholder: "Номер заказа (OrderID)" },
                model: {
                  value: _vm.model.order_id,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "order_id", $$v)
                  },
                  expression: "model.order_id"
                }
              })
            ],
            1
          ),
          _vm.isBalanceProduct
            ? _c(
                "app-form-group",
                { attrs: { "model-name": "amount" } },
                [
                  _c("app-input", {
                    attrs: { type: "number", placeholder: "Сумма" },
                    model: {
                      value: _vm.model.amount,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "amount", _vm._n($$v))
                      },
                      expression: "model.amount"
                    }
                  })
                ],
                1
              )
            : _c(
                "app-form-group",
                { attrs: { "model-name": "kadastr_number" } },
                [
                  _c("app-input", {
                    attrs: { placeholder: "Кадастровый номер" },
                    model: {
                      value: _vm.model.kadastr_number,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "kadastr_number", $$v)
                      },
                      expression: "model.kadastr_number"
                    }
                  })
                ],
                1
              ),
          _c("app-form-group", { attrs: { "model-name": "product_id" } }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.product_id,
                    expression: "model.product_id"
                  }
                ],
                staticClass: "custom-select",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.model,
                      "product_id",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", { domProps: { value: null } }, [
                  _vm._v("\n          Продукт\n        ")
                ]),
                _vm._l(_vm.products, function(product) {
                  return _c(
                    "option",
                    {
                      key: product.product_id,
                      domProps: { value: product.product_id }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(product.product_name) +
                          "\n        "
                      )
                    ]
                  )
                })
              ],
              2
            )
          ]),
          _vm.isStatement
            ? _c(
                "app-form-group",
                { attrs: { model: "source", label: "Источник" } },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.source,
                          expression: "model.source"
                        }
                      ],
                      staticClass: "custom-select",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.model,
                            "source",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { domProps: { value: 1 } }, [
                        _vm._v("\n          Каднет\n        ")
                      ]),
                      _c("option", { domProps: { value: 2 } }, [
                        _vm._v("\n          Api Росреестр\n        ")
                      ]),
                      _c("option", { domProps: { value: 4 } }, [
                        _vm._v("\n          RC data\n        ")
                      ])
                    ]
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }