








































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import noop from 'lodash/noop';
import flatpickr from 'flatpickr';

import { Validators } from '@/core/utils/validator';

const now = new Date();

@Component({
  name: 'AppCreateCampaignDialog',

  popupOptions: {
    size: 'sm',
  },

  props: {
    promocode: {
      type: Object,
      default: null,
    },
    onRequestEnd: {
      type: Function,
      default: noop,
    },
    tariffs: Array,
    coupons: Array,
  },
})
export default class CreateCampaign extends Vue {
  // props
  promocode: any;
  onRequestEnd: () => void;
  tariffs: any[];
  coupons: any[];

  // data
  datePickerOptions: flatpickr.Options.Options = {
    maxDate: '',
    minDate: new Date(now.getFullYear() - 120, 0, 0),
    defaultDate: '',
  };
  model: any = {
    code: '',
    items: [],
    enabled: true,
  };

  created() {
    if (this.promocode) {
      this.model = {
        code: this.promocode.code,
        items: this.promocode.items,
        enabled: this.promocode.enabled,
      };
    }
  }

  addAction() {
    this.model.items.push({
      action_name: '',
      value: {
        count: 1,
      },
    });
  }

  // methods
  async onSubmit() {
    try {
      // if (this.model.items && this.model.items.length) {
      //   this.model.items = this.model.items.map((elem: any) => {
      //     elem.value.from_date = new Date(elem.value.from_date).toISOString();
      //     elem.value.thru_date = new Date(elem.value.thru_date).toISOString();
      //     return elem;
      //   });
      // }
      if (this.promocode) {
        this.model.promocode_campaign_id = this.promocode.promocode_campaign_id;
        await this.$api.admin.UpdatePromocode(this.model);
      } else {
        await this.$api.admin.CreatePromocode(this.model);
      }

      this.onRequestEnd();
      this.$dialog.close();
    } catch (error) {
      console.error(error);
    }
  }

  get products() {
    return this.$store.state.products;
  }

  validationRules(): ValidationConfig {
    return {
      code: [ Validators.required ],
    };
  }
}
