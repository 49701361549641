





















































































































import Vue from 'vue';

import pluck from 'shared/utils/pluck';
import formatDateStringToISO from 'shared/utils/date/formatDateStringToISO';
import { Validators } from 'shared/utils/validator';

export default Vue.extend({
  popupOptions: {
    size: 'xs',
  },

  props: {
    userPriceComponent: Object as () => PriceComponent.UserItem,
    onUpdated: Function,
  },

  data() {
    return {
      model: this.userPriceComponent,
      priceComponentItems: {},
    };
  },

  async created() {
    try {
      const items = await this.$api.priceComponent.items.All();
      this.priceComponentItems = pluck(items, 'price_component_id', 'name');
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    async updateItem() {
      try {
        const body = { ...this.model };

        formatDateStringToISO(body, 'from_date', 'thru_date');

        await this.$api.priceComponent.user.UpdateItem(body);

        this.onUpdated();

        this.$dialog.close();
      } catch (error) {
        console.error(error);
      }
    },

    validationRules(): ValidationConfig {
      return {
        price_component_owner_id: [ Validators.required ],
        price_component_id: [ Validators.required ],
        name: [ Validators.required ],
        from_date: [ Validators.required ],
        thru_date: [ Validators.required ],
        count_current: [ Validators.isInt({ min: 1, message: 'Число должно быть не меньше 1' }) ],
        count_max: [ Validators.isInt({ min: 1, message: 'Число должно быть не меньше 1' }) ],
      };
    },
  },
});
