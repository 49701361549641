var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("app-loader")
    : parseInt(_vm.averagePriceStats.midPrice)
    ? _c("div", { staticClass: "estimate-result" }, [
        _c("div", { staticClass: "estimate-result__block" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "estimate-result__data",
                class: _vm.isPdf ? "col-12" : "col-12 col-lg-4"
              },
              [
                _c("h6", { staticClass: "estimate-result__data-title" }, [
                  _vm._v("\n          Рыночная цена\n        ")
                ]),
                _c(
                  "div",
                  { staticClass: "estimate-result__average-price" },
                  [
                    _c("img", {
                      staticClass: "estimate-result__average-price-icon",
                      attrs: { src: "/images/icons/rouble.svg" }
                    }),
                    _c(
                      "span",
                      { staticClass: "estimate-result__average-price-value" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.averagePriceStats.midPrice) +
                            " млн. ₽\n          "
                        )
                      ]
                    ),
                    !_vm.info.levels && !_vm.isPdf && !_vm.isDemo
                      ? _c(
                          "app-button",
                          {
                            staticClass: "estimate-result__average-price-btn",
                            attrs: { variant: "link" },
                            on: { click: _vm.openEditForm }
                          },
                          [
                            _vm._v(
                              "\n            Повысить точность\n          "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c("div", { staticClass: "estimate-result__range-price" }, [
                  _c("img", {
                    staticClass: "estimate-result__range-diagram-icon",
                    attrs: { src: "/images/icons/diagram.svg" }
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "estimate-result__range-price-minValue-wrapper"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "estimate-result__average-price-value estimate-result__average-price-value--minMaxValue"
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.averagePriceStats.lowPrice) + " млн. ₽"
                          )
                        ]
                      ),
                      _c(
                        "span",
                        { staticClass: "estimate-result__range-price-name" },
                        [_vm._v("Диапазон цен")]
                      )
                    ]
                  ),
                  _c(
                    "span",
                    {
                      staticClass:
                        "estimate-result__average-price-value estimate-result__average-price-value--minMaxValue"
                    },
                    [
                      _vm._v(
                        "- " +
                          _vm._s(_vm.averagePriceStats.highPrice) +
                          " млн. ₽"
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "estimate-result__info" }, [
                  _vm._v(
                    "\n          Рассчитано на основе похожих объектов, ранее размещенных объявлений на\n          Авито, Яндекс, Циан.\n        "
                  )
                ]),
                !_vm.rated && !_vm.isDemo
                  ? _c("div", { staticClass: "estimate-result__approve" }, [
                      _c(
                        "p",
                        { staticClass: "estimate-result__approve-text" },
                        [
                          _vm._v(
                            "\n            Подтвердите оценку или повысьте точность\n          "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "estimate-result__btn-wrapper" },
                        [
                          _c(
                            "app-button",
                            {
                              staticClass:
                                "estimate-result__btn estimate-result__btn--accept",
                              attrs: { loading: _vm.loadingBtn }
                            },
                            [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.rateEstimate("agree")
                                    }
                                  }
                                },
                                [_vm._v(" Согласен ")]
                              )
                            ]
                          ),
                          _c(
                            "app-button",
                            {
                              staticClass:
                                "estimate-result__btn estimate-result__btn--reject",
                              attrs: { loading: _vm.loadingBtn }
                            },
                            [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.rateEstimate("disagree")
                                    }
                                  }
                                },
                                [_vm._v(" Не согласен ")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                !_vm.info.levels && !_vm.isPdf && !_vm.isDemo
                  ? _c(
                      "app-button",
                      {
                        staticClass: "estimate-result__raise-btn",
                        on: { click: _vm.openEditForm }
                      },
                      [_vm._v("\n          Повысить точность\n        ")]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "estimate-result__graphic",
                class: _vm.isPdf ? "col-12" : "col-12 col-lg-8"
              },
              [
                _c("app-average-price-chart", {
                  attrs: {
                    average_price: _vm.averagePrice,
                    current_price: _vm.info.price
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    : _c("div", [
        _vm._v("\n  Не удалось найти данные о рыночной стоимости объекта\n")
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }