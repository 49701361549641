




























































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import get from 'lodash/get';

@Component({
  components: { AppReportItem },
  props: {
    report: Object,
    status: String,
    images: Array,
    orderIsDone: Boolean,
    missingRequirements: Array,
    openAddFieldDialog: Function,
    updateMap: Function,
    task: Object,
    isPhysical: Boolean,
    onlyOwners: Boolean,
    map: Object,
  },
})
export default class AppSnilsReport extends Vue {
  // props
  missingRequirements: any[];
  report: any;
  status: string;
  images: any[];
  orderIsDone: boolean;
  task: any;
  isPhysical: boolean;
  onlyOwners: boolean;
  get = get;
  openAddFieldDialog: (val: string) => any;
  updateMap: (taskName: string, field: string, value: any) => void;
  opened: boolean = false;
  map: any;
  resultStatusTrace: any = null;

  created() {
    this.resultStatusTrace = this.resultStatus;
  }

  get statusDone() {
    return this.status === 'done';
  }

  get resultStatus() {
    if (this.task && this.statusDone) {
      if (get(this.task, 'task_result.result.data.isValid')) {
        this.updateColor('success');
        return 'not found';
      } else {
        this.updateColor('danger');
        return 'found';
      }
    }
    this.updateColor('info');
    return 'no data';
  }

  updateColor(color: string) {
    this.updateMap('snils', 'color', color);
  }
}
