export const BankruptCaseTypes = {
  Observation: 'Наблюдение',
  FinancialRecovery: 'Финансовое оздоровление',
  ExternalManagement: 'Внешнее управление',
  Receivership: 'Конкурсное производство',
  DebtRestructuring: 'Реструктуризация долгов',
  PropertySale: 'Реализация имущества',
  ProceedingsStopped: 'Производство по делу прекращено',
  RefusedDeclarationDebtorBankrupt: 'Отказ в признании должника банкротом',
  ProceedingsFinished: 'Производство по делу завершено',
  Other: 'Иная причина завершения/прекращения дела',
};
