var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-5 check-fiz counter-agent-page" },
    _vm._l(_vm.report.owners, function(item) {
      return _c("div", { key: item.id }, [
        _c(
          "div",
          {
            staticClass: "check-fiz__owner-info",
            attrs: { id: "ownerInfoHeader" }
          },
          [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row check-fiz__owner-info-top" }, [
                !_vm.$isServer
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-md mb-3 mb-md-0 d-none d-md-block"
                      },
                      [
                        _c(
                          "div",
                          {},
                          [
                            _c(
                              "nuxt-link",
                              {
                                staticClass: "check-fiz__link",
                                attrs: { to: { name: "user-orders" } }
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-chevron-left mr-1"
                                }),
                                _vm._v(
                                  "\n                Мои заказы\n              "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "col-12 col-md-auto" }, [
                  _c(
                    "div",
                    { staticClass: "check-fiz__owner-number" },
                    [
                      _c("div", [
                        _c("span", { staticClass: "check-fiz__date-order" }, [
                          _vm._v(
                            "\n                  Дата проверки: " +
                              _vm._s(
                                _vm._f("date")(
                                  _vm.report.order.created_at,
                                  "DD.MM.YYYY HH:mm:ss"
                                )
                              ) +
                              "\n                "
                          )
                        ])
                      ]),
                      _c("div", {
                        staticClass:
                          "check-fiz__owner-info-divider d-none d-md-block"
                      }),
                      !_vm.demo
                        ? _c(
                            "div",
                            { staticClass: "check-fiz__order-number" },
                            [
                              _vm._v(
                                "\n                Номер заказа:\n                " +
                                  _vm._s(_vm.report.order.order_item_id) +
                                  "\n              "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.allowPdfDownload && !_vm.demo
                        ? [
                            _c("div", {
                              staticClass:
                                "check-fiz__owner-info-divider d-none d-md-block"
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "check-fiz__download-pdf",
                                on: { click: _vm.download }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src:
                                      "/rc-modern/Convert-Pdf-2--Streamline-Core.svg.svg"
                                  }
                                }),
                                _c("span", [
                                  _vm._v(
                                    "\n                    скачать PDF\n                  "
                                  )
                                ])
                              ]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ])
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "row align-items-center justify-content-between flex-md-nowrap"
                },
                [
                  _c("div", { staticClass: "col-auto" }, [
                    _c("div", { staticClass: "check-fiz__owner-info-person" }, [
                      _c("div", {}, [
                        _c("div", {}, [
                          _c(
                            "div",
                            { staticClass: "check-fiz__owner-info-name" },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "counter-agent-header__status-name"
                                  },
                                  [
                                    _vm.companyInfo["НаимЮЛСокр"]
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.companyInfo["НаимЮЛСокр"]
                                              ) +
                                              "\n                        "
                                          )
                                        ])
                                      : _vm.companyInfo["НаимВидИП"]
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                          ИП " +
                                              _vm._s(_vm.companyInfo["ФИО"]) +
                                              "\n                        "
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm.companyInfo["Активность"] ===
                                      "Действующее" ||
                                    _vm.companyInfo["Статус"] === "Действующее"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "counter-agent-header__status"
                                          },
                                          [
                                            _vm._v(
                                              "\n                          Действующее\n                        "
                                            )
                                          ]
                                        )
                                      : _vm.companyInfo["Активность"]
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "counter-agent-header__status counter-agent-header__status--danger"
                                          },
                                          [
                                            _vm._v(
                                              "\n                          " +
                                                _vm._s(
                                                  _vm.companyInfo["Активность"]
                                                ) +
                                                "\n                        "
                                            )
                                          ]
                                        )
                                      : _vm.companyInfo["Статус"]
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "counter-agent-header__status counter-agent-header__status--danger"
                                          },
                                          [
                                            _vm._v(
                                              "\n                          " +
                                                _vm._s(
                                                  _vm.companyInfo["Статус"]
                                                ) +
                                                "\n                        "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _vm.companyInfo["НаимЮЛПолн"]
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "counter-agent-header__fullName"
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.companyInfo["НаимЮЛПолн"]
                                            ) +
                                            "\n                      "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _c("div", {
                                staticClass:
                                  "check-fiz__owner-info-name-divider"
                              })
                            ]
                          )
                        ])
                      ])
                    ])
                  ])
                ]
              )
            ])
          ]
        ),
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row flex-nowrap" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-12 col-lg fizcheck-report-main-section counter-agent-main-section"
              },
              [
                _c("div", { staticClass: "report-panel-modern gap-24" }, [
                  _c("div", { staticClass: "person-info-modern mt-0" }, [
                    _c(
                      "div",
                      { staticClass: "row person-info-modern__section" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "person-info-modern__item col-12 col-md-4 col-lg"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "person-info-modern__title" },
                              [
                                _vm._v(
                                  "\n                    ОГРН\n                  "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "person-info-modern__data" },
                              [
                                _vm.companyInfo["ОГРН"]
                                  ? [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.companyInfo["ОГРН"]) +
                                          "\n                    "
                                      )
                                    ]
                                  : _vm.companyInfo["ОГРНИП"]
                                  ? [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.companyInfo["ОГРНИП"]) +
                                          "\n                    "
                                      )
                                    ]
                                  : [
                                      _vm._v(
                                        "\n                      Отстутствует\n                    "
                                      )
                                    ]
                              ],
                              2
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "person-info-modern__item col-12 col-md-4 col-lg"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "person-info-modern__title" },
                              [
                                _vm._v(
                                  "\n                    ИНН\n                  "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "person-info-modern__data" },
                              [
                                _vm.companyInfo["ИНН"]
                                  ? [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.companyInfo["ИНН"]) +
                                          "\n                    "
                                      )
                                    ]
                                  : _vm.companyInfo["ИННФЛ"]
                                  ? [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.companyInfo["ИННФЛ"]) +
                                          "\n                    "
                                      )
                                    ]
                                  : [
                                      _vm._v(
                                        "\n                      Отстутствует\n                    "
                                      )
                                    ]
                              ],
                              2
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "person-info-modern__item col-12 col-md-4 col-lg"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "person-info-modern__title" },
                              [
                                _vm._v(
                                  "\n                    КПП\n                  "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "person-info-modern__data" },
                              [
                                _vm.companyInfo["КПП"]
                                  ? [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.companyInfo["КПП"]) +
                                          "\n                    "
                                      )
                                    ]
                                  : [
                                      _vm._v(
                                        "\n                      Отстутствует\n                    "
                                      )
                                    ]
                              ],
                              2
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "person-info-modern__item col-12 col-md-4 col-lg"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "person-info-modern__title" },
                              [
                                _vm._v(
                                  "\n                    Дата регистрации\n                  "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "person-info-modern__data" },
                              [
                                _vm.companyInfo["ДатаОГРН"]
                                  ? [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(_vm.companyInfo["ДатаОГРН"]) +
                                          "\n                    "
                                      )
                                    ]
                                  : [
                                      _vm._v(
                                        "\n                      Отстутствует\n                    "
                                      )
                                    ]
                              ],
                              2
                            )
                          ]
                        ),
                        _vm.companyInfo["ДатаПрекрЮЛ"]
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "person-info-modern__item col-12 col-md-4 col-lg"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "person-info-modern__title" },
                                  [
                                    _vm._v(
                                      "\n                    Дата прекращения деятельности\n                  "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "person-info-modern__data" },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.companyInfo["ДатаПрекрЮЛ"]) +
                                        "\n                  "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "row person-info-modern__section" },
                      [
                        _vm.companyInfo["Адрес"]
                          ? _c(
                              "div",
                              {
                                staticClass: "person-info-modern__item col-12"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "person-info-modern__title" },
                                  [
                                    _vm._v(
                                      "\n                    Юридический адрес\n                  "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "person-info-modern__data" },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.companyInfo["Адрес"]) +
                                        "\n                  "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "row person-info-modern__section" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "person-info-modern__item col-12 col-md-auto"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "person-info-modern__title" },
                              [
                                _vm._v(
                                  "\n                    Специальный налоговый режим\n                  "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "person-info-modern__data" },
                              [
                                _vm.companyInfo["НалогРежим"]
                                  ? [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.companyInfo["НалогРежим"]
                                          ) +
                                          "\n                    "
                                      )
                                    ]
                                  : [
                                      _vm._v(
                                        "\n                      Отстутствует\n                    "
                                      )
                                    ]
                              ],
                              2
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "person-info-modern__item col-12 col-md-auto offset-md-1"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "person-info-modern__title" },
                              [
                                _vm._v(
                                  "\n                    Уставный капитал\n                  "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "person-info-modern__data" },
                              [
                                _vm.companyInfo["СумКап"]
                                  ? [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.companyInfo["СумКап"]
                                            )
                                          ) +
                                          "\n                    "
                                      )
                                    ]
                                  : [
                                      _vm._v(
                                        "\n                      Отстутствует\n                    "
                                      )
                                    ]
                              ],
                              2
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "row person-info-modern__section" },
                      [
                        _c(
                          "div",
                          { staticClass: "person-info-modern__item col-12" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "person-info-modern__title d-flex justify-content-between"
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                      Основной вид деятельности\n                    "
                                  )
                                ]),
                                _vm.companyInfo["СвОКВЭДДоп"] &&
                                _vm.companyInfo["СвОКВЭДДоп"].length > 1
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "link",
                                          on: {
                                            click: function($event) {
                                              _vm.openedOkved = !_vm.openedOkved
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Все виды деятельности (" +
                                              _vm._s(
                                                _vm.companyInfo["СвОКВЭДДоп"]
                                                  .length
                                              ) +
                                              ")\n                        "
                                          ),
                                          _c("i", {
                                            staticClass: "fas fa-chevron-down"
                                          })
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            !_vm.openedOkved
                              ? _c(
                                  "div",
                                  { staticClass: "person-info-modern__data" },
                                  [
                                    _vm.companyInfo["СвОКВЭДДоп"] &&
                                    _vm.companyInfo["СвОКВЭДДоп"].length
                                      ? [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.companyInfo[
                                                  "СвОКВЭДДоп"
                                                ][0]["КодОКВЭД"]
                                              ) +
                                              "\n                      "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-400 pl-2"
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.companyInfo[
                                                      "СвОКВЭДДоп"
                                                    ][0]["НаимОКВЭД"]
                                                  ) +
                                                  "\n                      "
                                              )
                                            ]
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                      Отстутствует\n                    "
                                          )
                                        ]
                                  ],
                                  2
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "person-info-modern__data d-flex flex-column gap-8"
                                  },
                                  _vm._l(
                                    _vm.companyInfo["СвОКВЭДДоп"],
                                    function(elem) {
                                      return _c(
                                        "div",
                                        { key: elem["НаимОКВЭД"] },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(elem["КодОКВЭД"]) +
                                              "\n                      "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-400 pl-2"
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(elem["НаимОКВЭД"]) +
                                                  "\n                      "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                          ]
                        )
                      ]
                    )
                  ])
                ]),
                _c("app-counter-agent-ceo", {
                  attrs: { id: "ceo", report: _vm.companyInfo }
                }),
                _c("app-counter-agent-founders", {
                  attrs: { id: "founders", report: _vm.companyInfo }
                }),
                _c("app-counter-agent-staff", {
                  attrs: { id: "staff", report: _vm.companyInfo }
                }),
                _c("app-counter-agent-finance", {
                  attrs: { id: "finance", report: _vm.companyInfo }
                }),
                _c("app-bankrupt-report", {
                  attrs: {
                    id: "bankrupt",
                    report: _vm.owner,
                    task: _vm.tasks.bankrupt,
                    status: _vm.getTaskStatus(_vm.tasks.bankrupt, "bankrupt"),
                    images: _vm.get(_vm.tasks.bankrupt, "task_result.images"),
                    "order-is-done": _vm.orderIsDone,
                    map: _vm.getMapItem("bankrupt"),
                    "only-owners": true,
                    "missing-requirements": _vm.getMissingRequirements(
                      _vm.tasks.bankrupt
                    ),
                    "open-add-field-dialog": function() {
                      return false
                    },
                    "update-map": _vm.updateTaskMap
                  }
                }),
                _c("app-debt-report", {
                  attrs: {
                    id: "bailiffDebt",
                    report: _vm.owner,
                    task: _vm.tasks.bailiffDebt,
                    status: _vm.getTaskStatus(
                      _vm.tasks.bailiffDebt,
                      "bailiffDebt"
                    ),
                    images: _vm.get(
                      _vm.tasks.bailiffDebt,
                      "task_result.images"
                    ),
                    "order-is-done": _vm.orderIsDone,
                    map: _vm.getMapItem("bailiffDebt"),
                    "only-owners": true,
                    "is-physical": false,
                    "missing-requirements": _vm.getMissingRequirements(
                      _vm.tasks.bailiffDebt
                    ),
                    "open-add-field-dialog": function() {
                      return false
                    },
                    "update-map": _vm.updateTaskMap
                  }
                }),
                _c(
                  "app-arbitr-report",
                  {
                    attrs: {
                      id: "sudStatistic",
                      report: _vm.owner,
                      task: _vm.tasks.arbitrReport,
                      status: _vm.getTaskStatus(
                        _vm.tasks.arbitrReport,
                        "arbitrReport"
                      ),
                      images: _vm.get(
                        _vm.tasks.arbitrReport,
                        "task_result.images"
                      ),
                      "order-is-done": _vm.orderIsDone,
                      map: _vm.getMapItem("arbitrReport"),
                      "only-owners": true,
                      "is-physical": false,
                      "missing-requirements": _vm.getMissingRequirements(
                        _vm.tasks.arbitrReport
                      ),
                      "open-add-field-dialog": function() {
                        return false
                      },
                      "update-map": _vm.updateTaskMap,
                      expand: true
                    }
                  },
                  [
                    _c("app-counter-agent-sud-statistic", {
                      ref: "sudStat",
                      refInFor: true,
                      attrs: { report: _vm.companyInfo }
                    })
                  ],
                  1
                ),
                _c("app-counter-agent-unfair", {
                  attrs: { id: "unfair", report: _vm.companyInfo }
                }),
                _c("app-foreign-agent-report", {
                  attrs: {
                    id: "foreignAgent",
                    report: _vm.owner,
                    task: _vm.tasks.foreignAgent,
                    status: _vm.getTaskStatus(_vm.tasks.foreignAgent),
                    images: _vm.get(
                      _vm.tasks.foreignAgent,
                      "task_result.images"
                    ),
                    "order-is-done": _vm.orderIsDone,
                    map: _vm.getMapItem("foreignAgent"),
                    "only-owners": true,
                    "is-physical": false,
                    "missing-requirements": _vm.getMissingRequirements(
                      _vm.tasks.foreignAgent
                    ),
                    "open-add-field-dialog": function() {
                      return false
                    },
                    "update-map": _vm.updateTaskMap
                  }
                }),
                _c("app-counter-agent-procurement", {
                  attrs: { id: "procurement", report: _vm.companyInfo }
                }),
                _c("app-counter-agent-trademarks", {
                  attrs: { id: "trademarks", report: _vm.companyInfo }
                }),
                _c("app-counter-agent-license", {
                  attrs: { id: "license", report: _vm.companyInfo }
                }),
                _c("app-counter-agent-rkn", {
                  attrs: { id: "rkn", report: _vm.companyInfo }
                }),
                _c("app-counter-agent-requisites", {
                  attrs: { id: "requisites", report: _vm.companyInfo }
                }),
                _c("app-counter-agent-taxes", {
                  attrs: { id: "taxes", report: _vm.companyInfo }
                })
              ],
              1
            ),
            _vm.scrollActiveList.length && !_vm.$isServer
              ? _c("div", { staticClass: "col-auto d-none d-lg-block" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "fizcheck-side-navigation mb-3 mb-lg-0 rc-sticky",
                      attrs: { id: "fizcheck-side-nav" }
                    },
                    [
                      _c(
                        "scrollactive",
                        {
                          attrs: {
                            "highlight-first-item": true,
                            offset: 400,
                            "scroll-offset": 20
                          }
                        },
                        [
                          _c(
                            "ul",
                            _vm._l(_vm.scrollActiveList, function(elem) {
                              return _c("li", { key: elem.name }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "scrollactive-item",
                                    attrs: { href: "#" + elem.name }
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "fizcheck-side-navigation__dot",
                                      class:
                                        "fizcheck-side-navigation__dot--" +
                                        elem.color
                                    }),
                                    elem.ruName
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(elem.ruName) +
                                              "\n                    "
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(elem.nameCap) +
                                              "\n                    "
                                          )
                                        ])
                                  ]
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }