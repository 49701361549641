var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPhysical
    ? _c(
        "app-report-item",
        {
          attrs: {
            title: "Социальные сети",
            icon: "users",
            status: _vm.status,
            images: _vm.images,
            opened: !_vm.havePhone || _vm.resultStatus === "found",
            "missing-requirements": _vm.missingRequirements,
            color: _vm.map.color
          },
          scopedSlots: _vm._u(
            [
              _vm.resultStatus === "found"
                ? {
                    key: "extra-content",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "social-media-modern social-media-modern__result-wrapper"
                          },
                          [
                            _vm.resultStatus === "found"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "social-media-modern__result"
                                  },
                                  [
                                    _vm.groupedSources.VK.length
                                      ? _c(
                                          "app-social-media-report-elem",
                                          {
                                            attrs: {
                                              name: "ВКонтакте",
                                              result: _vm.groupedSources.VK,
                                              "is-pdf": _vm.isPdf
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fab fa-vk"
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.groupedSources.OK.length
                                      ? _c(
                                          "app-social-media-report-elem",
                                          {
                                            attrs: {
                                              name: "Одноклассники",
                                              result: _vm.groupedSources.OK,
                                              "is-pdf": _vm.isPdf
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fab fa-odnoklassniki-square"
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.groupedSources.WhatsApp.length
                                      ? _c(
                                          "app-social-media-report-elem",
                                          {
                                            attrs: {
                                              name: "What’s App",
                                              result:
                                                _vm.groupedSources.WhatsApp,
                                              "is-pdf": _vm.isPdf
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fab fa-whatsapp"
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _c(
            "div",
            { staticClass: "report-item-modern__subtitle" },
            [
              _vm.resultStatus !== "no data"
                ? [
                    _vm.resultStatus === "not found"
                      ? _c(
                          "div",
                          { staticClass: "social-media-modern__not-found" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "social-media-modern__not-found-icons"
                              },
                              [
                                _c("div", {}, [
                                  _c("i", {
                                    staticClass: "fab fa-lg fa-whatsapp"
                                  })
                                ]),
                                _c("div", {}, [
                                  _c("i", { staticClass: "fab fa-lg fa-vk" })
                                ]),
                                _c("div", {}, [
                                  _c("i", {
                                    staticClass:
                                      "fab fa-lg fa-odnoklassniki-square"
                                  })
                                ]),
                                _c("div", {}, [
                                  _c("i", {
                                    staticClass: "fab fa-lg fa-telegram"
                                  })
                                ]),
                                _c("div", {}, [
                                  _c("i", {
                                    staticClass: "fab fa-lg fa-facebook"
                                  })
                                ])
                              ]
                            ),
                            _c("span", [
                              _vm._v("\n          По ФИО\n          "),
                              _vm.havePhone
                                ? _c("span", [
                                    _vm._v(
                                      "\n            и телефону\n          "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(
                                "\n          совпадения не найдены\n        "
                              )
                            ])
                          ]
                        )
                      : _vm.resultStatus === "found"
                      ? _c("div", [
                          _c("span", [
                            _vm._v("\n          По ФИО\n          "),
                            _vm.havePhone
                              ? _c("span", [
                                  _vm._v("\n            и телефону\n          ")
                                ])
                              : _vm._e(),
                            _vm._v("\n          найдены совпадения\n        ")
                          ])
                        ])
                      : _vm._e()
                  ]
                : _vm.orderIsDone
                ? [
                    _c("span", { staticClass: "text-warning" }, [
                      _vm._v(" Недостаточно данных ")
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }