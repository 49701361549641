








































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import has from 'lodash/has';
import AppReportCollapseItem from 'shared/components/FizCheck/ReportCollapseItem.vue';

@Component({
  components: { AppReportCollapseItem },
  props: {
    result: Array,
    opened: Boolean,
  },
})
export default class AppDisqualifiedReportDetails extends Vue {
  // props
  result: any;
  has = has;
  opened: boolean;

  getStatus(value: string) {
    switch (value) {
      case 'active':
        return 'Активный';
      case 'unknown':
        return 'Не удалось вычислить';
      case 'not_active':
        return 'Не активный';
      default:
        return 'Нет данных';
    }
  }
}
