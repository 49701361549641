var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data && _vm.data.length
    ? _c("div", { staticClass: "report-item-result__content-section" }, [
        _c(
          "div",
          { staticClass: "report-item-result__content-section-title" },
          [
            _vm.nameLot
              ? _c("h4", [_vm._v("\n      " + _vm._s(_vm.nameLot) + "\n    ")])
              : _c("h4", [_vm._v("\n      " + _vm._s(_vm.name) + "\n    ")])
          ]
        ),
        _c(
          "div",
          { staticClass: "report-item-result__content-list" },
          [
            _vm._l(_vm.dataSliced, function(elem, i) {
              return _c(
                "div",
                {
                  key: !!_vm.fieldName ? elem[_vm.fieldName] + i : elem + i,
                  staticClass: "report-item-result__content-list-elem"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "report-item-result__content-list-elem-title"
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.name) +
                          " №" +
                          _vm._s(i + 1) +
                          "\n      "
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "report-item-result__content-list-elem-result"
                    },
                    [
                      _vm.name === "Паспорт"
                        ? [_vm._v("\n          " + _vm._s(elem) + "\n        ")]
                        : _vm.fieldName
                        ? [
                            _vm._v(
                              "\n          " +
                                _vm._s(elem[_vm.fieldName]) +
                                "\n        "
                            )
                          ]
                        : [_vm._v("\n          " + _vm._s(elem) + "\n        ")]
                    ],
                    2
                  )
                ]
              )
            }),
            _vm.data.length > 3 && !_vm.$isServer
              ? _c(
                  "span",
                  {
                    staticClass: "link",
                    on: {
                      click: function($event) {
                        _vm.showAll = !_vm.showAll
                      }
                    }
                  },
                  [
                    _vm.showAll
                      ? [_vm._v("\n        Скрыть\n      ")]
                      : [
                          _vm._v(
                            "\n        Показать еще (" +
                              _vm._s(_vm.data.length - 3) +
                              ")\n      "
                          )
                        ]
                  ],
                  2
                )
              : _vm._e()
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }