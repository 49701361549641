import Vue from 'vue';
import Component from 'vue-class-component';
import { includes, isBoolean } from 'lodash';

@Component({
  name: 'AppSurveysMixin',
})
export default class AppSurveysMixin extends Vue {
  dictonary: any = {
    birthday: 'Дата рождения',
    first_name: 'Имя',
    patronymic: 'Отчество',
    surname: 'Фамилия',
    registration_date: 'Дата регистрации брака',
    divorce_date: 'Дата развода',
  };

  // Текущие собственники из Отчеты ЕГРН об объекте
  isDateField(name: string) {
    return includes(name, 'date') || includes(name, 'birthday');
  }

  isBooleanField(val: any) {
    return isBoolean(val);
  }

  translate(word: string) {
    return this.dictonary[word];
  }
}
