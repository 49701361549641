

















































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    job: Object,
  },
})
export default class AdminControlForm extends Vue {
  // props

  // data()
  job: any;
  model: any = null;
  // computed

  // lifecycle hooks
  async created() {
    const res: any = {};
    this.job.inputs.forEach((elem: any) => {
      if (elem.value_type === 'bool') {
        res[elem.index] = false;
      } else {
        res[elem.index] = '';
      }
    });
    this.model = res;
  }

  onSubmit() {
    for (const elem in this.model) {
      this.model[elem] = this.model[elem].toString();
      const input = this.job.inputs.find((el: any) => {
        return el.index === parseInt(elem);
      });
      if (input.value_type === 'data') {
        this.model[elem] = new Date(this.model[elem]).toISOString();
      }
    }
    const data = {
      name: this.job.name,
      task_type_id: '7132e8cf-9be2-4566-9f30-3b9e6300d71c',
      job_type: this.job.type,
      metadata: this.model,
    };
    try {
      this.$api.reset.Job(data);
      this.$noty.success({ text: 'Успешно' });
    } catch (err) {
      console.log(err);
      this.$noty.error({ text: 'Ошибка' });
    }
  }
}
