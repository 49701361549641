





























import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'AppEditTextSettingsDialog',

  popupOptions: {
    size: 'sm',
  },

  props: {
    item: Object,
  },
})
export default class AppCreateSettingsDialog extends Vue {
  // props
  item: Admin.Settings.Item;

  // data
  loading: boolean = false;
  model: ApiTypes.Settings.UpdateTextBody = {
    key: this.item.key,
    text: this.item.text,
  }

  async submit() {
    this.loading = true;

    try {
      await this.$store.dispatch('settings/updateTextValue', this.model);

      this.$noty.info({ text: 'Настройки обновлены' });

      this.$dialog.close();
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: this.$api._getErrorMessage(error) });
    }

    this.loading = false;
  }
}
