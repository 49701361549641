













































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  popupOptions: {
    size: 'sm',
  },

  props: {
    onSubmit: Function,
    egrnId: String,
    rightListId: String,
    newRiskId: String,
  },
})

export default class UpdateReportDialog extends Vue {
  // props
  public onSubmit: Function;
  egrnId: string;
  rightListId: string;
  newRiskId: string;

  // data
  order = {
    orderRiskID: '',
    orderEgrnID: '',
    orderRightListID: '',
  }

  // methods=3 9399
  submit() {
    this.onSubmit(this.order.orderRiskID, parseInt(this.order.orderEgrnID), parseInt(this.order.orderRightListID));
  }

  // lifecycle
  async created() {
    this.order.orderEgrnID = this.egrnId;
    this.order.orderRightListID = this.rightListId;
    this.order.orderRiskID = this.newRiskId;
  }
}

