





































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import get from 'lodash/get';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';

@Component({
  components: { AppReportItem },
  props: {
    report: Object,
  },
})
export default class AppCounterAgentTaxes extends Vue {
  // props
  missingRequirements: any[];
  report: any;
  status: string;
  images: any[];
  orderIsDone: boolean;
  task: any;
  isPhysical: boolean;
  onlyOwners: boolean;
  openAddFieldDialog: (val: string) => any;
  updateMap: (taskName: string, field: string, value: any) => void;
  map: any;
  lastYear: number = new Date().getFullYear() - 1;

  get allTaxes() {
    const currentTax = get(this.report, 'УплачСтрахВзнос', null);
    const taxHist = get(this.report, 'УплачСтрахВзносИст', []);
    if (isEmpty(currentTax)) {
      return [];
    }
    const arr = [
      {
        Год: this.lastYear,
        СтрахВзнос: currentTax,
      },
    ];
    return arr.concat(taxHist);
  }

  get groupedTaxes() {
    let arr: any = [];
    if (!this.allTaxes.length) {
      return null;
    }
    this.allTaxes.forEach((el: any) => {
      el.sum = sumBy(el['СтрахВзнос']['СвУплСумНал'], (item: any) => {
        return parseInt(item['@attributes']['СумУплНал']);
      });
      const res = map(el['СтрахВзнос']['СвУплСумНал'], (elem: any) => {
        elem.year = el['Год'];
        return elem;
      });
      arr = arr.concat(res);
    });
    return groupBy(arr, '@attributes.НаимНалог');
  }
}
