var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column gap-24" },
    [
      _vm.relatedData.list_users.length
        ? _c("div", { staticClass: "report-item-result__content-section" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "report-item-result__content-list" },
              _vm._l(_vm.relatedData.list_users, function(user, i) {
                return _c(
                  "div",
                  {
                    key: user + i,
                    staticClass: "report-item-result__content-list-elem"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "report-item-result__content-list-elem-title"
                      },
                      [
                        _vm._v(
                          "\n          Лицо №" + _vm._s(i + 1) + "\n        "
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "report-item-result__content-list-elem-result"
                      },
                      [_vm._v("\n          " + _vm._s(user) + "\n        ")]
                    )
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _c("app-fizcheck-expert-summary-result-elem", {
        attrs: {
          "field-name": "",
          data: _vm.relatedData.list_passport,
          name: "Паспорт",
          "name-lot": "Паспорта"
        }
      }),
      _c("app-fizcheck-expert-summary-result-elem", {
        attrs: {
          "field-name": "value",
          data: _vm.relatedData.list_phones,
          name: "Телефон",
          "name-lot": "Телефоны"
        }
      }),
      _c("app-fizcheck-expert-summary-result-elem", {
        attrs: {
          "field-name": "",
          data: _vm.relatedData.list_autos,
          name: "Автомобиль",
          "name-lot": "Автомобили"
        }
      }),
      _c("app-fizcheck-expert-summary-result-elem", {
        attrs: {
          "field-name": "number",
          data: _vm.relatedData.list_snils,
          name: "СНИЛС"
        }
      }),
      _c("app-fizcheck-expert-summary-result-elem", {
        attrs: { "field-name": "", data: _vm.relatedData.list_inn, name: "ИНН" }
      }),
      _c("app-fizcheck-expert-summary-result-elem", {
        attrs: {
          "field-name": "value",
          data: _vm.relatedData.list_emails,
          name: "Email"
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "report-item-result__content-section-title" },
      [_c("h4", [_vm._v("\n        Лица\n      ")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }