






































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import has from 'lodash/has';
import Component from 'vue-class-component';
import { BankruptCaseTypes } from 'shared/utils/constants/bankrupt';

@Component({
  popupOptions: {
    name: 'popup_side popup_sud-details',
  },

  props: {
    result: Object,
    isPhysical: Boolean,
  },
})
export default class AppBankruptDetails extends Vue {
  // props
  result: any;
  isPhysical: boolean;
  has = has;

  showDetails = false;
  details: any = null;

  get caseTypes() {
    return BankruptCaseTypes;
  }

  get fedresursLink() {
    return `https://fedresurs.ru/${this.isPhysical ? 'person/' : 'company/'}${this.result.guid}`;
  }

  toggleDetails(data: any) {
    this.showDetails = !this.showDetails;

    if (this.showDetails) {
      this.details = data;
    } else {
      this.details = null;
    }
  }
}
