var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "tippy",
          rawName: "v-tippy:[status]",
          value: _vm.hasStatuses ? _vm.tippyOptions : null,
          expression: "hasStatuses ? tippyOptions : null",
          arg: _vm.status
        }
      ],
      staticClass: "badge-wrap"
    },
    [
      _c(
        "div",
        {
          staticClass: "badge",
          class: _vm.$helper_classByStatus(_vm.orderItem.status)
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$helper_textByStatus(_vm.orderItem.status)) +
              "\n  "
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "small", attrs: { "data-tippy-content": "hide" } },
        _vm._l(_vm.orderItem.item_statuses, function(itemStatus) {
          return _c(
            "div",
            {
              key: itemStatus.ID,
              staticClass: "d-flex justify-content-between pb-1"
            },
            [
              _c("span", { staticClass: "mr-3" }, [
                _c("div", {
                  staticClass: "status-circle",
                  class: _vm.$helper_classByStatus(itemStatus.Status)
                }),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$helper_textByStatus(itemStatus.Status)) +
                    "\n      "
                )
              ]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("date")(itemStatus.CreatedAt, "DD.MM.YYYY HH:mm:ss")
                  )
                )
              ])
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }