




















































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportCollapseItem from 'shared/components/FizCheck/ReportCollapseItem.vue';
// import get from 'lodash/get';

@Component({
  components: { AppReportCollapseItem },
  props: {
    result: Object,
    opened: Boolean,
  },
})
export default class AppPledgeReportDetails extends Vue {
  // props
  result: any[];
  // data
  opened: boolean;

  get cases() {
    return this.result;
  }

  get records() {
    if (!this.cases) {
      return null;
    }
    const result: any[] = [];
    this.cases.forEach((elem: any) => {
      let recordResult: any = {
        properties: [],
      };
      if (elem.record && elem.record.length) {
        elem.record.forEach((record: any) => {
          const obj = this.toObject(record.fields);
          if (obj.Type === 'properties') {
            recordResult.properties.push(obj);
          } else {
            recordResult = Object.assign(recordResult, obj);
          }
        });
        result.push(recordResult);
      }
    });
    return result;
  }

  toObject(arr: any[]) {
    const res: any = {};
    arr.forEach((elem) => {
      res[elem.field_name] = elem.field_value;
    });
    return res;
  }
}
