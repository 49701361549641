var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sud-container" }, [
    _c("div", { staticClass: "sud-items" }, [
      _c(
        "div",
        { staticClass: "popup__header" },
        [
          _c("div", { staticClass: "popup__title" }, [
            _vm._v("\n        Данные о залоге\n      ")
          ]),
          _c("app-dialog-close")
        ],
        1
      ),
      _c("div", { staticClass: "popup__body" }, [
        _vm.result
          ? _c(
              "div",
              { staticClass: "sud-details__content legacy-container" },
              [
                [
                  _vm.result.referenceNumber
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v(
                            "\n              Номер уведомления о возникновении залога\n            "
                          )
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.result.referenceNumber) +
                              "\n            "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.result.referenceStatus
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v("\n              Статус\n            ")
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.result.referenceStatus) +
                              "\n            "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.result.registerDate
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v(
                            "\n              Дата регистрации\n            "
                          )
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("date")(
                                  _vm.result.registerDate,
                                  "DD.MM.YYYY"
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.result.pledgors_name
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v("\n              Залогодатель\n            ")
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.result.pledgors_name) +
                              "\n            "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.result.pledgors_birth
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v("\n              Дата рождения\n            ")
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.result.pledgors_birth) +
                              "\n            "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.result.pledgors_region
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v("\n              Регион\n            ")
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.result.pledgors_region) +
                              "\n            "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.result.pledgees_name
                    ? _c("div", { staticClass: "sud-details-item" }, [
                        _c("div", { staticClass: "sud-details-item__title" }, [
                          _vm._v(
                            "\n              Залогодержатель\n            "
                          )
                        ]),
                        _c("div", { staticClass: "sud-details-item__value" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.result.pledgees_name) +
                              "\n            "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.result.properties && _vm.result.properties.length
                    ? _c(
                        "div",
                        { staticClass: "sud-details-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "sud-details-item__title" },
                            [_vm._v("\n              Имущество\n            ")]
                          ),
                          _vm._l(_vm.result.properties, function(item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "mb-2" },
                              [
                                item.properties_typetext
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "sud-details-item__value"
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-muted small" },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(index + 1) +
                                                ".\n                "
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.properties_typetext) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                item.properties_description
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "sud-details-item__value"
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-muted small" },
                                          [
                                            _vm._v(
                                              "\n                  Описание\n                "
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              item.properties_description
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                item.properties_vin
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "sud-details-item__value"
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-muted small" },
                                          [
                                            _vm._v(
                                              "\n                  VIN:\n                "
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.properties_vin) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                item.properties_status
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "sud-details-item__value"
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-muted small" },
                                          [
                                            _vm._v(
                                              "\n                  Статус:\n                "
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.properties_status) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ]
              ],
              2
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }