
































































import Vue from 'vue';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import Component from 'vue-class-component';
import AppOwnerFormItemBase from './OwnerFormItemBase.vue';
import isUnderage from 'shared/utils/isUnderage';
import { OrderTypes } from 'shared/store/modules/order/types';
import { Validators } from 'shared/utils/validator';

@Component({
  components: { AppOwnerFormItemBase },
  props: {
    orderItemId: String,
    onlyPhysical: {
      type: Boolean,
      default: false,
    },
    onSuccess: {
      type: Function,
      default: noop,
    },
    rightsList: {
      type: Array,
      default: null,
    },
  },

  popupOptions: {
    disableBackdrop: true,
  },
})
export default class AddOwnerItemDialog extends Vue {
  // props
  orderItemId: string;
  onlyPhysical: boolean;
  onSuccess: () => any;
  rightsList: any[];

  // data
  loading: boolean = false;
  model: LawInformationOwner = {
    owner_type: 0,
    first: '',
    surname: '',
    patronymic: '',
    passport: '',
    birthday: '',
    region: '',
    inn: '',
    registration_number: '',
  };

  async fetchCreateOwner() {
    this.loading = true;
    console.log(this.getOwnersBody(), 'addOwner');
    try {
      await this.$api.risk.AddOwnerV2(this.getOwnersBody());
      this.$noty.success({ text: 'Данные успешно отправлены, ожидайте готовности отчёта' });
      this.$store.commit(OrderTypes.mutations.SET_OWNERS, []);
      this.$dialog.close();
      this.onSuccess();
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: 'Произошла ошибка при отправке формы' });
    }
    this.loading = false;
  }

  getOwnersBody(): LawInformationOwner[] {
    const body = {
      ...omit(this.model, 'name', 'metadata'),
      first: this.model.first,
      order_item_id: this.orderItemId,
    };

    if (this.model.birthday) {
      body.birthday = new Date(this.model.birthday).toISOString();
    } else {
      body.birthday = '';
    }

    return [ body as any ];
  }

  validationConfig(model: LawInformationOwner): ValidationConfig {
    if (model.owner_type === 0) {
      let config: ValidationConfig = {
        first: [ Validators.required, Validators.regex(/^[-а-яА-ЯёЁ\s]+$/, 'Только кириллица') ],
        surname: [ Validators.required, Validators.regex(/^[-а-яА-ЯёЁ\s]+$/, 'Только кириллица') ],
        passport: [ Validators.min(10, 'Минимальная длина 10') ],
        birthday: [ Validators.date('Неверный формат даты') ],
        region: [ Validators.required ],
        patronymic: [ Validators.regex(/^[-а-яА-ЯёЁ\s]+$/, 'Только кириллица') ],
      };

      if (isEmpty(model.passport)) {
        config = omit(config, 'passport');
      }

      if (isEmpty(model.birthday)) {
        config = omit(config, 'birthday');
      }

      if (isUnderage(model.birthday)) {
        config = omit(config, 'passport', 'region');
      }

      return config;
    } else {
      return {
        first: [ Validators.required ],
        inn: [ Validators.required, Validators.max(12, 'Максимальная длина 12') ],
        region: [ Validators.required ],
      };
    }
  }
}
