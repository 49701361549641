
















































































































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import get from 'lodash/get';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import Clipboard from 'clipboard';
import filter from 'lodash/filter';
import values from 'lodash/values';
import mergeWith from 'lodash/mergeWith';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import includes from 'lodash/includes';
import forIn from 'lodash/forIn';

import AppExtendedReport from 'shared/components/FizCheck/ExtendedReport/ExtendedReport.vue';
import AppExtendedCriminalReport from 'shared/components/FizCheck/ExtendedReport/ExtendedCriminalReport.vue';
import AppExtendedFsspReport from 'shared/components/FizCheck/ExtendedReport/ExtendedFsspReport.vue';
import AppExtendedReportAlt from 'shared/components/FizCheck/ExtendedReport/ExtendedReportAlt.vue';
import AppExtendedReportDocs from 'shared/components/FizCheck/ExtendedReport/ExtendedReportDocs.vue';
import AppExtendedReportCompanies from 'shared/components/FizCheck/ExtendedReport/ExtendedReportCompanies.vue';
import AppRiskConclusionItem from 'shared/components/RiskNew/RiskConclusionItem.vue';
import AppFizcheckExpertSummary from 'shared/components/FizcheckExpert/FizcheckExpertSummary.vue';
import AppFizcheckExpertConclusions from 'shared/components/FizcheckExpert/FizcheckExpertConclusions.vue';

@Component({
  components: {
    AppExtendedReport,
    AppExtendedReportAlt,
    AppRiskConclusionItem,
    AppExtendedCriminalReport,
    AppExtendedReportDocs,
    AppExtendedFsspReport,
    AppExtendedReportCompanies,
    AppFizcheckExpertSummary,
    AppFizcheckExpertConclusions,
  },
  props: {
    ulixes: Array,
    ulixesAlt: Array,
    task: Object,
    ulixesUser: Number,
    changeUser: Function,
    showSteps: Boolean,
  },
})
export default class AppExtendedReportWrapper extends Vue {
  // props
  ulixes: any;
  ulixesAlt: any;
  task: any;
  ulixesUser: number;
  changeUser: (i: number) => void;
  showSteps: boolean;

  // data
  openAllExtended: boolean = false;
  showExtendedReportGrouped: boolean = true;
  clipboard: Clipboard;
  showAllPhones: boolean = false;
  showAllEmails: boolean = false;
  showAllAdresses: boolean = false;
  completedProgress: number = 0;
  currentFilter: string = 'person';
  dataFilter: string = '';
  personFilter: string = '';
  findedUsers: any[] = [];

  get taskResult() {
    const res = get(this.task, 'task_result.result');
    console.log(res, '-------');
    if (!res || isString(res)) {
      return null;
    }
    return res.find((el: any) => el.name === 'sum');
  }

  get haveDocs() {
    if (this.taskResult && this.user && this.user.documents) {
      return !isEmpty(this.user.documents.passport) || !isEmpty(this.user.documents.inn) || !isEmpty(this.user.documents.snils);
    }
    return false;
  }

  get companies() {
    if (this.taskResult && this.user) {
      return get(this.user, 'documents.company');
    }
    return [];
  }

  get user() {
    return get(this.taskResult, 'users.' + this.ulixesUser);
  }

  get orderStatus() {
    return get(this.taskResult, 'order_status');
  }

  get orderIsDone() {
    return this.orderStatus === 'done';
  }

  get statusCollection() {
    if (this.showSteps && !this.taskResult) {
      return true;
    }
    return this.orderStatus === 'data_collection';
  }

  get statusAnalysis() {
    return this.orderStatus === 'data_analysis';
  }

  get statusGeneration() {
    return this.orderStatus === 'report_generation';
  }

  get statusChecking() {
    return this.orderStatus === 'documents_checking';
  }

  get phones() {
    if (!this.user || !this.user.list_phones) {
      return [];
    }
    if (this.showAllPhones) {
      return this.user.list_phones;
    }
    return this.user.list_phones.slice(0, 1);
  }

  get emails() {
    if (!this.user || !this.user.list_emails) {
      return [];
    }
    if (this.showAllEmails) {
      return this.user.list_emails;
    }
    return this.user.list_emails.slice(0, 1);
  }

  get adresses() {
    if (!this.user || !this.user.list_adresses) {
      return [];
    }
    if (this.showAllAdresses || this.$isServer) {
      return this.user.list_adresses;
    }
    return this.user.list_adresses.slice(0, 1);
  }

  get progress() {
    if (this.statusCollection) {
      return 24;
    } else if (this.statusAnalysis) {
      return 60;
    } else if (this.statusGeneration) {
      return 100;
    } else {
      return 100;
    }
  }

  get allData() {
    return this.mergeObjects(this.users);
  }

  get findedUsersData() {
    return this.mergeObjects(this.findedUsers);
  }

  get users() {
    return get(this.taskResult, 'users', []);
  }

  get ulixesAltFiltered() {
    if (!this.dataFilter && !this.personFilter) {
      return this.ulixesAltConcat;
    }
    if (this.personFilter) {
      return this.ulixesAlt;
    }
    return filter(this.ulixesAltConcat, (elem) => {
      return includes(values(elem), this.dataFilter.toUpperCase());
    });
  }

  get ulixesAltConcat() {
    const res = this.task;
    if (res && res.status === 'done' && isArray(res.task_result.result)) {
      const result = find(res.task_result.result, (el: any) => el.name === 'alternative');
      const users = get(result, 'users', []);
      let items: any = [];
      forEach(users, (user: any) => {
        items = items.concat(user.items);
      });
      return items;
    }
    return null;
  }

  get relatedDataInFilteredUlixes() {
    const findedData: any = {
      list_users: [],
      list_passport: [],
      list_phones: [],
      list_autos: [],
      list_snils: [],
      list_inn: [],
      list_emails: [],
    };
    if (!this.dataFilter) {
      return findedData;
    }
    forEach(this.ulixesAltFiltered, (elem: any) => {
      forIn(elem, (value, key) => {
        switch (key) {
          case 'ФИО':
          case 'ИМЯ':
            if (findedData.list_users.includes(value)) {
              return;
            }
            findedData.list_users.push(value);
            break;
          case 'ПАСПОРТ':
            if (findedData.list_passport.includes(value)) {
              return;
            }
            findedData.list_passport.push(value);
            break;
          case 'ТЕЛЕФОН':
          case 'ТЕЛЕФОНА':
            if (find(findedData.list_phones, { value: value })) {
              return;
            }
            findedData.list_phones.push({ value: value });
            break;
          case 'VIN':
            if (findedData.list_autos.includes(value)) {
              return;
            }
            findedData.list_autos.push(value);
            break;
          case 'СНИЛС':
            if (find(findedData.list_snils, { number: value })) {
              return;
            }
            findedData.list_snils.push({ number: value });
            break;
          case 'ИНН':
            if (findedData.list_inn.includes(value)) {
              return;
            }
            findedData.list_inn.push(value);
            break;
          case 'EMAIL':
          case 'ПОЧТА':
            if (find(findedData.list_emails, { value: value })) {
              return;
            }
            findedData.list_emails.push({ value: value });
            break;
        }
      });
    });
    return findedData;
  }

  mounted() {
    this.clipboard = new Clipboard('[data-clipboard-text]');
    this.clipboard.on('success', () => {
      this.$noty.info({ text: 'Значение скопировано в буфер обмена' });
    });
  }

  created() {
    if (this.$isServer) {
      this.openAllExtended = true;
    }
  }

  beforeDestroy() {
    if (this.clipboard) {
      this.clipboard.destroy();
      this.clipboard = null;
    }
  }

  mergeObjects(arr: any[]) {
    const users = arr;
    return mergeWith({}, ...users, (objValue: any, srcValue: any) => {
      if (isArray(objValue)) {
        if (!srcValue) {
          return objValue;
        }
        return objValue.concat(srcValue);
      }
    });
  }

  onFilterSelect(value: any) {
    this.currentFilter = value;
    this.dataFilter = '';
    this.personFilter = '';
    this.changeUser(0);
  }

  onPersonSelect(value: any, index: number) {
    this.personFilter = value;
    this.dataFilter = '';
    if (value) {
      this.changeUser(index);
      this.findedUsers = filter(this.users, (user: any) => {
        return user.fio === value;
      });
    } else {
      this.changeUser(0);
    }
  }

  // onDataSelect(value: any) {
  //   this.dataFilter = value;
  //   this.showExtendedReportGrouped = false;
  //   if (this.currentFilter === 'passport') {
  //     this.findedUsers = filter(this.users, (user: any) => {
  //       return !!find(user.list_passport, (passport: any) => {
  //         return (passport.series + passport.number) === this.dataFilter;
  //       });
  //     });
  //   }
  //   if (this.currentFilter === 'phone') {
  //     this.findedUsers = filter(this.users, (user: any) => {
  //       return !!find(user.list_phones, (phone: any) => {
  //         return phone.value === this.dataFilter;
  //       });
  //     });
  //   }
  //   if (this.currentFilter === 'auto') {
  //     this.findedUsers = filter(this.users, (user: any) => {
  //       return !!find(user.list_autos, (auto: any) => {
  //         return auto === this.dataFilter;
  //       });
  //     });
  //   }
  //   if (this.currentFilter === 'snils') {
  //     this.findedUsers = filter(this.users, (user: any) => {
  //       return !!find(user.list_snils, (snils: any) => {
  //         return snils.number === this.dataFilter;
  //       });
  //     });
  //   }
  //   if (this.currentFilter === 'inn') {
  //     this.findedUsers = filter(this.users, (user: any) => {
  //       return !!find(user.list_inn, (inn: any) => {
  //         return inn === this.dataFilter;
  //       });
  //     });
  //   }
  //   if (this.currentFilter === 'email') {
  //     this.findedUsers = filter(this.users, (user: any) => {
  //       return !!find(user.list_emails, (email: any) => {
  //         return email.value === this.dataFilter;
  //       });
  //     });
  //   }
  // }

  onDataSelect(value: any) {
    this.dataFilter = value;
    this.showExtendedReportGrouped = false;
    if (this.currentFilter === 'passport') {
      this.findedUsers = filter(this.users, (user: any) => {
        return !!find(user.list_passport, (passport: any) => {
          if (passport.series) {
            return (passport.series + passport.number) === this.dataFilter;
          } else {
            return passport === this.dataFilter;
          }
        });
      });
    }
    if (this.currentFilter === 'phone') {
      this.findedUsers = filter(this.users, (user: any) => {
        return !!find(user.list_phones, (phone: any) => {
          return phone.value === this.dataFilter;
        });
      });
    }
    if (this.currentFilter === 'auto') {
      this.findedUsers = filter(this.users, (user: any) => {
        return !!find(user.list_autos, (auto: any) => {
          return auto === this.dataFilter;
        });
      });
    }
    if (this.currentFilter === 'snils') {
      this.findedUsers = filter(this.users, (user: any) => {
        return !!find(user.list_snils, (snils: any) => {
          return snils.number === this.dataFilter;
        });
      });
    }
    if (this.currentFilter === 'inn') {
      this.findedUsers = filter(this.users, (user: any) => {
        return !!find(user.list_inn, (inn: any) => {
          return inn === this.dataFilter;
        });
      });
    }
    if (this.currentFilter === 'email') {
      this.findedUsers = filter(this.users, (user: any) => {
        return !!find(user.list_emails, (email: any) => {
          return email.value === this.dataFilter;
        });
      });
    }
  }
}
