








































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import pick from 'lodash/pick';

@Component({
  components: {},
  props: {
    user: Object,
  },
})
export default class AppExtendedReportDocs extends Vue {
  // props
  user: any;

  disabledName: string = 'Недействительный';
  activeName: string = 'Действительный';
  inProgressName: string = 'В процессе';

  currentFilter: string = this.activeName;

  get docs() {
    if (this.user.documents) {
      return this.user.documents;
    } else {
      return {
        inn: this.user.list_inn,
        passport: this.user.list_passport,
        snils: this.user.list_snils,
      };
    }
  }

  get flattenDocs() {
    const pickedDocs = pick(this.docs, [ 'snils', 'inn', 'passport' ]);
    return reduce(pickedDocs, (result, value) => {
      return result.concat(value);
    });
  }

  get docsInProgress() {
    return filter(this.flattenDocs, (elem: any) => this.statusInProgress(elem.status));
  }

  get docsActive() {
    return filter(this.flattenDocs, (elem: any) => this.statusActive(elem.status));
  }

  get docsDisabled() {
    return filter(this.flattenDocs, (elem: any) => this.statusDisabled(elem.status));
  }

  statusInProgress(val: string) {
    return val === this.inProgressName;
  }

  statusActive(val: string) {
    return val === this.activeName;
  }

  statusDisabled(val: string) {
    return val === this.disabledName;
  }
}
