





















































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import get from 'lodash/get';

@Component({
  popupOptions: {
    name: 'popup_side popup_sud-details',
  },

  props: {
    result: Object,
  },
})
export default class AppInterpolReportDetails extends Vue {
  // props
  result: any;
  get = get;
  mounted() {
    console.log(this.result);
  }
}
