





































































































































































import Vue from 'vue';
import { Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import AppExtendedReportItemList from 'shared/components/FizCheck/ExtendedReport/ExtendedReportItemList.vue';
import AppPagination from 'shared/components/ui/Pagination/Pagination.vue';
import AppWarningImg from 'shared/components/Static/WarningImg.vue';
import capitalize from 'lodash/capitalize';
import groupBy from 'lodash/groupBy';
import find from 'lodash/find';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import chunk from 'lodash/chunk';
import forEach from 'lodash/forEach';
import moment from 'moment';

@Component({
  components: {
    AppSemiCircleChart: () => import('shared/components/Charts/SemiCircleChart.vue'),
    AppColumnChartWithRadio: () => import('shared/components/Charts/ColumnChartWithRadio.vue'),
    AppReportItem,
    AppExtendedReportItemList,
    AppPagination,
    AppWarningImg,
  },
  props: {
    result: Object,
    opened: Boolean,
  },
})
export default class AppExtendedFsspReport extends Vue {
  // props
  result: any;
  capitalize = capitalize;
  opened: boolean;
  openedLocal: boolean = false;

  // data
  filterBy: string = 'sum';
  filterBySum: any = 'all';
  filterByYear: any = 'all';
  currentPage: number = 1;
  perPage: number = 5;
  filterColors: any = [
    '#9CA3AF',
    '#336FEE',
    '#FE203A',
    '#49C236',
    '#FFE146',
    '#24BA96',
    '#F0A900',
  ];

  get itemsBySum() {
    // return groupBy(this.result.items, 'price');
    const result: any = {
      500: [],
      1500: [],
      5000: [],
      20000: [],
      100000: [],
      'Более 100 000 ₽': [],
      'Без суммы': [],
    };
    const keys = Object.keys(result);
    forEach(this.result.items, (elem: any) => {
      if (!elem.price || !this.isNumeric(elem.price)) {
        result['Без суммы'].push(elem);
        return;
      }
      for (let index = 0; index < keys.length; index++) {
        const element: number = parseInt(keys[index]);
        if (parseInt(elem.price) > 100000) {
          result['Более 100 000 ₽'].push(elem);
          break;
        }
        if (parseInt(elem.price) <= element) {
          result[element].push(elem);
          break;
        }
      }
    });
    return result;
    // return groupBy(this.result.items, (elem: any) => {
    //   if (!elem.price || !this.isNumeric(elem.price)) {
    //     return 'Без суммы';
    //   }
    //   if (elem.price > 10000) {
    //     return 'более 10000';
    //   }
    //   return elem.price;
    // });
    // const b = elems.slice(6);
  }

  get itemsByYear() {
    const itemsWithYear = map(this.result.items, (elem: any) => {
      const date = this.getField(elem.headers, 'ДАТА');
      elem.year = 'Без даты';
      if (date && date.value) {
        elem.year = moment(date.value, 'DD.MM.YYYY').year();
      }
      return elem;
    });
    return groupBy(itemsWithYear, 'year');
  }

  get itemsSum() {
    return sumBy(this.result.items, (elem: any) => {
      if (!this.isNumeric(elem.price)) return 0;
      return parseInt(elem.price || 0);
    });
  }

  get bySumChartData() {
    return map(this.itemsBySum, (elem, i) => {
      if (!i) {
        i = 'Без суммы';
      }
      return [ this.$options.filters.currency(i), elem.length ];
    });
  }

  get byYearChartData() {
    return map(this.itemsByYear, (elem, i) => {
      return [ i, sumBy(elem, (item: any) => parseInt(item.price || 0)) ];
    });
  }

  get bySumChartTitleText() {
    return `<div class="semi-circle-chart-title">Общая сумма</div><div class="semi-circle-chart-subtitle">${this.$options.filters.lazyround(this.itemsSum)}</div>`;
  }

  get filteredItems() {
    switch (this.filterBy) {
      case 'sum':
        if (this.filterBySum === 'all') {
          return this.result.items;
        }
        return this.itemsBySum[this.filterBySum];
      case 'year':
        if (this.filterByYear === 'all') {
          return this.result.items;
        }
        return this.itemsByYear[this.filterByYear];
      default:
        return [];
    }
  }

  get paginatedItems() {
    return chunk(this.filteredItems, this.perPage);
  }

  changeFilter(i: any) {
    this.currentPage = 1;
    this.filterBySum = i;
  }

  isNumeric(str: any) {
    if (typeof str !== 'string') return false;
    return !isNaN(str as any) && !isNaN(parseFloat(str));
  }

  getField(items: any, name: string) {
    return find(items, (elem) => elem.name === name);
  }

  @Watch('opened', { immediate: true })
  openedChanged(val: boolean): void {
    this.openedLocal = val;
  }

  toggleOpen() {
    this.openedLocal = !this.openedLocal;
  }

  selectYear(event: any) {
    if (event.point.name === this.filterByYear) {
      this.filterByYear = 'all';
      return;
    }
    this.filterByYear = event.point.name;
  }

  pageChanged(page: number) {
    this.currentPage = page;
  }
}
