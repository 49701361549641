





































































































import Component, { mixins } from 'vue-class-component';
import AppDatePick from 'vue-date-pick';
import FilterWrapper from './FilterWrapper.vue';
import HelpersMixin from '@/core/mixins/helpers.mixin';

@Component({
  popupOptions: {
    name: 'popup_side popup_transparent-backdrop',
  },

  components: {
    FilterWrapper,
    AppDatePick,
  },
})
export default class ClientsWithTariffesFilter extends mixins(HelpersMixin) {
}
