export default function getValueWithCommas(value: string): string {
  if (value === '0') {
    return value;
  }
  if (value.length < 6) {
    return `0,0${value.slice(0, 2)}`;
  }
  if (value.length < 7) {
    return `0,${value.slice(0, 2)}`;
  }
  if (value.length < 8) {
    return `${value.slice(0, 1)},${value.slice(1, 2)}`;
  }
  if (value.length < 9) {
    return `${value.slice(0, 2)},${value.slice(2, 3)}`;
  }
}
