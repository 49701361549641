var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.badges && _vm.badges.length
    ? _c("div", { staticClass: "report-item-result__content-section" }, [
        _c(
          "div",
          {
            staticClass: "report-item-result__content-section-title",
            class: { "border-bottom-0 pb-0": !_vm.openedLocal }
          },
          [
            _c(
              "h4",
              { staticClass: "cursor-pointer", on: { click: _vm.toggleOpen } },
              [
                _vm._v("\n      Совпадение по:\n      "),
                _vm._l(_vm.uniqBadges, function(badge, i) {
                  return _c(
                    "span",
                    { key: badge.KEY + i },
                    [
                      _vm._v("\n        " + _vm._s(badge.KEY.toLowerCase())),
                      i < _vm.uniqBadges.length - 1 ? [_vm._v(",")] : _vm._e()
                    ],
                    2
                  )
                }),
                _c("i", {
                  staticClass: "fas fa-sort-down ml-2",
                  class: { rotated: _vm.openedLocal }
                })
              ],
              2
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.openedLocal,
                expression: "openedLocal"
              }
            ],
            staticClass: "report-item-result__badges"
          },
          _vm._l(_vm.badges, function(badge, i) {
            return _c(
              "div",
              {
                key: badge.KEY + i + "1",
                staticClass: "report-item-result__badge"
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(badge.KEY) +
                    ":\n      " +
                    _vm._s(badge.VALUE) +
                    "\n    "
                )
              ]
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }