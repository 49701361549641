var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("app-dialog-close"),
      _c("h5", { staticClass: "text-center mb-3" }, [
        _vm._v("\n    Введите новый телефон\n  ")
      ]),
      _c(
        "app-form",
        {
          attrs: {
            "request-handler": _vm.onSubmit,
            model: _vm.model,
            rules: _vm.validationRules
          },
          scopedSlots: _vm._u([
            {
              key: "button",
              fn: function(ref) {
                var loading = ref.loading
                return [
                  _c(
                    "app-button",
                    { attrs: { type: "submit", loading: loading, block: "" } },
                    [_vm._v("\n        Поменять\n      ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "app-form-group",
            { attrs: { "model-name": "phone" } },
            [
              _c("app-phone-number-input", {
                attrs: { placeholder: "Введите телефон, без 8", size: "lg" },
                model: {
                  value: _vm.model.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "phone", $$v)
                  },
                  expression: "model.phone"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }