var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row flex-nowrap" }, [
    _c(
      "div",
      {
        staticClass: "col-12 col-lg",
        class: _vm.isPdf ? "" : "fizcheck-report-main-section"
      },
      [
        _c(
          "div",
          { staticClass: "fizcheck-report" },
          [
            _c("app-report-item", {
              attrs: {
                title: _vm.isPhysical
                  ? "Персональные данные"
                  : "Данные юридического лица",
                icon: "address-card",
                opened: "",
                status: "done"
              },
              scopedSlots: _vm._u(
                [
                  !_vm.$isServer
                    ? {
                        key: "status",
                        fn: function() {
                          return [
                            !_vm.opened && !_vm.onlyOwners && !_vm.isDemo
                              ? _c(
                                  "div",
                                  { staticClass: "row no-gutters py-1" },
                                  [
                                    _vm.report.surveys
                                      ? _c("div", { staticClass: "col-6" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "link small",
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit(
                                                    "open-survey-dialog",
                                                    _vm.report.surveys
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm.newSurveys &&
                                              _vm.newSurveys.length
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge badge-pill badge-success"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.newSurveys
                                                              .length
                                                          ) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                                : _c("i", {
                                                    staticClass:
                                                      "fas fa-user fa-sm"
                                                  }),
                                              _vm._v(
                                                "\n\n                Добавить доп. сведения\n              "
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e(),
                                    _c("div", { staticClass: "col-6" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "link small",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "edit",
                                                _vm.report
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-pencil-alt fa-sm"
                                          }),
                                          (_vm.report.owner &&
                                            _vm.report.owner
                                              .registration_number) ||
                                          !_vm.isPhysical
                                            ? [
                                                _vm._v(
                                                  "\n                  Редактировать\n                "
                                                )
                                              ]
                                            : [
                                                _vm._v(
                                                  "\n                  Указать право собственности\n                "
                                                )
                                              ]
                                        ],
                                        2
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    : null,
                  {
                    key: "extra-content",
                    fn: function() {
                      return [
                        _c("app-owner-info", {
                          attrs: {
                            owner: _vm.report.owner,
                            metadata: _vm.report.surveys,
                            inn: _vm.getTask("owner_check_individual_inn"),
                            snils: _vm.getTask("snils_validation"),
                            "order-is-done": _vm.orderIsDone,
                            "add-field": _vm.openAddFieldDialog
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.$isServer,
                    expression: "!$isServer"
                  }
                ],
                staticClass: "report-filter",
                attrs: { id: "main-report" }
              },
              [
                _c("div", { staticClass: "report-filter__header" }, [
                  _vm._v("\n          Базовый отчет\n        ")
                ]),
                _c("div", { staticClass: "report-filter__items" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tippy",
                          rawName: "v-tippy",
                          value: {
                            content: "Показать все",
                            placement: "top"
                          },
                          expression:
                            "{\n              content: 'Показать все',\n              placement: 'top',\n            }"
                        }
                      ]
                    },
                    [
                      _c(
                        "app-button",
                        {
                          attrs: { variant: "white", size: "lg" },
                          on: {
                            click: function($event) {
                              _vm.filterColor = "all"
                            }
                          }
                        },
                        [_vm._v("\n              Все\n            ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tippy",
                          rawName: "v-tippy",
                          value: {
                            content: "Отрицательные факты",
                            placement: "top"
                          },
                          expression:
                            "{\n              content: 'Отрицательные факты',\n              placement: 'top',\n            }"
                        }
                      ],
                      staticClass: "report-filter__item",
                      class: { active: _vm.filterColor === "danger" },
                      on: {
                        click: function($event) {
                          _vm.filterColor = "danger"
                        }
                      }
                    },
                    [
                      _c("div", {
                        staticClass:
                          "report-filter__item-color report-filter__item-color--danger"
                      }),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            "\n                (" +
                              _vm._s(_vm.countTasksByColors.danger || 0) +
                              ")\n              "
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tippy",
                          rawName: "v-tippy",
                          value: {
                            content: "Положительные факты",
                            placement: "top"
                          },
                          expression:
                            "{\n              content: 'Положительные факты',\n              placement: 'top',\n            }"
                        }
                      ],
                      staticClass: "report-filter__item",
                      class: { active: _vm.filterColor === "success" },
                      on: {
                        click: function($event) {
                          _vm.filterColor = "success"
                        }
                      }
                    },
                    [
                      _c("div", {
                        staticClass:
                          "report-filter__item-color report-filter__item-color--success"
                      }),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            "\n                (" +
                              _vm._s(_vm.countTasksByColors.success || 0) +
                              ")\n              "
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tippy",
                          rawName: "v-tippy",
                          value: {
                            content: "Факты требующие внимания",
                            placement: "top"
                          },
                          expression:
                            "{\n              content: 'Факты требующие внимания',\n              placement: 'top',\n            }"
                        }
                      ],
                      staticClass: "report-filter__item",
                      class: { active: _vm.filterColor === "warning" },
                      on: {
                        click: function($event) {
                          _vm.filterColor = "warning"
                        }
                      }
                    },
                    [
                      _c("div", {
                        staticClass:
                          "report-filter__item-color report-filter__item-color--warning"
                      }),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            "\n                (" +
                              _vm._s(_vm.countTasksByColors.warning || 0) +
                              ")\n              "
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tippy",
                          rawName: "v-tippy",
                          value: {
                            content: "Факты требующие внимания",
                            placement: "top"
                          },
                          expression:
                            "{\n              content: 'Факты требующие внимания',\n              placement: 'top',\n            }"
                        }
                      ],
                      staticClass: "report-filter__item",
                      class: { active: _vm.filterColor === "info" },
                      on: {
                        click: function($event) {
                          _vm.filterColor = "info"
                        }
                      }
                    },
                    [
                      _c("div", {
                        staticClass:
                          "report-filter__item-color report-filter__item-color--primary"
                      }),
                      _c("div", [
                        _c("span", { staticClass: "tablet-hidden" }, [
                          _vm._v("В работе или требует действий ")
                        ]),
                        _c("span", [
                          _vm._v(
                            "\n                (" +
                              _vm._s(_vm.countTasksByColors.info || 0) +
                              ")\n              "
                          )
                        ])
                      ])
                    ]
                  )
                ])
              ]
            ),
            _vm._l(_vm.filteredTaskMap, function(item) {
              return _c(
                "div",
                { key: item.name },
                [
                  item.componentName
                    ? _c(item.componentName, {
                        tag: "component",
                        attrs: {
                          id: item.name,
                          report: _vm.report,
                          task: _vm.tasks[item.name],
                          status: _vm.getTaskStatus(
                            _vm.tasks[item.name],
                            item.name
                          ),
                          images: _vm.get(
                            _vm.tasks[item.name],
                            "task_result.images"
                          ),
                          "order-is-done": _vm.orderIsDone,
                          map: item,
                          "is-physical": _vm.isPhysical,
                          "only-owners": _vm.onlyOwners,
                          "missing-requirements": _vm.getMissingRequirements(
                            _vm.tasks[item.name]
                          ),
                          "open-add-field-dialog": _vm.openAddFieldDialog,
                          "update-map": _vm.updateTaskMap,
                          "extended-report-enabled": _vm.extendedReportEnabled,
                          "is-pdf": _vm.isPdf
                        }
                      })
                    : _c("span", [_vm._v("\n          no component\n        ")])
                ],
                1
              )
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "observe-visibility",
                    rawName: "v-observe-visibility",
                    value: _vm.visibilityChanged,
                    expression: "visibilityChanged"
                  }
                ]
              },
              [
                _c("app-extended-report-wrapper", {
                  ref: "extendedReportComponent",
                  attrs: {
                    task: _vm.dataAnalysis,
                    ulixes: _vm.ulixes,
                    "ulixes-alt": _vm.ulixesAlt,
                    "ulixes-user": _vm.ulixesUser,
                    "change-user": _vm.changeUlixesUser,
                    "show-steps": _vm.showExtendedReportSteps,
                    "is-pdf": _vm.isPdf
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "refund",
                      fn: function() {
                        return [
                          _vm.extendedReportReturned
                            ? _c(
                                "div",
                                { staticClass: "modern-panel__content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "extended-report__title" },
                                    [
                                      _vm._v(
                                        "\n                Заявка на возврат отправлена.\n              "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : [
                                _c(
                                  "div",
                                  { staticClass: "modern-panel__content" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "extended-report__title" },
                                      [
                                        _vm._v(
                                          "\n                  К сожалению, для формирования отчета данные не найдены.\n                  Проверьте представленную вами информацию по лицу,\n                  возможно имеется ошибка в введенных данных.\n                "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex-shrink-0" },
                                  [
                                    _c(
                                      "app-button",
                                      {
                                        attrs: {
                                          variant: "modern",
                                          size: "lg",
                                          block: ""
                                        },
                                        on: { click: _vm.returnExtendedReport }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Отправить заявку на возврат\n                "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            )
          ],
          2
        )
      ]
    ),
    _vm.filteredTaskMap.length && !_vm.isPdf
      ? _c("div", { staticClass: "col-auto d-none d-lg-block" }, [
          _c(
            "div",
            {
              staticClass: "fizcheck-side-navigation mb-3 mb-lg-0 rc-sticky",
              attrs: { id: "fizcheck-side-nav" }
            },
            [
              _c(
                "scrollactive",
                {
                  attrs: {
                    "highlight-first-item": true,
                    offset: 400,
                    "scroll-offset": 70
                  }
                },
                [
                  _c(
                    "ul",
                    _vm._l(_vm.scrollActiveList, function(item, i) {
                      return _c("li", { key: item.name + i }, [
                        _c(
                          "a",
                          {
                            staticClass: "scrollactive-item",
                            attrs: { href: "#" + item.name }
                          },
                          [
                            _vm.getTaskIsLoading(item)
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "fizcheck-side-navigation__icon"
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fas fa-spinner fa-spin text-brand"
                                    })
                                  ]
                                )
                              : _c("div", {
                                  staticClass: "fizcheck-side-navigation__dot",
                                  class:
                                    "fizcheck-side-navigation__dot--" +
                                    item.color
                                }),
                            item.ruName
                              ? _c("span", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.ruName) +
                                      "\n              "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.nameCap) +
                                      "\n              "
                                  )
                                ])
                          ]
                        )
                      ])
                    }),
                    0
                  )
                ]
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }