




















import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import get from 'lodash/get';

@Component({
  components: { AppReportItem },
  props: {
    report: Object,
  },
})
export default class AppCounterAgentUnfair extends Vue {
  // props
  report: any;

  get unfair() {
    return get(this.report, 'НедобросовПостав', 0);
  }
}
