var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "popup__header" },
      [
        _c("div", { staticClass: "popup__title" }, [
          _vm._v("\n      Редактирование прайс компонента\n    ")
        ]),
        _c("app-dialog-close")
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "popup__body" },
      [
        _c(
          "app-form",
          {
            attrs: {
              model: _vm.model,
              rules: _vm.validationRules,
              "request-handler": _vm.updateItem
            },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function(ref) {
                  var loading = ref.loading
                  return [
                    _c(
                      "app-button",
                      {
                        attrs: {
                          loading: loading,
                          type: "submit",
                          size: "sm",
                          block: ""
                        }
                      },
                      [_vm._v("\n          Сохранить\n        ")]
                    )
                  ]
                }
              }
            ])
          },
          [
            _c(
              "app-form-group",
              { attrs: { label: "Цена" } },
              [
                _c("app-input", {
                  attrs: { type: "number", size: "sm", placeholder: "Цена" },
                  model: {
                    value: _vm.model.price,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "price", $$v)
                    },
                    expression: "model.price"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c(
                  "app-form-group",
                  { staticClass: "col", attrs: { label: "Дата старта" } },
                  [
                    _c("app-date-picker", {
                      attrs: { size: "sm" },
                      model: {
                        value: _vm.model.from_date,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "from_date", $$v)
                        },
                        expression: "model.from_date"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "app-form-group",
                  { staticClass: "col", attrs: { label: "Дата окончания" } },
                  [
                    _c("app-date-picker", {
                      attrs: { size: "sm" },
                      model: {
                        value: _vm.model.thru_date,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "thru_date", $$v)
                        },
                        expression: "model.thru_date"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "app-form-group",
              [
                _c(
                  "app-checkbox",
                  {
                    model: {
                      value: _vm.model.enabled,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "enabled", $$v)
                      },
                      expression: "model.enabled"
                    }
                  },
                  [_vm._v("\n          Активировать\n        ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }