
















































import Vue from 'vue';
import Component from 'vue-class-component';
import omit from 'lodash/omit';
import noop from 'lodash/noop';
import { RoleNames, Role } from 'shared/utils/constants';
import { Validators } from 'shared/utils/validator';
import { Api } from 'shared/api';

@Component({
  props: {
    userId: {
      type: String,
      required: true,
    },
    onChoose: {
      type: Function,
      default: noop,
    },
  },
})
export default class AdminChooseRoleDialog extends Vue {
  // props
  userId: UUID;
  onChoose!: (newRole: number) => void;

  // data()
  model: SimpleObject<number> = {
    person_role: null,
  };
  user: User = null;

  roleNames: any = { ...omit(RoleNames, Role.Admin) };

  // lifecycle
  async created() {
    this.user = await Api.admin.GetUserInfo(this.userId);
    this.model.person_role = this.user.person_role;
  }

  // methods
  validationRules(): ValidationConfig {
    return {
      person_role: [ Validators.required ],
    };
  }

  async onSubmit() {
    this.user.person_role = this.model.person_role;
    try {
      await this.$api.user.UpdateInfo(this.user);
      this.$noty.info({ text: 'Профиль успешно обновлен' });
      this.onChoose(this.model.person_role);
      this.$dialog.close();
    } catch (error) {
      const text = (error as Error).message;
      this.$noty.error({ text });
    }
  }
}
