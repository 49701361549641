var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filter-popup" }, [
    _c(
      "div",
      {
        staticClass: "filter-popup-head",
        on: {
          click: function($event) {
            return _vm.$dialog.close()
          }
        }
      },
      [
        _c(
          "span",
          { staticClass: "filter-popup-head__title" },
          [_vm._t("title", [_vm._v("Фильтр заказов")])],
          2
        ),
        _vm._m(0)
      ]
    ),
    _c("div", { staticClass: "filter-popup-scrollable" }, [
      _c("div", { staticClass: "filter-popup-content" }, [_vm._t("default")], 2)
    ]),
    _c("div", { staticClass: "filter-popup-footer form-row" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "app-button",
            {
              attrs: { variant: "primary", size: "sm", block: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("submit")
                }
              }
            },
            [_vm._v("\n        Применить\n      ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "app-button",
            {
              attrs: { variant: "outline-secondary", size: "sm", block: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("clear")
                }
              }
            },
            [_vm._v("\n        Очистить\n      ")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "filter-popup-head__close" }, [
      _c("i", { staticClass: "fa fa-chevron-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }