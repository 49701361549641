















































































































































import Component, { mixins } from 'vue-class-component';
import { format } from 'date-fns';
import locale from 'date-fns/locale/ru';
import { mapState } from 'vuex';
import DataTableMixin from '@/core/mixins/data-table.mixin';
import { isEmpty } from 'lodash';
import HelpersMixin from '@/core/mixins/helpers.mixin';

@Component({
  computed: {
    ...mapState('admin', [ 'user' ]),
  },
  components: {
  },
})
export default class AdminUserEmailsSendpulse extends mixins(DataTableMixin, HelpersMixin) {
  // types
  user: User;
  pagination: TablePagination = {
    page: 1,
    limit: 20,
    total: 40,
  };
  headers: TableHeaderItem[] = [
    {
      text: 'Дата',
      sortProp: 'send_date',
      width: '170px',
    },

    {
      text: 'Отправитель / Получатель',
      width: '220px',
    },
    {
      text: 'Тема',
      sortProp: 'subject',
    },
    {
      text: 'Статус',
      width: '170px',
    },
  ];

  async handleRequest(offset: number, limit: number) {
    const data = await this.$api.admin.Emails('sendpulse', { recipient: this.user.email, offset, limit });
    return Array.isArray(data) ? data : [];
  }

  hasTracking(tracking: object) {
    return !isEmpty(tracking);
  }

  // метод специально для Safari, т.к. в нём при применении обычного фильтра выходит Invalid date
  getDate(date: string, formatDate: string) {
    return format(new Date(date.split(' ').join('T')), formatDate, { locale });
  }
}

