var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "object-section object-risk", attrs: { id: "risk" } },
    [
      _vm.riskItem && !_vm.checkRenderType("owners")
        ? _c("h5", { staticClass: "object-section__title mt-4" }, [
            _vm._v("\n    Проверка собственников\n  ")
          ])
        : _vm._e(),
      _vm.loading
        ? _c("app-loader", { attrs: { text: "Загрузка" } })
        : [
            _vm.riskItem
              ? [
                  _vm.isDemo ||
                  (!_vm.hasOwners && !_vm.checkRenderType("owners"))
                    ? _c("div", { staticClass: "mb-3" }, [
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col-auto" }, [
                            _c("i", { staticClass: "fas fa-info-circle" })
                          ]),
                          _c("div", { staticClass: "col" }, [
                            _vm._v(
                              "\n            Проверка объекта недвижимости - это только половина действий по обеспечению безопасности сделки, не менее важным фактором является и проверка собственников. Для исключения скрытых рисков, добавьте актуальные данные о собственниках.\n          "
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm.hasOwners && !_vm.checkRenderType("owners")
                    ? _c(
                        "app-collapse",
                        [
                          _vm._l(_vm.report.owners, function(
                            reportOwner,
                            index
                          ) {
                            return [
                              _c(
                                "app-collapse-item",
                                {
                                  key: reportOwner.owner.owner_id,
                                  class: {
                                    "is-underage": _vm.isUnderage(
                                      reportOwner.owner.birthday
                                    )
                                  },
                                  attrs: {
                                    opened: _vm.opened,
                                    disabled: _vm.isUnderage(
                                      reportOwner.owner.birthday
                                    )
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "risk-report-item__title-inner row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "risk-report-item__name col-12 col-md"
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-user-friends text-primary  mr-2"
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          reportOwner.owner
                                                            .surname
                                                        ) +
                                                        "  " +
                                                        _vm._s(
                                                          reportOwner.owner
                                                            .first
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          reportOwner.owner
                                                            .patronymic
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                    _vm.isUnderage(
                                                      reportOwner.owner.birthday
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "link small",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.editReportItem(
                                                                  reportOwner
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fas fa-pencil-alt fa-sm"
                                                            })
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.isUnderage(
                                                      reportOwner.owner.birthday
                                                    )
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-muted small ml-2"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "несовершеннолетний"
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                ),
                                                !_vm.opened
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "risk-report-item__status col-12 col-md-auto pl-4 pl-md-0"
                                                      },
                                                      [
                                                        _vm.report.order
                                                          .status === "done" ||
                                                        reportOwner.status ===
                                                          "done"
                                                          ? [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fas fa-check-circle text-success fa-sm align-middle fa-fw mr-1"
                                                              }),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "align-middle"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Выполнено"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          : _vm.report.order
                                                              .status !== "done"
                                                          ? [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fas fa-info-circle text-info fa-sm"
                                                              }),
                                                              _vm._v(
                                                                " В работе\n                  "
                                                              )
                                                            ]
                                                          : _vm._e()
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  !_vm.isUnderage(reportOwner.owner.birthday)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "risk-report-item__content pt-3"
                                        },
                                        [
                                          _c("app-risk-report", {
                                            attrs: {
                                              opened: _vm.opened,
                                              "is-demo": _vm.isDemo,
                                              report: reportOwner,
                                              "order-status":
                                                _vm.report.order.status,
                                              "only-owners": _vm.onlyOwners
                                            },
                                            on: {
                                              edit: _vm.editReportItem,
                                              "open-survey-dialog":
                                                _vm.openSurveyDialog
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              ),
                              index < _vm.report.owners.length - 1
                                ? _c("div", {
                                    key: reportOwner.owner.owner_id + "_break",
                                    staticClass: "html2pdf__page-break"
                                  })
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.report && !_vm.opened && !_vm.reportSealed && !_vm.isDemo
                    ? [
                        _c(
                          "app-button",
                          {
                            staticClass: "mt-3",
                            attrs: {
                              variant: "primary",
                              id: "addOwners",
                              size: "lg",
                              block: ""
                            },
                            on: { click: _vm.addOwner }
                          },
                          [
                            _vm._v(
                              "\n          Добавить собственника для проверки\n        "
                            )
                          ]
                        )
                      ]
                    : _vm._e(),
                  _vm.report && _vm.report.conclusions && !_vm.onlyOwners
                    ? [
                        _vm.opened
                          ? _c("div", { staticClass: "html2pdf__page-break" })
                          : _vm._e(),
                        _vm.report.surveys && !_vm.opened && !_vm.reportSealed
                          ? _c(
                              "app-button",
                              {
                                attrs: {
                                  variant: "primary-light",
                                  size: "lg",
                                  id: "Surveys",
                                  block: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openSurveyDialog(
                                      _vm.report.surveys
                                    )
                                  }
                                }
                              },
                              [
                                _vm.newSurveys && _vm.newSurveys.length
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "badge badge-pill badge-success"
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.newSurveys.length) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  : _c("i", {
                                      staticClass: "fas fa-user fa-sm"
                                    }),
                                _vm._v("\n\n          Пройдите опрос\n        ")
                              ]
                            )
                          : _vm._e(),
                        !_vm.checkRenderType("conclusion")
                          ? _c(
                              "app-collapse",
                              { staticClass: "mb-3" },
                              [
                                _c(
                                  "app-collapse-item",
                                  {
                                    staticClass:
                                      "risk-report-item risk-report-item_conclusion mt-3",
                                    class: {
                                      "risk-report-item_conclusion--red":
                                        _vm.conclusionStatus === "high",
                                      "risk-report-item_conclusion--orange":
                                        _vm.conclusionStatus === "medium",
                                      "risk-report-item_conclusion--green":
                                        _vm.conclusionStatus === "low"
                                    },
                                    attrs: { opened: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "title",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "risk-report-item__title-inner row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "risk-report-item__name col-12 col-md-auto"
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-balance-scale text-primary mr-2"
                                                      }),
                                                      _vm._v(
                                                        " Заключение и рекомендации\n                "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "app-risk-conclusion-general-status",
                                                    {
                                                      staticClass:
                                                        "risk-report-item__status col-12 col-md-auto pl-4 pl-md-0",
                                                      attrs: {
                                                        "conclusion-status":
                                                          _vm.conclusionStatus
                                                      }
                                                    }
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      213794217
                                    )
                                  },
                                  [
                                    _vm.sortedConclusions &&
                                    _vm.sortedConclusions.length
                                      ? _c(
                                          "div",
                                          { staticClass: "pt-3" },
                                          _vm._l(
                                            _vm.sortedConclusions,
                                            function(conclusion, index) {
                                              return _c(
                                                "app-risk-conclusion-item",
                                                {
                                                  key: index,
                                                  attrs: {
                                                    conclusion: conclusion
                                                  }
                                                }
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : _c("div", { staticClass: "pt-3" }, [
                                          _c("span", [
                                            _vm._v(
                                              "\n                Идет сбор данных для формирования заключения и рекомендаций.\n                Как только мы проанализируем полученные сведения, в этом блоке отобразится результат проверки.\n              "
                                            )
                                          ])
                                        ])
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e()
                ]
              : [
                  _c("app-report-example"),
                  _c(
                    "app-conclusions",
                    {
                      attrs: {
                        title: "Заключение и рекомендации",
                        icon: "user-tie"
                      }
                    },
                    [
                      _vm._v(
                        "\n        Результат проверки на риски, рекомендации по их уменьшению "
                      ),
                      _c("br"),
                      _c(
                        "span",
                        { staticClass: "text-muted font-weight-500" },
                        [
                          _c("i", {
                            staticClass: "fas fa-lock fa-xs fa-fw mr-1"
                          }),
                          _vm._v(" доступно после заказа")
                        ]
                      )
                    ]
                  )
                ]
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }