var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrapper mb-3" },
    [
      _c(
        "h6",
        {
          staticClass: "m-0 pb-0 admin-page-head d-flex justify-content-between"
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.title))]),
          _vm.items && _vm.items.length > 4
            ? _c(
                "span",
                {
                  staticClass: "stats-table__expand small",
                  on: {
                    click: function($event) {
                      _vm.showAll = !_vm.showAll
                    }
                  }
                },
                [
                  _vm._v("\n      развернуть "),
                  _c("i", { staticClass: "fas fa-chevron-down fa-xs" })
                ]
              )
            : _vm._e()
        ]
      ),
      _c("app-data-table", {
        staticClass: "stats-table stats-table_default",
        attrs: {
          items: _vm.filteredItems,
          headers: _vm.headers,
          loading: _vm.loading
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("td", [
                  _c("div", { staticClass: "text-truncate" }, [
                    _vm._v("\n          " + _vm._s(row.name) + "\n        ")
                  ])
                ]),
                _c("td", [_vm._v(_vm._s(row.count))]),
                _c("td", [_vm._v(_vm._s(_vm._f("currency")(row.sum)))]),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.getPercent(row)) + "%\n      "
                  )
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }