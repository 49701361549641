var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("h5", { staticClass: "text-center mb-4" }, [
        _vm._v("\n    Список прикреплённых файлов\n  ")
      ]),
      _c("app-dialog-close"),
      _c(
        "ul",
        { staticClass: "list-unstyled m-0" },
        _vm._l(_vm.attachments, function(attach) {
          return _c(
            "li",
            {
              key: attach.file_attachment_id,
              staticClass: "py-2 text-truncate"
            },
            [
              _c("a", { attrs: { href: attach.file_url, target: "_blank" } }, [
                _vm._v(_vm._s(attach.file_name))
              ])
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }