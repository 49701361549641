var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.result
        ? [
            _vm._l(_vm.result, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "debtor",
                  class: {
                    "d-none d-print-block":
                      !_vm.$isServer && !_vm.showAll && index >= 2
                  }
                },
                [
                  _c("div", { staticClass: "debtor-cols" }, [
                    _c("div", { staticClass: "debtor-item" }, [
                      _c("div", { staticClass: "debtor-item__title" }, [
                        _vm._v("\n            Должник\n          ")
                      ]),
                      _c("div", { staticClass: "debtor-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.entity) +
                            "\n          "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "debtor-item" }, [
                      _c("div", { staticClass: "debtor-item__title" }, [
                        _vm._v(
                          "\n            Исполнительное производство\n          "
                        )
                      ]),
                      _c("div", { staticClass: "debtor-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.enforcement_proceedings) +
                            "\n          "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "debtor-item" }, [
                      _c("div", { staticClass: "debtor-item__title" }, [
                        _vm._v(
                          "\n            Предмет исполнения, сумма непогашенной задолженности\n          "
                        )
                      ]),
                      _c("div", { staticClass: "debtor-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.amount) +
                            "\n          "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "debtor-item" }, [
                      _c("div", { staticClass: "debtor-item__title" }, [
                        _vm._v(
                          "\n            Отдел судебных приставов\n          "
                        )
                      ]),
                      _c("div", { staticClass: "debtor-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.department) +
                            "\n          "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "debtor-item" }, [
                      _c("div", { staticClass: "debtor-item__title" }, [
                        _vm._v(
                          "\n            Судебный пристав-исполнитель\n          "
                        )
                      ]),
                      _c("div", { staticClass: "debtor-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.bailiff) +
                            "\n          "
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "debtor-item debtor-item_full" }, [
                    _c("div", { staticClass: "debtor-item__title" }, [
                      _vm._v(
                        "\n          Реквизиты исполнительного документа:\n        "
                      )
                    ]),
                    _c("div", { staticClass: "debtor-item__value" }, [
                      _vm._v(
                        "\n          " + _vm._s(item.document) + "\n        "
                      )
                    ])
                  ]),
                  item.date_and_reason_to_stop
                    ? _c(
                        "div",
                        { staticClass: "debtor-item debtor-item_full" },
                        [
                          _c("div", { staticClass: "debtor-item__title" }, [
                            _vm._v(
                              "\n          Дата, причина окончания или прекращения ИП (статья, часть, пункт основания):\n        "
                            )
                          ]),
                          _c("div", { staticClass: "debtor-item__value" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.date_and_reason_to_stop) +
                                "\n        "
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              )
            }),
            !_vm.$isServer &&
            !_vm.showAll &&
            _vm.result &&
            _vm.result.length > 2
              ? _c("div", { staticClass: "mt-3 text-center d-print-none" }, [
                  _c(
                    "span",
                    {
                      staticClass: "btn-link",
                      on: {
                        click: function($event) {
                          _vm.showAll = !_vm.showAll
                        }
                      }
                    },
                    [_vm._v("Показать ещё")]
                  )
                ])
              : _vm._e()
          ]
        : [
            _vm._v(
              "\n    В базе данных ФССП исполнительные производства\n    "
            ),
            _vm._m(0)
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "text-success" }, [
      _c("i", { staticClass: "fas fa-check-circle fa-sm fa-fw" }),
      _c("b", [_vm._v("не числятся")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }