var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "filter-wrapper",
    {
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [_vm._v("\n    Фильтр клиентов\n  ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("app-input", {
              attrs: { placeholder: "Email пользователя", size: "sm" }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("h6", [_vm._v("Тариф")]),
            _c("app-multi-select", {
              attrs: {
                label: "tariffes",
                "track-by": "tariff_id",
                placeholder: "Выберите один или несколько",
                "preserve-search": "",
                multiple: ""
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "app-checkbox",
              { attrs: { "true-value": true, "false-value": undefined } },
              [_vm._v("\n        Тариф неактивен\n      ")]
            ),
            _c(
              "app-checkbox",
              { attrs: { "true-value": true, "false-value": undefined } },
              [_vm._v("\n        Нулевой баланс\n      ")]
            )
          ],
          1
        ),
        _c("div", { staticClass: "form-group" }, [
          _c("h6", [_vm._v("По дате")]),
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("app-date-pick", {
                  attrs: {
                    "input-attributes": {
                      class: "form-control form-control-sm",
                      placeholder: "Дата от"
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("app-date-pick", {
                  attrs: {
                    "input-attributes": {
                      class: "form-control form-control-sm",
                      placeholder: "Дата до"
                    }
                  }
                })
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("Остаток на балансе")]),
            _c("div", { staticClass: "form-row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [_c("app-input", { attrs: { placeholder: "От", size: "sm" } })],
                1
              ),
              _c(
                "div",
                { staticClass: "col" },
                [_c("app-input", { attrs: { placeholder: "До", size: "sm" } })],
                1
              )
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }