











import Vue from 'vue';
import Component from 'vue-class-component';

import AppCreditRatingInfo from 'shared/components/CreditRating/CreditRatingInfo.vue';

@Component({
  props: {
    orderCreditRating: Object,
    orderScoreRating: Object,
    owner: Object,
  },
  components: { AppCreditRatingInfo },
})
export default class AppCreditRatingDialog extends Vue {
}
