var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container container_responsive" }, [
    _c("div", { staticClass: "page-wrapper page-wrapper_border" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "admin-page-content" },
        _vm._l(_vm.jobs, function(item) {
          return _c(
            "div",
            { key: item.name, staticClass: "admin-control-item" },
            [
              _c(
                "app-collapse",
                [
                  _c(
                    "app-collapse-item",
                    {
                      staticClass:
                        "risk-report-item risk-report-item_conclusion mt-3 mb-3",
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "risk-report-item__title-inner row"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "risk-report-item__name col-12 col-md-auto"
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-exclamation-circle text-primary mr-2"
                                        }),
                                        _vm._v(" " + _vm._s(item.name) + " "),
                                        _c("br"),
                                        _c("span", { staticClass: "small" }, [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(item.description) +
                                              "\n                  "
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pt-3" },
                        [
                          item.active
                            ? [
                                _c("app-admin-control-form", {
                                  attrs: { job: item }
                                })
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "admin-page-head" }, [
      _c("h5", [_vm._v("Управление")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }