




























import Vue from 'vue';
import AppSudDetailsDialog from './SudDetails.vue';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { getRegionNameById } from 'shared/utils/regions';

export default Vue.extend({
  components: {},

  props: {
    result: Object,
    region: String,
    isPhysical: Boolean,
    mosgorsud: Object,
    sudact: Object,
  },

  computed: {
    regionName(): string {
      return getRegionNameById(this.region);
    },
    naming(): string {
      if (this.isPhysical) {
        return 'фамилии и инициалам';
      } else {
        return 'наименованию юридического лица';
      }
    },
    haveMosgorsud(): boolean {
      return this.mosgorsud && this.mosgorsud.task_result && this.mosgorsud.task_result.answer && this.mosgorsud.task_result.answer.length;
    },
    haveSudact(): boolean {
      return this.sudact &&
      this.sudact.task_result &&
      this.sudact.task_result.result &&
      (!isEmpty(this.sudact.task_result.result.magistrate) || !isEmpty(this.sudact.task_result.result.regular) || !isEmpty(this.sudact.task_result.result.vsrf));
    },
    sudactCases(): any[] {
      if (!this.haveSudact) {
        return [];
      }
      const cases = [].concat(this.sudact.task_result.result.magistrate, this.sudact.task_result.result.regular, this.sudact.task_result.result.vsrf);
      // cases.map((elem: any) => {
      //   const finded = this.result.documents.find((doc: any) => {
      //     return doc.name === elem.number;
      //   });
      //   if (finded && finded.length) {
      //     console.log(elem, finded, '-----');
      //   }
      //   // moment(elem.date, 'D MMMM YYYY').toDate();
      // });
      return cases;
    },
    casesCount(): number {
      let res = 0;
      if (this.result && this.result.result && this.result.result.data) {
        // res += this.result.documents.length;
        res += this.result.result.data.length;
      }
      if (this.haveMosgorsud) {
        res += this.mosgorsud.task_result.answer.length;
      }
      if (this.haveSudact) {
        res += this.sudactCases.length;
      }
      return res;
    },
  },

  methods: {
    showDetails() {
      this.$dialog.open({
        component: AppSudDetailsDialog,
        props: {
          result: get(this.result, 'result.data', []),
          mosgorsud: this.haveMosgorsud ? this.mosgorsud.task_result.answer : undefined,
          sudact: this.sudactCases,
        },
      });
    },
  },
});
