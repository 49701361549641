const propertyConstants = {
  get OBJECT_TYPES(): PropertyObjectType[] {
    return [
      {
        id: 1,
        name: 'Вторичка',
      },
      {
        id: 2,
        name: 'Новостройка',
      },
    ];
  },

  get BUILDING_TYPES(): PropertyBuildingType[] {
    return [
      {
        id: 0,
        name: 'Другое',
      },
      {
        id: 1,
        name: 'Панельный',
      },
      {
        id: 2,
        name: 'Монолитный',
      },
      {
        id: 3,
        name: 'Кирпичный',
      },
      {
        id: 4,
        name: 'Блочный',
      },
      {
        id: 5,
        name: 'Деревянный',
      },
    ];
  },

  get STATE_OF_APARTMENTS(): StateOfApartmentItem[] {
    return [
      {
        id: 1,
        name: 'Не требует ремонта',
      },
      {
        id: 2,
        name: 'Требует серьезного ремонта',
      },
      {
        id: 3,
        name: 'Требует косметического ремонта',
      },
      {
        id: 4,
        name: 'Сделан косметический ремонт',
      },
      {
        id: 5,
        name: 'Сделан евроремонт',
      },
      {
        id: 6,
        name: 'Новостройка (без отделки)',
      },
    ];
  },

  get AMOUNT_OF_ROOMS_IN_APARTMENT(): AmountRoomsItem[] {
    return [
      {
        amount: 'Студия',
        value: -1,
      },
      {
        amount: 'Свободная планировка',
        value: -1,
      },
      {
        amount: '1',
        value: 1,
      },
      {
        amount: '2',
        value: 2,
      },
      {
        amount: '3',
        value: 3,
      },
      {
        amount: '4',
        value: 4,
      },
      {
        amount: '5',
        value: 5,
      },
      {
        amount: '6',
        value: 6,
      },
      {
        amount: '7',
        value: 7,
      },
      {
        amount: '8',
        value: 8,
      },
      {
        amount: '9',
        value: 9,
      },
      {
        amount: '10',
        value: -2,
      },
    ];
  },
};

export default propertyConstants;
