


























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { TheMask } from 'vue-the-mask';
import { Validators } from 'shared/utils/validator';
import AppOfferDialog from 'shared/components/Static/OfferDialog.vue';

@Component({
  props: {
    owner: Object,
    fetchReport: Function,
  },

  popupOptions: {
    name: '',
    size: 'xs',
  },

  components: { TheMask },
})
export default class AppAddPhoneDialog extends Vue {
  // props
  fetchReport: () => void;
  owner: LawInformationOwner;

  loading: boolean = false;
  model: any = {
    phone: '',
    email: '',
  };

  mounted() {
    this.model.phone = this.owner.phone || this.owner.phone_number || '';
    this.model.email = this.owner.email || '';
  }

  validationConfig(): ValidationConfig {
    return {
      phone: [ Validators.required, Validators.phone ],
      email: [ Validators.email ],
    };
  }

  async onSubmit() {
    this.loading = true;
    try {
      this.owner.phone = this.model.phone;
      this.owner.email = this.model.email;
      await this.$api.risk.UpdateOwnerV2(this.owner);
      setTimeout(() => {
        this.fetchReport();
      }, 2000);
      this.$dialog.close();
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
    return Promise.resolve();
  }

  showOfferDialog() {
    this.$dialog.open({
      component: AppOfferDialog,
    });
  }
}
