



































import Vue from 'vue';
import Component from 'vue-class-component';
import _ from 'lodash';

@Component({
  props: {
    title: String,
    items: Array,
    headers: Array,
    loading: Boolean,
  },
})
export default class StatsDefaultTable extends Vue {
  // props
  items!: any[];

  // data
  showAll = false;

  get totalSum(): number {
    return _.sumBy(this.items, 'sum');
  }

  get filteredItems(): any[] {
    if (!this.items) {
      return [];
    }

    if (this.showAll) {
      return this.items;
    }

    return this.items.slice(0, 4);
  }

  getPercent(item: { sum: number }) {
    return parseFloat((item.sum / this.totalSum * 100).toFixed(2));
  }
}
