var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "admin-page-head" },
        [
          _c(
            "app-button",
            {
              attrs: { size: "sm" },
              on: { click: _vm.showCreateTariffDialog }
            },
            [
              _c("i", { staticClass: "fas fa-plus fa-xs mr-1" }),
              _vm._v(" Добавить\n    ")
            ]
          )
        ],
        1
      ),
      _c("app-data-table", {
        attrs: { headers: _vm.headers, items: _vm.items, loading: _vm.loading },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("td", [_vm._v(_vm._s(row.product_name))]),
                _c("td", [_vm._v(_vm._s(_vm._f("currency")(row.base_cost)))]),
                _c("td", [_vm._v(_vm._s(_vm._f("currency")(row.amount)))]),
                _c("td", [_vm._v(_vm._s(row.precent))]),
                _c(
                  "td",
                  { staticClass: "remove-cell" },
                  [
                    _c(
                      "app-button",
                      {
                        staticClass: "mr-2",
                        attrs: { variant: "light", size: "xs" },
                        on: {
                          click: function($event) {
                            return _vm.showUpdateDialog(row)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-pencil-alt fa-xs" })]
                    ),
                    _c(
                      "app-button",
                      {
                        attrs: { size: "xs", variant: "danger-light" },
                        on: {
                          click: function($event) {
                            return _vm.removeItem(row)
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-trash-alt fa-xs" })]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }