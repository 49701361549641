





















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { statusText, statusClassName } from 'shared/utils/orderStatus';

@Component({
  popupOptions: {
    size: 'sm',
  },

  props: {
    report: Object,
    orderItemId: String,
  },
})
export default class RiskUpdateScraperStatusDialog extends Vue {
  // props
  report: LawInformationOwnerElem;
  orderItemId: string;

  // data
  checked: string[] = [];
  loading: boolean = false;

  taskNames = {
    passport: 'Действительность паспорта',
    criminal: 'Нахождение в розыске',
    bailiffDebt: 'Сведения о долгах у судебных приставов',
    individualDebt: 'Налоговые задолженности',
    selfEmployed: 'Самозанятый',
    arbitrReport: 'Арбитражные суды',
    ceo: 'Руководитель или учредитель юридического лица',
    terrorism: 'Росфинмониторинг',
    judicialActs: 'Судебные акты Российской Федерации',
    bankrupt: 'Федеральный реестр сведений о банкротстве',
    inn: 'Поиск ИНН',
    disqualified: 'Реестр дискв. лиц',
  };

  // get isPhysical() {
  //   return this.report.owner.owner_type !== 1;
  // }

  // get tasks() {
  //   // let arbitrReportName = 'owner_check_individual_arbitration_courts';
  //   let arbitrReportName = 'owner_kad_arbitr_check';
  //   let bankruptName = 'owner_check_individual_bankrupt_rc_data_api';
  //   let bailiffDebtName = 'owner_check_individual_bailiff_debt';
  //   let judicialActsName = 'owner_check_individual_judicial_acts';
  //   if (!this.isPhysical && !this.report.owner.isNewVersion) {
  //     arbitrReportName = 'legal_entity_check_arbitration_courts';
  //     bankruptName = 'legal_entity_bankrupt_check';
  //     bailiffDebtName = 'legal_entity_fssp_check';
  //     judicialActsName = 'legal_entity_check_sudrf';
  //   }
  //   let ceo = this.getTask('owner_zachestnyibiznes_check_analyze');
  //   if (!ceo) {
  //     ceo = this.getTask('owner_check_individual_ceo');
  //   }
  //   const mosgorsud = this.getTask('mosgorsud');
  //   const result: any = {
  //     // arbitr: this.getTask('owner_check_individual_arbitration_courts'),
  //     inn: this.getTask('owner_check_individual_inn'),
  //     passport: this.getTask('owner_check_individual_valid_passport'),
  //     criminal: this.getTask('owner_check_individual_criminal_investigation'),
  //     bailiffDebt: this.getTask(bailiffDebtName),
  //     individualDebt: this.getTask('owner_check_individual_debt'),
  //     selfEmployed: this.getTask('owner_check_individual_self_employed'),
  //     arbitrReport: this.getTask(arbitrReportName),
  //     disqualified: this.getTask('owner_check_individual_disqualified_rc_data_api'),
  //     ceo,
  //     terrorism: this.getTask('owner_check_individual_terrorism'),
  //     judicialActs: this.getTask(judicialActsName),
  //     bankrupt: this.getTask(bankruptName),
  //   };
  //   if (mosgorsud) {
  //     result.mosgorsud = mosgorsud;
  //   }
  //   return result;
  // }

  get tasks() {
    return this.report.tasks;
  }

  getTask(val: string) {
    return this.report.tasks.find((elem: any) => elem.task_type === val);
  }

  statusText(status: string): string {
    return statusText(status);
  }

  statusClassName(status: string): string {
    return statusClassName(status);
  }

  async restartTask(id: string) {
    this.loading = true;
    try {
      await this.$api.risk.RestartTaskV2({
        task_id: id,
        order_item_id: this.orderItemId,
      });

      this.$noty.info({ text: 'Парсер будет перезапущен' });
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: this.$api._getErrorMessage(error) });
    }

    this.loading = false;
  }
}
