
































import Component, { mixins } from 'vue-class-component';
import { Options as TippyOptions } from 'tippy.js';

import HelpersMixin from '@/core/mixins/helpers.mixin';

@Component({
  props: {
    orderItem: Object,
  },
})
export default class OrderItemStatus extends mixins(HelpersMixin) {
  // props
  orderItem: AdminOrderItem;

  // data
  tippyOptions: TippyOptions = {
    placement: 'left',
  };

  // computed
  get hasStatuses(): boolean {
    return this.orderItem.item_statuses && this.orderItem.item_statuses.length > 1;
  }

  get status() {
    return this.hasStatuses ? 'default' : 'disabled';
  }
}
