export default function getPdfLink(productName: string, id: string) {
  let url = 'egrn-object-preview';
  if (productName === 'CheckOwnerV2') {
    url = 'check-person-preview';
  } else if (productName.includes('EgrnRightList')) {
    url = 'egrn-right-list-preview';
  } else if (productName.includes('RiskAssessment')) {
    url = 'risk-preview';
  } else if (productName.includes('CounterAgent')) {
    url = 'counter-agent-preview';
  } else if (productName.includes('CheckAuto')) {
    url = 'check-auto-preview';
  } else if (productName.includes('CreditRating')) {
    url = 'credit-rating-preview';
  } else if (productName.includes('CostAssessment')) {
    url = 'cost-assessment-preview';
  }
  return `https://realtycloud.ru/${url}/${id}/`;
}
