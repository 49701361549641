export const OBJECT_BY_TYPE: any = {
  '002001000000': 'Объекты учета и регистрации',
  '002001001000': 'Земельный участок',
  '002001002000': 'Здание',
  '002001003000': 'Помещение',
  '002001004000': 'Сооружение',
  '002001005000': 'Объект незавершенного строительства',
  '002001006000': 'Предприятие как имущественный комплекс (ПИК)',
  '002001007000': 'Участки недр',
  '002002000000': 'Границы',
  '002002001000': 'Государственная граница Российской Федерации',
  '002002002000': 'Граница между субъектами Российской Федерации',
  '002002003000': 'Граница муниципального образования',
  '002002004000': 'Граница населенного пункта',
  '002003000000': 'Зоны',
  '002003001000': 'Зона с особыми условиями использования территорий',
  '002003002000': 'Территориальная зона',
};
