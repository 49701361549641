import Vue from 'vue';
import Component from 'vue-class-component';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import mapValues from 'lodash/mapValues';
import mapKeys from 'lodash/mapKeys';

@Component
export default class DataTableMixin extends Vue {
  loading: boolean = false;
  items: any[] = [];
  pagination: TablePagination = {
    page: 1,
    limit: 30,
    total: 60,
  };

  created() {
    const pagination = mapValues(
      pick((this.$route as any).query, 'page', 'limit'),
      (value) => parseInt(value, 10),
    );

    this.pagination = { ...this.pagination, ...pagination };
    this.fetchData();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleRequest(offset: number, limit: number) {
    throw new Error('Method not implemented.');
  }

  async fetchData() {
    if (this.loading) {
      return;
    }

    this.loading = true;

    try {
      const offset = this.getOffset();
      const response = await this.handleRequest(offset, this.pagination.limit);

      this.items = this.mapDataIntoView(response);
      this.setPaginationTotalItems();
    } catch (error) {
      return this.$api.HttpError(error);
    } finally {
      this.loading = false;
    }
  }

  applyFilter(filter: any) {
    this.pagination.page = 1;
    this.fetchData();
    let query: any = {
      ...this.pagination,
      ...filter,
    };

    query = mapKeys(query, (value, key) => {
      if (Array.isArray(value)) {
        return `${key}[]`;
      }
      return key;
    });

    this.$router.replace({
      ...this.$router.currentRoute,
      query: pickBy(query, identity),
    });
  }

  pageChanged(page: number): void {
    this.pagination.page = page;

    const query: any = {
      ...this.$route.query,
      ...this.pagination,
    };

    this.$router.replace({
      ...this.$router.currentRoute,
      query,
    });

    this.fetchData();
  }

  setPaginationTotalItems() {
    const itemsCount = this.items.length;
    let totalItems = this.pagination.limit * (this.pagination.page + 1);

    if (!itemsCount) {
      return;
    }

    if (itemsCount <= this.pagination.limit / 2) {
      totalItems = this.pagination.limit * this.pagination.page;
    }

    if (totalItems < this.pagination.total) {
      return;
    }

    this.pagination.total = totalItems;
  }

  getOffset(): number {
    return Math.max(this.pagination.page - 1, 0) * this.pagination.limit;
  }

  mapDataIntoView(data: any) {
    return data || [];
  }
}
