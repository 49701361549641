















































































































































































































































































































import Component, { mixins } from 'vue-class-component';

import AppStatementItem from './StatementItem.vue';
// import StatementsMixin from 'shared/mixins/statements.mixin';
import StatementEgrnMixin from 'shared/mixins/statements/statementEgrn.mixin';

@Component({
  components: { AppStatementItem },

  props: {
    showPayInfo: Boolean,
    rights: Array,
    // isPurchased: Boolean,
    opened: {
      type: Boolean,
      default: false,
    },
    divergence: {
      type: Boolean,
      default: false,
    },
    showCheckPersonLink: {
      type: Boolean,
      default: false,
    },
  },
})
export default class StatementOwners extends mixins(StatementEgrnMixin) {
  // props
  // isPurchased!: boolean;
  rights: RealtyObjectRight[];
  opened: boolean;
  divergence: boolean;
  showCheckPersonLink: boolean;

  // data()
  fizTip: string = 'С 1 марта 2023 года вступил в силу запрет на передачу персональных данных из ЕГРН без согласия правообладателя. Поэтому ФИО собственников скрыты Росреестром. Для того чтобы сведения о собственниках в выписке из ЕГРН были доступны, владелец недвижимости должен открыть сведения о своих ФИО и дате рождения, подав специальное заявление в Росреестр, в котором разрешит предоставлять их третьим лицам.'
  // computed

  get openStatement() {
    if (!this.status.isPurchased && this.rights && this.rights.length) {
      return true;
    } else {
      return this.opened;
    }
  }

  get isDisabled(): boolean {
    if (this.rights && this.rights.length && this.status.isPurchased) {
      return false;
    }

    return this.ownersCount === 0;
  }
  // get ownersCount() {
  //   return size(this.currentOwners);
  // }

  // lifecycle hooks

  // methods
  // ownerName(entity: StatementEntity) {
  //   if (entity.content) {
  //     return entity.content;
  //   }

  //   return `${entity.surname} ${entity.name} ${entity.patronymic}`;
  // }

  numberPropNotContiansDate(rightNumber: string, rightDate: string): boolean {
    if (!rightNumber || !rightDate) {
      return false;
    }

    return rightNumber.match(rightDate) === null;
  }

  numberPropNotContainsType(rightNumber: string, rightType: string): boolean {
    if (!rightNumber || !rightType) {
      return false;
    }

    return rightNumber.match(rightType) === null;
  }

  goCheckPerson(owner: any) {
    const query: any = {};
    if (owner.first && owner.first !== 'Физическое лицо') {
      query.first = owner.first;
    }
    if (owner.surname) {
      query.surname = owner.surname;
    }
    if (owner.patronymic) {
      query.patronymic = owner.patronymic;
    }
    this.$router.push({
      name: 'check-person',
      query,
    });
  }
}
