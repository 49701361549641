
export function checkPersonTasks() {
  const arbitrReportName = 'owner_kad_arbitr_check';
  // let bankruptName = 'owner_check_individual_bankrupt';
  const bankruptName = 'owner_check_individual_bankrupt_rc_data_api';
  const bailiffDebtName = 'owner_check_individual_bailiff_debt';
  // const judicialActsName = 'owner_check_individual_judicial_acts';
  const judicialActsName = 'sudrf';
  const ceo = 'owner_zachestnyibiznes_check_analyze';
  const passportTask = 'validate_passport_government';
  // if (!isPhysical) {
  //   arbitrReportName = 'legal_entity_check_arbitration_courts';
  //   // bankruptName = 'legal_entity_check_irbis_conclusion_bankrupt';
  //   bankruptName = 'legal_entity_bankrupt_check';
  //   bailiffDebtName = 'legal_entity_fssp_check';
  //   // judicialActsName = 'legal_entity_check_sudrf';
  // }
  return {
    passport: passportTask,
    criminal: 'owner_check_individual_criminal_investigation',
    fsin: 'fsin',
    interpol: 'interpol',
    bailiffDebt: bailiffDebtName,
    individualDebt: 'owner_check_individual_debt',
    bankrupt: bankruptName,
    selfEmployed: 'owner_check_individual_self_employed',
    ceo,
    disqualified: 'owner_check_individual_disqualified_rc_data_api',
    terrorism: 'owner_check_individual_terrorism',
    foreignAgent: 'forgein_agent',
    arbitrReport: arbitrReportName,
    judicialActs: judicialActsName,
    pledge: 'reestr_zalogov',
    socialMedia: 'isphere',
    snils: 'snils_validation',
    license: 'custom_driver_check',
    phone: 'data_analysis_phone',
    bills: 'gosuslugi_bills',
  };
}

export function tasksMap() {
  const taskNames = checkPersonTasks();
  return [
    {
      taskName: taskNames.passport,
      ruName: 'Проверка паспорта',
      name: 'passport',
      color: 'info',
      componentName: 'AppPassportReport',
      order: 1,
    },
    {
      taskName: taskNames.criminal,
      ruName: 'Розыск МВД',
      name: 'criminal',
      color: 'info',
      componentName: 'AppCriminalReport',
      order: 2,
    },
    {
      taskName: taskNames.fsin,
      ruName: 'Розыск УФСИН',
      name: 'fsin',
      color: 'info',
      componentName: 'AppFsinReport',
      order: 3,
    },
    {
      taskName: taskNames.interpol,
      ruName: 'Розыск Интерпол',
      name: 'interpol',
      color: 'info',
      componentName: 'AppInterpolReport',
      order: 4,
    },
    {
      taskName: taskNames.bailiffDebt,
      ruName: 'Долги ФССП',
      name: 'bailiffDebt',
      color: 'info',
      componentName: 'AppDebtReport',
      order: 5,
    },
    {
      taskName: taskNames.individualDebt,
      ruName: 'Долги ФНС',
      name: 'individualDebt',
      color: 'info',
      componentName: 'AppTaxReport',
      order: 6,
    },
    {
      taskName: taskNames.bankrupt,
      ruName: 'Банкротство',
      name: 'bankrupt',
      color: 'info',
      componentName: 'AppBankruptReport',
      order: 7,
    },
    {
      taskName: taskNames.selfEmployed,
      ruName: 'Самозанятый',
      name: 'selfEmployed',
      color: 'info',
      componentName: 'AppSelfEmloyedReport',
      order: 8,
    },
    {
      taskName: taskNames.ceo,
      ruName: 'Управление юр.лицом',
      name: 'ceo',
      color: 'info',
      componentName: 'AppFounderReport',
      order: 9,
    },
    {
      taskName: taskNames.disqualified,
      ruName: 'Дисквалификация',
      name: 'disqualified',
      color: 'info',
      componentName: 'AppDisqualifiedReport',
      order: 10,
    },
    {
      taskName: taskNames.terrorism,
      ruName: 'Росфинмониторинг',
      name: 'terrorism',
      color: 'info',
      componentName: 'AppTerrorismReport',
      order: 11,
    },
    {
      taskName: taskNames.foreignAgent,
      ruName: 'Реестр иноагентов',
      name: 'foreignAgent',
      color: 'info',
      componentName: 'AppForeignAgentReport',
      order: 12,
    },
    {
      taskName: taskNames.arbitrReport,
      ruName: 'Арбитражные суды',
      name: 'arbitrReport',
      color: 'info',
      componentName: 'AppArbitrReport',
      order: 13,
    },
    {
      taskName: taskNames.judicialActs,
      ruName: 'Судебные акты РФ',
      name: 'judicialActs',
      color: 'info',
      componentName: 'AppSudReport',
      order: 14,
    },
    {
      taskName: taskNames.pledge,
      ruName: 'Реестр залогов',
      name: 'pledge',
      color: 'info',
      componentName: 'AppPledgeReport',
      order: 15,
    },
    {
      taskName: taskNames.license,
      ruName: 'Проверка ВУ',
      name: 'license',
      color: 'info',
      componentName: 'AppLicenseReport',
      order: 16,
    },
    {
      taskName: taskNames.bills,
      ruName: 'Штрафы ГИБДД',
      name: 'bills',
      color: 'info',
      componentName: 'AppBillsReport',
      order: 17,
    },
    {
      taskName: taskNames.snils,
      ruName: 'Проверка СНИЛС',
      name: 'snils',
      color: 'info',
      componentName: 'AppSnilsReport',
      order: 18,
    },
    {
      taskName: taskNames.socialMedia,
      ruName: 'Социальные сети',
      name: 'socialMedia',
      color: 'info',
      componentName: 'AppSocialMediaReport',
      order: 19,
    },
    // {
    //   taskName: taskNames.phone,
    //   ruName: 'Информация по телефону',
    //   name: 'phone',
    //   color: 'info',
    //   componentName: 'AppPhoneReport',
    //   order: 20,
    // },
  ];
}
