


















































import Vue from 'vue';
import _ from 'lodash';

import AppAdminPriceComponentCreateDialog from './AdminPriceComponentCreateDialog.vue';
import AppAdminPriceComponentEditDialog from './AdminPriceComponentEditDialog.vue';

export default Vue.extend({
  data() {
    return {
      items: [] as PriceComponent.Item[],
      loading: true,
      headers: [
        {
          text: 'Название',
        },
        {
          text: 'Продукт',
        },
        {
          text: 'Цена',
        },
        {},
      ] as TableHeaderItem[],
    };
  },

  computed: {
    productsById(): { [key: string]: Product } {
      return this.$store.state.productsByKey;
    },
  },

  created() {
    this.requestItems();
  },

  methods: {
    showCreatingComponentDialog() {
      this.$dialog.open({
        component: AppAdminPriceComponentCreateDialog,
        props: { onUpdated: this.requestItems },
      });
    },

    showEditDialog(priceItem: PriceComponent.Item) {
      this.$dialog.open({
        component: AppAdminPriceComponentEditDialog,
        props: { priceItem, onUpdated: this.requestItems },
      });
    },

    productName(productId: UUID) {
      return this.productsById[productId].product_name_ru;
    },

    async requestItems() {
      try {
        this.items = await this.$api.priceComponent.items.All();

        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },

    async destroyItem(item: PriceComponent.Item) {
      if (!confirm('Вы уверены, что хотите удалить этот Прайс компонент?')) {
        return;
      }

      try {
        await this.$api.priceComponent.items.DestroyItem(item.price_component_id);

        this.items = _.without(this.items, item);
      } catch (error) {
        console.error(error);
      }
    },
  },
});
