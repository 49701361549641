var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container container_responsive py-3" },
    [
      _c("h4", [_vm._v("Регистрация")]),
      _vm.metriks.length
        ? [
            _vm._l(_vm.metriks, function(metrik) {
              return _c(
                "div",
                {
                  key: metrik.id,
                  staticClass:
                    "metriks-container page-wrapper page-wrapper_border px-3 mb-3"
                },
                [
                  _c("div", { staticClass: "d-flex" }, [
                    _c("b", [_vm._v("Название")]),
                    _vm._v(": " + _vm._s(metrik.name) + "\n      ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("b", [_vm._v("Значение")]),
                      _vm._v(": " + _vm._s(metrik.value) + "\n        "),
                      metrik.partner_email
                        ? [
                            _vm._v(
                              "\n          (" +
                                _vm._s(metrik.partner_email) +
                                ")\n        "
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _c("div", { staticClass: "d-flex" }, [
                    _c("b", [_vm._v("Дата создания")]),
                    _vm._v(
                      ": " +
                        _vm._s(
                          _vm._f("date")(metrik.created_at, "DD.MM.YYYY")
                        ) +
                        "\n      "
                    )
                  ])
                ]
              )
            }),
            _vm.hasNextPage
              ? _c(
                  "div",
                  { staticClass: "text-center py-3" },
                  [
                    _c(
                      "app-button",
                      {
                        attrs: {
                          variant: "primary-light",
                          loading: _vm.nextPageloading
                        },
                        on: { click: _vm.fetchOrders }
                      },
                      [_vm._v("\n        Показать ещё\n      ")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        : [
            _c("div", { staticClass: "pb-3" }, [
              _vm._v("\n      Данные отсутствуют\n    ")
            ])
          ],
      _c("h4", [_vm._v("Заказы")]),
      _c("app-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          loading: _vm.loading,
          pagination: _vm.pagination,
          "item-key": "OrderID"
        },
        on: { "page-changed": _vm.pageChanged },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "td",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "admin-order-detail",
                            params: { orderID: row.OrderID }
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(row.KadastrNumber || "¯\\_(ツ)_/¯") +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c("td", [_vm._v(_vm._s(row.source))])
              ]
            }
          }
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }