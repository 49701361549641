























































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import get from 'lodash/get';
import AppWarningImg from 'shared/components/Static/WarningImg.vue';

@Component({
  components: {
    AppReportItem,
    AppWarningImg,
    AppSemiCircleChart: () => import('shared/components/Charts/SemiCircleChart.vue'),
  },
  props: {
    report: Object,
  },
})
export default class AppCounterAgentSudStatistic extends Vue {
  // props
  report: any;
  filterBySum: string = 'seller';
  filterColors: any = [
    '#9CA3AF',
    '#336FEE',
    '#FE203A',
    '#49C236',
    '#FFE146',
    '#24BA96',
    '#F0A900',
  ];

  get sudPlaintiff() {
    return get(this.report, 'СудыСтатистика.Истец', {});
  }

  get sudRespondent() {
    return get(this.report, 'СудыСтатистика.Ответчик', {});
  }

  get sumProcurements() {
    return this.sudRespondent['Сумма'] || 0 + this.sudPlaintiff['Сумма'] || 0;
  }

  get bySumChartData() {
    return [
      [
        'Истец',
        this.sudPlaintiff['Сумма'],
      ],
      [
        'Ответчик',
        this.sudRespondent['Сумма'],
      ],
    ];
  }

  get bySumChartTitleText() {
    return `<div class="semi-circle-chart-title">Сумма</div><div class="semi-circle-chart-subtitle">${this.$options.filters.lazyround(this.sumProcurements)}</div>`;
  }
}
