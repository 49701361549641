var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sud-container" }, [
    _c("div", { staticClass: "sud-items" }, [
      _c(
        "div",
        { staticClass: "popup__header" },
        [
          _c("div", { staticClass: "popup__title" }, [
            _vm._v("\n        Данные из реестра иноагентов\n      ")
          ]),
          _c("app-dialog-close")
        ],
        1
      ),
      _c("div", { staticClass: "popup__body" }, [
        _vm.result
          ? _c(
              "div",
              { staticClass: "sud-details__content legacy-container" },
              [
                _vm.result["No п/п"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v("\n            No п/п\n          ")
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.result["No п/п"]) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["Полное наименование/ФИО"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v(
                          "\n            Полное наименование/ФИО\n          "
                        )
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.result["Полное наименование/ФИО"]) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["Дата рождения"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v("\n            Дата рождения\n          ")
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("date")(
                                _vm.result["Дата рождения"],
                                "DD.MM.YYYY"
                              )
                            ) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["ОГРН"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v("\n            ОГРН\n          ")
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.result["ОГРН"]) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["ИНН"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v("\n            ИНН\n          ")
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.result["ИНН"]) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["Регистрационный номер"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v(
                          "\n            Регистрационный номер\n          "
                        )
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.result["Регистрационный номер"]) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["СНИЛС"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v("\n            СНИЛС\n          ")
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.result["СНИЛС"]) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["Адрес (место нахождения)"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v(
                          "\n            Адрес (место нахождения)\n          "
                        )
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.result["Адрес (место нахождения)"]) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["Информационный ресурс"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v(
                          "\n            Информационный ресурс\n          "
                        )
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.result["Информационный ресурс"]) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["Полное наименование/ФИО участников"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v(
                          "\n            Полное наименование/ФИО участников\n          "
                        )
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.result["Полное наименование/ФИО участников"]
                            ) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["Основания включения"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v("\n            Основания включения\n          ")
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.result["Основания включения"]) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["Дата принятия решения о включении"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v(
                          "\n            Дата принятия решения о включении\n          "
                        )
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("date")(
                                _vm.result["Дата принятия решения о включении"],
                                "DD.MM.YYYY"
                              )
                            ) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["Дата принятия решения об исключении"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v(
                          "\n            Дата принятия решения об исключении\n          "
                        )
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("date")(
                                _vm.result[
                                  "Дата принятия решения об исключении"
                                ],
                                "DD.MM.YYYY"
                              )
                            ) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["Физлицо"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v("\n            Физлицо\n          ")
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.result["Физлицо"] === "1" ? "Да" : "Нет"
                            ) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["Возраст ФЛ на дату обновления данных"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v(
                          "\n            Возраст ФЛ на дату обновления данных\n          "
                        )
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.result["Возраст ФЛ на дату обновления данных"]
                            ) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["Возраст ФЛ на дату включения"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v(
                          "\n            Возраст ФЛ на дату включения\n          "
                        )
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.result["Возраст ФЛ на дату включения"]) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["Возраст ФЛ на дату исключения"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v(
                          "\n            Возраст ФЛ на дату исключения\n          "
                        )
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.result["Возраст ФЛ на дату исключения"]
                            ) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["Дней в реестре"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v("\n            Дней в реестре\n          ")
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.result["Дней в реестре"]) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["Исключен"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v("\n            Исключен\n          ")
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.result["Исключен"] === "0" ? "Нет" : "Да"
                            ) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["Год включения"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v("\n            Год включения\n          ")
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.result["Год включения"]) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.result["Год исключения"]
                  ? _c("div", { staticClass: "sud-details-item" }, [
                      _c("div", { staticClass: "sud-details-item__title" }, [
                        _vm._v("\n            Год исключения\n          ")
                      ]),
                      _c("div", { staticClass: "sud-details-item__value" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.result["Год исключения"]) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }