










































import Vue from 'vue';
import Component from 'vue-class-component';
import uniqBy from 'lodash/uniqBy';

@Component({
  components: {},
  props: {
    badges: Array,
  },
})
export default class AppExtendedReportBadgeCollapse extends Vue {
  // props
  badges: any;
  openedLocal: boolean = false;
  uniqBy = uniqBy;

  get uniqBadges() {
    return uniqBy(this.badges, 'KEY');
  }

  toggleOpen() {
    this.openedLocal = !this.openedLocal;
  }
}
