


































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppStatementItem from './StatementItem.vue';

@Component({
  components: { AppStatementItem },
  props: {
    showPayInfo: Boolean,
    opened: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: '',
    },
    payed: Boolean,
    info: Object,
  },
})
export default class AppStatementLandInfo extends Vue {
  // props
  showPayInfo!: boolean;
  opened: boolean;
  info: any;
  payed: boolean;
  status: string;

  get hasInfo() {
    return !!this.info;
  }

  get showButton() {
    if (this.info && !/погашено|архивный|аннулированный/i.test(this.info.Info)) {
      return true;
    }
    return false;
  }

  get restrictions() {
    if (this.info.Limits && this.info.Limits.length) {
      return this.info.Limits.filter((elem: any) => {
        return /статьей 56 Земельного кодекса Российской Федерации/.test(elem.type);
      });
    }
    return [];
  }

  checkout() {
    this.$router.push({
      name: 'objectCheckout-objectKey',
      params: {
        objectKey: this.info.Number,
      },
    });
  }
}
