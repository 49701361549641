var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-report-item",
    {
      attrs: {
        title: _vm.capitalize(_vm.result.name),
        icon: "book-reader",
        status: "done",
        color: "primary",
        id: _vm.result.name,
        opened: !!(_vm.result.items && _vm.result.items.length)
      },
      scopedSlots: _vm._u([
        {
          key: "extra-content",
          fn: function() {
            return [
              !!(_vm.result.items && _vm.result.items.length)
                ? _c("div", { staticClass: "text-right report-open-all" }, [
                    !_vm.$isServer
                      ? _c(
                          "span",
                          {
                            staticClass: "link",
                            on: { click: _vm.toggleOpen }
                          },
                          [
                            !_vm.openedLocal
                              ? [_vm._v("\n          Раскрыть все\n        ")]
                              : [_vm._v("\n          Скрыть все\n        ")]
                          ],
                          2
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _c("app-extended-report-item-list", {
                attrs: { opened: _vm.openedLocal, result: _vm.result.items }
              }),
              _vm.slicedItems.length < _vm.result.items.length && _vm.$isServer
                ? _c("div", { staticClass: "rc-modern-info-panel mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "rc-modern-info-panel__wrapper" },
                      [
                        _c("div", [_c("app-warning-img")], 1),
                        _c("div", [
                          _vm._v(
                            "\n          Отображено " +
                              _vm._s(_vm.slicedItems.length) +
                              " элементов из " +
                              _vm._s(_vm.result.items.length) +
                              ", полная информация доступна в веб версии отчета.\n        "
                          )
                        ])
                      ]
                    )
                  ])
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.result.items && _vm.result.items.length
        ? [_vm._v("\n    Найдены данные в источнике\n  ")]
        : [_vm._v("\n    Данные не найдены\n  ")]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }