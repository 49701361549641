












import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: { },

  props: {
    title: String,
    tableColspan: Number,
  },
})
export default class AppStatementTableSingleRow extends Vue {
  title: any;
  tableColspan: number;
}
