


















































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    info: Object,
    generic: Object,
    isObjectCheckout: {
      type: Boolean,
      default: false,
    },
  },
})
export default class AppRosreestrInfo extends Vue {
  // props
  info!: RealtyObject;
  generic!: ObjectGeneric;
  isObjectCheckout: boolean;

  get typeObject(): string {
    if (
      [ 'Объект капитального строительства', 'Помещение' ].includes(
        this.info.ObjectType
      )
    ) {
      return this.info.TypeOKC ? this.info.TypeOKC : this.info.ObjectType;
    }
    return this.info.ObjectType;
  }

  get params(): any {
    if (this.generic) {
      return this.generic.params;
    }
    return {};
  }

  get isObjectCode(): boolean {
    if (this.generic) {
      return this.generic.object.common_data.type.code === '002001005000';
    }
    return false;
  }
}
