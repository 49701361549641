var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body owner-add-field" },
    [
      _c("h5", { staticClass: "mb-3" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
      ]),
      _c("app-dialog-close"),
      _c(
        "app-form",
        {
          attrs: {
            model: _vm.model,
            "request-handler": _vm.onSubmit,
            rules: _vm.validationConfig
          }
        },
        [
          _vm.isIncluded("fio")
            ? _c(
                "div",
                { staticClass: "add-owner-item__group-input mb-2" },
                [
                  _c(
                    "app-form-group",
                    {
                      staticClass: "owner-form-input",
                      attrs: { "model-name": "surname" }
                    },
                    [
                      _c("app-input", {
                        attrs: { label: "Фамилия*", size: "sm" },
                        model: {
                          value: _vm.model.surname,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "surname", $$v)
                          },
                          expression: "model.surname"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "app-form-group",
                    {
                      staticClass: "owner-form-input",
                      attrs: { "model-name": "first" }
                    },
                    [
                      _c("app-input", {
                        attrs: { label: "Имя*", size: "sm" },
                        model: {
                          value: _vm.model.first,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "first", $$v)
                          },
                          expression: "model.first"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "app-form-group",
                    {
                      staticClass: "owner-form-input",
                      attrs: { "model-name": "patronymic" }
                    },
                    [
                      _c("app-input", {
                        attrs: { label: "Отчество", size: "sm" },
                        model: {
                          value: _vm.model.patronymic,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "patronymic", $$v)
                          },
                          expression: "model.patronymic"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isIncluded("birthday")
            ? _c(
                "app-form-group",
                {
                  staticClass: "rc-form-group",
                  attrs: { "model-name": "birthday" }
                },
                [
                  _c("app-date-picker", {
                    attrs: {
                      label: "Дата рождения",
                      options: _vm.datePickerOptions,
                      placeholder: "ДД.ММ.ГГГГ"
                    },
                    model: {
                      value: _vm.model.birthday,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "birthday", $$v)
                      },
                      expression: "model.birthday"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isIncluded("passport")
            ? _c(
                "app-form-group",
                {
                  staticClass: "rc-form-group",
                  attrs: { "model-name": "passport" }
                },
                [
                  _c("the-mask", {
                    staticClass: "form-control form-control-sm",
                    attrs: {
                      mask: ["## ## ######"],
                      placeholder: "__ __ ______"
                    },
                    model: {
                      value: _vm.model.passport,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "passport", $$v)
                      },
                      expression: "model.passport"
                    }
                  }),
                  _c(
                    "label",
                    { class: [{ active: _vm.model.passport }] },
                    [
                      _vm._t("passportInputLabel", [
                        _vm._v("Серия и № паспорта")
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isIncluded("region")
            ? _c(
                "app-form-group",
                {
                  staticClass: "rc-form-group",
                  attrs: { "model-name": "region" }
                },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.region,
                          expression: "model.region"
                        }
                      ],
                      staticClass: "custom-select custom-select-sm",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.model,
                            "region",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "", hidden: "" } }),
                      _vm._l(_vm.regions, function(reg) {
                        return _c(
                          "option",
                          { key: reg.id, domProps: { value: reg.id } },
                          [
                            _vm._v(
                              "\n          " + _vm._s(reg.name) + "\n        "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _c(
                    "label",
                    { class: [{ active: _vm.model.region }] },
                    [
                      _vm._t("regionInputLabel", [
                        _vm._v(
                          "\n          Регион регистр./проживания\n        "
                        )
                      ])
                    ],
                    2
                  )
                ]
              )
            : _vm._e(),
          _vm.isIncluded("inn")
            ? _c(
                "app-form-group",
                {
                  staticClass: "rc-form-group",
                  attrs: { "model-name": "inn" }
                },
                [
                  _c("the-mask", {
                    attrs: { mask: ["############"], size: "sm" },
                    model: {
                      value: _vm.model.inn,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "inn", $$v)
                      },
                      expression: "model.inn"
                    }
                  }),
                  _c("label", { class: [{ active: _vm.model.inn }] }, [
                    _vm._v("\n        ИНН\n      ")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.isIncluded("snils")
            ? _c(
                "app-form-group",
                {
                  staticClass: "rc-form-group",
                  attrs: { "model-name": "snils" }
                },
                [
                  _c("the-mask", {
                    staticClass: "form-control form-control-sm",
                    attrs: {
                      mask: ["###-###-### ##"],
                      placeholder: "___-___-___ __"
                    },
                    model: {
                      value: _vm.model.snils,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "snils", $$v)
                      },
                      expression: "model.snils"
                    }
                  }),
                  _c("label", { class: [{ active: _vm.model.snils }] }, [
                    _vm._v("\n        Номер СНИЛС\n      ")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.isIncluded("phone")
            ? [
                _c(
                  "app-form-group",
                  {
                    staticClass: "rc-form-group",
                    attrs: { "model-name": "phone" }
                  },
                  [
                    _c("app-phone-number-input", {
                      attrs: {
                        label: "Телефон",
                        placeholder: "+7 (___) ___ __ __",
                        size: "sm"
                      },
                      model: {
                        value: _vm.model.phone,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "phone", $$v)
                        },
                        expression: "model.phone"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "app-form-group",
                  {
                    staticClass: "rc-form-group",
                    attrs: { "model-name": "email" }
                  },
                  [
                    _c("app-input", {
                      attrs: {
                        label: "Email",
                        placeholder: "examle@example.ru"
                      },
                      model: {
                        value: _vm.model.email,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "email", $$v)
                        },
                        expression: "model.email"
                      }
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.isIncluded("license")
            ? [
                _c(
                  "app-form-group",
                  {
                    staticClass: "rc-form-group",
                    attrs: { "model-name": "license" }
                  },
                  [
                    _c("the-mask", {
                      staticClass: "form-control form-control-sm",
                      attrs: {
                        mask: ["## ## ######"],
                        placeholder: "__ __ ______"
                      },
                      model: {
                        value: _vm.model.license,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "license", $$v)
                        },
                        expression: "model.license"
                      }
                    }),
                    _c("label", { class: [{ active: _vm.model.license }] }, [
                      _vm._v("\n          Водительское удостоверение\n        ")
                    ])
                  ],
                  1
                ),
                _c(
                  "app-form-group",
                  {
                    staticClass: "rc-form-group",
                    attrs: { "model-name": "driver_license_at" }
                  },
                  [
                    _c("app-date-picker", {
                      attrs: {
                        label: "Дата выдачи В/У",
                        options: _vm.datePickerOptions,
                        placeholder: "ДД.ММ.ГГГГ"
                      },
                      model: {
                        value: _vm.model.driver_license_at,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "driver_license_at", $$v)
                        },
                        expression: "model.driver_license_at"
                      }
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _c(
            "app-button",
            {
              attrs: {
                type: "submit",
                variant: "primary",
                size: "lg",
                loading: _vm.loading,
                block: ""
              }
            },
            [_vm._v("\n      Сохранить\n    ")]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }