





























































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import get from 'lodash/get';
import AppWarningImg from 'shared/components/Static/WarningImg.vue';

@Component({
  components: {
    AppReportItem,
    AppWarningImg,
    AppSemiCircleChart: () => import('shared/components/Charts/SemiCircleChart.vue'),
  },
  props: {
    report: Object,
  },
})
export default class AppCounterAgentProcurement extends Vue {
  // props
  report: any;
  filterBySum: string = 'seller';
  filterColors: any = [
    '#9CA3AF',
    '#336FEE',
    '#FE203A',
    '#49C236',
    '#FFE146',
    '#24BA96',
    '#F0A900',
  ];

  get sumProcurements() {
    return this.procurements['КонтрЗакупщСум'] + this.procurements['КонтрПоставщСум'];
  }

  get bySumChartData() {
    return [
      [
        'Поставщик',
        this.procurements['КонтрПоставщСум'],
      ],
      [
        'Заказчик',
        this.procurements['КонтрЗакупщСум'],
      ],
    ];
  }

  get bySumChartTitleText() {
    return `<div class="semi-circle-chart-title">Сумма</div><div class="semi-circle-chart-subtitle">${this.$options.filters.lazyround(this.sumProcurements)}</div>`;
  }

  get procurements() {
    return get(this.report, 'ЗакупкиСтат', null);
  }
}
