var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-report-item",
    {
      attrs: {
        title: "Судебные дела",
        icon: "gavel",
        opened: true,
        disabled: "",
        status: "done",
        color: "success"
      },
      scopedSlots: _vm._u(
        [
          _vm.sumProcurements
            ? {
                key: "extra-content",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "report-item-result__graph mb-3" },
                      [
                        _c(
                          "div",
                          { staticClass: "report-item-result__graph-counters" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__graph-counters-elem"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__graph-counters-elem-key"
                                  },
                                  [
                                    _vm._v(
                                      "\n            Судебные дела\n          "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__graph-counters-elem-result"
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.sudPlaintiff["Количество"] +
                                            _vm.sudRespondent["Количество"]
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__graph-counters-elem"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__graph-counters-elem-key"
                                  },
                                  [
                                    _vm._v(
                                      "\n            Общая сумма\n          "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__graph-counters-elem-result"
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm._f("lazyround")(
                                            _vm.sumProcurements
                                          )
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        !_vm.$isServer
                          ? [
                              _c("div", {
                                staticClass: "report-item-result__graph-divider"
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result-filter-and-chart"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "report-item-result-filter-and-chart__chart"
                                    },
                                    [
                                      _c("app-semi-circle-chart", {
                                        attrs: {
                                          data: _vm.bySumChartData,
                                          "title-text": _vm.bySumChartTitleText,
                                          "show-value": true
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "report-item-result-filter"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result-filter__radio-btn pointer-events-none",
                                          style: { "justify-content": "normal" }
                                        },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "report-item-result-filter__color-circle",
                                            style: {
                                              backgroundColor:
                                                _vm.filterColors[0]
                                            }
                                          }),
                                          _vm._v(
                                            "\n              Истец (" +
                                              _vm._s(
                                                _vm.sudPlaintiff["Количество"]
                                              ) +
                                              ")\n            "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result-filter__radio-btn pointer-events-none"
                                        },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "report-item-result-filter__color-circle",
                                            style: {
                                              backgroundColor:
                                                _vm.filterColors[1]
                                            }
                                          }),
                                          _vm._v(
                                            "\n              Ответчик (" +
                                              _vm._s(
                                                _vm.sudRespondent["Количество"]
                                              ) +
                                              ")\n            "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          : _c("div", { staticClass: "rc-modern-info-panel" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "rc-modern-info-panel__wrapper"
                                },
                                [
                                  _c("div", [_c("app-warning-img")], 1),
                                  _c("div", [
                                    _vm._v(
                                      "\n            Интерактивный график доступен в веб версии отчета\n          "
                                    )
                                  ])
                                ]
                              )
                            ])
                      ],
                      2
                    )
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _c(
        "div",
        { staticClass: "report-item-modern__subtitle" },
        [
          !_vm.sumProcurements
            ? [_vm._v("\n      Судебные дела не найдены\n    ")]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }