var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-dialog-close", { staticClass: "d-print-none popup__close--lg" }),
      _c(
        "div",
        { staticClass: "fizcheck-pdf-report" },
        [
          _c("app-counter-agent-report", {
            attrs: {
              report: _vm.report,
              "fetch-report": _vm.fetchReport,
              demo: _vm.demo,
              download: _vm.download,
              "allow-pdf-download": _vm.allowPdfDownload
            }
          })
        ],
        1
      ),
      _c(
        "app-button",
        {
          staticClass: "r-report-pdf__print d-print-none",
          attrs: { size: "sm" },
          on: { click: _vm.print }
        },
        [_vm._v("\n    Печать\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }