var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.result
        ? [
            _vm._l(_vm.result, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "debtor",
                  class: {
                    "d-none d-print-block":
                      !_vm.$isServer && !_vm.showAll && index >= 2
                  }
                },
                [
                  _c("div", { staticClass: "row no-gutters" }, [
                    _vm._m(0, true),
                    _c("div", { staticClass: "col" }, [
                      _c("div", { staticClass: "debtor-item__value mb-2" }, [
                        _vm._v("\n            Данные лица\n          ")
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-6" }, [
                          item.status
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  Статус дисквалификации\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.getStatus(item.status)) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          item["Дата рождения дисквалифицированного лица"]
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  Дата рождения\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          item[
                                            "Дата рождения дисквалифицированного лица"
                                          ]
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          item["Дата начала дисквалификации"]
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  Дата начала дисквалификации\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          item["Дата начала дисквалификации"]
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          item["Наименование организации"]
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  Наименование организации (ИНН)\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          item["Наименование организации"]
                                        ) +
                                        "\n                  "
                                    ),
                                    item["ИНН организации"]
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                    (" +
                                              _vm._s(item["ИНН организации"]) +
                                              ")\n                  "
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ])
                            : _vm._e(),
                          item[
                            "Наименование органа, составившего протокол об административном правонарушении"
                          ]
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  Наименование органа, составившего протокол об административном правонарушении\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          item[
                                            "Наименование органа, составившего протокол об административном правонарушении"
                                          ]
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          item["ФИО судьи"]
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  Судья\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item["ФИО судьи"]) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "col-6" }, [
                          item["Номер записи РДЛ"]
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  Номер записи РДЛ\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item["Номер записи РДЛ"]) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          item["Место рождения дисквалифицированного лица"]
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  Место рождения лица\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          item[
                                            "Место рождения дисквалифицированного лица"
                                          ]
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          item["Дата истечения срока дисквалификации"]
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  Дата истечения срока дисквалификации\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          item[
                                            "Дата истечения срока дисквалификации"
                                          ]
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          item["Должность"]
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  Должность\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item["Должность"]) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          item["Статья КоАП РФ"]
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  Статья КоАП РФ\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item["Статья КоАП РФ"]) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          item["Срок дисквалификации"]
                            ? _c("div", { staticClass: "debtor-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__title" },
                                  [
                                    _vm._v(
                                      "\n                  Срок дисквалификации\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "debtor-item__value" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item["Срок дисквалификации"]) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  ])
                ]
              )
            }),
            !_vm.$isServer &&
            !_vm.showAll &&
            _vm.result &&
            _vm.result.length > 2
              ? _c("div", { staticClass: "mt-3 text-center d-print-none" }, [
                  _c(
                    "span",
                    {
                      staticClass: "btn-link",
                      on: {
                        click: function($event) {
                          _vm.showAll = !_vm.showAll
                        }
                      }
                    },
                    [_vm._v("Показать ещё")]
                  )
                ])
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-auto" }, [
      _c("i", {
        staticClass:
          "report-item__icon fa-fw mr-3 mt-1 fas fa-address-card text-primary"
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }