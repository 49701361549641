var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container container_responsive" }, [
    _c(
      "div",
      { staticClass: "page-wrapper page-wrapper_border" },
      [
        _c(
          "div",
          { staticClass: "admin-page-head" },
          [
            _c(
              "app-button",
              {
                attrs: { size: "sm" },
                on: { click: _vm.showCreatingComponentDialog }
              },
              [
                _c("i", { staticClass: "fas fa-plus fa-xs mr-1" }),
                _vm._v(" Добавить новый\n      ")
              ]
            )
          ],
          1
        ),
        _c("app-data-table", {
          attrs: {
            headers: _vm.headers,
            items: _vm.items,
            loading: _vm.loading
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("td", [
                    _vm._v("\n          " + _vm._s(row.name) + "\n        ")
                  ]),
                  _c("td", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.productName(row.product_id)) +
                        "\n        "
                    )
                  ]),
                  _c("td", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm._f("currency")(row.price)) +
                        "\n        "
                    )
                  ]),
                  _c(
                    "td",
                    [
                      _c(
                        "app-button",
                        {
                          staticClass: "mr-2",
                          attrs: { size: "xs", variant: "light" },
                          on: {
                            click: function($event) {
                              return _vm.showEditDialog(row)
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-pencil-alt fa-xs" })]
                      ),
                      _c(
                        "app-button",
                        {
                          attrs: { size: "xs", variant: "danger-light" },
                          on: {
                            click: function($event) {
                              return _vm.destroyItem(row)
                            }
                          }
                        },
                        [_c("i", { staticClass: "far fa-trash-alt fa-xs" })]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }