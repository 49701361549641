export const ENCUMBRANCE_BY_TYPE: any = {
  22001000000: 'Сервитут',
  22010000000: 'Доверительное управление',
  22011000000: 'Рента',
  22012000000: 'Запрет на совершение действий в сфере ГКУ в отношении ОН',
  22013000000: 'Найм жилого помещения',
  22014000000: 'Безвозмездное пользование (ссуда)',
  22015000000: 'Объект культурного наследия',
  22016000000: 'Концессия',
  22017000000: 'Ограничение оборотоспособности земельных участков, предусмотренное статьей 11 Федерального закона 119-ФЗ',
  22002000000: 'Арест',
  22003000000: 'Запрещение регистрации',
  22004000000: 'Ограничения прав на земельный участок, предусмотренные статьями 56, 56.1 Земельного кодекса Российской Федерации',
  22005000000: 'Решение об изъятии земельного участка, жилого помещения',
  22006000000: 'Аренда (в том числе, субаренда)',
  22007000000: 'Ипотека',
  22008000000: 'Ипотека в силу закона',
  22009000000: 'Безвозмездное (срочное) пользование земельным/лесным участком',
  22099000000: 'Иные ограничения (обременения) прав',
  '022001000000': 'Сервитут',
  '022010000000': 'Доверительное управление',
  '022011000000': 'Рента',
  '022012000000': 'Запрет на совершение действий в сфере ГКУ в отношении ОН',
  '022013000000': 'Найм жилого помещения',
  '022014000000': 'Безвозмездное пользование (ссуда)',
  '022015000000': 'Объект культурного наследия',
  '022016000000': 'Концессия',
  '022017000000': 'Ограничение оборотоспособности земельных участков, предусмотренное статьей 11 Федерального закона 119-ФЗ',
  '022002000000': 'Арест',
  '022003000000': 'Запрещение регистрации',
  '022004000000': 'Ограничения прав на земельный участок, предусмотренные статьями 56, 56.1 Земельного кодекса Российской Федерации',
  '022005000000': 'Решение об изъятии земельного участка, жилого помещения',
  '022006000000': 'Аренда (в том числе, субаренда)',
  '022007000000': 'Ипотека',
  '022008000000': 'Ипотека в силу закона',
  '022009000000': 'Безвозмездное (срочное) пользование земельным/лесным участком',
  '022099000000': 'Иные ограничения (обременения) прав',
  '022001001000': 'Публичный сервитут',
  '022001002000': 'Частный сервитут',
  '022098000000': 'Сделка',
  '022018000000': 'Залог в качестве меры пресечения',
  // default:
  // 'Вид не определен'
};
