



































import Vue from 'vue';
import Component from 'vue-class-component';
import flatpickr from 'flatpickr';
import { Validators } from 'shared/utils/validator';

const now = new Date();

@Component({
  props: {
    owner: Object,
    fetchReport: Function,
  },

  popupOptions: {
    name: '',
    size: 'xs',
  },
})
export default class AppAddBirthdayDialog extends Vue {
  // props
  model: any = {
    birthday: '',
  };

  fetchReport: () => void;

  owner: LawInformationOwner;
  loading: boolean = false;

  datePickerOptions: flatpickr.Options.Options = {
    maxDate: now,
    minDate: new Date(now.getFullYear() - 120, 0, 0),
    defaultDate: '',
  };

  validationConfig(): ValidationConfig {
    return {
      birthday: [ Validators.date('Неверный формат даты') ],
    };
  }

  async onSubmit() {
    this.loading = true;
    try {
      if (this.model.birthday) {
        this.owner.birthday = new Date(this.model.birthday).toISOString();
      } else {
        this.owner.birthday = '';
      }

      await this.$api.risk.UpdateOwnerV2(this.owner);
      setTimeout(() => {
        this.fetchReport();
      }, 2000);
      this.$dialog.close();
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
    return Promise.resolve();
  }
}
