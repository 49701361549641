var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.task
    ? _c(
        "app-report-item",
        {
          attrs: {
            title: _vm.isPhysical
              ? "Реестр иностранных агентов"
              : "Реестр компаний - иноагентов",
            icon: "user-secret",
            status: _vm.status,
            images: _vm.images,
            color: _vm.map.color,
            opened: _vm.resultStatus === "found",
            "missing-requirements": _vm.missingRequirements
          },
          scopedSlots: _vm._u(
            [
              _vm.resultStatus === "found" && _vm.result
                ? {
                    key: "extra-content",
                    fn: function() {
                      return [
                        _c("div", {}, [
                          _c("div", {
                            staticClass: "criminal-report__divider"
                          }),
                          _c("div", { staticClass: "row md-gutters" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "criminal-report__items criminal-report__items--50"
                                },
                                [
                                  _vm.result["No п/п"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                No п/п\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(_vm.result["No п/п"]) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result["Полное наименование/ФИО"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Полное наименование/ФИО\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.result[
                                                      "Полное наименование/ФИО"
                                                    ]
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result["Дата рождения"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Дата рождения\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      _vm.result[
                                                        "Дата рождения"
                                                      ],
                                                      "DD.MM.YYYY"
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result["ОГРН"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                ОГРН\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(_vm.result["ОГРН"]) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result["ИНН"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                ИНН\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(_vm.result["ИНН"]) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result["Регистрационный номер"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Регистрационный номер\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.result[
                                                      "Регистрационный номер"
                                                    ]
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result["СНИЛС"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                СНИЛС\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(_vm.result["СНИЛС"]) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result["Адрес (место нахождения)"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Адрес (место нахождения)\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.result[
                                                      "Адрес (место нахождения)"
                                                    ]
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result["Информационный ресурс"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Информационный ресурс\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.result[
                                                      "Информационный ресурс"
                                                    ]
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result[
                                    "Полное наименование/ФИО участников"
                                  ]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Полное наименование/ФИО участников\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.result[
                                                      "Полное наименование/ФИО участников"
                                                    ]
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result["Основания включения"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Основания включения\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.result[
                                                      "Основания включения"
                                                    ]
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result[
                                    "Дата принятия решения о включении"
                                  ]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Дата принятия решения о включении\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      _vm.result[
                                                        "Дата принятия решения о включении"
                                                      ],
                                                      "DD.MM.YYYY"
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result[
                                    "Дата принятия решения об исключении"
                                  ]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Дата принятия решения об исключении\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      _vm.result[
                                                        "Дата принятия решения об исключении"
                                                      ],
                                                      "DD.MM.YYYY"
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result["Физлицо"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Физлицо\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.result["Физлицо"] ===
                                                      "1"
                                                      ? "Да"
                                                      : "Нет"
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result[
                                    "Возраст ФЛ на дату обновления данных"
                                  ]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Возраст ФЛ на дату обновления данных\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.result[
                                                      "Возраст ФЛ на дату обновления данных"
                                                    ]
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result["Возраст ФЛ на дату включения"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Возраст ФЛ на дату включения\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.result[
                                                      "Возраст ФЛ на дату включения"
                                                    ]
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result["Возраст ФЛ на дату исключения"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Возраст ФЛ на дату исключения\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.result[
                                                      "Возраст ФЛ на дату исключения"
                                                    ]
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result["Дней в реестре"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Дней в реестре\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.result["Дней в реестре"]
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result["Исключен"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Исключен\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.result["Исключен"] ===
                                                      "0"
                                                      ? "Нет"
                                                      : "Да"
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result["Год включения"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Год включения\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.result["Год включения"]
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.result["Год исключения"]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Год исключения\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.result["Год исключения"]
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ])
                          ])
                        ])
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _c(
            "div",
            { staticClass: "report-item-modern__subtitle" },
            [
              _vm.resultStatus === "not found"
                ? [
                    _vm.isPhysical
                      ? _c("span", [
                          _vm._v(
                            "\n        В реестре иностранных агентов Министерства юстиции РФ не найден\n      "
                          )
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n        Не состоит в реестре компаний - иноагентов\n      "
                          )
                        ])
                  ]
                : _vm.resultStatus === "found"
                ? [
                    _vm.isPhysical
                      ? _c("span", [
                          _vm._v(
                            "\n        Физическое лицо числится в реестре иностранных агентов Министерства юстиции РФ\n      "
                          )
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n        Числится в реестре компаний - иноагентов\n      "
                          )
                        ])
                  ]
                : _vm.orderIsDone
                ? [
                    _c("span", { staticClass: "text-warning" }, [
                      _vm._v(" Недостаточно данных ")
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }