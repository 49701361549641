









































import Vue from 'vue';
import Component from 'vue-class-component';
import _ from 'lodash';

import AppAdminCreateTariffDialog from './AdminCreateTariffDialog.vue';
import AppAdminUpdateTariffDialog from './AdminUpdateTariffDialog.vue';

@Component({
  props: {
    userID: String,
  },
})
export default class AdminPartnerTariffTools extends Vue {
  // props
  userID!: string;

  // props end

  // data()
  headers: TableHeaderItem[] = [
    { text: 'Продукт' },
    { text: 'Наша минимальная цена' },
    { text: 'Чистая прибыль партнёра' },
    { text: '%', width: '60px' },
    { text: '' },
  ];
  items: Admin.Tariff.Item[] = [];
  loading: boolean = true;

  // computed
  get products(): { [key: string]: ProductName } {
    const products = this.$store.state.products;

    return _.mapValues(
      _.keyBy(products, 'product_id'),
      'product_name',
    );
  }

  // lifecycle hooks
  created() {
    this.requestItems();
  }

  // methods
  async requestItems() {
    try {
      const items = await this.$api.admin.tariff.List(this.userID);

      items.forEach(item => {
        item.product_name = this.products[item.product_id];
      });

      this.items = items;
      this.loading = false;
    } catch (error) {
      console.error(error);
    }
  }

  showCreateTariffDialog() {
    this.$dialog.open({
      component: AppAdminCreateTariffDialog,

      props: {
        userId: this.userID,
        products: this.products,
        onAdded: this.requestItems,
      },
    });
  }

  showUpdateDialog(item: Admin.Tariff.Item) {
    this.$dialog.open({
      component: AppAdminUpdateTariffDialog,

      props: {
        item,
        onUpdated: this.requestItems,
      },
    });
  }

  async removeItem(item: any) {
    if (!confirm('Удалить этот элемент?')) {
      return;
    }

    try {
      await this.$api.admin.tariff.Remove(_.pick(item, 'percentage_remuneration_partner_id'));
      this.items = _.without(this.items, item);
    } catch (error) {
      console.error(error);
    }
  }
}
