




























































import _ from 'lodash';
import Component, { mixins } from 'vue-class-component';
import AdminUsersFilter from './components/AdminUsersFilters.vue';
import AddResponsibleToUserDialog from './components/AddResponsibleToUserDialog.vue';
import DataTableMixin from '@/core/mixins/data-table.mixin';
import FilterMixin from '@/core/mixins/filter.mixin';

function defaultFilter(): SimpleObject {
  return {
    email: '',
    phoneNumber: '',
    name: '',
    roleID: [],
    responsiblePersonID: '',
    showFreeEmailDomains: false,
  };
}

@Component
export default class AdminUsers extends mixins(DataTableMixin, FilterMixin) {
  filter: any = defaultFilter();
  headers: TableHeaderItem[] = [
    {
      text: 'Email',
      sortProp: 'email',
    },
    {
      text: 'Телефон',
      sortProp: 'phone',
    },
    {
      text: 'Источник',
      sortProp: 'source',
    },
    {
      text: 'Дата регистрации',
    },
    {
      text: 'Роль',
      align: 'right',
    },
    {
      text: '',
      align: 'right',
    },
  ];

  handleRequest(offset: number, limit: number) {
    const filter = {
      ...this.filter,
      responsiblePersonID: _.get(this.filter, 'responsiblePersonID.id', ''),
      sourceID: _.map(this.filter.sourceID, 'source_id'),
    };

    return this.$api.admin.Users({ offset, limit, ...filter });
  }

  openFilter() {
    this.$dialog.open({
      component: AdminUsersFilter,
      props: {
        defaultFilter: defaultFilter,
        filter: this.filter,
        sources: this.sources,
        submit: () => {
          const filter = {
            ...this.filter,
            responsiblePersonID: _.get(this.filter, 'responsiblePersonID.id', ''),
          };

          this.applyFilter(filter);
        },
      },
    });
  }

  showAddResponsibleUserDialog(userId: string) {
    this.$dialog.open({
      component: AddResponsibleToUserDialog,
      props: { userId },
    });
  }

  getWhatsAppLink(phone: string): string {
    const phoneWithoutPlus: string = phone.slice(1);
    return `https://api.whatsapp.com/send/?phone=${phoneWithoutPlus}`;
  }
}
