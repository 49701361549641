


























































































































































































import Vue from 'vue';
import startsWith from 'lodash/startsWith';

import format from 'date-fns/format';
import AppFounderElem from 'shared/components/RiskNew/FounderElem.vue';

export default Vue.extend({
  components: { AppFounderElem },
  popupOptions: {
    name: 'popup_side popup_sud-details',
  },

  props: {
    result: Object,
    date: String,
  },

  data() {
    return {
      showDetails: false,
      details: null,
    };
  },

  methods: {
    isIP(name: string) {
      return startsWith(name, 'ИП ');
    },
    getRole(item: any) {
      const arr = item.individual_data.roles.filter((role: any) => {
        return role.active === true;
      });
      if (arr.length) {
        const names = arr.map((elem: any) => elem.name);
        return names.join('/');
      } else {
        const names = item.individual_data.roles.map((elem: any) => elem.name);
        let text = ` (лицо к данной организации на ${format(this.date, 'DD.MM.YYYY')} отношения не имеет)`;
        if (item.org_data.death_date) {
          text = '';
        }
        return names.join('/') + text;
      }
    },
    toggleDetails(item: any) {
      this.showDetails = !this.showDetails;
      if (this.showDetails) {
        this.details = item;
      } else {
        this.details = null;
      }
    },
  },

});
