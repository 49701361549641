var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c(
        "div",
        { staticClass: "pt-4 pb-5" },
        [
          _c("h4", [_vm._v("Финансовая благонадежность")]),
          _c("h5", { staticClass: "text-primary" }, [
            _vm._v("\n        " + _vm._s(_vm.fullName) + "\n      ")
          ]),
          _c("div", { staticClass: "row my-3" }, [
            _c("div", { staticClass: "col-sm-4 py-2" }, [
              _c("b", { staticClass: "font-weight-500" }, [
                _vm._v("Номер заказа:")
              ]),
              _c("div", [_vm._v(_vm._s(_vm.creditOrderId))])
            ]),
            _c("div", { staticClass: "col-sm-4 py-2" }, [
              _c("b", { staticClass: "font-weight-500" }, [
                _vm._v("Дата проверки:")
              ]),
              _c("div", [
                _vm._v(
                  _vm._s(
                    _vm._f("date")(
                      _vm.orderCreditRating.createdDate,
                      "DD.MM.YYYY"
                    )
                  )
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "col-12 col-md-auto ml-md-auto d-print-none" },
              [_vm._t("download-pdf-btn")],
              2
            )
          ]),
          _c("div", { staticClass: "my-3" }, [
            _vm._m(0),
            !_vm.passportIsEmpty
              ? _c("div", [
                  _c("div", [
                    _vm.isNew
                      ? _c("span", [
                          _vm._v("\n              Паспорт: "),
                          _c("b", [
                            _vm._v(" " + _vm._s(_vm.owner.passport) + " ")
                          ])
                        ])
                      : _c("span", [
                          _vm._v("\n              Паспорт: "),
                          _c("b", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.orderCreditRating.metadata.nbkiData
                                    .document.series +
                                    " " +
                                    _vm.orderCreditRating.metadata.nbkiData
                                      .document.number
                                ) +
                                " "
                            )
                          ])
                        ])
                  ])
                ])
              : _vm._e(),
            _c("div", [
              _c("div", [
                _vm.isNew
                  ? _c("span", [
                      _vm._v("\n              Дата рождения: "),
                      _c("b", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("date")(_vm.owner.birthday, "DD.MM.YYYY")
                            ) +
                            " "
                        )
                      ])
                    ])
                  : _c("span", [
                      _vm._v("\n              Дата рождения: "),
                      _c("b", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("date")(
                                _vm.orderCreditRating.metadata.nbkiData.document
                                  .birthDate,
                                "DD.MM.YYYY"
                              )
                            ) +
                            " "
                        )
                      ])
                    ])
              ])
            ])
          ]),
          _vm.resultCreditRating.reportResultType === 1
            ? [
                _vm.resultCreditRating.report.inquiries
                  ? _c("div", { staticClass: "pb-3" }, [
                      _c("h5", [_vm._v("Количество запросов в банки:")]),
                      _vm.resultCreditRating.report.inquiries.hasOwnProperty(
                        "lastWeekCount"
                      )
                        ? _c("div", [
                            _vm._v("\n            За последние 7 дней: "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.resultCreditRating.report.inquiries
                                    .lastWeekCount
                                )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.resultCreditRating.report.inquiries.hasOwnProperty(
                        "lastTwoWeeksCount"
                      )
                        ? _c("div", [
                            _vm._v("\n            За последние 14 дней: "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.resultCreditRating.report.inquiries
                                    .lastTwoWeeksCount
                                )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.resultCreditRating.report.inquiries.hasOwnProperty(
                        "lastMonthCount"
                      )
                        ? _c("div", [
                            _vm._v("\n            За последние 30 дней: "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.resultCreditRating.report.inquiries
                                    .lastMonthCount
                                )
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.resultCreditRating.report.inquiries.hasOwnProperty(
                        "allTimeCount"
                      )
                        ? _c("div", [
                            _vm._v("\n            За всё время: "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.resultCreditRating.report.inquiries
                                    .allTimeCount
                                )
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm.resultCreditRating.report.credits
                  ? _c("div", { staticClass: "pb-3" }, [
                      _c("h5", [_vm._v("Информация о кредитах:")]),
                      _vm.resultCreditRating.report.credits
                        .activeCreditsAsGuaranteeCountRange
                        ? _c("div", { staticClass: "pb-1" }, [
                            _vm._v(
                              "\n            Количество счетов, где лицо выступает поручителем:\n            от "
                            ),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.resultCreditRating.report.credits
                                    .activeCreditsAsGuaranteeCountRange.from
                                )
                              )
                            ]),
                            _vm._v("\n            до "),
                            _c("b", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.resultCreditRating.report.credits
                                      .activeCreditsAsGuaranteeCountRange.till
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.resultCreditRating.report.credits
                        .activeCreditsCountRange
                        ? _c("div", { staticClass: "pb-1" }, [
                            _vm._v(
                              "\n            Количество активных счетов:\n            от "
                            ),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.resultCreditRating.report.credits
                                    .activeCreditsCountRange.from
                                )
                              )
                            ]),
                            _vm._v("\n            до "),
                            _c("b", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.resultCreditRating.report.credits
                                      .activeCreditsCountRange.till
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.resultCreditRating.report.credits
                        .activeCreditsLimitAmountRange
                        ? _c("div", { staticClass: "pb-1" }, [
                            _vm._v(
                              "\n            Сумма активных кредитов:\n            от "
                            ),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.resultCreditRating.report.credits
                                    .activeCreditsLimitAmountRange.from
                                )
                              )
                            ]),
                            _vm._v("\n            до "),
                            _c("b", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.resultCreditRating.report.credits
                                      .activeCreditsLimitAmountRange.till
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.resultCreditRating.report.credits
                        .activeCreditsOverdueAmountRange
                        ? _c("div", { staticClass: "pb-1" }, [
                            _vm._v(
                              "\n            Сумма просрочки по активным счетам:\n            от "
                            ),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.resultCreditRating.report.credits
                                    .activeCreditsOverdueAmountRange.from
                                )
                              )
                            ]),
                            _vm._v("\n            до "),
                            _c("b", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.resultCreditRating.report.credits
                                      .activeCreditsOverdueAmountRange.till
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _c("div", [
                        _vm._v("За последние 14 дней: "),
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm.resultCreditRating.report.inquiries
                                .lastTwoWeeksCount
                            )
                          )
                        ])
                      ]),
                      _c("div", [
                        _vm._v("За последние 30 дней: "),
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm.resultCreditRating.report.inquiries
                                .lastMonthCount
                            )
                          )
                        ])
                      ]),
                      _c("div", [
                        _vm._v("За всё время: "),
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm.resultCreditRating.report.inquiries
                                .allTimeCount
                            )
                          )
                        ])
                      ]),
                      _vm.resultCreditRating.report.credits.creditTypeInfos
                        ? _c("div", { staticClass: "pt-2" }, [
                            _c("h5", [_vm._v("История кредитов:")]),
                            _vm.get(
                              _vm.resultCreditRating,
                              "report.credits.creditTypeInfos.length"
                            )
                              ? _c(
                                  "ul",
                                  _vm._l(
                                    _vm.resultCreditRating.report.credits
                                      .creditTypeInfos,
                                    function(credit) {
                                      return _c(
                                        "li",
                                        { key: credit.type },
                                        [
                                          _vm.creditTypes[credit.type - 1]
                                            ? _c("h6", [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.creditTypes[
                                                        credit.type - 1
                                                      ].ru_name
                                                    ) +
                                                    "\n                "
                                                )
                                              ])
                                            : _c("h6", [
                                                _vm._v(
                                                  "\n                  Неизвестный\n                "
                                                )
                                              ]),
                                          credit.hasOwnProperty("hasOpen")
                                            ? [
                                                credit.hasOpen || credit.open
                                                  ? _c("div", [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-check fa-sm fa-fw"
                                                      }),
                                                      _vm._v(
                                                        "\n                    Имеется как минимиум один открытый кредит\n                  "
                                                      )
                                                    ])
                                                  : _c("div", [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-times fa-sm fa-fw"
                                                      }),
                                                      _vm._v(
                                                        "\n                    Открытых кредитов не имеется\n                  "
                                                      )
                                                    ])
                                              ]
                                            : [
                                                _vm._v(
                                                  "\n                  Нет данных\n                "
                                                )
                                              ],
                                          credit.hasOwnProperty("hasClosed")
                                            ? [
                                                credit.hasClosed ||
                                                credit.closed
                                                  ? _c("div", [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-check fa-sm fa-fw"
                                                      }),
                                                      _vm._v(
                                                        "\n                    Имеется как минимиум один закрытый кредит\n                  "
                                                      )
                                                    ])
                                                  : _c("div", [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-times fa-sm fa-fw"
                                                      }),
                                                      _vm._v(
                                                        "\n                    Закрытых кредитов не имеется\n                  "
                                                      )
                                                    ])
                                              ]
                                            : [
                                                _vm._v(
                                                  "\n                  Нет данных\n                "
                                                )
                                              ]
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _c("p", [
                                  _vm._v(
                                    "\n              Нет доступной информации по кредитам\n            "
                                  )
                                ])
                          ])
                        : _vm._e(),
                      _vm.emptyCreditTypes.length
                        ? _c(
                            "div",
                            [
                              _c("h6", [
                                _vm._v(
                                  "Нет сведений по наличию открытых/закрытых кредитов:"
                                )
                              ]),
                              _vm._l(_vm.emptyCreditTypes, function(item) {
                                return _c("div", { key: item.type }, [
                                  _c("i", {
                                    staticClass: "fas fa-times fa-sm fa-fw"
                                  }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.ru_name) +
                                      "\n            "
                                  )
                                ])
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm.resultCreditRating.report.credits.hasOwnProperty(
                  "hasBadDebtOrPassedToCollection"
                )
                  ? _c("div", { staticClass: "pb-3" }, [
                      _c("h5", [
                        _vm._v(
                          "Наличие безнадежных долгов или долгов, переданных на взыскание:"
                        )
                      ]),
                      _vm.resultCreditRating.report.credits
                        .hasBadDebtOrPassedToCollection
                        ? _c(
                            "div",
                            { staticClass: "text-danger font-weight-500" },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-exclamation-circle fa-sm fa-fw"
                              }),
                              _vm._v("\n            Имеются\n          ")
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "text-success font-weight-500" },
                            [
                              _c("i", {
                                staticClass: "fas fa-check-circle fa-sm fa-fw"
                              }),
                              _vm._v("\n            Не имеются\n          ")
                            ]
                          )
                    ])
                  : _vm._e(),
                _vm.resultCreditRating.report.credits
                  .worstOverdueForActiveCredits
                  ? _c("div", { staticClass: "pb-3" }, [
                      _c("h5", [
                        _vm._v(
                          "Максимальный срок просрочки по активным счетам:"
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "font-weight-500",
                          class: _vm.overdueActive.textClass
                        },
                        [
                          _c("i", { class: _vm.overdueActive.iconClass }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.overdueActive.ru_name) +
                              "\n          "
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm.resultCreditRating.report.credits.worstOverdueForAllCredits
                  ? _c("div", { staticClass: "pb-3" }, [
                      _c("h5", [
                        _vm._v("Максимальный срок просрочки по всем счетам:")
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "font-weight-500",
                          class: _vm.overdueAll.textClass
                        },
                        [
                          _c("i", { class: _vm.overdueAll.iconClass }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.overdueAll.ru_name) +
                              "\n          "
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm.resultScoreRating.report &&
                _vm.resultScoreRating.report.score
                  ? _c("div", { staticClass: "pb-3" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex credit-rating-item pb-3" },
                        [
                          _c("h5", [_vm._v("Возможность получения кредита:")]),
                          _vm.resultScoreRating.report.score <= 596
                            ? _c(
                                "div",
                                { staticClass: "font-weight-500 text-warning" },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-exclamation-circle fa-sm fa-fw"
                                  }),
                                  _vm._v(
                                    "\n              Высокая вероятность отказа банка в получении кредита, возможно получение кредита в микрофинансовой организации\n            "
                                  )
                                ]
                              )
                            : _vm.resultScoreRating.report.score <= 824
                            ? _c(
                                "div",
                                { staticClass: "font-weight-500 text-success" },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-check-circle fa-sm fa-fw"
                                  }),
                                  _vm._v(
                                    "\n              Стандартная вероятность отказа в получении кредита\n            "
                                  )
                                ]
                              )
                            : _vm.resultScoreRating.report.score <= 941
                            ? _c(
                                "div",
                                { staticClass: "font-weight-500 text-success" },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-check-circle fa-sm fa-fw"
                                  }),
                                  _vm._v(
                                    "\n              Низкая вероятность отказа в получении кредита\n            "
                                  )
                                ]
                              )
                            : _vm.resultScoreRating.report.score <= 999
                            ? _c(
                                "div",
                                { staticClass: "font-weight-500 text-success" },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-check-circle fa-sm fa-fw"
                                  }),
                                  _vm._v(
                                    "\n              Очень низкая вероятность отказа в получении кредита\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            : [
                _c("h5", [_vm._v("Проверка кредитов:")]),
                _c("div", { staticClass: "text-warning" }, [
                  _c("i", {
                    staticClass: "fas fa-exclamation-circle fa-sm fa-fw"
                  }),
                  _vm._v(
                    "\n          Лицо с такими данными не найдено. Проверьте правильность введённых данных.\n          Если данные указаны корректно, то это значит, что у лица либо нет кредитов в\n          банках, которые передают информацию в БКИ\n          "
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "link d-pring-none",
                      on: { click: _vm.openEditDialog }
                    },
                    [_vm._v("\n            Редактировать\n          ")]
                  )
                ])
              ]
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Персональные данные: ")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }