


























































































import Vue from 'vue';
import Component from 'vue-class-component';
import _ from 'lodash';

import { Validators } from 'shared/utils/validator';

@Component({
  popupOptions: {
    size: 'xs',
  },

  props: {
    item: Object,
    onUpdated: Function,
  },
})
export default class AdminCreateTariffDialog extends Vue {
  // props
  item: Admin.Tariff.Item;
  onUpdated!: Function;

  // props end

  // data()
  model: Api.Admin.Tariff.Request.Update = null;

  // computed
  get userPrice(): number {
    return parseInt(this.model.base_cost) + parseInt(this.model.amount);
  }

  get partnerReward(): number {
    return parseInt(this.model.amount) - (this.userPrice * parseFloat(this.model.precent));
  }

  get cleanRC(): string {
    const rcValue: number = parseInt(this.model.base_cost) + (this.userPrice * parseFloat(this.model.precent));
    return rcValue.toFixed(1);
  }

  // lifecycle hooks
  created() {
    this.model = _.omit(
      this.item,
      'product_id',
      'person_id',
      'created_at',
      'product_name'
    );
  }

  // methods
  validationRules(): ValidationConfig {
    return {
      precent: [ Validators.required ],
      base_cost: [ Validators.required ],
      amount: [ Validators.required ],
    };
  }

  async onSubmit() {
    try {
      await this.$api.admin.tariff.Update(this.model);
      this.onUpdated();
      this.$dialog.close();
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: this.$api._getErrorMessage(error) });
    }
  }
}
