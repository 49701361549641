var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body" },
    [
      _c("h5", { staticClass: "mb-4" }, [_vm._v("\n    Пометки\n  ")]),
      _c("app-dialog-close"),
      _vm.notes.egrn
        ? [
            _c("strong", [_vm._v("Отчеты ЕГРН")]),
            _c("p", [_vm._v(_vm._s(_vm.notes.egrn))])
          ]
        : _vm._e(),
      _vm.notes.right
        ? [
            _c("strong", [_vm._v("Выписки Перехда прав")]),
            _c("p", [_vm._v(_vm._s(_vm.notes.right))])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }