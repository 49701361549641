var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a-risk-report" },
    [
      _c(
        "div",
        { staticClass: "a-risk-report__head d-flex align-items-center mb-2" },
        [
          _c("div", { staticClass: "d-flex align-items-center" }, [
            _c("div", { staticClass: "btn-link" }, [
              _c("i", {
                staticClass: "fas fa-arrow-left mr-2",
                on: { click: _vm.goToOrder }
              })
            ]),
            _c("h5", { staticClass: "m-0" }, [
              _vm._v("\n        Отчет\n      ")
            ]),
            !_vm.isEgrnObject && !_vm.isEgrnRightList
              ? _c(
                  "span",
                  {
                    staticClass:
                      "a-risk-report__create-pdf btn-link ml-3 small",
                    on: { click: _vm.createPdf }
                  },
                  [_vm._v("\n        предпросмотр\n      ")]
                )
              : _vm._e(),
            _vm.isEgrnObject || _vm.isRisk
              ? _c(
                  "span",
                  {
                    staticClass:
                      "a-risk-report__create-pdf btn-link ml-3 small",
                    on: { click: _vm.egrnPreview }
                  },
                  [_vm._v("\n        предпросмотр егрн\n      ")]
                )
              : _vm._e(),
            _vm.isEgrnRightList || _vm.isRisk
              ? _c(
                  "span",
                  {
                    staticClass:
                      "a-risk-report__create-pdf btn-link ml-3 small",
                    on: { click: _vm.rightListPreview }
                  },
                  [_vm._v("\n        предпросмотр rightList\n      ")]
                )
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "ml-auto" },
            [
              _vm.riskOrderItem && _vm.riskOrderItem.status === "done"
                ? [
                    _vm.reportSending
                      ? _c("div", { staticClass: "px-3" }, [_vm._m(0)])
                      : _c("i", {
                          directives: [
                            {
                              name: "tippy",
                              rawName: "v-tippy",
                              value: {
                                content: "Обновить отчёт для пользователя"
                              },
                              expression:
                                "{ content: 'Обновить отчёт для пользователя' }"
                            }
                          ],
                          staticClass:
                            "fas fa-sync-alt mr-2 fa-sm align-middle",
                          on: { click: _vm.sendReportToUser }
                        }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "badge badge-success font-weight-500 rounded-sm"
                      },
                      [_vm._v("\n          Отправлено\n        ")]
                    )
                  ]
                : [
                    _vm.reportSending
                      ? _c("div", { staticClass: "px-3" }, [_vm._m(1)])
                      : _c(
                          "div",
                          {
                            staticClass: "btn-link small",
                            on: { click: _vm.sendReportToUser }
                          },
                          [
                            _vm._v(
                              "\n          отправить пользователю\n        "
                            )
                          ]
                        )
                  ]
            ],
            2
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "border-bottom pb-3 mb-3" },
        [
          _c("div", { staticClass: "row no-gutters align-items-baseline" }, [
            _c("label", { staticClass: "col-auto mb-0 mr-3" }, [
              _vm._v("Кадастровый номер")
            ]),
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("app-input", {
                  attrs: {
                    "input-class": "form-control-plaintext",
                    size: "sm"
                  },
                  model: {
                    value: _vm.kadastrNumber,
                    callback: function($$v) {
                      _vm.kadastrNumber = $$v
                    },
                    expression: "kadastrNumber"
                  }
                })
              ],
              1
            )
          ]),
          _vm.riskReportV2
            ? _c("div", { staticClass: "small mb-2" }, [
                _vm._v(
                  "\n      Общий статус заказа: " +
                    _vm._s(_vm.statusText) +
                    "\n      "
                ),
                _vm.riskReportV2.order.status === "failed" || _vm.encumbrances
                  ? _c("i", {
                      staticClass:
                        "fas fa-info-circle fa-fw text-danger cursor-pointer"
                    })
                  : _vm._e()
              ])
            : _vm._e(),
          _c(
            "app-button",
            {
              attrs: { size: "sm", variant: "light" },
              on: { click: _vm.showConclusion }
            },
            [_vm._v("\n      Заключение\n    ")]
          ),
          _c(
            "app-button",
            {
              staticClass: "ml-2",
              attrs: { size: "sm", variant: "light" },
              on: { click: _vm.openUpdateReportDialog }
            },
            [_vm._v("\n      Обновить\n    ")]
          ),
          _vm.pdfTask
            ? _c(
                "app-button",
                {
                  staticClass: "ml-2",
                  attrs: { size: "sm", variant: "light" },
                  on: { click: _vm.restartPdfTask }
                },
                [_vm._v("\n      Рестарт Pdf\n    ")]
              )
            : _vm._e(),
          _c(
            "app-button",
            {
              staticClass: "ml-2",
              attrs: { size: "sm", variant: "light" },
              on: { click: _vm.showUpdateStatus }
            },
            [_vm._v("\n      Парсеры\n    ")]
          ),
          _vm.attachments.length
            ? _c(
                "app-button",
                {
                  staticClass: "ml-2",
                  attrs: { variant: "light", size: "sm" },
                  on: { click: _vm.showAttachments }
                },
                [
                  _vm._v("\n      Файлы "),
                  _c("small", { staticClass: "text-muted small" }, [
                    _vm._v(_vm._s(_vm.attachments.length))
                  ])
                ]
              )
            : _vm._e(),
          _vm.statementNotes.egrn || _vm.statementNotes.right
            ? _c(
                "app-button",
                {
                  staticClass: "ml-2",
                  attrs: { variant: "light", size: "sm" },
                  on: { click: _vm.showNotes }
                },
                [_vm._v("\n      Пометки\n    ")]
              )
            : _vm._e(),
          _vm.loading
            ? _c("div", { staticClass: "text-center" }, [_vm._m(2)])
            : _vm._e()
        ],
        1
      ),
      _vm.owners && _vm.owners.length
        ? _vm._l(_vm.owners, function(item) {
            return _c("app-risk-report-form", {
              key: item.owner_id,
              attrs: {
                item: item,
                id: item.owner_id,
                api: item.api,
                "report-v2": _vm.reportV2
              },
              on: {
                create: _vm.CreateOwner,
                updated: _vm.ownerItemUpdated,
                remove: _vm.removeReportItem
              }
            })
          })
        : _vm._e(),
      !_vm.isEgrnObject && !_vm.isEgrnRightList
        ? _c("div", { staticClass: "text-center py-3" }, [
            _c(
              "span",
              { staticClass: "btn-link", on: { click: _vm.addOwner } },
              [
                _c("i", { staticClass: "fas fa-user-plus" }),
                _vm._v(" Добавить собственника\n    ")
              ]
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "spinner-border spinner-border-sm",
        attrs: { role: "status" }
      },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "spinner-border spinner-border-sm",
        attrs: { role: "status" }
      },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "spinner-border spinner-border-sm",
        attrs: { role: "status" }
      },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }