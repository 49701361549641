var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "statement-table" },
    [
      _c("app-statement-table-single-row", {
        attrs: {
          "table-colspan": 2,
          title:
            "Сведения о характерных точках границы части (частей) земельного участка"
        }
      }),
      _c("app-statement-table-single-row", [
        _vm._v(
          "\n    Учетный номер части\n    " +
            _vm._s(_vm.get(_vm.contour, "cad_number", "Нет данных")) +
            "\n  "
        )
      ]),
      _c("app-statement-table-single-row", [
        _vm._v(
          "\n    Система координат\n    " +
            _vm._s(_vm.get(_vm.contour, "entity_spatial.sk_id", "Нет данных")) +
            "\n  "
        )
      ]),
      _c("app-statement-table-row", {
        attrs: {
          title: "Статус записи об объекте недвижимости:",
          data: "get(statements, 'egrn.data.status', '')"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }