















































































































































































import Vue from 'vue';

import Component from 'vue-class-component';

@Component({
  props: {
    result: Array,
  },
})
export default class AppDisqualifiedReport extends Vue {
  // props
  result: any[];
  showAll: boolean = false;

  getStatus(value: string) {
    switch (value) {
      case 'active':
        return 'Активный';
      case 'unknown':
        return 'Не удалось вычислить';
      case 'not_active':
        return 'Не активный';
      default:
        return 'Нет данных';
    }
  }
}

