var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sud-container" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showDetails,
              expression: "!showDetails"
            }
          ],
          staticClass: "sud-items"
        },
        [
          _c(
            "div",
            { staticClass: "popup__header" },
            [
              _c("div", { staticClass: "popup__title" }, [
                _vm._v("\n        Информация по судам\n      ")
              ]),
              _c("app-dialog-close")
            ],
            1
          ),
          _c("div", { staticClass: "popup__body" }, [
            _vm.mosgorsud
              ? _c("div", { staticClass: "p-2" }, [
                  _c("div", { staticClass: "tabs position-relative" }, [
                    _c(
                      "a",
                      {
                        staticClass: "tabs__tab",
                        class: { "is-active": !_vm.showMosgorsud },
                        on: {
                          click: function($event) {
                            _vm.showMosgorsud = !_vm.showMosgorsud
                          }
                        }
                      },
                      [_vm._v("\n            ГАС «Правосудие»\n          ")]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "tabs__tab",
                        class: { "is-active": _vm.showMosgorsud },
                        on: {
                          click: function($event) {
                            _vm.showMosgorsud = !_vm.showMosgorsud
                          }
                        }
                      },
                      [_vm._v("\n            Суды г. Москва\n          ")]
                    )
                  ])
                ])
              : _vm._e(),
            !_vm.showMosgorsud
              ? _c(
                  "div",
                  [
                    _vm._l(_vm.sudact, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.link,
                          staticClass: "sud-preview-item",
                          on: {
                            click: function($event) {
                              return _vm.toggleDetails(item, "sudact")
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "sud-preview-item__body" }, [
                            _c(
                              "div",
                              { staticClass: "sud-preview-item__name" },
                              [
                                _c("i", { staticClass: "fas fa-university" }),
                                _vm._v(
                                  " " + _vm._s(item.justice) + "\n            "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "sud-preview-item__number" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.number) +
                                    "\n            "
                                )
                              ]
                            )
                          ]),
                          _vm._m(0, true)
                        ]
                      )
                    }),
                    _vm._l(_vm.result, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.name,
                          staticClass: "sud-preview-item",
                          on: {
                            click: function($event) {
                              return _vm.toggleDetails(item, "sudrf")
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "sud-preview-item__body" }, [
                            _c(
                              "div",
                              { staticClass: "sud-preview-item__name" },
                              [
                                _c("i", { staticClass: "fas fa-university" }),
                                _vm._v(
                                  " " + _vm._s(item.sudname) + "\n            "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "sud-preview-item__number" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.casename) +
                                    " sudrf\n            "
                                )
                              ]
                            )
                          ]),
                          _vm._m(1, true)
                        ]
                      )
                    })
                  ],
                  2
                )
              : _c(
                  "div",
                  _vm._l(_vm.mosgorsud, function(item) {
                    return _c(
                      "div",
                      {
                        key: item.name_delo,
                        staticClass: "sud-preview-item",
                        on: {
                          click: function($event) {
                            return _vm.toggleDetails(item, "mosgorsud")
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "sud-preview-item__body" }, [
                          _c(
                            "div",
                            { staticClass: "sud-preview-item__name" },
                            _vm._l(item.list_cases, function(elem, i) {
                              return _c("div", { key: elem.name }, [
                                i === 0
                                  ? _c("i", {
                                      staticClass: "fas fa-university"
                                    })
                                  : _vm._e(),
                                _c("span", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(elem.name) +
                                      "\n                "
                                  )
                                ])
                              ])
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticClass: "sud-preview-item__number" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.name_delo) +
                                  "\n            "
                              )
                            ]
                          )
                        ]),
                        _vm._m(2, true)
                      ]
                    )
                  }),
                  0
                )
          ])
        ]
      ),
      _vm.showDetails && _vm.detailsSource === "sudrf" && _vm.details !== null
        ? _c("app-sudrf-details", {
            attrs: { details: _vm.details },
            on: { toggleDetails: _vm.toggleDetails }
          })
        : _vm._e(),
      _vm.showDetails &&
      _vm.detailsSource === "mosgorsud" &&
      _vm.details !== null
        ? _c("app-mosgorsud-details", {
            attrs: { details: _vm.details },
            on: { toggleDetails: _vm.toggleDetails }
          })
        : _vm._e(),
      _vm.showDetails && _vm.detailsSource === "sudact" && _vm.details !== null
        ? _c("app-sudact-details", {
            attrs: { details: _vm.details },
            on: { toggleDetails: _vm.toggleDetails }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sud-preview-item__btn" }, [
      _vm._v("\n            детали "),
      _c("i", { staticClass: "fas fa-angle-right" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sud-preview-item__btn" }, [
      _vm._v("\n            детали "),
      _c("i", { staticClass: "fas fa-angle-right" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sud-preview-item__btn" }, [
      _vm._v("\n            детали "),
      _c("i", { staticClass: "fas fa-angle-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }