


















import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'ScrollTopButton',
})
export default class ScrollTopButton extends Vue {
  // data
  showButton = false;
  scrollThreshold = 300; // Показывать кнопку после прокрутки на 300px

  // methods
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  handleScroll() {
    // Проверяем только на клиенте
    if (!this.$isServer) {
      this.showButton = window.scrollY > this.scrollThreshold + 10;
    }
  }

  // lifecycle hooks
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
