



































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    conclusion: {
      type: Object,
      required: true,
    },
  },
})
export default class RiskConlusionItemComponent extends Vue {
  // props
  conclusion!: any

  // computed
  get statusColor() {
    switch (this.conclusion.color_level) {
      case 'low':
        return 'text-success';
      case 'medium':
        return 'text-warning';
      case 'high':
        return 'text-danger';
      case 'check':
        return 'text-success';
      case 'info':
        return 'text-info';
      default:
        return null;
    }
  }
}
