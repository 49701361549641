var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "egrn-object-pdf" }, [
    _c(
      "div",
      { staticClass: "egrn-object-pdf__ecp text-right" },
      [_c("app-ecp-svg", { attrs: { width: "260px" } })],
      1
    ),
    _c("h1", { staticClass: "text-center" }, [
      _vm._v("\n    Отчет об объекте недвижимости\n  ")
    ]),
    _c("div", { staticClass: "statement-table-wrapper" }, [
      _c("table", { staticClass: "statement-table" }, [
        _c(
          "tbody",
          [
            _c("app-statement-table-row", {
              attrs: {
                title: "Дата актуальности сведений:",
                data: _vm._f("date")(
                  _vm.riskReport.order.created_at,
                  "DD.MM.YYYY"
                )
              }
            }),
            _c("app-statement-table-row", {
              attrs: {
                title: "Тип объекта:",
                data: _vm.objectType(
                  _vm.get(
                    _vm.statements,
                    "egrn.data.generic_record.object.common_data.type.code",
                    ""
                  )
                )
              }
            }),
            _c(
              "app-statement-table-row",
              {
                attrs: {
                  title: "Кадастровый номер:",
                  data: _vm.info.KadastrNumber
                }
              },
              [
                _vm.get(
                  _vm.statements,
                  "egrn.data.generic_record.object.common_data.cad_number",
                  ""
                )
                  ? _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.get(
                              _vm.statements,
                              "egrn.data.generic_record.object.common_data.cad_number",
                              ""
                            )
                          ) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _c("app-statement-table-row", {
              attrs: {
                title: "Кадастровый номер присвоен:",
                data: _vm.info.DateOfKadReg
              }
            }),
            _c(
              "app-statement-table-row",
              {
                attrs: {
                  title: "Ранее присвоенные государственные учетные номера:",
                  data: ""
                }
              },
              [
                _vm.get(
                  _vm.statements,
                  "egrn.data.generic_record.cad_links.old_numbers.length",
                  0
                )
                  ? _c(
                      "td",
                      _vm._l(
                        _vm.get(
                          _vm.statements,
                          "egrn.data.generic_record.cad_links.old_numbers"
                        ),
                        function(elem) {
                          return _c(
                            "span",
                            { key: elem.number },
                            [
                              _vm.get(elem, "number_type.value", "")
                                ? [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(elem.number_type.value) +
                                        "\n              "
                                    )
                                  ]
                                : _vm._e(),
                              _vm._v(
                                "\n              " +
                                  _vm._s(elem.number) +
                                  ";\n            "
                              )
                            ],
                            2
                          )
                        }
                      ),
                      0
                    )
                  : _vm.get(
                      _vm.statements,
                      "egrn.data.generic_record.cad_links.old_numbers.old_number.number",
                      ""
                    )
                  ? _c(
                      "td",
                      [
                        _vm.get(
                          _vm.statements,
                          "egrn.data.generic_record.cad_links.old_numbers.old_number.number_type.value",
                          ""
                        )
                          ? [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.get(
                                      _vm.statements,
                                      "egrn.data.generic_record.cad_links.old_numbers.old_number.number_type.value",
                                      ""
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : _vm._e(),
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.get(
                                _vm.statements,
                                "egrn.data.generic_record.cad_links.old_numbers.old_number.number",
                                ""
                              )
                            ) +
                            "\n          "
                        )
                      ],
                      2
                    )
                  : _vm._e()
              ]
            ),
            _c("app-statement-table-row", {
              attrs: {
                title: "Назначение:",
                data: _vm.get(
                  _vm.statements,
                  "egrn.data.generic_record.params.purpose.value",
                  ""
                )
              }
            }),
            _c("app-statement-table-row", {
              attrs: {
                title: "Наименование:",
                data: _vm.get(
                  _vm.statements,
                  "egrn.data.generic_record.params.name",
                  ""
                )
              }
            }),
            _vm.get(
              _vm.statements,
              "egrn.data.generic_record.params.category.type.value"
            )
              ? _c("app-statement-table-row", {
                  attrs: {
                    title: "Категория земель:",
                    data: _vm.get(
                      _vm.statements,
                      "egrn.data.generic_record.params.category.type.value",
                      ""
                    )
                  }
                })
              : _vm._e(),
            _c("app-statement-table-row", {
              attrs: {
                title: "Разрешенное использование:",
                data: _vm.get(
                  _vm.statements,
                  "egrn.data.generic_record.params.permitted_uses.permitted_use.name"
                )
              }
            }),
            _c("app-statement-table-row", {
              attrs: {
                title: "Вид объекта:",
                data: _vm.get(
                  _vm.statements,
                  "egrn.data.generic_record.params.type.value",
                  ""
                )
              }
            }),
            _vm.get(
              _vm.statements,
              "egrn.data.generic_record.params.base_parameters.length",
              0
            )
              ? _c(
                  "app-statement-table-row",
                  {
                    attrs: {
                      title: "Основная характеристика (для сооружения):",
                      data: ""
                    }
                  },
                  [
                    _c(
                      "td",
                      _vm._l(
                        _vm.get(
                          _vm.statements,
                          "egrn.data.generic_record.params.base_parameters"
                        ),
                        function(obj, i) {
                          return _c("div", { key: i }, [
                            obj.extension
                              ? _c("div", [
                                  _vm._v(
                                    "\n                Протяженность: " +
                                      _vm._s(obj.extension) +
                                      " м.\n              "
                                  )
                                ])
                              : _vm._e(),
                            obj.built_up_area
                              ? _c("div", [
                                  _vm._v(
                                    "\n                Площадь территории: " +
                                      _vm._s(obj.built_up_area) +
                                      " м"
                                  ),
                                  _c("sup", [_vm._v("2")]),
                                  _vm._v(".\n              ")
                                ])
                              : _vm._e(),
                            obj.area
                              ? _c("div", [
                                  _vm._v(
                                    "\n                Площадь: " +
                                      _vm._s(obj.area) +
                                      " м"
                                  ),
                                  _c("sup", [_vm._v("2")]),
                                  _vm._v(".\n              ")
                                ])
                              : _vm._e()
                          ])
                        }
                      ),
                      0
                    )
                  ]
                )
              : _vm._e(),
            _vm.get(
              _vm.statements,
              "egrn.data.generic_record.params.floors",
              ""
            )
              ? _c(
                  "app-statement-table-row",
                  {
                    attrs: {
                      title: "Количество этажей, в том числе подземных этажей:",
                      data: ""
                    }
                  },
                  [
                    _c(
                      "td",
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.get(
                                _vm.statements,
                                "egrn.data.generic_record.params.floors",
                                ""
                              )
                            )
                        ),
                        _vm.get(
                          _vm.statements,
                          "egrn.data.generic_record.params.underground_floors",
                          ""
                        )
                          ? [
                              _vm._v(
                                "\n              в том числе поздемных " +
                                  _vm._s(
                                    _vm.get(
                                      _vm.statements,
                                      "egrn.data.generic_record.params.underground_floors",
                                      ""
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                )
              : _vm._e(),
            _vm.get(
              _vm.statements,
              "egrn.data.generic_record.params.year_commisioning",
              ""
            )
              ? _c("app-statement-table-row", {
                  attrs: {
                    title:
                      "Год ввода в эксплуатацию по завершении строительства:",
                    data: _vm.get(
                      _vm.statements,
                      "egrn.data.generic_record.params.year_commisioning",
                      ""
                    )
                  }
                })
              : _vm._e(),
            _vm.get(
              _vm.statements,
              "egrn.data.generic_record.params.year_built",
              ""
            )
              ? _c("app-statement-table-row", {
                  attrs: {
                    title: "Год завершения строительства:",
                    data: _vm.get(
                      _vm.statements,
                      "egrn.data.generic_record.params.year_built",
                      ""
                    )
                  }
                })
              : _vm._e(),
            _c(
              "app-statement-table-row",
              { attrs: { title: "Площадь:", data: "" } },
              [
                _vm.get(
                  _vm.statements,
                  "egrn.data.generic_record.params.area",
                  ""
                )
                  ? _c(
                      "td",
                      [
                        _vm.get(
                          _vm.statements,
                          "egrn.data.generic_record.params.area_type.value",
                          ""
                        )
                          ? [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.get(
                                      _vm.statements,
                                      "egrn.data.generic_record.params.area_type.value",
                                      ""
                                    )
                                  ) +
                                  ":\n            "
                              )
                            ]
                          : _vm._e(),
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.get(
                                _vm.statements,
                                "egrn.data.generic_record.params.area",
                                ""
                              )
                            ) +
                            "\n            "
                        ),
                        _vm.get(
                          _vm.statements,
                          "egrn.data.generic_record.params.area_inaccuracy",
                          ""
                        )
                          ? [
                              _vm._v(
                                "\n              ± " +
                                  _vm._s(
                                    _vm.get(
                                      _vm.statements,
                                      "egrn.data.generic_record.params.area_inaccuracy",
                                      ""
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          : _vm._e(),
                        _vm._v("\n            м"),
                        _c("sup", [_vm._v("2")])
                      ],
                      2
                    )
                  : _vm._e()
              ]
            ),
            _c(
              "app-statement-table-row",
              { attrs: { title: "Адрес:", data: "" } },
              [
                _vm.get(
                  _vm.statements,
                  "egrn.data.generic_record.address_location.address.readable_address",
                  ""
                )
                  ? _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.get(
                              _vm.statements,
                              "egrn.data.generic_record.address_location.address.readable_address",
                              ""
                            )
                          ) +
                          "\n          "
                      )
                    ])
                  : _vm.get(
                      _vm.statements,
                      "egrn.data.generic_record.address_room.address.address.readable_address",
                      ""
                    )
                  ? _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.get(
                              _vm.statements,
                              "egrn.data.generic_record.address_room.address.address.readable_address",
                              ""
                            )
                          ) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _c("app-statement-table-row", {
              attrs: {
                title:
                  "Расположение в пределах объекта недвижимости, имеющего или не имеющего этажи:",
                data: _vm.info.Level ? "Этаж: " + _vm.info.Level : ""
              }
            }),
            _c("app-statement-table-row", {
              attrs: {
                title: "Кадастровая стоимость:",
                data: _vm._f("currency")(_vm.info.KadPrice, { precision: 2 })
              }
            })
          ],
          1
        )
      ]),
      _c("table", { staticClass: "statement-table" }, [
        _c(
          "tbody",
          [
            _c("app-statement-table-row", {
              attrs: {
                title:
                  "Кадастровые номера объектов, в которых расположен объект:",
                data:
                  _vm.get(
                    _vm.statements,
                    "egrn.data.generic_record.cad_links.parent_cad_number.cad_number",
                    ""
                  ) ||
                  _vm.get(
                    _vm.statements,
                    "egrn.data.generic_record.cad_links.land_cad_numbers.land_cad_number.cad_number",
                    ""
                  )
              }
            }),
            _c("app-statement-table-row", {
              attrs: {
                title:
                  "Кадастровые номера объектов недвижимости, из которых был образован данный объект:",
                data: _vm.get(
                  _vm.statements,
                  "egrn.data.generic_record.cad_links.old_numbers.old_number.number",
                  ""
                )
              }
            }),
            _vm.get(
              _vm.statements,
              "egrn.data.generic_record.cad_links.included_objects.length",
              ""
            )
              ? _c(
                  "app-statement-table-row",
                  {
                    attrs: {
                      title:
                        "Кадастровые номера расположенных в пределах земельного участка объектов недвижимости:",
                      data: ""
                    }
                  },
                  [
                    _c(
                      "td",
                      _vm._l(
                        _vm.get(
                          _vm.statements,
                          "egrn.data.generic_record.cad_links.included_objects",
                          ""
                        ),
                        function(obj) {
                          return _c("div", { key: obj.cad_number }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(obj.cad_number) +
                                "\n            "
                            )
                          ])
                        }
                      ),
                      0
                    )
                  ]
                )
              : _vm._e(),
            _vm.get(
              _vm.statements,
              "egrn.data.generic_record.cultural_heritage.included",
              false
            )
              ? _c(
                  "app-statement-table-row",
                  {
                    attrs: {
                      title:
                        "Сведения о включении объекта недвижимости в реестр объектов культурного наследия:",
                      data: ""
                    }
                  },
                  [
                    _c(
                      "td",
                      [
                        _vm._v(
                          "\n            Является выявленным объектом культурного наследия,\n            "
                        ),
                        _vm.get(
                          _vm.statements,
                          "egrn.data.generic_record.cultural_heritage.reg_number",
                          ""
                        )
                          ? [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.get(
                                      _vm.statements,
                                      "egrn.data.generic_record.cultural_heritage.reg_number",
                                      ""
                                    )
                                  ) +
                                  ",\n            "
                              )
                            ]
                          : _vm._e(),
                        _vm._v(
                          "\n            представляющего собой историко-культурную ценность,\n            "
                        ),
                        _vm.get(
                          _vm.statements,
                          "egrn.data.generic_record.cultural_heritage.name_object",
                          ""
                        )
                          ? [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.get(
                                      _vm.statements,
                                      "egrn.data.generic_record.cultural_heritage.name_object",
                                      ""
                                    )
                                  ) +
                                  ",\n            "
                              )
                            ]
                          : _vm._e(),
                        _vm.get(
                          _vm.statements,
                          "egrn.data.generic_record.cultural_heritage.purpose.value",
                          ""
                        )
                          ? [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.get(
                                      _vm.statements,
                                      "egrn.data.generic_record.cultural_heritage.purpose.value",
                                      ""
                                    )
                                  ) +
                                  ",\n            "
                              )
                            ]
                          : _vm._e(),
                        _vm.get(
                          _vm.statements,
                          "egrn.data.generic_record.cultural_heritage.heritage_decisions.length",
                          0
                        )
                          ? _vm._l(
                              _vm.get(
                                _vm.statements,
                                "egrn.data.generic_record.cultural_heritage.heritage_decisions"
                              ),
                              function(dec) {
                                return _c("span", { key: dec.Number }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(dec.Date) +
                                      ", " +
                                      _vm._s(dec.Number) +
                                      ",\n              "
                                  )
                                ])
                              }
                            )
                          : _vm._e(),
                        _vm._v(
                          "\n            решения органов охраны объектов культурного наследия - в отношении объекта недвижимости,\n            являющегося выявленным объектом культурного наследия.\n          "
                        )
                      ],
                      2
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "app-statement-table-row",
              {
                attrs: { title: "Сведения о кадастровом инженере:", data: "" }
              },
              [
                _vm.get(
                  _vm.statements,
                  "egrn.data.generic_record.cad_works.cad_work.fio_cad_ingineer.name",
                  ""
                )
                  ? _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.get(
                              _vm.statements,
                              "egrn.data.generic_record.cad_works.cad_work.fio_cad_ingineer.surname",
                              ""
                            )
                          ) +
                          "\n            " +
                          _vm._s(
                            _vm.get(
                              _vm.statements,
                              "egrn.data.generic_record.cad_works.cad_work.fio_cad_ingineer.name",
                              ""
                            )
                          ) +
                          "\n            " +
                          _vm._s(
                            _vm.get(
                              _vm.statements,
                              "egrn.data.generic_record.cad_works.cad_work.fio_cad_ingineer.patronymic",
                              ""
                            )
                          ) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ]
            ),
            _c("app-statement-table-row", {
              attrs: {
                title: "Особые отметки:",
                data: _vm.get(
                  _vm.statements,
                  "egrn.data.generic_record.special_notes",
                  ""
                )
              }
            }),
            _c("app-statement-table-row", {
              attrs: {
                title: "Статус записи об объекте недвижимости:",
                data: _vm.get(_vm.statements, "egrn.data.status", "")
              }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "pagebreak" }),
    _c("h1", { staticClass: "text-center" }, [
      _vm._v("\n    Отчет об объекте недвижимости\n  ")
    ]),
    _vm.get(
      _vm.statements,
      "egrn.data.ownerless_right_record.ownerless_right_data.authority_name",
      ""
    )
      ? _c("div", { staticClass: "statement-table-wrapper" }, [
          _c("table", { staticClass: "statement-table" }, [
            _c(
              "tbody",
              [
                _c("app-statement-table-row", {
                  attrs: {
                    title: "Правообладатель (правообладатели):",
                    data: _vm.get(
                      _vm.statements,
                      "egrn.data.ownerless_right_record.ownerless_right_data.authority_name",
                      ""
                    )
                  }
                }),
                _c(
                  "app-statement-table-row",
                  {
                    attrs: {
                      title:
                        "Вид, номер, дата и время государственной регистрации права:",
                      data: ""
                    }
                  },
                  [
                    _vm.get(
                      _vm.statements,
                      "egrn.data.ownerless_right_record.ownerless_right_data.ownerless_right_number",
                      ""
                    )
                      ? _c(
                          "td",
                          [
                            _vm._v(
                              "\n            Принят на учет как бесхозяйный объект недвижимости,\n            "
                            ),
                            _vm.get(
                              _vm.statements,
                              "egrn.data.ownerless_right_record.record_info.registration_date",
                              ""
                            )
                              ? [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.get(
                                          _vm.statements,
                                          "egrn.data.ownerless_right_record.record_info.registration_date",
                                          ""
                                        )
                                      ) +
                                      ",\n            "
                                  )
                                ]
                              : _vm._e(),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.get(
                                    _vm.statements,
                                    "egrn.data.ownerless_right_record.ownerless_right_data.ownerless_right_number",
                                    ""
                                  )
                                ) +
                                "\n          "
                            )
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ])
        ])
      : _vm._e(),
    _vm.currentOwners && _vm.currentOwners.rights
      ? _c(
          "div",
          { staticClass: "statement-table-wrapper" },
          _vm._l(_vm.currentOwners.rights, function(right, i) {
            return _c(
              "table",
              {
                key: right.registration.reg_date + i,
                staticClass: "statement-table"
              },
              [
                _c(
                  "tbody",
                  [
                    _c(
                      "app-statement-table-row",
                      {
                        attrs: {
                          title:
                            "Правообладатель № " +
                            (i + 1) +
                            " (Текущий собственник):",
                          data: ""
                        }
                      },
                      [
                        _c(
                          "td",
                          {},
                          _vm._l(right.entities, function(entity, entityIndex) {
                            return _c(
                              "span",
                              { key: entityIndex + "i" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(entity.content) +
                                    "\n              "
                                ),
                                entity.birth_date
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                дата рождения: " +
                                          _vm._s(
                                            _vm._f("date")(
                                              entity.birth_date,
                                              "DD.MM.YYYY"
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ])
                                  : _vm._e(),
                                entity.inn
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                ИНН: " +
                                          _vm._s(entity.inn) +
                                          "\n              "
                                      )
                                    ])
                                  : _vm._e(),
                                entity.ogrn
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                ОГРН: " +
                                          _vm._s(entity.ogrn) +
                                          "\n              "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.isOwnership(right) &&
                                right.entities.length > 1 &&
                                entityIndex !== right.entities.length - 1
                                  ? [
                                      _vm._v(
                                        "\n                ,\n              "
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          }),
                          0
                        )
                      ]
                    ),
                    _c(
                      "app-statement-table-row",
                      {
                        attrs: {
                          title: "Вид зарегистрированного права, доля в праве:",
                          data: ""
                        }
                      },
                      [
                        _vm.get(right, "right_data.right_type.value", "")
                          ? _c(
                              "td",
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.get(
                                        right,
                                        "right_data.right_type.value"
                                      )
                                    ) +
                                    "\n            "
                                ),
                                _vm.get(right, "registration.text", "")
                                  ? [
                                      _vm._v(
                                        "\n              ,\n              " +
                                          _vm._s(
                                            _vm.get(
                                              right,
                                              "registration.text",
                                              ""
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e()
                      ]
                    ),
                    _c("app-statement-table-row", {
                      attrs: {
                        title: "Номер зарегистрированного права:",
                        data: _vm.get(right, "registration.reg_number", "")
                      }
                    }),
                    _c("app-statement-table-row", {
                      attrs: {
                        title: "Дата государственной регистрации права:",
                        data: _vm.get(right, "registration.reg_date", "")
                      }
                    }),
                    _c("app-statement-table-row", {
                      attrs: {
                        title:
                          "Вид, номер и дата государственной регистрации права:",
                        data: _vm.regName(right)
                      }
                    }),
                    _c("app-statement-table-row", {
                      attrs: {
                        title:
                          "Основание возникновения/прекращения права (полученные документы):",
                        data: "Данные не обнаружены*"
                      }
                    }),
                    _c(
                      "app-statement-table-row",
                      {
                        attrs: {
                          title:
                            "Сведения о невозможности государственной регистрации без личного участия правообладателя или его законного представителя:",
                          data: "Нет информации"
                        }
                      },
                      [
                        _vm.get(right, "personal_participations.length", 0)
                          ? _c(
                              "td",
                              _vm._l(right.personal_participations, function(
                                part
                              ) {
                                return _c("div", { key: part.text }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(part.text) +
                                      "\n            "
                                  )
                                ])
                              }),
                              0
                            )
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm.encumbrance && _vm.encumbrance.length
      ? _c("div", { staticClass: "statement-table-wrapper" }, [
          _c(
            "table",
            { staticClass: "statement-table" },
            _vm._l(_vm.encumbrance, function(enc, i) {
              return _c(
                "tbody",
                { key: i },
                [
                  _c("app-statement-table-row", {
                    attrs: {
                      title: "Обременение № " + (i + 1) + ":",
                      data: _vm.get(enc, "registration.type.type_name", "")
                    }
                  }),
                  _c("app-statement-table-row", {
                    attrs: {
                      title: "Дата государственной регистрации:",
                      data: _vm.get(enc, "registration.reg_date", "")
                    }
                  }),
                  _c("app-statement-table-row", {
                    attrs: {
                      title: "Номер государственной регистрации:",
                      data: _vm.get(enc, "registration.reg_number", "")
                    }
                  }),
                  _c(
                    "app-statement-table-row",
                    {
                      attrs: {
                        title:
                          "Срок, на который установлено ограничение прав и обременение объекта недвижимости:",
                        data: ""
                      }
                    },
                    [
                      _vm.get(enc, "duration.started", "")
                        ? _c(
                            "td",
                            {},
                            [
                              _vm._v(
                                "\n            Срок действия с " +
                                  _vm._s(_vm.get(enc, "duration.started", "")) +
                                  "\n            "
                              ),
                              _vm.get(enc, "duration.term", "")
                                ? [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.get(enc, "duration.term", "")
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "app-statement-table-row",
                    {
                      attrs: {
                        title:
                          "Лицо, в пользу которого установлено ограничение прав и обременение объекта недвижимости:",
                        data: ""
                      }
                    },
                    [
                      enc.entities && enc.entities.length
                        ? _c(
                            "td",
                            {},
                            _vm._l(enc.entities, function(org, orgIndex) {
                              return _c(
                                "div",
                                { key: orgIndex },
                                [
                                  org.content || org.name
                                    ? [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(org.content || org.name) +
                                            "\n                "
                                        ),
                                        org.inn || org.ogrn
                                          ? _c(
                                              "div",
                                              [
                                                org.inn
                                                  ? [
                                                      _vm._v(
                                                        "\n                    ИНН " +
                                                          _vm._s(org.inn) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  : _vm._e(),
                                                org.ogrn
                                                  ? [
                                                      _vm._v(
                                                        "\n                    ОГРН " +
                                                          _vm._s(org.ogrn) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          : _vm._e()
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                Имя держателя права не определено\n              "
                                        )
                                      ]
                                ],
                                2
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "app-statement-table-row",
                    {
                      attrs: {
                        title: "Основание государственной регистрации:",
                        data: ""
                      }
                    },
                    [
                      enc.docs && enc.docs.length
                        ? _c(
                            "td",
                            {},
                            _vm._l(enc.docs, function(d, docIndex) {
                              return _c(
                                "div",
                                { key: docIndex },
                                [
                                  d.Content
                                    ? [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(d.Content) +
                                            "\n              "
                                        )
                                      ]
                                    : _vm._e(),
                                  d.Name && d.Name !== d.Content
                                    ? [
                                        _vm._v(
                                          "\n                : " +
                                            _vm._s(d.Name) +
                                            "\n              "
                                        )
                                      ]
                                    : _vm._e(),
                                  d.Number
                                    ? [
                                        _vm._v(
                                          "\n                № " +
                                            _vm._s(d.Number) +
                                            ",\n              "
                                        )
                                      ]
                                    : _vm._e(),
                                  d.DocumentIssuer
                                    ? [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(d.DocumentIssuer) +
                                            ",\n              "
                                        )
                                      ]
                                    : _vm._e(),
                                  d.IssueOrgan
                                    ? [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(d.IssueOrgan) +
                                            ",\n              "
                                        )
                                      ]
                                    : _vm._e(),
                                  d.Date
                                    ? [
                                        _vm._v(
                                          "\n                дата документа: " +
                                            _vm._s(
                                              _vm._f("date")(
                                                d.Date,
                                                "DD.MM.YYYY"
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ]
                  ),
                  _c("app-statement-table-row", {
                    attrs: {
                      title: "Заявленные в судебном порядке права требования:",
                      data: "Данные не обнаружены*"
                    }
                  }),
                  _c("app-statement-table-row", {
                    attrs: {
                      title:
                        "Сведения о возражении в отношении зарегистрированного права:",
                      data: "Данные не обнаружены*"
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "statement-table-wrapper" },
      [
        _c("table", { staticClass: "statement-table" }, [
          _c(
            "tbody",
            [
              !_vm.encumbrance || !_vm.encumbrance.length
                ? _c("app-statement-table-row", {
                    attrs: {
                      title:
                        "Ограничение прав и обременение объекта недвижимости:",
                      data: "Не зарегистрировано"
                    }
                  })
                : _vm._e(),
              _c("app-statement-table-row", {
                attrs: {
                  title:
                    "Сведения о наличии решения об изъятии объекта недвижимости для государственных и муниципальных нужд:",
                  data: "Данные не обнаружены*"
                }
              }),
              _c("app-statement-table-row", {
                attrs: {
                  title:
                    "Правопритязания и сведения о наличии поступивших, но не рассмотренных заявлений о проведении государственной регистрации права (перехода, прекращения права), ограничения права или обременения объекта недвижимости, сделки в отношении объекта недвижимости:",
                  data: "Данные не обнаружены*"
                }
              }),
              _c("app-statement-table-row", {
                attrs: {
                  title:
                    "Сведения об осуществлении государственной регистрации сделки, права, ограничения права без необходимого в силу закона согласия третьего лица, органа:",
                  data: "Данные не обнаружены*"
                }
              })
            ],
            1
          )
        ]),
        _vm.get(
          _vm.statements,
          "egrn.data.generic_record.contours_location.contours.length",
          0
        )
          ? _vm._l(
              _vm.statements.egrn.data.generic_record.contours_location
                .contours,
              function(contour) {
                return _c("app-egrn-object-contours", {
                  key: contour.cad_number,
                  attrs: { contour: contour }
                })
              }
            )
          : _vm._e(),
        _vm.get(
          _vm.statements,
          "egrn.data.generic_record.contours_location.borders.length",
          0
        )
          ? [
              _c("app-egrn-object-borders", {
                attrs: {
                  borders:
                    _vm.statements.egrn.data.generic_record.contours_location
                      .borders
                }
              })
            ]
          : _vm._e()
      ],
      2
    ),
    _vm.objectPlan && _vm.objectPlan.length
      ? _c("div", { staticClass: "pagebreak" }, [
          _c("h1", { staticClass: "text-center" }, [
            _vm._v("\n      Прикрепленные к объекту планы\n    ")
          ]),
          _c("div", { staticClass: "statement-table-wrapper" }, [
            _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.objectPlan, function(elem, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "col-12",
                    class: { "col-md-6 mb-4": _vm.objectPlan.length > 4 }
                  },
                  [
                    _c("img", {
                      staticClass: "egrn-object-pdf__image",
                      attrs: { src: elem }
                    })
                  ]
                )
              }),
              0
            )
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "pagebreak" }),
    _c("h1", { staticClass: "text-center" }, [
      _vm._v("\n    Термины и описания\n  ")
    ]),
    _c("div", { staticClass: "statement-table-wrapper" }, [
      _c("div", { staticClass: "egrn-terms" }, [
        _c("div", { staticClass: "row" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "col egrn-terms__elems" },
            [
              _vm.terms.length
                ? _vm._l(_vm.terms, function(term) {
                    return _c("div", { key: term.Header }, [
                      _c("b", [
                        _vm._v(
                          "\n                " +
                            _vm._s(term.Header) +
                            "\n              "
                        )
                      ]),
                      _vm._v(
                        "\n              - " +
                          _vm._s(term.Text) +
                          "\n            "
                      )
                    ])
                  })
                : _vm._e(),
              _vm._m(1),
              _vm._m(2)
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-auto" }, [
      _c("i", { staticClass: "fas fa-info-circle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("b", [_vm._v("\n              С 1 марта 2023 года\n            ")]),
      _vm._v(
        "\n            - вступил в силу запрет на передачу персональных данных из\n            ЕГРН без согласия правообладателя. Для того чтобы сведения о\n            собственниках в выписке из ЕГРН были доступны, владелец\n            недвижимости должен открыть сведения о своих фамилии, имени,\n            отчестве и дате рождения, подав специальное заявление в Росреестр,\n            в котором разрешит предоставлять их третьим лицам. Для\n            этого можно лично посетить МФЦ или отправить туда документы почтой.\n            Также есть возможность подать заявление в электронной форме в личном кабинете\n            на официальном сайте Росреестра или через портал Госуслуг.\n          "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("b", [_vm._v("\n              Кадастровая стоимость\n            ")]),
      _vm._v(
        "\n            - результат оценки стоимости объекта недвижимости, полученный на\n            определенную дату, определяемый на основе ценообразующих и иных факторов,\n            в порядке, предусмотренном Федеральным законом от 03.07.2016 No 237-ФЗ\n            «О государственной кадастровой оценке» и методическими указаниями о\n            государственной кадастровой оценке, утвержденными приказом Минэкономразвития\n            России от 12.05.2017 No 226, или в соответствии со статьей 16, 20, 21 или 22\n            Федерального закона от 03.07.2016 No 237-ФЗ. Используется, в частности, для\n            целей налогообложения. Кадастровая стоимость обычно ниже рыночной.\n          "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }