







































































































import Vue from 'vue';
import Component from 'vue-class-component';
import get from 'lodash/get';

@Component({
  components: {},
  props: {
    result: Array,
    name: String,
    isPdf: Boolean,
  },
})
export default class AppSocialMediaReportElem extends Vue {
  // props
  result: any[];
  name: string;
  isPdf: boolean;

  getField(elem: any, name: string) {
    const recordFields = get(elem, 'record.0.fields');
    if (recordFields) {
      const field = recordFields.find((field: any) => {
        return field.field_name === name;
      });
      return field ? field.field_value : undefined;
    }
  }

  get uniqueProfiles() {
    const profileMap = new Map<string, any>();

    this.result.forEach((item: any) => {
      const link = this.getField(item, 'Link') || '';
      const photo = this.getField(item, 'photo');
      const phone = this.getField(item, 'phone');

      if (!link && !phone) return;

      if (!profileMap.has(link) || photo || phone) {
        profileMap.set(link, item);
      }
    });

    return Array.from(profileMap.values());
  }
}
