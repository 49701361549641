var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sud-details" }, [
    _c(
      "div",
      {
        staticClass: "popup__header",
        on: {
          click: function($event) {
            return _vm.$emit("toggleDetails")
          }
        }
      },
      [
        _c(
          "app-button",
          {
            staticClass: "sud-details__back",
            attrs: { variant: "light", size: "sm" }
          },
          [
            _c("i", { staticClass: "fas fa-xs fa-arrow-left mr-1" }),
            _vm._v("\n      Назад\n    ")
          ]
        )
      ],
      1
    ),
    _c("div", { staticClass: "popup__body" }, [
      _c("div", { staticClass: "sud-details__content" }, [
        _c("div", { staticClass: "sud-details__title" }, [
          _vm._v("\n        Детали дела\n      ")
        ]),
        _c(
          "div",
          { staticClass: "sud-details-item" },
          [
            _c("div", { staticClass: "sud-details-item__title" }, [
              _vm._v("\n          Тип\n        ")
            ]),
            _vm._l(_vm.details.list_cases, function(elem) {
              return _c(
                "div",
                { key: elem.name, staticClass: "sud-details-item__value" },
                [
                  _c("span", [
                    _vm._v(
                      "\n            " + _vm._s(elem.name) + "\n          "
                    )
                  ])
                ]
              )
            })
          ],
          2
        ),
        _c("div", { staticClass: "sud-details-item" }, [
          _c("div", { staticClass: "sud-details-item__title" }, [
            _vm._v("\n          Категория\n        ")
          ]),
          _c("div", { staticClass: "sud-details-item__value" }, [
            _vm.details.category
              ? _c("span", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.details.category) +
                      "\n          "
                  )
                ])
              : _c("span", [_vm._v("\n            Отсутствует\n          ")])
          ])
        ]),
        _c("div", { staticClass: "sud-details-item" }, [
          _vm.details.judge
            ? _c("div", { staticClass: "sud-details-item__title" }, [
                _vm._v("\n          Судья\n        ")
              ])
            : _vm._e(),
          _c("div", { staticClass: "sud-details-item__value" }, [
            _vm._v("\n          " + _vm._s(_vm.details.judge) + "\n        ")
          ])
        ]),
        _c("div", { staticClass: "sud-details-item" }, [
          _c("div", { staticClass: "sud-details-item__title" }, [
            _vm._v("\n          Начало рассмотрения\n        ")
          ]),
          _c("div", { staticClass: "sud-details-item__value" }, [
            _vm.details.date
              ? _c("span", [
                  _vm._v(
                    "\n            " + _vm._s(_vm.details.date) + "\n          "
                  )
                ])
              : _c("span", [_vm._v("\n            Отсутствует\n          ")])
          ])
        ]),
        _c("div", { staticClass: "sud-details-item" }, [
          _c("div", { staticClass: "sud-details-item__title" }, [
            _vm._v("\n          Статус\n        ")
          ]),
          _c("div", { staticClass: "sud-details-item__value" }, [
            _vm.details.status
              ? _c("span", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.details.status) +
                      "\n          "
                  )
                ])
              : _c("span", [_vm._v("\n            Отсутствует\n          ")])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }