


























































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import AppPledgeReportDetails from 'shared/components/FizCheck/Details/PledgeReportDetails.vue';
import get from 'lodash/get';

@Component({
  components: { AppReportItem, AppPledgeReportDetails },
  props: {
    report: Object,
    status: String,
    images: Array,
    orderIsDone: Boolean,
    missingRequirements: Array,
    openAddFieldDialog: Function,
    updateMap: Function,
    task: Object,
    isPhysical: Boolean,
    onlyOwners: Boolean,
    map: Object,
  },
})
export default class AppLicenseReport extends Vue {
  // props
  missingRequirements: any[];
  report: any;
  status: string;
  images: any[];
  orderIsDone: boolean;
  task: any;
  isPhysical: boolean;
  onlyOwners: boolean;
  get = get;
  openAddFieldDialog: (val: string) => any;
  updateMap: (taskName: string, field: string, value: any) => void;
  opened: boolean = false;
  map: any;
  resultStatusTrace: any = null;

  created() {
    this.resultStatusTrace = this.resultStatus;
  }

  get statusDone() {
    return this.status === 'done';
  }

  get resultStatus() {
    if (this.task && this.statusDone) {
      if (get(this.task, 'task_result.result.sources.0.record.length')) {
        this.updateColor(this.isDeprived ? 'danger' : 'success');
        return 'found';
      } else {
        this.updateColor('danger');
        return 'not found';
      }
    }
    this.updateColor('info');
    return 'no data';
  }

  get isDeprived() {
    return get(this.task, 'task_result.result.sources.0.record.length') > 1;
  }

  get result() {
    return get(this.records, '0');
  }

  get cases() {
    return get(this.task, 'task_result.result.sources');
  }

  get records() {
    if (!this.cases) {
      return null;
    }
    const result: any[] = [];
    this.cases.forEach((elem: any) => {
      let recordResult: any = {
        properties: [],
        deprivationLicense: [],
      };
      if (elem.record && elem.record.length) {
        elem.record.forEach((record: any) => {
          const obj = this.toObject(record.fields);
          if (obj.Type === 'properties') {
            recordResult.properties.push(obj);
          } else if (obj.Comment === 'ЛИШЕНИЕ') {
            recordResult.deprivationLicense = obj;
          } else {
            recordResult = Object.assign(recordResult, obj);
          }
        });
        result.push(recordResult);
      }
    });
    console.log('result', result);
    return result;
  }

  toObject(arr: any[]) {
    const res: any = {};
    arr.forEach((elem) => {
      res[elem.field_name] = elem.field_value;
    });
    return res;
  }

  updateColor(color: string) {
    this.updateMap('license', 'color', color);
  }
}
