





















































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';
// import AppSudrfDetails from 'shared/components/RiskNew/SudrfDetails.vue';
import AppSudrfDetails from 'shared/components/RiskNew/SudrfDetailsAlt.vue';
import AppSudactDetails from 'shared/components/RiskNew/SudactDetails.vue';
import AppMosgorsudDetails from 'shared/components/RiskNew/MosgorsudDetails.vue';

import 'moment/locale/ru';

moment.locale('ru');

@Component({
  props: {
    result: Array,
    mosgorsud: Array,
    sudact: Array,
  },
  components: {
    AppSudrfDetails,
    AppMosgorsudDetails,
    AppSudactDetails,
  },
  popupOptions: {
    name: 'popup_side popup_sud-details',
  },
})
export default class AppSudDetails extends Vue {
  showDetails: boolean = false;
  details: any = null;
  showMosgorsud: boolean = false;
  result: any[];
  mosgorsud: any[];
  sudact: any[];
  detailsSource = '';
  typesByKey = {
    A: 'Административные дела',
    G: 'Гражданские дела',
    U: 'Уголовные дела',
    M: 'Производство по материалам',
    O: 'Прочие дела',
  };

  toggleDetails(data: any, source: string = '') {
    this.showDetails = !this.showDetails;
    this.detailsSource = source;
    if (this.showDetails) {
      this.details = data;
    } else {
      this.details = null;
    }
  }

  // parseDate(date: string) {
  //   console.log(date);
  //   console.log(moment(date, 'D MMMM YYYY').toDate());
  // }

  getField(data: any, val: string) {
    const res = data.additionalFields.find((el: any) => el.name === val);
    if (!res) {
      return 'Отсутствует';
    }
    if (res.value) {
      return res.value;
    } else if (res.dateValue) {
      return res.dateValue;
    }
    return 'Отсутствует';
  }
}
