var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body owner-add-field" },
    [
      _c("app-dialog-close"),
      _c("h4", { staticClass: "mb-3" }, [_vm._v("\n    ИНН\n  ")]),
      _c(
        "app-form",
        {
          attrs: {
            model: _vm.model,
            rules: _vm.validationConfig,
            "request-handler": _vm.onSubmit
          }
        },
        [
          _c(
            "app-form-group",
            { staticClass: "rc-form-group", attrs: { "model-name": "inn" } },
            [
              _c("app-input", {
                attrs: { label: "ИНН", type: "number", size: "sm" },
                model: {
                  value: _vm.model.inn,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "inn", $$v)
                  },
                  expression: "model.inn"
                }
              })
            ],
            1
          ),
          _c(
            "app-button",
            {
              attrs: {
                type: "submit",
                variant: "primary",
                size: "lg",
                loading: _vm.loading,
                block: ""
              }
            },
            [_vm._v("\n      Сохранить\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }