var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm.model
        ? _c(
            "app-form",
            { attrs: { "request-handler": _vm.onSubmit, model: _vm.model } },
            [
              _c(
                "div",
                { staticClass: "row mb-3" },
                _vm._l(_vm.job.inputs, function(input) {
                  return _c(
                    "div",
                    { key: input.index, staticClass: "col-12 col-md-6" },
                    [
                      _c(
                        "app-form-group",
                        {
                          staticClass: "owner-form-input",
                          attrs: {
                            "model-id": input.index,
                            "model-name": input.name
                          }
                        },
                        [
                          input.value_type === "data"
                            ? _c("app-date-picker", {
                                attrs: {
                                  placeholder: "ДД.ММ.ГГГГ",
                                  options: { defaultDate: "" },
                                  label: input.description
                                },
                                model: {
                                  value: _vm.model[input.index],
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, input.index, $$v)
                                  },
                                  expression: "model[input.index]"
                                }
                              })
                            : input.value_type === "bool"
                            ? _c(
                                "app-checkbox",
                                {
                                  model: {
                                    value: _vm.model[input.index],
                                    callback: function($$v) {
                                      _vm.$set(_vm.model, input.index, $$v)
                                    },
                                    expression: "model[input.index]"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(input.description) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _c("app-input", {
                                attrs: {
                                  placeholder: input.example,
                                  label: input.description
                                },
                                model: {
                                  value: _vm.model[input.index],
                                  callback: function($$v) {
                                    _vm.$set(_vm.model, input.index, $$v)
                                  },
                                  expression: "model[input.index]"
                                }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                0
              ),
              _c("app-button", { attrs: { variant: "info", type: "submit" } }, [
                _vm._v("\n      Отправить\n    ")
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }