





















import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'AppHiddenText',
  props: {
    text: String,
    maxHeight: String,
  },
})
export default class AppHiddenText extends Vue {
  // props
  text: string;
  maxHeight: string;

  showAll: boolean = false;
  needCollapse: boolean = false;

  mounted() {
    if ((this.$refs.textContainer as any).clientHeight >= parseInt(this.maxHeight)) {
      this.needCollapse = true;
    }
  }
}
