<template>
  <div class="popup__body">
    <h5 class="mb-4">
      Пометки
    </h5>
    <app-dialog-close />

    <template v-if="notes.egrn">
      <strong>Отчеты ЕГРН</strong>
      <p>{{ notes.egrn }}</p>
    </template>
    <template v-if="notes.right">
      <strong>Выписки Перехда прав</strong>
      <p>{{ notes.right }}</p>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    notes: Object,
  },
};
</script>
