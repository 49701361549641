var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-statement-item",
    {
      attrs: {
        "is-disabled": _vm.isDisabled,
        "is-open": !!_vm.restrictions || !!_vm.missingEncumbrances
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c("i", { staticClass: "fas fa-shield-alt fa-fw mr-1 icon" }),
              _vm._v(" Ограничения\n    ")
            ]
          },
          proxy: true
        },
        {
          key: "status",
          fn: function() {
            return [
              !_vm.status.isPurchased && _vm.showPayInfo
                ? [
                    _c("client-only", [
                      _c("div", { staticClass: "risk-report-item__status" }, [
                        _c("i", {
                          staticClass: "fas fa-lock mr-2 text-danger"
                        }),
                        _c("span", { staticClass: "d-none d-md-inline" }, [
                          _vm._v("Доступно после заказа")
                        ]),
                        _c("img", {
                          staticClass: "ml-md-4",
                          attrs: { src: "/images/eye.svg" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("icon-click")
                            }
                          }
                        })
                      ])
                    ])
                  ]
                : [
                    _vm.status.isPurchased && !_vm.status.isCompleted
                      ? [
                          _c("i", {
                            staticClass: "fas fa-info-circle text-info"
                          }),
                          _vm._v(" В работе\n      ")
                        ]
                      : _vm.restrictions || _vm.missingEncumbrances
                      ? [
                          _c("i", {
                            staticClass:
                              "fas fa-exclamation-circle text-warning"
                          }),
                          _vm._v(
                            " Найдено: " +
                              _vm._s(_vm.restrictionsCount) +
                              "\n      "
                          )
                        ]
                      : _vm.noRestrictions
                      ? [
                          _c("i", {
                            staticClass: "fas fa-check-circle text-success"
                          }),
                          _vm._v(" Не зарегистрировано\n      ")
                        ]
                      : _vm._e()
                  ]
            ]
          },
          proxy: true
        }
      ])
    },
    [
      [
        _vm.missingEncumbrances
          ? _vm._l(_vm.missingEncumbrances, function(elem, i) {
              return _c("div", { key: i, staticClass: "encumbrance-item" }, [
                _c("h6", { staticClass: "mb-3" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.getRestrictionType(elem.type)) +
                      "\n        "
                  )
                ]),
                _c("div", { staticClass: "encumbrance-item__content row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6" },
                    [
                      elem.encumbrance_date
                        ? [
                            _c(
                              "div",
                              { staticClass: "encumbrance-item__row" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "encumbrance-item__row-title"
                                  },
                                  [_vm._v("Дата: ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "encumbrance-item__row-content"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(elem.encumbrance_date) +
                                        "\n                "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ])
              ])
            })
          : _vm._e(),
        _vm.restrictions
          ? [
              _vm._l(_vm.restrictions.slice(0, _vm.showCount), function(
                enc,
                i
              ) {
                return _c("div", { key: i, staticClass: "encumbrance-item" }, [
                  _c("h6", { staticClass: "mb-3" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.getEncumbranceType(enc)) +
                        "\n        "
                    )
                  ]),
                  _c("div", { staticClass: "encumbrance-item__content row" }, [
                    _vm.get(enc, "cad_links.reg_number_border.TypeZone.Name")
                      ? _c("div", { staticClass: "col-12 mb-3" }, [
                          _c("div", { staticClass: "encumbrance-item__row" }, [
                            _c(
                              "div",
                              { staticClass: "encumbrance-item__row-content" },
                              [
                                _vm._v(
                                  "\n                Территориальный зоны и зоны с особыми условия использования территорий:\n              "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "encumbrance-item__row-content" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.get(
                                        enc,
                                        "cad_links.reg_number_border.TypeZone.Name"
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.get(enc, "cad_links.reg_number_border.Number")
                      ? _c("div", { staticClass: "col-12 mb-3" }, [
                          _c("div", { staticClass: "encumbrance-item__row" }, [
                            _c(
                              "span",
                              { staticClass: "encumbrance-item__row-title" },
                              [
                                _vm._v(
                                  "\n                Реестровый номер:\n              "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "encumbrance-item__row-content" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.get(
                                        enc,
                                        "cad_links.reg_number_border.Number"
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.get(enc, "cad_links.reg_number_border.NameByDoc")
                      ? _c("div", { staticClass: "col-12 mb-3" }, [
                          _c("div", { staticClass: "encumbrance-item__row" }, [
                            _c(
                              "span",
                              { staticClass: "encumbrance-item__row-title" },
                              [
                                _vm._v(
                                  "\n                Вид зоны по документу:\n              "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "encumbrance-item__row-content" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.get(
                                        enc,
                                        "cad_links.reg_number_border.NameByDoc"
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        enc.registration
                          ? [
                              enc.registration.reg_date
                                ? _c(
                                    "div",
                                    { staticClass: "encumbrance-item__row" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "encumbrance-item__row-title"
                                        },
                                        [_vm._v("Дата регистрации: ")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "encumbrance-item__row-content"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                enc.registration.reg_date
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              enc.registration.reg_number
                                ? _c(
                                    "div",
                                    { staticClass: "encumbrance-item__row" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "encumbrance-item__row-title"
                                        },
                                        [_vm._v("Номер регистрации: ")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "encumbrance-item__row-content"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                enc.registration.reg_number
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          : _vm._e(),
                        enc.duration &&
                        (enc.duration.started || enc.duration.term)
                          ? _c(
                              "div",
                              { staticClass: "encumbrance-item__row" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "encumbrance-item__row-title"
                                  },
                                  [_vm._v("Продолжительность ограничения: ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "encumbrance-item__row-content"
                                  },
                                  [
                                    enc.duration.term
                                      ? _c("span", [
                                          _vm._v(_vm._s(enc.duration.term))
                                        ])
                                      : [
                                          _vm._v(
                                            "\n                  с " +
                                              _vm._s(enc.duration.started) +
                                              "\n                  "
                                          ),
                                          enc.duration.stopped &&
                                          !enc.duration.term
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                    до " +
                                                    _vm._s(
                                                      enc.duration.stopped
                                                    ) +
                                                    "\n                  "
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                  ],
                                  2
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      2
                    ),
                    _c("div", { staticClass: "col-12 col-md-6" }, [
                      enc.entities && enc.entities.length
                        ? _c(
                            "div",
                            { staticClass: "encumbrance-item__row" },
                            [
                              _c(
                                "span",
                                { staticClass: "encumbrance-item__row-title" },
                                [
                                  _vm._v(
                                    "\n                Лицо, в пользу которого установлено ограничение прав и обременение:\n              "
                                  )
                                ]
                              ),
                              _vm._l(enc.entities, function(org, orgIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: orgIndex,
                                    staticClass: "encumbrance-item__row-content"
                                  },
                                  [
                                    org.content || org.name
                                      ? [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(org.content || org.name) +
                                              "\n                "
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                  Имя держателя права не определено\n                "
                                          )
                                        ]
                                  ],
                                  2
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      enc.docs && enc.docs.length
                        ? _c(
                            "div",
                            { staticClass: "encumbrance-item__row" },
                            [
                              _c(
                                "span",
                                { staticClass: "encumbrance-item__row-title" },
                                [
                                  _vm._v(
                                    "\n                Основание государственной регистрации:\n              "
                                  )
                                ]
                              ),
                              _vm._l(enc.docs, function(d, docIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: docIndex,
                                    staticClass: "encumbrance-item__row-content"
                                  },
                                  [
                                    d.Content
                                      ? [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(d.Content) +
                                              ":\n                "
                                          )
                                        ]
                                      : _vm._e(),
                                    d.Name
                                      ? [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(d.Name) +
                                              "\n                "
                                          )
                                        ]
                                      : _vm._e(),
                                    d.Number
                                      ? [
                                          _vm._v(
                                            "\n                  № " +
                                              _vm._s(d.Number) +
                                              ",\n                "
                                          )
                                        ]
                                      : _vm._e(),
                                    d.DocumentIssuer
                                      ? [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(d.DocumentIssuer) +
                                              ",\n                "
                                          )
                                        ]
                                      : _vm._e(),
                                    d.IssueOrgan
                                      ? [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(d.IssueOrgan) +
                                              ",\n                "
                                          )
                                        ]
                                      : _vm._e(),
                                    d.Date
                                      ? [
                                          _vm._v(
                                            "\n                  дата документа: " +
                                              _vm._s(
                                                _vm._f("date")(
                                                  d.Date,
                                                  "DD.MM.YYYY"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ]),
                    enc.share_text
                      ? _c("div", { staticClass: "col-12 mt-3" }, [
                          _c("div", { staticClass: "encumbrance-item__row" }, [
                            _c(
                              "span",
                              { staticClass: "encumbrance-item__row-title" },
                              [
                                _vm._v(
                                  "\n                Дополнительные сведения:\n              "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "encumbrance-item__row-content" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(enc.share_text) +
                                    "\n              "
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e()
                  ])
                ])
              }),
              _vm.restrictions &&
              _vm.restrictions.length > 50 &&
              _vm.showCount < _vm.restrictions.length
                ? _c("div", { staticClass: "text-center pt-2" }, [
                    _vm.$isServer
                      ? _c("span", { staticClass: "text-info" }, [
                          _vm._v(
                            "\n          В PDF отчете отображены " +
                              _vm._s(_vm.showCount) +
                              " из " +
                              _vm._s(_vm.restrictions.length) +
                              " ограничений.\n          Просмотр всех ограничений доступен в web версии отчета.\n        "
                          )
                        ])
                      : _c(
                          "span",
                          {
                            staticClass: "link",
                            on: {
                              click: function($event) {
                                _vm.showCount += 50
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          Показать еще (" +
                                _vm._s(
                                  _vm.restrictions.length - _vm.showCount
                                ) +
                                ")\n        "
                            )
                          ]
                        )
                  ])
                : _vm._e()
            ]
          : _vm._e()
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }