var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "popup__header" },
      [
        _c("div", { staticClass: "popup__title" }, [
          _vm._v("\n      Выбор ответственного\n    ")
        ]),
        _c("app-dialog-close")
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "popup__body" },
      [
        _vm.userItems.length === 0
          ? _c(
              "app-form",
              {
                attrs: { model: _vm.model, "request-handler": _vm.onSubmit },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "button",
                      fn: function(ref) {
                        var loading = ref.loading
                        return [
                          _c(
                            "app-button",
                            {
                              attrs: {
                                loading: loading,
                                size: "sm",
                                block: "",
                                type: "submit"
                              }
                            },
                            [_vm._v("\n          Добавить\n        ")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3621631937
                )
              },
              [
                _c(
                  "app-form-group",
                  [
                    _c("app-input", {
                      attrs: {
                        placeholder: "email ответственного",
                        size: "sm"
                      },
                      model: {
                        value: _vm.model.email,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "email", $$v)
                        },
                        expression: "model.email"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm.userItems.length > 1
          ? [
              _c("div", { staticClass: "font-weight-500 mb-1" }, [
                _vm._v(
                  "\n        Найдено несколько пользователей с похожим email, выберите один из них\n      "
                )
              ]),
              _vm._l(_vm.userItems, function(user) {
                return _c(
                  "div",
                  {
                    key: user.id,
                    staticClass: "py-1 user-item",
                    on: {
                      click: function($event) {
                        return _vm.addResponsibleToUser(user)
                      }
                    }
                  },
                  [_vm._v("\n        " + _vm._s(user.email) + "\n      ")]
                )
              }),
              _c("div", { staticClass: "mt-2" }, [
                _c(
                  "span",
                  {
                    staticClass: "link",
                    on: {
                      click: function($event) {
                        _vm.userItems = []
                      }
                    }
                  },
                  [_vm._v("\n          Назад\n        ")]
                )
              ])
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }