var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-report-item",
    {
      attrs: {
        title: "Арбитражные суды",
        icon: "gavel",
        status: _vm.status,
        images: _vm.images,
        opened: _vm.resultStatus === "found" || _vm.expand,
        color: _vm.map.color,
        "missing-requirements": _vm.missingRequirements,
        source: {
          domain: "kad.arbitr.ru",
          link: "https://kad.arbitr.ru/"
        }
      },
      scopedSlots: _vm._u(
        [
          {
            key: "extra-slot",
            fn: function() {
              return [_vm._t("default")]
            },
            proxy: true
          },
          _vm.resultStatus === "found"
            ? {
                key: "extra-content",
                fn: function() {
                  return [
                    _c("div", { staticClass: "report-item-result__graph" }, [
                      _c(
                        "div",
                        { staticClass: "report-item-result__graph-filter" },
                        [
                          _c("div", [
                            _c("h4", [
                              _vm._v("\n            Совпадения:\n          ")
                            ])
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__graph-filter-radio"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__graph-filter-radio-btn",
                                  attrs: {
                                    active: _vm.currentFilter === "all",
                                    disabled: !_vm.totalCount
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.currentFilter = "all"
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Все (" +
                                      _vm._s(_vm.totalCount) +
                                      ")\n          "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__graph-filter-radio-btn",
                                  attrs: {
                                    active: _vm.currentFilter === "fio",
                                    disabled: !_vm.result.count_fio
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.currentFilter = "fio"
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Полное ФИО (" +
                                      _vm._s(_vm.result.count_fio) +
                                      ")\n          "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__graph-filter-radio-btn",
                                  attrs: {
                                    active: _vm.currentFilter === "shortFio",
                                    disabled: !_vm.result.count_short_fio
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.currentFilter = "shortFio"
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Фамилия и инициалы (" +
                                      _vm._s(_vm.result.count_short_fio) +
                                      ")\n          "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__graph-filter-radio-btn",
                                  attrs: {
                                    active: _vm.currentFilter === "inn",
                                    disabled: !_vm.result.count_inn
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.currentFilter = "inn"
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            ИНН (" +
                                      _vm._s(_vm.result.count_inn) +
                                      ")\n          "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm.currentFilter !== "inn"
                        ? _c("div", { staticClass: "rc-modern-info-panel" }, [
                            _c(
                              "div",
                              { staticClass: "rc-modern-info-panel__wrapper" },
                              [
                                _c("div", [_c("app-warning-img")], 1),
                                _c("div", [
                                  _vm._v(
                                    "\n            Возможны однофамильцы\n          "
                                  )
                                ]),
                                _c("div", { staticClass: "ml-auto" }, [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "tippy",
                                          rawName: "v-tippy",
                                          value: {
                                            placement: "top-end",
                                            content:
                                              "В отчете могут быть указаны дела не только проверяемого лица из-за отсутствия данных о дате рождения в источниках. При редком ФИО риск минимален. Чтобы сузить количество дел укажите регион."
                                          },
                                          expression:
                                            "{\n                placement: 'top-end',\n                content:\n                  'В отчете могут быть указаны дела не только проверяемого лица из-за отсутствия данных о дате рождения в источниках. При редком ФИО риск минимален. Чтобы сузить количество дел укажите регион.'\n              }"
                                        }
                                      ]
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-info-circle"
                                      })
                                    ]
                                  )
                                ])
                              ]
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("app-arbitr-report-details", {
                      attrs: {
                        opened: _vm.opened || _vm.isPdf,
                        owner: _vm.report.owner,
                        result: _vm.isPdf
                          ? _vm.slicedResult
                          : _vm.filteredResult
                      }
                    }),
                    _vm.slicedResult.length < _vm.totalCount && _vm.isPdf
                      ? _c(
                          "div",
                          { staticClass: "rc-modern-info-panel mt-3" },
                          [
                            _c(
                              "div",
                              { staticClass: "rc-modern-info-panel__wrapper" },
                              [
                                _c("div", [_c("app-warning-img")], 1),
                                _c("div", [
                                  _vm._v(
                                    "\n          Отображено " +
                                      _vm._s(_vm.slicedResult.length) +
                                      " дел из " +
                                      _vm._s(_vm.totalCount) +
                                      ", полная информация доступна в веб версии отчета.\n        "
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm.task
        ? _c(
            "div",
            { staticClass: "report-item-modern__subtitle-wrapper" },
            [
              _vm.resultStatus === "found"
                ? [
                    _c("div", { staticClass: "report-item-modern__subtitle" }, [
                      _c("div", {
                        staticClass:
                          "report-item-modern__dot report-item-modern__color--danger"
                      }),
                      _c("div", {}, [
                        _vm._v(
                          "\n          В базе арбитражных судов найдено " +
                            _vm._s(_vm.totalCount) +
                            " " +
                            _vm._s(_vm.pluralText) +
                            "\n        "
                        )
                      ])
                    ])
                  ]
                : [
                    _vm.isPhysical
                      ? _c(
                          "div",
                          { staticClass: "report-item-modern__subtitle" },
                          [
                            _c("div", {
                              staticClass: "report-item-modern__dot",
                              class:
                                _vm.result.count_fio > 0
                                  ? "report-item-modern__color--danger"
                                  : "report-item-modern__color--success"
                            }),
                            _c(
                              "div",
                              {},
                              [
                                _vm._v(
                                  "\n          В базе арбитражных судов по " +
                                    _vm._s(_vm.naming) +
                                    "\n          "
                                ),
                                _vm.result.count_fio > 0
                                  ? [
                                      _vm._v(
                                        "\n            найдено " +
                                          _vm._s(_vm.result.count_fio) +
                                          " " +
                                          _vm._s(_vm.pluralText) +
                                          "\n          "
                                      )
                                    ]
                                  : [
                                      _vm._v(
                                        "\n            совпадения не найдены\n          "
                                      )
                                    ]
                              ],
                              2
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.isPhysical
                      ? _c(
                          "div",
                          { staticClass: "report-item-modern__subtitle" },
                          [
                            _c("div", {
                              staticClass: "report-item-modern__dot",
                              class:
                                _vm.result.count_short_fio > 0
                                  ? "report-item-modern__color--danger"
                                  : "report-item-modern__color--success"
                            }),
                            _c(
                              "div",
                              {},
                              [
                                _vm._v(
                                  "\n          В базе арбитражных судов по фамилии и инициалам\n          "
                                ),
                                _vm.result.count_short_fio > 0
                                  ? [
                                      _vm._v(
                                        "\n            найдено " +
                                          _vm._s(_vm.result.count_short_fio) +
                                          " " +
                                          _vm._s(_vm.pluralText) +
                                          "\n          "
                                      )
                                    ]
                                  : [
                                      _vm._v(
                                        "\n            совпадения не найдены\n          "
                                      )
                                    ]
                              ],
                              2
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.inn || !_vm.isPhysical
                      ? _c(
                          "div",
                          { staticClass: "report-item-modern__subtitle" },
                          [
                            _c("div", {
                              staticClass: "report-item-modern__dot",
                              class:
                                _vm.result.count_inn > 0
                                  ? "report-item-modern__color--danger"
                                  : "report-item-modern__color--success"
                            }),
                            _c(
                              "div",
                              {},
                              [
                                _vm._v(
                                  "\n          В базе арбитражных судов по ИНН\n          "
                                ),
                                !_vm.isPhysical
                                  ? _c("span", [
                                      _vm._v(
                                        "и наименованию юридического лица "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.result.count_inn > 0
                                  ? [
                                      _vm._v(
                                        "\n            найдено " +
                                          _vm._s(_vm.result.count_inn) +
                                          " " +
                                          _vm._s(_vm.pluralText) +
                                          "\n          "
                                      )
                                    ]
                                  : [
                                      _vm._v(
                                        "\n            совпадения не найдены\n          "
                                      )
                                    ]
                              ],
                              2
                            )
                          ]
                        )
                      : _vm._e()
                  ]
            ],
            2
          )
        : _vm.orderIsDone
        ? [
            _c("span", { staticClass: "text-warning" }, [
              _vm._v(" Недостаточно данных ")
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }