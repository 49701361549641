

































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { AdvertTypes } from 'shared/store/modules/advert/types';
// import AppAboutHome from '@/components/pages/adverts/AboutHome.vue';
// import AppSalesHistory from '@/components/SalesHistory/SalesHistory.vue';
// import AppSimilarAdverts from '@/components/pages/adverts/SimilarAdverts.vue';
import propertyConstants from 'shared/utils/constants/property';
import { AuthTypes } from 'shared/store/modules/auth/types';
import getValueWithCommas from 'shared/utils/formatNumberValueToValueWithCommas';

@Component({
  name: 'valuation-id',
  popupOptions: {
    name: 'popup_modal',
  },
  components: {
    // AppSalesHistory,
    // AppAboutHome,
    AppAveragePriceChart: () => import('shared/components/Estimate/AveragePriceChart.vue'),
    // AppSimilarAdverts,
  },
  props: {
    rated: {
      type: Boolean,
      default: false,
    },
    isPdf: {
      type: Boolean,
      default: false,
    },
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    isDemo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('advert', [
      'reformaInfo',
      'priceChangeHouse',
      'priceChangeArea',
      'averagePrice',
      'similarList',
      'similarListIds',
      'info',
    ]),
  },
})
export default class EstimateResult extends Vue {
  // data
  buildingTypes: PropertyBuildingType[] = propertyConstants.BUILDING_TYPES;
  objectTypes: PropertyObjectType[] = propertyConstants.OBJECT_TYPES;
  loading: boolean = true;

  // props
  isDemo: boolean;
  priceChangeHouse: AveragePriceData;
  priceChangeArea: AveragePriceData;
  averagePrice: AveragePriceData;
  reformaInfo: ReformaResponse;
  similarList: RealtyAdvertItem[];
  similarListIds: number[];
  inSaleObjectsCount: number = 0;
  info: EstimateModel;
  rated: boolean;
  isPdf: boolean;
  loadingBtn: boolean;

  // computed
  get jwtToken(): string {
    return this.$store.state.query.jwt_token;
  }

  get averagePriceStats() {
    const { highPrice, midPrice, lowPrice } =
      this.$store.getters[AdvertTypes.getters.GET_AVERAGE_PRICE_STATS];
    if (this.isDemo) {
      return {
        highPrice: '6,0',
        midPrice: '5,7',
        lowPrice: '2,3',
      };
    }
    return {
      highPrice: getValueWithCommas(highPrice.toString()),
      midPrice: getValueWithCommas(midPrice.toString()),
      lowPrice: getValueWithCommas(lowPrice.toString()),
    };
  }

  // lifecycle
  async created() {
    if (this.jwtToken) {
      this.$store.commit(AuthTypes.mutations.SET_TOKEN, this.jwtToken);
      this.$store.dispatch(AuthTypes.actions.CHECK_TOKEN);
    }
  }

  async mounted() {
    if (this.info.advertId) {
      await this.$store.dispatch(
        AdvertTypes.actions.GET_AVERAGE_PRICE,
        this.info.advertId
      );
    } else {
      await this.$store.dispatch(
        AdvertTypes.actions.GET_AVERAGE_PRICE_BY_PARAMS,
        {
          type_table: 'sale',
          address: this.info.address ? this.info.address : null,
          area: this.info.area,
          rooms: this.info.rooms,
          id_category: this.info.object_type ? this.info.object_type : null,
        }
      );
    }
    this.loading = false;
  }

  // methods

  async getIDAdvertByURL(url: string) {
    const advertId: number = await this.$api.advert.GetIDAdvertByURL(url);
    return advertId;
  }

  closeDialog() {
    this.$dialog.close();
  }

  getBuildingType(buildingType: number): string {
    return buildingType ? this.buildingTypes[buildingType].name : 'нет данных';
  }

  getObjectType(): string {
    return this.info.object_type
      ? this.objectTypes[this.info.object_type - 1].name
      : 'нет данных';
  }

  getRooms(): string {
    if (this.info.rooms > 0) {
      return `${this.info.rooms}-комнатная`;
    } else if (this.info.rooms === 0) {
      return 'Свободная планировка';
    } else {
      return 'Студия';
    }
  }

  openEditForm() {
    this.$emit('edit');
  }

  rateEstimate(rate: string) {
    this.$emit('rateEstimate', rate);
  }
}
