var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "egrn-object-pdf" },
    [
      _c(
        "div",
        { staticClass: "egrn-object-pdf__ecp text-right" },
        [_c("app-ecp-svg", { attrs: { width: "260px" } })],
        1
      ),
      _vm.historyOwners.rights && _vm.historyOwners.rights.length
        ? _c("div", { staticClass: "statement-table-wrapper" }, [
            _vm.historyOwners.rights && _vm.historyOwners.rights.length
              ? _c("h1", { staticClass: "text-center" }, [
                  _vm._v("\n      Отчет о переходе прав на недвижимость\n    ")
                ])
              : _vm._e(),
            _c("table", { staticClass: "statement-table" }, [
              _c(
                "tbody",
                [
                  _c("app-statement-table-row", {
                    attrs: {
                      title: "Тип объекта:",
                      data: _vm.objectType(
                        _vm.get(
                          _vm.statements,
                          "rightList.data.generic_record.object.common_data.type.code",
                          ""
                        )
                      )
                    }
                  }),
                  _c("app-statement-table-row", {
                    attrs: {
                      title: "Кадастровый номер:",
                      data: _vm.get(
                        _vm.statements,
                        "rightList.data.generic_record.object.common_data.cad_number"
                      )
                    }
                  }),
                  _c("app-statement-table-row", {
                    attrs: {
                      title: "Адрес:",
                      data:
                        _vm.get(
                          _vm.statements,
                          "rightList.data.generic_record.address_location.address.readable_address",
                          ""
                        ) ||
                        _vm.get(
                          _vm.statements,
                          "rightList.data.generic_record.address_room.address.address.readable_address",
                          ""
                        )
                    }
                  }),
                  _c("app-statement-table-row", {
                    attrs: {
                      title: "Дата актуальности сведений:",
                      data: _vm._f("date")(
                        _vm.get(_vm.riskReport, "order.created_at"),
                        "DD.MM.YYYY"
                      )
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _c("div", { staticClass: "statement-table-wrapper" }, [
            _c("h1", { staticClass: "text-center" }, [
              _vm._v(
                "\n      Уведомление об отсутствии в Едином государственном реестре недвижимости запрашиваемых сведений по запросу от\n      " +
                  _vm._s(
                    _vm._f("date")(
                      _vm.get(
                        _vm.statements,
                        "rightList.data.details_request.date_receipt_request_reg_authority_rights"
                      ),
                      "DD.MM.YYYY"
                    )
                  ) +
                  "\n    "
              )
            ]),
            _c("table", { staticClass: "statement-table" }, [
              _c(
                "tbody",
                [
                  _c(
                    "app-statement-table-row",
                    {
                      attrs: {
                        title: "Содержание запроса:",
                        data:
                          "Объект недвижимости с кадастровым номером: " +
                          _vm.get(
                            _vm.statements,
                            "rightList.data.generic_record.object.common_data.cad_number"
                          )
                      }
                    },
                    [
                      _vm.get(
                        _vm.statements,
                        "rightList.data.content_request",
                        ""
                      )
                        ? _c("td", {}, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.get(
                                    _vm.statements,
                                    "rightList.data.content_request"
                                  )
                                ) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c("app-statement-table-row", {
                    attrs: {
                      title: "Причины направления уведомления:",
                      data:
                        "Запрашиваемые сведения отсутствуют (нет данных в Росреестре)"
                    }
                  }),
                  _c("app-statement-table-row", {
                    attrs: { title: "Правопритязания:", data: "Отсутствуют" }
                  }),
                  _c("app-statement-table-row", {
                    attrs: {
                      title:
                        "Заявленные в судебном порядке права требования, аресты (запрещения):",
                      data: "Данные отсутствуют"
                    }
                  })
                ],
                1
              )
            ])
          ]),
      _vm.historyOwners.rights && _vm.historyOwners.rights.length
        ? [
            _c("div", { staticClass: "pagebreak" }),
            _c("h1", { staticClass: "text-center" }, [
              _vm._v("\n      Отчет о переходе прав на недвижимость\n    ")
            ])
          ]
        : _vm._e(),
      _c(
        "div",
        { staticClass: "statement-table-wrapper" },
        _vm._l(_vm.historyOwners.rights, function(right, i) {
          return _c("table", { key: i, staticClass: "statement-table" }, [
            _c(
              "tbody",
              [
                _c(
                  "app-statement-table-row",
                  {
                    attrs: {
                      title:
                        "Правообладатель № " +
                        (i + 1) +
                        " " +
                        (_vm.get(right, "registration.end_date", "")
                          ? "(Предыдущий собственник)"
                          : "(Текущий собственник)") +
                        ":",
                      data: ""
                    }
                  },
                  [
                    _c(
                      "td",
                      {},
                      _vm._l(right.entities, function(entity, entityIndex) {
                        return _c("span", { key: entityIndex + "i" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(entity.content) +
                              "\n              "
                          ),
                          entity.birth_date
                            ? _c("div", [
                                _vm._v(
                                  "\n                дата рождения: " +
                                    _vm._s(
                                      _vm._f("date")(
                                        entity.birth_date,
                                        "DD.MM.YYYY"
                                      )
                                    ) +
                                    "\n              "
                                )
                              ])
                            : _vm._e(),
                          entity.inn
                            ? _c("div", [
                                _vm._v(
                                  "\n                ИНН: " +
                                    _vm._s(entity.inn) +
                                    "\n              "
                                )
                              ])
                            : _vm._e(),
                          entity.ogrn
                            ? _c("div", [
                                _vm._v(
                                  "\n                ОГРН: " +
                                    _vm._s(entity.ogrn) +
                                    "\n              "
                                )
                              ])
                            : _vm._e()
                        ])
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "app-statement-table-row",
                  {
                    attrs: {
                      title: "Вид зарегистрированного права, доля в праве:",
                      data: ""
                    }
                  },
                  [
                    _vm.get(right, "right_data.right_type.value", "")
                      ? _c(
                          "td",
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.get(right, "right_data.right_type.value")
                                ) +
                                "\n            "
                            ),
                            _vm.get(right, "registration.text", "")
                              ? [
                                  _vm._v(
                                    "\n              ,\n              " +
                                      _vm._s(
                                        _vm.get(right, "registration.text", "")
                                      ) +
                                      "\n            "
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                ),
                _c("app-statement-table-row", {
                  attrs: {
                    title: "Номер зарегистрированного права:",
                    data: _vm.get(right, "registration.reg_number", "")
                  }
                }),
                _c("app-statement-table-row", {
                  attrs: {
                    title: "Дата государственной регистрации права:",
                    data: _vm.get(right, "registration.reg_date", "")
                  }
                }),
                _c("app-statement-table-row", {
                  attrs: {
                    title:
                      "Вид, номер и дата государственной регистрации права:",
                    data: _vm.regName(right)
                  }
                }),
                _c(
                  "app-statement-table-row",
                  {
                    attrs: {
                      title:
                        "Основание возникновения/прекращения права (полученные документы):",
                      data: ""
                    }
                  },
                  [
                    _vm.get(right, "docs.length", 0)
                      ? _c(
                          "td",
                          _vm._l(right.docs, function(doc, docIndex) {
                            return _c(
                              "div",
                              { key: doc.Name + docIndex },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(doc.Name) +
                                    "\n              "
                                ),
                                doc.Content
                                  ? [
                                      _vm._v(
                                        "\n                (" +
                                          _vm._s(doc.Content) +
                                          ")\n              "
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ])
        }),
        0
      ),
      _vm.historyOwners.rights && _vm.historyOwners.rights.length
        ? [
            _c("div", { staticClass: "pagebreak" }),
            _c("h1", { staticClass: "text-center" }, [
              _vm._v("\n      Термины и описания\n    ")
            ]),
            _c("div", { staticClass: "statement-table-wrapper" }, [
              _c("div", { staticClass: "egrn-terms" }, [
                _c("div", { staticClass: "row" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "col egrn-terms__elems" },
                    [
                      _vm.terms.length
                        ? _vm._l(_vm.terms, function(term) {
                            return _c("div", { key: term.Header }, [
                              _c("b", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(term.Header) +
                                    "\n                "
                                )
                              ]),
                              _vm._v(
                                "\n                - " +
                                  _vm._s(term.Text) +
                                  "\n              "
                              )
                            ])
                          })
                        : _vm._e(),
                      _vm._m(1)
                    ],
                    2
                  )
                ])
              ])
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-auto" }, [
      _c("i", { staticClass: "fas fa-info-circle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("b", [
        _vm._v("\n                С 1 марта 2023 года\n              ")
      ]),
      _vm._v(
        "\n              - вступил в силу запрет на передачу персональных данных из\n              ЕГРН без согласия правообладателя. Для того чтобы сведения о\n              собственниках в выписке из ЕГРН были доступны, владелец\n              недвижимости должен открыть сведения о своих фамилии, имени,\n              отчестве и дате рождения, подав специальное заявление в Росреестр,\n              в котором разрешит предоставлять их третьим лицам. Для\n              этого можно лично посетить МФЦ или отправить туда документы почтой.\n              Также есть возможность подать заявление в электронной форме в личном кабинете\n              на официальном сайте Росреестра или через портал Госуслуг.\n            "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }