var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sud-details" }, [
    _c(
      "div",
      {
        staticClass: "popup__header",
        on: {
          click: function($event) {
            return _vm.$emit("toggleDetails")
          }
        }
      },
      [
        _c(
          "app-button",
          {
            staticClass: "sud-details__back",
            attrs: { variant: "light", size: "sm" }
          },
          [
            _c("i", { staticClass: "fas fa-xs fa-arrow-left mr-1" }),
            _vm._v("\n      Назад\n    ")
          ]
        )
      ],
      1
    ),
    _c("div", { staticClass: "popup__body" }, [
      _c("div", { staticClass: "sud-details__content" }, [
        _c("div", { staticClass: "sud-details__title" }, [
          _vm._v("\n        Детали дела\n      ")
        ]),
        _vm.details.number
          ? _c("div", { staticClass: "sud-details-item" }, [
              _c("div", { staticClass: "sud-details-item__title" }, [
                _vm._v("\n          Номер дела\n        ")
              ]),
              _c("div", { staticClass: "sud-details-item__value" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.details.number) + "\n        "
                )
              ])
            ])
          : _vm._e(),
        _vm.details.justice_region
          ? _c("div", { staticClass: "sud-details-item" }, [
              _c("div", { staticClass: "sud-details-item__title" }, [
                _vm._v("\n          Регион\n        ")
              ]),
              _c("div", { staticClass: "sud-details-item__value" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.details.justice_region) +
                    "\n        "
                )
              ])
            ])
          : _vm._e(),
        _vm.details.justice_type_case
          ? _c("div", { staticClass: "sud-details-item" }, [
              _c("div", { staticClass: "sud-details-item__title" }, [
                _vm._v("\n          Тип\n        ")
              ]),
              _c("div", { staticClass: "sud-details-item__value" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.details.justice_type_case) +
                    "\n        "
                )
              ])
            ])
          : _vm._e(),
        _c("div", { staticClass: "sud-details-item" }, [
          _c("div", { staticClass: "sud-details-item__title" }, [
            _vm._v("\n          Дата\n        ")
          ]),
          _c("div", { staticClass: "sud-details-item__value" }, [
            _c("span", [
              _vm._v(
                "\n            " + _vm._s(_vm.details.date) + "\n          "
              )
            ])
          ])
        ]),
        _vm.details.description
          ? _c("div", { staticClass: "sud-details-item" }, [
              _c("div", { staticClass: "sud-details-item__title" }, [
                _vm._v("\n          Описание\n        ")
              ]),
              _c("div", { staticClass: "sud-details-item__value" }, [
                _c("span", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.details.description) +
                      "\n          "
                  )
                ])
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }