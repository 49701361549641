var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container container_responsive" }, [
    _c(
      "div",
      { staticClass: "page-wrapper page-wrapper_border" },
      [
        _c(
          "div",
          {
            staticClass:
              "admin-page-head d-flex justify-content-between align-items-center"
          },
          [
            _c("h5", [_vm._v("\n        Активировали тариф\n      ")]),
            _c(
              "app-button",
              {
                attrs: { variant: "primary", size: "sm" },
                on: { mousedown: _vm.openFilter }
              },
              [
                _vm._v("\n        Фильтр "),
                _c("i", { staticClass: "fas fa-filter fa-xs" })
              ]
            )
          ],
          1
        ),
        _c("app-data-table", {
          staticClass: "stats-table stats-table_default",
          attrs: {
            items: _vm.items,
            headers: _vm.headers,
            loading: _vm.loading,
            pagination: _vm.pagination
          },
          on: { "page-changed": _vm.pageChanged },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "admin-user-orders",
                              params: { userID: row.person_id }
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(row.email) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("td", [_vm._v(_vm._s(row.tariff_name))]),
                  _c("td", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("date")(row.from_date, "DD.MM.YYYY HH:mm:ss")
                        ) +
                        "\n        "
                    )
                  ]),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("date")(row.thru_date, "DD.MM.YYYY HH:mm:ss")
                      )
                    )
                  ]),
                  _c("td", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("date")(
                            row.last_balance_replenishment_date,
                            "DD.MM.YYYY HH:mm:ss"
                          )
                        ) +
                        "\n        "
                    )
                  ]),
                  _c("td", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("currency")(
                            row.last_balance_replenishment_amount
                          )
                        ) +
                        "\n        "
                    )
                  ]),
                  _c("td", [
                    _vm._v("\n          " + _vm._s(row.balance) + "\n        ")
                  ])
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }