var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "popup__header" },
      [
        _c("div", { staticClass: "popup__title" }, [
          _vm._v("\n      Создать прайс компонент\n    ")
        ]),
        _c("app-dialog-close")
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "popup__body" },
      [
        _c(
          "app-form",
          {
            attrs: {
              model: _vm.model,
              rules: _vm.validationRules,
              "request-handler": _vm.createPriceComponent
            },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function(ref) {
                  var loading = ref.loading
                  return [
                    _c(
                      "app-button",
                      {
                        attrs: {
                          loading: loading,
                          type: "submit",
                          size: "sm",
                          block: ""
                        }
                      },
                      [_vm._v("\n          Создать\n        ")]
                    )
                  ]
                }
              }
            ])
          },
          [
            _c(
              "app-form-group",
              { attrs: { label: "Название", "model-name": "name" } },
              [
                _c("app-input", {
                  attrs: { placeholder: "Название", size: "sm" },
                  model: {
                    value: _vm.model.name,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "name", $$v)
                    },
                    expression: "model.name"
                  }
                })
              ],
              1
            ),
            _c(
              "app-form-group",
              { attrs: { label: "Описание", "model-name": "description" } },
              [
                _c("app-input", {
                  attrs: { placeholder: "Описание", size: "sm" },
                  model: {
                    value: _vm.model.description,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "description", $$v)
                    },
                    expression: "model.description"
                  }
                })
              ],
              1
            ),
            _c(
              "app-form-group",
              { attrs: { label: "Комментарий", "model-name": "comment" } },
              [
                _c("app-input", {
                  attrs: { placeholder: "Комментарий", size: "sm" },
                  model: {
                    value: _vm.model.comment,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "comment", $$v)
                    },
                    expression: "model.comment"
                  }
                })
              ],
              1
            ),
            _c(
              "app-form-group",
              { attrs: { label: "Продукт", "model-name": "product_id" } },
              [
                _c("app-select", {
                  attrs: { items: _vm.products, size: "sm" },
                  model: {
                    value: _vm.model.product_id,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "product_id", $$v)
                    },
                    expression: "model.product_id"
                  }
                })
              ],
              1
            ),
            _c(
              "app-form-group",
              {
                attrs: {
                  label: "Тип прайс компонента",
                  "model-name": "price_component_type_id"
                }
              },
              [
                _c("app-select", {
                  attrs: { items: _vm.priceComponentTypes, size: "sm" },
                  model: {
                    value: _vm.model.price_component_type_id,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "price_component_type_id", $$v)
                    },
                    expression: "model.price_component_type_id"
                  }
                })
              ],
              1
            ),
            _c(
              "app-form-group",
              { attrs: { label: "Цена", "model-name": "price" } },
              [
                _c("app-input", {
                  attrs: { type: "number", placeholder: "Цена", size: "sm" },
                  model: {
                    value: _vm.model.price,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "price", $$v)
                    },
                    expression: "model.price"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c(
                  "app-form-group",
                  {
                    staticClass: "col",
                    attrs: { label: "Дата старта", "model-name": "from_date" }
                  },
                  [
                    _c("app-date-picker", {
                      attrs: {
                        placeholder: "Дата старта",
                        size: "sm",
                        options: _vm.pickerOptions
                      },
                      model: {
                        value: _vm.model.from_date,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "from_date", $$v)
                        },
                        expression: "model.from_date"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "app-form-group",
                  {
                    staticClass: "col",
                    attrs: {
                      label: "Дата окончания",
                      "model-name": "thru_date"
                    }
                  },
                  [
                    _c("app-date-picker", {
                      attrs: {
                        placeholder: "Дата окончания",
                        size: "sm",
                        options: _vm.pickerOptions
                      },
                      model: {
                        value: _vm.model.thru_date,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "thru_date", $$v)
                        },
                        expression: "model.thru_date"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "app-form-group",
              { attrs: { "model-name": "enabled" } },
              [
                _c(
                  "app-checkbox",
                  {
                    model: {
                      value: _vm.model.enabled,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "enabled", $$v)
                      },
                      expression: "model.enabled"
                    }
                  },
                  [_vm._v("\n          Активировать\n        ")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }