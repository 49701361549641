



























import Vue from 'vue';
import Component from 'vue-class-component';
import AppForeignAgentDetails from 'shared/components/RiskNew/ForeignAgentDetails.vue';

@Component({
  name: 'AppForeignAgentReport',
  components: {
  },
  props: {
    result: Array,
  },
})
export default class AppForeignAgentReport extends Vue {
  // props
  result: any[];

  showDetails() {
    this.$dialog.open({
      component: AppForeignAgentDetails,

      props: {
        result: this.result[0],
      },
    });
  }
}
