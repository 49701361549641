



































import Vue from 'vue';
import noop from 'lodash/noop';
import Component from 'vue-class-component';

import AppSurveyForm from 'shared/components/RiskNew/SurveyDialog/SurveyForm.vue';

@Component({
  components: { AppSurveyForm },
  props: {
    onSave: {
      type: Function,
      default: noop,
    },
    surveys: Array,
    orderItemId: String,
  },

  popupOptions: {
    disableBackdrop: true,
  },
})
export default class AppSurveyDialog extends Vue {
  // props
  onSave: Function;
  surveys: SurveyElem[];
  orderItemId: string;
}
