

































































import Vue from 'vue';

import AppArbitrDetailsDialog from 'shared/components/RiskNew/ArbitrDetails.vue';
import { getRegionNameById } from 'shared/utils/regions';
import pluralize from 'shared/utils/pluralize';

export default Vue.extend({

  props: {
    result: Object,
    region: String,
    isPhysical: Boolean,
    inn: String,
    owner: Object,
  },

  computed: {
    regionName(): string {
      return getRegionNameById(this.region);
    },

    pluralText(): string {
      return pluralize(
        this.result.totalCount,
        'совпадение',
        'совпадения',
        'совпадений',
      );
    },

    naming(): string {
      if (this.isPhysical) {
        return 'фамилии имени отчеству';
      } else {
        return 'наименованию юридического лица';
      }
    },
  },

  methods: {
    showDetails(result: any) {
      this.$dialog.open({
        component: AppArbitrDetailsDialog,

        props: {
          result: result,
          region: this.region,
          owner: this.owner,
        },
      });
    },
  },
});
