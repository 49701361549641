
































































































import Component, { mixins } from 'vue-class-component';
import AppDatePick from 'vue-date-pick';
import FilterWrapper from './FilterWrapper.vue';
import HelpersMixin from '@/core/mixins/helpers.mixin';

interface CouponFilter {
  activeFrom: string;
  activeTo: string;
  campaignID: [];
  email: string;
  phone: string;
  countFrom: string;
  countTo: string;
  [key: string]: any;
}

@Component({
  popupOptions: {
    name: 'popup_side popup_transparent-backdrop',
  },

  components: { FilterWrapper, AppDatePick },

  props: {
    campaigns: Array,
    defaultFilter: Function,
    filter: Object,
    submit: Function,
  },
})
export default class AdminUsersFilter extends mixins(HelpersMixin) {
  defaultFilter!: () => any;
  submit: () => void;
  campaigns: [];
  filter: CouponFilter;

  // methods
  clear() {
    const defaults = this.defaultFilter();

    for (const key in this.filter) {
      this.filter[key] = defaults[key];
    }
  }

  isDateBeforeActiveFrom(date: any) {
    return date < new Date(this.filter.activeFrom);
  }
}
