import Vue from 'vue';
import Component from 'vue-class-component';
import AppDatePick from 'vue-date-pick';
import format from 'date-fns/format';
import addDays from 'date-fns/add_days';
import randomColor from 'randomcolor';

import AppStatsChart from '../components/StatsChart.vue';
import AppPartnerSumStats from './PartnerSumStats.vue';
import AppLineGraphic from './components/LineGraphic.vue';
import AppStatsDefaultTable from './components/StatsDefaultTable.vue';
import AppDateFilterDialog from '@/views/Admin/components/DateFilterDialog.vue';
import AppClientsWithTariffesTable from './components/ClientsWithTariffesTable.vue';
import { RoleNames } from 'shared/utils/constants';
// import { Watch } from 'vue-property-decorator';

const chartDataMap: SimpleObject<any> = {
  done: {
    title: 'done (Готовые)',
    color: '#13ce66',
  },
  pending: {
    title: 'pending (Прошли проверку, ожидают оплаты)',
    color: '#409eff',
  },
  paid: {
    title: 'paid (Оплачено)',
    color: '#343a40',
  },
  requested: {
    title: 'requested (Запросивших и ожидающих выписку)',
    color: '#6c757d',
  },
  waiting_for_payment: {
    title: 'wait_for_payment (Ожидает оплаты)',
    color: '#ff4949',
  },
  manual: {
    title: 'manual (Ручные)',
    color: '#ffc107',
  },
  Оплачен: {
    color: '#7364c9',
  },
  'Готов к оплате': {
    color: '#f78a37',
  },
  'Объект аннулирован': {
    color: '#6610f2',
  },
  'В работе': {
    color: '#409eff',
  },
  Отмена: {
    color: '#e83e8c',
  },
};

@Component({
  components: {
    AppStatsChart,
    AppDatePick,
    AppPartnerSumStats,
    AppLineGraphic,
    AppStatsDefaultTable,
    AppClientsWithTariffesTable,
  },
})
export default class AdminStats extends Vue {
  // props

  // props end

  // data()
  tab: string = 'table';
  defaultHeaders: TableHeaderItem[] = [
    { text: 'Кол-во' },
    { text: 'Сумма' },
    { text: '%', align: 'right' },
  ];
  showAllDonators: boolean = false;
  showAllDonatorsResponsible: boolean = false;
  stats: StatsData = {};
  loading: boolean = true;
  sumGroupItems: any[] = [];
  apiGroupItems: any[] = [];
  serverGroupItems: any[] = [];
  ordersGroupItems: any[] = [];
  usersGroupItems: any[] = [];
  donators: StatsDonator[] = [];
  donatorsResponsible: StatsDonator[] = [];
  roles: SimpleObject<string> = RoleNames;
  date = {
    from: format(Date.now(), 'YYYY-MM-DD'),
    to: format(addDays(Date.now(), 1), 'YYYY-MM-DD'),
  };
  // data() end

  /* @Watch('date', { deep: true })
  dateHandler(a:any, b:any) {
    console.log(a,b);
    this.loadStats();
  } */

  // computed
  get donatorsLimit(): any[] {
    if (this.showAllDonators) {
      return this.donators;
    }

    return this.donators.slice(0, 10);
  }

  // lifecycle hooks
  created() {
    this.loadStats();
  }

  // methods
  async loadStats() {
    this.loading = true;

    this.$api.admin
      .TopDonators(this.date)
      .then((data: any) => {
        this.donators = data;
      });

    this.$api.admin
      .TopDonatorsResponsible(this.date)
      .then((data: any) => {
        this.donatorsResponsible = data;
      });

    const stats = await this.$api.admin.Stats(this.date);

    this.stats = stats;
    this.loading = false;
    this.splitToGroups(stats);
  }

  splitToGroups(stats: any) {
    const sumGroup = [
      'total_paid_amount|Общая сумма оплаченных заказов',
      'income_clear_amount|Прибыль',
      'total_write_off|Оплата через кассу',
      'total_write_off_balance|Оплата через баланс',
      'total_amount|Общая сумма заказов',
      'total_discount|Сумма скидки',
      'total_unpaid_amount|Сумма не оплаченных заказов',
      'total_undefined_balance|[Баланс] неучтенный баланс',
      'total_write_off_balance_and_balance_replenishment|Списание и пополнение',
      'total_balance_replenishment|[Баланс] Общая сумма пополнения',
    ];

    const apiGroup = [
      'kadnet_order_average_time|Среднее время получения выписки в минутах из каднета',
      'rosreestr_can_search|Включен ли поиск по росреестру',
      'rosreestr_search_count|Кол-во поисков по росреестру',
      'rosreestr_search_limit|Лимит поиска по росреестру',
      'rosreestr_search_banned_before|Блокировка поиска по росреестру|true',
    ];

    const serverGroup = [
      'version|Версия приложения',
      'build|Дата сборки',
      'uptime|Время работы сервиса в минутах',
      'start_time|Время запуска|true',
      'server_time|Серверное время|true',
    ];

    const ordersGroup = [
      'orders_created_total|Создано заказов',
      'orders_paid|Оплаченные заказы',
      'product_egrn|Вписки ЕГРН',
      'product_egrn_right|Переход прав',
      'product_risk|Отчет о рисках',
      'product_risk_simple|ЕГРН + Переход прав',
      'last_created_order|Дата создания нового заказа|true',
      'last_completed_order|Дата завершения последнего заказа|true',
    ];

    const usersGroup = [
      'users_registered|Пользователей зарегистрировано',
      'users_paid|Пользователей совершивших оплату',
      'users_unpaid|Пользователей незаплативших',
      'last_registred_email|Email последнего зарегистрировавшегося пользователя',
      'last_registred_date|Дата последней регистрации|true',
    ];

    const pushToGroupArray = (arrayName: string) => {
      const items: any[] = [];
      return (item: string, index: number, array: any[]) => {
        const [ key, title, isDate ] = item.split('|');
        let value = stats[key];

        if (isDate && value) {
          value = format(value, 'DD.MM.YYYY HH:mm:ss');
        }

        if (Array.isArray(value)) {
          value.forEach((item) => {
            items.push({ title: `${title} ${item.product_name}`, key, value: `${item.avg} ч.` });
          });
        } else {
          items.push({ title, key, value });
        }

        if (index === array.length - 1) {
          (this as any)[arrayName] = items;
        }
      };
    };

    sumGroup.forEach(pushToGroupArray('sumGroupItems'));
    apiGroup.forEach(pushToGroupArray('apiGroupItems'));
    serverGroup.forEach(pushToGroupArray('serverGroupItems'));
    ordersGroup.forEach(pushToGroupArray('ordersGroupItems'));
    usersGroup.forEach(pushToGroupArray('usersGroupItems'));
  }

  ordersMapLabels(item: StatsItem) {
    const preset = chartDataMap[item.Name];

    if (preset && preset.title) {
      return preset.title;
    }

    return item.Name;
  }

  ordersMapColors(item: StatsItem) {
    const preset = chartDataMap[item.Name];

    if (preset && preset.color) {
      return preset.color;
    }

    return randomColor();
  }

  rolesMapLabel(item: StatsItem) {
    return RoleNames[item.Name];
  }

  showDateFilter() {
    this.$dialog.open({
      component: AppDateFilterDialog,
      props: {
        date: this.date,
        onClose: this.loadStats,
      },
    });
  }
}
