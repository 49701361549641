var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-dialog-close", { staticClass: "d-print-none popup__close--lg" }),
      _vm.onlyUser
        ? _c(
            "div",
            { staticClass: "fizcheck-pdf-report" },
            [
              _c("app-fizcheck-report-wrapper", {
                attrs: {
                  report: _vm.report,
                  "fetch-report": function() {
                    return null
                  },
                  "show-extended-report-steps": false,
                  "extended-report-enabled": true,
                  "extended-report-returned": false,
                  "return-extended-report": function() {
                    return null
                  },
                  demo: false,
                  download: function() {
                    return null
                  },
                  "allow-pdf-download": false,
                  "is-pdf": true
                }
              })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "r-report-pdf container" },
            [
              _c("app-risk-pdf", {
                attrs: {
                  info: _vm.objectInfo,
                  "fetch-risk": _vm.fetchRisk,
                  "object-key": _vm.objectKey,
                  "risk-report": _vm.report,
                  statements: _vm.statements,
                  "only-owners": _vm.onlyUser,
                  "risk-insurance": _vm.riskInsurance,
                  "land-cad-info": _vm.landCadInfo
                }
              })
            ],
            1
          ),
      _c(
        "app-button",
        {
          staticClass: "r-report-pdf__print d-print-none",
          attrs: { size: "sm" },
          on: { click: _vm.print }
        },
        [_vm._v("\n    Печать\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }