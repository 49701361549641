


























































































































































import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import Component, { mixins } from 'vue-class-component';

import AppSurveyMixin from 'shared/mixins/surveys.mixin';

@Component({
  components: { },
  props: {
    survey: Object,
    orderItemId: String,
  },

  popupOptions: {
    disableBackdrop: true,
  },
})
export default class AppSurveyForm extends mixins(AppSurveyMixin) {
  // props
  survey: SurveyElem;
  orderItemId: string;

  // data
  model: any = null;
  multiFormExceptions: string[] = [ 'Супруг (в браке)', 'Супруг (разведен)' ];

  created() {
    const modelNew: any = {};
    const survey = cloneDeep(this.survey);
    console.log(survey);
    survey.survey_type.questions.forEach((elem: SurveyQuestion) => {
      switch (elem.question_type) {
        case 'select':
          // modelNew[elem.question_number] = elem.values[0].value;
          modelNew[elem.question_number] = '';
          break;
        case 'form':
          modelNew[elem.question_number] = [];
          break;
        case 'check':
          modelNew[elem.question_number] = false;
          break;
      }
    });
    if (survey.status === 'done' && survey.result.length) {
      survey.result.forEach((elem: SurveyResultItem) => {
        modelNew[elem.number] = elem.answer;
      });
    }

    this.model = modelNew;
  }

  async onSubmit() {
    try {
      const result: SurveyResultItem[] = [];
      const model = cloneDeep(this.model);
      forEach(model, (val, key) => {
        // форматируем дату
        if (isArray(val)) {
          forEach(val, (object, objectKey) => {
            forEach(object, (value, name) => {
              if (this.isDateField(name)) {
                model[key][objectKey][name] = new Date(value).toISOString();
              }
            });
          });
        }
        if (val !== '') {
          result.push({
            number: parseInt(key),
            answer: val,
          });
        }
      });
      const body = {
        survey_id: this.survey.survey_id,
        order_item_id: this.orderItemId,
        result,
      };

      await this.$api.risk.UpdateSurvey(body);

      this.$emit('on-save');
      this.$noty.success({ text: 'Сохранено' });
      this.$dialog.close();
    } catch (error) {
      this.$noty.error({ text: 'Произошла ошибка' });
      console.error(error);
    }
    return Promise.resolve();
  }

  isNextQuestion(id: number) {
    // проверка на то, является ли вопрос дочерним, зависящим от ответа на родительский вопрос
    let res = true;
    forEach(this.survey.survey_type.questions, (question: SurveyQuestion) => {
      if (question.next_questions) {
        const finded = find(question.next_questions, (nextQuestion: SurveyNextQuestionsElem) => {
          // return (nextQuestion.number === id) && (this.model[question.question_number] === nextQuestion.condition);
          return nextQuestion.number === id;
        });
        // if (!finded) {
        //   console.log(id, 'не найден');
        //   res = false;
        // }
        if (finded) {
          const answered = find(question.next_questions, (nextQuestion: SurveyNextQuestionsElem) => {
            return (nextQuestion.number === finded.number) && (this.model[question.question_number] === nextQuestion.condition);
          });
          if (!answered) {
            res = false;
          }
          // if (this.model[question.question_number] !== finded.condition) {
          //   res = false;
          // }
        }
      }
    });
    return res;
  }

  addQuestionElement(question: SurveyQuestion) {
    const result: any = {};
    const arr = sortBy(question.form_fields, 'sort_number');
    forEach(arr, (val) => {
      result[val.name] = '';
    });
    this.model[question.question_number].push(result);
  }

  removeQuestionElement(object: any, id: number) {
    const newArr = this.model[id].filter((elem: any) => elem !== object);
    this.model[id] = newArr;
  }

  sortFields(arr: SurveyFormField[] | SurveySelectValue[]) {
    return sortBy(arr, 'sort_number');
  }

  isNotExceptMulti(text: string) {
    return !this.multiFormExceptions.includes(text);
  }

  clearUnusedInputs() {
    forEach(this.survey.survey_type.questions, (question: SurveyQuestion) => {
      if (question.next_questions) {
        forEach(question.next_questions, (nextQuestion: SurveyNextQuestionsElem) => {
          if (!this.isNextQuestion(nextQuestion.number)) {
            if (isArray(this.model[nextQuestion.number])) {
              this.model[nextQuestion.number] = [];
            } else {
              this.model[nextQuestion.number] = '';
            }
          }
          // if (this.model[question.question_number] !== nextQuestion.condition) {
          //   // если вопрос не нужно отображать то присваеваем ему пустое значение
          //   if (isArray(this.model[nextQuestion.number])) {
          //     this.model[nextQuestion.number] = [];
          //   } else {
          //     this.model[nextQuestion.number] = '';
          //   }
          // }
        });
      }
    });
  }
}
