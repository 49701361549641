var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    [
      _c("td", {}, [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]),
      _vm._t("default", [
        _vm.data
          ? _c("td", { domProps: { innerHTML: _vm._s(_vm.data) } })
          : _c("td", {}, [_vm._v("\n      Данные не обнаружены\n    ")])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }