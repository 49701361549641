var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("app-report-item", {
    attrs: {
      title: "Реквизиты",
      icon: "money-check",
      opened: true,
      disabled: "",
      status: "done",
      color: "success"
    },
    scopedSlots: _vm._u([
      {
        key: "extra-content",
        fn: function() {
          return [
            _c("div", [
              _c("div", { staticClass: "criminal-report__divider" }),
              _c("div", { staticClass: "d-flex flex-column gap-24" }, [
                _c(
                  "div",
                  { staticClass: "criminal-report__items license-report" },
                  [
                    _c("div", { staticClass: "criminal-report__item" }, [
                      _c(
                        "div",
                        { staticClass: "criminal-report__item-header" },
                        [
                          _vm._v(
                            "\n              Налоговый орган\n            "
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "divider-200" }),
                    _c("div", { staticClass: "criminal-report__item" }, [
                      _c("div", { staticClass: "criminal-report__title" }, [
                        _vm._v(
                          "\n              Наименование налогового органа\n            "
                        )
                      ]),
                      _vm.report["НаимНО"]
                        ? _c(
                            "div",
                            { staticClass: "criminal-report__result" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.report["НаимНО"]) +
                                  "\n            "
                              )
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "criminal-report__result" },
                            [_vm._v("\n              -\n            ")]
                          )
                    ]),
                    _c("div", { staticClass: "criminal-report__item" }, [
                      _c("div", { staticClass: "criminal-report__title" }, [
                        _vm._v(
                          "\n              Дата постановки на учет\n            "
                        )
                      ]),
                      _vm.report["ДатаПостУч"]
                        ? _c(
                            "div",
                            { staticClass: "criminal-report__result" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.report["ДатаПостУч"]) +
                                  "\n            "
                              )
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "criminal-report__result" },
                            [_vm._v("\n              -\n            ")]
                          )
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "criminal-report__items license-report" },
                  [
                    _c("div", { staticClass: "criminal-report__item" }, [
                      _c(
                        "div",
                        { staticClass: "criminal-report__item-header" },
                        [
                          _vm._v(
                            "\n              Сведения о регистрации в ПФР\n            "
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "divider-200" }),
                    _c("div", { staticClass: "criminal-report__item" }, [
                      _c("div", { staticClass: "criminal-report__title" }, [
                        _vm._v(
                          "\n              Регистрационный номер\n            "
                        )
                      ]),
                      _vm.report["РегНомПФ"]
                        ? _c(
                            "div",
                            { staticClass: "criminal-report__result" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.report["РегНомПФ"]) +
                                  "\n            "
                              )
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "criminal-report__result" },
                            [_vm._v("\n              -\n            ")]
                          )
                    ]),
                    _c("div", { staticClass: "criminal-report__item" }, [
                      _c("div", { staticClass: "criminal-report__title" }, [
                        _vm._v("\n              Дата регистрации\n            ")
                      ]),
                      _vm.report["ДатаРегПФ"]
                        ? _c(
                            "div",
                            { staticClass: "criminal-report__result" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.report["ДатаРегПФ"]) +
                                  "\n            "
                              )
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "criminal-report__result" },
                            [_vm._v("\n              -\n            ")]
                          )
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "criminal-report__items license-report" },
                  [
                    _c("div", { staticClass: "criminal-report__item" }, [
                      _c(
                        "div",
                        { staticClass: "criminal-report__item-header" },
                        [
                          _vm._v(
                            "\n              Сведения о регистрации в ФСС\n            "
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "divider-200" }),
                    _c("div", { staticClass: "criminal-report__item" }, [
                      _c("div", { staticClass: "criminal-report__title" }, [
                        _vm._v(
                          "\n              Регистрационный номер\n            "
                        )
                      ]),
                      _vm.report["РегНомФСС"]
                        ? _c(
                            "div",
                            { staticClass: "criminal-report__result" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.report["РегНомФСС"]) +
                                  "\n            "
                              )
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "criminal-report__result" },
                            [_vm._v("\n              -\n            ")]
                          )
                    ]),
                    _c("div", { staticClass: "criminal-report__item" }, [
                      _c("div", { staticClass: "criminal-report__title" }, [
                        _vm._v("\n              Дата регистрации\n            ")
                      ]),
                      _vm.report["ДатаРегФСС"]
                        ? _c(
                            "div",
                            { staticClass: "criminal-report__result" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.report["ДатаРегФСС"]) +
                                  "\n            "
                              )
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "criminal-report__result" },
                            [_vm._v("\n              -\n            ")]
                          )
                    ])
                  ]
                )
              ])
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }