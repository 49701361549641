import Vue from 'vue';
import Component from 'vue-class-component';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import toLower from 'lodash/toLower';
import trim from 'lodash/trim';

import format from 'date-fns/format';

@Component({
  props: {
    statements: Object,
  },
})
export default class StatementsMixin extends Vue {
  statements!: StatementItems;

  // methods
  /** Иногда StatementEntytiy.content бывает пустым, поэтому заполняем его из доступных данных */
  _checkEmptyContentInEntity(personEntity: StatementEntity): StatementEntity {
    // Добавляем тогда, когда content пустой, в других случаях используем то, что приходит из выписок
    if (!personEntity.content) {
      personEntity.content = this._getFullNameString(personEntity);
    }

    return personEntity;
  }

  _mapRegistrationInRightEntity(right: StatementRight) {
    const dateRegexFormat = /\d{2}\.\d{2}\.\d{4}/;
    if (right && right.entities) {
      right.entities = right.entities.map((entity: StatementEntity) => {
        if (!entity.registration) {
          entity.registration = right.registration;
        }
        if (!entity.registration.reg_date) {
          entity.registration.reg_date = right.registration.reg_date;
        }
        if (!entity.registration.docs) {
          entity.registration.docs = right.docs;
        }
        if (entity.registration.reg_date && !dateRegexFormat.test(entity.registration.reg_date)) {
          entity.registration.reg_date = format(entity.registration.reg_date, 'DD.MM.YYYY');
        }
        const reg = {
          docs: entity.registration.docs,
          end_date: entity.registration.end_date,
          name: entity.registration.name || right.registration.name,
          record_id: entity.registration.record_id || right.registration.record_id,
          reg_date: entity.registration.reg_date || right.registration.reg_date,
          reg_number: entity.registration.reg_number || right.registration.reg_number,
          text: entity.registration.text || right.registration.text,
          type: entity.registration.type,
        };
        if (reg.type && !reg.type.type_name) {
          reg.type = right.registration.type;
        }
        entity.registration = reg;
        return entity;
      });
    }

    return right;
  }

  getOwnerFromRightHistory(regNumber: string) {
    const rightList = cloneDeep(this.statements.rightList);
    const rights = get(rightList, 'data.extract_object.rights', []);
    return find(rights, (elem: any) => {
      return elem.registration.reg_number === regNumber;
    });
  }

  _getAllEntities(rights: StatementRight[]): StatementEntity[] {
    let entities: StatementEntity[] = flatten(
      rights
        .map(this._mapRegistrationInRightEntity)
        .map(right => {
          if (right && right.entities) {
            return right.entities.filter(e => e != null);
          } else if (rights.length === 1 && right && right.no_owner) {
            return [ {
              code_sp: '',
              subject_type: '',
              inn: '',
              surname: '',
              first: '',
              patronymic: '',
              name: '',
              content: 'Данные о правообладателе отсутствуют',
              registration: right.registration ? right.registration : null,
            } ];
          }

          return [];
        })
    );

    entities = entities.map(this._checkEmptyContentInEntity);

    entities = entities.map((elem: any) => {
      elem.rightList = this.getOwnerFromRightHistory(elem.registration.reg_number);
      return elem;
    });

    return entities;
  }

  _mapEntityNames(entities: StatementEntity[]) {
    return entities.map(entity => toLower(this._getFullNameString(entity)));
  }

  /** Хэлпер для получение ФИО в виде строки */
  _getFullNameString(item: OwnerFullName): string {
    let fullName = '';

    // Фамилия
    if (item.surname) {
      fullName += ` ${trim(item.surname)}`;
    }

    // У нас есть два разных ключа для получения имени
    // Приоритет даём .first потом уже .name
    if (item.first || item.name) {
      fullName += ` ${trim(item.first || item.name)}`;
    }

    // Отчество
    if (item.patronymic) {
      fullName += ` ${trim(item.patronymic)}`;
    }

    return trim(fullName);
  }

  // Сравниваем по полному имени
  _isFullNameEquals(first: OwnerFullName, second: OwnerFullName) {
    return toLower(this._getFullNameString(first)) === toLower(this._getFullNameString(second));
  }
}
