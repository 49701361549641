





































































































































































import Component, { mixins } from 'vue-class-component';

import AppStatementItem from './StatementItem.vue';
import StatementRightListMixin from 'shared/mixins/statements/statementRightList.mixin';

@Component({
  components: { AppStatementItem },

  props: {
    showPayInfo: Boolean,
    opened: {
      type: Boolean,
      default: false,
    },
  },
})
export default class Statementhistory extends mixins(StatementRightListMixin) {
  // props
  showPayInfo!: boolean;
  opened: boolean;

  fizTip: string = 'С 1 марта 2023 года вступил в силу запрет на передачу персональных данных из ЕГРН без согласия правообладателя. Поэтому ФИО собственников скрыты Росреестром. Для того чтобы сведения о собственниках в выписке из ЕГРН были доступны, владелец недвижимости должен открыть сведения о своих ФИО и дате рождения, подав специальное заявление в Росреестр, в котором разрешит предоставлять их третьим лицам.'

  // methods
}
