import Component, { mixins } from 'vue-class-component';
import { mapState } from 'vuex';
import DataTableMixin from '@/core/mixins/data-table.mixin';
import AppBillDialog from 'shared/components/Payers/BillDialog.vue';

@Component({
  computed: {
    ...mapState('admin', [ 'user' ]),
  },
})
export default class AdminUserBills extends mixins(DataTableMixin) {
  // types
  user: User;
  pagination: TablePagination = {
    page: 1,
    limit: 20,
    total: 40,
  };

  headers: TableHeaderItem[] = [
    {
      text: 'Дата создания',
      sortProp: 'Created',
      width: '170px',
      align: 'left',
    },
    {
      text: '№ Счета',
      align: 'left',
    },
    {
      text: 'ИНН',
      align: 'left',
    },
    {
      text: 'Емайл',
      align: 'left',
    },
    {
      text: 'Сумма',
      align: 'left',
    },
  ];

  openBillForm() {
    this.$dialog.open({
      component: AppBillDialog,
      props: {
        userID: this.user.id,
        onSuccess: this.fetchData,
      },
    });
  }

  async handleRequest() {
    const data = await this.$api.admin.GetBills({ email: this.user.email });

    return Array.isArray(data) ? data : [];
  }
}
