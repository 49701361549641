





































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import AppWarningImg from 'shared/components/Static/WarningImg.vue';
import AppArbitrReportDetails from 'shared/components/FizCheck/Details/ArbitrReportDetails.vue';
import { getRegionNameById } from 'shared/utils/regions';
import pluralize from 'shared/utils/pluralize';
import concat from 'lodash/concat';
import uniqBy from 'lodash/uniqBy';

@Component({
  components: { AppReportItem, AppArbitrReportDetails, AppWarningImg },
  props: {
    report: Object,
    status: String,
    images: Array,
    orderIsDone: Boolean,
    missingRequirements: Array,
    openAddFieldDialog: Function,
    updateMap: Function,
    task: Object,
    isPhysical: Boolean,
    onlyOwners: Boolean,
    map: Object,
    isPdf: Boolean,
    expand: Boolean,
  },
})
export default class AppArbitrReportModern extends Vue {
  // props
  missingRequirements: any[];
  report: any;
  status: string;
  images: any[];
  orderIsDone: boolean;
  task: any;
  isPhysical: boolean;
  onlyOwners: boolean;
  openAddFieldDialog: (val: string) => any;
  updateMap: (taskName: string, field: string, value: any) => void;
  map: any;
  currentFilter: string = 'all';
  opened: boolean = false;
  isPdf: boolean;
  resultStatusTrace: any = null;
  expand: boolean;

  created() {
    this.resultStatusTrace = this.resultStatus;
  }

  get inn() {
    const innTask = this.getTask('owner_check_individual_inn');
    if (innTask && [ 'failed, soft_failed' ].includes(innTask.status)) {
      return '';
    }
    if (innTask && innTask.task_result.inn) {
      return innTask.task_result.inn;
    } else if (this.report.owner && this.report.owner.inn) {
      return this.report.owner.inn;
    } else {
      return '';
    }
  }

  get filteredResult() {
    switch (this.currentFilter) {
      case 'all':
        return this.allCasesUniq;
      case 'fio':
        return this.result.fio;
      case 'shortFio':
        return this.result.short_fio;
      case 'inn':
        return this.result.inn;
      default:
        return [];
    }
  }

  get allCasesUniq() {
    return uniqBy(concat(
      this.result.fio || [],
      this.result.short_fio || [],
      this.result.inn || []
    ), 'case_number');
  }

  get slicedResult() {
    return this.filteredResult.slice(0, 20);
  }

  get result() {
    return this.task.task_result;
  }

  get totalCount() {
    return this.allCasesUniq.length;
  }

  get regionName(): string {
    return getRegionNameById(this.report.owner.region);
  }

  get pluralText(): string {
    return pluralize(this.totalCount, 'совпадение', 'совпадения', 'совпадений');
  }

  get naming(): string {
    if (this.isPhysical) {
      return 'фамилии имени отчеству';
    } else {
      return 'наименованию юридического лица';
    }
  }

  get statusDone() {
    return this.status === 'done';
  }

  get resultStatus() {
    if (this.task && this.task.task_result && this.statusDone) {
      if (
        this.task.task_result.count_fio > 0 ||
        this.task.task_result.count_short_fio > 0 ||
        this.task.task_result.count_inn > 0
      ) {
        this.updateColor('danger');
        return 'found';
      } else {
        this.updateColor('success');
        return 'not found';
      }
    }
    this.updateColor('info');
    return 'no data';
  }

  updateColor(color: string) {
    this.updateMap('arbitrReport', 'color', color);
  }

  getTask(val: string) {
    return this.report.tasks.find((elem: any) => elem.task_type === val);
  }
}
