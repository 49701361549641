



















































import Vue from 'vue';
import Component from 'vue-class-component';
import { TheMask } from 'vue-the-mask';
import { Validators } from 'shared/utils/validator';

const now = new Date();
@Component({
  props: {
    owner: Object,
    fetchReport: Function,
  },

  popupOptions: {
    name: '',
    size: 'xs',
  },

  components: { TheMask },
})
export default class AppAddLicenseDialog extends Vue {
  // props
  model: any = {
    license: '',
    driver_license_at: '',
  };

  datePickerOptions: any = {
    maxDate: now,
    minDate: new Date(now.getFullYear() - 120, 0, 0),
    defaultDate: '',
  };

  fetchReport: () => void;

  owner: LawInformationOwner;
  loading: boolean = false;

  created() {
    if (this.owner.license) {
      this.model.license = this.owner.license;
    }
  }

  validationConfig(): ValidationConfig {
    return {
      license: [ Validators.min(10, 'минимальная длина 10') ],
      // driver_license_at: [ Validators.date('Неверный формат даты') ],
    };
  }

  async onSubmit() {
    this.loading = true;
    try {
      this.owner.license = this.model.license;
      if (this.model.driver_license_at) {
        this.owner.driver_license_at = new Date(this.model.driver_license_at).toISOString();
      }
      await this.$api.risk.UpdateOwnerV2(this.owner);
      setTimeout(() => {
        this.fetchReport();
      }, 2000);
      this.$dialog.close();
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
    return Promise.resolve();
  }
}
