
























import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import Highcharts from 'highcharts';
import eachDay from 'date-fns/each_day';
import format from 'date-fns/format';
import getDay from 'date-fns/get_day';
import _ from 'lodash';

@Component({
  props: {
    date: Object,
  },
})
export default class AdminStatsLineGraphic extends Vue {
  private chart: Highcharts.Chart;

  // props
  date: { from: string; to: string };

  // data
  selectedCategory: string = 'status';
  chartCategoryList: SimpleObject<string> = {
    status: 'Статусы',
    transaction: 'Транзакции',
    role: 'Роли',
    product: 'Продукты',
    source: 'Источники',
  };
  daysOfWeek: SimpleObject = {
    0: 'Воскресенье',
    1: 'Понедельник',
    2: 'Вторник',
    3: 'Среда',
    4: 'Четверг',
    5: 'Пятница',
    6: 'Суббота',
  };

  @Watch('date', { deep: true })
  watchHandler() {
    this.request();
  }

  mounted() {
    this.chart = Highcharts.chart(this.$refs.container as HTMLElement, {
      title: null,
      yAxis: {
        title: null,
      },

      xAxis: {
        type: 'datetime',
        labels: {
          step: 1,
        },
        title: {
          text: 'Дата',
        },
        tickmarkPlacement: 'on',
      },

      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          threshold: 0,
          pointPlacement: 'on',
        },
        line: {
          marker: {
            enabled: false,
          },
        },
      },

      responsive: {
        rules: [ {
          condition: {
            maxWidth: 575,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'left',
              verticalAlign: 'bottom',
            },
          },
        } ],
      },
    });

    this.request();
  }

  // methods
  async request() {
    try {
      this.chart.showLoading('Загрузка');

      const data = await this.$api.admin.StatsGraphic({
        graphicTarget: this.selectedCategory,
        'date-from': this.date.from,
        'date-to': this.date.to,
      });
      console.log(data);

      let categories: any[] = eachDay(data.start_time, data.end_time);

      if (data.group_type === 'week') {
        categories = categories.filter((day, index) => index % 7 === 0);
      }

      if (data.group_type === 'month') {
        categories = categories.filter((day, index) => index % 30 === 0);
      }

      categories = categories.map(date => format(date, 'DD.MM.YYYY'));

      for (let i = 0, len = this.chart.series.length; i < len; i++) {
        this.chart.series[0].remove(false);
      }

      data.series.forEach((serie: any) => this.chart.addSeries(serie, false));

      this.chart.update({
        xAxis: {
          categories,
        },
      });

      this.chart.hideLoading();
    } catch (error) {
      console.error(error);
    }
  }

  changeCategory(category: string) {
    this.selectedCategory = category;

    this.request();
  }
}
