

























































import Component, { mixins } from 'vue-class-component';
import AppStatementItem from './StatementItem.vue';
import StatementEgrnMixin from 'shared/mixins/statements/statementEgrn.mixin';

@Component({
  components: { AppStatementItem },
  props: {
    showPayInfo: Boolean,
    opened: {
      type: Boolean,
      default: false,
    },
    statements: Object,
    images: {
      type: Array,
      default: false,
    },
  },
})
export default class AppStatementObjectPlan extends mixins(StatementEgrnMixin) {
  // props
  showPayInfo!: boolean;
  opened: boolean;
  images: any[];
  statements: any;

  get hasImages() {
    return this.images && !!this.images.length;
  }
}
