














































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import noop from 'lodash/noop';
import flatpickr from 'flatpickr';

import { Validators } from '@/core/utils/validator';

const now = new Date();

@Component({
  name: 'AppCreateCampaignDialog',

  popupOptions: {
    size: 'sm',
  },

  props: {
    campaign: {
      type: Object,
      default: null,
    },
    onRequestEnd: {
      type: Function,
      default: noop,
    },
  },
})
export default class CreateCampaign extends Vue {
  // props
  campaign: any;
  onRequestEnd: () => void;

  // data
  datePickerOptions: flatpickr.Options.Options = {
    maxDate: '',
    minDate: new Date(now.getFullYear() - 120, 0, 0),
    defaultDate: '',
  };
  model: any = {
    name: '',
    code: '',
    description: '',
    discount: '',
    start_at: '',
    end_at: '',
    active: true,
    product_id: '',
    usage_count: 1,
  };

  created() {
    if (this.campaign) {
      this.model = {
        name: this.campaign.Name,
        code: this.campaign.Code,
        description: this.campaign.Description,
        discount: this.campaign.Discount,
        start_at: this.campaign.StartAt,
        end_at: this.campaign.EndAt,
        active: this.campaign.Active,
        product_id: this.campaign.ProductID,
        usage_count: this.campaign.UsageCount,
      };
    }
  }

  // methods
  async onSubmit() {
    try {
      this.model.start_at = new Date(this.model.start_at).toISOString();
      this.model.end_at = new Date(this.model.end_at).toISOString();
      this.model.usage_count = parseInt(this.model.usage_count);
      if (this.campaign) {
        this.model.campaign_id = this.campaign.ID;
        await this.$api.admin.UpdateCampaign(this.model);
      } else {
        await this.$api.admin.CreateCampaign(this.model);
      }

      this.onRequestEnd();
      this.$dialog.close();
    } catch (error) {
      console.error(error);
    }
  }

  get products() {
    return this.$store.state.products;
  }

  validationRules(): ValidationConfig {
    return {
      name: [ Validators.required ],
      code: [ Validators.required ],
      description: [ Validators.required ],
      discount: [ Validators.required ],
      start_at: [ Validators.required ],
      end_at: [ Validators.required ],
      active: [],
      product_id: [ Validators.required ],
      usage_count: [ Validators.required ],
    };
  }
}
