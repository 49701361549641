var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPhysical
    ? _c(
        "app-report-item",
        {
          attrs: {
            title: "Действительность паспорта",
            icon: "passport",
            status: _vm.status,
            images: _vm.images,
            color: _vm.map.color,
            source: {
              domain: "services.fms.gov.ru",
              link: "http://services.fms.gov.ru/info-service.htm?sid=2000"
            },
            "missing-requirements": _vm.missingRequirements
          },
          scopedSlots: _vm._u(
            [
              {
                key: "not-required",
                fn: function() {
                  return [
                    !_vm.isPdf
                      ? _c(
                          "span",
                          {
                            staticClass: "link font-weight-500 d-print-none",
                            on: {
                              click: function($event) {
                                return _vm.openAddFieldDialog("passport")
                              }
                            }
                          },
                          [_vm._v("\n      Добавить\n    ")]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            1071807397
          )
        },
        [
          _c(
            "div",
            { staticClass: "report-item-modern__subtitle-wrapper" },
            [
              _vm.isPhysical &&
              (_vm.fioValidCheck ||
                ["valid", "invalid"].includes(_vm.gosuslugiStatus))
                ? _c("div", { staticClass: "report-item-modern__subtitle" }, [
                    _c("div", {
                      staticClass:
                        "report-item-modern__dot report-item-modern__color--success"
                    }),
                    _c("div", {}, [
                      _vm._v("\n        Соответствие ФИО"),
                      _vm.fioValidCheck
                        ? _c("span", [_vm._v(", даты рождения")])
                        : _vm._e(),
                      _vm._v("\n        и номера паспорта подтверждено\n      ")
                    ])
                  ])
                : _vm._e(),
              _vm.resultText
                ? [
                    _c("div", { staticClass: "report-item-modern__subtitle" }, [
                      _c("div", {
                        staticClass: "report-item-modern__dot",
                        class: "report-item-modern__color--" + _vm.map.color
                      }),
                      _c("div", [
                        _vm._v(
                          "\n          " + _vm._s(_vm.resultText) + "\n        "
                        )
                      ])
                    ]),
                    _vm.showMessage
                      ? _c(
                          "div",
                          { staticClass: "report-item-modern__subtitle" },
                          [
                            _c("div", {
                              staticClass: "report-item-modern__dot",
                              class:
                                "report-item-modern__color--" + _vm.map.color
                            }),
                            _c("div", [
                              _vm._v(
                                "\n          После получения информации от источника, данные будут обновлены на текущую дату запроса.\n          "
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tippy",
                                      rawName: "v-tippy",
                                      value: {
                                        placement: "top-end",
                                        interactive: true
                                      },
                                      expression:
                                        "{ placement: 'top-end', interactive: true }"
                                    }
                                  ],
                                  staticClass: "link small ml-2"
                                },
                                [
                                  _vm._v(
                                    "\n            Подробнее\n            "
                                  ),
                                  _c(
                                    "div",
                                    { attrs: { "data-tippy-content": "hide" } },
                                    [
                                      _vm._v(
                                        "\n              Напоминаем, МВД приостановил онлайн проверку действительных паспортов в связи с путаницей в базе данных. В связи с чем, сервис использует в работе последнюю доступную версию базы МВД. Для проверки актуальной информации мы используем Госуслуги, который на текущий момент не доступен - ожидайте уведомления.\n            "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                : _vm.orderIsDone
                ? [
                    _c("span", { staticClass: "text-warning" }, [
                      _vm._v(" Недостаточно данных ")
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }