



































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';

import startsWith from 'lodash/startsWith';
import size from 'lodash/size';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';

import { ObjectTypes } from 'shared/store/modules/objectDetail/types';
// import { mapState } from 'vuex';
import { Watch } from 'vue-property-decorator';

import AppRiskReport from 'shared/components/RiskNew/Report.vue';
import AppReportExample from 'shared/components/Risk/ReportExample.vue';
import AppConclusions from 'shared/components/Risk/Conclusions.vue';
import AppRiskConclusionItem from './RiskConclusionItem.vue';
import AppRiskConclusionGeneralStatus from './RiskConclusionGeneralStatus.vue';

import AppAddOwnerForm from 'shared/components/RiskNew/AddOwnerForm/AddOwnerForm.vue';
import AppAddOwnerDialog from 'shared/components/RiskNew/AddOwnerForm/AddOwnerItemDialog.vue';
import AppEditOwnerItemDialog from 'shared/components/RiskNew/AddOwnerForm/EditOwnerItemDialog.vue';
import isUnderage from 'shared/utils/isUnderage';
import AppSurveyDialog from 'shared/components/RiskNew/SurveyDialog/SurveyDialog.vue';
import AppAddOwnerSuccessDialog from 'shared/components/RiskNew/AddOwnerForm/AddOwnerSuccessDialog.vue';

@Component({
  components: {
    AppRiskReport,
    AppReportExample,
    AppAddOwnerForm,
    AppRiskConclusionItem,
    AppRiskConclusionGeneralStatus,
    AppConclusions,
    AppSurveyDialog,
  },
  props: {
    objectKey: String,
    objectInfo: Object,
    fetchRisk: Function,
    report: Object,
    renderType: {
      type: String,
      default: '',
    },
    onlyOwners: {
      default: false,
      type: Boolean,
    },
    opened: {
      default: false,
      type: Boolean,
    },
  },
  // computed: {
  //   ...mapState('objectDetail', [ 'riskReportV2' ]),
  // },
})
export default class Risk extends Vue {
  // props
  objectKey: string;
  objectInfo: RealtyObject;
  fetchRisk: (orderId: string) => void;
  opened: boolean;
  onlyOwners: boolean;
  report: LawInformation;
  renderType: string;

  isUnderage = isUnderage;

  // store
  // riskReportV2: LawInformation;

  // data()
  // reportItems: any[] = [];
  loading = false;
  showContent: SimpleObject<boolean> = {};

  @Watch('report')
  riskReportChanged(): void {
    this.setReport();
  }

  get showInfo() {
    return JSON.stringify(this.report);
  }

  // computed
  get riskItem(): any {
    if (this.opened) {
      return {
        status: this.report.order.status,
        order_item_id: this.report.order.order_item_id,
      };
    } else {
      return this.$store.getters[ObjectTypes.getters.PURCHASED_ITEM]((product: Product) => {
        return startsWith(product.product_name, 'RiskAssessment');
      });
    }
  }

  get hasOwners() {
    return size(get(this.report, 'owners')) !== 0;
  }

  get newSurveys() {
    return this.report.surveys.filter((elem: SurveyElem) => {
      return elem.status === 'in progress';
    });
  }

  get sortedConclusions(): ConclusionItem[] {
    const res: any = {
      high: [],
      medium: [],
      low: [],
      check: [],
      info: [],
    };
    this.report.conclusions.forEach((elem: ConclusionItem) => {
      res[elem.color_level].push(elem);
    });
    return [].concat(res.high, res.medium, res.low, res.check, res.info);
  }

  get conclusionStatus(): any {
    if (isEmpty(this.report.conclusions)) {
      return null;
    }

    if (this.report.conclusions.find((elem: any) => elem.color_level === 'high')) {
      return 'high';
    }

    if (this.report.conclusions.find((elem: any) => elem.color_level === 'medium')) {
      return 'medium';
    }

    if (this.report.conclusions.find((elem: any) => elem.color_level === 'low')) {
      return 'low';
    }

    return null;
  }

  get reportSealed() {
    return this.report.order.status === 'sealed';
  }

  get isDemo() {
    return this.objectKey === 'demo';
  }

  get fullObject() {
    return this.report.tasks_general.find((elem) => {
      return elem.task_type === 'get_object_full';
    });
  }

  get fullObjectRights() {
    let rights = get(this.fullObject, 'task_result.Rights', []);
    let limits = get(this.fullObject, 'task_result.Limits', []);
    if (rights && rights.length) {
      rights = rights.filter((elem: any) => {
        return elem.date;
      });
    }
    if (limits && limits.length) {
      limits = limits.filter((elem: any) => {
        return [ 'Аренда', 'Доверительное управление' ].includes(elem.type) && elem.date;
      });
    }
    return concat(rights || [], limits || []);
  }

  // lifecycle hooks
  async mounted() {
    if (this.riskItem) {
      this.setReport();
    }

    this.loading = false;
  }

  // methods
  ownersAdded() {
    this.$dialog.open({
      component: AppAddOwnerSuccessDialog,
      props: {
        addOwner: this.addOwner,
      },
    });
    setTimeout(() => {
      this.fetchRisk(this.riskItem.order_item_id);
    }, 1500);
  }

  async setReport() {
    try {
      // let report;

      // if (this.objectKey === 'demo') {
      //   const { data } = await this.$api._axios.get('/data/risk-demo.json');
      //   report = data;
      //   this.report = report;
      // }
    } catch (error) {
      console.error(error);
    }
  }

  toggleShowContent(id: string) {
    if (id in this.showContent) {
      this.showContent[id] = !this.showContent[id];
    } else {
      this.$set(this.showContent, id, true);
    }
  }

  editReportItem(item: any) {
    this.$dialog.open({
      component: AppEditOwnerItemDialog,
      props: {
        owner: item.owner,
        rightsList: this.fullObjectRights,
        onSave: () => setTimeout(() => this.fetchRisk(this.riskItem.order_item_id), 2000),
      },
    });
  }

  openSurveyDialog(surveys: SurveyElem[]) {
    this.$dialog.open({
      component: AppSurveyDialog,
      props: { surveys: surveys, onSave: () => this.fetchRisk(this.riskItem.order_item_id), orderItemId: this.riskItem.order_item_id },
    });
  }

  addOwner() {
    this.$dialog.open({
      component: AppAddOwnerDialog,
      props: {
        orderItemId: this.report.order.order_item_id,
        onSuccess: this.ownersAdded,
        rightsList: this.fullObjectRights,
      },
    });
  }

  checkRenderType(value: string) {
    const arr = this.renderType.split(',');
    return arr.includes(value);
  }
}
