































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import has from 'lodash/has';
import AppReportCollapseItem from 'shared/components/FizCheck/ReportCollapseItem.vue';

import { BankruptCaseTypes } from 'shared/utils/constants/bankrupt';

@Component({
  components: { AppReportCollapseItem },
  props: {
    result: Array,
    isPhysical: Boolean,
    opened: Boolean,
  },
})
export default class AppBankruptReportDetails extends Vue {
  // props
  result: any;
  isPhysical: boolean;
  has = has;
  opened: boolean;
  caseTypes = BankruptCaseTypes;

  objectHas(object: any, key: string) {
    return has(object, key);
  }
}
