import Vue from 'vue';
import Component from 'vue-class-component';

import AppCreateSettingsDialog from './components/AppCreateSettings.vue';
import AppEditTextSettingsDialog from './components/AppEditTextSettings.vue';

@Component({
  name: 'AppSettings',
})
export default class AppSettings extends Vue {
  loading: { [key: string]: boolean } = {};

  get settings() {
    return this.$store.state.settings.items;
  }

  showCreateDialog() {
    this.$dialog.open({
      component: AppCreateSettingsDialog,
    });
  }

  editText(item: Admin.Settings.Item) {
    this.$dialog.open({
      component: AppEditTextSettingsDialog,
      props: { item },
    });
  }

  async toggleEnabled(enabled: boolean, key: string) {
    this.toggleLoading(key);

    try {
      await this.$store.dispatch('settings/toggleEnabled', { key, enabled });
      this.$noty.info({ text: 'Настройки обновлены' });
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: this.$api._getErrorMessage(error) });
    }

    this.toggleLoading(key);
  }

  async removeItem(key: string) {
    if (!confirm('Удалить эту настройку')) {
      return;
    }

    this.toggleLoading(key);

    try {
      await this.$store.dispatch('settings/removeItem', key);
      this.$noty.info({ text: 'Настройки обовлены' });
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: this.$api._getErrorMessage(error) });
    }

    this.toggleLoading(key);
  }

  toggleLoading(key: string) {
    if (key in this.loading) {
      this.loading[key] = !this.loading[key];
    } else {
      this.$set(this.loading, key, true);
    }
  }
}
