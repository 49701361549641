import _ from 'lodash';
import Component, { mixins } from 'vue-class-component';
import DataTableMixin from '@/core/mixins/data-table.mixin';
import addDays from 'date-fns/add_days';
import format from 'date-fns/format';
import FilterMixin from '@/core/mixins/filter.mixin';
import HelpersMixin from '@/core/mixins/helpers.mixin';
import AppDateFilterDialog from '@/views/Admin/components/DateFilterDialog.vue';

@Component({
  props: {
    userID: String,
  },
})
export default class AdminUserOrders extends mixins(DataTableMixin, FilterMixin, HelpersMixin) {
  userID!: string;
  stats: StatsData = {};
  headers: TableHeaderItem[] = [
    {
      text: 'Название',
    },
    {
      text: 'Кол-во',
    },
    {
      text: 'Сумма',
    },
    {
      text: '%',
      width: '100px',
    },
  ];

  date = {
    from: format(Date.now(), 'YYYY-MM-DD'),
    to: format(addDays(Date.now(), 1), 'YYYY-MM-DD'),
  };

  async handleRequest() {
    const params = {
      ...this.date,
      target: this.userID,
    };
    this.stats = await this.$api.admin.Stats(params);
    return this.stats.sum_products;
  }

  getPercent(item: {sum: number}) {
    return parseFloat(((item.sum / this.totalSum) * 100).toFixed(2));
  }

  showDateFilter() {
    this.$dialog.open({
      component: AppDateFilterDialog,
      props: {
        date: this.date,
        onClose: this.fetchData,
      },
    });
  }

  get totalSum(): number {
    return _.sumBy(this.items, 'sum');
  }

  get totalCount(): number {
    return _.sumBy(this.items, 'count');
  }
}
