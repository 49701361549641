var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPhysical && _vm.onlyOwners
    ? _c(
        "app-report-item",
        {
          attrs: {
            title: "Розыск УФСИН",
            icon: "binoculars",
            status: _vm.status,
            opened: _vm.haveResult,
            "missing-requirements": _vm.missingRequirements,
            images: _vm.images,
            color: _vm.map.color,
            source: {
              domain: "fsin.gov.ru",
              link: "https://fsin.gov.ru/criminal/"
            }
          },
          scopedSlots: _vm._u(
            [
              {
                key: "not-required",
                fn: function() {
                  return [
                    !_vm.isPdf
                      ? _c(
                          "span",
                          {
                            staticClass: "link font-weight-500 d-print-none",
                            on: {
                              click: function($event) {
                                return _vm.openAddFieldDialog("owner")
                              }
                            }
                          },
                          [_vm._v("\n      Добавить\n    ")]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              },
              _vm.resultStatus === "found"
                ? {
                    key: "extra-content",
                    fn: function() {
                      return _vm._l(_vm.result, function(elem) {
                        return _c("div", { key: elem.url }, [
                          _c("div", {
                            staticClass: "criminal-report__divider"
                          }),
                          _c("div", { staticClass: "row md-gutters" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 col-md-auto text-center text-md-left mb-4 mb-md-0"
                              },
                              [
                                elem.photo
                                  ? _c("img", {
                                      staticClass: "criminal-report__image",
                                      attrs: { src: elem.photo }
                                    })
                                  : _c("img", {
                                      staticClass: "criminal-report__image",
                                      attrs: { src: "/rc-modern/photo.svg" }
                                    })
                              ]
                            ),
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "div",
                                { staticClass: "criminal-report__items" },
                                [
                                  elem.fio
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                ФИО\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(elem.fio) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  elem.date
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Дата рождения\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(elem.date) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  elem.territorial
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Регион\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(elem.territorial) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  elem.federal
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Федеральный округ\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(elem.federal) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  elem.subtext
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "criminal-report__item"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                Дополнительная информация\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__result"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(elem.subtext) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ])
                          ])
                        ])
                      })
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _vm.task
            ? [
                _c(
                  "div",
                  { staticClass: "report-item-modern__subtitle" },
                  [
                    _vm.resultStatus === "found"
                      ? [
                          _vm._v(
                            "\n        Числится в базе данных розыска\n      "
                          )
                        ]
                      : [
                          _vm._v(
                            "\n        В базе данных розыска не числится\n      "
                          )
                        ]
                  ],
                  2
                )
              ]
            : _vm.orderIsDone
            ? [
                _c("span", { staticClass: "text-warning" }, [
                  _vm._v(" Недостаточно данных ")
                ])
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }