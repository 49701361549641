






































import Vue from 'vue';
import Component from 'vue-class-component';
import get from 'lodash/get';
import forEach from 'lodash/forEach';

@Component({
  popupOptions: {
    size: 'sm',
  },

  props: {
    report: Object,
  },
})
export default class RiskUpdateScraperStatusDialog extends Vue {
  // props
  report: OwnerItemReport;

  // data
  checked: string[] = [];
  loading: boolean = false;

  get statuses(): SimpleObject<{ status: ExecutionStatusList; name: string }> {
    const paths = {
      'owner.inn': 'Поиск ИНН',
      'owner.npdStatus': 'Проверка на самозанятость',
      'orgs': 'Руководитель или учредитель',
      'owner.taxes': 'Проверка налогов',
      'owner.passport.valid': 'Действительность паспорта',
      'mvdRf': 'Мвд.рф нахождение в розыске',
      'rosfinmonitoring': 'Росфинмониторинг',
      'fssp': 'ФССП',
      'reestrBankrotov': 'Реестр Банкротов',
      'reestrZalogov': 'Реестр Залогов',
      'sudRf': 'Суды рф',
      'irbisApi': 'Запрос на создание (Irbis API)',
      'arbitr': 'Арбитраж (Irbis API)',
      'judge': 'Суды (Irbis API)',
      'scoring': 'Скоринговый бал (Irbis API)',
    };

    const statuses: SimpleObject<{ status: ExecutionStatusList; name: string }> = {};

    forEach(paths, (name, path) => {
      // console.log('name', name, path);
      statuses[path] = {
        status: get(this.report, `${path}.executionStatus`),
        name,
      };
    });

    return statuses;
  }

  statusText(status: ExecutionStatusList): string {
    switch (status) {
      case 'complete':
        return 'Выполнено';
      case 'in-progress':
        return 'Выполняется';
      case 'error':
        return 'Ошибка';
      case 'cancel':
        return 'Отменён';
      default:
        return 'В очереди на выполнение';
    }
  }

  statusClassName(status: ExecutionStatusList): string {
    switch (status) {
      case 'complete':
        return 'text-success';
      case 'in-progress':
        return 'text-info';
      case 'error':
        return 'text-danger';
      case 'cancel':
        return 'text-warnig';
      default:
        return 'text-purple';
    }
  }

  async submit() {
    this.loading = true;

    try {
      const statuses: SimpleObject<ExecutionStatusList> = this.checked.reduce<any>((obj: any, path: string) => {
        obj[path] = 'in-progress';

        return obj;
      }, {});

      await this.$api.risk.UpdateScraperStatus({
        ownerId: this.report.ownerId,
        statuses,
      });

      this.$dialog.close();
      this.$noty.info({ text: 'Парсеры будут перезапущены' });
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: this.$api._getErrorMessage(error) });
    }

    this.loading = false;
  }
}
