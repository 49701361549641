var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-item-result report-item-result__result-wrapper" },
    _vm._l(_vm.result, function(item, index) {
      return _c(
        "div",
        { key: index + "i", staticClass: "report-item-result__result" },
        [
          _c("app-report-collapse-item", {
            attrs: { opened: _vm.opened },
            scopedSlots: _vm._u(
              [
                item.headers && item.headers.length
                  ? {
                      key: "header",
                      fn: function() {
                        return _vm._l(item.headers, function(header) {
                          return _c(
                            "div",
                            {
                              key: header.name,
                              staticClass:
                                "report-item-result__result-elem-text-wrapper",
                              style: { flex: "0 0 " + header.width + "%" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(header.name) +
                                      "\n          "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                [
                                  _c("app-hidden-text", {
                                    attrs: {
                                      text: "" + header.value,
                                      "max-height": "180px"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        })
                      },
                      proxy: true
                    }
                  : {
                      key: "header",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "report-item-result__result-elem-text-wrapper extended-report-head-elem"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__result-elem-text"
                                },
                                [_vm._v("\n            Источник\n          ")]
                              ),
                              item["ИСТОЧНИК"]
                                ? _c("div", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(item["ИСТОЧНИК"]) +
                                        "\n          "
                                    )
                                  ])
                                : _c("div", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(item["источник"]) +
                                        "\n          "
                                    )
                                  ])
                            ]
                          )
                        ]
                      },
                      proxy: true
                    },
                item.items && item.items.length
                  ? {
                      key: "content",
                      fn: function() {
                        return [
                          _vm._l(item.items, function(elem, i) {
                            return _c(
                              "div",
                              {
                                key: elem[0] + "i",
                                staticClass:
                                  "report-item-result__content-section"
                              },
                              [
                                elem.title
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result__content-section-title"
                                      },
                                      [
                                        _c("h4", [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(i + 1) +
                                              ".\n              " +
                                              _vm._s(elem.title) +
                                              "\n            "
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__content-list"
                                  },
                                  _vm._l(elem, function(value, name) {
                                    return _c(
                                      "div",
                                      {
                                        key: name + "i",
                                        staticClass:
                                          "report-item-result__content-list-elem"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(name) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "report-item-result__content-list-elem-result"
                                          },
                                          [
                                            _c("app-hidden-text", {
                                              attrs: {
                                                text: "" + value,
                                                "max-height": "180px"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          }),
                          item.badges && item.badges.length
                            ? _c("app-extended-report-badge-collapse", {
                                attrs: { badges: item.badges }
                              })
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  : {
                      key: "content",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "report-item-result__content-section"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "report-item-result__content-list"
                                },
                                _vm._l(item, function(value, name) {
                                  return _c(
                                    "div",
                                    {
                                      key: name + "i",
                                      staticClass:
                                        "report-item-result__content-list-elem"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-title"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(name) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-item-result__content-list-elem-result"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(value) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }