














































































































































































import Vue from 'vue';
import { Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import AppReportCollapseItem from 'shared/components/FizCheck/ReportCollapseItem.vue';
import capitalize from 'lodash/capitalize';
import AppHiddenText from 'shared/components/ui/HiddenText/HiddenText.vue';
import AppExtendedReportBadgeCollapse from 'shared/components/FizCheck/ExtendedReport/ExtendedReportBadgeCollapse.vue';

@Component({
  components: { AppReportItem, AppReportCollapseItem, AppHiddenText, AppExtendedReportBadgeCollapse },
  props: {
    result: Object,
    opened: Boolean,
  },
})
export default class AppExtendedCriminalReport extends Vue {
  // props
  result: any;
  capitalize = capitalize;
  opened: boolean;
  openedLocal: boolean = false;

  @Watch('opened', { immediate: true })
  openedChanged(val: boolean): void {
    this.openedLocal = val;
  }

  toggleOpen() {
    this.openedLocal = !this.openedLocal;
  }
}
