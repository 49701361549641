var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wrapper mb-3" }, [
    _c(
      "div",
      { staticClass: "d-flex badges-list" },
      _vm._l(_vm.chartCategoryList, function(categoryText, categoryKey) {
        return _c(
          "div",
          {
            key: categoryKey,
            class: [
              "badge cursor-pointer mr-2",
              {
                "badge-primary": _vm.selectedCategory === categoryKey,
                "badge-light": _vm.selectedCategory !== categoryKey
              }
            ],
            on: {
              click: function($event) {
                return _vm.changeCategory(categoryKey)
              }
            }
          },
          [_vm._v("\n      " + _vm._s(categoryText) + "\n    ")]
        )
      }),
      0
    ),
    _c("div", { staticClass: "admin-page-content graphic-container" }, [
      _c("div", { ref: "container" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }