























































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportCollapseItem from 'shared/components/FizCheck/ReportCollapseItem.vue';

@Component({
  components: { AppReportCollapseItem },
  props: {
    result: Array,
    opened: Boolean,
  },
})
export default class AppSudActDetails extends Vue {
  // props
  result: any[];
  // data
  opened: boolean;
}
