





































































































import Vue from 'vue';
import omit from 'lodash/omit';
import { Validators } from 'shared/utils/validator';
import pluck from 'shared/utils/pluck';
import formatDateStringToISO from 'shared/utils/date/formatDateStringToISO';
interface ComponentData {
  model: Model.ActivateTariffForUserBody;
  activeTarif: Model.Tariff.Item;
  tariffes: any;
  loading: boolean;
}

export default Vue.extend({
  popupOptions: {
    size: 'xs',
  },

  props: {
    userId: String,
    onCreated: Function,
  },

  data(): ComponentData {
    return {
      model: {
        tariff_simple_id: null,
        target_user_id: this.userId,
        time_start: new Date().toString(),
        time_end: '',
        count_current: null,
        count_max: null,
      },
      activeTarif: {
        duration: null,
        tariff_simple_id: null,
        tariff_name: null,
        tariff_threshold: null,
        product_prices: null,
      },
      tariffes: [],
      loading: true,
    };
  },

  async created() {
    try {
      this.model.time_end = this.getTimeEndDate();
      this.activeTarif = await this.getActiveTariff();
      const tariffes = await this.getTariffes();
      this.tariffes = omit(tariffes, [ '0' ]);
      this.loading = false;
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    async getActiveTariff() {
      return this.$api.tariff.GetCurrentForUser({
        user_id: this.userId,
      });
    },

    async getTariffes() {
      const data = await this.$api.tariff.Items();
      return pluck(data.tariffs, 'tariff_simple_id', 'tariff_name');
    },

    validationRules(): ValidationConfig {
      return {
        tariff_simple_id: [ Validators.required ],
        time_start: [ Validators.required ],
        time_end: [ Validators.required ],
        count_current: [ Validators.isInt({ min: 1, message: 'Число должно быть не меньше 1' }) ],
        count_max: [ Validators.isInt({ min: 1, message: 'Число должно быть не меньше 1' }) ],
      };
    },

    async activateTariff() {
      if (this.activeTarif.tariff_name === 'Индивидуальный') {
        this.$noty.error({ text: 'У пользователя уже активирован тариф Индивидуальный' });
        return;
      }
      if (this.activeTarif.tariff_simple_id === +this.model.tariff_simple_id) {
        this.$noty.error({ text: 'У пользователя уже активирован данный тариф' });
        return;
      }
      if (Date.parse(this.model.time_start) > Date.parse(this.model.time_end)) {
        this.$noty.error({ text: 'Дата окончания тарифа не может быть раньше даты начала' });
        return;
      }
      this.loading = true;
      try {
        this.model.tariff_simple_id = +this.model.tariff_simple_id;
        const body = { ...this.model };

        const newDate = new Date();
        body.time_start = this.setDateTime(newDate, body.time_start);
        body.time_end = this.setDateTime(newDate, body.time_end);

        formatDateStringToISO(body, 'time_start', 'time_end');

        await this.$api.tariff.ActivateForUser(body);
        this.$noty.success({ text: 'Тариф успешно активирован' });
        this.onCreated();
        this.$dialog.close();
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },

    setDateTime(currentDate: Date, date: string) {
      const result = new Date(date);
      result.setHours(currentDate.getHours());
      result.setMinutes(currentDate.getMinutes());
      result.setSeconds(currentDate.getSeconds());
      return result.toString();
    },

    getTimeEndDate() {
      // 15670000000 - количество милисекунд в 6-ти месяцах
      return new Date(Date.now() + 15670000000).toString();
    },
  },

});

