





































































































import Vue from 'vue';
import Component from 'vue-class-component';
import differenceInYears from 'date-fns/difference_in_years';
import { TheMask } from 'vue-the-mask';
import { Validators } from 'shared/utils/validator';
import { omit } from 'lodash';
import isUnderage from 'shared/utils/isUnderage';
import flatpickr from 'flatpickr';

@Component({
  name: 'AppCreditRatingForm',
  components: {
    TheMask,
  },
  props: {
    owner: Object,
    onSubmit: Function,
  },
})
export default class AppCreditRatingForm extends Vue {
  // props
  owner: any;
  now: Date = new Date();

  datePickerOptions: flatpickr.Options.Options = {
    maxDate: this.now,
    minDate: new Date(this.now.getFullYear() - 120, 0, 0),
    defaultDate: '',
  };

  get isUnderage(): boolean {
    if (/\d{4}-\d{2}-\d{2}/.test(this.owner.birthday)) {
      return differenceInYears(Date.now(), this.owner.birthday) < 18;
    }

    return null;
  }

  get passportDateError(): boolean {
    if (/\d{4}-\d{2}-\d{2}/.test(this.owner.passport_issue_at)) {
      return (
        differenceInYears(this.owner.passport_issue_at, this.owner.birthday) <
        14
      );
    }

    return null;
  }

  created() {
    if (this.$route.query.first) {
      this.owner.first = this.$route.query.first;
    }
    if (this.$route.query.surname) {
      this.owner.surname = this.$route.query.surname;
    }
    if (this.$route.query.patronymic) {
      this.owner.patronymic = this.$route.query.patronymic;
    }
    if (this.$route.query.passport) {
      this.owner.passport = this.$route.query.passport;
    }
    if (this.$route.query.birthday) {
      this.owner.birthday = this.$route.query.birthday;
    }
    if (this.$route.query.passport_issue_at) {
      this.owner.passport_issue_at = this.$route.query.passport_issue_at;
    }
  }

  validationConfig(model: OwnerRequestBody): ValidationConfig {
    let config: ValidationConfig = {
      first: [
        Validators.required,
        Validators.regex(/^[-а-яА-ЯёЁ\s]+$/, 'Только кириллица'),
      ],
      surname: [
        Validators.required,
        Validators.regex(/^[-а-яА-ЯёЁ\s]+$/, 'Только кириллица'),
      ],
      patronymic: [ Validators.regex(/^[-а-яА-ЯёЁ\s]+$/, 'Только кириллица') ],
      passport: [
        Validators.required,
        Validators.min(10, 'минимальная длина 10'),
      ],
      // passportSeries: [ Validators.required, Validators.min(6, 'минимальная длина 6') ],
      passport_issue_at: [
        Validators.required,
        Validators.date('Неверный формат даты'),
      ],
      birthday: [ Validators.required, Validators.date('Неверный формат даты') ],
    };

    if (isUnderage(model.birthday)) {
      config = omit(config, 'passport', 'passport_issue_at');
    }

    return config;
  }
}
