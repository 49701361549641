









































































































































































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import first from 'lodash/first';
import get from 'lodash/get';
import find from 'lodash/find';

import isUnderage from 'shared/utils/isUnderage';
import { checkPersonTasks, tasksMap } from 'shared/utils/checkPersonTasks';

import AppCounterAgentCeo from 'shared/components/CounterAgent/CounterAgentCeo.vue';
import AppCounterAgentFounders from 'shared/components/CounterAgent/CounterAgentFounders.vue';
import AppCounterAgentUnfair from 'shared/components/CounterAgent/CounterAgentUnfair.vue';
import AppCounterAgentRkn from 'shared/components/CounterAgent/CounterAgentRkn.vue';
import AppCounterAgentRequisites from 'shared/components/CounterAgent/CounterAgentRequisites.vue';
import AppCounterAgentLicense from 'shared/components/CounterAgent/CounterAgentLicense.vue';
import AppCounterAgentTrademarks from 'shared/components/CounterAgent/CounterAgentTrademarks.vue';
import AppCounterAgentTaxes from 'shared/components/CounterAgent/CounterAgentTaxes.vue';
import AppCounterAgentProcurement from 'shared/components/CounterAgent/CounterAgentProcurement.vue';
import AppCounterAgentSudStatistic from 'shared/components/CounterAgent/CounterAgentSudStatistic.vue';
import AppCounterAgentStaff from 'shared/components/CounterAgent/CounterAgentStaff.vue';
import AppCounterAgentFinance from 'shared/components/CounterAgent/CounterAgentFinance.vue';
import AppBankruptReport from 'shared/components/FizCheck/BankruptReport.vue';
import AppArbitrReport from 'shared/components/FizCheck/ArbitrReport.vue';
import AppDebtReport from 'shared/components/FizCheck/DebtReport.vue';
import AppForeignAgentReport from 'shared/components/FizCheck/ForeignAgentReport.vue';

@Component({
  components: {
    AppCounterAgentCeo,
    AppCounterAgentFounders,
    AppCounterAgentUnfair,
    AppCounterAgentRkn,
    AppCounterAgentRequisites,
    AppCounterAgentLicense,
    AppCounterAgentTrademarks,
    AppCounterAgentTaxes,
    AppCounterAgentProcurement,
    AppCounterAgentSudStatistic,
    AppCounterAgentStaff,
    AppCounterAgentFinance,
    AppBankruptReport,
    AppDebtReport,
    AppArbitrReport,
    AppForeignAgentReport,
  },
  props: {
    demo: {
      type: Boolean,
      default: false,
    },
    report: Object,
    order: Object,
    id: String,
    fetchReport: Function,
    allowPdfDownload: Boolean,
    download: Function,
  },
})
export default class AppCounterAgentReport extends Vue {
  downloadLoading: boolean = false;
  loading: boolean = false;
  report: LawInformation;
  order: any;
  id: string;
  riskReportV2!: LawInformation;
  isUnderage = isUnderage;
  user: User;
  balance: number;
  aiReportTask: string = 'data_analysis_owner';
  get = get;
  demo: boolean;
  hideUlixes: boolean = false;
  returnSuccess: boolean = false;
  findedRefund: boolean = false;
  hideRateDesign: boolean = false;
  openedOkved: boolean = false;
  tasksMap: any = [];
  allowPdfDownload: boolean;
  download: () => any;

  scrollActiveList: any = [
    {
      ruName: 'Руководитель',
      color: 'info',
      name: 'ceo',
    },
    {
      ruName: 'Учредители',
      color: 'info',
      name: 'founders',
    },
    {
      ruName: 'Сотрудники',
      color: 'info',
      name: 'staff',
    },
    {
      ruName: 'Финансы',
      color: 'info',
      name: 'finance',
    },
    {
      ruName: 'Арбитражные суды',
      color: 'info',
      name: 'sudStatistic',
    },
    {
      ruName: 'Реестр недобросовестных поставщиков',
      color: 'info',
      name: 'unfair',
    },
    {
      ruName: 'Реестр иноагентов',
      color: 'info',
      name: 'foreignAgent',
    },
    {
      ruName: 'Закупки (Госзакупки)',
      color: 'info',
      name: 'procurement',
    },
    {
      ruName: 'Товарные знаки',
      color: 'info',
      name: 'trademarks',
    },
    {
      ruName: 'Лицензии',
      color: 'info',
      name: 'license',
    },
    {
      ruName: 'Реестр Роскомнадзорa',
      color: 'info',
      name: 'rkn',
    },
    {
      ruName: 'Реквизиты',
      color: 'info',
      name: 'requisites',
    },
    {
      ruName: 'Налоги и сборы',
      color: 'info',
      name: 'taxes',
    },
  ];

  get tasks() {
    const tasksNames = checkPersonTasks();
    return {
      bailiffDebt: this.getTask(tasksNames.bailiffDebt),
      individualDebt: this.getTask(tasksNames.individualDebt),
      bankrupt: this.getTask(tasksNames.bankrupt),
      arbitrReport: this.getTask(tasksNames.arbitrReport),
      judicialActs: this.getTask(tasksNames.judicialActs),
      foreignAgent: this.getTask(tasksNames.foreignAgent,),
    };
  }

  get isComplete(): boolean {
    if (!this.report) {
      return false;
    }
    return this.report.order.status === 'done';
  }

  get owner(): LawInformationOwnerElem {
    return first(this.report.owners);
  }

  get isPhysical() {
    return this.owner.owner.owner_type !== 1;
  }

  get companyInfo() {
    const task = this.getTask('biz_legal_entity');
    return get(task, 'task_result.result.docs.0', {});
  }

  get parsersToCheck(): string[] {
    const tasksNames = checkPersonTasks();
    let checkKeys: string[] = [];
    checkKeys = [
      tasksNames.passport,
      tasksNames.criminal,
      tasksNames.bankrupt,
      tasksNames.individualDebt,
      tasksNames.bailiffDebt,
      tasksNames.interpol,
      tasksNames.foreignAgent,
      tasksNames.judicialActs,
      tasksNames.arbitrReport,
      tasksNames.selfEmployed,
      tasksNames.ceo,
      tasksNames.terrorism,
      tasksNames.disqualified,
      tasksNames.pledge,
      tasksNames.fsin,
      tasksNames.socialMedia,
    ];
    if (!this.isPhysical) {
      checkKeys = [
        tasksNames.bankrupt,
        tasksNames.bailiffDebt,
        tasksNames.judicialActs,
        tasksNames.arbitrReport,
      ];
    }

    return checkKeys;
  }

  get completedCount(): number {
    const owner = first(this.report.owners);
    let completed = 0;

    this.parsersToCheck.forEach(path => {
      const task = owner.tasks.find((task) => task.task_type === path);
      if (task && task.status === 'done') {
        completed += 1;
      }
      // if (task && ![ 'in progress', 'not_required', 'failed', 'soft_failed' ].includes(task.status)) {
      //   completed += 1;
      // }
    });

    return completed;
  }

  get completedProgress(): number {
    return this.completedCount / this.totalParsers * 100;
  }

  get totalParsers() {
    return this.parsersToCheck.length;
  }

  get auth(): User {
    return this.$store.state.auth.user;
  }

  get orderIsDone() {
    return this.orderStatus === 'done';
  }

  get orderStatus() {
    return get(this.report, 'order.status');
  }

  async created() {
    this.tasksMap = tasksMap();
  }

  updateTaskMap(taskName: string, field: string, value: any) {
    const task = find(this.tasksMap, (elem) => {
      return elem.name === taskName;
    });
    task[field] = value;
  }

  getTaskStatus(task: any, taskName: string = ''): any {
    if ([ 'judicialActs' ].includes(taskName)) {
      return this.getSudTaskStatus(task);
    }
    if (task) return task.status;
    else if (this.orderIsDone) return 'done';
    else return 'in progress';
  }

  getSudTaskStatus(task: any) {
    const status = this.getTaskStatus(task);
    if ([ 'failed', 'soft_failed' ].includes(status)) {
      const sudact = this.getTask('sudact');
      this.tasks.judicialActs = sudact;
      return this.getTaskStatus(sudact);
    }
    return status;
  }

  getMissingRequirements(task: any) {
    if (task) return task.missing_requirements;
    else return [];
  }

  getMapItem(task: any) {
    return this.tasksMap.find((elem: any) => elem.name === task);
  }

  scrollTo(id: string) {
    const elem = document.getElementById(id);
    const headerOffset = 100;
    const elementPosition = elem.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  getTask(name: string) {
    const owner = first(this.report.owners);
    return owner.tasks.find((elem: any) => {
      return elem.task_type === name;
    });
  }
}
