





















































































import Vue from 'vue';
import Component from 'vue-class-component';

import AppRiskConclusionItem from 'shared/components/RiskNew/RiskConclusionItem.vue';

@Component({
  components: { AppRiskConclusionItem },
  props: {
    report: Object,
    onChange: Function,
  },
})
export default class RiskAddConclusion extends Vue {
  // props
  report: LawInformation;
  onChange: Function;

  // data
  dataSendLoading: boolean = false;

  statuses: { [key in ConclusionStatuses]: string } = {
    high: '<i class="fas fa-circle text-danger mr-1"></i> Высокий',
    medium: '<i class="fas fa-circle text-warning mr-1"></i> Средний',
    low: '<i class="fas fa-circle text-success mr-1"></i> Низкий',
    check: '<i class="fas fa-check-circle text-success mr-1"></i>',
    info: '<i class="fas fa-info-circle text-info mr-1"></i>',
  };

  item: { status: ConclusionStatuses; title: string; text: string } = {
    status: null,
    title: '',
    text: '',
  };

  changeStatus(status: ConclusionStatuses) {
    this.item.status = status;
  }

  async addConclusion() {
    if (!this.item.status) {
      this.$noty.error({ text: 'Добавьте иконку' });
      return;
    }
    const item = {
      color_level: this.item.status,
      text: this.item.text,
    };

    if (this.item.title) {
      item.text = `<b>${this.item.title}</b><br>` + this.item.text;
    }

    await this.updateConclusionItems([ item ]);

    this.$dialog.close();
  }

  async remove(id: string) {
    try {
      await this.$api.risk.RemoveConclusionV2({
        order_item_id: this.report.order.order_item_id,
        conclusions: [ id ],
      });

      this.onChange();
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: 'Ошибка при удалении заключения' });
    }

    this.$dialog.close();
  }

  async updateConclusionItems(items: ConclusionItem[]) {
    if (this.dataSendLoading) {
      return;
    }

    this.dataSendLoading = true;

    try {
      await this.$api.risk.AddConclusionV2({
        order_item_id: this.report.order.order_item_id,
        conclusions: items,
      });

      this.onChange();
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: 'Ошибка при добавлении заключения' });
    }

    this.dataSendLoading = false;
  }

  getConclusion(id: string) {
    return this.report.conclusions.find((item: ConclusionItem) => item.conclusion_id === id);
  }
}
