



































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportItem from 'shared/components/FizCheck/ReportItemModern.vue';
import AppPagination from 'shared/components/ui/Pagination/Pagination.vue';
import AppReportCollapseItem from 'shared/components/FizCheck/ReportCollapseItem.vue';
import get from 'lodash/get';
import chunk from 'lodash/chunk';

@Component({
  components: { AppReportItem, AppPagination, AppReportCollapseItem },
  props: {
    report: Object,
  },
})
export default class AppCounterAgentTrademarks extends Vue {
  // props
  report: any;

  currentPage: number = 1;
  perPage: number = 10;

  get trademarks() {
    return get(this.report, 'ТоварЗнак', []);
  }

  get elems() {
    return chunk(this.trademarks, this.perPage);
  }

  pageChanged(page: number) {
    this.currentPage = page;
  }
}
