var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPhysical && _vm.onlyOwners
    ? _c(
        "app-report-item",
        {
          attrs: {
            title: "Проверка водительского удостоверения",
            icon: "id-card",
            status: _vm.status,
            images: _vm.images,
            color: _vm.map.color,
            opened: _vm.resultStatus === "found",
            "missing-requirements": _vm.missingRequirements,
            source: {
              domain: "госавтоинспекция.рф",
              link: "https://госавтоинспекция.рф/check/driver"
            }
          },
          scopedSlots: _vm._u(
            [
              {
                key: "not-required",
                fn: function() {
                  return [
                    !_vm.isPdf
                      ? _c(
                          "span",
                          {
                            staticClass: "link font-weight-500 d-print-none",
                            on: {
                              click: function($event) {
                                return _vm.openAddFieldDialog("license")
                              }
                            }
                          },
                          [_vm._v("\n      Добавить\n    ")]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              },
              _vm.resultStatus === "found"
                ? {
                    key: "extra-content",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          [
                            _c("div", {
                              staticClass: "criminal-report__divider"
                            }),
                            _c(
                              "div",
                              { staticClass: "row md-gutters license-report" },
                              [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "div",
                                    { staticClass: "criminal-report__items" },
                                    [
                                      _vm.result.ResultCode
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__item"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "criminal-report__title"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                Код результата\n              "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "criminal-report__result"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.result.ResultCode
                                                      ) +
                                                      "\n              "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.result.EndDate
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__item"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "criminal-report__title"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                Срок действия ВУ\n              "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "criminal-report__result"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.result.EndDate
                                                      ) +
                                                      "\n              "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.result.BirthDate
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__item no-gutters"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "criminal-report__title col-md-6"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                Дата рождения\n              "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "criminal-report__result col-md-6"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.result.BirthDate
                                                      ) +
                                                      "\n              "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.result.Category
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__item no-gutters"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "criminal-report__title col-md-6"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                Категория\n              "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "criminal-report__result col-md-6"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.result.Category
                                                      ) +
                                                      "\n              "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.result.DocStatus
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__item no-gutters"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "criminal-report__title col-md-6"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                Статус документа\n              "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "criminal-report__result col-md-6"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.result.DocStatus
                                                      ) +
                                                      "\n              "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.result.RecordType
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "criminal-report__item no-gutters"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "criminal-report__title col-md-6"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                Тип записи\n              "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "criminal-report__result col-md-6"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.result.RecordType
                                                      ) +
                                                      "\n              "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              ]
                            ),
                            _vm.isDeprived
                              ? [
                                  _c("div", {
                                    staticClass: "criminal-report__divider"
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row md-gutters license-report"
                                    },
                                    [
                                      _c("div", { staticClass: "col-12" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "criminal-report__items"
                                          },
                                          [
                                            _vm.result.deprivationLicense
                                              .Comment
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "criminal-report__item"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "criminal-report__title"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  Комментарий\n                "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "criminal-report__result criminal-report__result--danger"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.result
                                                                .deprivationLicense
                                                                .Comment
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.result.deprivationLicense
                                              .BirthPlace
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "criminal-report__item"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "criminal-report__title"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  Место рождения\n                "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "criminal-report__result"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.result
                                                                .deprivationLicense
                                                                .BirthPlace
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.result.deprivationLicense.Date
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "criminal-report__item"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "criminal-report__title"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  Дата вынесения постановления\n                "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "criminal-report__result"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.result
                                                                .deprivationLicense
                                                                .Date
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.result.deprivationLicense.Term
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "criminal-report__item"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "criminal-report__title"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  Срок лишения прав\n                "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "criminal-report__result"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.result
                                                                .deprivationLicense
                                                                .Term
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.result.deprivationLicense.State
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "criminal-report__item"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "criminal-report__title"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  Состояние\n                "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "criminal-report__result"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.result
                                                                .deprivationLicense
                                                                .State
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.result.deprivationLicense
                                              .RecordType
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "criminal-report__item"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "criminal-report__title"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  Тип записи\n                "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "criminal-report__result"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.result
                                                                .deprivationLicense
                                                                .RecordType
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _vm.task
            ? _c(
                "div",
                { staticClass: "report-item-modern__subtitle-wrapper" },
                [
                  _vm.resultStatus === "found"
                    ? [
                        _c(
                          "div",
                          { staticClass: "report-item-modern__subtitle" },
                          [
                            _c("div", {
                              staticClass:
                                "report-item-modern__dot report-item-modern__color--success"
                            }),
                            _c("div", [
                              _vm._v(
                                "\n          Водительское удостоверение найдено, дата выдачи соответствует номеру\n        "
                              )
                            ])
                          ]
                        ),
                        _vm.isDeprived
                          ? [
                              _c(
                                "div",
                                { staticClass: "report-item-modern__subtitle" },
                                [
                                  _c("div", {
                                    staticClass:
                                      "report-item-modern__dot report-item-modern__color--danger"
                                  }),
                                  _c("div", [
                                    _vm._v(
                                      "\n            Есть решение о лишении права на управление ТС\n          "
                                    )
                                  ])
                                ]
                              )
                            ]
                          : _vm._e()
                      ]
                    : [
                        _c(
                          "div",
                          { staticClass: "report-item-modern__subtitle" },
                          [
                            _c("div", {
                              staticClass:
                                "report-item-modern__dot report-item-modern__color--danger"
                            }),
                            _c("div", [
                              _vm._v(
                                "\n          Водительское удостоверение не найдено или дата выдачи не\n          соответствует номеру\n        "
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "report-item-modern__link" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "link font-weight-500 d-print-none",
                                    on: {
                                      click: function($event) {
                                        return _vm.openAddFieldDialog("license")
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            Изменить данные запроса\n          "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                ],
                2
              )
            : _vm.orderIsDone
            ? [
                _c("span", { staticClass: "text-warning" }, [
                  _vm._v(" Недостаточно данных ")
                ])
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }