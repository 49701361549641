






































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import get from 'lodash/get';
import isNull from 'lodash/isNull';

@Component({
  components: { },
  props: {
    result: Array,
    user: Object,
  },
})
export default class AppFizcheckExpertSummary extends Vue {
  // props
  result: any[];
  user: any;
  get = get;
  isNull = isNull;

  currentFilter: string = '';

  @Watch('selectedResult', { deep: true })
  async resultChanged(result: any) {
    if (!result.result_ai && this.existingResult) {
      this.currentFilter = this.existingResult.group_name;
    }
  }

  mounted() {
    this.currentFilter = get(this.result, '0.group_name', '');
  }

  get selectedResult() {
    return this.result.find((elem: any) => {
      return elem.group_name === this.currentFilter;
    });
  }

  filterSelect(value: string) {
    this.currentFilter = value;
  }

  get existingResult() {
    return this.result.find((elem: any) => {
      return !!elem.result_ai;
    });
  }
}
