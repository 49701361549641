var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup__body owner-add-field" },
    [
      _c("app-dialog-close"),
      _c("h4", { staticClass: "mb-3" }, [
        _vm._v("\n    Добавить телефон и email\n  ")
      ]),
      _c(
        "app-form",
        {
          attrs: {
            model: _vm.model,
            rules: _vm.validationConfig,
            "request-handler": _vm.onSubmit
          }
        },
        [
          _c(
            "app-form-group",
            { staticClass: "rc-form-group", attrs: { "model-name": "phone" } },
            [
              _c("app-phone-number-input", {
                attrs: {
                  label: "Телефон*",
                  placeholder: "+7 (___) ___ __ __",
                  size: "lg"
                },
                model: {
                  value: _vm.model.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "phone", $$v)
                  },
                  expression: "model.phone"
                }
              })
            ],
            1
          ),
          _c(
            "app-form-group",
            { staticClass: "rc-form-group", attrs: { "model-name": "email" } },
            [
              _c("app-input", {
                attrs: { label: "Email", placeholder: "examle@example.ru" },
                model: {
                  value: _vm.model.email,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "email", $$v)
                  },
                  expression: "model.email"
                }
              })
            ],
            1
          ),
          _c("p", { staticClass: "font-weight-500" }, [
            _vm._v("\n      Узнаете:\n    ")
          ]),
          _c("div", { staticClass: "row sm-gutters align-items-center mb-2" }, [
            _c("div", { staticClass: "col-auto" }, [
              _c("i", { staticClass: "fas fa-check-circle text-success" })
            ]),
            _c("div", { staticClass: "col small" }, [
              _vm._v(
                "\n        Наличие профилей в социальных сетях и мессенджерах\n      "
              )
            ])
          ]),
          _c("div", { staticClass: "row sm-gutters align-items-center mb-2" }, [
            _c("div", { staticClass: "col-auto" }, [
              _c("i", { staticClass: "fas fa-check-circle text-success" })
            ]),
            _c("div", { staticClass: "col small" }, [
              _vm._v("\n        Подтверждение связки ФИО - телефон\n      ")
            ])
          ]),
          _c("div", { staticClass: "row sm-gutters align-items-center mb-4" }, [
            _c("div", { staticClass: "col-auto" }, [
              _c("i", { staticClass: "fas fa-check-circle text-success" })
            ]),
            _c("div", { staticClass: "col small" }, [
              _vm._v("\n        Подтверждение связки ФИО - email\n      ")
            ])
          ]),
          _c(
            "app-button",
            {
              attrs: {
                type: "submit",
                variant: "primary",
                size: "lg",
                loading: _vm.loading,
                block: ""
              }
            },
            [_vm._v("\n      Добавить и проверить\n    ")]
          ),
          _c("div", { staticClass: "text-center mt-3" }, [
            _c("span", { staticClass: "small text-muted" }, [
              _vm._v(
                "\n        Нажимая кнопку «Добавить и проверить», соглашаетесь с\n        "
              ),
              _c("a", { attrs: { href: "/privacy", target: "_blank" } }, [
                _vm._v("\n          политикой о персональных данных\n        ")
              ]),
              _vm._v("\n        и условиями\n        "),
              _c(
                "span",
                {
                  staticClass: "link",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.showOfferDialog($event)
                    }
                  }
                },
                [_vm._v("\n          оферты\n        ")]
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }