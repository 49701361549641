var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isPhysical
      ? _c(
          "div",
          [
            _vm._v(
              "\n    В базе арбитражных судов по " +
                _vm._s(_vm.naming) +
                "\n    "
            ),
            _vm.result.count_fio > 0
              ? [
                  _c("b", { staticClass: "text-danger" }, [
                    _vm._v("найдено " + _vm._s(_vm.result.count_fio))
                  ]),
                  _vm._v(" " + _vm._s(_vm.pluralText) + "\n      "),
                  !_vm.$isServer
                    ? _c(
                        "span",
                        {
                          staticClass: "link",
                          on: {
                            click: function($event) {
                              return _vm.showDetails(_vm.result.fio)
                            }
                          }
                        },
                        [_vm._v("подробнее")]
                      )
                    : _vm._e()
                ]
              : [_vm._v("\n      совпадения\n      "), _vm._m(0)]
          ],
          2
        )
      : _vm._e(),
    _vm.isPhysical
      ? _c(
          "div",
          { staticClass: "mt-2" },
          [
            _vm._v(
              "\n    В базе арбитражных судов по фамилии и инициалам\n    "
            ),
            _vm.result.count_short_fio > 0
              ? [
                  _c("b", { staticClass: "text-danger" }, [
                    _vm._v("найдено " + _vm._s(_vm.result.count_short_fio))
                  ]),
                  _vm._v(" " + _vm._s(_vm.pluralText) + "\n      "),
                  !_vm.$isServer
                    ? _c(
                        "span",
                        {
                          staticClass: "link",
                          on: {
                            click: function($event) {
                              return _vm.showDetails(_vm.result.short_fio)
                            }
                          }
                        },
                        [_vm._v("подробнее")]
                      )
                    : _vm._e()
                ]
              : [_vm._v("\n      совпадения\n      "), _vm._m(1)]
          ],
          2
        )
      : _vm._e(),
    _vm.inn || !_vm.isPhysical
      ? _c(
          "div",
          { staticClass: "mt-2" },
          [
            _vm._v("\n    В базе арбитражных судов по ИНН "),
            !_vm.isPhysical
              ? _c("span", [_vm._v("и наименованию юридического лица ")])
              : _vm._e(),
            _vm.result.count_inn > 0
              ? [
                  _c("b", { staticClass: "text-danger" }, [
                    _vm._v("найдено " + _vm._s(_vm.result.count_inn))
                  ]),
                  _vm._v(" " + _vm._s(_vm.pluralText) + "\n      "),
                  !_vm.$isServer
                    ? _c(
                        "span",
                        {
                          staticClass: "link",
                          on: {
                            click: function($event) {
                              return _vm.showDetails(_vm.result.inn)
                            }
                          }
                        },
                        [_vm._v("подробнее")]
                      )
                    : _vm._e()
                ]
              : [_vm._v("\n      совпадения\n      "), _vm._m(2)]
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "text-success" }, [
      _c("i", { staticClass: "fas fa-check-circle fa-sm fa-fw mr-1" }),
      _c("b", [_vm._v("не найдены")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "text-success" }, [
      _c("i", { staticClass: "fas fa-check-circle fa-sm fa-fw mr-1" }),
      _c("b", [_vm._v("не найдены")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "text-success" }, [
      _c("i", { staticClass: "fas fa-check-circle fa-sm fa-fw mr-1" }),
      _c("b", [_vm._v("не найдены")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }