






































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import AppReportCollapseItem from 'shared/components/FizCheck/ReportCollapseItem.vue';
import AppPagination from 'shared/components/ui/Pagination/Pagination.vue';
import filter from 'lodash/filter';
import map from 'lodash/map';
import chunk from 'lodash/chunk';

@Component({
  components: { AppReportCollapseItem, AppPagination },
  props: {
    result: Array,
    owner: Object,
    opened: Boolean,
  },
})
export default class AppArbitrReportDetails extends Vue {
  // props
  result: any[];
  owner: any;
  // data
  caseType: string = 'all';
  roleType: string = 'Все';
  opened: boolean;
  currentPage: number = 1;
  perPage: number = 10;
  typeFilters = [
    {
      text: 'Все',
      value: 'all',
    },
    {
      text: 'Банкротные',
      value: 'Б',
    },
    {
      text: 'Административные',
      value: 'А',
    },
    {
      text: 'Гражданские',
      value: 'Г',
    },
  ];

  personFilters = [
    {
      text: 'Все',
      value: 'all',
    },
    {
      text: 'Ответчик',
      value: 'respondent',
    },
    {
      text: 'Истец',
      value: 'plaintiff',
    },
    {
      text: 'Третье лицо',
      value: 'third',
    },
  ];

  roleTypes = {
    P: 'Истец',
    R: 'Ответчик',
  };

  caseTypes = {
    Г: 'Гражданское',
    А: 'Административное',
    Б: 'Банкротное',
    У: 'Уголовное',
    civil: 'Гражданское',
    administrative: 'Административное',
    criminal: 'Уголовное',
  };

  // get filteredResult() {
  //   switch (this.currentFilter) {
  //     case 'active':
  //       return this.activeCases;
  //     case 'closed':
  //       return this.closedCases;
  //     default:
  //       return this.result;
  //   }
  // }

  get mappedResult() {
    const fullName = [ this.owner.surname, this.owner.first, this.owner.patronymic ].join(' ');
    const shortName = `${this.owner.surname} ${this.owner.first.charAt(0)}. ${this.owner.patronymic.charAt(0)}.`;
    return map(this.result, (elem) => {
      const plaintiff = elem.plaintiff.find((item: any) => {
        return (item.name.includes(fullName) || item.name.includes(shortName));
      });
      const respondent = elem.respondent.find((item: any) => {
        return (item.name.includes(fullName) || item.name.includes(shortName));
      });
      if (plaintiff) {
        elem.roleTypeName = 'Истец';
        elem.roleType = 'plaintiff';
      } else if (respondent) {
        elem.roleTypeName = 'Ответчик';
        elem.roleType = 'respondent';
      } else {
        elem.roleTypeName = 'Третье лицо';
        elem.roleType = 'third';
      }
      return elem;
    });
  }

  get filteredCases() {
    return filter(this.mappedResult, (elem: any) => {
      let byType = true;
      let byPeson = true;
      if (this.caseType !== 'all') {
        byType = elem.case_type === this.caseType;
      }
      if (this.roleType !== 'Все') {
        byPeson = elem.entity_role === this.roleType;
      }
      return byType && byPeson;
    });
  }

  get cases() {
    return chunk(this.filteredCases, this.perPage);
  }

  pageChanged(page: number) {
    this.currentPage = page;
  }

  getCountByType(value: string) {
    if (value === 'all') {
      return this.mappedResult.length;
    }
    const cases = filter(this.mappedResult, (elem: any) => {
      return elem.case_type === value;
    });
    if (!cases) {
      return 0;
    }
    return cases.length;
  }

  formatCaseDate(date: string) {
    return date.split(' ')[0];
  }
}
