var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "contours-table" },
    [
      _c("thead", [
        _vm._m(0),
        _c("tr", [
          _c("th", { attrs: { colspan: "5" } }, [
            _vm._v(
              "\n        Учетный номер части\n        " +
                _vm._s(_vm.get(_vm.contour, "cad_number", "Нет данных")) +
                "\n      "
            )
          ])
        ]),
        _c("tr", [
          _c("th", { attrs: { colspan: "5" } }, [
            _vm._v(
              "\n        Система координат\n        " +
                _vm._s(
                  _vm.get(_vm.contour, "entity_spatial.sk_id", "Нет данных")
                ) +
                "\n      "
            )
          ])
        ]),
        _vm._m(1),
        _vm._m(2)
      ]),
      _vm._l(_vm.elements, function(element, i) {
        return _c(
          "tbody",
          { key: i },
          _vm._l(element.ordinates, function(item, index) {
            return _c("tr", { key: item.ord_nmb + index }, [
              _c("td", [_vm._v(_vm._s(item.ord_nmb))]),
              _c("td", [_vm._v(_vm._s(item.x))]),
              _c("td", [_vm._v(_vm._s(item.y))]),
              _c("td", [_vm._v("-")]),
              _c("td", [_vm._v(_vm._s(_vm.get(item, "delta_geopoint", "-")))])
            ])
          }),
          0
        )
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { attrs: { colspan: "5" } }, [
        _vm._v(
          "\n        Сведения о характерных точках границы земельного участка\n      "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { attrs: { rowspan: "2" } }, [
        _vm._v("\n        Номер точки\n      ")
      ]),
      _c("th", { attrs: { colspan: "2" } }, [
        _vm._v("\n        Координаты\n      ")
      ]),
      _c("th", { attrs: { rowspan: "2" } }, [
        _vm._v("\n        Описание закрепления на местности\n      ")
      ]),
      _c("th", { attrs: { rowspan: "2" } }, [
        _vm._v(
          "\n        Средняя квадратичная погрешность определения\n        координат характерных точек границ земельного участка, м\n      "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { "border-left-style": "none" } }, [
        _vm._v("\n        X\n      ")
      ]),
      _c("th", [_vm._v("Y")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }