












































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'AppCreateSettingsDialog',

  popupOptions: {
    size: 'sm',
  },
})
export default class AppCreateSettingsDialog extends Vue {
  model: ApiTypes.Settings.CreateItemBody = {
    key: '',
    text: '',
    enabled: true,
  }

  async submit() {
    try {
      await this.$store.dispatch('settings/createItem', this.model);

      this.$noty.success({ text: 'Успешно создано' });

      this.$dialog.close();
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: this.$api._getErrorMessage(error) });
    }
  }
}
