



































































import Vue from 'vue';
import { Validators } from 'shared/utils/validator';
import formatDateStringToISO from 'shared/utils/date/formatDateStringToISO';
interface ComponentData {
  model: Model.UpdateTariffForUserBody;
  activeTarif: Model.Tariff.Item;
  loading: boolean;
}

export default Vue.extend({
  popupOptions: {
    size: 'xs',
  },

  props: {
    userId: String,
    onUpdated: Function,
  },

  data(): ComponentData {
    return {
      model: {
        person_id: this.userId,
        from_date: new Date().toString(),
        thru_date: '',
      },
      activeTarif: {
        duration: null,
        tariff_simple_id: null,
        tariff_name: null,
        tariff_threshold: null,
        product_prices: null,
      },
      loading: true,
    };
  },

  async created() {
    try {
      this.model.thru_date = this.getTimeEndDate();
      this.activeTarif = await this.getActiveTariff();
      this.loading = false;
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    async getActiveTariff() {
      return this.$api.tariff.GetCurrentForUser({
        user_id: this.userId,
      });
    },

    validationRules(): ValidationConfig {
      return {
        from_date: [ Validators.required ],
        thru_date: [ Validators.required ],
      };
    },

    async editTariff() {
      this.loading = true;
      const body = { ...this.model };
      const newDate = new Date();
      body.from_date = this.setDateTime(newDate, body.from_date);
      body.thru_date = this.setDateTime(newDate, body.thru_date);
      formatDateStringToISO(body, 'from_date', 'thru_date');
      try {
        await this.$api.tariff.UpdateForUser(body);
        this.$noty.success({ text: 'Тариф успешно изменён' });
        this.onUpdated();
        this.$dialog.close();
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },

    setDateTime(currentDate: Date, date: string) {
      const result = new Date(date);
      result.setHours(currentDate.getHours());
      result.setMinutes(currentDate.getMinutes());
      result.setSeconds(currentDate.getSeconds());
      return result.toString();
    },

    getTimeEndDate() {
      // 15670000000 - количество милисекунд в 6-ти месяцах
      return new Date(Date.now() + 15670000000).toString();
    },
  },

});

