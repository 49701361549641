var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-report-item",
    {
      attrs: {
        title: _vm.capitalize(_vm.result.name),
        icon: "book-reader",
        status: "done",
        color: "primary",
        id: _vm.result.name,
        opened: !!(_vm.result.items && _vm.result.items.length)
      },
      scopedSlots: _vm._u(
        [
          !!(_vm.result.items && _vm.result.items.length)
            ? {
                key: "extra-content",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "report-item-result__graph mb-3" },
                      [
                        _c(
                          "div",
                          { staticClass: "report-item-result__graph-counters" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__graph-counters-elem"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__graph-counters-elem-key"
                                  },
                                  [
                                    _vm._v(
                                      "\n            Общее количество штрафов\n          "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__graph-counters-elem-result"
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.result.items.length) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__graph-counters-elem"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__graph-counters-elem-key"
                                  },
                                  [
                                    _vm._v(
                                      "\n            Общая сумма штрафов\n          "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__graph-counters-elem-result"
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm._f("currency")(_vm.itemsSum)
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _c("div", {
                          staticClass: "report-item-result__graph-divider"
                        }),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.$isServer,
                                expression: "!$isServer"
                              }
                            ],
                            staticClass:
                              "report-item-result__graph-filter justify-content-end"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result__graph-filter-radio report-item-result__graph-filter-radio--inline"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__graph-filter-radio-btn",
                                    attrs: { active: _vm.filterBy === "year" },
                                    on: {
                                      click: function($event) {
                                        _vm.filterBy = "year"
                                      }
                                    }
                                  },
                                  [_vm._v("\n            По годам\n          ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result__graph-filter-radio-btn",
                                    attrs: { active: _vm.filterBy === "sum" },
                                    on: {
                                      click: function($event) {
                                        _vm.filterBy = "sum"
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            По суммам\n          "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _vm.filterBy === "sum" && !_vm.$isServer
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result-filter-and-chart"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result-filter-and-chart__chart"
                                  },
                                  [
                                    _c("app-semi-circle-chart", {
                                      attrs: {
                                        data: _vm.bySumChartData,
                                        "title-text": _vm.bySumChartTitleText
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "report-item-result-filter" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "report-item-result-filter__radio-btn",
                                        attrs: {
                                          active: _vm.filterBySum === "all"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.filterBySum = "all"
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n            Все (" +
                                            _vm._s(_vm.result.items.length) +
                                            ")\n          "
                                        )
                                      ]
                                    ),
                                    _vm._l(_vm.itemsBySum, function(
                                      item,
                                      i,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: i,
                                          staticClass:
                                            "report-item-result-filter__radio-btn",
                                          attrs: {
                                            active: _vm.filterBySum === i,
                                            disabled: !item.length
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.changeFilter(i)
                                            }
                                          }
                                        },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "report-item-result-filter__color-circle",
                                            style: {
                                              backgroundColor:
                                                _vm.filterColors[index]
                                            }
                                          }),
                                          _vm.isNumeric(i)
                                            ? [
                                                _vm._v(
                                                  "\n              до\n            "
                                                )
                                              ]
                                            : _vm._e(),
                                          i
                                            ? [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      _vm._f("currency")(i)
                                                    ) +
                                                    "\n            "
                                                )
                                              ]
                                            : _vm._e(),
                                          _vm._v(
                                            "\n            (" +
                                              _vm._s(item.length) +
                                              ")\n          "
                                          )
                                        ],
                                        2
                                      )
                                    })
                                  ],
                                  2
                                )
                              ]
                            )
                          : _vm.filterBy === "year" && !_vm.$isServer
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "report-item-result-filter-and-chart"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "report-item-result-filter-and-chart__chart-column"
                                  },
                                  [
                                    _c("app-column-chart-with-radio", {
                                      attrs: {
                                        data: _vm.byYearChartData,
                                        "title-text": _vm.bySumChartTitleText,
                                        "on-column-click": _vm.selectYear
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "rc-modern-info-panel" }, [
                          _c(
                            "div",
                            { staticClass: "rc-modern-info-panel__wrapper" },
                            [
                              _c("div", [_c("app-warning-img")], 1),
                              _vm.$isServer
                                ? _c("div", [
                                    _vm._v(
                                      "\n            Интерактивный график доступен в веб версии отчета\n          "
                                    )
                                  ])
                                : _c("div", [
                                    _vm._v(
                                      "\n            Кликните на интересующий вас год или размер штрафа для отображения дополнительных сведений\n          "
                                    )
                                  ])
                            ]
                          )
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "text-right report-open-all" }, [
                      !_vm.$isServer
                        ? _c(
                            "span",
                            {
                              staticClass: "link",
                              on: { click: _vm.toggleOpen }
                            },
                            [
                              !_vm.openedLocal
                                ? [_vm._v("\n          Раскрыть все\n        ")]
                                : [_vm._v("\n          Скрыть все\n        ")]
                            ],
                            2
                          )
                        : _vm._e()
                    ]),
                    _c("app-extended-report-item-list", {
                      attrs: {
                        opened: _vm.openedLocal,
                        result: _vm.paginatedItems[_vm.currentPage - 1]
                      }
                    }),
                    _vm.filteredItems.length > _vm.perPage
                      ? _c("app-pagination", {
                          staticClass: "mt-3",
                          attrs: {
                            current: _vm.currentPage,
                            "per-page": _vm.perPage,
                            total: _vm.filteredItems.length
                          },
                          on: { "page-changed": _vm.pageChanged }
                        })
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm.result.items && _vm.result.items.length
        ? [
            _vm._v(
              "\n    Найдены записи о штрафах ФССП, включая закрытые и архивные дела. Актуальные сведения в Базовом отчете\n  "
            )
          ]
        : [_vm._v("\n    Данные не найдены\n  ")]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }