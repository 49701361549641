var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container container_responsive" }, [
    _c(
      "div",
      { staticClass: "page-wrapper page-wrapper_border" },
      [
        _vm._m(0),
        _c("app-data-table", {
          attrs: {
            headers: _vm.headers,
            items: _vm.items,
            loading: _vm.loading,
            pagination: _vm.pagination
          },
          on: { "page-changed": _vm.pageChanged },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "td",
                    {
                      staticClass: "link",
                      on: {
                        click: function($event) {
                          return _vm.goToUserPage(row.UserEmail)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(row.UserEmail) + "\n        "
                      )
                    ]
                  ),
                  _c("td", [_vm._v(_vm._s(row.Number))]),
                  _c("td", [_vm._v(_vm._s(row.INN))]),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm._f("date")(row.Created, "DD.MM.YYYY HH:mm:ss"))
                    )
                  ]),
                  _c("td", [_vm._v(_vm._s(row.Sum) + " руб.")])
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "admin-page-head d-flex align-items-center justify-content-between"
      },
      [_c("h5", [_vm._v("Счета на оплату")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }