




































































import Vue from 'vue';
import _ from 'lodash';

import formatDateStringToISO from 'shared/utils/date/formatDateStringToISO';
import { Validators } from 'shared/utils/validator';

interface ComponentData {
  model: PriceComponent.Request.Update;
}

export default Vue.extend({
  props: {
    priceItem: Object,
    onUpdated: Function,
  },

  popupOptions: {
    size: 'xs',
  },

  data() {
    return {
      model: _.pick(this.priceItem, 'thru_date', 'from_date', 'enabled', 'price', 'price_component_id'),
    };
  },

  methods: {
    validationRules(): ValidationConfig {
      return {
        thru_date: [ Validators.required ],
        from_date: [ Validators.required ],
        price: [ Validators.isInt({ min: 1, message: 'Цена должна быть не меньше 1' }) ],
      };
    },

    async updateItem() {
      try {
        const body = { ...this.model };

        formatDateStringToISO(body, 'from_date', 'thru_date');

        await this.$api.priceComponent.items.UpdateItem(body);

        this.$dialog.close();

        this.onUpdated();
      } catch (error) {
        console.error(error);
      }
    },
  },
});
