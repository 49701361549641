






























import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'AppConfirmDialog',

  popupOptions: {
    size: 'xs',
    name: 'popup_default popup_confirm',
  },

  props: {
    title: {
      type: String,
      default: 'Подтвердите действие',
    },
    body: String,
    onConfirm: Function,
    onCancel: Function,
    confirmBtn: {
      type: Object,
      default: () => ({
        text: 'Ок',
        variant: 'primary',
      }),
    },
    cancelBtn: {
      type: Object,
      default: () => ({
        text: 'Отмена',
        variant: 'light',
      }),
    },
  },
})
export default class ConfirmationDialog extends Vue {
  // props
  onConfirm: () => void;
  onCancel: () => void;
  // data()
  loading: boolean = false;
  // lifecycle hooks
  // methods
  async confirm() {
    if (this.loading) {
      return;
    }

    this.loading = true;

    if (this.onConfirm) {
      await this.onConfirm();
    }

    this.loading = false;
    this.$dialog.close();
  }

  cancel() {
    if (this.loading) {
      return;
    }

    if (this.onCancel) {
      this.onCancel();
    }

    this.$dialog.close();
  }
}
